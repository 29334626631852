import { faHospital, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Fade, UncontrolledDropdown } from 'reactstrap';
import { CHANGE_PASSWORD_URL, CHANGE_SECURITY_INFO_URL } from '../App/AppSettings';
import { IDENTITY_URL } from '../Shared/SmartMe/Constants';
import { ERROR } from '../Localization';
import { withSMARTWrapper } from '../Shared/Forms';
import { toastError } from '../Shared/Forms/Toaster';
import { redirect } from '../Shared/SmartMe/Actions';
import { navigateTo } from '../Shared/Actions';
import { withLoader } from '../Shared/Loading';
import { DEFAULT_TEXT, DEFAULT_NAMESPACE } from '../Shared/Constants/LanguageKeys.js';
import AuthHelper from '../Shared/Authentication/AuthHelper';
import { DirectionContextConsumer } from '../Shared/DirectionContext';
import { AuthConsumer } from '../Shared/Authentication/AuthContext';

// Const: Title of Dropdown titles resided in NavHeader
const NAVHEADER_DROPDOWN_TITLE = {
    CHANGE_PASSWORD: 'Change Password',
    CHANGE_SECURITY_INFO: 'Change Security Info',
    LOGOUT: 'Log Out'
};

const IS_USER_SECURITY_QNS_PRESENT_URL = IDENTITY_URL + '/Account/IsSecurityQuestionsPresent/';

class Profile extends React.Component {
    constructor(props) {
        super(props);

        const { supportedLangResponse } = this.props.context;
        const langArray = supportedLangResponse ? supportedLangResponse.body.Data : []; 

        this.state = { 
            isActive: false,
            isSecurityQuestionsPresent: false,
            languages: langArray
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.context.supportedLangResponse !== this.props.context.supportedLangResponse) {
            const { supportedLangResponse } = this.props.context;
            this.setState({
                languages: supportedLangResponse.body.Data
            });
        }
    }

    // Fn: Toggle between active state
    toggleActiveState = () => {
        this.setState({ isActive: !this.state.isActive });
    };

    toggleProfileDisplay = () => {
        if (this.props.profileDisplay) {
            return "d-block"
        }
        else {
            return "d-none"
        }
    }

    componentDidMount() {
        let self = this;
        AuthHelper.getUser(function (user) {
            self.setState({
                apiRoute: IS_USER_SECURITY_QNS_PRESENT_URL + user.profile.sub
            })
            self.isSecurityQuestionsPresent();
        });
    }

    async isSecurityQuestionsPresent() {
        const { loader } = this.props;

        //Start loading
        loader.start();

        const response = await fetch(this.state.apiRoute);
        if (!response.ok) {
            toastError(ERROR.FORM_DEFAULT_FAIL);
        }

        // End loading
        loader.done();

        const data = await response.json();
        if (data.IsSuccess) {
            this.setState({
                isSecurityQuestionsPresent: data.Data
            });
        } else {
            toastError(data.Messages);
        }
    }

    render() {
        
        const { i18n } = this.props;
        const { changeLanguage, currLang } = this.props.context; 

        return (
            <AuthConsumer>
                {({ isAuthenticated, profile, logout, login }) => (((!isAuthenticated) && (
                    <Col className={"col-auto profile " + this.toggleProfileDisplay()}>
                        <Button color="neutral" onClick={() => navigateTo('/dashboard')}>
                            Login
                        <FontAwesomeIcon icon={faHospital} className="login-button" fixedWidth={true} />
                        </Button>
                    </Col>
                )) || ((profile && isAuthenticated) && (
                    <Col className={"col-auto profile " + this.toggleProfileDisplay()}>
                        <Dropdown isOpen={this.state.isActive} toggle={this.toggleActiveState} className="profile-dropdown">
                            <DropdownToggle tag="div" data-toggle="dropdown" data-boundary="viewport" aria-expanded="false">
                                <div className="btn align-self-center" >
                                    <i className="fa-2x align-middle"><FontAwesomeIcon icon={faUserCircle} /></i>
                                    <span className="align-middle profile-text dropdown-toggle p-1">{profile.preferred_username}</span>
                                </div>
                            </DropdownToggle>
                            <Fade in={this.state.isActive} timeout={100}>
                                <DropdownMenu right className="navbar-header-dropdown">
                                    <div onClick={(e) => { e.preventDefault(); redirect(CHANGE_PASSWORD_URL) }}>
                                        <DropdownItem>{NAVHEADER_DROPDOWN_TITLE.CHANGE_PASSWORD}</DropdownItem>
                                    </div>
                                    {this.state.isSecurityQuestionsPresent &&
                                        <React.Fragment>
                                            <DropdownItem divider />
                                            <div onClick={(e) => { e.preventDefault(); redirect(CHANGE_SECURITY_INFO_URL) }}>
                                                <DropdownItem>{NAVHEADER_DROPDOWN_TITLE.CHANGE_SECURITY_INFO}</DropdownItem>
                                            </div>
                                        </React.Fragment>
                                    }
                                    <DropdownItem divider />

                                    <UncontrolledDropdown nav>
                                        <DropdownToggle nav caret className="language-nav">
                                            {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_MENU_CONTENT_DROPDOWN_LANGUAGE, DEFAULT_TEXT.LANGUAGE)}
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            {  
                                                this.state.languages.map((lang, i) =>
                                                    <DropdownItem
                                                        key={i}
                                                        onClick={() => {
                                                            i18n.changeLanguage(lang.LanguageCodeValue, () => changeLanguage(lang.LanguageCodeValue));
                                                        }}
                                                        active={currLang === lang.LanguageCodeValue}
                                                    >
                                                        <bdi>{`${lang.Description} (${lang.LanguageCodeValue})`}</bdi>
                                                    </DropdownItem>
                                                )
                                            }
                                        </DropdownMenu>
                                    </UncontrolledDropdown>

                                    <DropdownItem divider />




                                    <DropdownItem>
                                        <div
                                            href="#"
                                            onClick={logout}>
                                            {NAVHEADER_DROPDOWN_TITLE.LOGOUT}
                                        </div>
                                    </DropdownItem>
                                </DropdownMenu>
                            </Fade>
                        </Dropdown>
                    </Col>
                )))}
            </AuthConsumer>
        );
    }
}


const withDirectionContext = WrappedComponent => {
    class DirectionOnPageContext extends React.Component {
        render() {
            return ( 
                <DirectionContextConsumer>
                    {({ changeLanguage, supportedLangResponse, currLang }) => {
                        return (
                        <WrappedComponent
                            context={{ changeLanguage, supportedLangResponse, currLang }}
                            {...this.props}
                        />);
                    }}
                </DirectionContextConsumer>
            );
        }
    }
    return DirectionOnPageContext;
}

export default withSMARTWrapper(withLoader(withDirectionContext(Profile)));