// import {
//     faAt, faBook, faBrain, faBriefcase, faCalendarDay, faCode, faCog,
//     faCogs, faEnvelopeOpenText, faFileAlt, faFileExcel, faHome, faInbox, faList, faMailBulk, faSearch, faSms
// } from '@fortawesome/free-solid-svg-icons';
import { faBook, faBrain, faCode, faCog, faCogs, faFileAlt, faFileExcel, faHome, faInbox, faList, faSearch } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Nav } from 'reactstrap';
import { PATH } from '../App/AppSettings';
import { NavbarAuthExtension, NavbarExtension } from '../Extensions/NavbarExtension';
import { AuthConsumer } from '../Shared/Authentication/AuthContext';
import { withLoader } from '../Shared/Loading';
import NavContainer from './NavContainer';
import NavLayout1D from './NavLayout1D';
import NavLayout1M from './NavLayout1M';
import NavLayout2D from './NavLayout2D';
import NavLayout2M from './NavLayout2M';
import NavLink from './NavLink';
import { LANGUAGE_KEYS } from '../Shared/Constants/LanguageKeys';

class Navbar extends React.Component {
    NavbarContent = () => {
        const { t } = this.props;
        // Constant: Navbar Item Names
        const NAVITEM = {
            DASHBOARD: t(LANGUAGE_KEYS.BLS_INTERNALDASHBOARD_CONTENT_TITLE_DASHBOARD),
            INBOX: t(LANGUAGE_KEYS.BLS_INTERNALMENU_NAVIGATION_MENU_INBOX),
            APPLICATION: t(LANGUAGE_KEYS.BLS_INTERNALMENU_NAVIGATION_MENU_APPLICATIONS),
            SERVICE: t(LANGUAGE_KEYS.BLS_INTERNALMENU_NAVIGATION_MENU_APPROVALS),
            SERVICES: 'Services',
            ADMIN: t(LANGUAGE_KEYS.BLS_INTERNALMENU_NAVIGATION_MENU_ADMIN),
            CONTACTUS: 'Contact Us',
            FAQS: 'FAQ',
            SYSTEM_CONFIG: t(LANGUAGE_KEYS.BLS_INTERNALMENU_NAVIGATION_MENU_SYSTEMCONFIG),
            NOTIFICATION: t(LANGUAGE_KEYS.BLS_INTERNALMENU_NAVIGATION_MENU_NOTIFICATIONTEMPLATE),
            REPORT: t(LANGUAGE_KEYS.BLS_INTERNALMENU_NAVIGATION_MENU_REPORTGENERATION),
            PAYMENT_INBOX: t(LANGUAGE_KEYS.BLS_INTERNALMENU_NAVIGATION_MENU_PAYMENTINBOX),
            RECOMMENDATION_ENGINE_CONFIGURATION: t(LANGUAGE_KEYS.BLS_INTERNALMENU_NAVIGATION_MENU_NETWORKGRAPH),
            SERVICE_CONFIG: t(LANGUAGE_KEYS.BLS_INTERNALMENU_NAVIGATION_MENU_SERVICECONFIG),
            SERVICE_APP_CONFIG: t(LANGUAGE_KEYS.BLS_INTERNALMENU_NAVIGATION_MENU_SERVICEAPPCONFIG),
            LANGUAGE: t(LANGUAGE_KEYS.BLS_INTERNALMENU_NAVIGATION_MENU_LICENCEMANAGEMENT),
            // ACCESS_MGMT: 'Access Management',
        };

        return (
            <Nav>
                <NavLink
                    onClick={this.props.onClick}
                    navbarLayout={this.props.navbarLayout}
                    to={PATH.DASHBOARD}
                    icon={faHome}
                    navPath={NAVITEM.DASHBOARD}
                >
                    {t(LANGUAGE_KEYS.BLS_INTERNALMENU_NAVIGATION_MENU_HOME)}
                </NavLink>
                <AuthConsumer>
                    {({ isAuthenticated, profile }) => ((profile && isAuthenticated) && (
                        <React.Fragment>
                            <NavLink
                                onClick={this.props.onClick}
                                navbarLayout={this.props.navbarLayout}
                                to={PATH.INBOX.MAIN}
                                icon={faInbox}
                                navPath={NAVITEM.INBOX}
                                includePaths={[
                                    '/inbox/FormProcessing'
                                ]}
                            >
                                {t(LANGUAGE_KEYS.BLS_INTERNALMENU_NAVIGATION_MENU_INBOX)}
                            </NavLink>

                            <NavLink
                                onClick={this.props.onClick}
                                navbarLayout={this.props.navbarLayout}
                                to={PATH.PAYMENT_INBOX}
                                icon={faInbox}
                                navPath={NAVITEM.PAYMENT_INBOX}
                            >
                                {t(LANGUAGE_KEYS.BLS_INTERNALMENU_NAVIGATION_MENU_PAYMENTINBOX)}
                            </NavLink>

                            <NavContainer
                                navbarLayout={this.props.navbarLayout}
                                path={PATH.ENQUIRY.MAIN}
                                name={t(LANGUAGE_KEYS.BLS_INTERNALMENU_NAVIGATION_MENU_ENQUIRY)}
                                icon={faSearch}
                            >
                                <NavLink
                                    to={PATH.ENQUIRY.APPLICATION}
                                    dropdownlevel="1"
                                    icon={faSearch}
                                    navPath={NAVITEM.APPLICATION}
                                    includePaths={[
                                        '/enquiry/application/FormDetails'
                                    ]}
                                >
                                    {t(LANGUAGE_KEYS.BLS_INTERNALMENU_NAVIGATION_MENU_APPLICATIONS)}
                                </NavLink>

                                <NavLink
                                    to={PATH.ENQUIRY.SERVICE}
                                    dropdownlevel="1"
                                    icon={faSearch}
                                    navPath={NAVITEM.SERVICE}
                                    includePaths={[
                                        '/enquiry/service/serviceDetails',
                                        '/enquiry/service/serviceStatusDetails'
                                    ]}
                                >
                                    {t(LANGUAGE_KEYS.BLS_INTERNALMENU_NAVIGATION_MENU_APPROVALS)}
                                </NavLink>
                            </NavContainer>

                            <NavContainer
                                navbarLayout={this.props.navbarLayout}
                                path={PATH.SERVICE_MANAGEMENT.MAIN}
                                name={t(LANGUAGE_KEYS.BLS_INTERNALMENU_NAVIGATION_MENU_ADMIN)}
                                icon={faList}
                            >
                                <NavLink
                                    to={PATH.SERVICE_MANAGEMENT.SERVICE_MANAGEMENT}
                                    dropdownlevel="1"
                                    icon={faList}
                                    navPath={PATH.LICENCEMANAGEMENT}
                                    includePaths={[
                                        PATH.SERVICE_MANAGEMENT.MAIN + '/revoke',
                                        PATH.SERVICE_MANAGEMENT.MAIN + '/suspend',
                                        PATH.SERVICE_MANAGEMENT.MAIN + '/revoke"',
                                        PATH.SERVICE_MANAGEMENT.MAIN + '/reinstate'
                                    ]}
                                >
                                    {t(LANGUAGE_KEYS.BLS_INTERNALMENU_NAVIGATION_MENU_SERVICEMANAGEMENT)}
                                </NavLink>
                                <NavLink
                                    to={PATH.SERVICE_EXTENSION.SERVICE_EXTENSION}
                                    dropdownlevel="1"
                                    icon={faList}
                                    navPath={PATH.LICENCEEXTENSION}
                                >
                                    {t(LANGUAGE_KEYS.BLS_INTERNALMENU_NAVIGATION_MENU_SERVICEEXTENSION)}
                                </NavLink>
                            </NavContainer>

                            <NavContainer
                                navbarLayout={this.props.navbarLayout}
                                path={PATH.REPORT_GENERATION.MAIN}
                                name={t(LANGUAGE_KEYS.BLS_INTERNALMENU_NAVIGATION_MENU_REPORTGENERATION)}
                                icon={faFileAlt}
                            >
                                <NavLink
                                    to={PATH.REPORT_GENERATION.SERVICE_REPORT}
                                    dropdownlevel="1" icon={faFileExcel}
                                    navPath={NAVITEM.REPORT}
                                >
                                    {t(LANGUAGE_KEYS.BLS_INTERNALMENU_NAVIGATION_MENU_SERVICESUMMARY)}
                                </NavLink>
                                <NavLink
                                    to={PATH.REPORT_GENERATION.PAYMENT_REPORT}
                                    dropdownlevel="1"
                                    icon={faFileExcel}
                                    navPath={NAVITEM.REPORT}
                                >
                                    {t(LANGUAGE_KEYS.BLS_INTERNALMENU_NAVIGATION_MENU_PAYMENTSUMMARY)}
                                </NavLink>
                                <NavLink
                                    to={PATH.REPORT_GENERATION.RENEWAL_REPORT}
                                    dropdownlevel="1"
                                    icon={faFileExcel}
                                    navPath={NAVITEM.REPORT}>
                                    {t(LANGUAGE_KEYS.BLS_INTERNALMENU_NAVIGATION_MENU_RENEWALREPORT)}
                                </NavLink>
                            </NavContainer>

                            <NavContainer
                                navbarLayout={this.props.navbarLayout}
                                path={PATH.CONFIG.MAIN}
                                name={t(LANGUAGE_KEYS.BLS_INTERNALMENU_NAVIGATION_MENU_CONFIG)}
                                icon={faCogs}
                            >
                                <NavLink
                                    to={PATH.CONFIG.SERVICE_CONFIG}
                                    dropdownlevel="1"
                                    icon={faCog}
                                    navPath={NAVITEM.SERVICE_CONFIG}
                                    includePaths={[
                                        '/Config/ServiceConfig/Version',
                                        '/Config/ServiceConfig/Create',
                                        '/Config/ServiceConfig/Edit',
                                        '/Config/ServiceConfig/View',
                                        '/Config/ServiceConfig/CreateFromExisting'
                                    ]}
                                >
                                    {t(LANGUAGE_KEYS.BLS_INTERNALMENU_NAVIGATION_MENU_SERVICECONFIG)}
                                </NavLink>
                                <NavLink to={PATH.CONFIG.SERVICE_APP_CONFIG}
                                    dropdownlevel="1" icon={faCog}
                                    navPath={NAVITEM.SERVICE_APP_CONFIG}
                                    includePaths={[
                                        '/Config/ServiceAppConfig/Version',
                                        '/Config/ServiceAppConfig/Create',
                                        '/Config/ServiceAppConfig/Edit',
                                        '/Config/ServiceAppConfig/View',
                                        '/Config/ServiceAppConfig/CreateFromExisting'
                                    ]}
                                >
                                    {t(LANGUAGE_KEYS.BLS_INTERNALMENU_NAVIGATION_MENU_SERVICEAPPCONFIG)}
                                </NavLink>
                                <NavLink
                                    to={PATH.CONFIG.FORM_GENERATOR}
                                    dropdownlevel="1"
                                    icon={faCog}
                                >
                                    Form Generator
                                </NavLink>
                                <NavLink
                                    to={PATH.CONFIG.PREVIEW_SERVICE}
                                    dropdownlevel="1"
                                    icon={faCog}
                                >
                                    {t(LANGUAGE_KEYS.BLS_INTERNALMENU_NAVIGATION_MENU_PREVIEWSERVICE)}
                                </NavLink>
                                <NavLink
                                    to={PATH.CONFIG.ACCESS_MANAGEMENT}
                                    isExternal={true}
                                    target="_blank"
                                    dropdownlevel="1"
                                    icon={faCog}
                                >
                                    {t(LANGUAGE_KEYS.BLS_INTERNALMENU_NAVIGATION_MENU_ACCESSMANAGEMENT)}
                                </NavLink>
                                <NavLink
                                    to={PATH.CONFIG.FORMIOADMIN}
                                    isExternal={true}
                                    target="_blank"
                                    dropdownlevel="1"
                                    icon={faCog}
                                >
                                    {t(LANGUAGE_KEYS.BLS_INTERNALMENU_NAVIGATION_MENU_FORMIOADMIN)}
                                </NavLink>
                            </NavContainer>

                            <NavContainer
                                navbarLayout={this.props.navbarLayout}
                                path={PATH.SYSTEM_CONFIG.MAIN}
                                name={t(LANGUAGE_KEYS.BLS_INTERNALMENU_NAVIGATION_MENU_SYSTEMCONFIG)}
                                icon={faCogs}
                            >
                                <NavLink
                                    to={PATH.SYSTEM_CONFIG.APP_CONFIG}
                                    dropdownlevel="1" icon={faCog}
                                    navPath={NAVITEM.APPCONFIG}
                                    includePaths={[
                                        '/SystemConfig/ApplicationConfig/Create',
                                        '/SystemConfig/ApplicationConfig/Update'
                                    ]}
                                >
                                    {t(LANGUAGE_KEYS.BLS_INTERNALMENU_NAVIGATION_MENU_APPCONFIG)}
                                </NavLink>

                                <NavLink
                                    to={PATH.SYSTEM_CONFIG.CODE}
                                    dropdownlevel="1" icon={faCode}
                                    navPath={NAVITEM.CODE}
                                    includePaths={[
                                        PATH.CODE + '/New',
                                        PATH.CODE + '/View',
                                        PATH.CODE + '/Update'
                                    ]}
                                >
                                    {t(LANGUAGE_KEYS.BLS_INTERNALMENU_NAVIGATION_MENU_CODETABLE)}
                                </NavLink>

                                <NavLink 
                                    to={PATH.SYSTEM_CONFIG.FAQ}
                                    dropdownlevel="1" icon={faBook}
                                    navPath={NAVITEM.FAQ}
                                    includePaths={['/SystemConfig/faq/faqManagement']}
                                >
                                    {t(LANGUAGE_KEYS.BLS_INTERNALMENU_NAVIGATION_MENU_FAQMANAGEMENT)}
                                </NavLink>

                                {/* <NavContainer
                                    navbarLayout={this.props.navbarLayout}
                                    dropdownlevel="1"
                                    path={PATH.SYSTEM_CONFIG.NOTIFICATION_TEMPLATE.MAIN}
                                    name={t('BLS.InternalMenu.Navigation.Menu.NotificationTemplate')}
                                    icon={faMailBulk}
                                >
                                    <NavLink
                                        to={PATH.SYSTEM_CONFIG.NOTIFICATION_TEMPLATE.EMAIL_TEMPLATE}
                                        dropdownlevel="2"
                                        icon={faAt}
                                        navPath={NAVITEM.NOTIFICATION}
                                        includePaths={[
                                            PATH.NOTIFICATION + '/EmailCreate',
                                            PATH.NOTIFICATION + '/EmailUpdate'
                                        ]}
                                    >
                                        {t('BLS.InternalMenu.Navigation.Menu.EmailTemplate')}
                                    </NavLink>
                                    <NavLink
                                        to={PATH.SYSTEM_CONFIG.NOTIFICATION_TEMPLATE.LETTER_TEMPLATE}
                                        dropdownlevel="2"
                                        icon={faEnvelopeOpenText}
                                        navPath={NAVITEM.NOTIFICATION}
                                        includePaths={[
                                            PATH.NOTIFICATION + '/LetterCreate',
                                            PATH.NOTIFICATION + '/LetterUpdate'
                                        ]}
                                    >
                                        {t('BLS.InternalMenu.Navigation.Menu.LetterTemplate')}
                                    </NavLink>
                                    <NavLink
                                        to={PATH.SYSTEM_CONFIG.NOTIFICATION_TEMPLATE.SMS_TEMPLATE}
                                        dropdownlevel="2"
                                        icon={faSms}
                                        navPath={NAVITEM.NOTIFICATION}
                                        includePaths={[
                                            PATH.NOTIFICATION + '/SmsCreate',
                                            PATH.NOTIFICATION + '/SmsUpdate'
                                        ]}
                                    >
                                        {t('BLS.InternalMenu.Navigation.Menu.SMSTemplate')}
                                    </NavLink>
                                </NavContainer>
								<NavLink to='/SystemConfig/faq/faqManagement' dropdownlevel="1" icon={faBook} navPath={NAVITEM.FAQ}
                                    includePaths={'/SystemConfig/faq/faqManagement'}>FAQ Management</NavLink>

                                <NavLink
                                    to={PATH.SYSTEM_CONFIG.PUBLIC_HOLIDAY}
                                    dropdownlevel="1" icon={faCalendarDay}
                                    navPath={NAVITEM.PUBLICHOLIDAY}
                                    includePaths={[
                                        PATH.SYSTEM_CONFIG.PUBLICHOLIDAY + '/Create',
                                        PATH.SYSTEM_CONFIG.PUBLICHOLIDAY + '/Update'
                                    ]}
                                >
                                    {t('BLS.InternalMenu.Navigation.Menu.PublicHoliday')}
                                </NavLink>

                                <NavLink
                                    to={PATH.SYSTEM_CONFIG.WORKING_HOUR}
                                    dropdownlevel="1" icon={faBriefcase}
                                    navPath={NAVITEM.WORKINGHOUR}
                                    includePaths={[
                                        PATH.SYSTEM_CONFIG.WORKINGHOUR + '/Create',
                                        PATH.SYSTEM_CONFIG.WORKINGHOUR + '/Update',
                                        PATH.SYSTEM_CONFIG.WORKINGHOUR + '/CreateException',
                                        PATH.WORKINGHOUR + '/UpdateException'
                                    ]}
                                >
                                    {t('BLS.InternalMenu.Navigation.Menu.WorkingHour')}
                                </NavLink>

                                <NavLink
                                    to={PATH.SYSTEM_CONFIG.ONLINE_HELP}
                                    dropdownlevel="1" icon={faBook}
                                    navPath={NAVITEM.ONLINEHELP}
                                    includePaths={[
                                        '/SystemConfig/SystemConfig/Tree'
                                    ]}
                                >
                                    {t('BLS.InternalMenu.Navigation.Menu.OnlineHelpManagement')}
                                </NavLink> */}

                            </NavContainer>

                            <NavContainer
                                navbarLayout={this.props.navbarLayout}
                                path={PATH.LANGUAGE.MAIN}
                                name={t(LANGUAGE_KEYS.BLS_INTERNALMENU_NAVIGATION_MENU_LANGUAGEMANAGEMENT)}
                                icon={faBook}
                            >
                                <NavLink
                                    to={PATH.LANGUAGE.REGISTRATION}
                                    dropdownlevel="2"
                                    icon={faBook}
                                    navPath={NAVITEM.LANGUAGE}
                                    includePaths={[PATH.LANGUAGE.MAIN + '/editApplicationLanguages']}
                                >
                                    {t(LANGUAGE_KEYS.BLS_INTERNALMENU_NAVIGATION_MENU_LANGUAGEREGISTRATION)}
                                </NavLink>
                                <NavLink
                                    to={PATH.LANGUAGE.LIST}
                                    dropdownlevel="2"
                                    icon={faBook}
                                    navPath={NAVITEM.LANGUAGE}
                                    includePaths={[
                                        PATH.LANGUAGE.MAIN + '/addEntry/',
                                        PATH.LANGUAGE.MAIN + '/editEntry'
                                    ]}
                                >
                                    {t(LANGUAGE_KEYS.BLS_INTERNALMENU_NAVIGATION_MENU_TRANSLATIONMANAGEMENT)}
                                </NavLink>
                                {/* <NavLink
                                    to={PATH.LANGUAGE.SCOPE_MANAGEMENT}
                                    dropdownlevel="2"
                                    icon={faBook}
                                    navPath={NAVITEM.ADMIN}
                                    includePaths={[
                                        PATH.LANGUAGE.MAIN + '/addScope',
                                        PATH.LANGUAGE.MAIN + '/viewScope',
                                        PATH.LANGUAGE.MAIN + '/editScope'
                                    ]}
                                >
                                    {t('BLS.InternalMenu.Navigation.Menu.ScopeManagement')}
                                </NavLink>
                                <NavLink
                                    to={PATH.LANGUAGE.CATEGORY_MANAGEMENT}
                                    dropdownlevel="2"
                                    icon={faBook}
                                    navPath={NAVITEM.ADMIN}
                                    includePaths={[
                                        PATH.LANGUAGE.MAIN + '/addCategory',
                                        PATH.LANGUAGE.MAIN + '/viewCategory',
                                        PATH.LANGUAGE.MAIN + '/editCategory'
                                    ]}
                                >
                                    {t('BLS.InternalMenu.Navigation.Menu.CategoryManagement')}
                                </NavLink>
                                <NavLink
                                    to={PATH.LANGUAGE.SUBCATEGORY_MANAGEMENT}
                                    dropdownlevel="2" icon={faBook}
                                    navPath={NAVITEM.ADMIN}
                                    includePaths={[
                                        PATH.LANGUAGE.MAIN + '/addSubCategory',
                                        PATH.LANGUAGE.MAIN + '/viewSubCategory',
                                        PATH.LANGUAGE.MAIN + '/editSubCategory'
                                    ]}
                                >
                                    {t('BLS.InternalMenu.Navigation.Menu.SubCategoryManagement')}
                                </NavLink> */}
                                <NavLink
                                    to={PATH.LANGUAGE.EXCEL_UPDATE}
                                    dropdownlevel="2" icon={faBook}
                                    navPath={NAVITEM.ADMIN}
                                >
                                    {t(LANGUAGE_KEYS.BLS_INTERNALMENU_NAVIGATION_MENU_LANGUAGEPACKUPDATE)}
                                </NavLink>
                            </NavContainer>

                            <NavLink
                                onClick={this.props.onClick}
                                navbarLayout={this.props.navbarLayout}
                                to={PATH.RECOMMENDATION_ENGINE_CONFIGURATION}
                                icon={faBrain}
                                navPath={NAVITEM.RECOMMENDATION_ENGINE_CONFIGURATION}
                            >
                                {NAVITEM.RECOMMENDATION_ENGINE_CONFIGURATION}
                            </NavLink>

                            <NavbarAuthExtension onClick={this.props.onClick} navPath={NAVITEM.SERVICES} navbarLayout={this.props.navbarLayout} />
                        </React.Fragment>
                    ))}
                </AuthConsumer>
                <NavbarExtension onClick={this.props.onClick} navbarLayout={this.props.navbarLayout}></NavbarExtension>
            </Nav>
        );
    }

    LayoutToggle = () => {
        switch (this.props.navbarLayout) {
            case '1M':
                return (
                    <NavLayout1M navbarLayout={this.props.navbarLayout} collapseNavbar={this.props.collapseNavbar} onUpdate={this.props.onUpdate} onClick={this.props.onClick}>
                        {this.NavbarContent()}
                    </NavLayout1M>
                );
            case '2D':
                return (
                    <NavLayout2D showNavDisplay={this.props.showNavDisplay} navbarLayout={this.props.navbarLayout}>
                        {this.NavbarContent()}
                    </NavLayout2D>
                );
            case '2M':
                return (
                    <NavLayout2M navbarLayout={this.props.navbarLayout} collapseNavbar={this.props.collapseNavbar} onUpdate={this.props.onUpdate} onClick={this.props.onClick}>
                        {this.NavbarContent()}
                    </NavLayout2M>
                );
            case '1D':
            default:
                return (
                    <NavLayout1D navbarLayout={this.props.navbarLayout}>
                        {this.NavbarContent()}
                    </NavLayout1D>
                );
        }
    }

    render() {
        return this.LayoutToggle();
    }
}

export default withTranslation()(withLoader(Navbar));