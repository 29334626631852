import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row } from 'reactstrap';
import * as Yup from 'yup';
import { GET_CODES_BY_CODE_CATEGORIES, LICENCE_CONFIG_LICENCE_TYPE_LIST } from '../../App/AppSettings';
import { getParams, isValidForm } from '../../Shared/Actions';
import { FIELD_SIZE } from '../../Shared/Constants';
import { LANGUAGE_KEYS, ERROR } from '../../Shared/Constants/LanguageKeys';
import { ActionButton, DataTable } from '../../Shared/DataTable';
import { getCustomComponents } from '../../Shared/DataTable/BaseTable.js';
import { useAsyncFetch } from '../../Shared/Fetch';
import { AccordionContainer, SelectList } from '../../Shared/Forms';
import { ModalConfirm } from '../../Shared/Modal';
import { getLangKey, getLicenceTypeNameTranslationKey } from '../DisplayComponents/DisplayUtils';

const LICENCE_DEPENDENCY_CODE = 'LicenceDependency';

const INITIAL_VALUES = {
    DependencyType: '',
    LicenceName: '',
    DependencyTypeId: '',
    LicenceTypeId: ''
};

export default function Dependency(props) {

    const { t } = useTranslation();
    const { values, toggleSection } = props.smartFormValues;
    const [dependencyData, setDependencyData] = useState(values.DependencyData);
    const [dependencyTypeOptions, setDependencyTypeOptions] = useState([]);
    const [licenceTypeOptions, setLicenceTypeOptions] = useState([]);
    const [dependencyValues, setDependencyValues] = useState(INITIAL_VALUES);
    const [dependencyErrorDisplay, setDependencyErrorDisplay] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [count, setCount] = useState(values.DependencyData.length);
    const [modalState, setModalState] = useState(null);

    const VALIDATION = Yup.object().shape({
        DependencyType: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED)),
        LicenceName: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED))
    })
    
    const SECTION_NAMES = {
        LICENCE_DEPENDENCY: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_CONTENT_SECTION_SERVICEDEPENDENCY), status: true }
    };

    const ADD_DEPENDENCY_MODAL = {
        hasModal: true,
        name: 'AddDependency',
        modalHeader: t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_CONTENT_MODAL_ADDDEPENDENCY)
    };

    const EDIT_DEPENDENCY_MODAL = {
        hasModal: true,
        name: 'EditDependency',
        modalHeader: t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_CONTENT_MODAL_EDITDEPENDENCY)
    };

    const DELETE_MODAL = {
        hasModal: true,
        name: 'Delete',
        modalHeader: t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_CONTENT_MODAL_DELETEDEPENDENCY),
        modalContent: t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_MESSAGE_MODAL_DELETEDEPENDENCYMESSAGE)
    };

    const licenceTypeListResponse = useAsyncFetch(LICENCE_CONFIG_LICENCE_TYPE_LIST, getParams());

    const paramas = {
        categoryList: LICENCE_DEPENDENCY_CODE
    };

    const dependencyTypeListResponse = useAsyncFetch(GET_CODES_BY_CODE_CATEGORIES, getParams(paramas));
    useEffect(() => {
        let isMounted = true;
        if (isMounted && licenceTypeListResponse) {
            const fetchLicenceTypeList = async (response) => {
                const { Data, IsSuccess } = response.body;
                if (response.success && IsSuccess) {
                    let licenceTypeList = [];
                    Data.forEach(element => {
                        const licenceTypeNameTranslationKey = getLicenceTypeNameTranslationKey(element.LicenceTypeName);
                        const licenceName = t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY, licenceTypeNameTranslationKey, element.LicenceTypeId));
                        licenceTypeList.push({ label: licenceName, value: element.LicenceTypeId });
                    });
                    setLicenceTypeOptions(licenceTypeList);
                }
            };

            fetchLicenceTypeList(licenceTypeListResponse);
        }
        if (isMounted && dependencyTypeListResponse) {

            const fetchDependencyTypeList = async (response) => {
                const { Data, IsSuccess } = response.body;
                if (response.success && IsSuccess) {
                    let dependencyTypeList = [];
                    Data.forEach(element => {
                        dependencyTypeList.push({ label: t(getLangKey(LANGUAGE_KEYS.BLS_CODE_CATEGORY_TITLE_KEY, element.CodeCategory, element.CodeName)), value: element.ID });
                    });
                    setDependencyTypeOptions(dependencyTypeList);
                }
            };

            fetchDependencyTypeList(dependencyTypeListResponse);
        }
        return () => isMounted = false;
    }, [licenceTypeListResponse, dependencyTypeListResponse, t]);

    const toggleModal = (modalName) => {
        setModalState((modalState !== modalName) ? modalName : null);
    };

    const resetModalValues = () => {
        setDependencyValues(INITIAL_VALUES);
    };

    const setValues = (name, value, optionsArray) => {
        let id = optionsArray.findIndex(o => o.value === value);
        let label = optionsArray[id].label;
        if (name === 'DependencyTypeId') {
            setDependencyValues((prevValues) => ({ ...prevValues, 'DependencyType': label, 'DependencyTypeId': value }));
        } else {
            setDependencyValues((prevValues) => ({ ...prevValues, 'LicenceName': label, 'LicenceTypeId': value }));
        }
    };

    const addItem = () => {
        if (isValidForm(VALIDATION, dependencyValues)) {
            setDependencyErrorDisplay(false);
            let data = [...dependencyData];
            data.push({ ...dependencyValues, RowId: count });
            setCount(count + 1);
            setDependencyData(data);
            values.DependencyData = data;
            toggleModal(ADD_DEPENDENCY_MODAL.name);
        } else {
            setDependencyErrorDisplay(true);
        }
    };

    const editItem = (data) => {
        setDependencyValues(data);
        let index = values.DependencyData.findIndex(val =>
            val.RowId === data.RowId
        );
        setSelectedIndex(index);
        toggleModal(EDIT_DEPENDENCY_MODAL.name);
    };

    const displayRequiredMessage = (label) => (
        `${label} ${t(ERROR.REQUIRED)}`
    );

    const confirmEditItem = () => {
        if (isValidForm(VALIDATION, dependencyValues)) {
            setDependencyErrorDisplay(false);
            let data = [...dependencyData];
            data[selectedIndex] = dependencyValues;
            setDependencyData(data);
            values.DependencyData = data;
            toggleModal(EDIT_DEPENDENCY_MODAL.name);
            setSelectedIndex(null);
        } else {
            setDependencyErrorDisplay(true);
        }
    };

    const deleteItem = (data) => {
        toggleModal(DELETE_MODAL.name);
        let index = values.DependencyData.findIndex(val =>
            val.RowId === data.RowId
        );
        setSelectedIndex(index);
    };

    const confirmDeleteItem = () => {
        let data = [...dependencyData];
        data.splice(selectedIndex, 1);
        setDependencyData(data);
        values.DependencyData = data;
        setSelectedIndex(null);
    };

    const getLicenceTypeOptions = () => {
        let updatedLicTypeOptions;
        if (modalState === EDIT_DEPENDENCY_MODAL.name) {
            updatedLicTypeOptions = licenceTypeOptions.filter(currLicTypeOption => !dependencyData.map(d => d.LicenceTypeId).includes(currLicTypeOption.value)
                || currLicTypeOption.value === dependencyValues.LicenceTypeId);
        } else {
            updatedLicTypeOptions = licenceTypeOptions.filter(currLicTypeOption => !dependencyData.map(d => d.LicenceTypeId).includes(currLicTypeOption.value));
        }
        return updatedLicTypeOptions;
    };

    const renderDependencyContentBody = () => {
        const licTypeOptions = getLicenceTypeOptions();
        return (
            <div>
                <SelectList
                    isClearable={false}
                    name='DependencyTypeId'
                    value={dependencyValues.DependencyTypeId}
                    placeholder={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_PLACEHOLDER_DEPENDENCYTYPE)}
                    minLength={0}
                    maxLength={140}
                    inputSize={FIELD_SIZE.LARGE}
                    label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_FIELD_DEPENDENCYTYPE)}
                    required
                    options={dependencyTypeOptions}
                    onChangeField={(name, value) => setValues(name, value, dependencyTypeOptions)}
                    isMulti={false}
                    error={!dependencyValues.DependencyType && dependencyErrorDisplay ? displayRequiredMessage(t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_FIELD_DEPENDENCYTYPE)) : ''}
                />
                <SelectList
                    isClearable={false}
                    name='LicenceTypeId'
                    value={dependencyValues.LicenceTypeId}
                    placeholder={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_PLACEHOLDER_SERVICENAME)}
                    minLength={0}
                    maxLength={140}
                    inputSize={FIELD_SIZE.LARGE}
                    label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_FIELD_SERVICENAME)}
                    required
                    options={licTypeOptions}
                    onChangeField={(name, value) => setValues(name, value, licenceTypeOptions)}
                    isMulti={false}
                    error={!dependencyValues.LicenceName && dependencyErrorDisplay ? displayRequiredMessage(t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_FIELD_SERVICENAME)) : ''}
                />
            </div>
        );
    };

    const renderDependencyGrid = () => {
        return (
            <React.Fragment>
                <Row>
                    <Col >
                        <Button className="float-right" color="neutral" onClick={() => {
                            resetModalValues();
                            toggleModal(ADD_DEPENDENCY_MODAL.name);
                        }}>
                            {t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_NAVIGATION_BUTTON_NEWDEPENDENCY)}
                        </Button>
                    </Col>
                </Row>
                <div className="smart-list">
                    <DataTable
                        pageSize="5"
                        minFilterChars='2'
                        data={dependencyData}
                        noResultsMessage={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_MESSAGE_PLACEHOLDER_NODEPENDENCY)}
                        components={getCustomComponents()}
                        columns={
                            {
                                RunningNumber: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_TABLE_TITLE_NUMBER), width: '5%' },
                                DependencyType: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_TABLE_TITLE_DEPENDENCYTYPE), width: '25%', DBkey: 'DependencyType' },
                                LicenceName: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_TABLE_TITLE_SERVICENAME), width: '60%', DBkey: 'LicenceName' },
                                Actions: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_TABLE_TITLE_ACTION), width: '10%' }
                            }
                        }
                        renderActions={({ RowData }) => (
                            <React.Fragment>
                                <ActionButton tooltip={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_CONTENT_BUTTON_EDIT)} color="forward" onClick={() => editItem(RowData)}>
                                    <FontAwesomeIcon icon={faEdit} />
                                </ActionButton>
                                <ActionButton tooltip={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_CONTENT_BUTTON_DELETE)} color="hazard" onClick={() => deleteItem(RowData)}>
                                    <FontAwesomeIcon fixedWidth icon={faTrash} />
                                </ActionButton>
                            </React.Fragment>
                        )}
                    />
                    <ModalConfirm
                        isOpen={(modalState === DELETE_MODAL.name)}
                        contentHeader={DELETE_MODAL.modalHeader}
                        contentBody={DELETE_MODAL.modalContent}
                        confirmText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_CONTENT_BUTTON_CONFIRM)}
                        confirmCallback={() => {
                            toggleModal(DELETE_MODAL.name);
                            confirmDeleteItem();
                        }}
                        cancelText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_CONTENT_BUTTON_CANCEL)}
                        cancelCallback={() => toggleModal(DELETE_MODAL.name)}
                    />
                    <ModalConfirm
                        isOpen={(modalState === ADD_DEPENDENCY_MODAL.name)}
                        contentHeader={ADD_DEPENDENCY_MODAL.modalHeader}
                        confirmText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_CONTENT_BUTTON_ADD)}
                        confirmCallback={() => { addItem(); }}
                        cancelText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_CONTENT_BUTTON_CANCEL)}
                        cancelCallback={() => toggleModal(modalState)}
                        contentBody={renderDependencyContentBody()}
                    />
                    <ModalConfirm
                        isOpen={(modalState === EDIT_DEPENDENCY_MODAL.name)}
                        contentHeader={EDIT_DEPENDENCY_MODAL.modalHeader}
                        confirmText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_CONTENT_BUTTON_EDIT)}
                        confirmCallback={() => { confirmEditItem(); }}
                        cancelText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_CONTENT_BUTTON_CANCEL)}
                        cancelCallback={() => toggleModal(modalState)}
                        contentBody={renderDependencyContentBody()}
                    />
                </div>
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            <AccordionContainer
                title={SECTION_NAMES.LICENCE_DEPENDENCY.title}
                active={SECTION_NAMES.LICENCE_DEPENDENCY.status}
                onClick={toggleSection}
                isIndividual={true}
                sections={SECTION_NAMES}
            >
                {renderDependencyGrid()}
            </AccordionContainer>
        </React.Fragment>
    );
}
