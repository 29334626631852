import React, { useState } from "react";
import { Modal, Button, Row, Col, ModalHeader, ModalBody, ModalFooter, Label, Input } from "reactstrap";
import { COMPARATOR_OPTIONS } from "../../AppConstants";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

function ConditionalDisplayModal(props) {
    const [displayCondition, setDisplayCondition] = useState(props.json.displayCondition);
    const [displayConditionDetails, setDisplayConditionDetails] = useState(props.json.displayConditionDetails);
    const globalFieldList = props.globalFieldList;
    const currentFieldID = props.currentComponentID;

    function closeDisplayModal() {
        props.onModalClose();
    }

    function removeDisplayConditionOnClick() {
        setDisplayCondition(true);
        setDisplayConditionDetails([]);
        props.onModalReset();
    }

    function displayConditionOnChange(event) {
        setDisplayCondition(event.target.value === "true" ? true : false);
    }

    function updateDisplayModal() {
        const newJson = {
            ...props.json,
            displayCondition: displayCondition,
            displayConditionDetails: displayConditionDetails
        }
        props.onModalUpdate(newJson);
        props.onModalClose();
    }

    function addConditionOnClick() {
        const condition = {
            id: displayConditionDetails.length,
            componentName: "",
            comparator: "",
            comparisonValue: ""
        };
        setDisplayConditionDetails([...displayConditionDetails, condition]);
    }

    function componentNameOnChange(event, condition) {
        condition.componentName = event.target.value;
        setDisplayConditionDetails(createNewConditionsArray(condition));
    }

    function comparatorOnChange(event, condition) {
        condition.comparator = event.target.value;
        setDisplayConditionDetails(createNewConditionsArray(condition));
    }

    function comparisonValueOnChange(event, condition) {
        condition.comparisonValue = event.target.value;
        setDisplayConditionDetails(createNewConditionsArray(condition));
    }

    function createNewConditionsArray(condition) {
        const newConditionsArray = displayConditionDetails.map((existingCondition) => {
            if (existingCondition.id === condition.id)
                return condition;
            else 
                return existingCondition;
        })
        return newConditionsArray;
    }

    return <Modal className="modal-content" isOpen={props.show} backdrop={false} keyboard={false}>
        <ModalHeader>Configure Conditional Display</ModalHeader>
        <ModalBody>
            <Row xs="auto">
                <Col xs={5} className="mb-1">
                    <Label className="middle-title">Display Mode</Label>
                </Col>
                <Col className="mb-1">
                    <Input type="select" onChange={displayConditionOnChange} value={displayCondition}>
                        <option value="true">Show</option>
                        <option value="false">Don't Show</option>
                    </Input> 
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs="auto">
                    <Label className="middle-title">Add Conditions</Label>
                </Col>
                <Col xs="auto">
                    <Button color="primary" onClick={addConditionOnClick} outline>
                        <FontAwesomeIcon icon={faPlus} />
                    </Button>
                </Col>
            </Row>
            {displayConditionDetails.map((condition, index) => {
                return <Row key={index} className="mb-2">
                    <Col>
                        <Input type="select" value={condition.componentName} onChange={event => componentNameOnChange(event, condition)}>
                            <option value="" disabled>Component</option>
                            {globalFieldList.filter((field) => field.fieldID && !field.fieldID.includes(currentFieldID)).map((field, index) => {
                                return <option key={index} value={field.fieldID}>{field.label}</option>
                            })}
                        </Input>
                    </Col>
                    <Col>
                        <Input type="select" value={condition.comparator} onChange={event => comparatorOnChange(event, condition)}>
                            <option value="" disabled>Comparator</option>
                            {COMPARATOR_OPTIONS.map((comparator, index) => {
                                return <option key={index} value={comparator}>{comparator}</option>
                            })}
                        </Input>
                    </Col>
                    <Col>
                        <Input placeholder="Value" value={condition.comparisonValue} onChange={event => comparisonValueOnChange(event, condition)}></Input>
                    </Col>
                </Row>
            })}
        </ModalBody>
        <ModalFooter>
            <Button color="danger" onClick={removeDisplayConditionOnClick} outline>Clear</Button>    
            <Button onClick={closeDisplayModal} outline>Cancel</Button>
            <Button color="success" onClick={updateDisplayModal} outline>Save</Button>
        </ModalFooter>
    </Modal>
}

export default ConditionalDisplayModal;