import { DEFAULT_ENQUIRY_MONTHS_AGO, STATUS_BADGE_COLOR } from '../../App/AppSettings.js';
import { FILE_UPLOAD_SECTION_FILES } from '../../Shared/Constants';
import { getLangKey, LANGUAGE_KEYS } from '../Constants/LanguageKeys.js';
// addMonth - START : Date parsers to prevent errors in the addMonth function
export function isLeapYear(year) {
    return (((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0)); 
}

export function getDaysInMonth(year, month) {
    return [31, (isLeapYear(year) ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month];
}

export function addMonths(value) {
    var date = new Date();
    var n = date.getDate();
    date.setDate(1);
    date.setMonth(date.getMonth() + value);
    date.setDate(Math.min(n, getDaysInMonth(date.getFullYear(), date.getMonth())));
    return date;
}

export function getDateString(date) {
    if(date.type === Date.type){
        let dateString = new Intl.DateTimeFormat('en-GB', {
            year: 'numeric', 
            month: 'short', 
            day: '2-digit' })
            .format(date)
            .replace(/ /g,"-");
        return dateString;
    }
    else {
        return null;
    }
};
//addMonth - END

export const customMonthAgoString = getDateString(addMonths(-(DEFAULT_ENQUIRY_MONTHS_AGO)))
    
export const todayString = getDateString(new Date());

export function getBadgeColor(stateString) {
    var badgeColor = "normal"
    if((STATUS_BADGE_COLOR.success).indexOf(stateString) > -1) {
        badgeColor = "success"
    }
    else if((STATUS_BADGE_COLOR.warning).indexOf(stateString) > -1) {
        badgeColor = "warning"
    }
    else if((STATUS_BADGE_COLOR.danger).indexOf(stateString) > -1) {
        badgeColor = "danger"
    }

    return badgeColor;
}

export const arrayToSelectListOptions = (array, dropdownArray) => {
    if (Array.isArray(array)){
        if (!(array === undefined || array.length === 0)) {
            array.forEach(element => {
                if (element !== null) 
                {
                    dropdownArray.push({label: element, value: element})
                }
            });
        }
    }
}

export const convertToFormData = (data, _formData, _name) => {
    const formData = _formData || new FormData();

    for (let name in data) {

        if (!data.hasOwnProperty(name) || !data[name]) {
            continue;
        }

        const formKey = _name ? `${_name}[${name}]` : name;

        // Date
        if (data[name] instanceof Date) {
            formData.append(formKey, data[name].toISOString());
        }

        // Multi FileUpload
        else if (name === FILE_UPLOAD_SECTION_FILES) {

            if (data[name].constructor === Array) {
                // For each file in FileList
                for (let i = 0; i < (data[name].length); i++) {

                    if (data[name][i].key) {
                        // Append fileName
                        let fileNameStr = `${data[name][i].key}%%Multi`;

                        // Append file with key as fileName
                        formData.append(name, data[name][i], fileNameStr);

                        // Append key as fileKey, and value as description
                        formData.append(fileNameStr, data[name][i].description);
                    }
                }
            }
            else {
                // Append file with key as fileName
                formData.append(name, data[name], data[name].key);
            }
        }
        // Single FileUpload
        else if ((data[name] && ((data[name][0] instanceof File) || (data[name] instanceof File)))) {

            // Append fileName
            let fileNameStr = `${data[name].key}%%Single`;

            // Append file with key as fileName
            formData.append(name, data[name], fileNameStr);

            // Append key as fileKey, and value as description
            formData.append(fileNameStr, name);
        }

        // Else if the value is an object (Array is seen as an object too)
        else if (typeof (data[name]) === 'object') {

            // Appends nested child objects to form recursively
            convertToFormData(data[name], formData, formKey);
        }

        // Otherwise, it is a normal input
        else {
            // Append key value to form
            formData.append(formKey, data[name]);
        }
    }

    return formData;
}
/**
 * start of BLS form utils
 */
export const arrayToApplicationTypeSelectListOptions = (array, dropdownArray, t) => {
    if (Array.isArray(array)) {
        if (!(array === undefined || array.length === 0)) {
            array.forEach(element => {
                if (element !== null) {
                    dropdownArray.push({ label:  t(getLangKey(LANGUAGE_KEYS.BLS_CODE_LICENCEAPPLICATIONTYPE_TITLE_KEY, element.ApplicationTypeCodeName)), value: element.ApplicationType });
                }
            });
        }
    }
};

export const arrayToLicenceStatusSelectListOptions = (array, dropdownArray, t) => {
    if (Array.isArray(array)) {
        if (!(array === undefined || array.length === 0)) {
            array.forEach(element => {
                if (element !== null) {
                    dropdownArray.push({ label:  t(getLangKey(LANGUAGE_KEYS.BLS_CODE_LICENCESTATUS_TITLE_KEY, element.LicenceStatusCodeName)), value: element.LicenceStatus });
                }
            });
        }
    }
};

export const convertAdditionalFormDataToFormData = (data) => {
    const formData = new FormData();

    for (let name in data) {

        // If the value is an object (Array is seen as an object too)
        if (typeof (data[name]) === 'object' && !Array.isArray(data[name])) {
            // Append object child items into formData
            appendChildToForm(formData, data[name], name);
        }

        // Otherwise, it is a normal input
        else {
            // Append key value to form
            formData.append(name, data[name]);
        }
    }
    return formData;
}

// Helper: Appends nested child objects to form recursively
const appendChildToForm = (formData, objItem, objItemPath) => {

    // If object item exists
    if (objItem) {

        // Get keys of object Items
        const objKeys = Object.keys(objItem);

        // For each key in object
        for (let i = 0; i < objKeys.length; i++) {

            // Get key via index
            const objKey = objKeys[i];

            // If VALUE of object Item is not an object OR its a DATE Object, end the recursion
            if ((objKey) && (typeof (objItem[objKey]) !== 'object' || objItem[objKey] instanceof Date)) {
                // Append key-pair value to form
                formData.append((objItemPath + "." + objKey), objItem[objKey]);
            }
            else if (objItem[objKey] && objItem[objKey].file instanceof File) {

                /*** FILE UPLOAD MODIFICATION ***/
                // Append fileName
                const fileNameStr = objItem[objKey].fileKey === undefined ? objItem[objKey].fileName : objItem[objKey].fileKey;

                // Append file with key as fileName
                formData.append(objItemPath, objItem[objKey].file, fileNameStr);

                // // Append key as fileKey, and value as description
                // formData.append(fileNameStr, name);

            }
            // Otherwise it is an object, continue recursion
            else {

                // Otherwise, append <OBJECT_ITEM_KEY><.><recursive_fn>
                appendChildToForm(formData, objItem[objKey], (objItemPath + "." + objKey));
            }
        }
    }
}
/**
 * end of BLS form utils
 */
