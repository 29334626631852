import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Container, Row } from 'reactstrap';
import { GET_FILE_BY_NAME_URL } from '../../../App/AppSettings';
import { getFileRequest, getParams } from '../../../Shared/Actions';
import { ActionButton, DataTable } from '../../../Shared/DataTable';
import { getCustomComponents } from '../../../Shared/DataTable/BaseTable.js';
import { AccordionContainer, Plaintext } from '../../../Shared/Forms';
import { AdditionalInformationModalConfirm, ModalConfirm } from '../../../Shared/Modal';
import { previewAdditionalForm } from '../FormComponents/LicenceAppConfigUtils';
import { LANGUAGE_KEYS } from '../../../Shared/Constants/LanguageKeys';
import { getLangKey } from '../../DisplayComponents/DisplayUtils';

const GET_FILE_URL = GET_FILE_BY_NAME_URL;

export default function LicenceAppConfigAdditionalInformation(props) {
    const { t } = useTranslation();
    const { values, toggleSection } = props.smartFormValues;
    const [selectedAdditionalApplicationValues, setSelectedAdditionalApplicationValues] = useState('');
    const [selectedAdditionalApplicationDocValues, setSelectedAdditionalApplicationDocValues] = useState('');
    const [modalState, setModalState] = useState(null);
    const [additionalApplicationModalState, setAdditionalApplicationModalState] = useState(null);
    const [nextForm, setNextForm] = useState({});
    const [previousForm, setPreviousForm] = useState({});
    const [previousDisabled, setPreviousDisabled] = useState(true);
    const [nextDisabled, setNextDisabled] = useState(false);

    const SECTION_NAMES = {
        ADDITIONAL_APPLICATION_FORMS: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_SECTION_ADDITIONALAPPLICATIONFORMS), status: true }
    };
    
    const VIEW_ADDITIONAL_APPICATION_FIELDS_MODAL = {
        hasModal: true,
        name: 'ViewAdditionalApplicationFields',
        modalHeader: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_MODAL_VIEWADDITIONALAPPFIELDS)
    };
    
    const VIEW_ADDITIONAL_APPICATION_DOCUMENT_MODAL = {
        hasModal: true,
        name: 'ViewAdditionalApplicationDocument',
        modalHeader: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_MODAL_VIEWADDITIONALAPPDOCUMENT)
    };


    const toggleModal = modalName => {
        setModalState((modalState !== modalName) ? modalName : null);
    };

    const toggleAdditionalApplicationModal = modalName => {
        setAdditionalApplicationModalState((additionalApplicationModalState !== modalName) ? modalName : null);
    };

    const viewAdditionalApplicationForm = data => {
        if (data.RowId === 0) {
            setPreviousDisabled(true);
            setNextDisabled(false);
        } else {
            setPreviousDisabled(false);
            setNextDisabled(false);
            setPreviousForm(values.ApplicationOfficerData.AdditionalApplicationFieldsData[data.RowId - 1]);
        }

        if (values.ApplicationOfficerData.AdditionalApplicationFieldsData[data.RowId + 1]) {
            setNextForm(values.ApplicationOfficerData.AdditionalApplicationFieldsData[data.RowId + 1]);
        } else if (values.ApplicationOfficerData.AdditionalApplicationFieldsData.length - 1 === data.RowId) {
            setNextDisabled(true);
        }

        setSelectedAdditionalApplicationValues(data);
        setModalState(VIEW_ADDITIONAL_APPICATION_FIELDS_MODAL.name);
    };

    const viewAdditionalApplicationDocument = data => {
        setSelectedAdditionalApplicationDocValues(data);
        toggleAdditionalApplicationModal(VIEW_ADDITIONAL_APPICATION_DOCUMENT_MODAL.name);
    };

    const setMandatorySuppDoc = rowData => (
        rowData.IsMandatorySupportingDocument === 'true' ? t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_YES) : t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_NO)
    );

    const getMandatorySuppDocTranslatedString = (rowData) => {
        return rowData.IsMandatorySupportingDocument === 'true' ? t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_YES) :
            t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_NO);
    }

    const viewExcelFile = async fileName =>
        await getFileRequest(`${GET_FILE_URL}?fileName=${fileName}`, false, fileName, getParams());

    const setRequiredActions = rowData => {
        const actionList = [];
        rowData.RequiredActions && rowData.RequiredActions.forEach((action, index) => {
            actionList.push(<p key={index}>{action}</p>);
        });
        return <div className="additional-form-table-cell">{actionList}</div>;
    };

    const setAdditionalApplicationUserRoles = rowData => {
        const roleList = [];
        rowData.UserRoleName && rowData.UserRoleName.forEach((role, index) => {
            roleList.push(<p key={index}>{t(getLangKey(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_KEY, role))}</p>);
        });
        return <div className="additional-form-table-cell">{roleList}</div>;
    };

    const renderAdditionalApplicationFieldsGrid = () => {
        const getDocument = selectedAdditionalApplicationValues.AdditionalApplicationDocumentFile ?
            selectedAdditionalApplicationValues.AdditionalApplicationDocumentFile :
            selectedAdditionalApplicationValues.FileName;

        return (
            <React.Fragment>
                <Row>
                </Row>
                <div className="smart-list">
                    <DataTable
                        pageSize="5"
                        minFilterChars='2'
                        data={values.ApplicationOfficerData.AdditionalApplicationFieldsData}
                        noResultsMessage={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_MESSAGE_PLACEHOLDER_NOADDITIONALAPPFORM)}
                        components={getCustomComponents()}
                        columns={
                            {
                                RunningNumber: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_NUMBER), width: '5%' },
                                WorkflowState: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_WORKFLOWSTATE), width: '15%', DBkey: 'WorkflowStateName' },
                                DisplayTitle: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_DISPLAYTITLE), width: '20%', DBkey: 'DisplayTitle' },
                                RequiredActions: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_REQUIREDACTIONS), width: '15%', DBkey: 'RequiredActions', setContent: setRequiredActions },
                                UserRoleName: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_USERROLES), width: '15%', DBkey: 'UserRoleName', setContent: setAdditionalApplicationUserRoles },
                                FileName: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_FILE), width: '20%', DBkey: 'FileName' },
                                DocumentFile: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_FILE), width: '20%', DBkey: 'AdditionalApplicationDocumentFile', visible: false },
                                Actions: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_ACTION), width: '10%' }
                            }
                        }
                        renderActions={({ RowData }) => (
                            <React.Fragment>
                                <ActionButton tooltip={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_VIEW)} color='forward' onClick={() => viewAdditionalApplicationForm(RowData)}><FontAwesomeIcon icon={faEye} /></ActionButton>
                            </React.Fragment>
                        )}
                    />
                    <AdditionalInformationModalConfirm
                        isOpen={(modalState === VIEW_ADDITIONAL_APPICATION_FIELDS_MODAL.name)}
                        contentHeader={VIEW_ADDITIONAL_APPICATION_FIELDS_MODAL.modalHeader}
                        // Previous Form Modal
                        previousFormText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_NAVIGATION_BUTTON_PREVIOUS)}
                        previousForm={() => {
                            viewAdditionalApplicationForm(previousForm);
                        }}
                        cancelText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_CANCEL)}
                        cancelCallback={() => toggleModal(VIEW_ADDITIONAL_APPICATION_FIELDS_MODAL.name)}
                        // Next Form Modal
                        nextFormText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_NAVIGATION_BUTTON_NEXT)}
                        nextForm={() => {
                            viewAdditionalApplicationForm(nextForm);
                        }}
                        previousDisabled={previousDisabled}
                        nextDisabled={nextDisabled}
                        contentBody={
                            <div>
                                <Plaintext
                                    name='WorkflowStateName'
                                    value={selectedAdditionalApplicationValues.WorkflowStateName}
                                    label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_ASSOCIATEDWORKFLOWSTATE)}
                                />
                                <Plaintext
                                    name='DisplayTitle'
                                    value={selectedAdditionalApplicationValues.DisplayTitle}
                                    label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_DISPLAYTITLE)}
                                />
                                <Plaintext
                                    name='RequiredActions'
                                    value={selectedAdditionalApplicationValues.RequiredActions}
                                    label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_REQUIREDACTIONS)}
                                />
                                <Plaintext
                                    name='UserRoleName'
                                    value={selectedAdditionalApplicationValues.UserRoleName}
                                    label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_USERROLESFORMVIEW)}
                                />
                                <div>
                                    <Plaintext
                                        name='AdditionalApplicationDocumentFile'
                                        value={getDocument}
                                        label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_FILE)}
                                    />
                                    <Button
                                        color="white"
                                        type="button"
                                        onClick={() => viewExcelFile(getDocument)}
                                        className="additional-application-download-link-details"
                                    >
                                        {t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_DOWNLOADFILE)}
                                    </Button>
                                </div>
                                <Col className="button-group-center">
                                    <Button color="neutral" size="sm" type="button" onClick={() =>
                                        previewAdditionalForm(t, selectedAdditionalApplicationValues)}>
                                        {t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_PREVIEW)}
                                    </Button>
                                </Col>
                                <DataTable
                                    pageSize="5"
                                    minFilterChars='2'
                                    data={selectedAdditionalApplicationValues.SupportingDocumentData}
                                    noResultsMessage={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_MESSAGE_PLACEHOLDER_NOSERVICESELECTED)}
                                    components={getCustomComponents()}
                                    columns={
                                        {
                                            RunningNumber: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_NUMBER), width: '5%' },
                                            SupportingDocumentName: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_SUPPORTINGDOCUMENT), width: '30%', DBkey: 'SupportingDocumentName' },
                                            SupportingDocumentDescription: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_DESCRIPTION), width: '25%', DBkey: 'SupportingDocumentDescription' },
                                            IsMandatorySupportingDocument: {
                                                title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_MANDATORY),
                                                width: '10%',
                                                DBkey: 'IsMandatorySupportingDocument',
                                                setContent: setMandatorySuppDoc,
                                                getTranslatedString: getMandatorySuppDocTranslatedString
                                            },
                                            Actions: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_ACTION), width: '10%' }
                                        }
                                    }
                                    renderActions={({ RowData }) => (
                                        <React.Fragment>
                                            <ActionButton
                                                tooltip={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_VIEW)}
                                                color='forward'
                                                onClick={() => viewAdditionalApplicationDocument(RowData)}
                                            >
                                                <FontAwesomeIcon icon={faEye} />
                                            </ActionButton>
                                        </React.Fragment>
                                    )}
                                />
                                <ModalConfirm
                                    isOpen={(additionalApplicationModalState === VIEW_ADDITIONAL_APPICATION_DOCUMENT_MODAL.name)}
                                    contentHeader={VIEW_ADDITIONAL_APPICATION_DOCUMENT_MODAL.modalHeader}
                                    cancelText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_CANCEL)}
                                    cancelCallback={() => toggleAdditionalApplicationModal(VIEW_ADDITIONAL_APPICATION_DOCUMENT_MODAL.name)}
                                    contentBody={
                                        <div>
                                            <Plaintext
                                                name='SupportingDocumentName'
                                                value={selectedAdditionalApplicationDocValues.SupportingDocumentName}
                                                label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_DOCUMENTNAME)}
                                            />
                                            <Plaintext
                                                name='SupportingDocumentDescription'
                                                value={selectedAdditionalApplicationDocValues.SupportingDocumentDescription}
                                                label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_DESCRIPTION)}
                                            />
                                            <Plaintext
                                                name='IsMandatorySupportingDocument'
                                                value={selectedAdditionalApplicationDocValues.IsMandatorySupportingDocument ? t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_YES) : t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_NO)}
                                                label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_ISMANDATORY)}
                                            />
                                        </div>
                                    }
                                />
                            </div>
                        }
                    />
                </div>
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            <Container className='full-width'>
                <AccordionContainer
                    title={SECTION_NAMES.ADDITIONAL_APPLICATION_FORMS.title}
                    active={SECTION_NAMES.ADDITIONAL_APPLICATION_FORMS.status}
                    onClick={toggleSection}
                    isIndividual={true}
                    sections={SECTION_NAMES}
                >
                    {renderAdditionalApplicationFieldsGrid()}
                </AccordionContainer>
            </Container>
        </React.Fragment>
    );
}
