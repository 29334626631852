import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormGroup, Label, FormText } from 'reactstrap';
import Scroll from 'react-scroll';
import { FIELD_SIZE, ORIENTATION } from '../Constants';
import { ErrorLabel } from '.';

// HoC function to wrap all shared form components
export const withBasePlaintextControl = (FormControl) => {
    class BasePlaintextControl extends React.Component {

        // Lifecycle: Optimize performance for Form onChange & validation
        shouldComponentUpdate(nextProps, nextState) {
            return (
                this.props.value !== nextProps.value ||
                this.props.error !== nextProps.error ||
                this.props.options !== nextProps.options ||
                this.props.fileList !== nextProps.fileList ||
                this.props.disabled !== nextProps.disabled
            );
        }

        // Fn: Displays different color of label based on isError props
        ErrorField = error => {
            return (error) ? 'label-error' : 'label-default';
        };

        // Fn: Show/hide asterisk based on required prop from parent
        RequiredField = required => {
            return (required) ? 'required' : '';
        };

        IsRow = () => {
            return this.props.orientation === ORIENTATION.ROW;
        };

        IsInline = () => {
            return this.props.orientation === ORIENTATION.INLINE;
        };

        GetLabelSize = () => {
            if (this.props.orientation === ORIENTATION.ROW) {
                return this.props.labelSize;
            }
            return FIELD_SIZE.NONE;
        };

        FieldOrientationClassName = () => {
            if (this.props.orientation === ORIENTATION.INLINE) {
                return 'form-group-inline';
            }
            return '';
        };

        render() {
            return (
                <FormGroup row={this.IsRow()} inline={this.IsInline()} className={`${this.props.className}`}>

                    {/* Display Label */}
                    <Label lg={this.GetLabelSize()} className={`${this.RequiredField(this.props.required)} ${this.ErrorField(this.props.error)}`}>
                        {this.props.label}
                    </Label>

                    <Col lg={this.props.inputSize} className={`${this.FieldOrientationClassName()} ${this.props.className}`}>
                        {/* Form Component */}
                        <Scroll.Element name={this.props.name}>
                            <FormControl {...this.props} />
                        </Scroll.Element>

                        {/* Error Label: All props are possible formatters too */}
                        <FormText color="muted" className="label-help">
                            {this.props.helpLabel}
                        </FormText>
                        <ErrorLabel
                            message={this.props.error}
                            formatter={{ ...this.props, ...this.props.formatOptions }}
                        />
                    </Col>
                </FormGroup>
            );
        }
    }

    // PropTypes: For prop validation
    BasePlaintextControl.propTypes = {
        labelSize: PropTypes.number,
        inputSize: PropTypes.number,
        orientation: PropTypes.string,
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        error: PropTypes.string,
        formatOptions: PropTypes.object,
        required: PropTypes.bool,
        className: PropTypes.string
    };

    // PropTypes: Defaulting value for optional props
    BasePlaintextControl.defaultProps = {
        labelSize: FIELD_SIZE.SMALL,
        inputSize: FIELD_SIZE.LARGE,
        orientation: ORIENTATION.ROW,
        label: '',
        error: '',
        formatOptions: {},
        required: false,
        className: ''
    };


    return BasePlaintextControl;
};
