import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { INBOX_PATH, POST_ACTION_URL } from '../../../../../App/AppSettings';
import { isValidForm, navigateTo } from '../../../../../Shared/Actions';
import { FIELD_SIZE } from '../../../../../Shared/Constants';
import { ERROR, SUCCESS } from '../../../../../Shared/Constants/LanguageKeys';
import { Paragraph, SMARTForm, toastError, toastSuccess } from '../../../../../Shared/Forms';
import history from '../../../../../Shared/History';
import { ModalConfirm } from '../../../../../Shared/Modal';

const POST_URL = POST_ACTION_URL;
const NEXT_URL = INBOX_PATH;

const FORM_CONTEXT = "SubmitRemarks";

const IS_DEMO = false;

const MODAL_NAMES = {
    SUBMIT: "Submit"
};

export default function SubmitRemarks(props) {

    const { state } = history.location;
    
    const command = props.command;
    const modal = props.modal;
    const isCheckOut = props.isCheckOut;
    const clearCommand = props.clearCommand;

    const [on, setOn] = useState(command && modal && isCheckOut);

    const { t } = useTranslation();
    const FORM_VALIDATIONS = Yup.object().shape({
        Remarks: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED))
    });

    useEffect(() => {
        const toggle = () => setOn(!on);

        if (on) {
            modal.toggleModal(MODAL_NAMES.SUBMIT);
            toggle();
        }
    }, [on, modal])

    const FORM_INITIAL_VALUES = {
        command: command,
        Remarks: '',
        formID: state.ID
    }

    const prepareToSubmit = async (modal, _submitForm, values) => {
        if (isValidForm(FORM_VALIDATIONS, values)) {
            modal.toggleModal(MODAL_NAMES.SUBMIT);
        }
        _submitForm();
    }

    const submitCallback = async ({ response }) => {
        if (response.success) {
            const { IsSuccess, RedirectURL } = response.body;
            if (IsSuccess) {
                if (RedirectURL) {
                    navigateTo(RedirectURL, "");
                } else {
                    navigateTo(NEXT_URL, "");
                }
                toastSuccess(t(SUCCESS.FORM_PROCESS_SUCCESS));
            }
            else {
                toastError(t(ERROR.FORM_NOT_PROCESSED));
            }
        } else {
            toastError(t(ERROR.SERVER_UNREACHABLE))
        }
    }

    const displayModalContent = (values, errors, onChange) => {
        return (
            <Paragraph
                name="Remarks"
                value={values.Remarks}
                placeholder="Remarks"
                onChange={onChange}
                rows={3}
                minLength={0}
                maxLength={140}
                inputSize={12}
                labelSize={FIELD_SIZE.XSMALL}
                label="Remarks"
                error={errors.Remarks}
                required
            />
        )
    }

    return (
        <SMARTForm
            formContext={FORM_CONTEXT}
            formValues={FORM_INITIAL_VALUES}
            validationSchema={FORM_VALIDATIONS}
            validateOnSubmit={true}
            serverURL={POST_URL}
            isDummy={IS_DEMO}
            submitCallback={submitCallback}
        >
            {({ values, errors, onChange, submitForm }) => {

                return (
                    <>
                        {modal &&
                            <ModalConfirm
                                className="modal-remarks"
                                isOpen={modal.modalState === MODAL_NAMES.SUBMIT}
                                contentHeader={command}
                                confirmText="Confirm"
                                confirmCallback={() => { prepareToSubmit(modal, submitForm, values); }}
                                cancelText="Cancel"
                                cancelCallback={() => {
                                    modal.toggleModal(MODAL_NAMES.SUBMIT);
                                    setTimeout(function () { clearCommand();}, 1000);
                                }}
                                contentBody={
                                    displayModalContent(values, errors, onChange)
                                }
                            />
                        }
                    </>
                )
            }}
        </SMARTForm>
    )
}