import React from 'react';
import { LAYOUT_TYPE } from '../../../../../../App/AppSettings';
import Section from '../../../../../../Section';
import SubSection from '../../../../../../Section/SubSection';
import { Plaintext } from '../../../../../../Shared/Forms';
import CommonSpfLiquor1To4LicenceOverview from './CommonSpfLiquor1To4LicenceOverview';

const IS_UPPER = true;

class SpfLiquor1To4Cancel extends React.Component {
    render() {
        const { sectionState, toggleSection, sectionName, licenceDetails, licenceInformation, generalInformation } = this.props;
        
        return (
            generalInformation && licenceDetails && licenceInformation ?
            (<Section
                type={LAYOUT_TYPE.FORM_CONTENT}
                sectionState={sectionState}
                sectionName={sectionName}
                toggleSection={toggleSection}
            >
                {/* Cancellation Info */}
                <SubSection orientation="left" title="Cancellation Information">
                    <Plaintext
                        label="Remarks"
                        name="Remarks"
                        value={licenceInformation.Remarks}
                        isUpper={IS_UPPER}
                    />
                    <Plaintext
                        label="Effective End Date"
                        name="EndDate"
                        value={licenceInformation.EndDate}
                        isUpper={IS_UPPER}
                    />
                </SubSection>

                <CommonSpfLiquor1To4LicenceOverview
                    licenceDetails={licenceDetails}
                    generalInformation={generalInformation}
                />
            </Section>) : null
        );
    }
}

export default SpfLiquor1To4Cancel;