import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import * as Yup from 'yup';
import { isValidForm } from '../../Shared/Actions';
import { FIELD_SIZE } from '../../Shared/Constants';
import { ActionButton, DataTable } from '../../Shared/DataTable';
import { getCustomComponents } from '../../Shared/DataTable/BaseTable.js';
import { AccordionContainer, Paragraph, Text } from '../../Shared/Forms';
import { ModalConfirm } from '../../Shared/Modal';
import { REGEX } from '../../App/AppSettings';
import { useTranslation } from "react-i18next";
import { LANGUAGE_KEYS, ERROR, getLangKey } from '../../Shared/Constants/LanguageKeys';
import { getLicenceTypeNameTranslationKey } from '../DisplayComponents/DisplayUtils';

const INITIAL_VALUES = {
    Question: "",
    Answer: "",
    Sequence: "",
    IsEdit: false,
}

const REGEX_SPACE_OR_POSITIVE_INTEGER = /^\s*[1-9]+\d*\s*$|^\s*0\s*$|^\s*$/;

export default function Faq(props) {

    const { t } =  useTranslation();
    const { values, toggleSection } = props.smartFormValues;
    const [faqData, setFaqData] = useState(values.FaqData);
    const [faqValues, setFaqValues] = useState(INITIAL_VALUES);
    const [faqErrorDisplay, setFaqErrorDisplay] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [modalState, setModalState] = useState(null);
    const [count, setCount] = useState(values.FaqData.length);
    const licenceTypeNameTranslationKey = getLicenceTypeNameTranslationKey(values.LicenceInfo.LicenceName);

    const VALIDATION = Yup.object().shape({
        Question: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED)),
        Answer: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED)),
        Sequence: Yup.string().matches(REGEX_SPACE_OR_POSITIVE_INTEGER)
    })
    
    const SECTION_NAMES = {
        FAQ: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_CONTENT_SECTION_FAQ), status: true }
    }
    
    const ADD_FAQ_MODAL = {
        hasModal: true,
        name: "AddFAQ",
        modalHeader: t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_CONTENT_MODAL_ADDFAQ)
    };
    
    const EDIT_FAQ_MODAL = {
        hasModal: true,
        name: "EditFAQ",
        modalHeader: t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_CONTENT_MODAL_EDITFAQ)
    };
    
    const DELETE_MODAL = {
        hasModal: true,
        name: "Delete",
        modalHeader: t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_CONTENT_MODAL_DELETEFAQ),
        modalContent: t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_MESSAGE_MODAL_DELETEFAQMESSAGE)
    }    

    const toggleModal = (modalName) => {
        setModalState((modalState !== modalName) ? modalName : null);
    };

    const resetModalValues = () => {
        setFaqValues(INITIAL_VALUES);
    }

    const setValues = (name, value) => {
        setFaqValues((prevValues) => ({ ...prevValues, [name]: value, IsEdit: true}));
    }

    const addItem = () => {
        if (isValidForm(VALIDATION, faqValues)) {
            setFaqErrorDisplay(false);
            let data = [...faqData];
            data.push({ ...faqValues, RowId: count });
            setCount(count + 1);
            setFaqData(data);
            values.FaqData = data;
            toggleModal(ADD_FAQ_MODAL.name);
        }
        else {
            setFaqErrorDisplay(true);
        }
    };

    const editItem = (data) => {
        const faq = {
            ...data,
            Question: data.FaqId !== undefined ? t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCEDEFINITIONFAQ_QUESTION_KEY, licenceTypeNameTranslationKey, data.FaqId)) : data.Question,
            Answer: data.FaqId !== undefined ? t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCEDEFINITIONFAQ_ANSWER_KEY, licenceTypeNameTranslationKey, data.FaqId)) : data.Answer,
            IsEdit: true,
        }
        setFaqValues(faq);
        let index = values.FaqData.findIndex(val =>
            val.RowId === data.RowId
        )
        setSelectedIndex(index);
        toggleModal(EDIT_FAQ_MODAL.name);
    };

    const displayRequiredMessage = (label) => (
        `${label} ${t(ERROR.REQUIRED)}`
    );

    const isValidSequence = (number) => {
        if (number) {
            return number.match(REGEX.INTEGER_POSITIVE_INCLUDE_ZERO);
        }
        return true;
    }

    const confirmEditItem = () => {
        if (isValidForm(VALIDATION, faqValues)) {
            setFaqErrorDisplay(false);
            let data = [...faqData];
            data[selectedIndex] = faqValues;
            setFaqData(data);
            values.FaqData = data;
            toggleModal(EDIT_FAQ_MODAL.name);
            setSelectedIndex(null);
        }
        else {
            setFaqErrorDisplay(true);
        }
    };

    const deleteItem = (data) => {
        toggleModal(DELETE_MODAL.name);
        let index = values.FaqData.findIndex(val =>
            val.RowId === data.RowId
        )
        setSelectedIndex(index);
    };

    const confirmDeleteItem = () => {
        let data = [...faqData];
        data.splice(selectedIndex, 1);
        setFaqData(data);
        values.FaqData = data;
        setSelectedIndex(null);
    };

    const renderFAQContentBody = () => {
        return (
            <div>
                <Paragraph
                    name="Question"
                    value={getQuestionTranslatedString(faqValues)}
                    placeholder={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_PLACEHOLDER_QUESTION)}
                    onChange={(e) => setValues(e.target.name, e.target.value)}
                    rows={3}
                    minLength={0}
                    inputSize={FIELD_SIZE.LARGE}
                    label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_FIELD_QUESTION)}
                    error={!faqValues.Question && faqErrorDisplay ? displayRequiredMessage("FAQ question") : ""}
                    required
                />
                <Paragraph
                    name="Answer"
                    value={getAnswerTranslatedString(faqValues)}
                    placeholder={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_PLACEHOLDER_ANSWER)}
                    onChange={(e) => setValues(e.target.name, e.target.value)}
                    rows={3}
                    minLength={0}
                    inputSize={FIELD_SIZE.LARGE}
                    label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_FIELD_ANSWER)}
                    error={!faqValues.Answer && faqErrorDisplay ? displayRequiredMessage("FAQ answer") : ""}
                    required
                />
                <Text
                    name="Sequence"
                    value={faqValues.Sequence}
                    placeholder={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_PLACEHOLDER_DISPLAYSEQUENCE)}
                    onChange={(e) => setValues(e.target.name, e.target.value)}
                    rows={3}
                    minLength={0}
                    maxLength={140}
                    inputSize={FIELD_SIZE.LARGE}
                    label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_FIELD_DISPLAYSEQUENCE)}
                    error={!isValidSequence(faqValues.Sequence) && faqErrorDisplay ? "Please input a valid number" : ""}
                />
            </div>
        )
    };

    const setQuestionTranslatedString = (rowData) => {
        if (rowData.hasOwnProperty('FaqId')) {
            const translatedString = t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCEDEFINITIONFAQ_QUESTION_KEY, licenceTypeNameTranslationKey, rowData.FaqId));
            return <>{rowData.IsEdit ? rowData.Question : translatedString}</>
        }
        else return <>{rowData.Question}</>
    }

    const setAnswerTranslatedString = (rowData) => {
        if (rowData.hasOwnProperty('FaqId')) {
            const translatedString = t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCEDEFINITIONFAQ_ANSWER_KEY, licenceTypeNameTranslationKey, rowData.FaqId));
            return <>{rowData.IsEdit ? rowData.Answer : translatedString}</>
        }
        else return <>{rowData.Answer}</>
    }

    const getQuestionTranslatedString = (rowData) => {
        if (rowData.hasOwnProperty('FaqId')) {
            const translatedString =  t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCEDEFINITIONFAQ_QUESTION_KEY, licenceTypeNameTranslationKey, rowData.FaqId));
            return rowData.IsEdit ?  rowData.Question : translatedString;
        }
        else return rowData.Question
    }

    const getAnswerTranslatedString = (rowData) => {
        if (rowData.hasOwnProperty('FaqId')) {
            const translatedString = t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCEDEFINITIONFAQ_ANSWER_KEY, licenceTypeNameTranslationKey, rowData.FaqId));
            return rowData.IsEdit ? rowData.Answer : translatedString;
        }
        else return rowData.Answer
    }
    
    const renderFAQGrid = () => {
        return (
            <React.Fragment>
                <Row>
                    <Col>
                        <Button className="float-right" color="neutral" onClick={() => { toggleModal(ADD_FAQ_MODAL.name); resetModalValues() }}>{t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_NAVIGATION_BUTTON_NEWFAQ)}</Button>
                    </Col>
                </Row>
                <div className="smart-list">
                    <DataTable
                        pageSize="5"
                        minFilterChars='2'
                        data={faqData}
                        noResultsMessage={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_MESSAGE_PLACEHOLDER_NOFAQ)}
                        components={getCustomComponents()}
                        columns={
                            {
                                RunningNumber: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_TABLE_TITLE_NUMBER), width: '5%' },
                                Question: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_TABLE_TITLE_QUESTIONS), width: '35%', DBkey: 'Question', setContent: setQuestionTranslatedString, getTranslatedString: getQuestionTranslatedString },
                                Answer: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_TABLE_TITLE_ANSWER), width: '30%', DBkey: 'Answer', setContent: setAnswerTranslatedString, getTranslatedString: getAnswerTranslatedString },
                                Sequence: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_TABLE_TITLE_DISPLAYSEQUENCE), width: '20%', DBkey: 'Sequence' },
                                Actions: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_TABLE_TITLE_ACTION), width: '10%' }
                            }
                        }
                        renderActions={({ RowData }) => (
                            <React.Fragment>
                                <ActionButton tooltip={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_CONTENT_BUTTON_EDIT)} color="forward" onClick={() => editItem(RowData)}><FontAwesomeIcon icon={faEdit} /></ActionButton>
                                <ActionButton tooltip={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_CONTENT_BUTTON_DELETE)} color="hazard" onClick={() => deleteItem(RowData)}><FontAwesomeIcon fixedWidth icon={faTrash} /></ActionButton>
                            </React.Fragment>
                        )}
                    />
                    <ModalConfirm
                        isOpen={(modalState === DELETE_MODAL.name)}
                        contentHeader={DELETE_MODAL.modalHeader}
                        contentBody={DELETE_MODAL.modalContent}
                        confirmText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_CONTENT_BUTTON_CONFIRM)}
                        confirmCallback={() => { toggleModal(DELETE_MODAL.name); confirmDeleteItem(); }}
                        cancelText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_CONTENT_BUTTON_CANCEL)}
                        cancelCallback={() => toggleModal(DELETE_MODAL.name)}
                    />
                    <ModalConfirm
                        isOpen={(modalState === ADD_FAQ_MODAL.name)}
                        contentHeader={ADD_FAQ_MODAL.modalHeader}
                        confirmText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_CONTENT_BUTTON_ADD)}
                        confirmCallback={() => { addItem() }}
                        cancelText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_CONTENT_BUTTON_CANCEL)}
                        cancelCallback={() => toggleModal(modalState)}
                        contentBody={renderFAQContentBody()}
                    />
                    <ModalConfirm
                        isOpen={(modalState === EDIT_FAQ_MODAL.name)}
                        contentHeader={EDIT_FAQ_MODAL.modalHeader}
                        confirmText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_CONTENT_BUTTON_EDIT)}
                        confirmCallback={() => { confirmEditItem() }}
                        cancelText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_CONTENT_BUTTON_CANCEL)}
                        cancelCallback={() => toggleModal(modalState)}
                        contentBody={renderFAQContentBody()}
                    />
                </div>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <AccordionContainer
                title={SECTION_NAMES.FAQ.title}
                active={SECTION_NAMES.FAQ.status}
                onClick={toggleSection}
                isIndividual={true}
                sections={SECTION_NAMES}
            >
                {renderFAQGrid()}
            </AccordionContainer>
        </React.Fragment>
    )
}