import React, { useState, useEffect, useRef } from "react";
import { Button, Col, ButtonGroup, InputGroup, Input, InputGroupText } from "reactstrap";
import ConditionalDisplayModal from "./modals/ConditionalDisplayModal";
import DataSourceModal from "./modals/DataSourceModal";
import { FIELD_INPUT_TYPE_ARRAY, FIELD_INPUT_TYPE_LENGTH_REQUIRED } from "../AppConstants";
import { OverlayTrigger } from './OverlayTrigger';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTv, faCog, faTrash, faCopy } from '@fortawesome/free-solid-svg-icons';
import DataGridPanel from "./panel/DataGridPanel";

function Field(props) {
    const defaultMaxLength = 500;
    const defaultMinLength = 1;

    const sectionName = (props.sectionJson.sectionID) ? props.sectionJson.sectionID : props.parentFieldID;
    const tempFieldJson = (props.field.fieldID) ? props.field : {
        ...props.field,
        inputType: "",
        label: "",
        fieldID: "",
        placeholder: "",
        isDisabled: false,
        isRequired: false,
        isShown: true,
        maxLength: defaultMaxLength,
        minLength: defaultMinLength,
        displayCondition: true,
        displayDependencyKey: "",
        displayDependencyValue: "",
        displayConditionDetails: [],
        dataSource: "",
        dataSourceValues: [],
        dataSourceResourceID: "",
        dataSourceResource: "",
        dataSourceResourceDependencyKey: "",
        dataSourceResourceDependencyValue: "",
        dataSourceCodeCat: "",
        dataSourceAppConfig: "",
        minSize: defaultMinLength,
        maxSize: defaultMaxLength,
        datagridFields: {
            fields: []
        }
    }
    const [fieldJson, setFieldJson] = useState(tempFieldJson);
    const [fieldValidation, setFieldValidation] = useState(props.fieldValidation)
    const globalFieldList = props.globalFieldList;
    const [isLengthRequired, setIsLengthRequired] = useState(false);
    const inputRef = useRef();

    const isValidated = props.isValidated;

    useEffect(() => {
        setFieldJson({
            ...fieldJson,
            fieldID: sectionName + "_" + fieldJson.inputType + "_" + fieldJson.label.replaceAll(' ', '')
        })
    },
        // eslint-disable-next-line
        [sectionName])

    function deleteFieldOnClick() {
        props.onDelete(props.field);
    }

    function cloneFieldOnClick() {
        const clonedFieldJson = { ...fieldJson, id: props.sectionJson.fields.length + 1 };
        const newFieldArray = [...props.sectionJson.fields, clonedFieldJson];

        props.onFieldChange({
            ...props.sectionJson,
            fields: newFieldArray
        });

        const clonedFieldValidation = { ...fieldValidation, id: props.sectionValidation.fields.length + 1 };
        const newValidFieldArray = [...props.sectionValidation.fields, clonedFieldValidation];

        props.onValidationChange({
            ...props.sectionValidation,
            fields: newValidFieldArray
        });
    }

    //#region OnChange handlers for all input fields
    function inputTypeSelectOnChange(event) {
        const isInputTypeValid = !!event.target.value && event.target.value.trim().length > 0;
        setFieldValidation({
            ...fieldValidation,
            inputType: isInputTypeValid
        });

        setFieldJson({
            ...fieldJson,
            inputType: event.target.value,
            fieldID: sectionName + "_" + event.target.value + "_" + fieldJson.label
        });

        setShowDataGridPanel(event.target.value === "Datagrid");
    }

    function labelOnChange(event) {
        const isLabelValid = !!event.target.value && event.target.value.trim().length > 0;
        setFieldValidation({
            ...fieldValidation,
            label: isLabelValid
        });

        setFieldJson({
            ...fieldJson,
            label: event.target.value,
            fieldID: sectionName + "_" + fieldJson.inputType + "_" + event.target.value.replaceAll(' ', '')
        })
    }

    function placeholderOnChange(event) {
        setFieldJson({
            ...fieldJson,
            placeholder: event.target.value
        })
    }

    function isDisabledCheckOnChange(event) {
        setFieldJson({
            ...fieldJson,
            isDisabled: event.target.checked
        })
    }

    function isRequiredCheckOnChange(event) {
        setFieldJson({
            ...fieldJson,
            isRequired: event.target.checked
        })
    }

    function isShownCheckOnChange(event) {
        setFieldJson({
            ...fieldJson,
            isShown: event.target.checked
        })
    }

    function maxLengthOnChange(event) {
        setFieldJson({
            ...fieldJson,
            maxLength: event.target.value
        })
    }

    function minLengthOnChange(event) {
        setFieldJson({
            ...fieldJson,
            minLength: event.target.value
        })
    }
    //#endregion

    // function testOnClick() {
    //     console.log(fieldJson);
    // }

    //#region Tooltips
    const SET_DISPLAY_TOOPTIP = "setDisplayTooltip";
    const DATA_SOURCE_TOOLTIP = "dataSourceToolTip";
    const DELETE_FIELD_TOOLTIP = "deleteFieldToolTip";
    const CLONE_TOOLTIP = "cloneToolTip";
    const actionButtons = [
        {
            name: SET_DISPLAY_TOOPTIP,
            color: "success",
            icon: faTv,
            action: () => openDisplayModal(),
            toolTip: {
                target: SET_DISPLAY_TOOPTIP,
                innerText: "Set display condition for this field",
                placement: "top"
            }
        },
        {
            name: DATA_SOURCE_TOOLTIP,
            color: "success",
            icon: faCog,
            action: () => openDataSourceModal(),
            toolTip: {
                target: DATA_SOURCE_TOOLTIP,
                innerText: "Set data source for this field",
                placement: "top"
            }
        },
        {
            name: DELETE_FIELD_TOOLTIP,
            color: "success",
            icon: faTrash,
            action: () => deleteFieldOnClick(),
            toolTip: {
                target: DELETE_FIELD_TOOLTIP,
                innerText: "Delete this field",
                placement: "top"
            }
        },
        {
            name: CLONE_TOOLTIP,
            color: "success",
            icon: faCopy,
            action: () => cloneFieldOnClick(),
            toolTip: {
                target: CLONE_TOOLTIP,
                innerText: "Clone this field",
                placement: "top"
            }
        }
    ];
    //#endregion

    //#region Modal popup for Conditional Display
    const [showDisplayModal, setShowDisplayModal] = useState(false);
    function closeDisplayModal() {
        setShowDisplayModal(false);
    }
    function openDisplayModal() {
        setShowDisplayModal(true)
    };

    function removeDisplayConditionOnClick() {
        setFieldJson({
            ...fieldJson,
            displayCondition: true,
            displayConditionDetails: []
        })
        setShowDisplayModal(false);
    }
    //#endregion

    //#region Modal popup for Data Source
    const [showDataSourceModal, setShowDataSourceModal] = useState(false);
    function closeDataSourceModal() {
        setShowDataSourceModal(false)
    };
    function openDataSourceModal() {
        setShowDataSourceModal(true)
    };

    function removeDataSourceOnClick() {
        setFieldJson({
            ...fieldJson,
            dataSource: "",
            dataSourceValues: [],
            dataSourceResource: "",
            dataSourceResourceDependencyKey: "",
            dataSourceResourceDependencyValue: "",
            dataSourceCodeCat: "",
            dataSourceAppConfig: ""
        })
        setShowDataSourceModal(false);
    }
    //#endregion

    useEffect(() => {
        setIsLengthRequired(FIELD_INPUT_TYPE_LENGTH_REQUIRED.some(value => value === fieldJson.inputType));

        const newFieldArray = props.sectionJson.fields.map((field) => {
            if (field.id === fieldJson.id)
                return fieldJson;
            else
                return field;
        });
        const newFieldValidation = props.sectionValidation.fields.map((field) => {
            if (field.id === fieldValidation.id)
                return fieldValidation;
            else
                return field;
        });
        props.onFieldChange({
            ...props.sectionJson,
            fields: newFieldArray
        });
        props.onValidationChange({
            ...props.sectionValidation,
            fields: newFieldValidation
        })
    },
        // eslint-disable-next-line 
        [fieldJson]);

    //#region Data Grid setup
    const [showDataGridPanel, setShowDataGridPanel] = useState(fieldJson.inputType === "Datagrid");
    //#endregion

    return <div>
        <ButtonGroup>
            <Col xs="auto">
                <Input type="select" className="custom-border" required onChange={inputTypeSelectOnChange} value={fieldJson.inputType} valid={isValidated && !!fieldValidation?.inputType} invalid={isValidated && !fieldValidation?.inputType}>
                    <option value="" disabled>Select Input Type</option>
                    {FIELD_INPUT_TYPE_ARRAY.map((inputType, i) => {
                        return <option key={i}>{inputType}</option>
                    })}
                </Input>
            </Col>
            <Col xs="1">
                <Input ref={inputRef} name="label" placeholder="Label" className="custom-border" required value={fieldJson.label} onChange={labelOnChange} valid={isValidated && !!fieldValidation?.label} invalid={isValidated && !fieldValidation?.label}></Input>
            </Col>
            <Col xs="1">
                <Input placeholder="ID" disabled className="custom-border" value={fieldJson.fieldID}></Input>
            </Col>
            <Col xs="1">
                <Input placeholder="Place Holder" className="custom-border" value={fieldJson.placeholder} onChange={placeholderOnChange}></Input>
            </Col>
            <Col xs="1">
                <InputGroup>
                    <Input placeholder="Is Disabled?" disabled className="custom-border"></Input>
                    <Input type="checkbox" checked={fieldJson.isDisabled} onChange={isDisabledCheckOnChange}></Input>
                </InputGroup>
            </Col>
            <Col xs="1">
                <InputGroup>
                    <Input placeholder="Is Required?" disabled className="custom-border"></Input>
                    <Input type="checkbox" checked={fieldJson.isRequired} onChange={isRequiredCheckOnChange}></Input>
                </InputGroup>
            </Col>
            <Col xs="1">
                <InputGroup>
                    <Input placeholder="Is Shown?" disabled className="custom-border"></Input>
                    <Input type="checkbox" checked={fieldJson.isShown} onChange={isShownCheckOnChange}></Input>
                </InputGroup>
            </Col>
            {isLengthRequired && <Col>
                <InputGroup>
                    <InputGroupText className="custom-border">Min Length</InputGroupText>
                    <Input value={fieldJson.minLength} onChange={minLengthOnChange} className="custom-border"></Input>
                </InputGroup>
            </Col>}
            {isLengthRequired && <Col>
                <InputGroup>
                    <InputGroupText className="custom-border">Max Length</InputGroupText>
                    <Input value={fieldJson.maxLength} onChange={maxLengthOnChange} className="custom-border"></Input>
                </InputGroup>
            </Col>}
            <Col xs="auto">
                <InputGroup>
                    {actionButtons.map(({ name, color, icon, action, toolTip }) => {
                        return <OverlayTrigger key={name} {...toolTip}>
                            <Button
                                id={name}
                                color={color}
                                onClick={action}
                                outline>
                                <FontAwesomeIcon icon={icon} />
                            </Button>
                        </OverlayTrigger>
                    })}
                </InputGroup>
            </Col>
            <div className="break"></div>
            <div style={{width: "95%"}}>
                <DataGridPanel 
                show={showDataGridPanel}
                fieldJson={fieldJson}
                currentComponentID={fieldJson.fieldID}
                fieldValidation={fieldValidation}
                isValidated={isValidated}
                parentFieldID={fieldJson.fieldID}
                onDatagridChange={setFieldJson}
                onValidationChange={setFieldValidation}
            />
            </div>
        </ButtonGroup>

        <ConditionalDisplayModal
            show={showDisplayModal}
            json={fieldJson}
            onModalClose={closeDisplayModal}
            onModalReset={removeDisplayConditionOnClick}
            onModalUpdate={setFieldJson}
            globalFieldList={globalFieldList}
            currentComponentID={fieldJson.fieldID}
        />

        <DataSourceModal
            show={showDataSourceModal}
            fieldJson={fieldJson}
            onModalClose={closeDataSourceModal}
            onModalReset={removeDataSourceOnClick}
            onModalUpdate={setFieldJson}
            globalFieldList={globalFieldList}
            currentComponentID={fieldJson.fieldID}
        />

        
    </div>
}

export default Field;