import { Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { Label } from 'reactstrap';
import { LICENCE_CONFIG_USER_AGENCY_LIST } from '../../App/AppSettings';
import { getParams } from '../../Shared/Actions';
import { FIELD_SIZE } from '../../Shared/Constants';
import { useAsyncFetch } from '../../Shared/Fetch';
import { AccordionContainer, Paragraph, Radio, SelectList, Text } from '../../Shared/Forms';
import InlineInput from './InlineInput';
import { useTranslation } from 'react-i18next';
import { LANGUAGE_KEYS } from '../../Shared/Constants/LanguageKeys';
import { getLicenceTypeNameTranslationKey, getLangKey } from '../DisplayComponents/DisplayUtils';

export default function LicenceInformation(props) {
    const { t, i18n } = useTranslation();
    const { values, errors, toggleSection, onChange, onChangeField } = props.smartFormValues;
    const [agencyOptions, setAgencyOptions] = useState([]);
    const { isLicenceNameReadOnly, isAgencyReadOnly } = props;
    const licenceTypeNameTranslationKey = getLicenceTypeNameTranslationKey(values.LicenceInfo.LicenceName);
    let translatedLicenceDescr = '';
    if (i18n.exists(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCEDEFINITION_LICENCEDESCRIPTION_KEY, licenceTypeNameTranslationKey, values.LicenceDefinitionId))) {
        translatedLicenceDescr = t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCEDEFINITION_LICENCEDESCRIPTION_KEY, licenceTypeNameTranslationKey, values.LicenceDefinitionId));
    }
    const [licenceDescription, setLicenceDescription] = useState(translatedLicenceDescr);

    const SECTION_NAMES = {
        LICENCE_INFORMATION: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_CONTENT_SECTION_SERVICEINFORMATION), status: true }
    };

    const response = useAsyncFetch(LICENCE_CONFIG_USER_AGENCY_LIST, getParams());
    useEffect(() => {
        let isMounted = true;

        const fetchData = async () => {
            const { Data, IsSuccess } = response.body;
            if (response.success && IsSuccess) {
                const agencyOptionsArray = [];
                Data.forEach(element => {
                    const agencyName = t(getLangKey(LANGUAGE_KEYS.BLS_LICENCEAGENCY_AGENCY_AGENCYNAME, element.AgencyId));
                    agencyOptionsArray.push({ label: agencyName, value: element.AgencyId });
                });
                setAgencyOptions(agencyOptionsArray);
            }
        };
        if (response && isMounted) {
            fetchData();
        }
        return () => isMounted = false;
    }, [response, t]);

    const setAgencyInValues = (name, value) => {
        if (agencyOptions.length > 0) {
            const id = agencyOptions.findIndex(o => o.value === value);
            const label = t(getLangKey(LANGUAGE_KEYS.BLS_LICENCEAGENCY_AGENCY_AGENCYNAME, id));
            onChangeField('LicenceInfo.Agency', label);
            onChangeField('LicenceInfo.AgencyId', value);
        }
    };

    const licenceName = (licenceTypeId) => {
        if (licenceTypeId) {
            return t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY, licenceTypeNameTranslationKey, licenceTypeId));
        } else {
            return values.LicenceInfo.LicenceName;
        }
    };

    const onChangeDescription = (e) => {
        setLicenceDescription(e.target.value)
        values.LicenceInfo.LicenceDescription = e.target.value;
    }

    return (
        <React.Fragment>
            <AccordionContainer
                title={SECTION_NAMES.LICENCE_INFORMATION.title}
                active={SECTION_NAMES.LICENCE_INFORMATION.status}
                onClick={toggleSection}
                isIndividual={true}
                sections={SECTION_NAMES}
            >
                <Text
                    name='LicenceInfo.LicenceName'
                    value={licenceName(values.LicenceTypeId)}
                    placeholder={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_PLACEHOLDER_SERVICENAME)}
                    onChange={onChange}
                    minLength={0}
                    maxLength={140}
                    inputSize={FIELD_SIZE.LARGE}
                    label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_FIELD_SERVICENAME)}
                    error={errors.LicenceInfo && errors.LicenceInfo.LicenceName}
                    required
                    readOnly={isLicenceNameReadOnly}
                />
                <Paragraph
                    name='LicenceInfo.LicenceDescription'
                    value={licenceDescription}
                    placeholder={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_PLACEHOLDER_SERVICEDESCRIPTION)}
                    onChange={(e) => onChangeDescription(e)}
                    minLength={0}
                    inputSize={FIELD_SIZE.LARGE}
                    label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_FIELD_SERVICEDESCRIPTION)}
                    error={errors.LicenceInfo && errors.LicenceInfo.LicenceDescription}
                    required
                />
                <SelectList
                    isClearable={false}
                    name='LicenceInfo.AgencyId'
                    value={values.LicenceInfo.AgencyId}
                    placeholder={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_PLACEHOLDER_AGENCY)}
                    minLength={0}
                    maxLength={140}
                    inputSize={FIELD_SIZE.MEDIUM}
                    label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_FIELD_AGENCY)}
                    required
                    isDisabled={isAgencyReadOnly}
                    options={agencyOptions}
                    onChangeField={(name, value) => setAgencyInValues(name, value)}
                    isMulti={false}
                    error={errors.LicenceInfo && errors.LicenceInfo.AgencyId}
                />
                <Radio
                    name='LicenceInfo.IsOnline'
                    value={values.LicenceInfo.IsOnline}
                    options={[
                        { label: t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_FIELD_YES), value: 'true' },
                        { label: t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_FIELD_NO), value: 'false' }
                    ]}
                    onChange={e => {
                        onChange(e);
                    }}
                    inputSize={7}
                    label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_FIELD_ISONLINE)}
                    error={errors.LicenceInfo && errors.LicenceInfo.IsOnline}
                    required
                />
                {values.LicenceInfo.IsOnline !== 'true' &&
                    <Text
                        name='LicenceInfo.ExternalLink'
                        value={values.LicenceInfo.ExternalLink}
                        placeholder={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_PLACEHOLDER_EXTERNALLINK)}
                        onChange={onChange}
                        minLength={0}
                        maxLength={140}
                        inputSize={FIELD_SIZE.LARGE}
                        label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_FIELD_EXTERNALLINK)}
                        error={errors.LicenceInfo && errors.LicenceInfo.ExternalLink}
                        required
                    />
                }
                {values.LicenceInfo.IsOnline === 'true' &&
                    <div>

                        <Text
                            name='LicenceInfo.FeeDescription'
                            value={values.LicenceInfo.FeeDescription}
                            placeholder={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_FIELD_SERVICEFEEDESC)}
                            onChange={onChange}
                            minLength={0}
                            maxLength={140}
                            inputSize={FIELD_SIZE.LARGE}
                            label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_FIELD_SERVICEFEEDESC)}
                            error={errors.LicenceInfo && errors.LicenceInfo.FeeDescription}
                            required
                        />
                        <Text
                            name='LicenceInfo.ProcessTimeDescription'
                            value={values.LicenceInfo.ProcessTimeDescription}
                            placeholder={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_PLACEHOLDER_NUMBEROFWORKINGDAYS)}
                            onChange={onChange}
                            minLength={0}
                            maxLength={140}
                            inputSize={FIELD_SIZE.LARGE}
                            label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_FIELD_SERVICEPROCESSTIME)}
                            error={errors.LicenceInfo && errors.LicenceInfo.ProcessTimeDescription}
                            helpLabel={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_PLACEHOLDER_WORKINGDAYS)}
                            required
                        />
                        <Text
                            name='LicenceInfo.LicencePrefix'
                            value={values.LicenceInfo.LicencePrefix}
                            placeholder={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_FIELD_SERVICEPREFIX)}
                            onChange={onChange}
                            minLength={0}
                            maxLength={3}
                            inputSize={FIELD_SIZE.LARGE}
                            label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_FIELD_SERVICEPREFIX)}
                            error={errors.LicenceInfo && errors.LicenceInfo.LicencePrefix}
                            required
                        />
                        <Text
                            name='LicenceInfo.RFAResponsePeriod'
                            value={values.LicenceInfo.RFAResponsePeriod}
                            placeholder={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_PLACEHOLDER_NUMBEROFWORKINGDAYS)}
                            onChange={onChange}
                            minLength={0}
                            maxLength={140}
                            inputSize={FIELD_SIZE.SMALL}
                            label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_FIELD_RFARESPONSEPERIOD)}
                            error={errors.LicenceInfo && errors.LicenceInfo.RFAResponsePeriod}
                            helpLabel={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_PLACEHOLDER_WORKINGDAYS)}
                            required
                        />
                        <Text
                            name='LicenceInfo.PaymentPeriod'
                            value={values.LicenceInfo.PaymentPeriod}
                            placeholder={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_PLACEHOLDER_NUMBEROFWORKINGDAYS)}
                            onChange={onChange}
                            minLength={0}
                            maxLength={140}
                            inputSize={FIELD_SIZE.SMALL}
                            label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_FIELD_DAYSTOMAKEPAYMENT)}
                            error={errors.LicenceInfo && errors.LicenceInfo.PaymentPeriod}
                            helpLabel={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_PLACEHOLDER_WORKINGDAYS)}
                            required
                        />
                        <Text
                            name='LicenceInfo.TerminationMinPeriod'
                            value={values.LicenceInfo.TerminationMinPeriod}
                            placeholder={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_PLACEHOLDER_NUMBEROFDAYS)}
                            onChange={onChange}
                            minLength={0}
                            maxLength={140}
                            inputSize={FIELD_SIZE.SMALL}
                            label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_FIELD_TERMINATIONMINPERIOD)}
                            error={errors.LicenceInfo && errors.LicenceInfo.TerminationMinPeriod}
                            helpLabel={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_PLACEHOLDER_DAYS)}
                            required
                        />
                        <InlineInput label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEEXTENSION_FORM_FIELD_SERVICEVALIDITYPERIOD)}>
                            <div className='col-lg-4 licence-config-year-input'>
                                <Input
                                    type='text'
                                    name='LicenceInfo.ValidityYear'
                                    value={values.LicenceInfo.ValidityYear}
                                    placeholder={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_PLACEHOLDER_YEAR)}
                                    onChange={onChange}
                                    minLength={0}
                                    maxLength={140}
                                    error={errors.LicenceInfo && errors.LicenceInfo.ValidityYear}
                                />
                                <div>
                                    <Label className='label-feedback'>
                                        {errors.LicenceInfo && errors.LicenceInfo.ValidityYear}
                                    </Label>
                                </div>
                            </div>
                            <Label className='label-default col-form-label'>{t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_PLACEHOLDER_YEAR)}</Label>
                            <div className='col-lg-4'>
                                <Input
                                    type='text'
                                    name='LicenceInfo.ValidityMonth'
                                    value={values.LicenceInfo.ValidityMonth}
                                    placeholder={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_PLACEHOLDER_MONTH)}
                                    onChange={onChange}
                                    minLength={0}
                                    maxLength={140}
                                    autoComplete='off'
                                    error={errors.LicenceInfo && errors.LicenceInfo.ValidityMonth}
                                />
                                <div>
                                    <Label className='label-feedback'>
                                        {errors.LicenceInfo && errors.LicenceInfo.ValidityMonth}
                                    </Label>
                                </div>
                            </div>
                            <Label className='label-default col-form-label'>{t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_PLACEHOLDER_MONTH)}</Label>
                            <div className='col-lg-4'>
                                <Input
                                    type='text'
                                    name='LicenceInfo.ValidityDay'
                                    value={values.LicenceInfo.ValidityDay}
                                    placeholder={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_PLACEHOLDER_DAY)}
                                    onChange={onChange}
                                    minLength={0}
                                    maxLength={140}
                                    autoComplete='off'
                                    error={errors.LicenceInfo && errors.LicenceInfo.ValidityDay}
                                />
                                <div>
                                    <Label className='label-feedback'>
                                        {errors.LicenceInfo && errors.LicenceInfo.ValidityDay}
                                    </Label>
                                </div>
                            </div>
                            <Label className='label-default col-form-label'>{t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_PLACEHOLDER_DAY)}</Label>
                        </InlineInput>
                        <Radio
                            name='LicenceInfo.IsRenewable'
                            value={values.LicenceInfo.IsRenewable}
                            options={[
                                { label: t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_FIELD_YES), value: 'true' },
                                { label: t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_FIELD_NO), value: 'false' }]}
                            onChange={e => {
                                onChange(e);
                            }}
                            inputSize={7}
                            label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_FIELD_CANBERENEWED)}
                            error={errors.LicenceInfo && errors.LicenceInfo.IsRenewable}
                            required
                        />

                        {values.LicenceInfo.IsRenewable === 'true' &&
                            <div>
                                <Text
                                    name='LicenceInfo.RenewalPeriod'
                                    value={values.LicenceInfo.RenewalPeriod}
                                    placeholder={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_PLACEHOLDER_NUMBEROFDAYS)}
                                    onChange={onChange}
                                    minLength={0}
                                    maxLength={140}
                                    inputSize={FIELD_SIZE.SMALL}
                                    label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_FIELD_RENEWALPERIOD)}
                                    error={errors.LicenceInfo && errors.LicenceInfo.RenewalPeriod}
                                    helpLabel={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_PLACEHOLDER_DAYS)}
                                    required
                                />
                                <Text
                                    name='LicenceInfo.LatePenaltyPeriod'
                                    value={values.LicenceInfo.LatePenaltyPeriod}
                                    placeholder={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_PLACEHOLDER_NUMBEROFDAYS)}
                                    onChange={onChange}
                                    minLength={0}
                                    maxLength={140}
                                    inputSize={FIELD_SIZE.SMALL}
                                    label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_FIELD_LATEPENALTYPERIOD)}
                                    error={errors.LicenceInfo && errors.LicenceInfo.LatePenaltyPeriod}
                                    helpLabel={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_PLACEHOLDER_DAYS)}
                                    required
                                />
                            </div>
                        }
                    </div>
                }
            </AccordionContainer>
        </React.Fragment>
    );
}
