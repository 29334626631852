import React from 'react';
import { withRouter } from 'react-router-dom';
import { CODES_LIST, CODETABLE_EDIT_URL } from '../../App/AppSettings';
import history from '../../Shared/History';
import { withLoader } from '../../Shared/Loading';
import { withSMARTWrapper } from '../../Shared/Forms';
import { DEFAULT_TEXT, DEFAULT_NAMESPACE } from '../../Shared/Constants/LanguageKeys.js';
import Form from './Form.js';

const SERVER_URL_EDIT = CODETABLE_EDIT_URL;

class Update extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
        }
    }
    updateDisplayValue = (checkValue, displayValue, isChangeToString) => {
        return checkValue === undefined || checkValue === null || checkValue === "-" ? "" : isChangeToString ? displayValue.toString() : displayValue;
    }
    async componentDidMount() {
        let data = history.location.state.Data;
        this.setState({
            values: {
                id: data.ID,
                CodeTableCategory: data.CodeCategory,
                CodeTableTitle: data.CodeTitle,
                CodeTableValue: data.CodeValue,
                CodeTableDescription: this.updateDisplayValue(data.CodeDescription, data.CodeDescription),
                CodeTableStatus: data.IsActive,
                CodeTableEffectiveStartDate: this.updateDisplayValue(data.EffectiveStartDate, data.EffectiveStartDateFormatted),
                CodeTableEffectiveEndDate: this.updateDisplayValue(data.EffectiveEndDate, data.EffectiveEndDateFormatted),
                CodeTableSequenceNumber: data.SequenceNumber,
                CodeTableParentCategory: this.updateDisplayValue(data.ParentCodeCategory, data.ParentCodeCategory),
                CodeTableParent: this.updateDisplayValue(data.ParentId, data.ParentId, true)
            },
            isLoaded: true
        });
    }

    render() {
        return (
            <React.Fragment>
                {this.state.isLoaded ?
                    (<Form
                        values={this.state.values}
                        methodName="PUT"
                        server_url={SERVER_URL_EDIT + history.location.state.ID}
                        title={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_CODESTATIC_CONTENT_TITLE_UPDATECODETABLEENTRY, DEFAULT_TEXT.UPDATE_CODE)}
                        contentHeader={"Update Code Table Confirmation"}
                        contentBody={"Confirm update of Code Table?"}
                        next_url={CODES_LIST}
                        isUpdate={true}
                        submitBtnLabel={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_CODESTATIC_NAVIGATION_BUTTON_SAVECHANGES, DEFAULT_TEXT.UPDATE)}
                    />)
                    : null}
            </React.Fragment>
        )
    }
}

export default withSMARTWrapper(withLoader(withRouter(Update)));