
import { GET_CODES_BY_CODE_CATEGORIES } from '../../App/AppSettings';
import { fetchRequest, getParams } from '../../Shared/Actions';
import { ERROR, getLangKey } from '../../Shared/Constants/LanguageKeys';
import { toastError } from '../../Shared/Forms/Toaster';

//get the options required and store them in a dictionary in the label,value,key format that is use by the form inputs
export const getRemoteList = async (t, fields) => {
    let CODE_URL = `${GET_CODES_BY_CODE_CATEGORIES}?`;
    fields.forEach(field => {
        CODE_URL += `categoryList=${field}&`;
    });

    const response = await fetchRequest(CODE_URL, getParams());
    const { IsSuccess, Data, Messages } = response.body;
    const optionObj = {};

    if (response.success && IsSuccess) {
        const codes = Data;
        fields.forEach(field => {
            const options = [];

            codes.forEach(code => {
                if (code.CodeTableCategory === field) {
                    options.push({
                        label: code.CodeTitle,
                        value: code.CodeTitle,
                        key: code.CodeTitle
                    });
                }
            });
            optionObj[field] = options;
        });
    } else {
        toastError(t(getLangKey(ERROR.BACKEND_ERROR_MESSAGE, Messages)), Messages);
    }
    return optionObj;
};

export const getTableListValues = (tableValueKeys, sectionName) => {
    const listOfTable = [];
    let tableIndex = 0;
    const sectionObjTable = {};
    tableValueKeys && tableValueKeys.forEach(tv => {
        if (`${tv.split('.')[0]}` === sectionName &&
            `${tv.split('.')[0]}.${tv.split('.')[1]}` === `${sectionName}.${tableIndex}` &&
            listOfTable.indexOf(`${tv.split('.')[0]}.${tv.split('.')[1]}`) < 0) {
            listOfTable.push(`${tv.split('.')[0]}.${tv.split('.')[1]}`);
            tableIndex++;
        }
    });
    if (listOfTable.length !== 0) {
        sectionObjTable[sectionName] = listOfTable;
        return sectionObjTable;
    } else {
        return null;
    }
};

export const additionalFormRegex = value => {
    value = value.replace(/[&/`\\#,+()$~%.'":*?<>{}]/g, '').replace(/ /g, '_');
    return value;
};
