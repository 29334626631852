import { default as React } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { CHECK_PAYMENT_ORDERS_URL, GET_MAKE_PAYMENT_DETAIL_URL, IS_DEMO, LAYOUT_TYPE, PATH } from '../../../App/AppSettings';
import Layout from '../../../Layout';
import Section from '../../../Section';
import SectionWrapper from '../../../SectionWrapper';
import { fetchRequest, getParams, navigateTo, postParams } from '../../../Shared/Actions';
import { SECTION_WRAPPER_TYPES } from '../../../Shared/Constants';
import { ERROR, getLangKey, LANGUAGE_KEYS } from '../../../Shared/Constants/LanguageKeys';
import { ScrollToTop, SMARTForm, withSMARTWrapper } from '../../../Shared/Forms';
import history from '../../../Shared/History';
import PageFooter from '../../../Shared/PageFooter';
import TitleChildren from '../../../Title/TitleChildren';
import { getLicenceTypeNameTranslationKey } from '../../DisplayComponents/DisplayUtils';
import PaymentSummary from '../../FormComponents/PaymentSummary';


const SECTION_LAYOUT_TYPE = SECTION_WRAPPER_TYPES.ACCORDION;
const SECTION_LAYOUT_TYPE_WIZARD = SECTION_WRAPPER_TYPES.WIZARD;

const INITIAL_VALUES = 0;
const MAKE_PAYMENT_PATH = PATH.MAKE_PAYMENT;
const CANCEL_PATH = PATH.PAYMENT_INBOX;
const GET_MAKE_PAYMENT_REQUEST_URL = GET_MAKE_PAYMENT_DETAIL_URL;
const CHECK_PAYMENT_URL = CHECK_PAYMENT_ORDERS_URL;

const SECTION_NAME_PAYMENT = 'Payments';


class MakePaymentSummary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formNames: {
                tab: ''
            },
            titleData: {
                formName: '',
                applicationType: '',
                referenceNumber: '',
                status: '',
                licenceTypeId: null
            }
        };
    }
    async componentDidMount() {
        this.getFees();
    }

    getFees = async () => {
        // Start loading
        const { loader, t } = this.props;
        loader.start();

        let referenceNumberValue = INITIAL_VALUES;
        let titleData = {};

        //get the params
        if (history.location.state !== undefined && history.location.state.Data !== undefined) {
            referenceNumberValue = history.location.state.Data.referenceNumber;
            titleData = history.location.state.Data;
        }
        else {
            //show error page
            loader.done();
            alert(t(ERROR.CANNOT_GET_PAYMENT_DETAILS));
            return;
        }

        const queryString = Object.assign({}, { referenceNumber: referenceNumberValue });
        const response = await fetchRequest(GET_MAKE_PAYMENT_REQUEST_URL, getParams(queryString), IS_DEMO);

        const { Data, IsSuccess } = response.body;

        if (IsSuccess) {

            this.setState(() => {
                return {
                    licenceFees: Data.LicenceFeeItemList,
                    totalLicenceFee: Data.TotalLicenceFee,
                    titleData
                };
            });

            loader.done();
        }
        else {
            //show error page
            loader.done();
            alert(t(ERROR.PAYMENT_DETAILS_NOT_FETCHED));
        }

        // End loading
    };

    renderTitleChildren = () => {
        const { t } = this.props;
        const { formName, applicationTypeCodeName, referenceNumber, status, licenceTypeId } = this.state.titleData;
        const licenceTypeNameTranslationKey = getLicenceTypeNameTranslationKey(formName);
        
        return (
            <TitleChildren
                items={[
                    { label: t(LANGUAGE_KEYS.BLS_INTERNALPAYMENTINBOX_CONTENT_FIELD_SERVICENAME), value: t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY, licenceTypeNameTranslationKey, licenceTypeId)), type: 'textMobileShow' },
                    { label: t(LANGUAGE_KEYS.BLS_INTERNALPAYMENTINBOX_CONTENT_FIELD_APPLICATIONTYPE), value: t(getLangKey(LANGUAGE_KEYS.BLS_CODE_LICENCEAPPLICATIONTYPE_TITLE_KEY, applicationTypeCodeName)), type: 'textMobileShow' },
                    { label: t(LANGUAGE_KEYS.BLS_INTERNALPAYMENTINBOX_CONTENT_FIELD_REF), value: referenceNumber, type: 'textMobileShow' },
                    { label: t(LANGUAGE_KEYS.BLS_INTERNALPAYMENTINBOX_CONTENT_FIELD_STATUS), value: status, type: 'badge' }
                ]}
            >
            </TitleChildren>
        );
    };


    checkOrders = async (submissionNumber, referenceNumber) => {

        const queryString = Object.assign({}, { referenceNumber: referenceNumber });

        const response = await fetchRequest(CHECK_PAYMENT_URL, postParams('', queryString), IS_DEMO);
        const { IsSuccess, Messages } = response.body;

        if (IsSuccess) {
            navigateTo(MAKE_PAYMENT_PATH, {
                Data: {
                    SubmissionNumber: submissionNumber,
                    ReferenceNumber: referenceNumber
                }
            });
        } else {
            alert(Messages);
        }
    };

    render() {
        const { t } = this.props;
        return (
            <React.Fragment >
                <ScrollToTop />
                {this.state.titleData.licenceTypeId &&
                    <SMARTForm
                        //className="container margin-only-40"
                        //formContext={FORM_CONTEXT}
                        sectionNames={this.state.formNames}
                        defaultSection={SECTION_NAME_PAYMENT}
                        validateOnSubmit={false}
                    >
                        {({ toggleSection, sectionState }) => {
                            return (
                                <React.Fragment >
                                    <Layout type={LAYOUT_TYPE.FORM} title={t(LANGUAGE_KEYS.BLS_INTERNALPAYMENTINBOX_CONTENT_TITLE_PAYAPPLICATION)} footer={false} titleClassName="sticky-title" titleChildren={this.renderTitleChildren()}>
                                        <Row className="body-content internal-content">
                                            <Col className="body-section-col">
                                                <SectionWrapper
                                                    type={SECTION_LAYOUT_TYPE}
                                                    tabs={this.state.formNames}
                                                    sectionState={sectionState}
                                                    toggleSection={toggleSection}>

                                                    {/* Payment Section */}
                                                    <Section
                                                        type={SECTION_LAYOUT_TYPE_WIZARD}
                                                        sectionState={sectionState}
                                                        toggleSection={toggleSection}
                                                        sectionName={SECTION_NAME_PAYMENT}
                                                        key="Payments">
                                                        {this.state.licenceFees &&
                                                            <PaymentSummary
                                                                licenceFees={this.state.licenceFees}
                                                                totalLicenceFee={this.state.totalLicenceFee}
                                                            />
                                                        }
                                                    </Section>
                                                </SectionWrapper>
                                            </Col>

                                            <PageFooter type={LAYOUT_TYPE.FORM}>
                                                <div className="button-center">

                                                    {/* Cancel Button */}
                                                    <Button color="backward" size="sm" type="button" onClick={() => navigateTo(CANCEL_PATH)}>{t(LANGUAGE_KEYS.BLS_INTERNALPAYMENTINBOX_CONTENT_BUTTON_CANCEL)}</Button>

                                                    <Button color="forward" size="sm" type="button"
                                                        onClick={() => this.checkOrders(this.state.titleData.submissionNumber, this.state.titleData.referenceNumber)}>{t(LANGUAGE_KEYS.BLS_INTERNALPAYMENTINBOX_CONTENT_BUTTON_RECEIVEPAYMENT)}
                                                    </Button>
                                                </div>
                                            </PageFooter>
                                        </Row>
                                    </Layout>

                                </React.Fragment >
                            );
                        }}
                    </SMARTForm>
                }
            </React.Fragment >
        );
    }
}

export default withTranslation()(withRouter(withSMARTWrapper(MakePaymentSummary)));
