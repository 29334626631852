import 'core-js/es6/object';
import 'core-js/es7/object'; // ES7 Object needed
import React from 'react';
import { Router } from 'react-router-dom';
import Content from '../Content';
import { AuthProvider } from '../Shared/Authentication/AuthContext';
import history from '../Shared/History';
import Css from '../Css';

class App extends React.Component {
  render() {
    return (
      <Router history={history}>
        <AuthProvider>
            <Content/>
            <Css/>
        </AuthProvider>
      </Router>
    );
  }
}

export default App;