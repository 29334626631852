import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { GET_DOCUMENT_NAMES_URL, INBOX_PATH, POST_ACTION_URL } from '../../../../../App/AppSettings';
import { isValidForm, navigateTo } from '../../../../../Shared/Actions';
import { FIELD_SIZE } from '../../../../../Shared/Constants';
import { ERROR, LANGUAGE_KEYS, SUCCESS } from '../../../../../Shared/Constants/LanguageKeys';
import { getParams, useAsyncFetch } from '../../../../../Shared/Fetch';
import { Paragraph, SelectList, SMARTForm, toastError, toastSuccess } from '../../../../../Shared/Forms';
import history from '../../../../../Shared/History';
import { ModalConfirm } from '../../../../../Shared/Modal';
import { getLangKey } from '../../../../DisplayComponents/DisplayUtils';

const POST_URL = POST_ACTION_URL;
const NEXT_URL = INBOX_PATH;
const SUPPORTING_DOCS_URL = GET_DOCUMENT_NAMES_URL;

const FORM_CONTEXT = "SubmitClarification";

const IS_DEMO = false;

const MODAL_NAMES = {
    SUBMIT: "Submit"
};

export default function SubmitClarification(props) {

    const { state } = history.location;

    const command = props.command;
    const modal = props.modal;
    const isCheckOut = props.isCheckOut;
    const clearCommand = props.clearCommand;

    const [supportingDocs, setSupportingDocsData] = useState([]);
    const [on, setOn] = useState(command && modal && isCheckOut);

    const actionResponse = useAsyncFetch(SUPPORTING_DOCS_URL + state.ID, getParams());

    const { t } = useTranslation();
    
    const FORM_VALIDATIONS = Yup.object().shape({
        Remarks: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED))
    });

    useEffect(() => {
        async function setSupportingDocs() {
            const { IsSuccess, Messages, Data } = actionResponse.body;
            if (IsSuccess) {
                setSupportingDocsData(Data)
            } else {
                // Error message to be loaded here
                toastError(t(getLangKey(ERROR.BACKEND_ERROR_MESSAGE, Messages)), Messages);
            }
        }

        if (actionResponse) {
            setSupportingDocs();
        }

        const toggle = () => setOn(!on);

        if (on) {
            modal.toggleModal(MODAL_NAMES.SUBMIT);
            toggle();
        }
    }, [actionResponse, on, modal, t])

    const FORM_INITIAL_VALUES = {
        command: command,
        Remarks: '',
        formID: state.ID
    }

    const prepareToSubmit = async (modal, _submitForm, values) => {
        if (isValidForm(FORM_VALIDATIONS, values)) {
            const additionalDocuments = values.AdditionalDocuments;
            if (additionalDocuments !== undefined) {
                values.workflowParams = { 'AdditionalDocuments': JSON.stringify(additionalDocuments) };
            }
            modal.toggleModal(MODAL_NAMES.SUBMIT);
        }
        _submitForm();
    }

    const submitCallback = async ({ response }) => {
        if (response.success) {
            const { IsSuccess, RedirectURL } = response.body;
            if (IsSuccess) {
                if (RedirectURL) {
                    navigateTo(RedirectURL, "");
                } else {
                    navigateTo(NEXT_URL, "");
                }
                toastSuccess(t(SUCCESS.FORM_PROCESS_SUCCESS));
            }
            else {
                toastError(t(ERROR.FORM_NOT_PROCESSED));
            }
        } else {
            toastError(t(ERROR.SERVER_UNREACHABLE))
        }
    }

    const displayModalContent = (values, errors, onChange, onChangeField) => {
        let supportingDocValues = [];
        supportingDocs.forEach((doc) => {
            supportingDocValues.push({ label: doc, value: doc });
        })
        return (
            <>
                <Paragraph
                    name="Remarks"
                    value={values.Remarks}
                    placeholder={t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_TABLE_INFORMATION_REMARKS)}
                    onChange={onChange}
                    rows={3}
                    minLength={0}
                    maxLength={140}
                    inputSize={12}
                    labelSize={FIELD_SIZE.XSMALL}
                    label={t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_TABLE_INFORMATION_REMARKS)}
                    error={errors.Remarks}
                    required
                />
                <SelectList
                    name="AdditionalDocuments"
                    value={values.AdditionalDocuments}
                    options={supportingDocValues}
                    onChangeField={onChangeField}
                    isMulti={true}
                    labelSize={12}
                    inputSize={12}
                    label={t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_CONTENT_TITLE_ADDITIONALSUPPORTINGDOCUMENTS)}
                />
            </>
        );
    }

    return (
        <SMARTForm
            formContext={FORM_CONTEXT}
            formValues={FORM_INITIAL_VALUES}
            validationSchema={FORM_VALIDATIONS}
            validateOnSubmit={true}
            serverURL={POST_URL}
            isDummy={IS_DEMO}
            submitCallback={submitCallback}
        >
            {({ values, errors, onChange, submitForm, onChangeField }) => {

                return (
                    <>
                        {modal &&
                            <ModalConfirm
                                className="modal-remarks"
                                isOpen={modal.modalState === MODAL_NAMES.SUBMIT}
                                contentHeader={t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_CONTENT_TITLE_REQUESTFORCLARIFICATION)}
                                confirmText={t(LANGUAGE_KEYS.BLS_INTERNALGENERAL_FORM_BUTTON_CONFIRM)}
                                confirmCallback={() => { prepareToSubmit(modal, submitForm, values); }}
                                cancelText={t(LANGUAGE_KEYS.BLS_INTERNALGENERAL_FORM_BUTTON_CANCEL)}
                                cancelCallback={() => {
                                    modal.toggleModal(MODAL_NAMES.SUBMIT);
                                    setTimeout(function () { clearCommand(); }, 1000);
                                }}
                                contentBody={
                                    displayModalContent(values, errors, onChange, onChangeField)
                                }
                            />
                        }
                    </>
                )
            }}
        </SMARTForm>
    )
}