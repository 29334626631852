import { faEye, faUserEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import { CODETABLE_LIST_URL, GET_PREVIEW_SERVICE, GET_PREVIEW_SERVICE_SAC, GET_PREVIEW_SERVICE_SC, GET_PREVIEW_SERVICE_TEST_ACCOUNT_BY_LICENCE_TYPE_ID, GET_PREVIEW_SERVICE_TEST_ACCOUNT_CONFIG, LAYOUT_TYPE } from '../../App/AppSettings';
import Layout from '../../Layout';
import { getParams, getRequest } from '../../Shared/Actions';
import { PREVIEW_SERVICE_STATUS } from '../../Shared/Constants';
import { ERROR, LANGUAGE_KEYS } from '../../Shared/Constants/LanguageKeys';
import { ActionButton, DataTable } from '../../Shared/DataTable';
import { getCustomComponents } from '../../Shared/DataTable/BaseTable.js';
import { useAsyncFetch } from '../../Shared/Fetch';
import { Checkbox, toastError } from '../../Shared/Forms';
import { Modal } from '../../Shared/Modal';
import { getLangKey, getLicenceTypeNameTranslationKey } from '../DisplayComponents/DisplayUtils';
import ConfigurationDetailModal from './ConfigurationDetailModal';
import TestAccountModal from './TestAccount/TestAccountModal';

const MODAL_NAMES = {
    PREVIEW_SERVICE_POP_UP: 'Preview_Service_Pop_Up',
    PREVIEW_SERVICE_TEST_ACCOUNT_ADD_POP_UP: 'Preview_Service_Test_Account_Add_Pop_Up',
    PREVIEW_SERVICE_TEST_ACCOUNT_EDIT_POP_UP: 'Preview_Service_Test_Account_Edit_Pop_Up'
};

const USER_TYPE = {
    INTERNAL: 'Internal',
    PUBLIC: 'External'
};


export default function Preview(props) {
    const { t } = useTranslation();
    const { loader } = props;
    const { values, onChangeField } = props.smartFormValues;
    const [data, setData] = useState(props.data);
    const [lcDetailData, setLCDetailData] = useState([]);
    const [lacDetailData, setLACDetailData] = useState([]);
    const [headerAgencyName, setHeaderAgencyName] = useState('');
    const [headerLicenceName, setHeaderLicenceName] = useState('');
    const [licenceTypeId, setLicenceTypeId] = useState('');
    const [userTypes, setUserTypes] = useState([]);
    const [testAccountData, setTestAccountData] = useState([]);
    const [completeTestByDate, setCompleteTestByDate] = useState('');
    const [isEditTestAccounts, setIsEditTestAccounts] = useState(false);
    const userTypesResponse = useAsyncFetch(CODETABLE_LIST_URL, getParams({ codeCategoryFilter: 'UserType' }));
    const [workflowRoles, setWorkflowRoles] = useState([]);
    const workflowRolesResponse = useAsyncFetch(GET_PREVIEW_SERVICE_TEST_ACCOUNT_CONFIG, getParams({ category: 'Workflow_RoleGuid' }));

    const reloadData = async () => {
        const response = await getRequest(`${GET_PREVIEW_SERVICE}Staging`, getParams());
        const { IsSuccess, Messages, Data } = response.body;

        if (IsSuccess) {
            setData(Data);
        } else {
            toastError(t(getLangKey(ERROR.BACKEND_ERROR_MESSAGE, Messages)), Messages);
        }
    };

    useEffect(() => {
        const fetchUserTypesData = async () => {
            if (userTypesResponse.success && userTypesResponse.body.IsSuccess) {
                const { Data } = userTypesResponse.body;

                setUserTypes((prev) => ([...prev, ...Data.Data.map(d => {
                    return { label: d.CodeValue, value: d.CodeValue };
                })]));
            }
        };

        if (userTypesResponse) {
            fetchUserTypesData();
        }

    }, [userTypesResponse]);

    useEffect(() => {
        const fetchWorkflowRolesData = async () => {
            if (workflowRolesResponse.success && workflowRolesResponse.body.IsSuccess) {
                const { Data } = workflowRolesResponse.body;
                setWorkflowRoles((prev) => ([...prev, ...Data.map(d => {
                    return { label: d.Value, value: d.Title, userType: (d.Value === 'Public User' ? USER_TYPE.PUBLIC : USER_TYPE.INTERNAL) };
                })]));
            }
        };
        if (workflowRolesResponse) {
            fetchWorkflowRolesData();
        }
    }, [workflowRolesResponse]);


    const openAddTestAccountViewModal = async (modal, licenceName, agencyName, licenceTypeId) => {
        setHeaderAgencyName(agencyName);
        setHeaderLicenceName(licenceName);
        setLicenceTypeId(licenceTypeId);
        const response = await getRequest(`${GET_PREVIEW_SERVICE_TEST_ACCOUNT_BY_LICENCE_TYPE_ID}?`, getParams({ licenceTypeId }));
        const { IsSuccess, Messages, Data } = response.body;
        if (IsSuccess) {
            setTestAccountData(() => ([...[], ...Data.map(d => {
                return {
                    UserType: d.UserType,
                    Username: d.Username,
                    Status: d.Status,
                    Email: d.Email,
                    WorkflowUserRoleGuid: d.WorkflowUserRoleGuid,
                    LicenceTypeId: licenceTypeId,
                    IsDeleted: false
                };
            })]));
            if (Data && Data.length > 0) {
                setCompleteTestByDate(Data[0].CompleteTestByDate);
            } else {
                setCompleteTestByDate('');
            }
        } else {
            toastError(t(getLangKey(ERROR.BACKEND_ERROR_MESSAGE, Messages)), Messages);
        }
        modal.toggleModal(MODAL_NAMES.PREVIEW_SERVICE_TEST_ACCOUNT_ADD_POP_UP);
    };

    const openEditTestAccountViewModal = async (modal, licenceName, agencyName, licenceTypeId) => {
        setHeaderAgencyName(agencyName);
        setHeaderLicenceName(licenceName);
        setLicenceTypeId(licenceTypeId);
        setIsEditTestAccounts(true);
        const response = await getRequest(`${GET_PREVIEW_SERVICE_TEST_ACCOUNT_BY_LICENCE_TYPE_ID}?`, getParams({ licenceTypeId: licenceTypeId }));
        const { IsSuccess, Messages, Data } = response.body;
        if (IsSuccess) {
            setTestAccountData(() => ([...[], ...Data.map(d => {
                return {
                    UserType: d.UserType,
                    Username: d.Username,
                    Status: d.Status,
                    Email: d.Email,
                    WorkflowUserRoleGuid: d.WorkflowUserRoleGuid,
                    LicenceTypeId: licenceTypeId,
                    IsDeleted: false
                };
            })]));
            if (Data && Data.length > 0) {
                setCompleteTestByDate(Data[0].CompleteTestByDate);
            }
        } else {
            toastError(t(getLangKey(ERROR.BACKEND_ERROR_MESSAGE, Messages)), Messages);
        }
        modal.toggleModal(MODAL_NAMES.PREVIEW_SERVICE_TEST_ACCOUNT_EDIT_POP_UP);
    };

    const openLicenceViewModal = async (modal, licenceName, agencyName) => {
        loader.start();
        const response = await getRequest(`${GET_PREVIEW_SERVICE_SC}${PREVIEW_SERVICE_STATUS.STAGING}/${licenceName}`, getParams());
        const { IsSuccess, Messages, Data } = response.body;
        setHeaderAgencyName(agencyName);
        setHeaderLicenceName(licenceName);
        setLicenceTypeId(licenceTypeId);
        if (IsSuccess) {
            setLCDetailData(Data);
        } else {
            toastError(t(getLangKey(ERROR.BACKEND_ERROR_MESSAGE, Messages)), Messages);
        }
        getLicenceAppConfigDetail(licenceName);
        modal.toggleModal(MODAL_NAMES.PREVIEW_SERVICE_POP_UP);
        // End loading
        loader.done();
    };

    const getLicenceAppConfigDetail = async (licenceName) => {
        const response = await getRequest(`${GET_PREVIEW_SERVICE_SAC}${PREVIEW_SERVICE_STATUS.STAGING}/${licenceName}`, getParams());
        const { IsSuccess, Messages, Data } = response.body;

        if (IsSuccess) {
            setLACDetailData(Data);
        } else {
            toastError(t(getLangKey(ERROR.BACKEND_ERROR_MESSAGE, Messages)), Messages);
        }
    };

    const setSelectedCheckbox = (name, value) => {
        const id = name.replace('PreviewService_', '');
        const newData = [...data];
        newData.find(item => item.LicenceName === id).Selected = value.length > 0 ? value[0] : '';
        setData(newData);
        if (value.length !== 0) {
            values.PublishStatus = PREVIEW_SERVICE_STATUS.STAGING;
            onChangeField('PublishStatus', PREVIEW_SERVICE_STATUS.STAGING)
        } else {
            values.PublishStatus = '';
            onChangeField('PublishStatus', '')
        }
        onChangeField('LicenceTypeIds', value);
    };

    const setCheckboxContent = (rowData) => {
        return <Checkbox
            name={`PreviewService_${rowData.LicenceName}`}
            value={values.LicenceTypeIds}
            options={[{ label: '', value: rowData.LicenceTypeId }]}
            onChangeField={(name, val) => {
                setSelectedCheckbox(name, val);
            }}
            checked />;
    };

    const setLicenceName = rowData => {
        const licenceTypeNameTranslationKey = getLicenceTypeNameTranslationKey(rowData.LicenceName);
        const licenceName = t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY, licenceTypeNameTranslationKey, rowData.LicenceTypeId));

        return <div>{licenceName}</div>;
    };

    const getLicenceNameTranslatedString = (rowData) => {
        const licenceTypeNameTranslationKey = getLicenceTypeNameTranslationKey(rowData.LicenceName);
        return t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY, licenceTypeNameTranslationKey, rowData.LicenceTypeId));
    };

    const setAgencyName = rowData => {
        const agencyName = t(getLangKey(LANGUAGE_KEYS.BLS_LICENCEAGENCY_AGENCY_AGENCYNAME, rowData.AgencyId));

        return <div>{agencyName}</div>;
    };

    const getAgencyNameTranslatedString = (rowData) => {
        return t(getLangKey(LANGUAGE_KEYS.BLS_LICENCEAGENCY_AGENCY_AGENCYNAME, rowData.AgencyId));
    };

    const renderGrid = () => {
        return (
            <React.Fragment>
                <div className="smart-list">
                    <Modal render={({ modal }) => (
                        <>
                            <DataTable
                                pageSize="5"
                                minFilterChars='2'
                                data={data}
                                noResultsMessage={t(LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_TABLE_TITLE_NO_RESULTS_MESSAGE)}
                                components={getCustomComponents()}
                                columns={
                                    {
                                        Checkbox: { title: t(LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_TABLE_TITLE_CHECK_BOX), width: '5%', DBkey: 'Checkbox', setContent: setCheckboxContent },
                                        AgencyName: {
                                            title: t(LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_TABLE_TITLE_AGENCY_NAME),
                                            width: '20%',
                                            DBkey: 'AgencyName',
                                            setContent: setAgencyName,
                                            getTranslatedString: getAgencyNameTranslatedString
                                        },
                                        LicenceName: {
                                            title: t(LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_TABLE_TITLE_SERVICE_NAME),
                                            width: '20%',
                                            DBkey: 'LicenceName',
                                            setContent: setLicenceName,
                                            getTranslatedString: getLicenceNameTranslatedString
                                        },
                                        LastPublishedToStaging: { title: t(LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_TABLE_TITLE_LAST_PUBLISHED_TO_PREVIEW), width: '15%', DBkey: 'LastPublishedToStaging', isDate: true },
                                        PublishStatus: { title: t(LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_TABLE_TITLE_PUBLISH_STATUS_TO_PREVIEW), width: '10%', DBkey: 'PublishStatus' },
                                        NumberOfTestAccount: { title: t(LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_TABLE_TITLE_TEST_ACCOUNTS), width: '10%', DBkey: 'NumberOfTestAccount' },
                                        Actions: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_TABLE_TITLE_ACTION), width: '20%' }
                                    }
                                }
                                renderActions={({ RowData }) => (
                                    <React.Fragment>
                                        <ActionButton
                                            tooltip={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_CONTENT_BUTTON_VIEW)}
                                            color="forward"
                                            onClick={() => { openLicenceViewModal(modal, RowData.LicenceName, RowData.AgencyName, RowData.LicenceTypeId); }}
                                        >
                                            <FontAwesomeIcon icon={faEye} />
                                        </ActionButton>
                                        {(!RowData.IsExistingTestAccount) &&
                                            <Button outline color="success" onClick={() => { openAddTestAccountViewModal(modal, RowData.LicenceName, RowData.AgencyName, RowData.LicenceTypeId); }}>
                                                {t(LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_TABLE_BUTTON_ADDTESTERS)}
                                            </Button>
                                        }
                                        {(RowData.IsExistingTestAccount) &&
                                            <ActionButton
                                                tooltip={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_CONTENT_BUTTON_EDIT)}
                                                color="forward"
                                                onClick={() => { openEditTestAccountViewModal(modal, RowData.LicenceName, RowData.AgencyName, RowData.LicenceTypeId); }}
                                            >
                                                <FontAwesomeIcon icon={faUserEdit} />
                                            </ActionButton>
                                        }
                                    </React.Fragment>
                                )}
                            />
                            <ConfigurationDetailModal
                                modalClassName='theme-new'
                                isOpen={modal.modalState === MODAL_NAMES.PREVIEW_SERVICE_POP_UP}
                                cancelText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_CANCEL)}
                                cancelCallback={() => modal.toggleModal(MODAL_NAMES.PREVIEW_SERVICE_POP_UP)}
                                lcData={lcDetailData}
                                lacData={lacDetailData}
                                licenceName={headerLicenceName}
                                agencyName={headerAgencyName}
                                licenceTypeId={licenceTypeId}
                            />
                            <TestAccountModal
                                modalClassName='theme-new'
                                isOpen={modal.modalState === MODAL_NAMES.PREVIEW_SERVICE_TEST_ACCOUNT_ADD_POP_UP}
                                title={t(LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_MODAL_TITLE_CREATETESTACCOUNTS)}
                                toggle={() => modal.toggleModal(MODAL_NAMES.PREVIEW_SERVICE_TEST_ACCOUNT_ADD_POP_UP)}
                                cancelText={t(LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_MODAL_BUTTON_CANCEL)}
                                saveText={t(LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_MODAL_BUTTON_SAVE)}
                                editTestAccounts={true}
                                licenceName={headerLicenceName}
                                agencyName={headerAgencyName}
                                testAccountData={testAccountData}
                                licenceTypeId={licenceTypeId}
                                userTypes={userTypes}
                                workflowRoles={workflowRoles}
                                completeTestByDate={completeTestByDate}
                                reloadData={reloadData}
                            />
                            <TestAccountModal
                                modalClassName='theme-new'
                                isOpen={modal.modalState === MODAL_NAMES.PREVIEW_SERVICE_TEST_ACCOUNT_EDIT_POP_UP}
                                title={t(LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_MODAL_TITLE_EDITTESTACCOUNTS)}
                                toggle={() => modal.toggleModal(MODAL_NAMES.PREVIEW_SERVICE_TEST_ACCOUNT_EDIT_POP_UP)}
                                cancelText={t(LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_MODAL_BUTTON_CANCEL)}
                                updateText={t(LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_MODAL_BUTTON_UPDATE)}
                                editTestAccounts={isEditTestAccounts}
                                licenceName={headerLicenceName}
                                agencyName={headerAgencyName}
                                licenceTypeId={licenceTypeId}
                                testAccountData={testAccountData}
                                completeTestByDate={completeTestByDate}
                                userTypes={userTypes}
                                workflowRoles={workflowRoles}
                                reloadData={reloadData}
                            />
                        </>
                    )} />
                </div>
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            <Layout type={LAYOUT_TYPE.FORM}>
                <div className="grid-container">
                    {renderGrid()}
                </div>
            </Layout>
        </React.Fragment>
    );
}

