import { Divider } from 'antd';
import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import * as Yup from 'yup';
import { APP_REGISTERED_LANGUAGE_URL, LAYOUT_TYPE, TRANSLATION_MANAGEMENT_ADD_URL, TRANSLATION_MANAGEMENT_LIST } from '../../App/AppSettings';
import Layout from '../../Layout';
import { ERROR, SUCCESS } from '../../Localization/index';
import { fetchRequest, getParams, navigateTo } from '../../Shared/Actions';
import { FIELD_SIZE } from '../../Shared/Constants';
import { withSMARTWrapper, Plaintext, SelectList, SMARTForm, Text, toastError, toastSuccess } from '../../Shared/Forms';
import { DEFAULT_TEXT, DEFAULT_NAMESPACE } from '../../Shared/Constants/LanguageKeys.js';
import history from '../../Shared/History';
import { withLoader } from '../../Shared/Loading';
import PageFooter from '../../Shared/PageFooter';
import './LanguageList.css';
import RichTextToggle from './RichTextToggle';

const FORM_CONTEXT = "CreateNewKey";
const LABEL_SIZE = 3;
const INPUT_SIZE = 9;

const FORM_INITIAL_VALUES = {
    ApplicationId: '',
    ScopeId: '',
    CategoryId: '',
    SubcategoryId: '',
    Key: '',
    LanguagesInput: {}
}

class CreateNewKey extends React.Component {
    constructor(props) {
        super(props);
        if (history.location.state) {
            const { scopes, categories, subcategories } = history.location.state.dropDownOptions;
            const { applicationId, applicationLabel, chosenLanguage, chosenFilterValues } = history.location.state;
            FORM_INITIAL_VALUES.LanguagesInput = {};
            FORM_INITIAL_VALUES.ApplicationId = applicationId;
            this.state = {
                scopes: scopes,
                categories: categories,
                subcategories: subcategories,
                values: FORM_INITIAL_VALUES,
                chosenLanguageApplication: applicationId,
                chosenLanguageApplicationLabel: applicationLabel,
                chosenLanguage,
                chosenFilterValues
            }
        }
    }
    componentDidMount = async () => {
        const params = {
            applicationId: this.state.values.ApplicationId,
            isActiveOnly: true
        }
        const { loader } = this.props;
        loader.start();
        const response = await fetchRequest(APP_REGISTERED_LANGUAGE_URL, getParams(params));
        const { Data, IsSuccess, MessageLanguageKey, Message } = response.body;
        if (response.success) {
            if (IsSuccess) {
                this.setState({
                    languages: Data,
                    values: this.getValues(Data),
                    validationSchema: this.createValidationSchema(Data),
                })
            }
            else {
                toastError(this.props.getMessage(MessageLanguageKey, Message));
            }
        } else {
            toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
        }
        loader.done();
    }

    getValues = (languages) => {
        let values = FORM_INITIAL_VALUES
        languages.forEach((language) => {
            values.LanguagesInput[language.LanguageCodeValue] = ''
        })
        return values;
    }

    createValidationSchema = (languages) => {
        return Yup.object().shape({
            ScopeId: Yup.string().required(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_REQUIRED, ERROR.REQUIRED)),
            CategoryId: Yup.string().required(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_REQUIRED, ERROR.REQUIRED)),
            SubcategoryId: Yup.string().required(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_REQUIRED, ERROR.REQUIRED)),
            Key: Yup.string().required(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_REQUIRED, ERROR.REQUIRED)),
            LanguagesInput: this.getValueValidation(languages)
        })
    }

    getValueValidation = (languages) => {
        let languageSchema = {};

        languages.forEach((language) => {
            languageSchema[language.LanguageCodeValue] = Yup.string()
                .required(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_ERROR_TITLE_VALUE, DEFAULT_TEXT.VALUE_REQUIRED))
        })
        return Yup.object().shape(languageSchema);
    }

    massageValues = (values, submitPlainForm) => {
        values = { ...values, "Languages": JSON.stringify(values.LanguagesInput) }
        this.setState({
            values: values
        }, () => submitPlainForm())
    }

    submitCallback = ({ response }) => {
        const { chosenLanguageApplication, chosenLanguage, chosenFilterValues } = this.state;
        if (response.success) {
            const { IsSuccess, Message, MessageLanguageKey } = response.body;
            if (IsSuccess) {
                navigateTo(TRANSLATION_MANAGEMENT_LIST, { chosenLanguageApplication, chosenLanguage, chosenFilterValues });
                toastSuccess(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_SUCCESS_TITLE_CREATETRANS, SUCCESS.CREATE_SUCCESS), DEFAULT_TEXT.TRANSLATION);
            }
            else {
                toastError(this.props.getMessage(MessageLanguageKey, Message));
            }
        } else {
            toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
        }
    }


    render() {
        const { languages, validationSchema, scopes, categories, subcategories, values, chosenLanguageApplication, chosenLanguage, chosenFilterValues, chosenLanguageApplicationLabel } = this.state;
        return (
            <>
                <Layout type={LAYOUT_TYPE.FORM} title=
                    {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_CONTENT_TITLE_ADDLANGENTRY, DEFAULT_TEXT.ADD_LANG_ENTRY)}>
                    <SMARTForm
                        formContext={FORM_CONTEXT}
                        formValues={values}
                        validateOnSubmit={true}
                        serverURL={TRANSLATION_MANAGEMENT_ADD_URL}
                        isDummy={false}
                        validationSchema={validationSchema}
                        submitCallback={this.submitCallback}
                    >
                        {({ values, errors, onChange, onChangeField, submitPlainForm }) => (
                            <>
                                <Row className="body-content admin-content-wrapper">
                                    <Col lg="10">
                                        <Plaintext
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_FORM_TITLE_APPLICATION, DEFAULT_TEXT.APPLICATION)}
                                            name="Application"
                                            value={chosenLanguageApplicationLabel}
                                            labelSize={LABEL_SIZE}
                                            inputSize={INPUT_SIZE}
                                        />
                                        <SelectList
                                            name="ScopeId"
                                            value={values.ScopeId}
                                            options={scopes}
                                            onChangeField={onChangeField}
                                            placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_FORM_PLACEHOLDER_SCOPE, DEFAULT_TEXT.SELECT_SCOPE)}
                                            isMulti={false}
                                            inputSize={FIELD_SIZE.XLARGE}
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_FORM_TITLE_SCOPE, DEFAULT_TEXT.SCOPE)}
                                            error={errors.ScopeId}
                                            required
                                        />
                                        <SelectList
                                            name="CategoryId"
                                            value={values.CategoryId}
                                            options={categories}
                                            onChangeField={onChangeField}
                                            placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_FORM_PLACEHOLDER_CATEGORY, DEFAULT_TEXT.SELECT_CATEGORY)}
                                            isMulti={false}
                                            inputSize={FIELD_SIZE.XLARGE}
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_FORM_TITLE_CATEGORY, DEFAULT_TEXT.CATEGORY)}
                                            error={errors.CategoryId}
                                            required
                                        />
                                        <SelectList
                                            name="SubcategoryId"
                                            value={values.SubcategoryId}
                                            options={subcategories}
                                            onChangeField={onChangeField}
                                            placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_FORM_PLACEHOLDER_SUBCATEGORY, DEFAULT_TEXT.SELECT_SUB_CATEGORY)}
                                            isMulti={false}
                                            inputSize={FIELD_SIZE.XLARGE}
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_FORM_TITLE_SUBCATEGORY, DEFAULT_TEXT.SUBCATEGORY)}
                                            error={errors.SubcategoryId}
                                            required
                                        />
                                        <Text
                                            name="Key"
                                            value={values.Key}
                                            placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_FORM_PLACEHOLDER_KEY, DEFAULT_TEXT.KEY)}
                                            onChange={onChange}
                                            minLength={0}
                                            maxLength={140}
                                            inputSize={FIELD_SIZE.XLARGE}
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_FORM_TITLE_KEY, DEFAULT_TEXT.KEY)}
                                            error={errors.Key}
                                            required
                                        />
                                    </Col>
                                    <Col lg="12">
                                        <div className="row">
                                            <Col lg="12" className="label-default">
                                                <Divider className="db-divider" orientation="left">
                                                    {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_FORM_DIVIDER_ADDVALUE, DEFAULT_TEXT.ADD_VALUE_FOR_KEY)}
                                                </Divider>
                                            </Col>
                                        </div>
                                        {languages && languages.map(language =>
                                            <div key={language.LanguageCodeValue}>
                                                <RichTextToggle
                                                    language={language}
                                                    values={values}
                                                    onChangeField={onChangeField}
                                                    onChange={onChange}
                                                    errors={errors}
                                                />
                                            </div>
                                        )}
                                    </Col>
                                </Row>

                                <PageFooter type={LAYOUT_TYPE.FORM}>
                                    <Button color="backward" size="sm" type="button" onClick={() => navigateTo(TRANSLATION_MANAGEMENT_LIST, { chosenLanguageApplication, chosenLanguage, chosenFilterValues })}>
                                        {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_NAVIGATION_BUTTON_BACK, DEFAULT_TEXT.BACK)}
                                    </Button>
                                    <Button color="forward" size="sm" type="button" onClick={() => this.massageValues(values, submitPlainForm)}>
                                        {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_NAVIGATION_BUTTON_CREATE, DEFAULT_TEXT.CREATE)}
                                    </Button>
                                </PageFooter>
                            </>
                        )}
                    </SMARTForm>
                </Layout>
            </>
        );
    }
}

export default withSMARTWrapper(withLoader(CreateNewKey));
