import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row } from 'reactstrap';
import { LICENCE_CONFIG_ACTIVE_LICENCE_TYPE_BY_AGENCY_LIST, LICENCE_CONFIG_CREATE_PAGE, LICENCE_CONFIG_VERSION_PAGE } from '../../App/AppSettings';
import { getParams, navigateTo } from '../../Shared/Actions';
import { DATE_TIME_FORMAT } from '../../Shared/Constants';
import { LANGUAGE_KEYS } from '../../Shared/Constants/LanguageKeys';
import { ActionButton, DataTable, MobileCardActionButton } from '../../Shared/DataTable';
import { getCustomComponents } from '../../Shared/DataTable/BaseTable.js';
import { useAsyncFetch } from '../../Shared/Fetch';
import Title from '../../Title';
import { getLangKey, getLicenceTypeNameTranslationKey } from '../DisplayComponents/DisplayUtils';

const RETRIEVE_URL = LICENCE_CONFIG_ACTIVE_LICENCE_TYPE_BY_AGENCY_LIST;

export default function LicenceConfigMgmtList() {

    const [data, setData] = useState([]);
    const { t } = useTranslation();
    const response = useAsyncFetch(RETRIEVE_URL, getParams());

    useEffect(() => {
        const fetchData = async () => {
            const { Data, IsSuccess } = response.body;
            if (response.success && IsSuccess) {
                setData(Data);
            }
        }
        if (response) {
            fetchData();
        }
    }, [response])

    const renderMobileCardActions = (RowData) => (
        <MobileCardActionButton
            onClick={() => navigateTo(LICENCE_CONFIG_VERSION_PAGE, { ID: RowData.Id })}
            icon={faEye}
            label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_CONTENT_BUTTON_VIEW)}
        />
    )

    const setLicenceName = rowData => {
        const licenceTypeNameTranslationKey = getLicenceTypeNameTranslationKey(rowData.LicenceName);
        const licenceName = t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY, licenceTypeNameTranslationKey, rowData.LicenceTypeId));

        return <div>{licenceName}</div>;
    };

    const setAgencyName = rowData => {
        const agencyName = t(getLangKey(LANGUAGE_KEYS.BLS_LICENCEAGENCY_AGENCY_AGENCYNAME, rowData.AgencyId));

        return <div>{agencyName}</div>;
    };

    const getLicenceNameTranslatedString = (rowData) => {
        const licenceTypeNameTranslationKey = getLicenceTypeNameTranslationKey(rowData.LicenceName);
        return t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY, licenceTypeNameTranslationKey, rowData.LicenceTypeId));;
    };

    const getAgencyNameTranslatedString = (rowData) => {
        return t(getLangKey(LANGUAGE_KEYS.BLS_LICENCEAGENCY_AGENCY_AGENCYNAME, rowData.AgencyId));
    };

    const setStatusTranslatedContent = (rowData) => {
        return <>{t(getLangKey(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_VERSIONSTATUS_TITLE_KEY, rowData.Status))}</>;
    }

    const getStatusTranslatedString = (rowData) => {
        return t(getLangKey(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_VERSIONSTATUS_TITLE_KEY, rowData.Status));
    }

    const setEffectiveFromDate = (rowData) => {
        if (Date.parse(rowData.EffectiveFrom) > Date.parse(DATE_TIME_FORMAT.DEFAULT_MIN_DATE)) {
            return <>{rowData.EffectiveFrom}</>;
        } else {
            return <>{DATE_TIME_FORMAT.DEFAULT_EMPTY_DATE}</>;
        }
    };

    const setEffectiveToDate = (rowData) => {
        if (Date.parse(rowData.EffectiveTo) > Date.parse(DATE_TIME_FORMAT.DEFAULT_MIN_DATE)) {
            return <>{rowData.EffectiveTo}</>;
        } else {
            return <>{DATE_TIME_FORMAT.DEFAULT_EMPTY_DATE}</>;
        }
    };

    const renderLayout = () => {
        return (
            <>
                <Row>
                    <Col>
                        <Button className="add-action-button" color="neutral" onClick={() => navigateTo(LICENCE_CONFIG_CREATE_PAGE)}>{t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_NAVIGATION_BUTTON_ADDNEWSERVICE)}</Button>
                    </Col>
                </Row>
                <div className="smart-list">
                    <DataTable
                        pageSize="5"
                        minFilterChars='2'
                        data={data}
                        noResultsMessage={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_MESSAGE_PLACEHOLDER_NOSERVICECONFIG)}
                        components={getCustomComponents()}
                        columns={
                            {
                                RunningNumber: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_TABLE_TITLE_NUMBER), width: '5%' },
                                LicenceName: {
                                    title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_TABLE_TITLE_SERVICENAME),
                                    width: '20%',
                                    DBkey: 'LicenceName',
                                    setContent: setLicenceName,
                                    getTranslatedString: getLicenceNameTranslatedString
                                },
                                Agency: {
                                    title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_TABLE_TITLE_AGENCY),
                                    width: '20%',
                                    DBkey: 'Agency',
                                    setContent: setAgencyName,
                                    getTranslatedString: getAgencyNameTranslatedString
                                },
                                EffectiveFrom: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_TABLE_TITLE_EFFECTIVEFROM), width: '20%', DBkey: 'EffectiveFrom', isDate: true, setContent: setEffectiveFromDate },
                                EffectiveTo: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_TABLE_TITLE_EFFECTIVETO), width: '20%', DBkey: 'EffectiveTo', isDate: true, setContent: setEffectiveToDate },
                                Status: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_TABLE_TITLE_STATUS), width: '10%', DBkey: 'Status', setContent: setStatusTranslatedContent, getTraslatedString: getStatusTranslatedString },
                                Actions: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_TABLE_TITLE_ACTION), width: '5%' }
                            }
                        }
                        renderActions={({ RowData }) => (
                            <React.Fragment>
                                <ActionButton tooltip={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_CONTENT_BUTTON_VIEW)} color="forward" onClick={() => navigateTo(LICENCE_CONFIG_VERSION_PAGE, { LicenceType: RowData })}><FontAwesomeIcon icon={faEye} /></ActionButton>
                            </React.Fragment>
                        )}
                        renderMobileCardActions={renderMobileCardActions}
                    />
                </div>
            </>
        );
    }

    return (
        <>
            <Title className="first body-title body-title-text" titleValue={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_CONTENT_TITLE_SERVICECONFIGURATIONMANAGEMENT)} />
            <div className="grid-container">
                <Row>
                    <Col>
                        {renderLayout()}
                    </Col>
                </Row>
            </div>
        </>
    );

}


