import React, { useEffect, useState } from "react";
import { Row, Col, Label, Input } from "reactstrap";
import { DSP_APP_CONFIG, DSP_CODE_CATEGORY, DATA_SOURCE_CODE_CAT_ID, DATA_SOURCE_APP_CONFIG_ID } from "../../AppConstants";

function DSPDatabase(props) {
    const dataType = props.dataType;
    const [dataTypeOptions, setDataTypeOptions] = useState([]);
    const [dataSourceSelected, setDataSourceSelected] = useState(props.dataSourceSelected);

    useEffect(() => {
        let url = "";
        if (dataType === DATA_SOURCE_CODE_CAT_ID) {
            url = DSP_CODE_CATEGORY;
        } else if (dataType === DATA_SOURCE_APP_CONFIG_ID) {
            url = DSP_APP_CONFIG;
        } 
        fetch(url)
        .then(response => response.json())
        .then(json => setDataTypeOptions(json))
        .catch(error => console.error(error));
    }, 
    // eslint-disable-next-line
    [])

    function onCategorySelected(event) {
        setDataSourceSelected(event.target.value);
        props.onDataSourceSelected(event.target.value);
    }

    return <div>
        <Row>
            <Col xs="auto">
                <Label className="middle-title">Category</Label>
            </Col>
            <Col xs="auto">
                <Input type="select" value={dataSourceSelected} onChange={onCategorySelected}>
                    <option value="" disabled>Select a category</option>
                    {dataTypeOptions.map((option, index) => {
                        return <option key={index} value={option}>{option}</option>
                    })}
                </Input>
            </Col>
        </Row>
    </div>
}

export default DSPDatabase;