import i18n from "i18next";
import Backend from 'i18next-http-backend';
import { initReactI18next } from "react-i18next";
import { GET_TRANSLATION_URL } from './components/App/AppSettings';
import { fetchRequest, getParams } from './components/Shared/Actions/';
import { ERROR, getLangKey } from "./components/Shared/Constants/LanguageKeys";
import { toastError } from './components/Shared/Forms';

const loadCurrent = (options, url, payload, callback) => {
    fetchRequest(url, getParams(), false)
        .then(response => {
            if (response.success) {
                if (response.body.IsSuccess) {
                    const data = response.body.Data.Translation;
                    callback(null, {status: response.status, data: data});
                }
                else {
                    toastError(i18n.t(getLangKey(ERROR.BACKEND_ERROR_MESSAGE, response.body.Message)), response.body.Message);
                }
            } else {
                toastError(i18n.t(ERROR.SERVER_UNREACHABLE));
            }
        });
};

i18n
    .use(Backend)
    .use(initReactI18next)// passes i18n down to react-i18next
    .init({
        lng: 'en',
        backend: {
            loadPath: GET_TRANSLATION_URL,
            request: loadCurrent,
        },
        load: 'currentOnly',
        lowerCaseLng: true,
        fallbackLng: false,
        defaultValue: '',
        parseMissingKeyHandler: () => {
            return '';
        },
        debug: true,
        react: {
            useSuspense: true,
            bindI18nStore: 'added'
        },
    });

export default i18n;
