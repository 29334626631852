import Griddle, { RowDefinition } from 'griddle-react';
import _ from 'lodash';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { IS_DEMO } from '../../App/AppSettings';
import { getLangKey } from '../../Content/DisplayComponents/DisplayUtils';
import { fetchRequest, getParams } from '../../Shared/Actions';
import { DIRECTIONS, FILTER } from '../../Shared/Constants';
import { CardTable } from '../../Shared/DataTable';
import { toastError } from '../../Shared/Forms';
import { withLoader } from '../../Shared/Loading';
import { ERROR } from '../Constants/LanguageKeys';
import { getColDefinition, getCustomComponents } from './BaseTable';

const getGridData = async (url, currentPage, pageSize, filterText, sortColumn, sortDir, extraGetParams) => {
    let gridParams = {
        PageSize: pageSize,
        CurrentPage: currentPage,
        SortColumn: sortColumn,
        SortDir: sortDir
    }

    gridParams = Object.assign(gridParams, filterText);
    if (extraGetParams !== undefined) {
        gridParams = Object.assign(gridParams, extraGetParams);
    }

    return fetchRequest(url, getParams(gridParams), IS_DEMO);
}

class ServerSideDataTable extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            gridData: [],
            currentPage: 1,
            recordCount: 0,
            filterText: { [FILTER]: "" },
            sortColumn: "",
            sortDir: "",
            width: window.innerWidth,
            renderMobileCardActions: this.props.renderMobileCardActions,
            toggleUpdate: false,
            isLoaded: false
        };
    }

    async componentDidMount() {
        const { currentPage, sortColumn, sortDir } = this.state;
        const { url, pageSize, loader, extraGetParams, setInitialFilterText, t } = this.props;
        let { filterText } = this.state;
        if (setInitialFilterText) {
            filterText = setInitialFilterText();
        }
        this.setState({ filterText });
        
        // Start loading
        loader.start();

        // Get grid data
        const response = await getGridData(url, currentPage, pageSize, filterText, sortColumn, sortDir, extraGetParams);

        // Done loading
        loader.done();
        if (response !== null) {
            const { IsSuccess, Data, Message } = response.body;

            if (response.success && IsSuccess) {
                this.initGrid(Data.Data, Data.TotalRecords);
            }
            else {
                Message ? toastError(t(getLangKey(ERROR.BACKEND_ERROR_MESSAGE, Message)), Message) : toastError(t(ERROR.LIST_RETRIEVAL_FAIL));
            }
        }
        else {
            toastError(t(ERROR.LIST_RETRIEVAL_FAIL));
        }
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.toggleUpdate !== this.props.toggleUpdate || !(_.isEqual(prevProps.extraGetParams, this.props.extraGetParams))) {
            const { currentPage, filterText, sortColumn, sortDir } = this.state;
            const { url, pageSize, loader, extraGetParams } = this.props;
            // Start loading
            loader.start();
            // Get grid data
            const response = await getGridData(url, currentPage, pageSize, filterText, sortColumn, sortDir, extraGetParams);

            // Done loading
            loader.done();
            // Update table state
            this.updateTableState(true, response, currentPage);
        }

        this.setAutocompleteOff();
    }

    componentWillMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    };

    handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
    };

    setAutocompleteOff = () => {
        var inputElements = document.getElementsByTagName("input");
        for (var i = 0; inputElements[i]; i++) {
            inputElements[i].setAttribute("autocomplete", "off");
        }
    }

    initGrid = (data, recordCount) => {
        this.setState({
            gridData: this.massageData(data),
            recordCount: recordCount,
            isLoaded: true
        });
    }

    massageData = (Data) => {
        const { customizeData } = this.props;
        if (Data !== null) {
            Object.keys(Data).forEach(function (i) {
                //Returns a dash to fill null fields
                Object.keys(Data[i]).forEach(function (x) {
                    if (Data[i][x] === "" || Data[i][x] === null) {
                        return Data[i][x] = "-";
                    }
                })
                //set customized data
                if (customizeData) {
                    customizeData(Data[i]);
                }
            });
        }

        return Data;
    }

    updateTableState = (toToggle, response, currentPage) => {
        const { t } = this.props;
        const { IsSuccess, Data, Message } = response.body;
        if (response !== null) {
            if (response.success && IsSuccess) {
                //Get previous page's data when current's page data is empty (except for Page 1)
                if (Data !== undefined && Data.length === 0 && currentPage !== 1) {
                    this.getPrev();
                } else {
                    this.setState({
                        gridData: this.massageData(Data.Data),
                        currentPage: currentPage,
                        recordCount: Data.TotalRecords
                    });
                }

                // Update Grid
                if (toToggle) {
                    this.setState({
                        toggleUpdate: !this.state.toggleUpdate
                    })
                }
            }
            else {
                Message ? toastError(t(getLangKey(ERROR.BACKEND_ERROR_MESSAGE, Message))) : toastError(t(ERROR.LIST_RETRIEVAL_FAIL));
            }
        }
        else {
            toastError(t(ERROR.LIST_RETRIEVAL_FAIL));
        }
    }

    togglePaginationDisplay = () => {
        if (this.state.recordCount === 0) {
            return "no-pagination";
        }
        return "";
    }

    getNext = async () => {
        const { currentPage, filterText, sortColumn, sortDir } = this.state;
        const { url, pageSize, loader, extraGetParams } = this.props;
        let nextPage = currentPage + 1;

        // Start loading
        loader.start();

        // Get grid data
        const response = await getGridData(url, nextPage, pageSize, filterText, sortColumn, sortDir, extraGetParams);

        // Done loading
        loader.done();

        // Update table state
        this.updateTableState(true, response, nextPage);
    }

    getPrev = async () => {
        const { currentPage, filterText, sortColumn, sortDir } = this.state;
        const { url, pageSize, loader, extraGetParams } = this.props;
        let prevPage = currentPage - 1;

        // Start loading
        loader.start();

        // Get grid data
        const response = await getGridData(url, prevPage, pageSize, filterText, sortColumn, sortDir, extraGetParams);

        // Done loading
        loader.done();

        // Update table state
        this.updateTableState(true, response, prevPage);
    }

    getPage = async (pageNumber) => {
        const { filterText, sortColumn, sortDir } = this.state;
        const { url, pageSize, loader, extraGetParams } = this.props;

        // Start loading
        loader.start();

        // Get grid data
        const response = await getGridData(url, pageNumber, pageSize, filterText, sortColumn, sortDir, extraGetParams);

        // Done loading
        loader.done();

        // Update table state
        this.updateTableState(true, response, pageNumber);
    }

    getCurrentPage = (e) => {
        let page = parseInt(e.target.value, 10);
        this.getPage(page);
    }

    applyFilter = (filterText) => {
        const { minFilterChars } = this.props;
        const minFilterCharacters = (typeof minFilterChars === 'undefined' ? 0 : minFilterChars);
        const searchFilterLength = filterText[FILTER].length;

        return (searchFilterLength >= minFilterCharacters) || (searchFilterLength === 0);
    }

    getFilter = async (filterText) => {
        const { sortColumn, sortDir } = this.state;
        const { isCustomFilter, url, pageSize, extraGetParams } = this.props;
        // const { loader } = this.props;

        // Construct filterText
        filterText = isCustomFilter ? filterText : { [FILTER]: filterText };

        const toToggle = this.hasSelectFilterChange(filterText, this.state.filterText);

        await this.setState({ filterText });

        if (this.applyFilter(filterText)) {

            // // Start loading
            // loader.start();

            // Get grid data
            const response = await getGridData(url, 1, pageSize, filterText, sortColumn, sortDir, extraGetParams);

            // // Done loading
            // loader.done();

            await this.setState({
                isFilter: true
            })

            // Update table state
            this.updateTableState(toToggle, response, 1);
        }
    }

    hasSelectFilterChange = (currentFilterText, prevFilterText) => {
        let currentFilterTextToCompare = Object.assign({}, currentFilterText);
        let prevFilterTextToCompare = Object.assign({}, prevFilterText);

        // Exclude search filter
        delete currentFilterTextToCompare[FILTER];
        delete prevFilterTextToCompare[FILTER];

        return !_.isEqual(currentFilterTextToCompare, prevFilterTextToCompare);
    }

    getSort = async (sortProperties) => {
        let col = sortProperties.id;
        let dir = sortProperties.sortAscending === true ? "asc" : "desc";
        this.setState({
            sortColumn: col,
            sortDir: dir
        });

        const { currentPage, filterText } = this.state;
        const { url, pageSize, loader, extraGetParams } = this.props;

        // Start loading
        loader.start();

        // Get grid data
        const response = await getGridData(url, currentPage, pageSize, filterText, col, dir, extraGetParams);

        // Done loading
        loader.done();

        // Update table state
        this.updateTableState(false, response, currentPage);
    }

    Filter = (props) => {
        return (
            <input
                dir={DIRECTIONS.AUTO}
                type="text"
                name="filter"
                defaultValue={this.state.filterText[FILTER]}
                className={props.className}
                placeholder="Filter"
                onChange={(e) => props.setFilter(e.target.value)}
            />
        );
    };

    getImageCard = () => {
        const { noResultsMessage, graphicUrl } = this.props;
        const { gridData, isLoaded } = this.state;

        return (
            <div className="row grid-list">
                {isLoaded && (gridData === undefined || gridData.length === 0) ?
                    <p>{noResultsMessage}</p>
                    :
                    gridData.map((file, index) => {
                        return (
                            <React.Fragment key={index}>
                                <div className={graphicUrl === file.Path ? "card grid-card grid-card-highlighted" : "card grid-card"}>
                                    <div className="card-headers white-background">
                                        <img className="card-img-graphic-select" src={this.getPath(file)} alt="Thumbnail" />
                                    </div>
                                    <div className="card-body">
                                        <div className="card-title">{file.Title}</div>
                                        <div className="card-information">{file.CreatedDate}</div>
                                        {this.props.renderActions({
                                            RowData: gridData[index],
                                            className: "action-button-group"
                                        })}
                                    </div>
                                </div>
                            </React.Fragment>
                        );
                    })}
            </div>
        )
    }

    getPath = (file) => {
        if (file.ContentType.includes("image")) {
            return this.props.imageUrl + file.Path;
        }
        else {
            return "/images/file.png"
        }
    }

    desktopLayout = ({ Table, Pagination, Filter }) => {
        return (
            <div>
                <Filter />
                <Table />
                <Pagination />
            </div>
        )
    }

    cardLayout = ({ Filter, Table }) => {
        return (
            <div>
                <Filter />
                <Table />
            </div>
        )
    }

    cardTable = () => {
        const { gridData, currentPage, recordCount, isMobile } = this.state;
        const { pageSize, isLanguageCard } = this.props;
        const NoOfPage = Math.ceil(recordCount / pageSize);

        return (
            <CardTable
                islanguageCard={isLanguageCard}
                isMobile={isMobile}
                data={gridData}
                getPrev={this.getPrev}
                onChange={this.getCurrentPage}
                value={currentPage}
                NoOfPage={NoOfPage}
                getNext={this.getNext}
                getFirst={this.getFirst}
                getLast={this.getLast}
                columns={this.props.columns}
                currentPage={this.state.currentPage}
                renderMobileCardActions={this.props.renderMobileCardActions}
                renderActions={this.props.renderActions}
                noResultsMessage={this.props.noResultsMessage}
                filterExcludedFieldsArray={this.props.filterExcludedFieldsArray}
            />
        )
    }

    getComponents = (isMobile) => {
        const { isCustomLayout, isCustomFilter, components, isImageCard, isLanguageCard } = this.props;

        let customComponents = getCustomComponents();
        let dataTableComponents = components === undefined ? Object.assign({}, customComponents) : components;
        dataTableComponents.Filter = isCustomFilter ? components.Filter : this.Filter;

        if (isMobile) {
            dataTableComponents.Table = this.cardTable;
        } else {
            dataTableComponents.Table = isImageCard ? this.getImageCard : isLanguageCard ? this.cardTable : customComponents.Table;
        }

        if (isCustomLayout) {
            dataTableComponents.Layout = components.Layout;
        } else {
            dataTableComponents.Layout = isMobile || isLanguageCard ? this.cardLayout : this.desktopLayout;
        }

        return dataTableComponents;
    }

    render() {

        const { gridData, currentPage, recordCount, width, toggleUpdate } = this.state;
        const { pageSize, noResultsMessage } = this.props;

        const isMobile = width <= 992;
        let colDef = getColDefinition(this.props);

        return (
            <div className={"smart-griddle-table " + this.togglePaginationDisplay()}>
                <Griddle
                    key={isMobile + " " + toggleUpdate}
                    data={gridData}
                    pageProperties={{
                        currentPage,
                        pageSize,
                        recordCount
                    }}
                    textProperties={
                        this.noResultsMessage = { noResultsMessage }
                    }
                    components={this.getComponents(isMobile)}
                    events={{
                        onNext: this.getNext,
                        onPrevious: this.getPrev,
                        onGetPage: this.getPage,
                        onFilter: this.getFilter,
                        onSort: this.getSort,
                        onFirst: this.getFirst,
                        onLast: this.getLast
                    }}>
                    <RowDefinition>
                        {colDef}
                    </RowDefinition>
                </Griddle>
            </div>
        )
    }
}

export default withTranslation()(withLoader(ServerSideDataTable));