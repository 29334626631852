import React, { useEffect, useState } from 'react';
import { FORM_HISTORY_URL, LAYOUT_TYPE } from '../../../App/AppSettings';
import Section from '../../../Section';
import { DataTable } from '../../../Shared/DataTable';
import { getCustomComponents } from '../../../Shared/DataTable/BaseTable.js';
import { getParams, useAsyncFetch } from '../../../Shared/Fetch';
import { toastError } from '../../../Shared/Forms/Toaster.js';
import history from '../../../Shared/History';
import { useTranslation } from "react-i18next";
import { ERROR, getLangKey, LANGUAGE_KEYS } from '../../../Shared/Constants/LanguageKeys';

export default function FormHistory(props) {

    const {t} = useTranslation();
    const { state } = history.location;
    const [historyData, setHistoryData] = useState([])
    const [isHistoryDataLoaded, setHistoryDataLoaded] = useState(false)

    const response = useAsyncFetch(FORM_HISTORY_URL + state.ID, getParams());

    useEffect(() => {
        async function setData() {
            const { IsSuccess, Messages, Data } = response.body;

            if (response.success && IsSuccess) {
                const historyData = Data ? Data : [];
                setHistoryData(historyData);
                setHistoryDataLoaded(true);
            }
            else {
                // Error message to be loaded here
                toastError(t(getLangKey(ERROR.BACKEND_ERROR_MESSAGE, Messages)), Messages);
            }
        }

        if (response) {
            setData()
        }
    }, [response, t])

    return (
        <>
            {isHistoryDataLoaded &&
                <Section
                    type={LAYOUT_TYPE.FORM_CONTENT}
                    sectionName={props.sectionName}
                    sectionState={props.sectionState}
                    toggleSection={props.toggleSection}
                >
                    <DataTable
                        pageSize="5"
                        minFilterChars='2'
                        data={historyData}
                        noResultsMessage={t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_MESSAGE_PLACEHOLDER_NOFORMHISTORY)}
                        components={getCustomComponents()}
                        columns={
                            {
                                RunningNumber: { title: t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_TABLE_TITLE_NUMBER), width: '5%' },
                                InternalDescription: { title: t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_TABLE_INFORMATION_STATUSOFFORM), width: '25%', DBkey: 'InternalDescription' },
                                Action: { title: t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_TABLE_INFORMATION_ACTION), width: '18%', DBkey: 'CommandExecuted' },
                                ActionBy: { title: t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_TABLE_INFORMATION_ACTIONBY), width: '12%', DBkey: 'UserName' },
                                TransactionDate: { title: t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_TABLE_INFORMATION_ACTIONDATE), isDate: true, width: '15%', DBkey: 'TransactionDate' },
                                Remarks: { title: t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_TABLE_INFORMATION_REMARKS), width: '25%', DBkey: 'UserRemarks' },
                            }
                        }
                    />
                </Section>
            }
        </>
    )
}