import React from 'react';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripVertical } from '@fortawesome/free-solid-svg-icons';

function SortableItem(props) {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        setActivatorNodeRef
    } = useSortable({id: props.id});
    
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };
    
    return (
        <div ref={setNodeRef} style={style} {...attributes} >
            <Row>
                <Col xs="auto">
                    {/* <DragIndicatorIcon ref={setActivatorNodeRef} {...listeners} className="middle-title"></DragIndicatorIcon> */}
                    <div ref={setActivatorNodeRef} {...listeners}><FontAwesomeIcon className="middle-title" icon={faGripVertical} /></div>
                </Col>
                <Col>
                    {props.children}
                </Col>
            </Row>
        </div>
    );
}

export default SortableItem;