import React from 'react';
import PropTypes from 'prop-types';
import { Button, Table } from 'reactstrap';
import _ from 'lodash';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getLangKey, LANGUAGE_KEYS } from '../../../Shared/Constants/LanguageKeys';
import { withTranslation } from 'react-i18next';
import { DOCUMENT_TYPE_ISSUED, GET_LICENCE_DOCUMENT_FILE_URL } from '../../../App/AppSettings';
import { getFileRequestNewTab, getSFormFileNewTab } from '../../../Shared/Actions';

class FileUploadSectionViewer extends React.Component {

    renderFileUrl = (fileItem, isUpper, fileItemDescription) => {
        const displayText = isUpper ? fileItemDescription.toUpperCase() : fileItemDescription;
        return (fileItem.DocumentType === DOCUMENT_TYPE_ISSUED
            ? <a href='#' className="file-upload-text" onClick={(e) => { e.preventDefault(); this.getLicenceDocument(fileItem) }}>{displayText}</a>
            : <a href='#' className="file-upload-text" onClick={(e) => { e.preventDefault(); this.getSFormDocument(fileItem) }}>{displayText}</a>
        )
    }

    getSFormDocument = async obj => {
        const url = `${this.props.getFileURL}id=${obj.Id}&fileName=${obj.Filename}`;
        const response = await getSFormFileNewTab(url, false, {}, obj.Filename);
        return response;
    }

    getLicenceDocument = async obj => {
        const documentName = obj.Filename;
        const url = GET_LICENCE_DOCUMENT_FILE_URL + "id=" + obj.Id + "&fileName=" + documentName;
        const response = await getFileRequestNewTab(url, true, documentName);
        return response;
    }

    // Fn: Render uploaded formik files
    UploadedFiles = ({ fileName, fileList, onChangeField, readonly, isUpper }) => {
        let tableNo = 0;
        const activeFileList = _.filter(fileList, function (f) {
            return !f.IsDeleted;
        });
        const { t } = this.props;

        if (activeFileList !== '' && activeFileList !== undefined && activeFileList !== null && activeFileList.length !== 0) {
            return _.map(fileList, (fileItem, i) => {
                const fileItemTranslation = t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_GLOBALSUPPORTINGDOC_DOCNAME_KEY, fileItem.FileUploadField));
                const fileItemDescription = fileItemTranslation ? fileItemTranslation : fileItem.FileDescription;
                // Do not display IsDeleted
                if (fileItem.IsDeleted) return null;
                return (
                    /*  i is the index of the fileList, which includes deleted file items.
                        tableNo is the key for each row (regardless of deleted file items), which is also used to display table number. */
                    <tbody key={tableNo += 1} className="griddle-table-body">
                        <tr>
                            <td className="griddle-cell">{tableNo}</td>
                            <td className="griddle-cell">
                                {(fileItemDescription)
                                    ? this.renderFileUrl(fileItem, isUpper, fileItemDescription)
                                    : isUpper ? fileItem.description.toUpperCase() : fileItem.description
                                }
                            </td>
                            <td>
                                {fileItem.DocumentType === DOCUMENT_TYPE_ISSUED
                                    ? t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_TABLE_INFORMATION_ISSUED)
                                    : t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_TABLE_INFORMATION_SUBMITTED)
                                }
                            </td>
                            <td className="griddle-cell">
                                {((fileItem.ContentLength !== undefined) ?
                                    fileItem.ContentLength
                                    : (fileItem.size / 1000)).toFixed(1)
                                    + ' KB'
                                }
                            </td>
                            <td className="griddle-cell">
                                {((fileItem.ContentType !== undefined) ?
                                    fileItem.ContentType
                                    : (/[^.]+$/.exec(fileItem.name)[0])
                                ).toUpperCase()
                                }
                            </td>
                            {(readonly) ?
                                (null)
                                :
                                (<td className="griddle-cell">
                                    <Button
                                        color="hazard"
                                        className="docs-btn-delete"
                                        type="button"
                                        onClick={() => this.removeFile(fileName, fileList, onChangeField, i)}>
                                        <FontAwesomeIcon icon={faTimes} />
                                    </Button>
                                </td>)
                            }
                        </tr>
                    </tbody>
                );
            });
        } else {
            return (
                <tbody className="griddle-table-body">
                    <tr className="griddle-noResults">
                        <td
                            colSpan={(this.props.readonly) ? '5' : '6'}
                            className="griddle-cell"
                        >
                            {t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_MESSAGE_PLACEHOLDER_NOFILESUPLOADED)}
                        </td>
                    </tr>
                </tbody>
            );
        }
    };

    // Fn: Remove the file object based on given array index
    removeFile = (fileName, fileList, onChangeField, index) => {
        const fileItem = fileList[index];

        // Marks file as deleted
        fileItem.IsDeleted = true;

        if (fileItem instanceof File) {
            // Removes 1 file from the given index
            fileList.splice(index, 1);
        }

        // Updates changes in parent state
        onChangeField(fileName, fileList);

        // Updates File Limit error in Adder component
        this.props.hasFileLimitReached();
    };

    render() {
        const { t } = this.props;
        return (
            <div className={(this.props.readonly) ? 'docs-table-viewer form-group' : 'docs-table form-group'}>
                <Table bordered size="sm" className="griddle-table">
                    <thead className="griddle-table-heading">
                        <tr>
                            <th className="griddle-table-heading-cell">{t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_TABLE_TITLE_NUMBER)}</th>
                            <th className="griddle-table-heading-cell">{t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_TABLE_TITLE_DOCUMENTNAME)}</th>
                            <th className="griddle-table-heading-cell">{t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_TABLE_TITLE_DOCUMENTTYPE)}</th>
                            <th className="griddle-table-heading-cell">{t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_TABLE_TITLE_SIZE)}</th>
                            <th className="griddle-table-heading-cell">{t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_TABLE_TITLE_TYPE)}</th>
                            {(this.props.readonly) ?
                                (null) :
                                (<th className="griddle-table-heading-cell">{t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_TABLE_TITLE_ACTION)}</th>)
                            }
                        </tr>
                    </thead>

                    {this.UploadedFiles(this.props)}

                </Table>
            </div >
        );
    }
}

// PropTypes: For prop validation
FileUploadSectionViewer.propTypes = {
    name: PropTypes.string.isRequired,
    fileList: PropTypes.array,
    getFileURL: PropTypes.string,
    // Required if readonly={false}
    onChangeField: PropTypes.func,
    // Required if readonly={false}
    hasFileLimitReached: PropTypes.func,
    isUpper: PropTypes.bool,
    readonly: PropTypes.bool
};

// PropTypes: Defaulting value for optional props
FileUploadSectionViewer.defaultProps = {
    fileList: [],
    onChangeField: () => { },
    hasFileLimitReached: () => { },
    readonly: false
};


export default withTranslation()(FileUploadSectionViewer);