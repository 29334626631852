import React from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink, withRouter } from 'react-router-dom';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavDropdownConsumer } from './NavDropdownContext';
import AuthComponent from '../Shared/Authentication/AuthComponent';
import { PATH_TO_PERMISSIONS } from '../App/AppSettings';

class NavLink extends React.Component {

    // Fn: returns true if these additional paths are matched
    matchPath = ({ to, includePaths, location }) => {
        let checkIncludePaths = false;
        if (includePaths !== undefined) {
            checkIncludePaths = includePaths.find(ip => ip.toLowerCase() === location.pathname.toLowerCase());
        }
        return (_.isEqual(to, location.pathname) || checkIncludePaths) ? true : false;
    };

    //Optional fontawesome icon
    getIcon = ({ icon }) => {
        const iconString = icon;
        if (!icon) {
            return '';
        } else {
            return <i aria-hidden="false"> <FontAwesomeIcon size="lg" className="align-middle navbar-item-icon" fixedWidth icon={iconString} /></i>;
        }
    }

    getClassName = () => {
        return 'navbar-item'
            + (this.props.dropdownlevel !== undefined ? ` dropdownlevel-${this.props.dropdownlevel}` : '');
    }

    renderNavLink = () => {
        switch (this.props.navbarLayout) {
            case '1M':
                return (
                    <RouterLink
                        className={this.getClassName()}
                        to={this.props.to}
                        isActive={() => this.matchPath(this.props)}
                        onClick={this.props.onClick}
                    >
                        {this.getIcon(this.props)} <div className="navlink-text">{this.props.children}</div>
                    </RouterLink>
                );
            case '2D':
            case '2M':
                return (
                    <NavDropdownConsumer>
                        {({ setNavDropdown }) =>
                            <RouterLink
                                className={this.getClassName() + ' nav-link'}
                                to={this.props.to}
                                isActive={() => this.matchPath(this.props)}
                                onClick={() => {
                                    this.props.onClick();
                                    setNavDropdown('');
                                }}
                            >
                                {this.getIcon(this.props)} <div className="navlink-text">{this.props.children}</div>
                            </RouterLink>
                        }
                    </NavDropdownConsumer>
                );
            case '1D':
            default:
                return (
                    this.props.isExternal ?
                        <a
                            className={this.getClassName()}
                            href={this.props.to}
                            target={this.props.target}
                        >
                            {this.getIcon(this.props)} <div className="navlink-text">{this.props.children}</div>
                        </a>
                        :
                        <RouterLink
                            className={this.getClassName()}
                            to={this.props.to}
                            isActive={() => this.matchPath(this.props)}
                            onClick={this.props.onClick}
                        >
                            {this.getIcon(this.props)} <div className="navlink-text">{this.props.children}</div>
                        </RouterLink>
                );
        }
    }

    render() {
        const { to } = this.props;
        let permissions = PATH_TO_PERMISSIONS[to];
        permissions = permissions === undefined ? [] : permissions;

        return (
            <AuthComponent permissions={permissions}>
                {this.renderNavLink()}
            </AuthComponent>
        );
    }
}

NavLink.propTypes = {
    to: PropTypes.string.isRequired,
    dropdownlevel: PropTypes.string
};


export default withRouter(NavLink);