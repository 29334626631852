import moment from 'moment';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Card, CardBody, Col, Container, Label, Row } from 'reactstrap';
import * as Yup from 'yup';
import {
    DATE_DISPLAY_FORMAT,
    DOWNLOAD_LICENCE_EXTENSION_HISTORY,
    GET_LICENCE_EXTENSION,
    IS_DEMO,
    LAYOUT_TYPE,
    LICENCE_ENQUIRY_PARAMS_URL,
    PATH,
    REGEX
} from '../../App/AppSettings';
import Layout from '../../Layout';
import { fetchRequest, getFileRequest, getParams, navigateTo } from '../../Shared/Actions';
import { DATE_TIME_FORMAT, FIELD_SIZE } from '../../Shared/Constants';
import { ERROR, LANGUAGE_KEYS } from '../../Shared/Constants/LanguageKeys';
import { DataTable } from '../../Shared/DataTable';
import { getCustomComponents } from '../../Shared/DataTable/BaseTable.js';
import { DateInput, Plaintext, SelectList, SMARTForm, toastError, withSMARTWrapper } from '../../Shared/Forms';
import { ModalConfirm } from '../../Shared/Modal';
import { getLangKey, getLicenceTypeNameTranslationKey } from '../DisplayComponents/DisplayUtils';

const FORM_CONTEXT = 'LicenceExtension';

const FORM_INITIAL_VALUES = {
    LicenceName: '',
    FromDate: '',
    ToDate: ''
};



class LicenceExtension extends React.Component {

    constructor(props) {
        super(props);
        const { t } = this.props;
        this.state = {
            values: FORM_INITIAL_VALUES,
            data: [],
            count: 0,
            modalState: undefined,
            licenceNameOptions: [],
            VIEW_LICENCE_EXTENSION_MODAL: {
                hasModal: true,
                name: 'ViewLicenceExtension',
                modalHeader: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEEXTENSION_CONTENT_MODAL_SERVICEEXTENSIONDETAILS)
            }
        };
    }

    componentDidMount = async () => {
        const { loader, t } = this.props;
        // Start loading
        loader.start();

        const response = await fetchRequest(LICENCE_ENQUIRY_PARAMS_URL, getParams(), IS_DEMO);
        const { IsSuccess, Data, Messages } = response.body;

        if (IsSuccess) {
            const { LicenceAgency } = Data;
            const licenceNameOptions = [];

            // Format filter params into SelectList format
            LicenceAgency.forEach(licenceA => {
                const licenceTypeNameTranslationKey = getLicenceTypeNameTranslationKey(licenceA.LicenceTypeName);
                const licenceName = t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY, licenceTypeNameTranslationKey, licenceA.LicenceTypeId));
                licenceNameOptions.push({ label: licenceName, value: licenceA.LicenceTypeName });
            });

            this.setState({
                // actual select list that keeps applicable licences
                licenceNameOptions,
                // to keep a copy of all licences
                allLicenceOptions: licenceNameOptions,
                values: { ...this.state.values }
            });
        } else {
            toastError(t(getLangKey(ERROR.BACKEND_ERROR_MESSAGE, Messages)), Messages);
        }

        // End loading
        loader.done();
    };

    parseSearch = async ({ response }) => {
        const { loader, t } = this.props;
        // Start loading
        loader.start();
        if (response.success) {
            const { IsSuccess, Data, Messages } = response.body;

            //set state
            if (IsSuccess) {
                await this.setState({
                    success: IsSuccess,
                    data: Data,
                    count: ((Data !== undefined) ? Data.length : 0)
                });
                // End loading
                loader.done();
            } else {
                // Error message to be loaded here
                loader.error(t(getLangKey(ERROR.BACKEND_ERROR_MESSAGE, Messages)));
            }
        }
    };

    // Set extension period include day, month and year
    setExtensionPeriod = rowData =>{
        const { t } = this.props;
        return (<div>
            {`${rowData.ExtensionDurationYear}${' '+ t(LANGUAGE_KEYS.BLS_INTERNALLICENCEEXTENSION_FORM_FIELD_YEAR)}, ${rowData.ExtensionDurationMonth}${' '+ t(LANGUAGE_KEYS.BLS_INTERNALLICENCEEXTENSION_FORM_FIELD_MONTH)}, ${rowData.ExtensionDurationDay}${' '+ t(LANGUAGE_KEYS.BLS_INTERNALLICENCEEXTENSION_FORM_FIELD_DAY)}`}
        </div>);
    }

    // Set licence name to be clickable to open the corresponding modal
    setLicenceName = rowData => {
        const { t } = this.props;
        const licenceTypeNameTranslationKey = getLicenceTypeNameTranslationKey(rowData.LicenceName);
        const licenceName = t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY, licenceTypeNameTranslationKey, rowData.LicenceTypeId));

        return <div className="licence-extension-table-cell" onClick={() => this.toggleModal(this.state.VIEW_LICENCE_EXTENSION_MODAL.name, rowData)}>
            {licenceName}
        </div>;
    };
    getLicenceName = rowData => {
        const { t } = this.props;
        const licenceTypeNameTranslationKey = getLicenceTypeNameTranslationKey(rowData.LicenceName);
        return t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY, licenceTypeNameTranslationKey, rowData.LicenceTypeId));
    };

    // Set licence download list to download the licence list excel
    setLicenceList = rowData => {
        const { t } = this.props;
        return (<div className="licence-extension-table-cell" onClick={() => this.downloadLicenceExtensionHistory(rowData.LicenceName, rowData.DateOfExtension)}>
            {t(LANGUAGE_KEYS.BLS_INTERNALLICENCEEXTENSION_TABLE_LINK_DOWNLOADLIST)}
        </div>);
    };

    // Set DateTime to date format and display it in the table
    setDateOfExtension = rowData =>
        <div>
            {moment(rowData.DateOfExtension).format(DATE_TIME_FORMAT.DD_MM_YYYY)}
        </div>;


    // Open Edit Modal
    toggleModal = (modalName, modalValues) => {
        this.setState({
            modalState: (this.state.modalState !== modalName) ? modalName : undefined,
            modalValues
        });
    };

    downloadLicenceExtensionHistory = (licenceName, dateOfExtension) => {
        const { loader, t } = this.props;
        // Start loading
        loader.start();
        getFileRequest(
            `${DOWNLOAD_LICENCE_EXTENSION_HISTORY}dateOfExtension=${dateOfExtension}`,
            false,
            `${licenceName} ${t(LANGUAGE_KEYS.BLS_INTERNALLICENCEEXTENSION_CONTENT_TITLE_SERVICEEXTENSIONLIST)} (${moment(dateOfExtension).format(DATE_TIME_FORMAT.DD_MM_YYYY)}).xlsx`,
            getParams()
        ).then(() => loader.done())
        .catch(() => loader.done());
    };

    renderGrid = () => {
        const { t } = this.props;
        if (this.state.success) {
            return (
                <React.Fragment>
                    <Row>
                        <Col>
                            <DataTable
                                pageSize={5}
                                minFilterChars='2'
                                data={this.state.data}
                                components={getCustomComponents()}
                                noResultsMessage={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEEXTENSION_MESSAGE_PLACHOLDER_NORESULTS)}
                                columns={
                                    {
                                        RunningNumber: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEEXTENSION_TABLE_TITLE_NUMBER), width: '5%', DBkey: 'Id' },
                                        DateOfExtension: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEEXTENSION_TABLE_TITLE_DATEOFEXTENSION), width: '15%', DBkey: 'DateOfExtension', setContent: this.setDateOfExtension, isDate: true },
                                        ServiceName: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEEXTENSION_TABLE_TITLE_SERVICENAME), width: '15%', DBkey: 'ServiceName', setContent: this.setLicenceName, getTranslatedString: this.getLicenceName },
                                        ExtensionPeriod: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEEXTENSION_TABLE_TITLE_EXTENSIONPERIOD), width: '15%', DBkey: 'ExtensionPeriod', setContent: this.setExtensionPeriod },
                                        Actions: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEEXTENSION_TABLE_TITLE_ACTION), width: '8%' }
                                    }
                                }
                                renderActions={({ RowData }) => (
                                    <React.Fragment>
                                        <div className="licence-extension-table-cell" onClick={() => this.downloadLicenceExtensionHistory(RowData.LicenceName, RowData.DateOfExtension)}>
                                            {t(LANGUAGE_KEYS.BLS_INTERNALLICENCEEXTENSION_TABLE_LINK_DOWNLOADLIST)}
                                        </div>
                                    </React.Fragment>
                                )}
                                renderMobileCardActions={this.renderMobileCardActions}
                            />
                        </Col>
                    </Row>
                </React.Fragment>
            );
        } else {
            return null;
        }
    };

    render() {
        const { t } = this.props;
        const FORM_VALIDATIONS = Yup.object().shape({
            AgencyName: Yup.string(),
            LicenceName: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED)),
            FromDate: Yup.string().matches(REGEX.DATE, t(ERROR.DATE_INVALID)),
            ToDate: Yup.string().matches(REGEX.DATE, t(ERROR.DATE_INVALID))
        });
        const { modalValues } = this.state;
        return (
            <React.Fragment>
                <Layout type={LAYOUT_TYPE.FORM} title={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEEXTENSION_CONTENT_TITLE_SERVICEEXTENSION)}>
                    <div className="grid-container">
                        <SMARTForm
                            formContext={FORM_CONTEXT}
                            formValues={this.state.values}
                            validationSchema={FORM_VALIDATIONS}
                            validateOnSubmit={true}
                            serverURL={GET_LICENCE_EXTENSION}
                            isDummy={IS_DEMO}
                            submitCallback={this.parseSearch}
                        >
                            {({ values, errors, onChangeField, submitForm }) => (
                                <Container className="full-width">
                                    <Row>
                                        <Col>
                                            <Button
                                                className="add-action-button"
                                                color="neutral"
                                                onClick={() => navigateTo(PATH.SERVICE_EXTENSION.ADD_NEW_SERVICE_EXTENSION_PAGE)}
                                            >
                                                {t(LANGUAGE_KEYS.BLS_INTERNALLICENCEEXTENSION_NAVIGATION_BUTTON_ADDNEWSERVICE)}
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Card className="enquiry-container">
                                            <CardBody className="enquiry-card-body">
                                                <SelectList
                                                    name="LicenceName"
                                                    value={values.LicenceName}
                                                    options={this.state.licenceNameOptions}
                                                    onChangeField={(name, value) => onChangeField(name, value)}
                                                    placeholder={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEEXTENSION_FORM_PLACEHOLDER_SERVICENAME)}
                                                    isMulti={false}
                                                    isClearable={true}
                                                    inputSize={FIELD_SIZE.MEDIUM}
                                                    label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEEXTENSION_FORM_FIELD_SERVICENAME)}
                                                    labelSize={FIELD_SIZE.XSMALL}
                                                    error={errors.LicenceName}
                                                    required
                                                />
                                                <DateInput
                                                    name="FromDate"
                                                    value={values.FromDate}
                                                    placeholder={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEEXTENSION_FORM_PLACEHOLDER_CHOOSEDATE)}
                                                    onChangeField={onChangeField}
                                                    date={true}
                                                    time={false}
                                                    format={DATE_DISPLAY_FORMAT.DATE}
                                                    inputSize={FIELD_SIZE.MEDIUM}
                                                    label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEEXTENSION_FORM_FIELD_EXTENSIONPERIODFROM)}
                                                    labelSize={FIELD_SIZE.XSMALL}
                                                    error={errors.FromDate}
                                                    clearFieldEnabled={true}
                                                    disableKeyboardInput={true}
                                                />
                                                <DateInput
                                                    name="ToDate"
                                                    value={values.ToDate}
                                                    placeholder={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEEXTENSION_FORM_PLACEHOLDER_CHOOSEDATE)}
                                                    onChangeField={onChangeField}
                                                    date={true}
                                                    time={false}
                                                    format={DATE_DISPLAY_FORMAT.DATE}
                                                    inputSize={FIELD_SIZE.MEDIUM}
                                                    label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEEXTENSION_FORM_FIELD_EXTENSIONPERIODTO)}
                                                    labelSize={FIELD_SIZE.XSMALL}
                                                    error={errors.ToDate}
                                                    clearFieldEnabled={true}
                                                    disableKeyboardInput={true}
                                                />
                                                <Row className="button-row">
                                                    <Button
                                                        color="forward"
                                                        size="sm"
                                                        type="button"
                                                        onClick={submitForm}
                                                    >
                                                        {t(LANGUAGE_KEYS.BLS_INTERNALLICENCEEXTENSION_FORM_BUTTON_SEARCH)}
                                                    </Button>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Row>
                                    <Row>
                                        <Col className="col-gutter record-count">
                                            {t(LANGUAGE_KEYS.BLS_INTERNALLICENCEEXTENSION_FORM_TITLE_RECORDS)} ({this.state.count})
                                        </Col>
                                    </Row>
                                    <Row>
                                        <div className="enquiry-grid-container">
                                            {this.renderGrid()}
                                        </div>
                                    </Row>
                                </Container>
                            )}
                        </SMARTForm>
                    </div>
                </Layout>
                {/* Edit Table Modal */}
                <ModalConfirm
                    className={'modal-table'}
                    isOpen={this.state.modalState === this.state.VIEW_LICENCE_EXTENSION_MODAL.name}
                    contentHeader={this.state.VIEW_LICENCE_EXTENSION_MODAL.modalHeader}
                    confirmText={t(LANGUAGE_KEYS.BLS_INTERNALREPORTHISTORY_CONTENT_BUTTON_CLOSE)}
                    confirmCallback={() => {
                        this.toggleModal();
                    }}
                    contentBody={
                        modalValues && <div>
                            <Plaintext
                                label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEEXTENSION_TABLE_TITLE_DATEOFEXTENSION)}
                                className="modal-plaintext"
                                name={modalValues.DateOfExtension}
                                value={moment(modalValues.DateOfExtension).format(DATE_TIME_FORMAT.DD_MM_YYYY)}
                                labelSize={FIELD_SIZE.MEDIUM}
                                inputSize={FIELD_SIZE.MEDIUM}
                            />
                            <Plaintext
                                label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEEXTENSION_TABLE_TITLE_ACTIONBY)}
                                className="modal-plaintext"
                                name={modalValues.ActionBy}
                                value={modalValues.ActionBy}
                                labelSize={FIELD_SIZE.MEDIUM}
                                inputSize={FIELD_SIZE.MEDIUM}
                            />
                            <Plaintext
                                label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEEXTENSION_TABLE_TITLE_EXTENSIONPERIOD)}
                                className="modal-plaintext"
                                name={modalValues.ActionBy}
                                value={`${modalValues.ExtensionDurationYear} ${t(LANGUAGE_KEYS.BLS_INTERNALLICENCEEXTENSION_FORM_FIELD_YEAR)}, ${modalValues.ExtensionDurationMonth} ${t(LANGUAGE_KEYS.BLS_INTERNALLICENCEEXTENSION_FORM_FIELD_MONTH)}, ${modalValues.ExtensionDurationDay} ${t(LANGUAGE_KEYS.BLS_INTERNALLICENCEEXTENSION_FORM_FIELD_DAY)}`}
                                labelSize={FIELD_SIZE.MEDIUM}
                                inputSize={FIELD_SIZE.MEDIUM}
                            />
                            <Plaintext
                                label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEEXTENSION_FORM_FIELD_REMARKS)}
                                className="modal-plaintext"
                                name={modalValues.Remarks}
                                value={modalValues.Remarks}
                                labelSize={FIELD_SIZE.MEDIUM}
                                inputSize={FIELD_SIZE.MEDIUM}
                            />
                            <div className="modal-plaintext row form-group">
                                <Label className="label-default col-lg-5 col-form-label">{t(LANGUAGE_KEYS.BLS_INTERNALLICENCEEXTENSION_CONTENT_TITLE_SERVICELIST)}</Label>
                                <span className='modal-plaintext col-lg-5'>
                                    <b
                                        className="licence-extension-download-link"
                                        onClick={() => this.downloadLicenceExtensionHistory(modalValues.LicenceName, modalValues.DateOfExtension)}
                                    >
                                        {t(LANGUAGE_KEYS.BLS_INTERNALLICENCEEXTENSION_CONTENT_BUTTON_DOWNLOAD)}
                                    </b>
                                </span>
                            </div>
                        </div>
                    }
                />
            </React.Fragment>
        );
    }
}

export default withTranslation()(withSMARTWrapper(LicenceExtension));
