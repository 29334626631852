import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { default as React } from 'react';
import { Button, Col, Row } from 'reactstrap';
// import Select from "react-select";
import { deleteParams, fetchRequest, getParams, navigateTo } from '../../Shared/Actions';
import { FILTER, SELECT_FILTERS } from '../../Shared/Constants';
import { ActionButton, DataTable, MobileCardActionButton } from '../../Shared/DataTable';
import { getCustomComponents } from '../../Shared/DataTable/BaseTable.js';
import { withSMARTWrapper } from '../../Shared/Forms';
import { toastSuccess } from '../../Shared/Forms/Toaster';
import { ModalConfirm } from '../../Shared/Modal';
import { ONLINEHELP_URL } from '../../App/AppSettings';
import Title from '../../Title';
import { withTranslation } from 'react-i18next';
import { getLangKey } from '../DisplayComponents/DisplayUtils';
import { ERROR, SUCCESS } from '../../Shared/Constants/LanguageKeys';

const RETRIEVE_URL = ONLINEHELP_URL;
const SERVER_URL = ONLINEHELP_URL + "/";

const DELETE_MODAL = {
    hasModal: true,
    name: "Delete",
    modalHeader: "Delete Online Help Tree",
    modalContent: "Are you sure you want to delete this online help tree?"
};

class OnlineHelpMgmtList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            count: 0,
            modalState: undefined,
            searchFilter: "",
            categoryFilter: ""
        };
    }

    componentDidMount = async () => {
        this.loadGrid();
    }

    toggleModal = (modalName, ID) => {
        this.setState({
            modalState : (this.state.modalState !== modalName) ? modalName : undefined,
            modalID : ID
        })
    };

    deleteRow = async () => {
        
        const { loader, t } = this.props;

        // Set URL
        const DELETE_URL = (SERVER_URL + this.state.modalID);
        
        // Start loading
        loader.start();

        // Get results of server-side form posting
        const response = await fetchRequest(DELETE_URL, deleteParams(), false);
        const { IsSuccess, Messages } = response.body;
        if (IsSuccess) {
            // Reload Grid Data after deleting form
            await this.loadGrid();

            toastSuccess(t(getLangKey(SUCCESS.BACKEND_SUCCESS_MESSAGE, Messages)), Messages);
             // End loading
            loader.done();        
        }
        else {
            // Error message to be loaded here
            loader.error(t(getLangKey(ERROR.BACKEND_ERROR_MESSAGE, Messages)));
        }
    }

    onFilterChange = (filterName, value, setFilter) => {
        this.setState({
            [filterName]: value
        }, () => {
            this.filter(setFilter);
        });
    }

    filter = (setFilter) => {
        const filterFields = {
            [FILTER]: this.state.searchFilter,
            [SELECT_FILTERS]: {
                'AppConfigCategory': this.state.categoryFilter
            }
        };
        setFilter(filterFields);
    }

    Filter = (props) => {
        // let categoryOptions = this.state.categoryOptions;
        // let categoryValue = this.state.categoryFilter;
        // categoryValue = categoryValue === "" ? categoryOptions[0] :
        //     _.find(categoryOptions, { 'value': categoryValue });

        return (
            <React.Fragment>
                {/* <Select
                    defaultValue={categoryValue}
                    placeholder="All Categories"
                    onChange={(selectedOption) => this.onFilterChange("categoryFilter", selectedOption.value, props.setFilter)}
                    options={categoryOptions}
                    maxMenuHeight={150}
                    menuPlacement="auto"
                    styles={{
                        menuPortal: styles => ({ ...styles, zIndex: 100 }) //  >= dialog's z-index
                    }}
                    className="select-filter select-list" //  class for css
                /> */}
                <input
                    type="text"
                    name="filter"
                    defaultValue={this.state.searchFilter}
                    placeholder="Filter"
                    className="custom-griddle-filter griddle-filter"
                    onChange={e => this.onFilterChange("searchFilter", e.target.value, props.setFilter)}
                />
            </React.Fragment>
        );
    };

    loadGrid = async () => {
        const response = await fetchRequest(RETRIEVE_URL, getParams(), false);
        
        const { Data, IsSuccess } = response.body;
        if (response.success && IsSuccess) {
            this.setState({
                success: response.success,
                data: Data,
                // categoryOptions: this.getCategoryOptions(Data),
                count: ((Data !== undefined) ? Data.length : 0)
            });
        }
    }

    // getCategoryOptions = (Data) => {
    //     let allCategoriesOption = [{ label: "All Categories", value: "" }];

    //     // Existing Category Options
    //     let categoryOptions = [];
    //     _.forEach(Data, function (appConfig) {
    //         let val = appConfig.AppConfigCategory;
    //         categoryOptions.push({ 'label': val.toString(), 'value': val });
    //     });
        
    //     // Sort Unique Category Options
    //     let otherCategoryOptions = _.sortBy(_.uniqBy(categoryOptions, 'label'), ['label']);
    //     categoryOptions = _.concat(allCategoriesOption, otherCategoryOptions);

    //     return categoryOptions;
    // }

    renderMobileCardActions = (RowData) => {
        return (
            <React.Fragment>
                <MobileCardActionButton
                    onClick={() => navigateTo(`/SystemConfig/OnlineHelp/Update`, { ID: RowData.Id })}
                    icon={faEdit}
                    label='Update'
                />
                <MobileCardActionButton
                    onClick={() => this.toggleModal(DELETE_MODAL.name, RowData.Id)}
                    icon={faTrash}
                    label='Delete'
                />
            </React.Fragment>
        )
    }

    getMobileCardSelectFilterValues = () => {
        let categoryOptions = this.state.categoryOptions;
        let selectFilterValues = [
            {
                name: "AppConfigCategory",
                placeholder: "All Categories",
                options: categoryOptions
            }
        ]
        return selectFilterValues;
    }

    getCustomComponents = () => {
        let customComponents = getCustomComponents();
        customComponents.Filter = this.Filter;

        return customComponents;
    }

    setFilterText = (filterText) => {
        this.setState({
            categoryFilter: filterText[SELECT_FILTERS]['AppConfigCategory'],
            searchFilter: filterText[FILTER]
        })
    }

    renderLayout = () => {
        if (this.state.success) {
            return (
                <React.Fragment>
                    <Button color="neutral" onClick={() => navigateTo("/SystemConfig/OnlineHelp/Create")}>New Online Help Tree</Button>
                    <div className="smart-list">
                        <DataTable
                            pageSize="5"
                            isCustomFilter={true}
                            setFilterText={this.setFilterText}
                            minFilterChars='2'
                            data={this.state.data}
                            noResultsMessage='No online help trees found'
                            components={this.getCustomComponents()}
                            columns={
                                {
                                    RunningNumber: { title: 'No.', width: '5%', DBkey: 'Id' },
                                    Actions: { title: 'Actions', width: '10%', DBkey: 'Actions' },
                                    Name: { title: 'Name', width: '35%', DBkey: 'OnlineHelpName' },
                                    Description: { title: 'Description', width: '50%', DBkey: 'OnlineHelpDescription' },
                                }
                            }
                            renderActions={({RowData}) => (
                                <React.Fragment>
                                    <ActionButton tooltip="Edit" color="forward" onClick={() => navigateTo(`/SystemConfig/OnlineHelp/Update`, { ID: RowData.Id })}><FontAwesomeIcon icon={faEdit} /></ActionButton>
                                    <ActionButton tooltip="Delete" color="hazard" onClick={() => this.toggleModal(DELETE_MODAL.name, RowData.Id)}><FontAwesomeIcon fixedWidth icon={faTrash} /></ActionButton>
                                </React.Fragment>
                            )}
                            getMobileCardSelectFilterValues={this.getMobileCardSelectFilterValues}
                            renderMobileCardActions={this.renderMobileCardActions}
                        />
                        <ModalConfirm
                                isOpen={(this.state.modalState === DELETE_MODAL.name)}
                                contentHeader={DELETE_MODAL.modalHeader}
                                contentBody={DELETE_MODAL.modalContent}
                                confirmText="Confirm"
                                confirmCallback={() => { this.toggleModal(DELETE_MODAL.name); this.deleteRow(); }}
                                cancelText="Cancel"
                                cancelCallback={() => this.toggleModal(DELETE_MODAL.name)}
                            />
                    </div>
                </React.Fragment>
            );
        } else {
            return null;
        }
    }

    render() {
        return (
            <React.Fragment>
                <Title className="first body-title body-title-text" titleValue="Online Help Management" />
                <div className="grid-container">
                    <Row>
                        <Col>
                            {this.renderLayout()} 
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}

export default withTranslation()(withSMARTWrapper(OnlineHelpMgmtList));

