import React from 'react';
import { faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { animateScroll as scroll } from 'react-scroll';

class ScrollToTop extends React.Component {

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    scrollToTop = () => {
        scroll.scrollToTop();
    }

    handleScroll = () => {
        const toTopButton = document.querySelector('.scrollToTop');
        if (document.scrollingElement.scrollTop !== 0) {
            toTopButton.classList.remove('fadeToBottom')
            toTopButton.classList.add('fadeToTop')
        }
        else {
            toTopButton.classList.remove('fadeToTop')
            toTopButton.classList.add('fadeToBottom')
        }
    }

    render() {
        return (
            <div className="scrollToTop circle fadeToTop" onClick={this.scrollToTop}>
                <FontAwesomeIcon icon={faAngleUp} />
            </div>
        );
    }
}

export default ScrollToTop;