import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GET_FILE_URL, GET_FORM_SUPPORTING_DOCUMENT_URL, LAYOUT_TYPE } from '../../../App/AppSettings';
import Section from '../../../Section';
import { ERROR } from '../../../Shared/Constants/LanguageKeys';
import { getParams, useAsyncFetch } from '../../../Shared/Fetch';
import { toastError } from '../../../Shared/Forms/Toaster.js';
import history from '../../../Shared/History';
import { getLangKey } from '../../DisplayComponents/DisplayUtils';
import FileUploadSection from '../../FormComponents/FileUploadSection';

const IS_UPPER = true;

export default function SupportingDocuments(props) {

    const { t } = useTranslation();
    const { state } = history.location;
    const [supportingDocData, setSupportingDocData] = useState([])

    const response = useAsyncFetch(GET_FORM_SUPPORTING_DOCUMENT_URL + state.ID, getParams());

    useEffect(() => {
        async function setData() {
            const { IsSuccess, Messages, Data } = response.body;

            if (response.success && IsSuccess) {
                const data = Data ? Data : [];
                setSupportingDocData(data);
            }
            else {
                // Error message to be loaded here
                toastError(t(getLangKey(ERROR.BACKEND_ERROR_MESSAGE, Messages)), Messages);
            }
        }

        if (response) {
            setData()
        }
    }, [response, t])

    return (
        <>
            {supportingDocData &&
                <Section
                    type={LAYOUT_TYPE.FORM_CONTENT}
                    sectionName={props.sectionName}
                    sectionState={props.sectionState}
                    toggleSection={props.toggleSection}
                >
                    <FileUploadSection
                        name="SupportingDocuments"
                        fileList={supportingDocData}
                        getFileURL={GET_FILE_URL}
                        isUpper={IS_UPPER}
                        readonly
                    />
                </Section>
            }
        </>
    )
}