import { faCaretDown, faCaretRight, faCircle, faEye, faLongArrowAltDown, faLongArrowAltUp, faPlus, faPlusSquare, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Card, CardBody, CardDeck, CardHeader, Col, Row } from 'reactstrap';
import { isValidForm } from '../../Shared/Actions';
import { ActionButton } from '../../Shared/DataTable';
import { RichTextEditor, SMARTSubForm, Text, withSMARTWrapper } from '../../Shared/Forms';
import { ModalConfirm } from '../../Shared/Modal';
import { FIELD_SIZE, ORIENTATION } from '../Constants';
import { BUTTON } from '../Constants/LanguageKeys';
import { DELETE_MODAL, FORM_CONTEXT, FORM_INITIAL_VALUES_CHAPTER, FORM_INITIAL_VALUES_CHAPTER_EDIT, FORM_INITIAL_VALUES_SECTION, FORM_INITIAL_VALUES_SECTION_EDIT, FORM_INITIAL_VALUES_SUBSECTION, FORM_INITIAL_VALUES_SUBSECTION_EDIT, FORM_INITIAL_VALUES_TITLE, FORM_INITIAL_VALUES_TITLE_EDIT, FORM_VALIDATIONS_CHAPTER, FORM_VALIDATIONS_SECTION, FORM_VALIDATIONS_SECTION_EDIT, FORM_VALIDATIONS_SUBSECTION, FORM_VALIDATIONS_SUBSECTION_EDIT, FORM_VALIDATIONS_TITLE, FORM_VALIDATIONS_TITLE_EDIT, UPDATE_TREE_STATUS } from './TreeConstants';

// Documentation:
// 1. Node - Each element on the tree
// 2. Chapter - First node; i.e. Parent node
// 3. Section, Sub-Section, Title - Child nodes of a Chapter node; Title being the final node.
// 4. Chapter, Section & Sub-section nodes have the function to add child nodes. e.g. A chapter node can add multiple section nodes.
// 5. All nodes on the tree have at least 3 functions - View, Edit, Delete.
// 6. Sort function - parent nodes & nodes with the same parent can be sorted.

// Fn: Async process that fetch tree data from given URL

class Tree extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            input: "",
            values_chapter: FORM_INITIAL_VALUES_CHAPTER,
            values_section: FORM_INITIAL_VALUES_SECTION,
            values_subsection: FORM_INITIAL_VALUES_SUBSECTION,
            values_title: FORM_INITIAL_VALUES_TITLE,
            values_chapter_edit: FORM_INITIAL_VALUES_CHAPTER_EDIT,
            values_section_edit: FORM_INITIAL_VALUES_SECTION_EDIT,
            values_subsection_edit: FORM_INITIAL_VALUES_SUBSECTION_EDIT,
            values_title_edit: FORM_INITIAL_VALUES_TITLE_EDIT,
            modalState: undefined,
            renderFilterStatus: false,
            renderFilterChildStatus: false,
            renderSort: false,
            documentReady: false,
            updateTreeStatus: undefined,
            nodeToBeDeleted: undefined,
            minimizeTree: false
        }
        this.onChangeFilter = this.onChangeFilter.bind(this);
    }

    componentDidMount = async () => {
        const { value, loader } = this.props;

        loader.start();
        let data;

        if (value) {
            //Sorts data by ID (Ascending)
            data = this.sortTree(value);
        }
        else {
            data = [];
        }

        await this.setState({
            success: true,
            data: data,
        });
        await this.updateOnlyChildArray();
        await this.listNodes();

        this.setState({
            documentReady: true
        })
        loader.done();

    }

    deleteNode = async () => {
        const { data, nodeToBeDeleted, listChildNodes } = this.state
        data.forEach((chapter) => {
            if (nodeToBeDeleted.ID === chapter.ID) { //find and delete chapter
                _.remove(data, { ID: nodeToBeDeleted.ID })

                this.updateTreeValues(data);
                this.renderNodes();
                return { nodeToBeDeleted };
            }
            else if (nodeToBeDeleted.ParentID === chapter.ID) { //find and delete section
                _.remove(chapter.children, { ID: nodeToBeDeleted.ID })

                this.updateTreeValues(data);
                this.renderNodes();
                return { nodeToBeDeleted };
            }

        })

        listChildNodes.map((e) => {
            let node = e;
            if (node.ID === nodeToBeDeleted.ParentID) {  //find and delete subsection/title
                _.remove(node.children, { ID: nodeToBeDeleted.ID })

                this.updateTreeValues(data);
                this.renderNodes();
            }
            return { nodeToBeDeleted };
        })


    }

    toggleDeleteNodeModal = (modalName, node) => {
        this.setState({
            modalState: (this.state.modalState !== modalName) ? modalName : undefined,
            nodeToBeDeleted: node
        })
    };

    //Toggles a node
    toggleParent = (ob) => {
        ob.toggleParent = !ob.toggleParent;
        this.setState({ ob });
    }

    /******* NODE MANIPULATION START *******/

    //Checks if an element is the final node
    isFinalNode = (node) => {
        let isFinal = false;
        let x = this.state.data.map(y => y.children).reduce((a, b) => a.concat(b), []);
        let y = x.map(z => z.children).reduce((a, b) => a.concat(b), []);
        let q = y.map(z => z.children).reduce((a, b) => a.concat(b), []);

        q.map(function (e) {
            if (e.ID === node.ID) {
                isFinal = true;
            }
            return isFinal;
        })
        return isFinal;
    }

    //Generates a list of nodes where the element is the only child
    updateOnlyChildArray = () => {
        let onlyChildArray = [];
        let fullTree = _.cloneDeep(this.state.data);
        onlyChildArray = this.computeOnlyChildArray(fullTree);

        this.setState({
            onlyChildArray: onlyChildArray
        })
    }

    computeOnlyChildArray = (data) => {
        var OnlyChildArray = []
        if (data.length === 1) {
            OnlyChildArray.push(data[0].ID);
        }
        data.forEach((node) => {
            if (node.children.length > 0) {
                OnlyChildArray = OnlyChildArray.concat(this.computeOnlyChildArray(node.children));
            }
        })
        return OnlyChildArray;
    }

    //Generates list of child nodes & final nodes for sort function
    listNodes = async () => {
        let listFinalNodes = [];
        let listChildNodes = []

        // let z = this.state.data.map(y => y);
        let x = this.state.data.map(y => y.children).reduce((a, b) => a.concat(b), []);
        let y = x.map(z => z.children).reduce((a, b) => a.concat(b), []);
        let q = y.map(z => z.children).reduce((a, b) => a.concat(b), []);

        //Get Final nodes
        let fullTree = _.cloneDeep(this.state.data);
        listFinalNodes = this.computeFinalNodesArray(fullTree);

        //Get Child Nodes
        x.map(function (e) { return listChildNodes.push(e) })
        y.map(function (e) { return listChildNodes.push(e) })
        q.map(function (e) { return listChildNodes.push(e) })
        await this.setState({
            listFinalNodes: listFinalNodes,
            listChildNodes: listChildNodes
        })
    }

    computeFinalNodesArray = (data) => {
        var finalNodesArray = []

        let finalNode = data.slice(-1).pop();

        if (finalNode) {
            finalNodesArray.push(finalNode.ID);
        }

        data.forEach((node) => {
            if (node.children.length > 0) {
                finalNodesArray = finalNodesArray.concat(this.computeFinalNodesArray(node.children));
            }
        })
        return finalNodesArray;
    }

    updateTreeValues = async (oldData) => {
        const { name, onChangeField } = this.props

        let newData = this.sortTree(oldData)
        await this.setState({ data: newData });
        onChangeField(name, JSON.stringify(newData));
        await this.listNodes();
        await this.updateOnlyChildArray();
    }
    //Moves the node one level up
    sortNodeUp = async (element) => {
        let node = _.cloneDeep(element);
        //Sort chapter down

        this.state.data.forEach((chapter) => {
            let topNode = _.cloneDeep(node);
            let bottomNode = _.cloneDeep(node);
            if (node.ID === chapter.ID) {
                chapter.ID = bottomNode.ID - 1;
                return (chapter.children.length > 0) ?
                    (chapter.children.map((section) => {
                        section.ParentID = chapter.ID;
                        return { section };
                    })
                    ) : null;
            }
            else if (node.ID - 1 === chapter.ID) {
                chapter.ID = topNode.ID;
                return (chapter.children.length > 0) ?
                    (chapter.children.map((section) => {
                        section.ParentID = chapter.ID;
                        return { section };
                    })) : null;
            }
            return { chapter };
        })

        //Sort Section down
        this.state.data.forEach((chapter) => {
            return (chapter.children.length > 0) ?
                (chapter.children.map((section) => {
                    let topNode = _.cloneDeep(node);
                    let bottomNode = _.cloneDeep(node);
                    if (node.ID === section.ID) {
                        section.ID = bottomNode.ID - 1;
                        return (section.children.length > 0) ?
                            (section.children.map((subsection) => {
                                subsection.ParentID = section.ID;
                                return { subsection };
                            })
                            ) : null;
                    }
                    else if (node.ID - 1 === section.ID) {
                        section.ID = topNode.ID;
                        return (section.children.length > 0) ?
                            (section.children.map((subsection) => {
                                subsection.ParentID = section.ID;
                                return { subsection };
                            })) : null;
                    }
                    return { section };
                })
                ) : null
        })

        //Sort Subsection down
        this.state.data.forEach((chapter) => {
            return (chapter.children.length > 0) ?
                (chapter.children.map((section) => {
                    return (section.children.length > 0) ? (
                        section.children.map((subsection) => {
                            let topNode = _.cloneDeep(node);
                            let bottomNode = _.cloneDeep(node);
                            if (node.ID === subsection.ID) {
                                subsection.ID = bottomNode.ID - 1;
                                return (subsection.children.length > 0) ?
                                    (subsection.children.map((title) => {
                                        title.ParentID = subsection.ID;
                                        return { title };
                                    })
                                    ) : null;
                            }
                            else if (node.ID - 1 === subsection.ID) {
                                subsection.ID = topNode.ID;
                                return (subsection.children.length > 0) ?
                                    (subsection.children.map((title) => {
                                        title.ParentID = subsection.ID;
                                        return { title };
                                    })) : null;
                            }
                            return { subsection };
                        })
                    ) : null
                })
                ) : null
        })

        //Sort title down
        this.state.data.forEach((chapter) => {
            return (chapter.children.length > 0) ?
                (chapter.children.map((section) => {
                    return (section.children.length > 0) ? (
                        section.children.map((subsection) => {
                            return (subsection.children.length > 0) ? (
                                subsection.children.map((title) => {
                                    let topNode = _.cloneDeep(node);
                                    let bottomNode = _.cloneDeep(node);
                                    if (node.ID === title.ID) {
                                        title.ID = bottomNode.ID - 1;
                                    }
                                    else if (node.ID - 1 === title.ID) {
                                        title.ID = topNode.ID;
                                    }
                                    return { title };
                                })
                            ) : null
                        })
                    ) : null
                })
                ) : null
        })

        await this.updateTreeValues(this.state.data);

        this.renderChildren();
    }

    //Moves the node one level down
    sortNodeDown = async (element) => {
        let node = _.cloneDeep(element);
        //Sort chapter down

        this.state.data.forEach((chapter) => {
            let topNode = _.cloneDeep(node);
            let bottomNode = _.cloneDeep(node);
            if (node.ID === chapter.ID) {
                chapter.ID = topNode.ID + 1;
                return (chapter.children.length > 0) ?
                    (chapter.children.map((section) => {
                        section.ParentID = chapter.ID;
                        return { section };
                    })
                    ) : null;
            }
            else if (node.ID + 1 === chapter.ID) {
                chapter.ID = bottomNode.ID;
                return (chapter.children.length > 0) ?
                    (chapter.children.map((section) => {
                        section.ParentID = chapter.ID;
                        return { section };
                    })) : null;
            }
            return { chapter };
        })

        //Sort Section down
        this.state.data.forEach((chapter) => {
            return (chapter.children.length > 0) ?
                (chapter.children.map((section) => {
                    let topNode = _.cloneDeep(node);
                    let bottomNode = _.cloneDeep(node);
                    if (node.ID === section.ID) {
                        section.ID = topNode.ID + 1;
                        return (section.children.length > 0) ?
                            (section.children.map((subsection) => {
                                subsection.ParentID = section.ID;
                                return { subsection };
                            })
                            ) : null;
                    }
                    else if (node.ID + 1 === section.ID) {
                        section.ID = bottomNode.ID;
                        return (section.children.length > 0) ?
                            (section.children.map((subsection) => {
                                subsection.ParentID = section.ID;
                                return { subsection };
                            })) : null;
                    }
                    return { section };
                })
                ) : null
        })

        //Sort Subsection down
        this.state.data.forEach((chapter) => {
            return (chapter.children.length > 0) ?
                (chapter.children.map((section) => {
                    return (section.children.length > 0) ? (
                        section.children.map((subsection) => {
                            let topNode = _.cloneDeep(node);
                            let bottomNode = _.cloneDeep(node);
                            if (node.ID === subsection.ID) {
                                subsection.ID = topNode.ID + 1;
                                return (subsection.children.length > 0) ?
                                    (subsection.children.map((title) => {
                                        title.ParentID = subsection.ID;
                                        return { title };
                                    })
                                    ) : null;
                            }
                            else if (node.ID + 1 === subsection.ID) {
                                subsection.ID = bottomNode.ID;
                                return (subsection.children.length > 0) ?
                                    (subsection.children.map((title) => {
                                        title.ParentID = subsection.ID;
                                        return { title };
                                    })) : null;
                            }
                            return { subsection };
                        })
                    ) : null
                })
                ) : null
        })

        //Sort title down
        this.state.data.forEach((chapter) => {
            return (chapter.children.length > 0) ?
                (chapter.children.map((section) => {
                    return (section.children.length > 0) ? (
                        section.children.map((subsection) => {
                            return (subsection.children.length > 0) ? (
                                subsection.children.map((title) => {
                                    let topNode = _.cloneDeep(node);
                                    let bottomNode = _.cloneDeep(node);
                                    if (node.ID === title.ID) {
                                        title.ID = topNode.ID + 1;
                                    }
                                    else if (node.ID + 1 === title.ID) {
                                        title.ID = bottomNode.ID;
                                    }
                                    return { title };
                                })
                            ) : null
                        })
                    ) : null
                })
                ) : null
        })

        await this.updateTreeValues(this.state.data);

        this.renderChildren();
    }

    //Sorts the tree according to ID recursively
    sortTree = (data) => {
        data.sort(function (a, b) { return a.Rank - b.Rank })
        data.forEach((node) => {
            if (node.children.length > 0) {
                this.sortTree(node.children);
            }
        })
        return data;
    }

    // Add Chapter to tree json
    addChapterToTree = async (values) => {
        const { data } = this.state;
        let newData = data;
        let newNodeID;

        if (data !== undefined && data.length) {
            newNodeID = data[data.length - 1].ID + 1;
        }
        else {
            newNodeID = 1;
        }

        newData.push({
            ID: newNodeID,
            ParentID: 0,
            name: values.TreeChapter,
            content: values.TreeChapterContent,
            children: []
        });

        await this.updateTreeValues(newData);
        this.renderNodes();
    }

    // Add Section to tree json
    addSectionToTree = async (values) => {
        const { data } = this.state;
        let newData = data;
        let newNodeID;

        if (data[values.Rank - 1] !== undefined && data[values.Rank - 1].children.length) {
            newNodeID = data[values.Rank - 1].children[data[values.Rank - 1].children.length - 1].ID + 1;
        }
        else {
            newNodeID = parseInt(values.Rank + "0");
        }
        newData[values.Rank - 1].children.push(
            {
                ID: newNodeID,
                ParentID: values.TreeParentID,
                Rank: values.Rank,
                name: values.TreeSection,
                content: values.TreeSectionContent,
                children: []
            });
        await this.updateTreeValues(newData);
        this.renderNodes();
    }

    // Add SubSection to tree json
    addSubSectionToTree = async (values) => {
        const { listChildNodes } = this.state;
        let newNodeID;

        listChildNodes.map(function (e) { //Checks if the element passed is a child node
            let node = e;

            if (node.ID === values.TreeParentID) { //Matches the element to a child node by ID
                if (node.children.length) {
                    newNodeID = node.children[node.children.length - 1].ID + 1;
                }
                else {
                    newNodeID = parseInt(node.ID + "0");
                }
                node.children.push(
                    {
                        ID: newNodeID,
                        ParentID: values.TreeParentID,
                        name: values.TreeSubSection,
                        content: values.TreeSubSecContent,
                        children: []
                    }
                );
            }
            return null;
        })
        await this.updateTreeValues(this.state.data);
        this.renderNodes();
    }

    // Add Title to tree json
    addTitleToTree = async (values) => {
        const { listChildNodes } = this.state;
        let newNodeID;

        listChildNodes.map(function (e) { //Checks if the element passed is a child node
            let node = e;
            if (node.ID === values.TreeParentID) { //Matches the element to a child node by ID
                if (node.children.length) {
                    newNodeID = node.children[node.children.length - 1].ID + 1;
                }
                else {
                    newNodeID = parseInt(node.ID + "0");
                }
                node.children.push(
                    {
                        ID: newNodeID,
                        ParentID: values.TreeParentID,
                        name: values.TreeTitle,
                        content: values.TreeTitleContent,
                        children: []
                    }
                );
            }
            return null;
        })
        await this.updateTreeValues(this.state.data);
        this.renderNodes();
    }

    //Edit chapter in tree json
    editChapterInTree = (node) => {
        //Edit a chapter
        this.state.data.forEach((chapter) => {
            if (node.TreeNodeID === chapter.ID) {
                chapter.name = node.TreeChapter;
                chapter.content = node.TreeChapterContent;

                this.updateTreeValues(this.state.data);
                return { chapter };
            }
        })
    }

    //Edit Section in tree json
    editSectionInTree = (node) => {
        //Edit a section
        this.state.data.forEach((chapter) => {
            return chapter.children.map((section) => {
                if (section.ID === node.TreeNodeID) {
                    section.name = node.TreeSection;
                    section.content = node.TreeSectionContent
                    this.updateTreeValues(this.state.data);
                }
                return { section };
            })
        });
    }

    //Edit SubSection in tree json
    editSubSectionInTree = (node) => {
        //Edit a subsection
        this.state.data.forEach((chapter) => {
            return chapter.children.map((section) => {
                return (section.children.length > 0) ? (
                    section.children.map((subsection) => {
                        if (subsection.ID === node.TreeNodeID) {
                            subsection.name = node.TreeSubSection;
                            subsection.content = node.TreeSubSecContent;
                            this.updateTreeValues(this.state.data);
                        }
                        return { subsection };
                    })
                ) : null
            })
        })
    }

    //Edit SubSection in tree json
    editTitleInTree = (node) => {
        //Edit a subsection
        this.state.data.forEach((chapter) => {
            return chapter.children.map((section) => {
                return (section.children.length > 0) ? (
                    section.children.map((subsection) => {
                        return (subsection.children.length > 0) ? (
                            subsection.children.map((title) => {
                                if (title.ID === node.TreeNodeID) {
                                    title.name = node.TreeTitle;
                                    title.content = node.TreeTitleContent;
                                    this.updateTreeValues(this.state.data);
                                }
                                return { title };
                            })
                        ) : null
                    })
                ) : null
            })
        })
    }

    /******* NODE MANIPULATION END *******/

    /******* FILTER MANIPULATION START *******/

    // Filter tree function
    onChangeFilter = (e) => {
        const { data } = this.state;
        const inputValue = e.target.value;
        this.setState({
            input: inputValue,
            renderFilterChildStatus: false
        });

        //Filter Chapter nodes
        let w = data.map(y => y).reduce((a, b) => a.concat(b), []);
        let filterChaptNode = this.filterData(w, inputValue);

        //Filter Section nodes
        let x = data.map(y => y.children).reduce((a, b) => a.concat(b), []);
        let filterSecNode = this.filterData(x, inputValue);

        //Filter Sub-Section nodes
        let y = x.map(z => z.children).reduce((a, b) => a.concat(b), []);
        let filterSubSecNode = this.filterData(y, inputValue);

        //Filter Title nodes
        let q = y.map(z => z.children).reduce((a, b) => a.concat(b), []);
        let filterTitleNode = this.filterData(q, inputValue);

        if (filterChaptNode !== undefined && filterChaptNode.length !== 0 && this.state.input.length >= 2) {
            this.setState({
                filteredNode: filterChaptNode,
                renderFilterStatus: true,
                renderFilterChildStatus: false
            })
        } else if (filterSecNode !== undefined && filterSecNode.length > 0 && this.state.input.length >= 2) {
            let newData = this.getParentChild(filterSecNode, this.state.data);

            //Toggle parent to display filtered child nodes
            newData.forEach(function (e) {
                e.toggleParent = true
            })
            this.setState({
                filteredNode: newData,
                renderFilterStatus: true,
                renderFilterChildStatus: true
            })
        } else if (filterSubSecNode !== undefined && filterSubSecNode.length > 0 && this.state.input.length >= 2) {
            let newData = this.getParentChild(filterSubSecNode, x);
            if (newData.length > 0) {
                let newestData = this.getParentChild(newData, this.state.data);

                //Toggle parent to display filtered child nodes
                newestData.forEach(function (e) {
                    e.toggleParent = true
                })
                newData.forEach(function (e) {
                    e.toggleParent = true
                })
                this.setState({
                    filteredNode: newestData,
                    renderFilterStatus: true,
                    renderFilterChildStatus: true
                })
            }
        } else if (filterTitleNode !== undefined && filterTitleNode.length > 0 && this.state.input.length >= 2) {
            let newData = this.getParentChild(filterTitleNode, y);
            if (newData.length > 0) {
                let newestData = this.getParentChild(newData, x);
                if (newestData.length > 0) {
                    let totalData = this.getParentChild(newestData, this.state.data);

                    //Toggle parent to display filtered child nodes
                    totalData.forEach(function (e) {
                        e.toggleParent = true
                    })
                    newestData.forEach(function (e) {
                        e.toggleParent = true
                    })
                    newData.forEach(function (e) {
                        e.toggleParent = true
                    })
                    this.setState({
                        filteredNode: totalData,
                        renderFilterStatus: true,
                        renderFilterChildStatus: true
                    })
                }
            }
        } else {
            this.setState({
                renderFilterStatus: false,
                renderFilterChildStatus: false
            })
        }
    }

    filterData(data, inputValue) {
        let filteredData = data.filter(item =>
            item.name.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1);

        if (filteredData.length > 0) {
            return filteredData
        } else {
            return []
        }
    }

    //Matches parent and child nodes from ID & ParentID
    getParentChild = (key, tree) => {
        let newData;
        let x = [];
        tree.forEach(treeElement => {
            var node = treeElement;
            key.forEach(element => {
                if (element.ParentID === node.ID) {
                    let y = Object.assign({}, node, {
                        children: [element]
                    })
                    x.push(y)
                };
            });
        })

        //Removes duplicates
        var output = x.reduce(function (x, y) {
            var duplicate = x.reduce(function (n, item, i) {
                return (item.ID === y.ID) ? i : n;
            }, -1);

            if (duplicate >= 0) {
                x[duplicate].children = x[duplicate].children.concat(y.children);

            } else {
                var obj = { ID: y.ID, ParentID: y.ParentID, name: y.name, content: y.content, children: y.children };
                x = x.concat([obj]);
            }
            return x;
        }, []);

        if (output.length > 0) {
            newData = output
        } else {
            newData = x;
        }
        return newData;
    };

    /******* FILTER MANIPULATION END *******/

    /******* DISPLAY MANIPULATION START *******/

    //Adding a chapter
    addChapter = () => {
        this.setState({
            values_chapter: {
                TreeChapter: '',
                TreeChapterContent: ''
            },
            updateTreeStatus: UPDATE_TREE_STATUS.addChaptStatus
        })
    }

    //Adding Section, Sub-section & Title
    addChild = (node) => {
        //Adding a section
        if (node.ParentID === 0) {
            this.setState({
                chaptname: node.name,
                values_section: {
                    TreeParentID: node.ID,
                    TreeChapter: node.name,
                    TreeSection: '',
                    TreeSectionContent: '',
                },
                updateTreeStatus: UPDATE_TREE_STATUS.addSectionStatus
            })
        }

        //Adding a sub-section
        this.state.data.forEach((element) => {
            return element.children.map((child) => {
                return (child.ParentID === element.ID && element.children.includes(node)) ?
                    this.setState({
                        sectionname: node.name,
                        chaptname: element.name,
                        values_subsection: {
                            TreeParentID: node.ID,
                            TreeChapter: element.name,
                            TreeSection: node.name,
                            TreeSubSection: '',
                            TreeSubSecContent: '',
                        },
                        updateTreeStatus: UPDATE_TREE_STATUS.addSubSectionStatus
                    }) : null
            })
        });

        //Adding a title
        this.state.data.forEach((element) => {
            return element.children.map((child) => {
                return (child.children.includes(node) && element.children.includes(child)) ?
                    this.setState({
                        subsectionname: node.name,
                        sectionname: child.name,
                        chaptname: element.name,
                        values_title: {
                            TreeParentID: node.ID,
                            TreeChapter: element.name,
                            TreeSection: child.name,
                            TreeSubSection: node.name,
                            TreeTitle: '',
                            TreeTitleContent: '',
                        },
                        updateTreeStatus: UPDATE_TREE_STATUS.addTitleStatus
                    }) : null
            })
        });
    }

    //Edit tree data
    editNodes = (node) => {
        //Edit a chapter
        this.state.data.forEach((chapter) => {
            if (node.ID === chapter.ID) {
                this.setState({
                    values_chapter_edit: {
                        TreeChapter: node.name,
                        TreeChapterContent: node.content,
                        TreeNodeID: node.ID
                    },
                    updateTreeStatus: UPDATE_TREE_STATUS.editChaptStatus
                })
                return;
            }
        });

        //Edit a section
        this.state.data.forEach((chapter) => {
            return chapter.children.map((section) => {
                return (section.ParentID === chapter.ID && chapter.children.includes(node)) ? (
                    //Reassigns the values of selected node to the form initial values.
                    this.setState({
                        values_section_edit: {
                            TreeSection: node.name,
                            TreeSectionContent: node.content,
                            TreeNodeID: node.ID
                        },
                        updateTreeStatus: UPDATE_TREE_STATUS.editSectionStatus
                    })
                ) : null
            })
        });

        //Edit a sub-section
        this.state.data.forEach((chapter) => {
            return chapter.children.map((section) => {
                return (section.children.length > 0) ? (
                    section.children.map((subsection) => {
                        return (subsection.ParentID === section.ID && section.children.includes(node)) ? (
                            //Reassigns the values of selected node to the form initial values.
                            this.setState({
                                values_subsection_edit: {
                                    TreeSubSection: node.name,
                                    TreeSubSecContent: node.content,
                                    TreeNodeID: node.ID
                                },
                                updateTreeStatus: UPDATE_TREE_STATUS.editSubSectionStatus
                            })
                        ) : null
                    })
                ) : null
            })
        });

        //Edit a title
        this.state.data.forEach((chapter) => {
            return chapter.children.map((section) => {
                return (section.children.length > 0) ? (
                    section.children.map((subsection) => {
                        return (subsection.children.length > 0) ? (
                            subsection.children.map((title) => {
                                return (title.ParentID === subsection.ID && subsection.children.includes(node)) ? (
                                    //Reassigns the values of selected node to the form initial values.
                                    this.setState({
                                        values_title_edit: {
                                            TreeTitle: node.name,
                                            TreeTitleContent: node.content,
                                            TreeNodeID: node.ID
                                        },
                                        updateTreeStatus: UPDATE_TREE_STATUS.editTitleStatus
                                    })
                                ) : null
                            })
                        ) : null
                    })
                ) : null
            })
        });
    }

    renderAddChapter = () => {
        const { t } = this.props;
        return (
            <React.Fragment>
                <SMARTSubForm
                    formContext={FORM_CONTEXT}
                    formValues={this.state.values_chapter}
                    validationSchema={FORM_VALIDATIONS_CHAPTER(t)}
                    validateOnSubmit={true}
                >
                    {({ values, errors, onChange, submitPlainForm, onChangeField }) => (
                        <React.Fragment>
                            <React.Fragment>
                                <Row className="admin-content-wrapper">
                                    <Col>
                                        <Text
                                            name="TreeChapter"
                                            value={values.TreeChapter}
                                            placeholder="Chapter"
                                            onChange={onChange}
                                            minLength={0}
                                            orientation={ORIENTATION.INLINE}
                                            inputSize={FIELD_SIZE.MAX}
                                            label="Chapter"
                                            error={errors.TreeChapter}
                                            required
                                        />
                                        <RichTextEditor
                                            name="TreeChapterContent"
                                            value={values.TreeChapterContent}
                                            onChangeField={onChangeField}
                                            showHtml={false}
                                            orientation={ORIENTATION.INLINE}
                                            inputSize={FIELD_SIZE.MAX}
                                            label="Content"
                                            error={errors.TreeChapterContent}
                                        />
                                        <div className="oh-card-footer">
                                            <Button color="forward" size="sm" type="button" onClick={() => isValidForm(FORM_VALIDATIONS_CHAPTER(t), values) ? this.addChapterToTree(values) : submitPlainForm()}>Add</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </React.Fragment>
                        </React.Fragment>
                    )}
                </SMARTSubForm>
            </React.Fragment>
        );
    }

    renderAddSection = () => {
        const { t } = this.props;
        return (
            <React.Fragment>
                <SMARTSubForm
                    formContext={FORM_CONTEXT}
                    formValues={this.state.values_section}
                    validationSchema={FORM_VALIDATIONS_SECTION(t)}
                    validateOnSubmit={true}
                >
                    {({ values, errors, onChange, onChangeField, submitPlainForm }) => (
                        <React.Fragment>
                            <React.Fragment>
                                <Row className="admin-content-wrapper">
                                    <Col>
                                        <Text
                                            name="TreeChapter"
                                            value={values.TreeChapter}
                                            placeholder="Chapter"
                                            onChange={onChange}
                                            minLength={0}
                                            orientation={ORIENTATION.INLINE}
                                            inputSize={FIELD_SIZE.MAX}
                                            label="Chapter"
                                            readOnly
                                        />
                                        <Text
                                            name="TreeSection"
                                            value={values.TreeSection}
                                            placeholder="Section"
                                            onChange={onChange}
                                            minLength={0}
                                            orientation={ORIENTATION.INLINE}
                                            inputSize={FIELD_SIZE.MAX}
                                            label="Section"
                                            error={errors.TreeSection}
                                            required
                                        />
                                        <RichTextEditor
                                            name="TreeSectionContent"
                                            value={values.TreeSectionContent}
                                            onChangeField={onChangeField}
                                            showHtml={false}
                                            orientation={ORIENTATION.INLINE}
                                            inputSize={FIELD_SIZE.MAX}
                                            label="Content"
                                            error={errors.TreeSectionContent}
                                        />
                                    </Col>
                                </Row>
                                <div className="oh-card-footer">
                                    <Button color="forward" size="sm" type="button" onClick={() => isValidForm(FORM_VALIDATIONS_SECTION(t), values) ? this.addSectionToTree(values) : submitPlainForm()}>{t(BUTTON.ADD)}</Button>
                                </div>
                            </React.Fragment>
                        </React.Fragment>
                    )}
                </SMARTSubForm>
            </React.Fragment>
        );
    }

    renderAddSubSection = () => {
        const { t } = this.props;
        return (
            <React.Fragment>
                <SMARTSubForm
                    formContext={FORM_CONTEXT}
                    formValues={this.state.values_subsection}
                    validationSchema={FORM_VALIDATIONS_SUBSECTION(t)}
                    validateOnSubmit={true}
                >
                    {({ values, errors, onChange, onChangeField, submitPlainForm }) => (
                        <React.Fragment>
                            <React.Fragment>
                                <Row className="admin-content-wrapper">
                                    <Col>
                                        <Text
                                            name="TreeChapter"
                                            value={values.TreeChapter}
                                            placeholder="Chapter"
                                            onChange={onChange}
                                            minLength={0}
                                            orientation={ORIENTATION.INLINE}
                                            inputSize={FIELD_SIZE.MAX}
                                            label="Chapter"
                                            readOnly
                                        />
                                        <Text
                                            name="TreeSection"
                                            value={values.TreeSection}
                                            placeholder="Section"
                                            onChange={onChange}
                                            minLength={0}
                                            orientation={ORIENTATION.INLINE}
                                            inputSize={FIELD_SIZE.MAX}
                                            label="Section"
                                            readOnly
                                        />
                                        <Text
                                            name="TreeSubSection"
                                            value={values.TreeSubSection}
                                            placeholder="Sub-Section"
                                            onChange={onChange}
                                            minLength={0}
                                            orientation={ORIENTATION.INLINE}
                                            inputSize={FIELD_SIZE.MAX}
                                            label="Sub-Section"
                                            error={errors.TreeSubSection}
                                            required
                                        />
                                        <RichTextEditor
                                            name="TreeSubSecContent"
                                            value={values.TreeSubSecContent}
                                            onChangeField={onChangeField}
                                            showHtml={false}
                                            orientation={ORIENTATION.INLINE}
                                            inputSize={FIELD_SIZE.MAX}
                                            label="Content"
                                            error={errors.TreeSubSecContent}
                                        />
                                    </Col>
                                </Row>
                                <div className="oh-card-footer">
                                    <Button color="forward" size="sm" type="button" onClick={() => isValidForm(FORM_VALIDATIONS_SUBSECTION(t), values) ? this.addSubSectionToTree(values) : submitPlainForm()}>{t(BUTTON.ADD)}</Button>
                                </div>
                            </React.Fragment>
                        </React.Fragment>
                    )}
                </SMARTSubForm>
            </React.Fragment>
        );
    }

    renderAddTitle = () => {
        const { t } = this.props;
        return (
            <React.Fragment>
                <SMARTSubForm
                    formContext={FORM_CONTEXT}
                    formValues={this.state.values_title}
                    validationSchema={FORM_VALIDATIONS_TITLE(t)}
                    validateOnSubmit={true}
                >
                    {({ values, errors, onChange, onChangeField, submitPlainForm }) => (
                        <React.Fragment>
                            <React.Fragment>
                                <Row className="admin-content-wrapper">
                                    <Col>
                                        <Text
                                            name="TreeChapter"
                                            value={values.TreeChapter}
                                            placeholder="Chapter"
                                            onChange={onChange}
                                            minLength={0}
                                            orientation={ORIENTATION.INLINE}
                                            inputSize={FIELD_SIZE.MAX}
                                            label="Chapter"
                                            readOnly
                                        />
                                        <Text
                                            name="TreeSection"
                                            value={values.TreeSection}
                                            placeholder="Section"
                                            onChange={onChange}
                                            minLength={0}
                                            orientation={ORIENTATION.INLINE}
                                            inputSize={FIELD_SIZE.MAX}
                                            label="Section"
                                            readOnly
                                        />
                                        <Text
                                            name="TreeSubSection"
                                            value={values.TreeSubSection}
                                            placeholder="Sub-Section"
                                            onChange={onChange}
                                            minLength={0}
                                            orientation={ORIENTATION.INLINE}
                                            inputSize={FIELD_SIZE.MAX}
                                            label="Sub-Section"
                                            readOnly
                                        />
                                        <Text
                                            name="TreeTitle"
                                            value={values.TreeTitle}
                                            placeholder="Title"
                                            onChange={onChange}
                                            minLength={0}
                                            orientation={ORIENTATION.INLINE}
                                            inputSize={FIELD_SIZE.MAX}
                                            label="Title"
                                            error={errors.TreeTitle}
                                            required
                                        />
                                        <RichTextEditor
                                            name="TreeTitleContent"
                                            value={values.TreeTitleContent}
                                            onChangeField={onChangeField}
                                            showHtml={false}
                                            orientation={ORIENTATION.INLINE}
                                            inputSize={FIELD_SIZE.MAX}
                                            label="Content"
                                            error={errors.TreeTitleContent}
                                        />
                                    </Col>
                                </Row>
                                <div className="oh-card-footer">
                                    <Button color="forward" size="sm" type="button" onClick={() => isValidForm(FORM_VALIDATIONS_TITLE(t), values) ? this.addTitleToTree(values) : submitPlainForm()}>{t(BUTTON.ADD)}</Button>
                                </div>
                            </React.Fragment>
                        </React.Fragment>
                    )}
                </SMARTSubForm>
            </React.Fragment>
        );
    }

    renderEditChapter = () => {
        const { renderActions, t } = this.props;
        return (
            <React.Fragment>
                <SMARTSubForm
                    key={this.state.values_chapter_edit.TreeNodeID}
                    formContext={FORM_CONTEXT}
                    formValues={this.state.values_chapter_edit}
                    validationSchema={FORM_VALIDATIONS_CHAPTER(t)}
                    validateOnSubmit={true}
                >
                    {({ values, errors, onChange, onChangeField, submitPlainForm }) => (
                        <React.Fragment>
                            <Row className="admin-content-wrapper">
                                <Col>
                                    <Text
                                        name="TreeChapter"
                                        value={values.TreeChapter}
                                        placeholder="Chapter"
                                        onChange={onChange}
                                        minLength={0}
                                        label="Chapter"
                                        orientation={ORIENTATION.INLINE}
                                        inputSize={FIELD_SIZE.MAX}
                                        error={errors.TreeChapter}
                                        required={renderActions === true ? true : false}
                                    />
                                    <RichTextEditor
                                        name="TreeChapterContent"
                                        value={values.TreeChapterContent}
                                        onChangeField={onChangeField}
                                        showHtml={false}
                                        orientation={ORIENTATION.INLINE}
                                        inputSize={FIELD_SIZE.MAX}
                                        label="Content"
                                        error={errors.TreeChapterContent}
                                    />
                                </Col>
                            </Row>
                            {renderActions === false ? null :
                                <div className="oh-card-footer">
                                    <Button color="neutral" size="sm" type="button" onClick={() => isValidForm(FORM_VALIDATIONS_CHAPTER(t), values) ? this.editChapterInTree(values) : submitPlainForm()}>Update</Button>
                                </div>
                            }
                        </React.Fragment>
                    )}
                </SMARTSubForm>
            </React.Fragment>
        );
    }

    renderEditSection = () => {
        const { renderActions, t } = this.props;
        return (
            <React.Fragment>
                <SMARTSubForm
                    key={this.state.values_section_edit.TreeNodeID}
                    formContext={FORM_CONTEXT}
                    formValues={this.state.values_section_edit}
                    validationSchema={FORM_VALIDATIONS_SECTION_EDIT(t)}
                    validateOnSubmit={true}
                >
                    {({ values, errors, onChange, onChangeField, submitPlainForm }) => (
                        <React.Fragment>
                            <Row className="admin-content-wrapper">
                                <Col>
                                    <Text
                                        name="TreeSection"
                                        value={values.TreeSection}
                                        placeholder="Section"
                                        onChange={onChange}
                                        minLength={0}
                                        label="Section"
                                        orientation={ORIENTATION.INLINE}
                                        inputSize={FIELD_SIZE.MAX}
                                        error={errors.TreeSection}
                                        required={renderActions === true ? true : false}
                                    />
                                    <RichTextEditor
                                        name="TreeSectionContent"
                                        value={values.TreeSectionContent}
                                        onChangeField={onChangeField}
                                        showHtml={false}
                                        orientation={ORIENTATION.INLINE}
                                        inputSize={FIELD_SIZE.MAX}
                                        label="Content"
                                        error={errors.TreeSectionContent}
                                    />
                                </Col>
                            </Row>
                            {renderActions === false ? null :
                                <div className="oh-card-footer">
                                    <Button color="neutral" size="sm" type="button" onClick={() => isValidForm(FORM_VALIDATIONS_SECTION_EDIT(t), values) ? this.editSectionInTree(values) : submitPlainForm()}>{t(BUTTON.UPDATE)}</Button>
                                </div>
                            }
                        </React.Fragment>
                    )}
                </SMARTSubForm>
            </React.Fragment>
        );
    }

    renderEditSubSection = () => {
        const { renderActions, t } = this.props;
        return (
            <React.Fragment>
                <SMARTSubForm
                    key={this.state.values_subsection_edit.TreeNodeID}
                    formContext={FORM_CONTEXT}
                    formValues={this.state.values_subsection_edit}
                    validationSchema={FORM_VALIDATIONS_SUBSECTION_EDIT(t)}
                    validateOnSubmit={true}
                >
                    {({ values, errors, onChange, onChangeField, submitPlainForm }) => (
                        <React.Fragment>
                            <Row className="admin-content-wrapper">
                                <Col>
                                    <Text
                                        name="TreeSubSection"
                                        value={values.TreeSubSection}
                                        placeholder="Sub-Section"
                                        onChange={onChange}
                                        minLength={0}
                                        orientation={ORIENTATION.INLINE}
                                        inputSize={FIELD_SIZE.MAX}
                                        label="Sub-Section"
                                        error={errors.TreeSubSection}
                                        required={renderActions === true ? true : false}
                                    />
                                    <RichTextEditor
                                        name="TreeSubSecContent"
                                        value={values.TreeSubSecContent}
                                        onChangeField={onChangeField}
                                        showHtml={false}
                                        orientation={ORIENTATION.INLINE}
                                        inputSize={FIELD_SIZE.MAX}
                                        label="Content"
                                        error={errors.TreeSubSecContent}
                                    />
                                </Col>
                            </Row>
                            {renderActions === false ? null :
                                <div className="oh-card-footer">
                                    <Button color="neutral" size="sm" type="button" onClick={() => isValidForm(FORM_VALIDATIONS_SUBSECTION_EDIT(t), values) ? this.editSubSectionInTree(values) : submitPlainForm()}>{t(BUTTON.UPDATE)}</Button>
                                </div>
                            }
                        </React.Fragment>
                    )}
                </SMARTSubForm>
            </React.Fragment>
        );
    }

    renderEditTitle = () => {
        const { renderActions,t } = this.props;
        return (
            <React.Fragment>
                <SMARTSubForm
                    key={this.state.values_title_edit.TreeNodeID}
                    formContext={FORM_CONTEXT}
                    formValues={this.state.values_title_edit}
                    validationSchema={FORM_VALIDATIONS_TITLE_EDIT(t)}
                    validateOnSubmit={true}
                >
                    {({ values, errors, onChange, onChangeField, submitPlainForm }) => (
                        <React.Fragment>
                            <Row className="admin-content-wrapper">
                                <Col>
                                    <Text
                                        name="TreeTitle"
                                        value={values.TreeTitle}
                                        placeholder="Title"
                                        onChange={onChange}
                                        minLength={0}
                                        label="Title"
                                        orientation={ORIENTATION.INLINE}
                                        inputSize={FIELD_SIZE.MAX}
                                        error={errors.TreeTitle}
                                        required={renderActions === true ? true : false}
                                    />
                                    <RichTextEditor
                                        name="TreeTitleContent"
                                        value={values.TreeTitleContent}
                                        onChangeField={onChangeField}
                                        showHtml={false}
                                        orientation={ORIENTATION.INLINE}
                                        inputSize={FIELD_SIZE.MAX}
                                        label="Content"
                                        error={errors.TreeTitleContent}
                                    />
                                </Col>
                            </Row>
                            {renderActions === false ? null :
                                <div className="oh-card-footer">
                                    <Button color="neutral" size="sm" type="button" onClick={() => isValidForm(FORM_VALIDATIONS_TITLE_EDIT(t), values) ? this.editTitleInTree(values) : submitPlainForm()}>{t(BUTTON.UPDATE)}</Button>
                                </div>
                            }
                        </React.Fragment>
                    )}
                </SMARTSubForm>
            </React.Fragment>
        );
    }

    UpdateTreeCardDetails = {
        addChaptStatus: { title: "New Chapter", noActionTitle: "View Chapter", body: this.renderAddChapter },
        addSectionStatus: { title: "New Section", noActionTitle: "View Section", body: this.renderAddSection },
        addSubSectionStatus: { title: "New Sub-Section", noActionTitle: "View Sub-Section", body: this.renderAddSubSection },
        addTitleStatus: { title: "New Title", noActionTitle: "View Title", body: this.renderAddTitle },
        editChaptStatus: { title: "View|Edit Chapter", noActionTitle: "View Chapter", body: this.renderEditChapter },
        editSectionStatus: { title: "View|Edit Section", noActionTitle: "View Section", body: this.renderEditSection },
        editSubSectionStatus: { title: "View|Edit Sub-Section", noActionTitle: "View Sub-Section", body: this.renderEditSubSection },
        editTitleStatus: { title: "View|Edit Title", noActionTitle: "View Title", body: this.renderEditTitle },
    }

    // Render tree data
    renderChildren = (node) => {
        const { renderActions } = this.props;
        if (typeof node === 'undefined' || node.length === 0) {
            return null;
        } else {
            let elements = [];
            elements = node.map((element, index) => {
                let item = null;
                let node;
                // A node with children
                if (element.children.length > 0 && element.toggleParent) {
                    let children = this.renderChildren(element.children);
                    (
                        renderActions === false ?
                            node = (
                                <React.Fragment>
                                    <div className="node">
                                        <i className="fa-close"><FontAwesomeIcon fixedWidth icon={faCaretDown} /></i><span className="node-name">{element.name}</span>
                                        <span className="actions">
                                            <i className="fa-edit" onClick={(e) => { e.stopPropagation(); this.editNodes(element) }}><FontAwesomeIcon fixedWidth icon={faEye} /></i>
                                        </span>
                                    </div>
                                </React.Fragment>
                            ) :
                            node = (
                                <React.Fragment>
                                    <div className="node">
                                        <i className="fa-close"><FontAwesomeIcon fixedWidth icon={faCaretDown} /></i><span className="node-name">{element.name}</span>
                                        <span className="actions">
                                            <i className="fa-add" onClick={(e) => { e.stopPropagation(); this.addChild(element) }}><FontAwesomeIcon fixedWidth icon={faPlusSquare} /></i>
                                            <i className="fa-edit" onClick={(e) => { e.stopPropagation(); this.editNodes(element) }}><FontAwesomeIcon fixedWidth icon={faEye} /></i>
                                            <i className="fa-del" onClick={(e) => { e.stopPropagation(); this.toggleDeleteNodeModal(DELETE_MODAL.name, element) }}><FontAwesomeIcon fixedWidth icon={faTrash} /></i>
                                            {index === 0 && this.state.onlyChildArray.includes(element.ID) === true ? null : index > 0 && this.state.listFinalNodes.includes(element.ID) === true ? <i className="fa-sort" onClick={(e) => { e.stopPropagation(); this.sortNodeUp(element) }}><FontAwesomeIcon fixedWidth icon={faLongArrowAltUp} /></i> :
                                                index === 0 && this.state.onlyChildArray.includes(element.ID) === false ? <i className="fa-sort" onClick={(e) => { e.stopPropagation(); this.sortNodeDown(element) }}><FontAwesomeIcon fixedWidth icon={faLongArrowAltDown} /></i> :
                                                    <React.Fragment>
                                                        <i className="fa-sort" onClick={(e) => { e.stopPropagation(); this.sortNodeUp(element) }}><FontAwesomeIcon fixedWidth icon={faLongArrowAltUp} /></i>
                                                        <i className="fa-sort" onClick={(e) => { e.stopPropagation(); this.sortNodeDown(element) }}><FontAwesomeIcon fixedWidth icon={faLongArrowAltDown} /></i>
                                                    </React.Fragment>
                                            }
                                        </span>
                                    </div>
                                </React.Fragment>
                            )
                    )
                    item = (
                        <li key={index} onClick={(e) => { e.stopPropagation(); this.toggleParent(element) }}>
                            {node}
                            {children}
                        </li>
                    )
                }

                // Toggle parent node
                else if (element.children.length > 0 && !element.toggleParent) {
                    item = (
                        <li key={index} onClick={(e) => { e.stopPropagation(); this.toggleParent(element) }}>
                            <div className="node"><i className="fa-open"><FontAwesomeIcon fixedWidth icon={faCaretRight} /></i>
                                <span className="node-name">{element.name}</span>
                                <span className="actions">
                                    <i className="fa-add" onClick={(e) => { e.stopPropagation(); this.addChild(element) }}><FontAwesomeIcon fixedWidth icon={faPlusSquare} /></i>
                                    <i className="fa-edit" onClick={(e) => { e.stopPropagation(); this.editNodes(element) }}><FontAwesomeIcon fixedWidth icon={faEye} /></i>
                                    <i className="fa-del" onClick={(e) => { e.stopPropagation(); this.toggleDeleteNodeModal(DELETE_MODAL.name, element) }}><FontAwesomeIcon fixedWidth icon={faTrash} /></i>
                                    {index === 0 && this.state.onlyChildArray.includes(element.ID) === true ? null : index > 0 && this.state.listFinalNodes.includes(element.ID) === true ? <i className="fa-sort" onClick={(e) => { e.stopPropagation(); this.sortNodeUp(element) }}><FontAwesomeIcon fixedWidth icon={faLongArrowAltUp} /></i> :
                                        index === 0 && this.state.onlyChildArray.includes(element.ID) === false ? <i className="fa-sort" onClick={(e) => { e.stopPropagation(); this.sortNodeDown(element) }}><FontAwesomeIcon fixedWidth icon={faLongArrowAltDown} /></i> :
                                            <React.Fragment>
                                                <i className="fa-sort" onClick={(e) => { e.stopPropagation(); this.sortNodeUp(element) }}><FontAwesomeIcon fixedWidth icon={faLongArrowAltUp} /></i>
                                                <i className="fa-sort" onClick={(e) => { e.stopPropagation(); this.sortNodeDown(element) }}><FontAwesomeIcon fixedWidth icon={faLongArrowAltDown} /></i>
                                            </React.Fragment>
                                    }
                                </span>
                            </div>
                        </li>
                    );
                }

                // A final node or a node with no children
                else if (element.children.length === 0) {
                    renderActions === false ?
                        node = (
                            <React.Fragment>
                                <div className="node">
                                    <i><FontAwesomeIcon className="tree-fa-circle" fixedWidth icon={faCircle} /></i><span className="node-name">{element.name}</span>
                                    <span className="actions">
                                        <i className="fa-edit" onClick={(e) => { e.stopPropagation(); this.editNodes(element) }}><FontAwesomeIcon fixedWidth icon={faEye} /></i>
                                    </span>
                                </div>
                            </React.Fragment>
                        ) :
                        node = (
                            <React.Fragment>

                                <div className="node"><i><FontAwesomeIcon className="tree-fa-circle" fixedWidth icon={faCircle} /></i><span className="node-name">{element.name}</span>
                                    <span className="actions">
                                        {this.isFinalNode(element) === false && <i className="fa-add" onClick={(e) => { e.stopPropagation(); this.addChild(element) }}><FontAwesomeIcon fixedWidth icon={faPlusSquare} /></i>}
                                        <i className="fa-edit" onClick={(e) => { e.stopPropagation(); this.editNodes(element) }}><FontAwesomeIcon fixedWidth icon={faEye} /></i>
                                        <i className="fa-del" onClick={(e) => { e.stopPropagation(); this.toggleDeleteNodeModal(DELETE_MODAL.name, element) }}><FontAwesomeIcon fixedWidth icon={faTrash} /></i>
                                        {index === 0 && this.state.onlyChildArray.includes(element.ID) === true ?
                                            null :
                                            index > 0 && this.state.listFinalNodes.includes(element.ID) === true ?
                                                <i className="fa-sort" onClick={(e) => { e.stopPropagation(); this.sortNodeUp(element) }}><FontAwesomeIcon fixedWidth icon={faLongArrowAltUp} /></i>
                                                :
                                                index === 0 && this.state.onlyChildArray.includes(element.ID) === false ?
                                                    <i className="fa-sort" onClick={(e) => { e.stopPropagation(); this.sortNodeDown(element) }}><FontAwesomeIcon fixedWidth icon={faLongArrowAltDown} /></i>
                                                    :
                                                    <React.Fragment>
                                                        <i className="fa-sort" onClick={(e) => { e.stopPropagation(); this.sortNodeUp(element) }}><FontAwesomeIcon fixedWidth icon={faLongArrowAltUp} /></i>
                                                        <i className="fa-sort" onClick={(e) => { e.stopPropagation(); this.sortNodeDown(element) }}><FontAwesomeIcon fixedWidth icon={faLongArrowAltDown} /></i>
                                                    </React.Fragment>
                                        }
                                    </span>
                                </div>
                            </React.Fragment>
                        )
                    item = (
                        <li key={index} onClick={(e) => e.stopPropagation()}>
                            {node}
                        </li>
                    );
                }
                return item;
            });
            return (
                <ul >
                    {elements}
                </ul>
            );
        }
    }

    renderNodes = () => {
        const { className } = this.props;
        if (!this.state.success) return null;
        let elements;
        (this.state.input.length > 2 && this.state.renderFilterStatus === true ? elements = this.renderChildren(this.state.filteredNode) : elements = this.renderChildren(this.state.data));
        return (
            <div className={"tree " + className}>
                {elements}
            </div>
        );
    }

    /******* DISPLAY MANIPULATION END *******/

    toggleMinimizeTree = () => {
        this.setState({
            minimizeTree: !this.state.minimizeTree
        })
    }

    render() {
        const { renderActions, className } = this.props;
        return (
            <React.Fragment>
                <Button color="neutral" tooltip="Toggle Tree View" onClick={() => this.toggleMinimizeTree()} > {this.state.minimizeTree ? "Expand Tree" : "Minimize Tree"}</Button>
                <CardDeck className="codetable-mgmt-list">
                    <Col xl={this.state.minimizeTree ? "3" : "6"}>
                        <Card className={"tree-card " + className}>
                            <CardHeader className={"tree-card-header " + className}><p>{this.state.minimizeTree ? null : this.props.treeHeader}</p>
                                {this.state.minimizeTree ? null : <input
                                    className="tree-tb"
                                    type="text"
                                    placeholder="Filter"
                                    onChange={this.onChangeFilter}
                                >
                                </input>}
                                {renderActions && <ActionButton className='add-button' tooltip="Add Chapter" onClick={() =>
                                    this.addChapter()}><FontAwesomeIcon className='fa-addChapt' icon={faPlus} /></ActionButton>}
                            </CardHeader>
                            <CardBody className={"tree-card-body" + className}>
                                {this.state.documentReady && this.renderNodes()}
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xl={this.state.minimizeTree ? "9" : "6"}>
                        <Card className={"tree-card " + className}>
                            <CardHeader className={"tree-card-header " + className}>
                                <p>
                                    {this.state.updateTreeStatus &&
                                        (renderActions ?
                                            this.UpdateTreeCardDetails[this.state.updateTreeStatus].title
                                            : this.UpdateTreeCardDetails[this.state.updateTreeStatus].noActionTitle)
                                    }

                                </p>
                            </CardHeader>
                            <CardBody>
                                {this.state.updateTreeStatus && this.UpdateTreeCardDetails[this.state.updateTreeStatus].body()}
                            </CardBody>
                        </Card>
                    </Col>
                </CardDeck>
                <ModalConfirm
                    isOpen={(this.state.modalState === DELETE_MODAL.name)}
                    contentHeader={DELETE_MODAL.modalHeader}
                    contentBody={DELETE_MODAL.modalContent}
                    confirmText="Confirm"
                    confirmCallback={() => { this.toggleDeleteNodeModal(DELETE_MODAL.name); this.deleteNode(); }}
                    cancelText="Cancel"
                    cancelCallback={() => this.toggleDeleteNodeModal(DELETE_MODAL.name)}
                />
            </React.Fragment>
        );
    }
}

// PropTypes: For prop validation
Tree.propTypes = {
    renderActions: PropTypes.bool.isRequired,
    className: PropTypes.string
};

Tree.defaultProps = {
    className: ""
}

export default withSMARTWrapper(Tree);
