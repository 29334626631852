import { Button as AntdButton } from 'antd';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { compose } from 'recompose';
import * as Yup from 'yup';
import { DATE_DISPLAY_FORMAT, EDIT_SUSPEND_LICENCE_URL, GET_LICENCE_DETAILS, GET_LICENCE_SUSPEND_END_DATE, LAYOUT_TYPE } from '../../App/AppSettings';
import Layout from '../../Layout';
import { fetchRequest, getParams, navigateTo } from '../../Shared/Actions';
import { FIELD_SIZE, FILE_UPLOAD_SECTION_FILES } from '../../Shared/Constants';
import { ERROR, LANGUAGE_KEYS, SUCCESS } from '../../Shared/Constants/LanguageKeys';
import { AccordionContainer, DateInput, Paragraph, Plaintext, SMARTForm, toastError, toastSuccess } from '../../Shared/Forms';
import history from '../../Shared/History';
import { withLoader } from '../../Shared/Loading';
import PageFooter from '../../Shared/PageFooter';
import TitleChildren from '../../Title/TitleChildren';
import { getLangKey } from '../DisplayComponents/DisplayUtils';
import FileUploadSection from '../FormComponents/FileUploadSection';

const LABEL_SIZE = 6;
const INPUT_SIZE = 6;

const SERVICE_MANAGEMENT_PATH = '/service/management';

const FORM_INITIAL_VALUES = {
    FileUploadSectionFiles: [],
    SuspensionRemarks: '',
    SuspensionEndDate: ''
}

const FORM_CONTEXT = 'EditSuspendLicence';

class EditSuspendLicence extends React.Component {
    constructor(props) {
        super(props);
        const { t } = this.props;
        this.state = {
            licence: null,
            SECTION_NAMES : {
                SERVICE_OVERVIEW: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEMANAGEMENT_CONTENT_SECTION_SERVICEOVERVIEW), status: true },
                SUSPEND_INPUT: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEMANAGEMENT_CONTENT_SECTION_EDITSUSPENSIONDETAILS), status: true }
            },
            DATE_HELP_TEXT : t(ERROR.DATE_LATER_THAN_EXPIRY),
            DATE_ERROR_TEXT : t(ERROR.DATE_EQUAL_PREVIOUS_SUSPENSION),
      
        };
        if (history.location.state) {
            this.state = {
                licence: history.location.state.licence,
                values: FORM_INITIAL_VALUES,
                validationSchema: {},
                SECTION_NAMES : {
                    SERVICE_OVERVIEW: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEMANAGEMENT_CONTENT_SECTION_SERVICEOVERVIEW), status: true },
                    SUSPEND_INPUT: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEMANAGEMENT_CONTENT_SECTION_EDITSUSPENSIONDETAILS), status: true }
                },
                DATE_HELP_TEXT : t(ERROR.DATE_LATER_THAN_EXPIRY),
                DATE_ERROR_TEXT : t(ERROR.DATE_EQUAL_PREVIOUS_SUSPENSION),
          
            }
        }
    }

    componentDidMount() {
        const { loader } = this.props;

        // TODO: Refactor SN4
        loader.start();
        this.getLicenceData(loader);
    }

    getLicenceData = async (loader) => {
        const response = await fetchRequest(GET_LICENCE_DETAILS + this.state.licence.LicenceID, getParams(), false);
        const { IsSuccess, Data } = response.body;
        const { t } = this.props;

        if (IsSuccess) {
            this.setState((prevState) => {
                return {
                    licence: Object.assign(prevState.licence, Data, { LicenceData: JSON.parse(Data.LicenceData) }),
                    values: Object.assign(prevState.values, {
                        LicenceID: history.location.state.licence.LicenceID,
                        LicenceNumber: history.location.state.licence.LicenceNumber
                    }),
                    licenceDetailsLoaded: true,
                }
            }, () => this.getPreviousEndDate(loader)
            )
            this.stopLoader(loader);
        } else {
            this.setState({
                licenceDetailsLoaded: true,
            })
            toastError(t(ERROR.SERVICE_DATA_NOT_FETCHED));
            this.stopLoader(loader);
        }
    }

    getPreviousEndDate = async (loader) => {
        const response = await fetchRequest(GET_LICENCE_SUSPEND_END_DATE + this.state.licence.LicenceID, getParams(), false);
        const { IsSuccess, Data } = response.body;
        const { t } = this.props;

        if (IsSuccess) {

            this.setState((prevState) => {
                return {
                    previousEndDate: Data,
                    endDateLoaded: true,
                    values: Object.assign(prevState.values, {
                        SuspensionEndDate: Data
                    }),
                    validationSchema: this.getValidationSchema(Data, prevState.licence.ExpiryDate)
                }
            })
            this.stopLoader(loader);
        } else {
            this.setState({
                endDateLoaded: true,
            })
            toastError(t(ERROR.PREV_END_DATE_NOT_FETCHED));
            this.stopLoader(loader);
        }
    }

    getValidationSchema = (prevDate, licExpiryDate) => {
        const { t } = this.props;
        return Yup.object().shape({
            FileUploadSectionFiles: Yup.array().required((ERROR.LABEL) + t(ERROR.REQUIRED)),
            SuspensionRemarks: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED)).min(0, t(ERROR.MIN_LENGTH_LIMIT) + (ERROR.MIN_LENGTH_LABEL)).max(500, t(ERROR.MAX_LENGTH_LIMIT) + (ERROR.MAX_LENGTH_LABEL)),
            SuspensionEndDate: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED))
            .test("isValidDate",
                this.state.DATE_ERROR_TEXT, 
                function(value) {
                    let oldDate = new Date(prevDate);
                    let expiryDate = new Date(licExpiryDate);
                    let inputDate = new Date(value);

                    return (inputDate.getTime() !== oldDate.getTime() && inputDate <= expiryDate);
            })
        })
    }

    // Loader done
    stopLoader = async (loader) => {
        const { licenceDetailsLoaded, endDateLoaded } = this.state;

        if (licenceDetailsLoaded && endDateLoaded) {
            loader.done();
        }
    }

    renderTitleChildren = () => {
        const { t } = this.props;
        return (
            <TitleChildren>
                <Row className="title-custom">
                    <Col className="title-actions">
                        <AntdButton
                            className="btn-backward"
                            onClick={() => history.goBack()}
                        >
                            {t(LANGUAGE_KEYS.BLS_INTERNALLICENCEMANAGEMENT_NAVIGATION_BUTTON_BACK)}
                        </AntdButton>
                    </Col>
                </Row>
            </TitleChildren>
        )
    }

    submitCallback = async ({ response }) => {
        const { t } = this.props;
        if (response.success) {
            const { IsSuccess, Messages } = response.body;
            if (IsSuccess) {
                navigateTo(SERVICE_MANAGEMENT_PATH);
                toastSuccess(t(SUCCESS.SERVICE_SUSPENSION_UPDATE_SUCCESS));
            }
            else {
                toastError(t(getLangKey(ERROR.BACKEND_ERROR_MESSAGE, Messages)), Messages);
            }
        } else {
            toastError(t(ERROR.SERVER_UNREACHABLE))
        }
    }

    render() {
        const { t } = this.props;
        const Licence = this.state.licence;
        const { LicenceData } = Licence;
        const { GeneralInformation } = LicenceData ? LicenceData : '';
        const Entity = GeneralInformation ? GeneralInformation.GI_EntitydetailsEntityname : null;

        return (
            <SMARTForm
                formContext={FORM_CONTEXT}
                isDummy={false}
                formValues={this.state.values}
                serverURL={EDIT_SUSPEND_LICENCE_URL}
                nextURL={SERVICE_MANAGEMENT_PATH}
                validateOnSubmit={true}
                submitCallback={this.submitCallback}
                validationSchema={this.state.validationSchema}
            >
                {({ toggleSection, onChange, onChangeField, values, errors, submitForm }) => (
                    <Layout type={LAYOUT_TYPE.FORM} title={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEMANAGEMENT_CONTENT_TITLE_SUSPENDSERVICE)} footer={false} titleClassName="sticky-title" titleChildren={this.renderTitleChildren()}>
                        <>
                            <Row className="body-content internal-content">
                                <Col className="body-section-col">
                                    <AccordionContainer
                                        title={this.state.SECTION_NAMES.SERVICE_OVERVIEW.title}
                                        active={this.state.SECTION_NAMES.SERVICE_OVERVIEW.status}
                                        onClick={toggleSection}
                                        isIndividual={true}
                                        sections={this.state.SECTION_NAMES}
                                    >
                                        {Licence && GeneralInformation &&
                                            <>
                                                <Plaintext
                                                    label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEMANAGEMENT_FORM_FIELD_SERVICENAME)}
                                                    name="LicenceName"
                                                    className="overview-margin"
                                                    value={Licence.LicenceName}
                                                    labelSize={LABEL_SIZE}
                                                    inputSize={INPUT_SIZE}
                                                />
                                                <Plaintext
                                                    label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEMANAGEMENT_FORM_FIELD_SERVICENUMBER)}
                                                    name="LicenceNumber"
                                                    className="overview-margin"
                                                    value={Licence.LicenceNumber}
                                                    labelSize={LABEL_SIZE}
                                                    inputSize={INPUT_SIZE}
                                                />
                                                <Plaintext
                                                    label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEMANAGEMENT_FORM_FIELD_APPLICANT)}
                                                    name="Licensee"
                                                    className="overview-margin"
                                                    value={GeneralInformation.GI_Name}
                                                    labelSize={LABEL_SIZE}
                                                    inputSize={INPUT_SIZE}
                                                />
                                                <Plaintext
                                                    label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEMANAGEMENT_FORM_FIELD_SERVICESTATUS)}
                                                    name="LicenceStatus"
                                                    className="overview-margin"
                                                    value={Licence.LicenceStatus}
                                                    labelSize={LABEL_SIZE}
                                                    inputSize={INPUT_SIZE}
                                                />
                                                <Plaintext
                                                    label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEMANAGEMENT_FORM_FIELD_AGENCY2)}
                                                    name="Agency"
                                                    className="overview-margin"
                                                    value={Licence.AgencyName}
                                                    labelSize={LABEL_SIZE}
                                                    inputSize={INPUT_SIZE}
                                                />
                                                {Entity &&
                                                    <Plaintext
                                                        label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEMANAGEMENT_FORM_FIELD_ENTITY)}
                                                        name="Entity"
                                                        className="overview-margin"
                                                        value={Entity}
                                                        labelSize={LABEL_SIZE}
                                                        inputSize={INPUT_SIZE}
                                                    />
                                                }
                                                <Plaintext
                                                    label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEMANAGEMENT_FORM_FIELD_ISSUEDDATE2)}
                                                    name="IssuedDate"
                                                    className="overview-margin"
                                                    value={Licence.IssuedDate}
                                                    labelSize={LABEL_SIZE}
                                                    inputSize={INPUT_SIZE}
                                                />
                                                <Plaintext
                                                    label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEMANAGEMENT_FORM_FIELD_EXPIRYDATE2)}
                                                    name="ExpiryDate"
                                                    className="overview-margin"
                                                    value={Licence.ExpiryDate}
                                                    labelSize={LABEL_SIZE}
                                                    inputSize={INPUT_SIZE}
                                                />
                                            </>
                                        }
                                    </AccordionContainer>
                                    <AccordionContainer
                                        title={this.state.SECTION_NAMES.SUSPEND_INPUT.title}
                                        active={this.state.SECTION_NAMES.SUSPEND_INPUT.status}
                                        onClick={toggleSection}
                                        isIndividual={true}
                                        sections={this.state.SECTION_NAMES}
                                    >
                                        <FileUploadSection
                                            name={FILE_UPLOAD_SECTION_FILES}
                                            fileList={values.FileUploadSectionFiles}
                                            onChangeField={onChangeField}
                                            sectionName="Supporting Document"
                                            error={errors.FileUploadSectionFiles}
                                            required

                                        />

                                        <DateInput
                                            name="SuspensionEndDate"
                                            value={values.SuspensionEndDate}
                                            placeholder={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEMANAGEMENT_FORM_FIELD_SUSPENSIONENDDATE)}
                                            onChangeField={onChangeField}
                                            time={false}
                                            date={true}
                                            min={new Date().setHours(0, 0, 0, 0)}
                                            max={Licence.ExpiryDate}
                                            format={DATE_DISPLAY_FORMAT.DATE}
                                            inputSize={FIELD_SIZE.LARGE}
                                            label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEMANAGEMENT_FORM_FIELD_SUSPENSIONENDDATE)}
                                            error={errors.SuspensionEndDate}
                                            required
                                            helpLabel={errors.SuspensionEndDate ? "" : this.state.DATE_HELP_TEXT}
                                        />
                                        <Paragraph
                                            name="SuspensionRemarks"
                                            value={values.SuspensionRemarks}
                                            placeholder={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEMANAGEMENT_FORM_FIELD_SUSPENSIONREMARKS)}
                                            onChange={onChange}
                                            rows={5}
                                            minLength={0}
                                            maxLength={500}
                                            inputSize={FIELD_SIZE.LARGE}
                                            label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEMANAGEMENT_FORM_FIELD_SUSPENSIONREMARKS)}
                                            error={errors.SuspensionRemarks}
                                            required
                                        />
                                    </AccordionContainer>
                                </Col>
                            </Row>
                            <PageFooter type={LAYOUT_TYPE.FORM}>
                                {/* Submit Button */}
                                <Button color="forward" size="sm" type="button" onClick={submitForm}>{t(LANGUAGE_KEYS.BLS_INTERNALLICENCEMANAGEMENT_NAVIGATION_BUTTON_SUBMIT)}</Button>
                            </PageFooter>
                        </>
                    </Layout>
                )}
            </SMARTForm>
        );
    }
}

export default withTranslation()(compose(withLoader, withRouter)(EditSuspendLicence));