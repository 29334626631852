import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GET_PREVIEW_SERVICE_SAC, GET_PREVIEW_SERVICE_SC, LAYOUT_TYPE } from '../../App/AppSettings';
import Layout from '../../Layout';
import { getParams, getRequest } from '../../Shared/Actions';
import { PREVIEW_SERVICE_STATUS } from '../../Shared/Constants';
import { ERROR, LANGUAGE_KEYS } from '../../Shared/Constants/LanguageKeys';
import { ActionButton, DataTable } from '../../Shared/DataTable';
import { getCustomComponents } from '../../Shared/DataTable/BaseTable.js';
import { toastError } from '../../Shared/Forms';
import { Modal } from '../../Shared/Modal';
import { getLangKey, getLicenceTypeNameTranslationKey } from '../DisplayComponents/DisplayUtils';
import LiveConfigurationDetailModal from './LiveConfigurationDetailModal';

const MODAL_NAMES = {
    PREVIEW_SERVICE_POP_UP: 'Preview_Service_Pop_Up'
};

export default function Live(props) {
    const { t } = useTranslation();
    const { loader } = props;
    //const { values, onChangeField } = props.smartFormValues;
    const [data, setData] = useState(props.data);
    const [lcDetailData, setLCDetailData] = useState([]);
    const [lacDetailData, setLACDetailData] = useState([]);
    const [headerAgencyName, setHeaderAgencyName] = useState('');
    const [headerLicenceName, setHeaderLicenceName] = useState('');

    const openLicenceViewModal = async (modal, licenceName, agencyName) => {
        loader.start();
        const response = await getRequest(`${GET_PREVIEW_SERVICE_SC}${PREVIEW_SERVICE_STATUS.PRODUCTION}/${licenceName}`, getParams());
        const { IsSuccess, Messages, Data } = response.body;
        setHeaderAgencyName(agencyName);
        setHeaderLicenceName(licenceName);
        if (IsSuccess) {
            setLCDetailData(Data);
        } else {
            toastError(t(getLangKey(ERROR.BACKEND_ERROR_MESSAGE, Messages)), Messages);
        }
        getLicenceAppConfigDetail(licenceName);
        modal.toggleModal(MODAL_NAMES.PREVIEW_SERVICE_POP_UP);
        // End loading
        loader.done();
    };

    const getLicenceAppConfigDetail = async (licenceName) => {
        const response = await getRequest(`${GET_PREVIEW_SERVICE_SAC}${PREVIEW_SERVICE_STATUS.PRODUCTION}/${licenceName}`, getParams());
        const { IsSuccess, Messages, Data } = response.body;

        if (IsSuccess) {
            setLACDetailData(Data);
        } else {
            toastError(t(getLangKey(ERROR.BACKEND_ERROR_MESSAGE, Messages)), Messages);
        }
    };

    // const setSelectedCheckbox = (name, value) => {
    //     const id = name.replace('PreviewService_', '');
    //     const newData = [...data];
    //     newData.find(item => item.LicenceName === id).Selected = value.length > 0 ? value[0] : '';
    //     setData(newData);
    //     onChangeField('SelfServiceLicences', value);
    // };

    // const setCheckboxContent = (rowData) => {
    //     return <Checkbox
    //         name={`PreviewService_${rowData.LicenceName}`}
    //         value={values.SelfServiceLicences}
    //         options={[{ label: '', value: rowData.LicenceName }]}
    //         onChangeField={(name, val) => {
    //             setSelectedCheckbox(name, val);
    //         }}
    //         checked />;
    // };

    const setLicenceName = rowData => {
        const licenceTypeNameTranslationKey = getLicenceTypeNameTranslationKey(rowData.LicenceName);
        const licenceName = t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY, licenceTypeNameTranslationKey, rowData.LicenceTypeId));

        return <div>{licenceName}</div>;
    };

    const getLicenceNameTranslatedString = (rowData) => {
        const licenceTypeNameTranslationKey = getLicenceTypeNameTranslationKey(rowData.LicenceName);
        return t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY, licenceTypeNameTranslationKey, rowData.LicenceTypeId));
    };

    const setAgencyName = rowData => {
        const agencyName = t(getLangKey(LANGUAGE_KEYS.BLS_LICENCEAGENCY_AGENCY_AGENCYNAME, rowData.AgencyId));

        return <div>{agencyName}</div>;
    };

    const getAgencyNameTranslatedString = (rowData) => {
        return t(getLangKey(LANGUAGE_KEYS.BLS_LICENCEAGENCY_AGENCY_AGENCYNAME, rowData.AgencyId));
    };

    const renderGrid = () => {
        return (
            <React.Fragment>
                <div className="smart-list">
                    <Modal render={({ modal }) => (
                        <>
                            <DataTable
                                pageSize="5"
                                minFilterChars='2'
                                data={data}
                                noResultsMessage={t(LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_TABLE_TEXT_NO_SERVICE_RESULT_MESSAGE)}
                                components={getCustomComponents()}
                                columns={
                                    {
                                        PublishedDate: { title: t(LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_TABLE_TITLE_PUBLISHED_DATE), width: '10%', DBkey: 'PublishedDate', isDate: true},
                                        AgencyName: {
                                            title: t(LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_TABLE_TITLE_AGENCY_NAME),
                                            width: '30%',
                                            DBkey: 'AgencyName',
                                            setContent: setAgencyName,
                                            getTranslatedString: getAgencyNameTranslatedString
                                        },
                                        LicenceName: {
                                            title: t(LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_TABLE_TITLE_SERVICE_NAME),
                                            width: '30%',
                                            DBkey: 'LicenceName',
                                            setContent: setLicenceName, getTranslatedString:
                                                getLicenceNameTranslatedString
                                        },
                                        PublishStatus: { title: t(LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_TABLE_TITLE_PUBLISH_STATUS_TO_LIVE), width: '10%', DBkey: 'PublishStatus' },
                                        PublishedBy: {title: t(LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_TABLE_TITLE_PUBLISHED_BY), width: '10%', DBkey: 'PublishedBy'},
                                        Actions: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_TABLE_TITLE_ACTION), width: '10%' }
                                    }
                                }
                                renderActions={({ RowData }) => (
                                    <React.Fragment>
                                        <ActionButton
                                            tooltip={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_CONTENT_BUTTON_VIEW)}
                                            color="forward"
                                            onClick={() => { openLicenceViewModal(modal, RowData.LicenceName, RowData.AgencyName); }}
                                        >
                                            <FontAwesomeIcon icon={faEye} />
                                        </ActionButton>
                                    </React.Fragment>
                                )}
                            />
                            <LiveConfigurationDetailModal
                                modalClassName='theme-new'
                                isOpen={modal.modalState === MODAL_NAMES.PREVIEW_SERVICE_POP_UP}
                                cancelText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_CANCEL)}
                                cancelCallback={() => modal.toggleModal(MODAL_NAMES.PREVIEW_SERVICE_POP_UP)}
                                lcData={lcDetailData}
                                lacData={lacDetailData}
                                licenceName={headerLicenceName}
                                agencyName={headerAgencyName}
                            />
                        </>
                    )} />
                </div>
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            <Layout type={LAYOUT_TYPE.FORM}>
                <div className="grid-container">
                    {renderGrid()}
                </div>
            </Layout>
        </React.Fragment>
    );
}

