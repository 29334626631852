import { Button, Row, Col, ButtonGroup, Label, Input } from "reactstrap";
import Field from "./Field";
import React, { useState, useEffect } from "react";
import ConditionalDisplayModal from "./modals/ConditionalDisplayModal";
import { DndContext, closestCenter, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import SortableItem from "./dragndrop/SortableItem";
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { OverlayTrigger } from './OverlayTrigger';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash, faTv, faCopy } from '@fortawesome/free-solid-svg-icons';

function Section(props) {
    const tempSectionJson = (props.section.fields) ? props.section : {
        ...props.section,
        fields: [],
        title: "",
        sectionID: "",
        displayCondition: true,
        displayConditionDetails: []
    }
    const [sectionJson, setSectionJson] = useState(tempSectionJson);
    const [sectionValidation, setSectionValidation] = useState(props.sectionValidation);

    const isValidated = props.isValidated;
    const globalFieldList = props.globalFieldList;

    //#region Section buttons
    function addNewFieldOnClick() {
        const newFieldJson = {
            id: sectionJson.fields.length + 1
        }
        setSectionJson({
            ...sectionJson,
            fields: [...sectionJson.fields, newFieldJson]
        })

        const newFieldValidation = {
            id: sectionValidation.fields.length + 1,
            inputType: null,
            label: null,
            datagridFields: {
                fields: []
            }
        }
        setSectionValidation({
            ...sectionValidation,
            fields: [...sectionValidation.fields, newFieldValidation]
        })
    }

    function deleteField(field) {
        const newFieldArray = sectionJson.fields.filter(a => a.id !== field.id);
        const newValidFieldArray = sectionValidation.fields.filter(a => a.id !== field.id);
        setSectionValidation({
            ...sectionValidation,
            fields: newValidFieldArray
        })
        setSectionJson({
            ...sectionJson,
            fields: newFieldArray
        })
    }

    function deleteButtonOnClick() {
        props.onDelete(props.section);
    }

    function cloneSectionOnClick() {
        const clonedSectionJson = { ...sectionJson, id: props.formJson.sections.length + 1 };
        const newSectionArray = [...props.formJson.sections, clonedSectionJson];

        const clonedSectionValidation = { ...sectionValidation, id: props.formValidation.sections.length + 1 };
        const newValidSectionArray = [...props.formValidation.sections, clonedSectionValidation];

        props.onSectionChange({
            ...props.formJson,
            sections: newSectionArray
        });

        props.onValidationChange({
            ...props.formValidation,
            sections: newValidSectionArray
        });
    }
    //#endregion

    //#region Tooltips
    const ADD_FIELD_TOOLTIP = "addFieldToolTip";
    const DELETE_SECTION_TOOLTIP = "deleteSectionToolTip";
    const SET_DISPLAY_TOOPTIP = "setDisplayTooltip";
    const CLONE_TOOLTIP = "cloneToolTip";
    const actionButtons = [
        {
            name: ADD_FIELD_TOOLTIP,
            color: "primary",
            icon: faPlus,
            action: () => addNewFieldOnClick(),
            toolTip: {
                target: ADD_FIELD_TOOLTIP,
                innerText: "Add new field to this section",
                placement: "top"
            }
        },
        {
            name: DELETE_SECTION_TOOLTIP,
            color: "primary",
            icon: faTrash,
            action: () => deleteButtonOnClick(),
            toolTip: {
                target: DELETE_SECTION_TOOLTIP,
                innerText: "Delete this section",
                placement: "top"
            }
        },
        {
            name: SET_DISPLAY_TOOPTIP,
            color: "primary",
            icon: faTv,
            action: () => openDisplayModal(),
            toolTip: {
                target: SET_DISPLAY_TOOPTIP,
                innerText: "Set display condition for this section",
                placement: "top"
            }
        },
        {
            name: CLONE_TOOLTIP,
            color: "primary",
            icon: faCopy,
            action: () => cloneSectionOnClick(),
            toolTip: {
                target: CLONE_TOOLTIP,
                innerText: "Clone this section",
                placement: "top"
            }
        }
    ];
    //#endregion

    function sectionNameOnChange(event) {
        const isSectionTitleValid = !!event.target.value && event.target.value.trim().length > 0;
        setSectionValidation({
            ...sectionValidation,
            title: isSectionTitleValid
        });
        setSectionJson({
            ...sectionJson,
            title: event.target.value,
            sectionID: event.target.value.replaceAll(' ', '')
        })
    }

    // function testOnClick() {
    //     console.log(sectionJson);
    // }

    //#region Modal popup for Conditional Display
    const [showDisplayModal, setShowDisplayModal] = useState(false);
    function closeDisplayModal() {
        setShowDisplayModal(false);
    }
    function openDisplayModal() {
        setShowDisplayModal(true);
    }

    function removeDisplayConditionOnClick() {
        setSectionJson({
            ...sectionJson,
            displayCondition: true,
            displayConditionDetails: []
        })
        setShowDisplayModal(false);
    }
    //#endregion

    //#region Drag and Drop
    const sensors = useSensors(
        useSensor(PointerSensor)
    );

    function handleDragEnd(event) {
        const { active, over } = event;

        if (active.id !== over.id) {
            const oldFieldIndex = sectionJson.fields.findIndex(field => field.id === active.id);
            const newFieldIndex = sectionJson.fields.findIndex(field => field.id === over.id);
            const newFields = arrayMove(sectionJson.fields, oldFieldIndex, newFieldIndex);
            setSectionJson({
                ...sectionJson,
                fields: (newFields)
            });
        }
    }
    //#endregion

    useEffect(() => {
        const newSectionArray = props.formJson.sections.map((section) => {
            if (section.id === sectionJson.id)
                return sectionJson;
            else
                return section;
        });
        const newSectionValidation = props.formValidation.sections.map((section) => {
            if (section.id === sectionValidation.id)
                return sectionValidation;
            else
                return section;
        });
        props.onSectionChange({
            ...props.formJson,
            sections: newSectionArray
        });
        props.onValidationChange({
            ...props.formValidation,
            sections: newSectionValidation
        })
    },
        // eslint-disable-next-line
        [sectionJson]);

    return <div>
        <Row xs="auto" className="mb-3">
            <Col xs="auto">
                <Label className="middle-title">Section Title</Label>
            </Col>
            <Col md={3}>
                <Input placeholder="Please enter Section name." value={sectionJson.title} onChange={sectionNameOnChange} valid={isValidated && !!sectionValidation?.title} invalid={isValidated && !sectionValidation?.title} required></Input>
            </Col>
            <ButtonGroup>
                {actionButtons.map(({ name, color, icon, action, toolTip }) => {
                        return <OverlayTrigger key={name} {...toolTip}>
                            <Button
                                id={name}
                                color={color}
                                onClick={action}
                                outline>
                                    <FontAwesomeIcon icon={icon} />
                            </Button>
                        </OverlayTrigger>
                    })}
                {/* <Button color="primary" onClick={testOnClick} outline>Test</Button> */}
            </ButtonGroup>
        </Row>
        <DndContext onDragEnd={handleDragEnd} sensors={sensors} collisionDetection={closestCenter} modifiers={[restrictToVerticalAxis]}>
            <SortableContext items={sectionJson.fields.map(field => field.id)} strategy={verticalListSortingStrategy}>
                {sectionJson.fields.map((field) => {
                    const fieldValidation = sectionValidation.fields.find(f => f.id === field.id);
                    return <SortableItem key={field.id} id={field.id}>
                        <Row xs="auto" className="mb-2">
                            <Field field={field} fieldValidation={fieldValidation} isValidated={isValidated} onDelete={deleteField} sectionJson={sectionJson} sectionValidation={sectionValidation} onFieldChange={setSectionJson} onValidationChange={setSectionValidation} globalFieldList={globalFieldList}></Field>
                        </Row>
                    </SortableItem>
                })}
            </SortableContext>
        </DndContext>

        <ConditionalDisplayModal
            show={showDisplayModal}
            json={sectionJson}
            onModalClose={closeDisplayModal}
            onModalReset={removeDisplayConditionOnClick}
            onModalUpdate={setSectionJson}
            globalFieldList={globalFieldList}
            currentComponentID={sectionJson.sectionID}
        />
    </div>
}

export default Section;