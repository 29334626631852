import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import { PUBLIC_HOLIDAY_MANAGEMENT_URL, GET_PUBLIC_HOLIDAY_URL, LAYOUT_TYPE } from '../../App/AppSettings';
import Layout from '../../Layout';
import { ERROR } from '../../Localization';
import { fetchRequest, getParams, navigateTo } from '../../Shared/Actions';
import { FIELD_SIZE } from '../../Shared/Constants';
import { SMARTForm, Plaintext, withSMARTWrapper } from '../../Shared/Forms';
import { DEFAULT_TEXT, DEFAULT_NAMESPACE } from '../../Shared/Constants/LanguageKeys.js';
import { toastError } from '../../Shared/Forms/Toaster.js';
import history from '../../Shared/History';
import PageFooter from '../../Shared/PageFooter';
import { withLoader } from '../../Shared/Loading';

const FORM_CONTEXT = "PublicHolidayView";

const FORM_INITIAL_VALUES = {
    Date: '',
    Title: '',
    Remarks: ''
};

class PublicHolidayView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            values: FORM_INITIAL_VALUES
        };

        if (history.location.state) {
            this.state = {
                Id: history.location.state.ID
            }
        }
    }

    async componentDidMount() {
        const { state } = history.location;
        const { loader } = this.props;

        if (state && state.ID) {
            loader.start();
            const response = await fetchRequest(GET_PUBLIC_HOLIDAY_URL, getParams({ Id: this.state.Id }), false);
            loader.done();
            
            const { IsSuccess, Data, Messages } = response.body;
            
            if (response.success && IsSuccess) {
                this.setState({ 
                    values: Data 
                });
            }
            else {
                loader.error(Messages);
            }
        }
        else {
            toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
        }
    }

    render() {
        return (
            <React.Fragment>
                <Layout type={LAYOUT_TYPE.FORM} title=
                    {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_PUBLICHOLIDAYSTATIC_CONTENT_TITLE_VIEWPUBLICHOLIDAY, DEFAULT_TEXT.VIEW_PUBLIC_HOLIDAY)}>
                    <SMARTForm
                        formContext={FORM_CONTEXT}
                        formValues={this.state.values}
                    >
                        {({ values }) => (
                            <React.Fragment>
                                <Row className="body-content admin-content-wrapper">
                                    <Col>
                                        <Plaintext
                                            name="Date"
                                            value={values.Date}
                                            placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_PUBLICHOLIDAYSTATIC_FORM_PLACEHOLDER_DATE, DEFAULT_TEXT.NAME_OF_PH)}
                                            inputSize={FIELD_SIZE.LARGE}
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_PUBLICHOLIDAYSTATIC_FORM_TITLE_DATE, DEFAULT_TEXT.PUBLIC_HOLIDAY)}
                                            required
                                        />
                                        <Plaintext
                                            name="Title"
                                            value={values.Title}
                                            placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_PUBLICHOLIDAYSTATIC_FORM_PLACEHOLDER_NAME, DEFAULT_TEXT.NAME_OF_PH)}
                                            inputSize={FIELD_SIZE.LARGE}
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_PUBLICHOLIDAYSTATIC_FORM_TITLE_PUBLICHOLIDAY, DEFAULT_TEXT.PUBLIC_HOLIDAY)}
                                            required
                                        />
                                        <Plaintext
                                            name="Remarks"
                                            value={values.Remarks}
                                            placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_PUBLICHOLIDAYSTATIC_FORM_PLACEHOLDER_REMARKS, DEFAULT_TEXT.REMARKS)}
                                            inputSize={FIELD_SIZE.LARGE}
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_PUBLICHOLIDAYSTATIC_FORM_TITLE_REMARKS, DEFAULT_TEXT.REMARKS)}
                                        />
                                        <Plaintext
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_PUBLICHOLIDAYSTATIC_FORM_TITLE_CREATEDBY, DEFAULT_TEXT.CREATED_BY)}
                                            name="CreatedBy"
                                            value={values.CreatedBy}
                                            inputSize={FIELD_SIZE.LARGE}
                                        />
                                        <Plaintext
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_PUBLICHOLIDAYSTATIC_FORM_TITLE_CREATEDDATE, DEFAULT_TEXT.CREATED_DATE)}
                                            name="FormattedCreatedDate"
                                            value={values.FormattedCreatedDate}
                                            inputSize={FIELD_SIZE.LARGE}
                                        />
                                        <Plaintext
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_PUBLICHOLIDAYSTATIC_FORM_TITLE_LASTUPDATEDBY, DEFAULT_TEXT.LAST_UPDATED_BY)}
                                            name="LastUpdatedBy"
                                            value={values.LastUpdatedBy}
                                            inputSize={FIELD_SIZE.LARGE}
                                        />
                                        <Plaintext
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_PUBLICHOLIDAYSTATIC_FORM_TITLE_LASTUPDATEDDATE, DEFAULT_TEXT.LAST_UPDATED_DATE)}
                                            name="FormattedLastUpdatedDate"
                                            value={values.FormattedLastUpdatedDate}
                                            inputSize={FIELD_SIZE.LARGE}
                                        />
                                    </Col>
                                </Row>
                                <PageFooter type={LAYOUT_TYPE.FORM}>
                                    <Button color="backward" size="sm" type="button" onClick={() => navigateTo(PUBLIC_HOLIDAY_MANAGEMENT_URL)}>
                                        {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_PUBLICHOLIDAYSTATIC_NAVIGATION_BUTTON_BACK, DEFAULT_TEXT.BACK)}
                                    </Button>
                                </PageFooter>
                            </React.Fragment>
                        )}
                    </SMARTForm>
                </Layout>
            </React.Fragment>
        );
    }
}

export default withSMARTWrapper(withLoader(PublicHolidayView));

