import React from 'react';
import { Col, Row } from 'reactstrap';
import Profile from '../Profile';
import { NavDropdownProvider } from './NavDropdownContext';
import NavHeader from './NavHeader';

class NavLayout2M extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            navDropdown : "",
        }
    }
    setNavDropdown = (input) => {
            this.setState({ navDropdown: input});
    }
    
    render() {
        return (
            <Col className={"smart-navbar layout-2m"}>
                <div className="nav-container">
                    <NavHeader navbarLayout={this.props.navbarLayout}>
                        <Profile profileDisplay={true}/>
                    </NavHeader>   
                    <Row className="layout-2m-container"> 
                        <Col>
                            <NavDropdownProvider
                                value={{
                                    navDropdown: this.state.navDropdown,
                                    setNavDropdown: this.setNavDropdown,
                                }}>
                            {this.props.children}
                            </NavDropdownProvider>
                            <Profile />
                        </Col>
                    </Row>
                </div>
            </Col>
        )
    }
}

 
export default NavLayout2M;