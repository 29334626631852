import moment from 'moment';
import React from 'react';
import { Button, Col, Row, Table } from 'reactstrap';
import * as Yup from 'yup';
import { DATE_DISPLAY_FORMAT, LAYOUT_TYPE, ADD_WORKING_HOUR_URL, WORKING_HOUR_MANAGEMENT_URL } from '../../App/AppSettings';
import Layout from '../../Layout';
import { ERROR } from '../../Localization';
import { navigateTo } from '../../Shared/Actions';
import { FIELD_SIZE } from '../../Shared/Constants';
import { DateInput, SMARTForm, Text, ToggleSwitchBoolean } from '../../Shared/Forms';
import { toastError, toastSuccess } from '../../Shared/Forms/Toaster.js';
import history from '../../Shared/History';
import PageFooter from '../../Shared/PageFooter';

const FORM_CONTEXT = "WorkingHourConfig";
const NEXT_URL = '/admin/workinghourconfig/list';

const FORM_VALIDATIONS = Yup.object().shape({
    WorkingHourCategory: Yup.string().required(ERROR.REQUIRED).min(0, ERROR.MIN_LENGTH_LIMIT).max(140, ERROR.MAX_LENGTH_LIMIT),

    WorkingHourDayofWeekMON: Yup.string(),
    WorkingHourWorkStartMON: Yup.string(),
    WorkingHourWorkEndMON: Yup.string(),
    WorkingHourBreakStartMON: Yup.string(),
    WorkingHourBreakEndMON: Yup.string(),

    WorkingHourDayofWeekTUE: Yup.string(),
    WorkingHourWorkStartTUE: Yup.string(),
    WorkingHourWorkEndTUE: Yup.string(),
    WorkingHourBreakStartTUE: Yup.string(),
    WorkingHourBreakEndTUE: Yup.string(),

    WorkingHourDayofWeekWED: Yup.string(),
    WorkingHourWorkStartWED: Yup.string(),
    WorkingHourWorkEndWED: Yup.string(),
    WorkingHourBreakStartWED: Yup.string(),
    WorkingHourBreakEndWED: Yup.string(),

    WorkingHourDayofWeekTHU: Yup.string(),
    WorkingHourWorkStartTHU: Yup.string(),
    WorkingHourWorkEndTHU: Yup.string(),
    WorkingHourBreakStartTHU: Yup.string(),
    WorkingHourBreakEndTHU: Yup.string(),

    WorkingHourDayofWeekFRI: Yup.string(),
    WorkingHourWorkStartFRI: Yup.string(),
    WorkingHourWorkEndFRI: Yup.string(),
    WorkingHourBreakStartFRI: Yup.string(),
    WorkingHourBreakEndFRI: Yup.string(),

    WorkingHourDayofWeekSAT: Yup.string(),
    WorkingHourWorkStartSAT: Yup.string(),
    WorkingHourWorkEndSAT: Yup.string(),
    WorkingHourBreakStartSAT: Yup.string(),
    WorkingHourBreakEndSAT: Yup.string(),

    WorkingHourDayofWeekSUN: Yup.string(),
    WorkingHourWorkStartSUN: Yup.string(),
    WorkingHourWorkEndSUN: Yup.string(),
    WorkingHourBreakStartSUN: Yup.string(),
    WorkingHourBreakEndSUN: Yup.string(),
});

const FORM_INITIAL_VALUES = {
    WorkingHourCategory: '',

    WorkingHourDayofWeekMON: '',
    WorkingHourWorkStartMON: '',
    WorkingHourWorkEndMON: '',
    WorkingHourBreakStartMON: '',
    WorkingHourBreakEndMON: '',
    WorkingHourWorkingDayMON: true,

    WorkingHourDayofWeekTUE: '',
    WorkingHourWorkStartTUE: '',
    WorkingHourWorkEndTUE: '',
    WorkingHourBreakStartTUE: '',
    WorkingHourBreakEndTUE: '',
    WorkingHourWorkingDayTUE: true,

    WorkingHourDayofWeekWED: '',
    WorkingHourWorkStartWED: '',
    WorkingHourWorkEndWED: '',
    WorkingHourBreakStartWED: '',
    WorkingHourBreakEndWED: '',
    WorkingHourWorkingDayWED: true,

    WorkingHourDayofWeekTHU: '',
    WorkingHourWorkStartTHU: '',
    WorkingHourWorkEndTHU: '',
    WorkingHourBreakStartTHU: '',
    WorkingHourBreakEndTHU: '',
    WorkingHourWorkingDayTHU: true,

    WorkingHourDayofWeekFRI: '',
    WorkingHourWorkStartFRI: '',
    WorkingHourWorkEndFRI: '',
    WorkingHourBreakStartFRI: '',
    WorkingHourBreakEndFRI: '',
    WorkingHourWorkingDayFRI: true,

    WorkingHourDayofWeekSAT: '',
    WorkingHourWorkStartSAT: '',
    WorkingHourWorkEndSAT: '',
    WorkingHourBreakStartSAT: '',
    WorkingHourBreakEndSAT: '',
    WorkingHourWorkingDaySAT: false,

    WorkingHourDayofWeekSUN: '',
    WorkingHourWorkStartSUN: '',
    WorkingHourWorkEndSUN: '',
    WorkingHourBreakStartSUN: '',
    WorkingHourBreakEndSUN: '',
    WorkingHourWorkingDaySUN: false,

};

//Time validation Work End
function checkWorkEnd(start, end) {
    var mStart = moment(start, DATE_DISPLAY_FORMAT.TIME);
    var mEnd = moment(end, DATE_DISPLAY_FORMAT.TIME);
    if (mEnd.isBefore(mStart)) return ERROR.TIME_INVALID_WE;
}

//Time validation Break Start
function checkBreakStart(start, end) {
    var mStart = moment(start, DATE_DISPLAY_FORMAT.TIME);
    var mEnd = moment(end, DATE_DISPLAY_FORMAT.TIME);
    if (mEnd.isBefore(mStart)) return ERROR.TIME_INVALID_BS;
}

//Time validation Break start, Work end
function checkBSWE(start, end) {
    var mStart = moment(start, DATE_DISPLAY_FORMAT.TIME);
    var mEnd = moment(end, DATE_DISPLAY_FORMAT.TIME);
    if (mEnd.isBefore(mStart)) return ERROR.TIME_INVALID_BSWE;
}

//Time validation Break End
function checkBreakEnd(start, end) {
    var mStart = moment(start, DATE_DISPLAY_FORMAT.TIME);
    var mEnd = moment(end, DATE_DISPLAY_FORMAT.TIME);
    if (mEnd.isBefore(mStart)) return ERROR.TIME_INVALID_BE;
}

class WorkHourConfigCreate extends React.Component {
    constructor(props) {
        super(props);
        this.state = { disabled: false };

        if (history.location.state && history.location.state[FORM_CONTEXT]) {
            this.state = {
                values: history.location.state[FORM_CONTEXT],
                dropDownArrayCategory: [],
            };

            history.replace({
                pathname: history.location.pathname,
                state: {}
            });
        }
        else {
            this.state = {
                values: FORM_INITIAL_VALUES,
                dropDownArrayCategory: [],
            };
        }
    }


    handletogglechange() {
        this.setState({ disabled: !this.state.disabled })
    };

      // Fn: Massage form values to be submitted
      massageValues = (values, submitPlainForm) => {

        // Reset WorkStart, WorkEnd, BreakStart, Breakend if WorkingDay is set to false

        // Monday
        if (!values.WorkingHourWorkingDayMON) {
            values.WorkingHourWorkStartMON = "";
            values.WorkingHourWorkEndMON = "";
            values.WorkingHourBreakStartMON = "";
            values.WorkingHourBreakEndMON = "";
        }

        // Tuesday
        if (!values.WorkingHourWorkingDayTUE) {
            values.WorkingHourWorkStartTUE = "";
            values.WorkingHourWorkEndTUE = "";
            values.WorkingHourBreakStartTUE = "";
            values.WorkingHourBreakEndTUE = "";
        }

        // Wednesday
        if (!values.WorkingHourWorkingDayWED) {
            values.WorkingHourWorkStartWED = "";
            values.WorkingHourWorkEndWED = "";
            values.WorkingHourBreakStartWED = "";
            values.WorkingHourBreakEndWED = "";
        }

         // Thursday
        if (!values.WorkingHourWorkingDayTHU) {
            values.WorkingHourWorkStartTHU = "";
            values.WorkingHourWorkEndTHU = "";
            values.WorkingHourBreakStartTHU = "";
            values.WorkingHourBreakEndTHU = "";
        }

        // Friday
        if (!values.WorkingHourWorkingDayFRI) {
            values.WorkingHourWorkStartFRI = "";
            values.WorkingHourWorkEndFRI = "";
            values.WorkingHourBreakStartFRI = "";
            values.WorkingHourBreakEndFRI = "";
        }

        // Saturday
        if (!values.WorkingHourWorkingDaySAT) {
            values.WorkingHourWorkStartSAT = "";
            values.WorkingHourWorkEndSAT = "";
            values.WorkingHourBreakStartSAT = "";
            values.WorkingHourBreakEndSAT = "";
        }

        // Sunday
        if (!values.WorkingHourWorkingDaySUN) {
            values.WorkingHourWorkStartSUN = "";
            values.WorkingHourWorkEndSUN = "";
            values.WorkingHourBreakStartSUN = "";
            values.WorkingHourBreakEndSUN = "";
        }

        submitPlainForm();
    }

    submitCallback = ({ response }) => {
        if (response.success) {
            const { IsSuccess, RedirectURL, Message } = response.body;
            if (IsSuccess) {
                if (RedirectURL) {
                    navigateTo(RedirectURL, "");
                } else {
                    navigateTo(WORKING_HOUR_MANAGEMENT_URL, "");
                }
                toastSuccess("Working Hour has been added successfully.");
            }
            else {
                toastError(Message);
            }
        } else {
            toastError("Server could not be reached, please try again later")
        }
    }

    render() {
        return (
            <React.Fragment>
                <Layout type={LAYOUT_TYPE.FORM} title="New Working Hour">
                    <SMARTForm
                        formContext={FORM_CONTEXT}
                        formValues={this.state.values}
                        validationSchema={FORM_VALIDATIONS}
                        validateOnSubmit={true}
                        serverURL={ADD_WORKING_HOUR_URL}
                        nextURL={NEXT_URL}
                        isDummy={false}
                        submitCallback={this.submitCallback}
                        resetFormAfterSubmit={true}
                    >
                        {({ values, errors, onChange, onChangeField, submitPlainForm }) => (
                            <React.Fragment>
                                <Row className="body-content admin-content-wrapper">
                                    <Col>
                                        <Text
                                            name="WorkingHourCategory"
                                            value={values.WorkingHourCategory}
                                            placeholder="Category"
                                            onChange={onChange}
                                            minLength={0}
                                            maxLength={140}
                                            inputSize={FIELD_SIZE.MEDIUM}
                                            label="Category"
                                            error={errors.WorkingHourCategory}
                                            required
                                        />
                                        <Table size="sm" className="WHC-Table">
                                            <thead>
                                                <tr>
                                                    <th>Day</th>
                                                    <th>Working Day</th>
                                                    <th>Work Start</th>
                                                    <th>Work End</th>
                                                    <th>Break Start</th>
                                                    <th>Break End</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        Monday
                                                    </td>
                                                    <td>
                                                        <ToggleSwitchBoolean
                                                            name="WorkingHourWorkingDayMON"
                                                            value={values.WorkingHourWorkingDayMON}
                                                            onChangeField={onChangeField}
                                                            inputSize={12}
                                                            trueText="Work"
                                                            falseText="No Work"
                                                            defaultOn={true}
                                                        />
                                                    </td>
                                                    <td>
                                                        <DateInput
                                                            name="WorkingHourWorkStartMON"
                                                            value={!values.WorkingHourWorkingDayMON ? "" : values.WorkingHourWorkStartMON}
                                                            placeholder="Start"
                                                            onChangeField={onChangeField}
                                                            time={true}
                                                            date={false}
                                                            min="1900-01-01 00:00"
                                                            max="2100-12-31 00:00"
                                                            format={DATE_DISPLAY_FORMAT.TIME}
                                                            inputSize={8}
                                                            label="Start"
                                                            error={errors.WorkingHourWorkStartMON}
                                                            required
                                                            disabled={!values.WorkingHourWorkingDayMON ? true : false}
                                                        />
                                                    </td>
                                                    <td>
                                                        <DateInput
                                                            name="WorkingHourWorkEndMON"
                                                            value={!values.WorkingHourWorkingDayMON ? "" : values.WorkingHourWorkEndMON}
                                                            placeholder="End"
                                                            onChangeField={onChangeField}
                                                            time={true}
                                                            date={false}
                                                            min="1900-01-01 00:00"
                                                            max="2100-12-31 00:00"
                                                            format={DATE_DISPLAY_FORMAT.TIME}
                                                            inputSize={8}
                                                            label="End"
                                                            error={!values.WorkingHourWorkingDayMON ? "" : checkWorkEnd(values.WorkingHourWorkStartMON, values.WorkingHourWorkEndMON)}
                                                            disabled={!values.WorkingHourWorkingDayMON ? true : false}
                                                        />
                                                    </td>
                                                    <td>
                                                        <DateInput
                                                            name="WorkingHourBreakStartMON"
                                                            value={!values.WorkingHourWorkingDayMON ? "" : values.WorkingHourBreakStartMON}
                                                            placeholder="Start"
                                                            onChangeField={onChangeField}
                                                            time={true}
                                                            date={false}
                                                            min="1900-01-01 00:00"
                                                            max="2100-12-31 00:00"
                                                            format={DATE_DISPLAY_FORMAT.TIME}
                                                            inputSize={8}
                                                            label="Start"
                                                            error={!values.WorkingHourWorkingDayMON ? "" : checkBreakStart(values.WorkingHourWorkStartMON, values.WorkingHourBreakStartMON) || checkBSWE(values.WorkingHourBreakStartMON, values.WorkingHourWorkEndMON)}
                                                            disabled={!values.WorkingHourWorkingDayMON ? true : false}
                                                        /></td>
                                                    <td>
                                                        <DateInput
                                                            name="WorkingHourBreakEndMON"
                                                            value={!values.WorkingHourWorkingDayMON ? "" : values.WorkingHourBreakEndMON}
                                                            placeholder="End"
                                                            onChangeField={onChangeField}
                                                            time={true}
                                                            date={false}
                                                            min="1900-01-01 00:00"
                                                            max="2100-12-31 00:00"
                                                            format={DATE_DISPLAY_FORMAT.TIME}
                                                            inputSize={8}
                                                            label="End"
                                                            error={!values.WorkingHourWorkingDayMON ? "" : checkBreakEnd(values.WorkingHourBreakStartMON, values.WorkingHourBreakEndMON)}
                                                            disabled={!values.WorkingHourWorkingDayMON ? true : false}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Tuesday
                                                    </td>
                                                    <td>
                                                        <ToggleSwitchBoolean
                                                            name="WorkingHourWorkingDayTUE"
                                                            value={values.WorkingHourWorkingDayTUE}
                                                            onChangeField={onChangeField}
                                                            inputSize={12}
                                                            trueText="Work"
                                                            falseText="No Work"
                                                            defaultOn={true}
                                                        />
                                                    </td>
                                                    <td>
                                                        <DateInput
                                                            name="WorkingHourWorkStartTUE"
                                                            value={!values.WorkingHourWorkingDayTUE ? "" : values.WorkingHourWorkStartTUE}
                                                            placeholder="Start"
                                                            onChangeField={onChangeField}
                                                            time={true}
                                                            date={false}
                                                            min="1900-01-01 00:00"
                                                            max="2100-12-31 00:00"
                                                            format={DATE_DISPLAY_FORMAT.TIME}
                                                            inputSize={8}
                                                            label="Start"
                                                            error={errors.WorkingHourWorkStartTUE}
                                                            disabled={!values.WorkingHourWorkingDayTUE ? true : false}
                                                        />
                                                    </td>
                                                    <td>
                                                        <DateInput
                                                            name="WorkingHourWorkEndTUE"
                                                            value={!values.WorkingHourWorkingDayTUE ? "" : values.WorkingHourWorkEndTUE}
                                                            placeholder="End"
                                                            onChangeField={onChangeField}
                                                            time={true}
                                                            date={false}
                                                            min="1900-01-01 00:00"
                                                            max="2100-12-31 00:00"
                                                            format={DATE_DISPLAY_FORMAT.TIME}
                                                            inputSize={8}
                                                            label="End"
                                                            error={!values.WorkingHourWorkingDayTUE ? "" : checkWorkEnd(values.WorkingHourWorkStartTUE, values.WorkingHourWorkEndTUE)}
                                                            disabled={!values.WorkingHourWorkingDayTUE ? true : false}
                                                        />
                                                    </td>
                                                    <td>
                                                        <DateInput
                                                            name="WorkingHourBreakStartTUE"
                                                            value={!values.WorkingHourWorkingDayTUE ? "" : values.WorkingHourBreakStartTUE}
                                                            placeholder="Start"
                                                            onChangeField={onChangeField}
                                                            time={true}
                                                            date={false}
                                                            min="1900-01-01 00:00"
                                                            max="2100-12-31 00:00"
                                                            format={DATE_DISPLAY_FORMAT.TIME}
                                                            inputSize={8}
                                                            label="Start"
                                                            error={!values.WorkingHourWorkingDayTUE ? "" : checkBreakStart(values.WorkingHourWorkStartTUE, values.WorkingHourBreakStartTUE) || checkBSWE(values.WorkingHourBreakStartTUE, values.WorkingHourWorkEndTUE)}
                                                            disabled={!values.WorkingHourWorkingDayTUE ? true : false}
                                                        />
                                                    </td>
                                                    <td>
                                                        <DateInput
                                                            name="WorkingHourBreakEndTUE"
                                                            value={!values.WorkingHourWorkingDayTUE ? "" : values.WorkingHourBreakEndTUE}
                                                            placeholder="End"
                                                            onChangeField={onChangeField}
                                                            time={true}
                                                            date={false}
                                                            min="1900-01-01 00:00"
                                                            max="2100-12-31 00:00"
                                                            format={DATE_DISPLAY_FORMAT.TIME}
                                                            inputSize={8}
                                                            label="End"
                                                            error={!values.WorkingHourWorkingDayTUE ? "" : checkBreakEnd(values.WorkingHourBreakStartTUE, values.WorkingHourBreakEndTUE)}
                                                            disabled={!values.WorkingHourWorkingDayTUE ? true : false}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Wednesday
                                                    </td>
                                                    <td>
                                                        <ToggleSwitchBoolean
                                                            name="WorkingHourWorkingDayWED"
                                                            value={values.WorkingHourWorkingDayWED}
                                                            onChangeField={onChangeField}
                                                            inputSize={12}
                                                            trueText="Work"
                                                            falseText="No Work"
                                                            defaultOn={true}
                                                        />
                                                    </td>
                                                    <td>
                                                        <DateInput
                                                            name="WorkingHourWorkStartWED"
                                                            value={!values.WorkingHourWorkingDayWED ? "" : values.WorkingHourWorkStartWED}
                                                            placeholder="Start"
                                                            onChangeField={onChangeField}
                                                            time={true}
                                                            date={false}
                                                            min="1900-01-01 00:00"
                                                            max="2100-12-31 00:00"
                                                            format={DATE_DISPLAY_FORMAT.TIME}
                                                            inputSize={8}
                                                            label="Start"
                                                            error={errors.WorkingHourWorkStartWED}
                                                            disabled={!values.WorkingHourWorkingDayWED ? true : false}
                                                        />
                                                    </td>
                                                    <td>
                                                        <DateInput
                                                            name="WorkingHourWorkEndWED"
                                                            value={!values.WorkingHourWorkingDayWED ? "" : values.WorkingHourWorkEndWED}
                                                            placeholder="End"
                                                            onChangeField={onChangeField}
                                                            time={true}
                                                            date={false}
                                                            min="1900-01-01 00:00"
                                                            max="2100-12-31 00:00"
                                                            format={DATE_DISPLAY_FORMAT.TIME}
                                                            inputSize={8}
                                                            label="End"
                                                            error={!values.WorkingHourWorkingDayWED ? "" : checkWorkEnd(values.WorkingHourWorkStartWED, values.WorkingHourWorkEndWED)}
                                                            disabled={!values.WorkingHourWorkingDayWED ? true : false}
                                                        />
                                                    </td>
                                                    <td>
                                                        <DateInput
                                                            name="WorkingHourBreakStartWED"
                                                            value={!values.WorkingHourWorkingDayWED ? "" : values.WorkingHourBreakStartWED}
                                                            placeholder="Start"
                                                            onChangeField={onChangeField}
                                                            time={true}
                                                            date={false}
                                                            min="1900-01-01 00:00"
                                                            max="2100-12-31 00:00"
                                                            format={DATE_DISPLAY_FORMAT.TIME}
                                                            inputSize={8}
                                                            label="Start"
                                                            error={!values.WorkingHourWorkingDayWED ? "" : checkBreakStart(values.WorkingHourWorkStartWED, values.WorkingHourBreakStartWED) || checkBSWE(values.WorkingHourBreakStartWED, values.WorkingHourWorkEndWED) }
                                                            disabled={!values.WorkingHourWorkingDayWED ? true : false}
                                                        />
                                                    </td>
                                                    <td>
                                                        <DateInput
                                                            name="WorkingHourBreakEndWED"
                                                            value={!values.WorkingHourWorkingDayWED ? "" : values.WorkingHourBreakEndWED}
                                                            placeholder="End"
                                                            onChangeField={onChangeField}
                                                            time={true}
                                                            date={false}
                                                            min="1900-01-01 00:00"
                                                            max="2100-12-31 00:00"
                                                            format={DATE_DISPLAY_FORMAT.TIME}
                                                            inputSize={8}
                                                            label="End"
                                                            error={!values.WorkingHourWorkingDayWED ? "" : checkBreakEnd(values.WorkingHourBreakStartWED, values.WorkingHourBreakEndWED)}
                                                            disabled={!values.WorkingHourWorkingDayWED ? true : false}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Thursday
                                                    </td>
                                                    <td>
                                                        <ToggleSwitchBoolean
                                                            name="WorkingHourWorkingDayTHU"
                                                            value={!values.WorkingHourWorkingDayTHU ? "" : values.WorkingHourWorkingDayTHU}
                                                            onChangeField={onChangeField}
                                                            inputSize={12}
                                                            trueText="Work"
                                                            falseText="No Work"
                                                            defaultOn={true}
                                                        />
                                                    </td>
                                                    <td>
                                                        <DateInput
                                                            name="WorkingHourWorkStartTHU"
                                                            value={!values.WorkingHourWorkingDayTHU ? "" : values.WorkingHourWorkStartTHU}
                                                            placeholder="Start"
                                                            onChangeField={onChangeField}
                                                            time={true}
                                                            date={false}
                                                            min="1900-01-01 00:00"
                                                            max="2100-12-31 00:00"
                                                            format={DATE_DISPLAY_FORMAT.TIME}
                                                            inputSize={8}
                                                            label="Start"
                                                            error={errors.WorkingHourWorkStartTHU}
                                                            disabled={!values.WorkingHourWorkingDayTHU ? true : false}
                                                        />
                                                    </td>
                                                    <td>
                                                        <DateInput
                                                            name="WorkingHourWorkEndTHU"
                                                            value={!values.WorkingHourWorkingDayTHU ? "" : values.WorkingHourWorkEndTHU}
                                                            placeholder="End"
                                                            onChangeField={onChangeField}
                                                            time={true}
                                                            date={false}
                                                            min="1900-01-01 00:00"
                                                            max="2100-12-31 00:00"
                                                            format={DATE_DISPLAY_FORMAT.TIME}
                                                            inputSize={8}
                                                            label="End"
                                                            error={!values.WorkingHourWorkingDayTHU ? "" : checkWorkEnd(values.WorkingHourWorkStartTHU, values.WorkingHourWorkEndTHU)}
                                                            disabled={!values.WorkingHourWorkingDayTHU ? true : false}
                                                        />
                                                    </td>
                                                    <td>
                                                        <DateInput
                                                            name="WorkingHourBreakStartTHU"
                                                            value={!values.WorkingHourWorkingDayTHU ? "" : values.WorkingHourBreakStartTHU}
                                                            placeholder="Start"
                                                            onChangeField={onChangeField}
                                                            time={true}
                                                            date={false}
                                                            min="1900-01-01 00:00"
                                                            max="2100-12-31 00:00"
                                                            format={DATE_DISPLAY_FORMAT.TIME}
                                                            inputSize={8}
                                                            label="Start"
                                                            error={!values.WorkingHourWorkingDayTHU ? "" : checkBreakStart(values.WorkingHourWorkStartTHU, values.WorkingHourBreakStartTHU) || checkBSWE(values.WorkingHourBreakStartTHU, values.WorkingHourWorkEndTHU)}
                                                            disabled={!values.WorkingHourWorkingDayTHU ? true : false}
                                                        />
                                                    </td>
                                                    <td>
                                                        <DateInput
                                                            name="WorkingHourBreakEndTHU"
                                                            value={!values.WorkingHourWorkingDayTHU ? "" : values.WorkingHourBreakEndTHU}
                                                            placeholder="End"
                                                            onChangeField={onChangeField}
                                                            time={true}
                                                            date={false}
                                                            min="1900-01-01 00:00"
                                                            max="2100-12-31 00:00"
                                                            format={DATE_DISPLAY_FORMAT.TIME}
                                                            inputSize={8}
                                                            label="End"
                                                            error={!values.WorkingHourWorkingDayTHU ? "" : checkBreakEnd(values.WorkingHourBreakStartTHU, values.WorkingHourBreakEndTHU)}
                                                            disabled={!values.WorkingHourWorkingDayTHU ? true : false}
                                                        /></td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Friday
                                                    </td>
                                                    <td>
                                                        <ToggleSwitchBoolean
                                                            name="WorkingHourWorkingDayFRI"
                                                            value={values.WorkingHourWorkingDayFRI}
                                                            onChangeField={onChangeField}
                                                            inputSize={12}
                                                            trueText="Work"
                                                            falseText="No Work"
                                                            defaultOn={true}
                                                        />
                                                    </td>
                                                    <td>
                                                        <DateInput
                                                            name="WorkingHourWorkStartFRI"
                                                            value={!values.WorkingHourWorkingDayFRI ? "" : values.WorkingHourWorkStartFRI}
                                                            placeholder="Start"
                                                            onChangeField={onChangeField}
                                                            time={true}
                                                            date={false}
                                                            min="1900-01-01 00:00"
                                                            max="2100-12-31 00:00"
                                                            format={DATE_DISPLAY_FORMAT.TIME}
                                                            inputSize={8}
                                                            label="Start"
                                                            error={errors.WorkingHourWorkStartFRI}
                                                            disabled={!values.WorkingHourWorkingDayFRI ? true : false}
                                                        />
                                                    </td>
                                                    <td>
                                                        <DateInput
                                                            name="WorkingHourWorkEndFRI"
                                                            value={!values.WorkingHourWorkingDayFRI ? "" : values.WorkingHourWorkEndFRI}
                                                            placeholder="End"
                                                            onChangeField={onChangeField}
                                                            time={true}
                                                            date={false}
                                                            min="1900-01-01 00:00"
                                                            max="2100-12-31 00:00"
                                                            format={DATE_DISPLAY_FORMAT.TIME}
                                                            inputSize={8}
                                                            label="End"
                                                            error={!values.WorkingHourWorkingDayFRI ? "" : checkWorkEnd(values.WorkingHourWorkStartFRI, values.WorkingHourWorkEndFRI)}
                                                            disabled={!values.WorkingHourWorkingDayFRI ? true : false}
                                                        />
                                                    </td>
                                                    <td
                                                    ><DateInput
                                                            name="WorkingHourBreakStartFRI"
                                                            value={!values.WorkingHourWorkingDayFRI ? "" : values.WorkingHourBreakStartFRI}
                                                            placeholder="Start"
                                                            onChangeField={onChangeField}
                                                            time={true}
                                                            date={false}
                                                            min="1900-01-01 00:00"
                                                            max="2100-12-31 00:00"
                                                            format={DATE_DISPLAY_FORMAT.TIME}
                                                            inputSize={8}
                                                            label="Start"
                                                            error={!values.WorkingHourWorkingDayFRI ? "" : checkBreakStart(values.WorkingHourWorkStartFRI, values.WorkingHourBreakStartFRI) || checkBSWE(values.WorkingHourBreakStartFRI, values.WorkingHourWorkEndFRI)}
                                                            disabled={!values.WorkingHourWorkingDayFRI ? true : false}
                                                        /></td>
                                                    <td>
                                                        <DateInput
                                                            name="WorkingHourBreakEndFRI"
                                                            value={!values.WorkingHourWorkingDayFRI ? "" : values.WorkingHourBreakEndFRI}
                                                            placeholder="End"
                                                            onChangeField={onChangeField}
                                                            time={true}
                                                            date={false}
                                                            min="1900-01-01 00:00"
                                                            max="2100-12-31 00:00"
                                                            format={DATE_DISPLAY_FORMAT.TIME}
                                                            inputSize={8}
                                                            label="End"
                                                            error={!values.WorkingHourWorkingDayFRI ? "" : checkBreakEnd(values.WorkingHourBreakStartFRI, values.WorkingHourBreakEndFRI)}
                                                            disabled={!values.WorkingHourWorkingDayFRI ? true : false}
                                                        /></td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Saturday
                                                    </td>
                                                    <td>
                                                        <ToggleSwitchBoolean
                                                            name="WorkingHourWorkingDaySAT"
                                                            value={values.WorkingHourWorkingDaySAT}
                                                            onChangeField={onChangeField}
                                                            inputSize={12}
                                                            trueText="Work"
                                                            falseText="No Work"
                                                            defaultOn={true}
                                                        />
                                                    </td>
                                                    <td>
                                                        <DateInput
                                                            name="WorkingHourWorkStartSAT"
                                                            value={!values.WorkingHourWorkingDaySAT ? "" : values.WorkingHourWorkStartSAT}
                                                            placeholder="Start"
                                                            onChangeField={onChangeField}
                                                            time={true}
                                                            date={false}
                                                            min="1900-01-01 00:00"
                                                            max="2100-12-31 00:00"
                                                            format={DATE_DISPLAY_FORMAT.TIME}
                                                            inputSize={8}
                                                            label="Start"
                                                            error={errors.WorkingHourWorkStartSAT}
                                                            disabled={!values.WorkingHourWorkingDaySAT ? true : false}
                                                        />
                                                    </td>
                                                    <td>
                                                        <DateInput
                                                            name="WorkingHourWorkEndSAT"
                                                            value={!values.WorkingHourWorkingDaySAT ? "" : values.WorkingHourWorkEndSAT}
                                                            placeholder="End"
                                                            onChangeField={onChangeField}
                                                            time={true}
                                                            date={false}
                                                            min="1900-01-01 00:00"
                                                            max="2100-12-31 00:00"
                                                            format={DATE_DISPLAY_FORMAT.TIME}
                                                            inputSize={8}
                                                            label="End"
                                                            error={!values.WorkingHourWorkingDaySAT ? "" : checkWorkEnd(values.WorkingHourWorkStartSAT, values.WorkingHourWorkEndSAT)}
                                                            disabled={!values.WorkingHourWorkingDaySAT ? true : false}
                                                        />
                                                    </td>
                                                    <td>
                                                        <DateInput
                                                            name="WorkingHourBreakStartSAT"
                                                            value={!values.WorkingHourWorkingDaySAT ? "" : values.WorkingHourBreakStartSAT}
                                                            placeholder="Start"
                                                            onChangeField={onChangeField}
                                                            time={true}
                                                            date={false}
                                                            min="1900-01-01 00:00"
                                                            max="2100-12-31 00:00"
                                                            format={DATE_DISPLAY_FORMAT.TIME}
                                                            inputSize={8}
                                                            label="Start"
                                                            error={!values.WorkingHourWorkingDaySAT ? "" : checkBreakStart(values.WorkingHourWorkStartSAT, values.WorkingHourBreakStartSAT) || checkBSWE(values.WorkingHourBreakStartSAT, values.WorkingHourWorkEndSAT)}
                                                            disabled={!values.WorkingHourWorkingDaySAT ? true : false}
                                                        />
                                                    </td>
                                                    <td>
                                                        <DateInput
                                                            name="WorkingHourBreakEndSAT"
                                                            value={!values.WorkingHourWorkingDaySAT ? "" : values.WorkingHourBreakEndSAT}
                                                            placeholder="End"
                                                            onChangeField={onChangeField}
                                                            time={true}
                                                            date={false}
                                                            min="1900-01-01 00:00"
                                                            max="2100-12-31 00:00"
                                                            format={DATE_DISPLAY_FORMAT.TIME}
                                                            inputSize={8}
                                                            label="End"
                                                            error={!values.WorkingHourWorkingDaySAT ? "" : checkBreakEnd(values.WorkingHourBreakStartSAT, values.WorkingHourBreakEndSAT)}
                                                            disabled={!values.WorkingHourWorkingDaySAT ? true : false}
                                                        /></td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Sunday
                                                    </td>
                                                    <td>
                                                        <ToggleSwitchBoolean
                                                            name="WorkingHourWorkingDaySUN"
                                                            value={values.WorkingHourWorkingDaySUN}
                                                            onChangeField={onChangeField}
                                                            inputSize={12}
                                                            trueText="Work"
                                                            falseText="No Work"
                                                            defaultOn={true}
                                                        />
                                                    </td>
                                                    <td>
                                                        <DateInput
                                                            name="WorkingHourWorkStartSUN"
                                                            value={!values.WorkingHourWorkingDaySUN ? "" : values.WorkingHourWorkStartSUN}
                                                            placeholder="Start"
                                                            onChangeField={onChangeField}
                                                            time={true}
                                                            date={false}
                                                            min="1900-01-01 00:00"
                                                            max="2100-12-31 00:00"
                                                            format={DATE_DISPLAY_FORMAT.TIME}
                                                            inputSize={8}
                                                            label="Start"
                                                            error={errors.WorkingHourWorkStartSUN}
                                                            disabled={!values.WorkingHourWorkingDaySUN ? true : false}
                                                        />
                                                    </td>
                                                    <td>
                                                        <DateInput
                                                            name="WorkingHourWorkEndSUN"
                                                            value={!values.WorkingHourWorkingDaySUN ? "" : values.WorkingHourWorkEndSUN}
                                                            placeholder="End"
                                                            onChangeField={onChangeField}
                                                            time={true}
                                                            date={false}
                                                            min="1900-01-01 00:00"
                                                            max="2100-12-31 00:00"
                                                            format={DATE_DISPLAY_FORMAT.TIME}
                                                            inputSize={8}
                                                            label="End"
                                                            error={!values.WorkingHourWorkingDaySUN ? "" : checkWorkEnd(values.WorkingHourWorkStartSUN, values.WorkingHourWorkEndSUN)}
                                                            disabled={!values.WorkingHourWorkingDaySUN ? true : false}
                                                        />
                                                    </td>
                                                    <td>
                                                        <DateInput
                                                            name="WorkingHourBreakStartSUN"
                                                            value={!values.WorkingHourWorkingDaySUN ? "" : values.WorkingHourBreakStartSUN}
                                                            placeholder="Start"
                                                            onChangeField={onChangeField}
                                                            time={true}
                                                            date={false}
                                                            min="1900-01-01 00:00"
                                                            max="2100-12-31 00:00"
                                                            format={DATE_DISPLAY_FORMAT.TIME}
                                                            inputSize={8}
                                                            label="Start"
                                                            error={!values.WorkingHourWorkingDaySUN ? "" : checkBreakStart(values.WorkingHourWorkStartSUN, values.WorkingHourBreakStartSUN) || checkBSWE(values.WorkingHourBreakStartSUN, values.WorkingHourWorkEndSUN)}
                                                            disabled={!values.WorkingHourWorkingDaySUN ? true : false}
                                                        />
                                                    </td>
                                                    <td>
                                                        <DateInput
                                                            name="WorkingHourBreakEndSUN"
                                                            value={!values.WorkingHourWorkingDaySUN ? "" : values.WorkingHourBreakEndSUN}
                                                            placeholder="End"
                                                            onChangeField={onChangeField}
                                                            time={true}
                                                            date={false}
                                                            min="1900-01-01 00:00"
                                                            max="2100-12-31 00:00"
                                                            format={DATE_DISPLAY_FORMAT.TIME}
                                                            inputSize={8}
                                                            label="End"
                                                            error={!values.WorkingHourWorkingDaySUN ? "" : checkBreakEnd(values.WorkingHourBreakStartSUN, values.WorkingHourBreakEndSUN)}
                                                            disabled={!values.WorkingHourWorkingDaySUN ? true : false}
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                                <PageFooter type={LAYOUT_TYPE.FORM}>
                                    <Button color="backward" size="sm" type="button" onClick={() => navigateTo(WORKING_HOUR_MANAGEMENT_URL)}>Back</Button>
                                    <Button color="forward" size="sm" type="button" onClick={() => this.massageValues(values, submitPlainForm)}>Submit</Button>
                                </PageFooter>
                            </React.Fragment>
                        )}
                    </SMARTForm>
                </Layout>
            </React.Fragment>
        );
    }
}

export default WorkHourConfigCreate;

