import { faAlignRight, faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Badge, Col, Row } from 'reactstrap';
import { APP_LANGUAGE_LIST_URL, LANGUAGE_REGISTRATION_EDIT, LAYOUT_TYPE } from '../../App/AppSettings';
import Layout from '../../Layout';
import { withSMARTWrapper } from '../../Shared/Forms';
import { DEFAULT_TEXT, DEFAULT_NAMESPACE } from '../../Shared/Constants/LanguageKeys.js';
import { fetchRequest, getParams, navigateTo } from '../../Shared/Actions';
import { ActionButton, DataTable } from '../../Shared/DataTable';
import { getCustomComponents } from '../../Shared/DataTable/BaseTable.js';
import { withLoader } from '../../Shared/Loading';
import './LanguageList.css';

class ApplicationsLanguageList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        };
    }

    componentDidMount = async () => {
        this.props.loader.start();
        const response = await fetchRequest(APP_LANGUAGE_LIST_URL, getParams());
        const { Data, IsSuccess } = response.body;
        if (IsSuccess) {
            this.setState({
                data: Data,
            });
        }
        this.props.loader.done();
    }

    setLanguageContent = (RowData) => {
        const { LanguageDetails } = RowData;

        return (
            <>
                {LanguageDetails.map((lang, i) =>
                    <div className="table-language-list" key={i}>
                        <Row>
                            <Col xs='12' sm='6'>
                                {`${lang.LanguageTitle} (${lang.LanguageCode})`}
                            </Col>
                            <Col xs='12' sm='2'>
                                {lang.IsDefault &&
                                    <Badge color="primary">
                                        {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_BADGE_TEXT_DEFAULT, DEFAULT_TEXT.DEFAULT)}
                                    </Badge>
                                }
                            </Col>
                            <Col xs='12' sm='2'>
                                {lang.IsActive &&
                                    <Badge color="success">
                                        {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_BADGE_TEXT_ACTIVE, DEFAULT_TEXT.ACTIVE)}
                                    </Badge>
                                }
                            </Col>
                            <Col xs='12' sm='2'>
                                {lang.IsAlignRight &&
                                    <Badge color="success"><FontAwesomeIcon icon={faAlignRight} /></Badge>
                                }
                            </Col>
                        </Row>
                    </div>
                )
                }
            </>
        );
    }


    renderLayout = () => {
        return (
            <DataTable
                pageSize="3"
                minFilterChars='2'
                data={this.state.data}
                noResultsMessage={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_TABLE_TEXT_APPNORECORD, DEFAULT_TEXT.APP_NO_RECORD)}
                components={getCustomComponents()}
                columns={
                    {
                        RunningNumber: { title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_CONTENT_TABLE_NO, DEFAULT_TEXT.NUM), width: '5%', DBkey: 'Id' },
                        Actions: { title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_CONTENT_TABLE_ACTIONS, DEFAULT_TEXT.ACTIONS), width: '10%' },
                        Application: { title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_CONTENT_TABLE_APPLICATION, DEFAULT_TEXT.APPLICATION), width: '30%', DBkey: 'ApplicationName' },
                        LanguageDetails:
                        {
                            title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_CONTENT_TABLE_REGLANGUAGE, DEFAULT_TEXT.REG_LANGUAGE), width: '55%', DBkey: 'LanguageDetails',
                            setContent: (RowData) => this.setLanguageContent(RowData)
                        }
                    }
                }
                renderActions={({ RowData }) => (
                    <React.Fragment>
                        <ActionButton tooltip={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_TABLE_TOOLTIP_EDIT, DEFAULT_TEXT.LANGUAGE_REG)} color="neutral" onClick={() => navigateTo(LANGUAGE_REGISTRATION_EDIT, { appData: RowData })}><FontAwesomeIcon icon={faEdit} /></ActionButton>
                    </React.Fragment>
                )}
                renderMobileCardActions={(RowData) => (
                    <React.Fragment>
                        <ActionButton tooltip={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_TABLE_TOOLTIP_EDIT, DEFAULT_TEXT.LANGUAGE_REG)} color="neutral" onClick={() => navigateTo(LANGUAGE_REGISTRATION_EDIT, { appData: RowData })}><FontAwesomeIcon icon={faEdit} /></ActionButton>
                    </React.Fragment>
                )}
            />
        );

    }

    render() {
        return (
            <>
                <Layout type={LAYOUT_TYPE.FORM} title=
                    {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_CONTENT_TITLE_LANGUAGEREG, DEFAULT_TEXT.LANGUAGE_REG)}>
                    {this.renderLayout()}
                </Layout>

            </>
        )
    }
}

export default withSMARTWrapper(withLoader(ApplicationsLanguageList));

