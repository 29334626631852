import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Label } from 'reactstrap';
import * as Yup from 'yup';
import { GET_ADDITIONAL_PAYMENT_FEE_ITEM_URL, GET_ADDITIONAL_PAYMENT_FEE_URL } from '../../../../../App/AppSettings';
import { fetchRequest, isValidForm } from '../../../../../Shared/Actions';
import { FIELD_SIZE } from '../../../../../Shared/Constants';
import { BUTTON, ERROR, getLangKey, LANGUAGE_KEYS } from '../../../../../Shared/Constants/LanguageKeys';
import { DataTable } from '../../../../../Shared/DataTable';
import { getCustomComponents } from '../../../../../Shared/DataTable/BaseTable.js';
import { getParams, useAsyncFetch } from '../../../../../Shared/Fetch';
import { Paragraph, SelectList, toastError } from '../../../../../Shared/Forms';
import history from '../../../../../Shared/History';
import { ModalConfirm } from '../../../../../Shared/Modal';

const ADDITIONAL_FEES_OPTIONS_URL = GET_ADDITIONAL_PAYMENT_FEE_URL;

const MODAL_NAMES = {
    SUBMIT: 'Submit',
    PAYMENT_ITEM: 'PaymentItem'
};


export default function SubmitAdditionalPayment(props) {

    const { t } = useTranslation();
    const { state } = history.location;
    const { command, modal, isCheckOut, clearCommand } = props;
    const [paymentItemTotal, setPaymentItemTotal] = useState(0);
    const [paymentItems, setPaymentItems] = useState([]);
    const [paymentItemOptions, setPaymentItemOptions] = useState([]);
    const [paymentItemError, setPaymentItemError] = useState('');
    const [on, setOn] = useState(command && modal && isCheckOut);
    const { values, submitForm, onChange, onChangeField } = props.smartFormValues;
    const [remarksError, setRemarksError] = useState(false);
    const [checkPaymentError, setCheckPaymentError] = useState(false);
    values.PaymentItems = [];
    
    const FORM_VALIDATIONS = Yup.object().shape({
        Remarks: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED)),
        PaymentItems: Yup.array().min(1, t(ERROR.PAYMENT_ITEMS_REQUIRED))
    });
    const response = useAsyncFetch(ADDITIONAL_FEES_OPTIONS_URL + state.ID, getParams());

    useEffect(() => {
        async function setPaymentItems() {
            const { IsSuccess, Messages, Data } = response.body;
            if (response.success && IsSuccess) {
                const fees = Data;
                const itemOptions = [];
                fees.map(fee =>
                    itemOptions.push({
                        value: fee.LicenceApplicationFeeId.toString(),
                        label: fee.FeeName
                    })
                );
                setPaymentItemOptions(itemOptions);
            } else {
                // Error message to be loaded here
                toastError(t(getLangKey(ERROR.BACKEND_ERROR_MESSAGE, Messages)), Messages);
            }
        }

        if (response) {
            setPaymentItems();
        }

        const toggle = () => setOn(!on);

        if (on) {
            modal.toggleModal(MODAL_NAMES.SUBMIT);
            toggle();
        }
    }, [response, on, modal, t]);


    const prepareToSubmit = async (_submitForm) => {
        if (isValidForm(FORM_VALIDATIONS, values)) {
            values.workflowParams = { 'PaymentItems': JSON.stringify(paymentItems) };
            values.command = command;
            modal.toggleModal(MODAL_NAMES.SUBMIT);
            setRemarksError(false);
            await _submitForm();
        } else {
            setRemarksError(true);
        }
    };

    //to fix the decimal adding issue
    const calculateTotalAmount = total => Math.round(total * 1e12) / 1e12;

    const deletePaymentItem = rowData => {
        const existing = paymentItems.map(x => x);
        existing.splice(rowData.griddleKey, 1);
        let totalAmt = 0;
        existing.map(item => totalAmt = totalAmt + item.SubtotalDec);
        totalAmt = calculateTotalAmount(totalAmt);

        setPaymentItems(existing);
        setPaymentItemTotal(totalAmt);
    };

    const displayModalContent = () => {
        values.PaymentItems = paymentItems;

        return (
            <>
                <div className="row form-group">
                    <Label className="required label-default col-lg-12 col-form-label">{t(LANGUAGE_KEYS.BLS_INTERNALPAYMENTINBOX_CONTENT_TITLE_PAYMENTITEMS)}</Label>
                    <div className="col-lg-12">
                        <Button color="neutral" size="sm" type="button" onClick={() => modal.toggleModal(MODAL_NAMES.PAYMENT_ITEM)}>{t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_ADDPAYMENTITEM)}</Button>
                    </div>
                    <div className="col-lg-12">
                        <DataTable
                            pageSize='2'
                            minFilterChars='2'
                            data={paymentItems}
                            noResultsMessage={t(LANGUAGE_KEYS.BLS_INTERNALPAYMENTINBOX_MESSAGE_PLACEHOLDER_NOPAYMENTITEMADDED)}
                            components={getCustomComponents()}
                            columns={
                                {
                                    RunningNumber: { title: t(LANGUAGE_KEYS.BLS_INTERNALPAYMENTINBOX_TABLE_TITLE_NUMBER), width: '5%' },
                                    FeeName: { title: t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_TABLE_TITLE_DESCRIPTION), width: '25%', DBkey: 'FeeName' },
                                    FeeAmount: { title: t(LANGUAGE_KEYS.BLS_INTERNALPAYMENTINBOX_TABLE_TITLE_FEEAMOUNT), width: '18%', DBkey: 'FeeAmount' },
                                    TaxAmount: { title: t(LANGUAGE_KEYS.BLS_INTERNALPAYMENTINBOX_TABLE_TITLE_TAXAMOUNT), width: '12%', DBkey: 'TaxAmount' },
                                    Subtotal: { title: t(LANGUAGE_KEYS.BLS_INTERNALPAYMENTINBOX_TABLE_TITLE_SUBTOTAL), width: '15%', DBkey: 'Subtotal' },
                                    Actions: { title: t(LANGUAGE_KEYS.BLS_INTERNALREPORTHISTORY_TABLE_TITLE_ACTIONS), width: '5%', DBkey: 'Actions' }
                                }
                            }
                            renderActions={({ RowData }) => (
                                <Button color="neutral" size="sm" type="button" onClick={() => deletePaymentItem(RowData)}><FontAwesomeIcon icon={faTrash} /></Button>
                            )}
                        />
                        <div>
                            <Label className="label-payment-item-total"> {t(LANGUAGE_KEYS.BLS_INTERNALPAYMENTINBOX_CONTENT_INFORMATION_TOTALPAYABLEAMOUNT)} <b>{t(LANGUAGE_KEYS.BLS_INTERNALPAYMENTINBOX_CONTENT_INFORMATION_CURRENCYSYMBOL)}{paymentItemTotal}</b></Label>
                        </div>
                        {paymentItems.length === 0 &&
                            <div>
                                <Label className="label-feedback"> {values['PaymentItems'].length <= 0 && remarksError ? `PaymentItems ${t(ERROR.REQUIRED)}` : ''} </Label>
                            </div>
                        }
                    </div>
                </div>

                <Paragraph
                    name="Remarks"
                    value={values.Remarks}
                    placeholder={t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_TABLE_INFORMATION_REMARKS)}
                    onChange={onChange}
                    rows={3}
                    minLength={0}
                    maxLength={140}
                    inputSize={12}
                    labelSize={FIELD_SIZE.XSMALL}
                    label={t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_TABLE_INFORMATION_REMARKS)}
                    error={!values['Remarks'] && remarksError ? t(ERROR.REMARKS_REQUIRED) : ''}
                    required
                />

            </>
        );
    };

    const addToPaymentItem = async () => {
        if (values.selectedPaymentItemOption !== undefined) {
            const response = await fetchRequest(GET_ADDITIONAL_PAYMENT_FEE_ITEM_URL + values.selectedPaymentItemOption, getParams());
            const { IsSuccess, Messages, Data } = response.body;

            if (IsSuccess) {
                if (Data.Id !== 0) {
                    //reset selectList
                    values.selectedPaymentItemOption = undefined;

                    const items = paymentItems.concat(Data);
                    let totalAmt = 0;
                    items.map(item => totalAmt = totalAmt + item.SubtotalDec);
                    totalAmt = calculateTotalAmount(totalAmt);

                    setPaymentItems(items);
                    setPaymentItemTotal(totalAmt);
                    setPaymentItemError('');
                    setCheckPaymentError(false);
                } else {
                    toastError(t(ERROR.INVALID_SELECTED_ITEM));
                }
            } else {
                toastError(t(getLangKey(ERROR.BACKEND_ERROR_MESSAGE, Messages)), Messages);
            }
            modal.toggleModal(MODAL_NAMES.SUBMIT);
        } else {
            setCheckPaymentError(true);
            setPaymentItemError(t(ERROR.PAYMENT_ITEM_REQUIRED));
        }
    };

    //modal to add the payment items
    const showAddPaymentItemModal = () => {
        return (
            <>
                <SelectList
                    name="selectedPaymentItemOption"
                    value={values.selectedPaymentItemOption}
                    options={paymentItemOptions}
                    onChangeField={onChangeField}
                    placeholder={t(LANGUAGE_KEYS.BLS_INTERNALPAYMENTINBOX_CONTENT_TITLE_PAYMENTITEM)}
                    isMulti={false}
                    isClearable={false}
                    inputSize={12}
                    labelSize={12}
                    error={!values['selectedPaymentItemOption'] && checkPaymentError ? `selectedPaymentItemOption ${t(ERROR.REQUIRED)}` : ''}
                />
                {!values.selectedPaymentItemOption &&
                    <Label className="label-feedback">
                        {paymentItemError}
                    </Label>
                }
            </>
        );
    };

    return (
        <>
            {modal &&
                <>
                    <ModalConfirm
                        className="modal-remarks"
                        isOpen={modal.modalState === MODAL_NAMES.SUBMIT}
                        contentHeader={command}
                        confirmText={t(LANGUAGE_KEYS.BLS_INTERNALGENERAL_FORM_BUTTON_CONFIRM)}
                        confirmCallback={() => { prepareToSubmit(submitForm); }}
                        cancelText={t(LANGUAGE_KEYS.BLS_INTERNALGENERAL_FORM_BUTTON_CANCEL)}
                        cancelCallback={() => {
                            modal.toggleModal(MODAL_NAMES.SUBMIT);
                            setTimeout(function () { clearCommand(); }, 1000);
                        }}
                        contentBody={
                            displayModalContent()
                        }
                    />
                    <ModalConfirm
                        className="modal-remarks"
                        isOpen={modal.modalState === MODAL_NAMES.PAYMENT_ITEM}
                        contentHeader={t(LANGUAGE_KEYS.BLS_INTERNALPAYMENTINBOX_MESSAGE_PLACEHOLDER_SELECTPAYMENTITEM)}
                        confirmText={t(BUTTON.ADD)}
                        confirmCallback={() => addToPaymentItem()}
                        cancelText={t(LANGUAGE_KEYS.BLS_INTERNALGENERAL_FORM_BUTTON_CANCEL)}
                        cancelCallback={() => modal.toggleModal(MODAL_NAMES.SUBMIT)}
                        contentBody={showAddPaymentItemModal()}
                    />
                </>
            }
        </>
    );
}
