import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { withSMARTWrapper } from '../../../Shared/Forms';
import { INBOX_PATH, GET_PAYMENT_SUCCESS_URL, IS_DEMO, GET_PAYMENT_RECEIPT_URL, LAYOUT_TYPE } from '../../../App/AppSettings';
import { getParams, fetchRequest, getFileRequestNewTab, navigateTo } from '../../../Shared/Actions';
import Layout from '../../../../components/Layout';
import PageFooter from '../../../Shared/PageFooter';
import { withTranslation } from 'react-i18next';
import { getLangKey, LANGUAGE_KEYS } from '../../../Shared/Constants/LanguageKeys';
import { getLicenceTypeNameTranslationKey } from '../../DisplayComponents/DisplayUtils';

const INITIAL_VALUES = 0;
const GET_PAYMENT_SUCCESS = GET_PAYMENT_SUCCESS_URL;
const GET_PAYMENT_RECEIPT = GET_PAYMENT_RECEIPT_URL;
const URL_PARAMS = 'ApplicationPaymentReferenceNo';

const EmptyOrderDetails = [{
    ApplicationName: '',
    ApplicationNumber: ''
}];

class PaymentSuccess extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            orderNumber: INITIAL_VALUES,
            orderDetails: EmptyOrderDetails,
            isPaid: false,
        };
    }

    componentDidMount() {
        this.getPaymentSuccess();

        this.interval = setInterval(() => {
            this.getPaymentSuccess();
        }, 6000);
    }

    getPaymentSuccess = async () => {

        // Start loading
        const { loader, } = this.props;
        loader.start();

        let paramValue = INITIAL_VALUES;

        //get the params
        if (this.props.location.search) {
            const params = new URLSearchParams(this.props.location.search);
            paramValue = params.get(URL_PARAMS);
        }

        const queryString = Object.assign({}, { applicationPaymentReferenceNo: paramValue });

        const response = await fetchRequest(GET_PAYMENT_SUCCESS, getParams(queryString), IS_DEMO);

        const { Data, IsSuccess } = response.body;

        if (IsSuccess) {
            if (Data.length !== 0) {

                if (Data.IsPaid) {
                    clearInterval(this.interval);
                }
                this.setState(() => {
                    return {
                        orderNumber: Data.OrderNumber,
                        orderDetails: Data.LicenceApplications,
                        isPaid: Data.IsPaid,
                        applicationPaymentReferenceNo: paramValue,
                        userGuid: Data.UserGuid,
                        licence: Data.Licence
                    };
                });

            }
            else {
                this.setState(() => {
                    return { orderDetails: EmptyOrderDetails };
                });
            }

        }
        else {
            //show error page
            this.setState(() => {
                return { orderDetails: EmptyOrderDetails };
            });
        }

        // End loading
        loader.done();
    }

    getReceipt = async () => {

        const { loader } = this.props;
        loader.start();

        const paramValue = this.state.applicationPaymentReferenceNo;
        const paramGuid = this.state.userGuid;

        const url = GET_PAYMENT_RECEIPT.concat(`applicationPaymentReferenceNo=${paramValue}&userGuid=${paramGuid}`);

        const response = await getFileRequestNewTab(url, false, paramValue);

        loader.done();

        return response;
    }

    render() {
        const { t } = this.props;
        const isPaid = this.state.isPaid;

        return (
            <React.Fragment>
                {!isPaid ? (
                    <React.Fragment>
                        <Layout type={LAYOUT_TYPE.FORM} footer={true}>
                            <p></p><p></p>
                            <h3>{t(LANGUAGE_KEYS.BLS_INTERNALPAYMENTINBOX_MESSAGE_PLACEHOLDER_RETRIEVEPAYMENTDETAILS)}</h3>
                        </Layout>

                    </React.Fragment>
                ) : (
                        <React.Fragment>
                            <Layout type={LAYOUT_TYPE.FORM} title={t(LANGUAGE_KEYS.BLS_INTERNALPAYMENTINBOX_CONTENT_TITLE_PAYMENTSUCCESS)} footer={false} titleClassName='sticky-title' >
                                <Row className='body-content internal-content'>
                                    <Col className='body-section-col'>

                                        <div className='success-block'>

                                            <i className='fas fa-check-circle'></i>
                                            <h4>{t(LANGUAGE_KEYS.BLS_INTERNALPAYMENTINBOX_MESSAGE_PLACEHOLDER_APPLICATIONSUBMISSIONCOMPLETE)}</h4>
                                            <h5>{t(LANGUAGE_KEYS.BLS_INTERNALPAYMENTINBOX_MESSAGE_INFORMATION_ORDERID)} {this.state.orderNumber}</h5>
                                            <h5>{t(LANGUAGE_KEYS.BLS_INTERNALPAYMENTINBOX_MESSAGE_INFORMATION_PAYMENTSUCCESSFUL)}</h5>

                                            <div className='data-table'>
                                                <div className='table-responsive'>
                                                    <table className='table'>
                                                        <thead>
                                                            <tr>
                                                                <th>{t(LANGUAGE_KEYS.BLS_INTERNALPAYMENTINBOX_TABLE_TITLE_SERVICES)}</th>
                                                                <th>{t(LANGUAGE_KEYS.BLS_INTERNALPAYMENTINBOX_TABLE_TITLE_APPLICATIONNO)}</th>
                                                                <th>{t(LANGUAGE_KEYS.BLS_INTERNALPAYMENTINBOX_TABLE_TITLE_MODEPAYMENT)}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.orderDetails.map((item, i) => {
                                                                const licenceTypeNameTranslationKey = getLicenceTypeNameTranslationKey(item.ApplicationName);

                                                                return (
                                                                    <tr key={i}>
                                                                        <td>{t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY, licenceTypeNameTranslationKey, item.LicenceTypeId))}</td>
                                                                        <td>{item.ApplicationNumber}</td>
                                                                        <td>{t(getLangKey(LANGUAGE_KEYS.BLS_DEFAULT_PAYMENTTYPE_PAYMENTOPTION_KEY, item.PaymentMode.toLowerCase()))}</td>
                                                                    </tr>
                                                                );
                                                            })}

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                        </div>
                                    </Col>

                                    <PageFooter type={LAYOUT_TYPE.FORM}>
                                        <div className='button-center'>
                                            <Button color='backward' size='sm' type='button' onClick={() => navigateTo(INBOX_PATH)} >{t(LANGUAGE_KEYS.BLS_INTERNALPAYMENTINBOX_CONTENT_BUTTON_DASHBOARD)}</Button>
                                            <Button color='forward' size='sm' type='button' onClick={this.getReceipt}>{t(LANGUAGE_KEYS.BLS_INTERNALPAYMENTINBOX_CONTENT_BUTTON_PRINTRECEIPT)}</Button>
                                        </div>
                                    </PageFooter>

                                </Row>
                            </Layout>
                        </React.Fragment>
                    )
                }
            </React.Fragment>
        );
    }
}

export default withTranslation()(withRouter(withSMARTWrapper(PaymentSuccess)));

