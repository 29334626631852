import { faFont } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Badge, Col, Row } from 'reactstrap';
import { FIELD_SIZE, PARAGRAPH_TYPES, RICHTEXT } from '../../Shared/Constants';
import { withSMARTWrapper, Paragraph, RichTextEditor } from '../../Shared/Forms';
import { DEFAULT_TEXT, DEFAULT_NAMESPACE } from '../../Shared/Constants/LanguageKeys.js';
import './LanguageList.css';


class RichTextToggle extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isRichText: false
        }
    }

    onChangeTextBox = () => {
        this.setState({
            isRichText: !this.state.isRichText
        });
    }


    renderDefaultBadge = (isDefault, languageTitle, languageValue) => {
        let languageLabel = languageTitle + " (" + languageValue + ")";
        if (isDefault) {
            return (<span>
                <Badge color='primary' className="badge-icon">
                    {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_BADGE_TEXT_DEFAULT, DEFAULT_TEXT.DEFAULT)}
                </Badge> {languageLabel}</span>);
        }
        else {
            return languageLabel;
        }
    }

    render() {
        const { isRichText } = this.state;
        const { language, values, onChangeField, onChange, errors } = this.props;
        return (
            <>
                <Row>
                    <Col lg="10" xs="11" className="text-toggle-text-box-col">
                        {isRichText ?
                            <RichTextEditor
                                name={`LanguagesInput.${language.LanguageCodeValue}`}
                                editorClassName={language.IsAlignRight ? "direction-rtl language-management-rich-text" : "direction-ltr"}
                                value={values.LanguagesInput[language.LanguageCodeValue]}
                                onChangeField={onChangeField}
                                showHtml={false}
                                inputSize={FIELD_SIZE.XLARGE}
                                label={this.renderDefaultBadge(language.IsDefault, language.LanguageCodeTitle, language.LanguageCodeValue)}
                                error={errors.LanguagesInput && errors.LanguagesInput[language.LanguageCodeValue]}
                                toolbar={RICHTEXT.DEFAULT}
                                placeholder={`Enter ${language.LanguageCodeTitle + " (" + language.LanguageCodeValue + ")"} value`}
                                required
                            />
                            :
                            <Paragraph
                                type={PARAGRAPH_TYPES.DEFAULT}
                                inputClassName={language.IsAlignRight ? "direction-rtl" : "direction-ltr"}
                                name={`LanguagesInput.${language.LanguageCodeValue}`}
                                value={values.LanguagesInput[language.LanguageCodeValue]}
                                placeholder={this.props.getStaticTextWithDynamicValue(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_FORM_PLACEHOLDER_ENTERVALUE,
                                    `Enter ${language.LanguageCodeTitle + " (" + language.LanguageCodeValue + ")"} value`, language.LanguageCodeTitle, language.LanguageCodeValue)}
                                onChange={onChange}
                                rows={4}
                                minLength={0}
                                icon={faFont}
                                inputSize={FIELD_SIZE.XLARGE}
                                label={this.renderDefaultBadge(language.IsDefault, language.LanguageCodeTitle, language.LanguageCodeValue)}
                                error={errors.LanguagesInput && errors.LanguagesInput[language.LanguageCodeValue]}
                                required
                            />
                        }
                    </Col>
                    <Col xs="1" className="text-toggle-col">
                        <Badge color="normal" className="badge-icon text-toggle"
                            onClick={this.onChangeTextBox}>
                            <FontAwesomeIcon icon={faFont} />
                        </Badge>
                    </Col>
                </Row>

            </>
        );
    }
}

export default withSMARTWrapper(RichTextToggle);