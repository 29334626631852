import React, { useState } from "react";
import { Modal, Button, Row, Col, Label, ModalHeader, ModalBody, ModalFooter, Input } from "reactstrap";
import Value from "../dataSource/Value";
import { DATA_SOURCE_VALUES, DATA_SOURCE_RESOURCE, DATA_SOURCE_CODE_CAT, DATA_SOURCE_APP_CONFIG, 
    DATA_SOURCE_VALUES_ID, DATA_SOURCE_RESOURCE_ID, DATA_SOURCE_CODE_CAT_ID, DATA_SOURCE_APP_CONFIG_ID } from "../../AppConstants";
import Resource from "../dataSource/Resource";
import DSPDatabase from "../dataSource/DSPDatabase";

function DataSourceModal(props) {
    const fieldJson = props.fieldJson;
    const [dataSource, setDataSource] = useState(props.fieldJson.dataSource);
    const [dataSourceValues, setDataSourceValues] = useState(props.fieldJson.dataSourceValues);

    const [dataSourceResourceID, setDataSourceResourceID] = useState(props.fieldJson.dataSourceResourceID);
    const [dataSourceResource, setDataSourceResource] = useState(props.fieldJson.dataSourceResource);
    const [dataSourceResourceDependencyKey, setDataSourceResourceDependencyKey] = useState(props.fieldJson.dataSourceResourceDependencyKey);
    const [dataSourceResourceDependencyValue, setDataSourceResourceDependencyValue] = useState(props.fieldJson.dataSourceResourceDependencyValue);
    const [isDynamicFiltering, setIsDynamicFiltering] = useState(false);

    const [dataSourceCodeCat, setDataSourceCodeCat] = useState(props.fieldJson.dataSourceCodeCat);
    const [dataSourceAppConfig, setDataSourceAppConfig] = useState(props.fieldJson.dataSourceAppConfig);

    const [minSize, setMinSize] = useState(props.fieldJson.minSize);
    const [maxSize, setMaxSize] = useState(props.fieldJson.maxSize);

    const globalFieldList = props.globalFieldList;
    const currentComponentID = props.currentComponentID;

    function removeDataSourceOnClick() {
        setDataSource("");
        setDataSourceValues([]);
        setDataSourceResourceID("");
        setDataSourceResource("");
        setDataSourceResourceDependencyKey("");
        setDataSourceResourceDependencyValue("");
        setDataSourceCodeCat("");
        setDataSourceAppConfig("");
        props.onModalReset();
    }

    function closeDataSourceModal() {
        props.onModalClose();
    }

    function updateDataSourceModal() {
        const newFieldJson = {
            ...props.fieldJson,
            dataSource: dataSource,
            dataSourceValues: dataSourceValues,
            dataSourceResourceID: dataSourceResourceID,
            dataSourceResource: dataSourceResource,
            dataSourceResourceDependencyKey: dataSourceResourceDependencyKey,
            dataSourceResourceDependencyValue: dataSourceResourceDependencyValue,
            dataSourceCodeCat: dataSourceCodeCat,
            dataSourceAppConfig: dataSourceAppConfig,
            minSize: minSize,
            maxSize: maxSize
        }
        props.onModalUpdate(newFieldJson);
        props.onModalClose();
    }

    function dataSourceModelBody() {
        if (fieldJson.inputType === "Select")
            return <div>
                <Row xs="auto">
                    <Col className="mb-1">
                        <Label className="middle-title">Data Source Type</Label>
                    </Col>
                    <Col className="mb-1">
                        <Input type="select" onChange={dataSourceOnChange} value={dataSource}>
                            <option value="" disabled>Select a data source</option>
                            <option value={DATA_SOURCE_VALUES_ID}>{DATA_SOURCE_VALUES}</option>
                            <option value={DATA_SOURCE_RESOURCE_ID}>{DATA_SOURCE_RESOURCE}</option>
                            <option value={DATA_SOURCE_CODE_CAT_ID}>{DATA_SOURCE_CODE_CAT}</option>
                            <option value={DATA_SOURCE_APP_CONFIG_ID}>{DATA_SOURCE_APP_CONFIG}</option>
                        </Input>
                    </Col>
                </Row>
                <hr/>
                <Row xs="auto">
                    {dataSourceConditionalSettings()}
                </Row>
            </div>
        else if (fieldJson.inputType === "Selectbox" || fieldJson.inputType === "Radio") 
            return <div>
                <Row xs="auto">
                    <Col className="mb-1">
                        <Label className="middle-title">Data Source</Label>
                    </Col>
                    <Col className="mb-1">
                        <Input type="select" onChange={dataSourceOnChange} value={dataSource}>
                            <option value="" disabled>Select a data source</option>
                            <option value={DATA_SOURCE_VALUES_ID}>{DATA_SOURCE_VALUES}</option>
                        </Input>
                    </Col>
                </Row>
                <hr/>
                <Row xs="auto">
                    {dataSourceConditionalSettings()}
                </Row>
            </div>
        else if (fieldJson.inputType === "File")
            return <div>
                <Row xs="auto">
                    <Col className="mb-1" xs={4}>
                        <Label className="middle-title">Minimum Size</Label>
                    </Col>
                    <Col className="mb-1" xs="auto">
                        <Input placeholder="MB" required value={minSize} onChange={onMinSizeChange}></Input> 
                    </Col>
                </Row>
                <Row xs="auto">
                    <Col className="mb-1" xs={4}>
                        <Label className="middle-title">Maximum Size</Label>
                    </Col>
                    <Col className="mb-1" xs="auto">
                        <Input placeholder="MB" required value={maxSize} onChange={onMaxSizeChange}></Input> 
                    </Col>
                </Row>
            </div>
        else 
            return <div>Data source configuration is not available for this input type</div>
    }

    function dataSourceOnChange(event) {
        setDataSource(event.target.value);
    }

    function onDataSourceResourceSelected(dataSourceResourceID, dataSourceResource, dataSourceResourceDependencyKey, dataSourceResourceDependencyValue, isDynamicFiltering) {
        setDataSourceResourceID(dataSourceResourceID);
        setDataSourceResource(dataSourceResource);
        setDataSourceResourceDependencyKey(dataSourceResourceDependencyKey);
        setDataSourceResourceDependencyValue(dataSourceResourceDependencyValue);
        setIsDynamicFiltering(isDynamicFiltering);
    }

    function onDataSourceDSPDataSelected(dataSourceSelected) {
        if (dataSource === DATA_SOURCE_CODE_CAT_ID) {
            setDataSourceCodeCat(dataSourceSelected);
        } else if (dataSource === DATA_SOURCE_APP_CONFIG_ID) {
            setDataSourceAppConfig(dataSourceSelected);
        }
    }

    function dataSourceConditionalSettings() {
        switch (dataSource) {
            case DATA_SOURCE_VALUES_ID: return <Value dataSourceValues={dataSourceValues} onValueAdd={setDataSourceValues}/>
            case DATA_SOURCE_RESOURCE_ID: return <Resource 
                                            dataSourceResourceID={dataSourceResourceID}
                                            dataSourceResource={dataSourceResource} 
                                            dataSourceResourceDependencyKey={dataSourceResourceDependencyKey}
                                            dataSourceResourceDependencyValue={dataSourceResourceDependencyValue}
                                            onResourceSelected={onDataSourceResourceSelected}
                                            globalFieldList={globalFieldList}
                                            currentComponentID={currentComponentID}
                                            isDynamicFiltering={isDynamicFiltering} />
            case DATA_SOURCE_CODE_CAT_ID: return <DSPDatabase dataSourceSelected={dataSourceCodeCat} dataType={DATA_SOURCE_CODE_CAT_ID} 
                                                    onDataSourceSelected={onDataSourceDSPDataSelected} key={DATA_SOURCE_CODE_CAT_ID}/>
            case DATA_SOURCE_APP_CONFIG_ID: return <DSPDatabase dataSourceSelected={dataSourceAppConfig} dataType={DATA_SOURCE_APP_CONFIG_ID} 
                                                    onDataSourceSelected={onDataSourceDSPDataSelected} key={DATA_SOURCE_APP_CONFIG_ID} />
            default: return <div></div>
        }
    }

    function onMinSizeChange(event) {
        setMinSize(event.target.value);
    }

    function onMaxSizeChange(event) {
        setMaxSize(event.target.value);
    }

    return <Modal className="modal-content" isOpen={props.show} backdrop={false} keyboard={false}>
        <ModalHeader>Configure Data Source</ModalHeader>
        <ModalBody>
            {dataSourceModelBody()}
        </ModalBody>
        <ModalFooter>
            <Button color="danger" onClick={removeDataSourceOnClick} outline>Clear</Button>    
            <Button onClick={closeDataSourceModal}>Cancel</Button>
            <Button color="success" onClick={updateDataSourceModal} outline>Save</Button>
        </ModalFooter>
    </Modal>
}

export default DataSourceModal;