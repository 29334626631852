import { faChevronDown, faChevronUp, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, Col, Collapse, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import { APPLICATION_ENQUIRY_LIST_URL, APPLICATION_ENQUIRY_PARAMS_URL, DATE_DISPLAY_FORMAT, IS_DEMO, LAYOUT_TYPE, PATH, REGEX } from '../../App/AppSettings';
import Layout from '../../Layout';
import { fetchRequest, getParams, navigateTo } from '../../Shared/Actions';
import { ERROR, getLangKey, LANGUAGE_KEYS } from '../../Shared/Constants/LanguageKeys';
import { DataTable, MobileCardActionButton } from '../../Shared/DataTable';
import { getCustomComponents } from '../../Shared/DataTable/BaseTable.js';
import { DateInput, SelectList, SMARTForm, Text, toastError, withSMARTWrapper } from '../../Shared/Forms';
import { arrayToApplicationTypeSelectListOptions, arrayToSelectListOptions, customMonthAgoString, todayString } from '../../Shared/Forms/FormUtils.js';

const FORM_CONTEXT = 'ApplicationEnquiry';

// Backend URL
const RETRIEVE_URL = APPLICATION_ENQUIRY_LIST_URL;
const PARAMS_URL = APPLICATION_ENQUIRY_PARAMS_URL;

const FORM_INITIAL_VALUES = {
    SubmissionNo: '',
    ReferenceNo: '',
    ApplicationType: '',
    AgencyName: '',
    ApplicationStatus: '',
    FromDate: customMonthAgoString,
    ToDate: todayString
};



const LABEL_SIZE = 2;
const INPUT_SIZE = 5;

class ApplicationEnquiry extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            values: FORM_INITIAL_VALUES,
            data: [],
            openEnquiryView: true,
            count: 0,
            modalState: undefined,
            applicationTypeOptions: [],
            applicationStatusOptions: [],
            agencyOptions: []
        };
    }

    componentDidMount = async () => {
        const { loader, t } = this.props;
        // Start loading
        loader.start();

        const response = await fetchRequest(PARAMS_URL, getParams(), IS_DEMO);
        const { IsSuccess, Data, Messages } = response.body;

        if (IsSuccess) {
            const { ApplicationType, ApplicationStatus, AgencyName } = Data;
            const applicationTypeOptions = [];
            const applicationStatusOptions = [];
            const agencyOptions = [];

            // Format filter params into SelectList format
            arrayToApplicationTypeSelectListOptions(ApplicationType, applicationTypeOptions, t);
            arrayToSelectListOptions(ApplicationStatus, applicationStatusOptions);
            arrayToSelectListOptions(AgencyName, agencyOptions);

            this.setState({
                applicationTypeOptions,
                applicationStatusOptions,
                agencyOptions,
                values: {
                    ...this.state.values,
                    AgencyName: agencyOptions.length === 1 ? agencyOptions[0].value : ''
                }
            });
        } else {
            toastError(t(getLangKey(ERROR.BACKEND_ERROR_MESSAGE, Messages)), Messages);
        }

        // End loading
        loader.done();
    };

    // CLASS METHODS
    setContent = rowData => {
        const { ID, FormName, InternalDescription, ReferenceNo, WorkflowStateId, LicenceTypeId, ApplicationTypeCodeName } = rowData;
        return <Link to={
            {
                pathname: PATH.ENQUIRY.APPLICATION_FORMDETAILS,
                state: {
                    ID, FormName, InternalDescription, ReferenceNo, WorkflowStateId, LicenceTypeId, ApplicationTypeCodeName
                }
            }
        }>{rowData.ReferenceNo}</Link>;
    };

    parseSearch = async ({ response }) => {
        const { loader, t } = this.props;

        // Start loading
        loader.start();
        if (response.success) {
            const { IsSuccess, Data, Messages } = response.body;

            //set state
            if (IsSuccess) {
                await this.setState({
                    success: IsSuccess,
                    data: Data,
                    count: ((Data !== undefined) ? Data.length : 0)
                });
                // End loading
                loader.done();
            } else {
                // Error message to be loaded here
                loader.error(t(getLangKey(ERROR.BACKEND_ERROR_MESSAGE, Messages)));
            }
        }
    };

    toggleEnquiryView = () => {
        this.setState({ openEnquiryView: !this.state.openEnquiryView });
    };

    renderMobileCardActions = data => {
        const { t } = this.props;
        const { ID, FormName, InternalDescription, ReferenceNo, WorkflowStateId } = data;
        return (
            <React.Fragment>
                <MobileCardActionButton
                    onClick={() => navigateTo((PATH.ENQUIRY.APPLICATION_FORMDETAILS), { ID, FormName, InternalDescription, ReferenceNo, WorkflowStateId })}
                    icon={faEye}
                    label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_VIEW)}
                />
            </React.Fragment>
        );
    };

    setAppTypeTranslatedContent = (rowData) => {
        const { t } = this.props;
        return (
            <>{t(getLangKey(LANGUAGE_KEYS.BLS_CODE_LICENCEAPPLICATIONTYPE_TITLE_KEY, rowData.ApplicationTypeCodeName))}</>
        );
    }

    getAppTypeTranslatedString = (rowData) => {
        const { t } = this.props;
        return t(getLangKey(LANGUAGE_KEYS.BLS_CODE_LICENCEAPPLICATIONTYPE_TITLE_KEY, rowData.ApplicationTypeCodeName));
    }

    renderGrid = () => {
        if (this.state.success) {
            const { t } = this.props;
            return (
                <React.Fragment>
                    <Row>
                        <Col>
                            <DataTable
                                pageSize={5}
                                minFilterChars='2'
                                data={this.state.data}
                                components={getCustomComponents()}
                                noResultsMessage={t(LANGUAGE_KEYS.BLS_INTERNALAPPENQUIRY_MESSAGE_PLACEHOLDER_NORESULTS)}
                                columns={
                                    {
                                        RunningNumber: { title: t(LANGUAGE_KEYS.BLS_INTERNALAPPENQUIRY_TABLE_TITLE_NUMBER), width: '5%', DBkey: 'Id' },
                                        ReferenceNo: { title: t(LANGUAGE_KEYS.BLS_INTERNALAPPENQUIRY_TABLE_TITLE_REFNO), width: '8%', DBkey: 'ReferenceNo', setContent: this.setContent },
                                        SubmissionNo: { title: t(LANGUAGE_KEYS.BLS_INTERNALAPPENQUIRY_TABLE_TITLE_SUBMISSIONNO), width: '8%', DBkey: 'SubmissionNo' },
                                        ApplicationType: { title: t(LANGUAGE_KEYS.BLS_INTERNALAPPENQUIRY_TABLE_TITLE_APPLICATIONTYPE), width: '5%', DBkey: 'ApplicationType', setContent: this.setAppTypeTranslatedContent, getTranslatedString: this.getAppTypeTranslatedString },
                                        AgencyName: { title: t(LANGUAGE_KEYS.BLS_INTERNALAPPENQUIRY_TABLE_TITLE_AGENCY), width: '20%', DBkey: 'AgencyName' },
                                        SubmissionDate: { title: t(LANGUAGE_KEYS.BLS_INTERNALAPPENQUIRY_TABLE_TITLE_SUBMISSIONDATE), isDate: true, width: '12%', DBkey: 'SubmissionDate' },
                                        ApplicationStatus: { title: t(LANGUAGE_KEYS.BLS_INTERNALAPPENQUIRY_TABLE_TITLE_STATUS), width: '15%', DBkey: 'ApplicationStatus' }
                                    }
                                }
                                renderMobileCardActions={this.renderMobileCardActions}
                            />
                        </Col>
                    </Row>
                </React.Fragment>
            );
        } else {
            return null;
        }
    };

    render() {

        const { t } = this.props;
        const FORM_VALIDATIONS = Yup.object().shape({
            SubmissionNo: Yup.string(),
            ReferenceNo: Yup.string(),
            ApplicationType: Yup.string(),
            AgencyName: Yup.string(),
            ApplicationStatus: Yup.string(),
            FromDate: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED)).matches(REGEX.DATE, t(ERROR.DATE_INVALID)),
            ToDate: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED)).matches(REGEX.DATE, t(ERROR.DATE_INVALID))
        });
        
        return (
            <React.Fragment>
                <Layout type={LAYOUT_TYPE.FORM} title={t(LANGUAGE_KEYS.BLS_INTERNALAPPENQUIRY_CONTENT_TITLE_APPENQUIRY)}>
                    <div className="grid-container">
                        <SMARTForm
                            formContext={FORM_CONTEXT}
                            formValues={this.state.values}
                            validationSchema={FORM_VALIDATIONS}
                            validateOnSubmit={true}
                            serverURL={RETRIEVE_URL}
                            isDummy={IS_DEMO}
                            submitCallback={this.parseSearch}
                        >
                            {({ values, errors, onChange, onChangeField, submitForm }) => (
                                <Container className="full-width">
                                    <Row>
                                        <Col className="col-gutter">
                                            <div onClick={this.toggleEnquiryView} className="chevron">
                                                <FontAwesomeIcon icon={this.state.openEnquiryView ? faChevronUp : faChevronDown} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Collapse className="collapse-enquiry" isOpen={this.state.openEnquiryView}>
                                            <Card className="enquiry-container">
                                                <CardBody className="enquiry-card-body">
                                                    <Text
                                                        name="SubmissionNo"
                                                        value={values.SubmissionNo}
                                                        placeholder={t(LANGUAGE_KEYS.BLS_INTERNALAPPENQUIRY_FORM_PLACEHOLDER_SUBMISSIONNO)}
                                                        onChange={onChange}
                                                        inputSize={INPUT_SIZE}
                                                        label={t(LANGUAGE_KEYS.BLS_INTERNALAPPENQUIRY_FORM_FIELD_SUBMISSIONNO)}
                                                        labelSize={LABEL_SIZE}
                                                        error={errors.SubmissionNo}
                                                    />
                                                    <Text
                                                        name="ReferenceNo"
                                                        value={values.ReferenceNo}
                                                        placeholder={t(LANGUAGE_KEYS.BLS_INTERNALAPPENQUIRY_FORM_PLACEHOLDER_REFNO)}
                                                        onChange={onChange}
                                                        inputSize={INPUT_SIZE}
                                                        label={t(LANGUAGE_KEYS.BLS_INTERNALAPPENQUIRY_FORM_FIELD_REFNO)}
                                                        labelSize={LABEL_SIZE}
                                                        error={errors.ReferenceNo}
                                                    />
                                                    <SelectList
                                                        name="ApplicationType"
                                                        value={values.ApplicationType}
                                                        options={this.state.applicationTypeOptions}
                                                        onChangeField={onChangeField}
                                                        placeholder={t(LANGUAGE_KEYS.BLS_INTERNALAPPENQUIRY_FORM_PLACEHOLDER_APPLICATIONTYPE)}
                                                        isMulti={false}
                                                        isClearable={true}
                                                        inputSize={INPUT_SIZE}
                                                        label={t(LANGUAGE_KEYS.BLS_INTERNALAPPENQUIRY_FORM_FIELD_APPLICATIONTYPE)}
                                                        labelSize={LABEL_SIZE}
                                                        error={errors.Status}
                                                    />
                                                    <SelectList
                                                        name="AgencyName"
                                                        value={values.AgencyName}
                                                        options={this.state.agencyOptions}
                                                        onChangeField={onChangeField}
                                                        placeholder={t(LANGUAGE_KEYS.BLS_INTERNALAPPENQUIRY_FORM_PLACEHOLDER_AGENCY)}
                                                        isMulti={false}
                                                        isClearable={true}
                                                        inputSize={INPUT_SIZE}
                                                        label={t(LANGUAGE_KEYS.BLS_INTERNALAPPENQUIRY_FORM_FIELD_AGENGY)}
                                                        labelSize={LABEL_SIZE}
                                                        error={errors.AgencyName}
                                                        isDisabled={this.state.agencyOptions.length === 1}
                                                    />
                                                    <SelectList
                                                        name="ApplicationStatus"
                                                        value={values.ApplicationStatus}
                                                        options={this.state.applicationStatusOptions}
                                                        onChangeField={onChangeField}
                                                        placeholder={t(LANGUAGE_KEYS.BLS_INTERNALAPPENQUIRY_FORM_PLACEHOLDER_APPLICATIONSTATUS)}
                                                        isMulti={false}
                                                        isClearable={true}
                                                        inputSize={INPUT_SIZE}
                                                        label={t(LANGUAGE_KEYS.BLS_INTERNALAPPENQUIRY_FORM_FIELD_APPLICATIONSTATUS)}
                                                        labelSize={LABEL_SIZE}
                                                        error={errors.ApplicationStatus}
                                                    />
                                                    <DateInput
                                                        name="FromDate"
                                                        value={values.FromDate}
                                                        placeholder={t(LANGUAGE_KEYS.BLS_INTERNALAPPENQUIRY_FORM_PLACEHOLDER_DATE)}
                                                        onChangeField={onChangeField}
                                                        date={true}
                                                        time={false}
                                                        max={new Date()}
                                                        format={DATE_DISPLAY_FORMAT.DATE}
                                                        inputSize={INPUT_SIZE}
                                                        label={t(LANGUAGE_KEYS.BLS_INTERNALAPPENQUIRY_FORM_FIELD_FROMDATE)}
                                                        labelSize={LABEL_SIZE}
                                                        error={errors.FromDate}
                                                        clearFieldEnabled={false}
                                                        disableKeyboardInput={true}
                                                    />
                                                    <DateInput
                                                        name="ToDate"
                                                        value={values.ToDate}
                                                        placeholder={t(LANGUAGE_KEYS.BLS_INTERNALAPPENQUIRY_FORM_PLACEHOLDER_DATE)}
                                                        onChangeField={onChangeField}
                                                        date={true}
                                                        time={false}
                                                        max={new Date()}
                                                        format={DATE_DISPLAY_FORMAT.DATE}
                                                        inputSize={INPUT_SIZE}
                                                        label={t(LANGUAGE_KEYS.BLS_INTERNALAPPENQUIRY_FORM_FIELD_TODATE)}
                                                        labelSize={LABEL_SIZE}
                                                        error={errors.ToDate}
                                                        clearFieldEnabled={false}
                                                        disableKeyboardInput={true}
                                                    />
                                                    <Row className="button-row">
                                                        <Button
                                                            color="forward"
                                                            size="sm"
                                                            type="button"
                                                            onClick={submitForm}>
                                                            {t(LANGUAGE_KEYS.BLS_INTERNALAPPENQUIRY_FORM_BUTTON_SEARCHAPP)}
                                                        </Button>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Collapse>
                                    </Row>
                                    <Row>
                                        <Col className="col-gutter record-count">
                                            {t(LANGUAGE_KEYS.BLS_INTERNALAPPENQUIRY_FORM_TITLE_RECORDS)} ({this.state.count})
                                        </Col>
                                    </Row>
                                    <Row>
                                        <div className="enquiry-grid-container">
                                            {this.renderGrid()}
                                        </div>
                                    </Row>
                                </Container>
                            )}
                        </SMARTForm>
                    </div>
                </Layout>
            </React.Fragment>
        );
    }
}

export default withTranslation()(withSMARTWrapper(ApplicationEnquiry));
