import Oidc from 'oidc-client';
import { AUTHORITY_CONFIG } from '../../App/AppSettings.js';

let mgr = new Oidc.UserManager(AUTHORITY_CONFIG.openid_client_configuration.authentication_settings);
mgr.events.addAccessTokenExpiring(function () {});
mgr.events.addUserSignedOut(function() {
  mgr.signoutRedirect();
});

class AuthHelper {
  static async getUser(callback) {
    mgr.getUser().then(function (user) {
      return callback(user);
    });
  }

  static intersect(a, b) {
    var t;
    if (b.length > a.length) 
    {
      t = b; 
      b = a; 
      a = t;
    }
    
    return a.filter(function (e) {
        return b.indexOf(e) > -1;
    });
  }

  static getManager()
  {
    return mgr;
  }
}

export default AuthHelper;