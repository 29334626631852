import React from 'react'
import Spinner from '../Loading/Spinner'

export default function Loader(props) {
    return (
        <div>
            {/* Always render an overlay spinner */}
            <Spinner visible={!(props.counter <= 0)} />
        </div>
    )
}