import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import { GET_CODES_BY_CODE_CATEGORIES, REGEX, GET_WORKFLOW_STATE_LIST } from '../../../App/AppSettings';
import { getParams, isValidForm } from '../../../Shared/Actions';
import { FIELD_SIZE } from '../../../Shared/Constants';
import { getLangKey, LANGUAGE_KEYS, ERROR, SCOPE_CAT_SUBCAT_STRINGS } from '../../../Shared/Constants/LanguageKeys';
import { ActionButton, DataTable } from '../../../Shared/DataTable';
import { getCustomComponents } from '../../../Shared/DataTable/BaseTable.js';
import { useAsyncFetch } from '../../../Shared/Fetch';
import { AccordionContainer, Paragraph, Radio, SelectList, Text } from '../../../Shared/Forms';
import { ModalConfirm } from '../../../Shared/Modal';
import { getLicenceTypeNameTranslationKey } from '../../DisplayComponents/DisplayUtils';
import { toastError } from '../../../Shared/Forms/Toaster.js';

const LICENCE_PAYMENT_CODE = "LicenceApplicationFeeType";

const PAYMENT_TYPES = [
    { label: "Upfront", value: "Upfront" },
    { label: "Additional", value: "Additional" }
]

const PAYMENT_TYPE = {
    ADDITIONAL: "Additional"
}

const PAYMENT_AMOUNT_MESSAGE = "Please input a valid decimal of up to 2 places";

const INITIAL_VALUES = {
    PaymentDescription: "",
    PaymentType: "",
    PaymentAmount: "",
    IsIncludeTax: "",
    PaymentWorkflowState: ""
}

export default function LicenceAppConfigPayment(props) {
    const { t } = useTranslation();
    const { values, toggleSection, errors } = props.smartFormValues;
    const [workflowStateList, setWorkflowStateList] = useState([]);
    const [modalState, setModalState] = useState(null);
    const [paymentData, setPaymentData] = useState(values.PaymentData);
    const [paymentTypes, setPaymentTypes] = useState(PAYMENT_TYPES);
    const [paymentValues, setPaymentValues] = useState(INITIAL_VALUES);
    const [paymentErrorDisplay, setPaymentErrorDisplay] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [count, setCount] = useState(values.PaymentData.length);
    const paymentTypeCat = SCOPE_CAT_SUBCAT_STRINGS.LICENCE_APP_FEE_TYPE;
    const licenceTypeNameTranslationKey = values.LicenceTypeName ? getLicenceTypeNameTranslationKey(values.LicenceTypeName) : null;

    const workflowResponse = useAsyncFetch(GET_WORKFLOW_STATE_LIST, getParams());

    const VALIDATION = Yup.object().shape({
        PaymentDescription: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED)),
        PaymentType: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED)),
        PaymentAmount: Yup.string().matches(REGEX.DECIMAL).required((ERROR.LABEL) + t(ERROR.REQUIRED)),
        IsIncludeTax: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED)),
        PaymentWorkflowState: Yup.string().when('PaymentType', {
            is: PAYMENT_TYPE.ADDITIONAL,
            then: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED))
        }),
    })

    const SECTION_NAMES = {
        PAYMENT: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_SECTION_PAYMENT), status: true }
    }

    const ADD_PAYMENT_ITEM_MODAL = {
        hasModal: true,
        name: "AddPaymentItem",
        modalHeader: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_MODAL_ADDPAYMENTITEM)
    };

    const EDIT_PAYMENT_ITEM_MODAL = {
        hasModal: true,
        name: "EditPaymentItem",
        modalHeader: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_MODAL_EDITPAYMENTITEM)
    };

    const DELETE_MODAL = {
        hasModal: true,
        name: "Delete",
        modalHeader: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_MODAL_DELETE),
        modalContent: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_MESSAGE_MODAL_DELETEPAYMENT)
    }


    const params = {
        categoryList: LICENCE_PAYMENT_CODE
    }
    const paymentResponse = useAsyncFetch(GET_CODES_BY_CODE_CATEGORIES, getParams(params));

    //Fetch select list options
    useEffect(() => {
        if (workflowResponse) {
            fetchWorkflowResponse(workflowResponse);
        }
        if (paymentResponse) {
            const fetchPaymentTypeListResponse = async (response) => {
                const { Data, IsSuccess } = response.body;
                if (response.success && IsSuccess) {
                    let paymentTypeOptions = [];
                    Data.forEach(element => {
                        paymentTypeOptions.push({ label: t(getLangKey(LANGUAGE_KEYS.BLS_CODE_CATEGORY_TITLE_KEY, element.CodeCategory, element.CodeName)), value: element.CodeTitle });
                    });
                    setPaymentTypes(paymentTypeOptions);
                }
            };

            fetchPaymentTypeListResponse(paymentResponse);
        }
    }, [workflowResponse, paymentResponse, t]);

    const fetchWorkflowResponse = async (response) => {
        const { IsSuccess, Messages, Data } = workflowResponse.body;

        if (workflowResponse.success && IsSuccess) {
            const states = (Data ? Data : []);
            setWorkflowStateList(states);
        }
        else {
            // Error message to be loaded here
            toastError(t(getLangKey(ERROR.BACKEND_ERROR_MESSAGE, Messages)), Messages);
        }
    }

    const displayRequiredMessage = (label) => (
        `${label} ${t(ERROR.REQUIRED)}`
    )

    const setValues = (name, value) => {
        setPaymentValues((prevValues) => ({ ...prevValues, [name]: value }));
    }

    const addPaymentItem = () => {
        if (isValidForm(VALIDATION, paymentValues)) {
            setPaymentErrorDisplay(false);
            let data = [...paymentData];
            data.push({ ...paymentValues, RowId: count });
            setCount(count + 1);
            setPaymentData(data);
            values.PaymentData = data;
            toggleModal(ADD_PAYMENT_ITEM_MODAL.name);
        }
        else {
            setPaymentErrorDisplay(true);
        }
    };

    const validatePaymentAmountMessage = () => {
        if (!paymentValues.PaymentAmount && paymentErrorDisplay) {
            return displayRequiredMessage(t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_PLACEHOLDER_PAYMENTAMOUNT));
        }
        if (!paymentValues.PaymentAmount.match(REGEX.DECIMAL)) {
            return PAYMENT_AMOUNT_MESSAGE;
        }
    }

    const editPaymentItem = (data) => {
        setPaymentValues(data);
        let index = values.PaymentData.findIndex(val =>
            val.RowId === data.RowId
        )
        setSelectedIndex(index);
        toggleModal(EDIT_PAYMENT_ITEM_MODAL.name);
    }

    const confirmEditPaymentItem = () => {
        if (isValidForm(VALIDATION, paymentValues)) {
            setPaymentErrorDisplay(false);
            let data = [...paymentData];
            data[selectedIndex] = paymentValues;
            setPaymentData(data);
            values.PaymentData = data;
            toggleModal(EDIT_PAYMENT_ITEM_MODAL.name);
            setSelectedIndex(null);
        }
        else {
            setPaymentErrorDisplay(true);
        }
    }

    const deleteItem = (data) => {
        toggleModal(DELETE_MODAL.name);
        let index = values.PaymentData.findIndex(val =>
            val.RowId === data.RowId
        )
        setSelectedIndex(index);
    }

    const confirmDeleteItem = () => {
        let data = [...paymentData];
        data.splice(selectedIndex, 1);
        setPaymentData(data);
        values.PaymentData = data;
        setSelectedIndex(null);
    }

    const toggleModal = (modalName) => {
        if (modalName === ADD_PAYMENT_ITEM_MODAL.name || modalName === EDIT_PAYMENT_ITEM_MODAL.name) {
            setPaymentErrorDisplay(false);
        }
        setModalState((modalState !== modalName) ? modalName : null);
    }

    const setIncludeTaxContent = (rowData) => {
        return (
            rowData.IsIncludeTax === "true" ? t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_YES) : t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_NO)
        )
    }

    const getIncludeTaxContent = (rowData) => {
        return (
            rowData.IsIncludeTax === "true" ? t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_YES) : t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_NO)
        )
    }

    const setPaymentDesc = rowData => {
        if (rowData.Id) {
            if (t(getLangKey(LANGUAGE_KEYS.BLS_LICENCE_LICENCEAPPLICATIONFEE_APPLICATIONFEE_KEY, licenceTypeNameTranslationKey, rowData.Id)) !== rowData.PaymentDescription) {
                return rowData.PaymentDescription;
            }
            return t(getLangKey(LANGUAGE_KEYS.BLS_LICENCE_LICENCEAPPLICATIONFEE_APPLICATIONFEE_KEY, licenceTypeNameTranslationKey, rowData.Id));
        } else {
            return rowData.PaymentDescription;
        }
    };

    const getPaymentDescTranslatedString = (rowData) => t(getLangKey(LANGUAGE_KEYS.BLS_LICENCE_LICENCEAPPLICATIONFEE_APPLICATIONFEE_KEY, licenceTypeNameTranslationKey, rowData.Id));

    const setPaymentType = (rowData) => {
        if (i18next.exists(getLangKey(LANGUAGE_KEYS.BLS_CODE_CATEGORY_TITLE_KEY, paymentTypeCat, rowData.PaymentTypeCodeName))) {
            return t(getLangKey(LANGUAGE_KEYS.BLS_CODE_CATEGORY_TITLE_KEY, paymentTypeCat, rowData.PaymentTypeCodeName));
        } else {
            return paymentTypes.find(pt => pt.value === rowData.PaymentType).label;
        }
    };
    const getPaymentTypeTranslatedString = (rowData) => t(getLangKey(LANGUAGE_KEYS.BLS_CODE_CATEGORY_TITLE_KEY, paymentTypeCat, rowData.PaymentType));

    const setPaymentWorkflowSate = (rowData) => {
        if(rowData.PaymentWorkflowState !== '' && rowData.PaymentWorkflowState.trim() !== '') {
            const states = workflowStateList.filter(el => el.value === rowData.PaymentWorkflowState.trim());
            if(states.length > 0) 
                return states[0].label;
            else
                return '';
        }
        return '';
    }
    const renderPaymentDetailsBody = () => {
        return (
            <div>
                <Paragraph
                    name="PaymentDescription"
                    value={paymentValues.PaymentDescription}
                    placeholder={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_PLACEHOLDER_PAYMENTITEMDESC)}
                    onChange={(e) => setValues(e.target.name, e.target.value)}
                    rows={3}
                    minLength={0}
                    inputSize={FIELD_SIZE.LARGE}
                    label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_PAYMENTITEMDESC)}
                    error={!paymentValues.PaymentDescription && paymentErrorDisplay ? displayRequiredMessage(t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_PLACEHOLDER_PAYMENTITEMDESC)) : ""}
                    required
                />
                <SelectList
                    name="PaymentType"
                    value={paymentValues.PaymentType}
                    placeholder={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_PLACEHOLDER_PAYMENTTYPE)}
                    minLength={0}
                    maxLength={140}
                    inputSize={FIELD_SIZE.MEDIUM}
                    label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_PAYMENTTYPE)}
                    required
                    options={paymentTypes}
                    onChangeField={(name, value) => setValues(name, value)}
                    isMulti={false}
                    error={!paymentValues.PaymentType && paymentErrorDisplay ? displayRequiredMessage(t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_PLACEHOLDER_PAYMENTTYPE)) : ""}
                />
                <Text
                    name="PaymentAmount"
                    value={paymentValues.PaymentAmount}
                    placeholder={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_PLACEHOLDER_PAYMENTAMOUNT)}
                    onChange={(e) => setValues(e.target.name, e.target.value)}
                    minLength={0}
                    maxLength={140}
                    inputSize={FIELD_SIZE.LARGE}
                    label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_PAYMENTAMOUNT)}
                    error={paymentErrorDisplay ? validatePaymentAmountMessage() : ""}
                    required
                />
                <Radio
                    name="IsIncludeTax"
                    value={paymentValues.IsIncludeTax}
                    options={[
                        { label: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_YES), value: "true" },
                        { label: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_NO), value: "false" }]}
                    onChange={(e) => setValues(e.target.name, e.target.value)}

                    inputSize={FIELD_SIZE.NONE}
                    label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_INCLUDETAX)}
                    error={!paymentValues.IsIncludeTax && paymentErrorDisplay ? displayRequiredMessage(t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_INCLUDETAX)) : ""}
                    required
                />
                {paymentValues.PaymentType === PAYMENT_TYPE.ADDITIONAL
                    &&
                    <SelectList
                        name="PaymentWorkflowState"
                        value={paymentValues.PaymentWorkflowState}
                        options={workflowStateList}
                        onChangeField={(name, value) => setValues(name, value)}
                        placeholder={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_PLACEHOLDER_WORKFLOWSTATE)}
                        isMulti={false}
                        isClearable={false}
                        inputSize={FIELD_SIZE.LARGE}
                        label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_WORKFLOWSTATE)}
                        error={!paymentValues.PaymentWorkflowState && paymentErrorDisplay ? displayRequiredMessage(t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_PLACEHOLDER_WORKFLOWSTATE)) : ""}
                        required
                    />
                }
            </div>
        );
    }

    const renderPaymentGrid = () => {
        return (
            <React.Fragment>
                <Row>
                    <Col>
                        <Button className="float-right" color="neutral" onClick={() => { setPaymentValues(INITIAL_VALUES); toggleModal(ADD_PAYMENT_ITEM_MODAL.name) }}>{t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_ADDPAYMENTITEM)}</Button>
                    </Col>
                </Row>
                <div className="smart-list">
                    <DataTable
                        pageSize="5"
                        minFilterChars='2'
                        data={paymentData}  
                        noResultsMessage={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_MESSAGE_PLACEHOLDER_NOPAYMENTITEM)}
                        components={getCustomComponents()}
                        columns={
                            {
                                RunningNumber: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_NUMBER), width: '5%' },
                                PaymentDescription: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_PAYMENTITEMDESC), width: '30%', DBkey: 'PaymentDescription', setContent: setPaymentDesc, getTraslatedString: getPaymentDescTranslatedString },
                                PaymentType: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_PAYMENTTYPE), width: '20%', DBkey: 'PaymentType', setContent: setPaymentType, getTraslatedString: getPaymentTypeTranslatedString },
                                PaymentAmount: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_PAYMENTAMOUNT), width: '15%', DBkey: 'PaymentAmount' },
                                IsIncludeTax: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_INCLUDETAX), width: '10%', DBkey: 'IsIncludeTax', setContent: setIncludeTaxContent, getTranslatedString: getIncludeTaxContent },
                                PaymentWorkflowState: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_WORKFLOWSTATE), width: '10%', DBkey: 'PaymentWorkflowState', setContent: setPaymentWorkflowSate },
                                Actions: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_ACTION), width: '10%' }
                            }
                        }
                        renderActions={({ RowData }) => (
                            <React.Fragment>
                                <ActionButton tooltip={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_EDIT)} color="forward" onClick={() => editPaymentItem(RowData)}><FontAwesomeIcon icon={faEdit} /></ActionButton>
                                <ActionButton tooltip={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_DELETE)} color="hazard" onClick={() => deleteItem(RowData)}><FontAwesomeIcon fixedWidth icon={faTrash} /></ActionButton>
                            </React.Fragment>
                        )}
                    />
                    {errors.PaymentData &&
                    <div> <label class="label-feedback">{errors.PaymentData}</label></div>
                    }
                    <ModalConfirm
                        isOpen={(modalState === DELETE_MODAL.name)}
                        contentHeader={DELETE_MODAL.modalHeader}
                        contentBody={DELETE_MODAL.modalContent}
                        confirmText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_CONFIRM)}
                        confirmCallback={() => { toggleModal(DELETE_MODAL.name); confirmDeleteItem(); }}
                        cancelText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_CANCEL)}
                        cancelCallback={() => toggleModal(DELETE_MODAL.name)}
                    />
                    <ModalConfirm
                        isOpen={(modalState === EDIT_PAYMENT_ITEM_MODAL.name)}
                        contentHeader={EDIT_PAYMENT_ITEM_MODAL.modalHeader}
                        confirmText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_EDIT)}
                        confirmCallback={() => confirmEditPaymentItem()}
                        cancelText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_CANCEL)}
                        cancelCallback={() => toggleModal(EDIT_PAYMENT_ITEM_MODAL.name)}
                        contentBody={renderPaymentDetailsBody()}
                    />

                    <ModalConfirm
                        isOpen={(modalState === ADD_PAYMENT_ITEM_MODAL.name)}
                        contentHeader={ADD_PAYMENT_ITEM_MODAL.modalHeader}
                        confirmText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_ADD)}
                        confirmCallback={() => {
                            addPaymentItem()
                        }}
                        cancelText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_CANCEL)}
                        cancelCallback={() => toggleModal(ADD_PAYMENT_ITEM_MODAL.name)}
                        contentBody={renderPaymentDetailsBody()}
                    />
                </div>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <Container className="full-width">
                <AccordionContainer
                    title={SECTION_NAMES.PAYMENT.title}
                    active={SECTION_NAMES.PAYMENT.status}
                    onClick={toggleSection}
                    isIndividual={true}
                    sections={SECTION_NAMES}
                >
                    {workflowStateList.length > 0 ? renderPaymentGrid() : <></>}
                </AccordionContainer>
            </Container>
        </React.Fragment>
    );
}


