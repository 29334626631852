import moment from 'moment';
import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import * as Yup from 'yup';
import { DATE_DISPLAY_FORMAT, LAYOUT_TYPE, UPDATE_WORKING_HOUR_URL, GET_WORKING_HOUR_URL, WORKING_HOUR_MANAGEMENT_URL } from '../../App/AppSettings';
import Layout from '../../Layout';
import { ERROR } from '../../Localization';
import { fetchRequest, getParams, navigateTo } from '../../Shared/Actions';
import { FIELD_SIZE } from '../../Shared/Constants';
import { Plaintext, DateInput, SelectList, SMARTForm, ToggleSwitchBoolean, withSMARTWrapper } from '../../Shared/Forms';
import { toastError, toastSuccess } from '../../Shared/Forms/Toaster.js';
import history from '../../Shared/History';
import PageFooter from '../../Shared/PageFooter';

const FORM_CONTEXT = "WorkingHourExcepConfig";

const FORM_VALIDATIONS = Yup.object().shape({
    WorkingHourCategory: Yup.string().required(ERROR.REQUIRED).min(0, ERROR.MIN_LENGTH_LIMIT).max(140, ERROR.MAX_LENGTH_LIMIT),
    WorkingHourDayofWeek: Yup.string(),
});

const FORM_INITIAL_VALUES = {
    WorkingHourCategory: '',
    WorkingHourDayofWeek: '',
    WorkingHourExceptionDate: '',
    WorkingHourWorkStart: '',
    WorkingHourWorkEnd: '',
    WorkingHourBreakStart: '',
    WorkingHourBreakEnd: '',
    WorkingHourWorkingDay: ''
};

//Time validation Work End
function checkWorkEnd(start, end) {
    var mStart = moment(start, DATE_DISPLAY_FORMAT.TIME);
    var mEnd = moment(end, DATE_DISPLAY_FORMAT.TIME);
    if (mEnd.isBefore(mStart)) return ERROR.TIME_INVALID_WE;
}

//Time validation Break Start
function checkBreakStart(start, end) {
    var mStart = moment(start, DATE_DISPLAY_FORMAT.TIME);
    var mEnd = moment(end, DATE_DISPLAY_FORMAT.TIME);
    if (mEnd.isBefore(mStart)) return ERROR.TIME_INVALID_BS;
}

//Time validation Break start, Work end
function checkBSWE(start, end) {
    var mStart = moment(start, DATE_DISPLAY_FORMAT.TIME);
    var mEnd = moment(end, DATE_DISPLAY_FORMAT.TIME);
    if (mEnd.isBefore(mStart)) return ERROR.TIME_INVALID_BSWE;
}

//Time validation Break End
function checkBreakEnd(start, end) {
    var mStart = moment(start, DATE_DISPLAY_FORMAT.TIME);
    var mEnd = moment(end, DATE_DISPLAY_FORMAT.TIME);
    if (mEnd.isBefore(mStart)) return ERROR.TIME_INVALID_BE;
}

class WorkingHourUpdate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: 0,
            values: FORM_INITIAL_VALUES,
            dropDownArrayCategory: [],
        };
    }

    async componentDidMount() {
        const { state } = history.location;
        const { loader, pageNotFound } = this.props;

        if (state && state.ID) {
            loader.start();
            const response = await fetchRequest(GET_WORKING_HOUR_URL, getParams({ Id: state.ID }), false);
            loader.done();
            const { IsSuccess, Data, Messages } = response.body;
            if (response.success && IsSuccess) {
                this.setState({
                    Id: state.ID,
                    values: Data,

                });
            }
            else {
                // Error message to be loaded here
                loader.error(Messages);
            }
        }
        else {
            pageNotFound();
        }
    }

    // Fn: Massage form values to be submitted
    massageValues = (values, submitPlainForm) => {
        // Reset WorkStart, WorkEnd, BreakStart, Breakend if WorkingDay is set to false
        if (!values.WorkingHourWorkingDay) {
            values.WorkingHourWorkStart = "";
            values.WorkingHourWorkEnd = "";
            values.WorkingHourBreakStart = "";
            values.WorkingHourBreakEnd = "";
        }

        // Add Id
        values.Id = this.state.Id;
        submitPlainForm();
    }

    submitCallback = ({ response }) => {
        if (response.success) {
            const { IsSuccess, RedirectURL, Message } = response.body;
            if (IsSuccess) {
                if (RedirectURL) {
                    navigateTo(RedirectURL, "");
                } else {
                    navigateTo(WORKING_HOUR_MANAGEMENT_URL, "");
                }
                toastSuccess("Working Hour has been updated successfully.");
            }
            else {
                toastError(Message);
            }
        } else {
            toastError("Server could not be reached, please try again later")
        }
    }

    render() {
        return (
            <React.Fragment>
                <Layout type={LAYOUT_TYPE.FORM} title="Update Working Hour">
                    <SMARTForm
                        formContext={FORM_CONTEXT}
                        formValues={this.state.values}
                        validationSchema={FORM_VALIDATIONS}
                        validateOnSubmit={true}
                        serverURL={UPDATE_WORKING_HOUR_URL}
                        isDummy={false}
                        submitCallback={this.submitCallback}
                    >
                        {({ values, errors, onChangeField, submitPlainForm }) => (
                            <React.Fragment>
                                <Row className="body-content admin-content-wrapper">
                                    <Col>
                                        <Plaintext
                                            name="WorkingHourCategory"
                                            value={values.WorkingHourCategory}
                                            placeholder={"Category"}
                                            inputSize={FIELD_SIZE.SMALL}
                                            label={"Category"}
                                            required
                                        />
                                        <SelectList
                                            name="WorkingHourDayofWeek"
                                            value={values.WorkingHourDayofWeek.toString()}
                                            options={[
                                                { label: "Monday", value: "1" },
                                                { label: "Tuesday", value: "2" },
                                                { label: "Wednesday", value: "3" },
                                                { label: "Thursday", value: "4" },
                                                { label: "Friday", value: "5" },
                                                { label: "Saturday", value: "6" },
                                                { label: "Sunday", value: "7" }]
                                            }
                                            placeholder="Day of Week"
                                            onChangeField={onChangeField}
                                            isMulti={false}
                                            inputSize={FIELD_SIZE.SMALL}
                                            label="Day of Week"
                                            isDisabled={true}
                                        />
                                        <ToggleSwitchBoolean
                                            name="WorkingHourWorkingDay"
                                            value={values.WorkingHourWorkingDay}
                                            onChangeField={onChangeField}
                                            inputSize={FIELD_SIZE.SMALL}
                                            trueText="Work"
                                            falseText="No Work"
                                            defaultOn={true}
                                            label="Is Working Day"
                                        />
                                        <DateInput
                                            name="WorkingHourWorkStart"
                                            value={!values.WorkingHourWorkStart ? "" : values.WorkingHourWorkStart}
                                            placeholder="Work Start"
                                            onChangeField={onChangeField}
                                            time={true}
                                            date={false}
                                            min="1900-01-01 00:00"
                                            max="2100-12-31 00:00"
                                            format={DATE_DISPLAY_FORMAT.TIME}
                                            inputSize={FIELD_SIZE.SMALL}
                                            label="Work Start"
                                            error={errors.WorkingHourWorkStart}
                                            disabled={!values.WorkingHourWorkingDay ? true : false}
                                        />
                                        <DateInput
                                            name="WorkingHourWorkEnd"
                                            value={!values.WorkingHourWorkingDay ? "" : values.WorkingHourWorkEnd}
                                            placeholder="Work End"
                                            onChangeField={onChangeField}
                                            time={true}
                                            date={false}
                                            min="1900-01-01 00:00"
                                            max="2100-12-31 00:00"
                                            format={DATE_DISPLAY_FORMAT.TIME}
                                            inputSize={FIELD_SIZE.SMALL}
                                            label="Work End"
                                            error={!values.WorkingHourWorkingDay ? "" : checkWorkEnd(values.WorkingHourWorkStart, values.WorkingHourWorkEnd)}
                                            disabled={!values.WorkingHourWorkingDay ? true : false}
                                        />
                                        <DateInput
                                            name="WorkingHourBreakStart"
                                            value={!values.WorkingHourWorkingDay ? "" : values.WorkingHourBreakStart}
                                            placeholder="Break Start"
                                            onChangeField={onChangeField}
                                            time={true}
                                            date={false}
                                            min="1900-01-01 00:00"
                                            max="2100-12-31 00:00"
                                            format={DATE_DISPLAY_FORMAT.TIME}
                                            inputSize={FIELD_SIZE.SMALL}
                                            label="Break Start"
                                            error={!values.WorkingHourWorkingDay ? "" : checkBreakStart(values.WorkingHourWorkStart, values.WorkingHourBreakStart) || checkBSWE(values.WorkingHourBreakStart, values.WorkingHourWorkEnd)}
                                            disabled={!values.WorkingHourWorkingDay ? true : false}
                                        />
                                        <DateInput
                                            name="WorkingHourBreakEnd"
                                            value={!values.WorkingHourWorkingDay ? "" : values.WorkingHourBreakEnd}
                                            placeholder="Break End"
                                            onChangeField={onChangeField}
                                            time={true}
                                            date={false}
                                            min="1900-01-01 00:00"
                                            max="2100-12-31 00:00"
                                            format={DATE_DISPLAY_FORMAT.TIME}
                                            inputSize={FIELD_SIZE.SMALL}
                                            label="Break End"
                                            error={!values.WorkingHourWorkingDay ? "" : checkBreakEnd(values.WorkingHourBreakStart, values.WorkingHourBreakEnd)}
                                            disabled={!values.WorkingHourWorkingDay ? true : false}
                                        />
                                        
                                    </Col>
                                </Row>
                                <PageFooter type={LAYOUT_TYPE.FORM}>
                                    <Button color="backward" size="sm" type="button" onClick={() => navigateTo(WORKING_HOUR_MANAGEMENT_URL)}>Back</Button>
                                    <Button color="forward" size="sm" type="button" onClick={() => this.massageValues(values, submitPlainForm)}>Update</Button>
                                </PageFooter>
                            </React.Fragment>
                        )}
                    </SMARTForm>
                </Layout>
            </React.Fragment>
        );
    }
}

export default withSMARTWrapper(WorkingHourUpdate);

