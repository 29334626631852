import React from 'react';

// HoC: To toggle modal using a Wrapper
export const withModalWrapper = (WrappedComponent) => {

    class ModalWrapper extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                modalState: undefined
            };
        }

        // Fn: Toggles state of Modal (show/hide) based on given modalName
        toggleModal = (modalName) => {
            // Default state: Allow opened modal to hide
            // Otherwise, open new modal and hide others
            let newModalState = (this.state.modalState !== modalName) ? modalName : undefined;

            // Update new modalState
            this.setState({ modalState: newModalState });
        };

        render() {
            const modal = {
                modalState: this.state.modalState,
                toggleModal: this.toggleModal,
            };

            return (
                <WrappedComponent
                    {...this.props}
                    modal={modal}
                />
            );
        }
    }

    return ModalWrapper;
};