import PropTypes from 'prop-types';
import React from 'react';
import { ADDRESS, DEFAULT_OVERSEAS_COUNTRY_ID_TYPE } from '../../../App/AppSettings';
import Section from '../../../Section';
import SubSection from '../../../Section/SubSection';
import { Plaintext } from '../../../Shared/Forms';
import { withTranslation } from 'react-i18next';
import { LANGUAGE_KEYS } from '../../../Shared/Constants/LanguageKeys';
import { getLangKey } from '../../../Content/DisplayComponents/DisplayUtils';
import i18n from "i18next";
const IS_UPPER = true;

class InternalGeneralInformationSection extends React.Component {

    render() {

        const { values, type, sectionState, sectionName, toggleSection, t } = this.props;
        const id_type = i18n.exists(getLangKey(LANGUAGE_KEYS.BLS_CODE_CATEGORY_TITLE_KEY, 'IdType', values.GI_IDtype)) ?
            i18n.t(getLangKey(LANGUAGE_KEYS.BLS_CODE_CATEGORY_TITLE_KEY, 'IdType', values.GI_IDtype)) :
            values.GI_IDtype;
            
        const country = i18n.exists(getLangKey(LANGUAGE_KEYS.BLS_CODE_CATEGORY_TITLE_KEY, 'Country', values.GI_Passportissuingcountry)) ?
            i18n.t(getLangKey(LANGUAGE_KEYS.BLS_CODE_CATEGORY_TITLE_KEY, 'Country', values.GI_Passportissuingcountry)) :
            values.GI_Passportissuingcountry;
        const nationality = i18n.exists(getLangKey(LANGUAGE_KEYS.BLS_CODE_CATEGORY_TITLE_KEY, 'Country', values.GI_Nationality)) ?
            i18n.t(getLangKey(LANGUAGE_KEYS.BLS_CODE_CATEGORY_TITLE_KEY, 'Country', values.GI_Nationality)) :
            values.GI_Nationality;
            
        return (
            <Section
                type={type}
                sectionState={sectionState}
                sectionName={sectionName}
                toggleSection={toggleSection}
            >
                <SubSection orientation="left" title={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_SECTION_KEY, 'GI_ApplicantParticulars'))}>
                    <Plaintext
                        label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_Salutation'))}
                        name="GI_Salutation"
                        value={t(getLangKey(LANGUAGE_KEYS.BLS_CODE_CATEGORY_TITLE_KEY, 'Salutation', values.GI_Salutation))}
                        isUpper={IS_UPPER}
                    />
                    <Plaintext
                        label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_Name'))}
                        name="GI_Name"
                        value={values.GI_Name}
                        isUpper={IS_UPPER}
                    />
                    <Plaintext
                        label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_IDtype'))}
                        name="GI_IDtype"
                        value={id_type}
                        isUpper={IS_UPPER}
                    />
                    <Plaintext
                        label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_IDnumber'))}
                        name="GI_IDnumber"
                        value={values.GI_IDnumber}
                        isUpper={IS_UPPER}
                    />
                    {values.GI_IDtype === DEFAULT_OVERSEAS_COUNTRY_ID_TYPE &&

                        <Plaintext
                            label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_Passportissuingcountry'))}
                            name="GI_Passportissuingcountry"
                            value={country}
                            isUpper={IS_UPPER}
                        />
                    }
                    <Plaintext
                        label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_Nationality'))}
                        name="GI_Nationality"
                        value={nationality}
                        isUpper={IS_UPPER}
                    />
                    <Plaintext
                        label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_Gender'))}
                        name="GI_Gender"
                        value={values.GI_Gender}
                        isUpper={IS_UPPER}
                    />
                    <Plaintext
                        label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_DateOfBirth'))}
                        name="GI_DateOfBirth"
                        value={values.GI_DateOfBirth}
                        isUpper={IS_UPPER}
                    />
                    <Plaintext
                        label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_PrimaryContact'))}
                        name="GI_PrimaryContact"
                        value={values.GI_PrimaryContact}
                        isUpper={IS_UPPER}
                    />
                    <Plaintext
                        label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_MobileNumber'))}
                        name="GI_MobileNumber"
                        value={values.GI_MobileNumber}
                        isUpper={IS_UPPER}
                    />
                    <Plaintext
                        label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_OfficeNumber'))}
                        name="GI_OfficeNumber"
                        value={values.GI_OfficeNumber}
                        isUpper={IS_UPPER}
                    />
                    <Plaintext
                        label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_HomeNumber'))}
                        name="GI_HomeNumber"
                        value={values.GI_HomeNumber}
                        isUpper={IS_UPPER}
                    />
                    <Plaintext
                        label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_FaxNumber'))}
                        name="GI_FaxNumber"
                        value={values.GI_FaxNumber}
                        isUpper={IS_UPPER}
                    />
                    <Plaintext
                        label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_Designation'))}
                        name="GI_Designation"
                        value={values.GI_Designation}
                        isUpper={IS_UPPER}
                    />
                    <Plaintext
                        label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_Email'))}
                        name="GI_Email"
                        value={values.GI_Email}
                        isUpper={IS_UPPER}
                    />
                    <Plaintext
                        label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_AltEmailAddress'))}
                        name="GI_AltEmailAddress"
                        value={values.GI_AltEmailAddress}
                        isUpper={IS_UPPER}
                    />
                </SubSection>
                <SubSection orientation="left" title={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_SECTION_KEY, 'GI_ApplicantAddress'))}>
                    <Plaintext
                        label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_AddressAddressType'))}
                        name="GI_AddressAddressType"
                        value={values.GI_AddressAddressType}
                        isUpper={IS_UPPER}
                    />
                    <Plaintext
                        label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_AddressTypeOfPremises'))}
                        name="GI_AddressTypeOfPremises"
                        value={values.GI_AddressTypeOfPremises}
                        isUpper={IS_UPPER}
                    />
                    <Plaintext
                        label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_AddressPostalCode'))}
                        name="GI_AddressPostalCode"
                        value={values.GI_AddressPostalCode}
                        isUpper={IS_UPPER}
                    />
                    {values.GI_AddressAddressType === ADDRESS.ADDRESS_TYPE.LOCAL &&
                        <>
                            <Plaintext
                                label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_AddressAddressLine1BlockNo'))}
                                name="GI_AddressAddressLine1BlockNo"
                                value={values.GI_AddressAddressLine1BlockNo}
                                isUpper={IS_UPPER}
                            />
                            <Plaintext
                                label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_AddressAddressLine1StreetName'))}
                                name="GI_AddressAddressLine1StreetName"
                                value={values.GI_AddressAddressLine1StreetName}
                                isUpper={IS_UPPER}
                            />
                            <Plaintext
                                label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_AddressAddressLine2LevelNo'))}
                                name="GI_AddressAddressLine2LevelNo"
                                value={values.GI_AddressAddressLine2LevelNo}
                                isUpper={IS_UPPER}
                            />
                            <Plaintext
                                label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_AddressAddressLine2UnitNo'))}
                                name="GI_AddressAddressLine2UnitNo"
                                value={values.GI_AddressAddressLine2UnitNo}
                                isUpper={IS_UPPER}
                            />
                            <Plaintext
                                label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_AddressAddressLine2BuildingName'))}
                                name="GI_AddressAddressLine2BuildingName"
                                value={values.GI_AddressAddressLine2BuildingName}
                                isUpper={IS_UPPER}
                            />
                        </>
                    }
                    {values.GI_AddressAddressType === ADDRESS.ADDRESS_TYPE.OVERSEAS &&
                        <>
                            <Plaintext
                                label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_AddressCountry'))}
                                name="GI_AddressCountry"
                                value={values.GI_AddressCountry}
                                isUpper={IS_UPPER}
                            />
                            <Plaintext
                                label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_AddressAddressLine1AddressLine1'))}
                                name="GI_AddressAddressLine1AddressLine1"
                                value={values.GI_AddressAddressLine1AddressLine1}
                                isUpper={IS_UPPER}
                            />
                            <Plaintext
                                label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_AddressAddressLine2AddressLine2'))}
                                name="GI_AddressAddressLine2AddressLine2"
                                value={values.GI_AddressAddressLine2AddressLine2}
                                isUpper={IS_UPPER}
                            />
                            <Plaintext
                                label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_AddressCity'))}
                                name="GI_AddressCity"
                                value={values.GI_AddressCity}
                                isUpper={IS_UPPER}
                            />
                            <Plaintext
                                label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_AddressState'))}
                                name="GI_AddressState"
                                value={values.GI_AddressState} isUpper={IS_UPPER}
                            />
                        </>
                    }
                </SubSection>
                <SubSection orientation="left" title={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_SECTION_KEY, 'GI_MailingAddress'))}>
                    <Plaintext
                        label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_MailingAddressAddressType'))}
                        name="GI_MailingAddressAddressType"
                        value={values.GI_MailingAddressAddressType}
                        isUpper={IS_UPPER}
                    />
                    <Plaintext
                        label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_MailingAddressTypeOfPremises'))}
                        name="GI_MailingAddressTypeOfPremises"
                        value={values.GI_MailingAddressTypeOfPremises}
                        isUpper={IS_UPPER}
                    />
                    <Plaintext
                        label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_MailingAddressPostalCode'))}
                        name="GI_MailingAddressPostalCode"
                        value={values.GI_MailingAddressPostalCode}
                        isUpper={IS_UPPER}
                    />
                    {values.GI_MailingAddressAddressType === ADDRESS.ADDRESS_TYPE.LOCAL &&
                        <>
                            <Plaintext
                                label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_MailingAddressAddressLine1BlockNo'))}
                                name="GI_MailingAddressAddressLine1BlockNo"
                                value={values.GI_MailingAddressAddressLine1BlockNo}
                                isUpper={IS_UPPER}
                            />
                            <Plaintext
                                label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_MailingAddressAddressLine1StreetName'))}
                                name="GI_MailingAddressAddressLine1StreetName"
                                value={values.GI_MailingAddressAddressLine1StreetName}
                                isUpper={IS_UPPER}
                            />
                            <Plaintext
                                label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_MailingAddressAddressLine2LevelNo'))}
                                name="GI_MailingAddressAddressLine2LevelNo"
                                value={values.GI_MailingAddressAddressLine2LevelNo}
                                isUpper={IS_UPPER}
                            />
                            <Plaintext
                                label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_MailingAddressAddressLine2UnitNo'))}
                                name="GI_MailingAddressAddressLine2UnitNo"
                                value={values.GI_MailingAddressAddressLine2UnitNo}
                                isUpper={IS_UPPER}
                            />
                            <Plaintext
                                label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_MailingAddressAddressLine2BuildingName'))}
                                name="GI_MailingAddressAddressLine2BuildingName"
                                value={values.GI_MailingAddressAddressLine2BuildingName}
                                isUpper={IS_UPPER}
                            />
                        </>
                    }
                    {values.GI_MailingAddressAddressType === ADDRESS.ADDRESS_TYPE.OVERSEAS &&
                        <>
                            <Plaintext
                                label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_MailingAddressCountry'))}
                                name="GI_MailingAddressCountry"
                                value={values.GI_MailingAddressCountry}
                                isUpper={IS_UPPER}
                            />
                            <Plaintext
                                label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_MailingAddressAddressLine1AddressLine1'))}
                                name="GI_MailingAddressAddressLine1AddressLine1"
                                value={values.GI_MailingAddressAddressLine1AddressLine1}
                                isUpper={IS_UPPER}
                            />
                            <Plaintext
                                label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_MailingAddressAddressLine2AddressLine2'))}
                                name="GI_MailingAddressAddressLine2AddressLine2"
                                value={values.GI_MailingAddressAddressLine2AddressLine2}
                                isUpper={IS_UPPER}
                            />
                            <Plaintext
                                label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_MailingAddressCity'))}
                                name="GI_MailingAddressCity"
                                value={values.GI_MailingAddressCity}
                                isUpper={IS_UPPER}
                            />
                            <Plaintext
                                label={t(getLangKey(LANGUAGE_KEYS.BLS_GENERALINFORMATION_FORM_FIELD_KEY, 'GI_MailingAddressState'))}
                                name="GI_MailingAddressState"
                                value={values.GI_MailingAddressState}
                                isUpper={IS_UPPER}
                            />
                        </>
                    }
                </SubSection>

            </Section>
        );
    }
}

InternalGeneralInformationSection.propTypes = {
    values: PropTypes.object.isRequired
};

export default withTranslation()(InternalGeneralInformationSection);
