import { faChevronDown, faChevronUp, faDownload, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Card, CardBody, Col, Collapse, Container, Nav, NavItem, NavLink, Row } from 'reactstrap';
import * as Yup from 'yup';
import {
    DATE_DISPLAY_FORMAT, GET_CODES_BY_CODE_CATEGORIES, GET_INTERNAL_FILE_URL, IS_DEMO, LAYOUT_TYPE, LICENCE_ENQUIRY_PARAMS_CODE_URL,
    REPORT_ENQUIRY_SEARCH_LICENCE_URL, REPORT_HISTORY_LICENCE_URL, REPORT_SAVE_AND_DOWNLOAD_LICENCE_URL
} from '../../../App/AppSettings';
import Layout from '../../../Layout';
import { fetchRequest, getParams, postParams } from '../../../Shared/Actions';
import { ERROR, LANGUAGE_KEYS } from '../../../Shared/Constants/LanguageKeys';
import { ActionButton, DataTable, MobileCardActionButton } from '../../../Shared/DataTable';
import { getCustomComponents } from '../../../Shared/DataTable/BaseTable.js';
import { DateInput, Plaintext, SelectList, SMARTForm, toastError, withSMARTWrapper } from '../../../Shared/Forms';
import { arrayToLicenceStatusSelectListOptions, customMonthAgoString, todayString } from '../../../Shared/Forms/FormUtils.js';
import { ModalConfirm } from '../../../Shared/Modal';
import { getLangKey, getLicenceTypeNameTranslationKey } from '../../DisplayComponents/DisplayUtils';

const SECTIONS = {
    SEARCH: 'Search',
    HISTORY: 'History'
};

const FORM_CONTEXT = 'Enquiry';

const LABEL_SIZE = 2;
const INPUT_SIZE = 5;
const MODAL_LABEL_SIZE = 4;
const MODAL_INPUT_SIZE = 8;

// Backend URL
const RETRIEVE_URL = REPORT_SAVE_AND_DOWNLOAD_LICENCE_URL;
const SEARCH_URL = REPORT_ENQUIRY_SEARCH_LICENCE_URL;
const PARAMS_URL = LICENCE_ENQUIRY_PARAMS_CODE_URL;
const GET_REPORT_HISTORY_URL = REPORT_HISTORY_LICENCE_URL;

const FORM_INITIAL_VALUES = {
    AgencyName: '',
    LicenceTypeId: '',
    StartDate: customMonthAgoString,
    EndDate: todayString,
    LicenceStatus: '',
    ReportType: 'Licence'
};

const PARAM_MODAL = {
    name: 'Parameters',
    modalHeader: 'Report parameters'
};

class LicenceReport extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            values: FORM_INITIAL_VALUES,
            tabs: SECTIONS,
            activeTab: SECTIONS.SEARCH,
            openEnquiryView: true,
            count: 0,
            agencyOptions: [],
            licenceNameOptions: [],
            licenceStatusOptions: [],
            data: [],
            searchData: [],
            modalContent: ''
        };
    }

    componentDidMount = async () => {
        const { loader, t } = this.props;
        // Start loading
        loader.start();

        this.getLicenceStatusCodes();
        const response = await fetchRequest(PARAMS_URL, getParams(), IS_DEMO);
        const { IsSuccess, Data, Messages } = response.body;

        if (IsSuccess) {
            //enquiry values
            const { LicenceAgency, LicenceTypes, LicenceStatus } = Data;

            const agencyOptions = [];
            const originalAgencyName = [];
            const licenceNameOptions = [];
            const originalLicenceName = [];
            const licenceStatusOptions = [];

            LicenceAgency.forEach(la => {
                const agencyName = t(getLangKey(LANGUAGE_KEYS.BLS_LICENCEAGENCY_AGENCY_AGENCYNAME, la.Id));
                agencyOptions.push({ label: agencyName, value: la.Id });
                originalAgencyName.push({ label: la.AgencyName, value: la.Id });
            });

            LicenceTypes.forEach(lt => {
                const licenceTypeNameTranslationKey = getLicenceTypeNameTranslationKey(lt.LicenceTypeName);
                const licenceName = t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY, licenceTypeNameTranslationKey, lt.Id));
                licenceNameOptions.push({ label: licenceName, value: lt.Id });
                originalLicenceName.push({ label: lt.LicenceTypeName, value: lt.Id });
            });

            // Format filter params into SelectList format
            arrayToLicenceStatusSelectListOptions(LicenceStatus, licenceStatusOptions, t);

            this.setState({
                // actual select list that keeps applicable agencies
                agencyOptions,
                originalAgencyName,
                // actual select list that keeps applicable licences
                licenceNameOptions,
                originalLicenceName,
                licenceStatusOptions,
                licenceAgency: LicenceAgency,
                values: {
                    ...this.state.values,
                    AgencyId: agencyOptions.length === 1 ? agencyOptions[0].value : ''
                }
            });
        } else {
            toastError(t(getLangKey(ERROR.BACKEND_ERROR_MESSAGE, Messages)), Messages);
        }

        //Populate report history table
        const historyListResponse = await fetchRequest(GET_REPORT_HISTORY_URL, getParams(), IS_DEMO);
        if (historyListResponse.body.IsSuccess) {
            this.setState({
                data: historyListResponse.body.Data
            });
        } else {
            toastError(t(ERROR.REPORT_HISTORY_LOAD_FAIL));
        }

        // End loading
        loader.done();
    };

    getLicenceStatusCodes = async() => {
        const params = {
            categoryList: 'LicenceStatus'
        }
        const statusCodesResponse = await fetchRequest(`${GET_CODES_BY_CODE_CATEGORIES}?`, getParams(params));
        const { IsSuccess, Data } = statusCodesResponse.body;
        if (IsSuccess) {
            this.setState({
                licenceStatusList: Data
            })
        }

    }

    enquirySearch = async values => {
        const { loader } = this.props;

        loader.start();

        let SEARCH_ENQUIRY_URL = SEARCH_URL;

        const { AgencyId, LicenceTypeId, LicenceStatus, StartDate, EndDate } = values;
        const AgencyIdArr = Array.isArray(AgencyId) ? AgencyId : [AgencyId];

        AgencyIdArr.forEach(val => {
            SEARCH_ENQUIRY_URL += `&AgencyId=${val}`;
        });

        if (LicenceTypeId) {
            LicenceTypeId.forEach(val => {
                SEARCH_ENQUIRY_URL += `&LicenceTypeId=${val}`;
            });
        }
        if (LicenceStatus) {
            LicenceStatus.forEach(val => {
                SEARCH_ENQUIRY_URL += `&LicenceStatus=${val}`;
            });
        }

        const queryValues = {
            StartDate,
            EndDate,
            ReportType: 'Licence'
        };

        const response = await fetchRequest(`${SEARCH_ENQUIRY_URL}&`, postParams('', queryValues), IS_DEMO);
        const { t } = this.props;
        if (response && response.success) {
            const { Data } = response.body;

            this.setState({
                success: response.success,
                searchData: Data,
                count: ((Data !== undefined) ? Data.length : 0)
            });
            loader.done();
        } else {
            loader.error(t(ERROR.ENQUIRY_PROCESS_FAIL));
        }
    };

    downloadReport = (DynaFormDocumentId, FileName) => {
        const { loader } = this.props;
        loader.start();
        window.open(`${GET_INTERNAL_FILE_URL}id=${DynaFormDocumentId}&fileName=${FileName}`);
        loader.done();
    };

    parseSearch = async ({ response }) => {
        const { loader, t } = this.props;
        // Start loading
        loader.start();
        if (response.success) {
            const { IsSuccess, Data, Messages } = response.body;
            //set state
            if (IsSuccess) {
                window.open(`${GET_INTERNAL_FILE_URL}id=${Data.currentDocumentId}&fileName=${Data.currentDocumentName}`);
                this.setState({ data: Data.historyList });
                loader.done();
            } else {
                // Error message to be loaded here
                loader.error(t(getLangKey(ERROR.BACKEND_ERROR_MESSAGE, Messages)));
            }
        } else {
            // Error message to be loaded here
            loader.error(t(ERROR.REPORT_GENERATE_FAIL));
        }
    };

    toggleTab = tab => {
        this.setState({
            activeTab: tab
        });
    };

    toggleEnquiryView = () => {
        this.setState({ openEnquiryView: !this.state.openEnquiryView });
    };

    toggleModal = (modalName, params) => {
        const { t } = this.props;
        let modalContent = null;
        const agencies = [];
        const licences = [];
        const licenceStatuses = [];
        if (params !== undefined && params !== null) {
            const paramContent = JSON.parse(params);
            paramContent.Agencies.forEach(agency => {
                let agencyName = agency;
                const originalAgencyName = this.state.originalAgencyName.find(a => a.label.toLowerCase() === agency.toLowerCase());
                if (originalAgencyName) {
                    agencyName = t(getLangKey(LANGUAGE_KEYS.BLS_LICENCEAGENCY_AGENCY_AGENCYNAME, originalAgencyName.value));
                    agencies.push(agencyName);
                }
            });
            paramContent.Licences.forEach(licence => {
                let licenceName = licence;
                const originalLicenceName = this.state.originalLicenceName.find(l => l.label.toLowerCase() === licence.toLowerCase());
                if (originalLicenceName) {
                    const licenceTypeNameTranslationKey = getLicenceTypeNameTranslationKey(licence);
                    licenceName = t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY, licenceTypeNameTranslationKey, originalLicenceName.value));
                }
                licences.push(licenceName);
            });
            paramContent.LicenceStatus.forEach(paramStatus => {
                let licenceStatus = paramStatus;
                const originalLicenceStatus = this.state.licenceStatusList.find(s => s.CodeTitle.toLowerCase() === paramStatus.toLowerCase());
                if (originalLicenceStatus) {
                    licenceStatus = t(getLangKey(LANGUAGE_KEYS.BLS_CODE_LICENCESTATUS_TITLE_KEY, originalLicenceStatus.CodeName));
                    licenceStatuses.push(licenceStatus);
                }
            });

            modalContent =
                <>
                    <Plaintext
                        label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEREPORT_FORM_FIELD_AGENCY)}
                        name='AgencyId'
                        value={agencies.toString().replace(',', ', ')}
                        labelSize={MODAL_LABEL_SIZE}
                        inputSize={MODAL_INPUT_SIZE}
                    />
                    <Plaintext
                        label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEDETAILS_CONTENT_INFORMATION_SERVICENAME)}
                        name='LicenceTypeId'
                        value={licences.toString().replace(',', ', ')}
                        labelSize={MODAL_LABEL_SIZE}
                        inputSize={MODAL_INPUT_SIZE}
                    />
                    <Plaintext
                        label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEREPORT_FORM_FIELD_ISSUEDSTARTDATE)}
                        name='StartDate'
                        value={paramContent.StartDate}
                        labelSize={MODAL_LABEL_SIZE}
                        inputSize={MODAL_INPUT_SIZE}
                    />
                    <Plaintext
                        label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEREPORT_FORM_FIELD_ISSUEDENDDATE)}
                        name='EndDate'
                        value={paramContent.EndDate}
                        labelSize={MODAL_LABEL_SIZE}
                        inputSize={MODAL_INPUT_SIZE}
                    />
                    <Plaintext
                        label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEREPORT_FORM_FIELD_STATUS)}
                        name='LicenceStatus'
                        value={licenceStatuses.toString().replace(',', ', ')}
                        labelSize={MODAL_LABEL_SIZE}
                        inputSize={MODAL_INPUT_SIZE}
                    />
                </>;
        }
        this.setState({
            modalState: (this.state.modalState !== modalName) ? modalName : undefined,
            modalContent
        });
    };

    setAgencyForTable = rowData => {
        const agencyList = [];
        const { t } = this.props;

        rowData.AgencyCodeId.forEach(agencyId =>
            agencyList.push(
                <div key={`Agency${agencyId}`} className='correspondence-agency correspondence-cell'>
                    {t(getLangKey(LANGUAGE_KEYS.BLS_LICENCEAGENCY_AGENCY_AGENCYNAME, agencyId))}
                </div>
            )
        );
        if (agencyList.length === 0) {
            agencyList.push(<div key={'Agency'}>-</div>);
        }
        return agencyList;
    };

    renderMobileCardActions = data => {
        const { RowData } = data;
        const { t } = this.props;
        return (
            <React.Fragment>
                <MobileCardActionButton
                    onClick={() => this.downloadReport(RowData.DynaFormDocumentId, RowData.FileName)}
                    icon={faDownload}
                    label={t(LANGUAGE_KEYS.BLS_INTERNALREPORTHISTORY_TABLE_BUTTON_DOWNLOAD)}
                />
                <MobileCardActionButton
                    onClick={() => this.toggleModal(PARAM_MODAL.name, RowData.Parameters)}
                    icon={faEye}
                    label={t(LANGUAGE_KEYS.BLS_INTERNALREPORTHISTORY_TABLE_BUTTON_VIEWPARAMETER)}
                />
            </React.Fragment>
        );
    };

    setServiceName = rowData => {
        const { t } = this.props;
        const licenceTypeNameTranslationKey = getLicenceTypeNameTranslationKey(rowData.ServiceName);
        const licenceName = t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY, licenceTypeNameTranslationKey, rowData.LicenceTypeId));

        return <div>{licenceName}</div>;
    };
    getServiceName = rowData => {
        const { t } = this.props;
        const licenceTypeNameTranslationKey = getLicenceTypeNameTranslationKey(rowData.ServiceName);
        return t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY, licenceTypeNameTranslationKey, rowData.LicenceTypeId));
    };

    setAgencyName = rowData => {
        const { t } = this.props;
        const agencyName = t(getLangKey(LANGUAGE_KEYS.BLS_LICENCEAGENCY_AGENCY_AGENCYNAME, rowData.LicenceAgencyId));

        return <div>{agencyName}</div>;
    };
    getAgencyName = rowData => {
        const { t } = this.props;
        return t(getLangKey(LANGUAGE_KEYS.BLS_LICENCEAGENCY_AGENCY_AGENCYNAME, rowData.LicenceAgencyId));
    };

    renderGrid = () => {
        const { t } = this.props;

        if (this.state.success) {
            return (<DataTable
                pageSize='5'
                minFilterChars='2'
                data={this.state.searchData}
                noResultsMessage={t(LANGUAGE_KEYS.BLS_INTERNALREPORTSEARCH_MESSAGE_PLACEHOLDER_NOSERVICESMATCHING)}
                components={getCustomComponents()}
                columns={
                    {
                        RunningNumber: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEREPORT_TABLE_TITLE_NUMBER), width: '5%', DBkey: 'Id' },
                        NumberHeader: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEREPORT_TABLE_TITLE_SERVICENO), width: '10%', DBkey: 'ServiceNumber' },
                        ServiceName: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEREPORT_TABLE_TITLE_SERVICENAME), width: '18%', DBkey: 'ServiceName', setContent: this.setServiceName, getTranslatedString: this.getServiceName },
                        AgencyName: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEREPORT_TABLE_TITLE_AGENCY), width: '20%', DBkey: 'AgencyName', setContent: this.setAgencyName, getTranslatedString: this.getAgencyName },
                        LicenceStatus: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEREPORT_TABLE_TITLE_STATUS), width: '8%', DBkey: 'Status' },
                        IssuedDate: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEREPORT_TABLE_TITLE_ISSUEDDATE), isDate: true, width: '12%', DBkey: 'IssuedDate' },
                        ExpiryDate: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEREPORT_TABLE_TITLE_EXPIRYDATE), isDate: true, width: '12%', DBkey: 'ExpiryDate' }
                    }
                }
            />);
        } else {
            return <></>;
        }
    };

    render() {
        const { tabs, activeTab } = this.state;
        const { t } = this.props;

        const FORM_VALIDATIONS = Yup.object().shape({
            AgencyId: Yup.array(),
            StartDate: Yup.date().required((ERROR.LABEL) + t(ERROR.REQUIRED)),
            EndDate: Yup.date().required((ERROR.LABEL) + t(ERROR.REQUIRED)).when(
                'StartDate',
                {
                    is: v => v != null,
                    then: s => s.min(Yup.ref('StartDate'), t(ERROR.START_DATE_BEFORE_END))
                }
            ),
            LicenceStatus: Yup.array()
        });
        
        return (
            <div className='body-content'>
                <Nav tabs className='main-tab tab-nav-tabs'>
                    <NavItem key={0} className='tab-nav-item'>
                        <NavLink
                            className={'tab-header tab-nav-link'}
                            active={(activeTab === tabs.SEARCH)}
                            id='searchTab'
                            onClick={() => this.toggleTab(tabs.SEARCH)}
                        >
                            {t(LANGUAGE_KEYS.BLS_INTERNALREPORTSEARCH_NAVIGATION_BUTTON_SEARCHSERVICES)}
                        </NavLink>
                    </NavItem>
                    <NavItem key={1} className='tab-nav-item'>
                        <NavLink
                            className={'tab-header tab-nav-link'}
                            id='licenceTab'
                            active={(activeTab === tabs.HISTORY)}
                            onClick={() => this.toggleTab(tabs.HISTORY)}
                        >
                            {t(LANGUAGE_KEYS.BLS_INTERNALREPORTSEARCH_NAVIGATION_BUTTON_REPORTHISTORY)}
                        </NavLink>
                    </NavItem>
                </Nav>

                {activeTab === tabs.SEARCH &&
                    <>
                        <Layout type={LAYOUT_TYPE.FORM}>
                            <div className='grid-container report-generation'>
                                <SMARTForm
                                    formContext={FORM_CONTEXT}
                                    formValues={this.state.values}
                                    validationSchema={FORM_VALIDATIONS}
                                    validateOnSubmit={true}
                                    serverURL={RETRIEVE_URL}
                                    isDummy={IS_DEMO}
                                    submitCallback={this.parseSearch}
                                >
                                    {({ values, errors, onChangeField, submitForm }) => (
                                        <Container className='full-width'>
                                            <Row>
                                                <Col className='col-gutter'>
                                                    <div onClick={this.toggleEnquiryView} className='chevron'>
                                                        <FontAwesomeIcon icon={this.state.openEnquiryView ? faChevronUp : faChevronDown} />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Collapse className='collapse-enquiry' isOpen={this.state.openEnquiryView}>
                                                    <Card className='enquiry-container'>
                                                        <CardBody className='enquiry-card-body'>
                                                            {this.state.agencyOptions.length > 1 &&
                                                                <SelectList
                                                                    name='AgencyId'
                                                                    value={values.AgencyId}
                                                                    options={this.state.agencyOptions}
                                                                    onChangeField={onChangeField}
                                                                    placeholder={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEREPORT_FORM_FIELD_AGENCY)}
                                                                    isMulti={true}
                                                                    inputSize={INPUT_SIZE}
                                                                    label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEREPORT_FORM_FIELD_AGENCY)}
                                                                    labelSize={LABEL_SIZE}
                                                                    error={errors.AgencyId}
                                                                    isDisabled={this.state.agencyOptions.length === 1}
                                                                />
                                                            }
                                                            <SelectList
                                                                name='LicenceTypeId'
                                                                value={values.LicenceTypeId}
                                                                options={this.state.licenceNameOptions}
                                                                onChangeField={onChangeField}
                                                                placeholder={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEREPORT_FORM_FIELD_SERVICENAME)}
                                                                isMulti={true}
                                                                isClearable={true}
                                                                inputSize={INPUT_SIZE}
                                                                label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEREPORT_FORM_FIELD_SERVICENAME)}
                                                                labelSize={LABEL_SIZE}
                                                                error={errors.LicenceTypeId}
                                                            />
                                                            <DateInput
                                                                name='StartDate'
                                                                value={values.StartDate}
                                                                placeholder={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEREPORT_FORM_FIELD_ISSUEDSTARTDATE)}
                                                                onChangeField={onChangeField}
                                                                date={true}
                                                                time={false}
                                                                max={new Date()}
                                                                format={DATE_DISPLAY_FORMAT.DATE}
                                                                inputSize={INPUT_SIZE}
                                                                label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEREPORT_FORM_FIELD_ISSUEDSTARTDATE)}
                                                                labelSize={LABEL_SIZE}
                                                                error={errors.StartDate}
                                                                clearFieldEnabled={false}
                                                                disableKeyboardInput={true}
                                                            />
                                                            <DateInput
                                                                name='EndDate'
                                                                value={values.EndDate}
                                                                placeholder={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEREPORT_FORM_FIELD_ISSUEDENDDATE)}
                                                                onChangeField={onChangeField}
                                                                date={true}
                                                                time={false}
                                                                max={new Date()}
                                                                format={DATE_DISPLAY_FORMAT.DATE}
                                                                inputSize={INPUT_SIZE}
                                                                label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEREPORT_FORM_FIELD_ISSUEDENDDATE)}
                                                                labelSize={LABEL_SIZE}
                                                                error={errors.EndDate}
                                                                clearFieldEnabled={false}
                                                                disableKeyboardInput={true}
                                                            />
                                                            <SelectList
                                                                name='LicenceStatus'
                                                                value={values.LicenceStatus}
                                                                options={this.state.licenceStatusOptions}
                                                                onChangeField={onChangeField}
                                                                placeholder={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEREPORT_FORM_FIELD_STATUS)}
                                                                isMulti={true}
                                                                isClearable={true}
                                                                inputSize={INPUT_SIZE}
                                                                label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEREPORT_FORM_FIELD_STATUS)}
                                                                labelSize={LABEL_SIZE}
                                                                error={errors.LicenceStatus}
                                                            />
                                                            <Row className='button-row'>
                                                                <Button
                                                                    color='neutral'
                                                                    size='sm'
                                                                    type='button'
                                                                    onClick={() => this.enquirySearch(values)}>
                                                                    {t(LANGUAGE_KEYS.BLS_INTERNALREPORTSEARCH_FORM_BUTTON_SEARCHLICENCES)}
                                                                </Button>
                                                                <Button
                                                                    color='forward'
                                                                    size='sm'
                                                                    type='button'
                                                                    onClick={submitForm}>
                                                                    {t(LANGUAGE_KEYS.BLS_INTERNALREPORTSEARCH_FORM_BUTTON_DOWNLOADREPORT)}
                                                                </Button>
                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                </Collapse>
                                            </Row>
                                            <Row>
                                                <Col className='col-gutter record-count'>
                                                    {t(LANGUAGE_KEYS.BLS_INTERNALREPORTSEARCH_FORM_TITLE_RECORDS)} ({this.state.count})
                                                </Col>
                                            </Row>
                                            <Row>
                                                <div className='enquiry-grid-container'>
                                                    {this.renderGrid()}
                                                </div>
                                            </Row>
                                        </Container>
                                    )}
                                </SMARTForm>
                            </div>
                        </Layout>
                    </>
                }
                {
                    activeTab === tabs.HISTORY
                    &&
                    <div className='grid-container report-generation'>
                        <DataTable
                            pageSize='5'
                            minFilterChars='2'
                            data={this.state.data}
                            noResultsMessage={t(LANGUAGE_KEYS.BLS_INTERNALREPORTHISTORY_MESSAGE_PLACEHOLDER_NOREPORTRECORDS)}
                            components={getCustomComponents()}
                            columns={
                                {
                                    RunningNumber: { title: t(LANGUAGE_KEYS.BLS_INTERNALREPORTHISTORY_TABLE_TITLE_NUMBER), width: '5%', DBkey: 'Id' },
                                    AgencyName: {
                                        title: t(LANGUAGE_KEYS.BLS_INTERNALREPORTHISTORY_TABLE_TITLE_AGENCY), width: '30', DBkey: 'AgencyName',
                                        setContent: this.setAgencyForTable
                                    },
                                    RequestedDate: { title: t(LANGUAGE_KEYS.BLS_INTERNALREPORTHISTORY_TABLE_TITLE_REQUESTEDDATE), width: '20%', DBkey: 'CreatedDate', isDate: true },
                                    RequestedBy: { title: t(LANGUAGE_KEYS.BLS_INTERNALREPORTHISTORY_TABLE_TITLE_REQUESTEDBY), width: '20%', DBkey: 'CreatedBy' },
                                    Actions: { title: t(LANGUAGE_KEYS.BLS_INTERNALREPORTHISTORY_TABLE_TITLE_ACTIONS), width: '25%', DBkey: 'Actions' }

                                }
                            }
                            renderActions={({ RowData }) => (
                                <React.Fragment>
                                    <ActionButton tooltip={t(LANGUAGE_KEYS.BLS_INTERNALREPORTHISTORY_CONTENT_BUTTON_DOWNLOADREPORT)}
                                        color='forward'
                                        onClick={() => this.downloadReport(RowData.DynaFormDocumentId, RowData.FileName)}
                                    >
                                        {t(LANGUAGE_KEYS.BLS_INTERNALREPORTHISTORY_TABLE_BUTTON_DOWNLOAD)}
                                    </ActionButton>
                                    <ActionButton tooltip={t(LANGUAGE_KEYS.BLS_INTERNALREPORTHISTORY_CONTENT_BUTTON_VIEWSEARCHPARAM)}
                                        color='neutral'
                                        onClick={() => this.toggleModal(PARAM_MODAL.name, RowData.Parameters)}
                                    >
                                        {t(LANGUAGE_KEYS.BLS_INTERNALREPORTHISTORY_TABLE_BUTTON_VIEWPARAMETER)}
                                    </ActionButton>
                                </React.Fragment>
                            )}
                            renderMobileCardActions={this.renderMobileCardActions}
                        />
                        <ModalConfirm
                            isOpen={(this.state.modalState === PARAM_MODAL.name)}
                            contentHeader={t(LANGUAGE_KEYS.BLS_INTERNALREPORTHISTORY_CONTENT_MODAL_REPORTPARAMETERS)}
                            contentBody={this.state.modalContent}
                            cancelText={t(LANGUAGE_KEYS.BLS_INTERNALREPORTHISTORY_CONTENT_BUTTON_CLOSE)}
                            cancelCallback={() => this.toggleModal(PARAM_MODAL.name)}
                        />
                    </div>
                }
            </div>
        );
    }
}

export default withTranslation()(withSMARTWrapper(LicenceReport));
