import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import Section from '../../Section';
import SectionWrapper from '../../SectionWrapper';
import { DATE_TIME_FORMAT } from '../../Shared/Constants';
import { getLangKey, LANGUAGE_KEYS } from '../../Shared/Constants/LanguageKeys';
import { DataTable } from '../../Shared/DataTable';
import { getCustomComponents } from '../../Shared/DataTable/BaseTable.js';
import { SMARTForm } from '../../Shared/Forms';

const FORM_CONTEXT = 'PreviewService'; 

const setEffectiveFromDate = (rowData) => {
    if (Date.parse(rowData.EffectiveFrom) > Date.parse(DATE_TIME_FORMAT.DEFAULT_MIN_DATE)) {
        return <>{rowData.EffectiveFrom}</>;
    } else {
        return <>{DATE_TIME_FORMAT.DEFAULT_EMPTY_DATE}</>;
    }
};

const setEffectiveToDate = (rowData) => {
    if (Date.parse(rowData.EffectiveTo) > Date.parse(DATE_TIME_FORMAT.DEFAULT_MIN_DATE)) {
        return <>{rowData.EffectiveTo}</>;
    } else {
        return <>{DATE_TIME_FORMAT.DEFAULT_EMPTY_DATE}</>;
    }
};

export default function LiveConfigurationDetailModal(props) {
    const { t } = useTranslation();

    const setStatusTranslatedContent = (rowData) => {
        return <>{t(getLangKey(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_VERSIONSTATUS_TITLE_KEY, rowData.Status))}</>;
    };

    const SECTION_NAMES = {
        LICENCECONFIGURATION: t(LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_CONTENT_MENU_SERVICECONFIGURATION),
        LICENCEAPPCONFIGURATION: t(LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_CONTENT_MENU_SERVICEAPPCONFIGURATION)
    };

    return (
        <Modal isOpen={props.isOpen} className={'modal-content ' + props.modalClassName}>
            <ModalHeader className={props.modalHeaderClassName} >{t(LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_MODAL_TITLE_CONFIGURATIONHEADER)}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        {t(LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_TABLE_TITLE_AGENCY_NAME)}
                    </Col>
                    <Col>
                        {t(LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_TABLE_TITLE_SERVICE_NAME)}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h4>{props.agencyName}</h4>
                    </Col>
                    <Col>
                        <h4>{props.licenceName}</h4>
                    </Col>
                </Row>
                <SMARTForm
                    titleClassName='theme-new'
                    formContext={FORM_CONTEXT}
                    submitCallback={null}
                    defaultSection={SECTION_NAMES.LICENCECONFIGURATION}
                    sectionNames={SECTION_NAMES}
                >
                    {({ sectionState, toggleSection }) => (
                        <React.Fragment>
                            <Row>
                                <Col className="body-content">
                                    <SectionWrapper
                                        type='1'
                                        tabs={SECTION_NAMES}
                                        sectionState={sectionState}
                                        toggleSection={toggleSection}
                                        toggleFlex={true}
                                    >
                                        <Section
                                            type={1}
                                            sectionState={sectionState}
                                            sectionName={SECTION_NAMES.LICENCECONFIGURATION}
                                            toggleSection={toggleSection}
                                        >
                                            <DataTable
                                                pageSize="5"
                                                minFilterChars='2'
                                                data={props.lcData}
                                                noResultsMessage="You do not have any service configurations to publish"
                                                components={getCustomComponents()}
                                                columns={
                                                    {
                                                        VersionNo: { title: t(LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_TABLE_TITLE_VERSION_NO), width: '17%', DBkey: 'Version' },
                                                        EffectiveFrom: { title: 'Effective From', width: '17%', DBkey: 'EffectiveFrom', isDate: true, setContent: setEffectiveFromDate },
                                                        EffectiveTo: { title: 'Effective To', width: '17%', DBkey: 'EffectiveTo', isDate: true, setContent: setEffectiveToDate },
                                                        Status: { title: 'Status', width: '17%', DBkey: 'Status', setContent: setStatusTranslatedContent },
                                                        CreatedBy: { title: 'Created By', width: '17%', DBkey: 'CreatedBy' },
                                                        CreatedDate: { title: 'Created Date', width: '17%', DBkey: 'CreatedDate' }
                                                    }
                                                }
                                            />
                                        </Section>
                                        <Section
                                            type={1}
                                            sectionState={sectionState}
                                            sectionName={SECTION_NAMES.LICENCEAPPCONFIGURATION}
                                            toggleSection={toggleSection}
                                        >
                                            <DataTable
                                                pageSize="5"
                                                minFilterChars='2'
                                                data={props.lacData}
                                                noResultsMessage="You do not have any service application configurations to publish"
                                                components={getCustomComponents()}
                                                columns={
                                                    {
                                                        VersionNo: { title: t(LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_TABLE_TITLE_VERSION_NO), width: '14%', DBkey: 'Version' },
                                                        ApplicationType: { title: t(LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_TABLE_TITLE_APPLICATION_TYPE), width: '14%', DBkey: 'ApplicationType' },
                                                        EffectiveFrom: { title: 'Effective From', width: '14%', DBkey: 'EffectiveFrom', isDate: true, setContent: setEffectiveFromDate },
                                                        EffectiveTo: { title: 'Effective To', width: '14%', DBkey: 'EffectiveTo', isDate: true, setContent: setEffectiveToDate },
                                                        Status: { title: t(LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_TABLE_TITLE_STATUS), width: '14%', DBkey: 'Status', setContent: setStatusTranslatedContent },
                                                        CreatedBy: { title: t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_TABLE_INFORMATION_CREATEDBY), width: '14%', DBkey: 'CreatedBy' },
                                                        CreatedDate: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_TABLE_TITLE_CREATEDDATE), width: '14%', DBkey: 'CreatedDate' }
                                                    }
                                                }
                                            />
                                        </Section>
                                    </SectionWrapper>
                                </Col>
                            </Row>
                        </React.Fragment>
                    )}
                </SMARTForm>
            </ModalBody>
            <ModalFooter>
                {props.cancelCallback && <Button color="backward" size="sm" onClick={props.cancelCallback}>{props.cancelText}</Button>}
            </ModalFooter>
        </Modal>
    );
}
