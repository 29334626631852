import React from 'react';
import { LAYOUT_TYPE } from '../../../../../../App/AppSettings';
import Section from '../../../../../../Section';
import CommonSpfLiquor1To4LicenceOverview from './CommonSpfLiquor1To4LicenceOverview';
import CommonSpfLiquor1To4LicenceDetails from './CommonSpfLiquor1To4LicenceDetails';

class SpfLiquor1To4Renewal extends React.Component {
    render() {
        const { sectionState, toggleSection, sectionName, licenceInformation, licenceDetails, generalInformation } = this.props;

        return (
            licenceInformation && generalInformation && licenceDetails ?
            (<Section
                type={LAYOUT_TYPE.FORM_CONTENT}
                sectionState={sectionState}
                sectionName={sectionName}
                toggleSection={toggleSection}
            >
                <CommonSpfLiquor1To4LicenceOverview
                    licenceDetails={licenceDetails}
                    generalInformation={generalInformation}
                />
                <CommonSpfLiquor1To4LicenceDetails licenceInformation={licenceInformation} />
            </Section>) : null
        );
    }
}

export default SpfLiquor1To4Renewal;