import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Badge, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';
import { ActionButton } from '../../Shared/DataTable';
import { withSMARTWrapper } from '../../Shared/Forms';
import { DEFAULT_TEXT, DEFAULT_NAMESPACE } from '../../Shared/Constants/LanguageKeys.js';
import './LanguageList.css';

const STATUS = {
    PUBLISHED: "Published",
    DRAFTED: "Drafted",
    DELETED: "Deleted"
}

class KeyCard extends React.Component {
    copyText = (textToCopy) => {
        navigator.clipboard.writeText(textToCopy);
    }

    render() {
        const { keyData } = this.props;

        return (
            <>
                <Card className="card-container">
                    <CardHeader className="key-card-header" >
                        <Row>
                            <Col xs="10">
                                <Badge color={`${keyData.Status === STATUS.PUBLISHED ? "success" : keyData.Status === STATUS.DRAFTED ? "secondary" : "danger"}`}>{keyData.Status}</Badge>
                            </Col>

                            <Col xs="2">
                                <div className="key-card-action-button action-button-group">
                                    {this.props.children}
                                </div>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody className="key-card-body">
                        <Row className="key-row">
                            <Col xs="12">
                                <div className="key-name-space">
                                    <b>
                                        {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_FORM_TITLE_FULLKEY, DEFAULT_TEXT.FULL_KEY)}:
                                    </b> {keyData.FullKey}
                                    <ActionButton className="copy-icon" tooltip="Copy" color="link" onClick={() => this.copyText(keyData.FullKey)}>
                                        <FontAwesomeIcon fixedWidth icon={faCopy} />
                                    </ActionButton>
                                </div>
                            </Col>
                        </Row>
                        <Row className="key-row">
                            <Col xs="6"><b>{this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_FORM_TITLE_DEFAULTLANG, DEFAULT_TEXT.DEFAULT_LANGUAGE)} - {keyData.DefaultLanguageTitle} ({keyData.DefaultLanguageValue}) </b><br />
                                {keyData.DefaultIsDeleted ? <strike>{ReactHtmlParser(keyData.DefaultValue)}</strike> : ReactHtmlParser(keyData.DefaultValue)}
                            </Col>
                            <Col xs="6" className="card-translated-value"><b> {keyData.LanguageLabel} </b> <br />
                                {keyData.IsDeleted ? <strike>{ReactHtmlParser(keyData.Value)}</strike> : ReactHtmlParser(keyData.Value)}
                            </Col>
                        </Row>
                        <Row>
                            <Col> <div className="key-card-action-button">
                                {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_FORM_TITLE_LASTEDITEDBY, DEFAULT_TEXT.LAST_EDITED_BY)}: {keyData.UpdatedBy} {keyData.UpdatedDateFormatted}</div></Col>
                        </Row>
                    </CardBody>
                </Card>

            </>
        );
    }
}

export default withSMARTWrapper(KeyCard);