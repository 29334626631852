import { faEdit } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { withTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { IS_DEMO, LAYOUT_TYPE, PAYMENT_SUMMARY_PATH, PENDING_PAYMENT_INBOX_LIST_URL } from '../../App/AppSettings';
import Layout from '../../Layout';
import { fetchRequest, getParams, navigateTo } from '../../Shared/Actions';
import { LANGUAGE_KEYS } from '../../Shared/Constants/LanguageKeys';
import { DataTable, MobileCardActionButton } from '../../Shared/DataTable';
import { getCustomComponents } from '../../Shared/DataTable/BaseTable.js';
import { withSMARTWrapper } from '../../Shared/Forms';
import { getLangKey, getLicenceTypeNameTranslationKey } from '../DisplayComponents/DisplayUtils';
import './Inbox.css';

const RETRIEVE_URL = PENDING_PAYMENT_INBOX_LIST_URL;
const PAYMENT_SUMMARY_URL = PAYMENT_SUMMARY_PATH;

class PaymentInbox extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            count: 0,
        };
    }

    // LIFECYCLE METHODS - START
    componentDidMount = async () => {
        const { loader } = this.props;

        loader.start();

        const response = await fetchRequest(RETRIEVE_URL, getParams(), IS_DEMO);
        const { Data } = response.body;

        this.setState({
            success: response.success,
            data: Data,
            count: ((Data !== undefined) ? Data.length : 0),
        });

        loader.done();

    }
    // LIFECYCLE METHODS - END

    // CLASS METHODS
    setContent = (rowData) => {
        const { ReferenceNo, SubmissionNumber, FormName, InternalDescription, ApplicationType, LicenceTypeId, ApplicationTypeCodeName } = rowData;

        return <Link to={
            {
                pathname: PAYMENT_SUMMARY_URL,
                state: {
                    Data: {
                        submissionNumber: SubmissionNumber,
                        referenceNumber: ReferenceNo,
                        formName: FormName,
                        status: InternalDescription,
                        applicationType: ApplicationType, 
                        licenceTypeId: LicenceTypeId,
                        applicationTypeCodeName: ApplicationTypeCodeName
                    }
                }
            }
        }>{rowData.ReferenceNo}</Link>
    }

    setLicenceNameTranslatedContent = (rowData) => {
        const { t } = this.props;
        const licenceTypeNameTranslationKey = getLicenceTypeNameTranslationKey(rowData.FormName);
        return (
            <>{t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY, licenceTypeNameTranslationKey, rowData.LicenceTypeId))}</>
        );
    }

    getTranslatedString = (rowData) => {
        const { t } = this.props;
        const licenceTypeNameTranslationKey = getLicenceTypeNameTranslationKey(rowData.FormName);
        return t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY, licenceTypeNameTranslationKey, rowData.LicenceTypeId));
    }

    renderMobileCardActions = (data) => {
        const { ReferenceNo, SubmissionNumber, FormName, InternalDescription, ApplicationType, ApplicationTypeCodeName } = data;
        return (
            <React.Fragment>
                <MobileCardActionButton
                    onClick={() => navigateTo(PAYMENT_SUMMARY_URL, {
                        Data: {
                            submissionNumber: SubmissionNumber,
                            referenceNumber: ReferenceNo,
                            formName: FormName,
                            status: InternalDescription,
                            applicationType: ApplicationType,
                            applicationTypeCodeName: ApplicationTypeCodeName
                        }
                    })}
                    icon={faEdit}
                    label='Update'
                />
            </React.Fragment>
        )
    }

    setAppTypeTranslatedContent = (rowData) => {
        const { t } = this.props;
        return (
            <>{t(getLangKey(LANGUAGE_KEYS.BLS_CODE_LICENCEAPPLICATIONTYPE_TITLE_KEY, rowData.ApplicationTypeCodeName))}</>
        );
    }

    getAppTypeTranslatedString = (rowData) => {
        const { t } = this.props;
        return t(getLangKey(LANGUAGE_KEYS.BLS_CODE_LICENCEAPPLICATIONTYPE_TITLE_KEY, rowData.ApplicationTypeCodeName));
    }

    renderGrid = () => {
        const { t } = this.props;
        if (this.state.success) {
            return (
                <React.Fragment>
                    <Row>
                        <Col>
                            <DataTable
                                pageSize="5"
                                minFilterChars='2'
                                data={this.state.data}
                                noResultsMessage={t(LANGUAGE_KEYS.BLS_INTERNALPAYMENTINBOX_MESSAGE_INFORMATION_NOTASKS)}
                                components={getCustomComponents()}
                                columns={
                                    {
                                        RunningNumber: { title: t(LANGUAGE_KEYS.BLS_INTERNALPAYMENTINBOX_TABLE_TITLE_NUMBER), width: '5%', DBkey: 'Id' },
                                        IsCheckedOut: { title: t(LANGUAGE_KEYS.BLS_INTERNALPAYMENTINBOX_TABLE_TITLE_LOCKED), width: '8%', DBkey: 'IsCheckedOut' },
                                        ReferenceNo: { title: t(LANGUAGE_KEYS.BLS_INTERNALPAYMENTINBOX_TABLE_TITLE_REFNO), width: '10%', DBkey: 'ReferenceNo', setContent: this.setContent },
                                        FormName: { title: t(LANGUAGE_KEYS.BLS_INTERNALPAYMENTINBOX_TABLE_TITLE_SERVICENAME), width: '25%', DBkey: 'FormName', setContent: this.setLicenceNameTranslatedContent, getTranslatedString: this.getTranslatedString },
                                        FormType: { title: 'Form Type', visible: false, DBkey: 'FormType' },
                                        ApplicationType: { title: t(LANGUAGE_KEYS.BLS_INTERNALPAYMENTINBOX_TABLE_TITLE_APPLICATIONTYPE), width: '10%', DBkey: 'ApplicationType', setContent: this.setAppTypeTranslatedContent, getTranslatedString: this.getAppTypeTranslatedString },
                                        SubmissionDate: { title: t(LANGUAGE_KEYS.BLS_INTERNALPAYMENTINBOX_TABLE_TITLE_SUBMISSIONDATE), width: '15%', DBkey: 'RequestDate', isDate: true },
                                        LastUpdatedDate: { title: t(LANGUAGE_KEYS.BLS_INTERNALPAYMENTINBOX_TABLE_TITLE_LASTUPDATED), width: '18%', DBkey: 'LastUpdatedDate', isDate: true },
                                        SLADate: { title: t(LANGUAGE_KEYS.BLS_INTERNALPAYMENTINBOX_TABLE_TITLE_SLADATE), width: '15%', DBkey: 'ExpectedResolutionDate', DependantKey: "SLAStatus", isDate: true },
                                        SLAStatus: { title: t(LANGUAGE_KEYS.BLS_INTERNALPAYMENTINBOX_TABLE_TITLE_STATUS), width: '0%', visible: false, DBkey: 'SLAStatus' },
                                        StatusBadge: { title: t(LANGUAGE_KEYS.BLS_INTERNALPAYMENTINBOX_TABLE_TITLE_STATUS), width: '8%', DBkey: 'InternalDescription' }
                                    }
                                }
                                renderMobileCardActions={this.renderMobileCardActions}
                            />
                        </Col>
                    </Row>
                </React.Fragment>
            );
        }
    }

    render() {
        const { t } = this.props;
        return (
            <React.Fragment>
                <Layout type={LAYOUT_TYPE.FORM} title={t(LANGUAGE_KEYS.BLS_INTERNALPAYMENTINBOX_CONTENT_TITLE_PAYMENTINBOX)}>
                    <div className="grid-container">
                        <div className="inbox-grid-container">
                            {this.renderGrid()}
                        </div>
                    </div>
                </Layout>
            </React.Fragment>
        );
    }
}

export default withTranslation()(withSMARTWrapper(PaymentInbox));

