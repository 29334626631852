import React from "react";
import { UncontrolledTooltip } from "reactstrap";

export const OverlayTrigger = ({ children, placement, target, innerText }) => {
    return <div>
        <UncontrolledTooltip placement={placement} target={target}>
            {innerText}
        </UncontrolledTooltip>
        {children}
    </div>
}