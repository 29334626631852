import PropTypes from 'prop-types';
import React from 'react';
import { Label } from 'reactstrap';
import { withBasePlaintextControl } from './BasePlaintextControl';
import { withTranslation } from 'react-i18next';
import { LANGUAGE_KEYS } from '../Constants/LanguageKeys';

class Plaintext extends React.Component {

    displayTextOrLink = (name, value, isFile, isSupportingDocument, unitOfMeasurement) => {

        const { t } = this.props;
        const displayedValue = (value) ? (`${(value.name) ? (value.name) : ((value.Filename) ? value.Filename : value)} ${unitOfMeasurement}`) : ('-');

        if (isFile && value) {
            if (value.Filename !== undefined) {
                return (<a href={this.props.getFileURL + "id=" + value.Id + "&fileName=" + value.Filename} className="download-link-details">Download File ({value.ContentLength}KB/{value.ContentType.toUpperCase()})</a>);
            }
            else {
                return (
                    <Label
                        className={"plaintext form-control-plaintext" + (this.props.className ? " " + this.props.className : "")}
                        name={name}
                    >
                        {(this.props.isUpper) ? displayedValue.toUpperCase() : displayedValue}
                    </Label>

                );
            }
        }
        if (isSupportingDocument && value && value.Filename && value.FileUploadField) {
            return (<a href={this.props.getFileURL + "id=" + value.Id + "&fileName=" + value.Filename} className="form-control-plaintext supporting-document" target="_blank" rel="noopener noreferrer"> {value.FileUploadField}</a>);
        }
        if (this.props.isCustomDocument) {
            return <div className="application-link" onClick={() => this.props.getCustomDocument(value)}>{t(LANGUAGE_KEYS.BLS_INTERNALLICENCEDETAILS_CONTENT_LINK_DOWNLOADLICENCE)}</div>;
        }
        else {
            return (
                <Label
                    className={"plaintext form-control-plaintext" + (this.props.className ? " " + this.props.className : "")}
                    name={name}
                >
                    {(this.props.isUpper) ? displayedValue.toUpperCase() : displayedValue}
                </Label>
            );
        }
    };

    render() {
        const { name, value, isFile, isSupportingDocument, unitOfMeasurement } = this.props;

        return (
            this.displayTextOrLink(name, value, isFile, isSupportingDocument, unitOfMeasurement)
        );
    }
}

// PropTypes: For prop validation
Plaintext.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    unitOfMeasurement: PropTypes.string,
    isFile: PropTypes.bool,
    isSupportingDocument: PropTypes.bool,
    isLicencePdf: PropTypes.bool,
    isUpper: PropTypes.bool
};

// PropTypes: Defaulting value for optional props
Plaintext.defaultProps = {
    unitOfMeasurement: "",
    isFile: false,
    isSupportingDocument: false,
    isLicencePdf: false,
    isUpper: false
};


export default withTranslation()(withBasePlaintextControl(Plaintext));
