import React from 'react';
import Footer from '../Footer';
import NavHeader from './NavHeader';

class NavLayout1D extends React.Component {

    render() {
        return (
            <React.Fragment>
                <div className="layout-1d">
                    <div className="layout-1d-container">
                        <NavHeader navbarLayout={this.props.navbarLayout}/>   
                        {this.props.children}
                        <Footer />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}


export default NavLayout1D;