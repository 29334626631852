import PropTypes from 'prop-types';
import React from 'react';
import { Badge, Col, Row } from 'reactstrap';
import { getBadgeColor } from '../Shared/Forms/FormUtils.js'

class TitleChildren extends React.Component {

    renderChildrenItem = (items) => {
        let cols = [];
    
        for (var item in items) {
            if (items.hasOwnProperty(item)) {
                switch (items[item].type) {
                    case "text":
                    default:
                        cols.push(
                            <Row className='title-children-item' key={item}>
                                <Col>
                                    <span className='title-children-item-label'>{items[item].label}:&nbsp;</span>
                                    <span className='title-children-item-value'>{items[item].value}</span>
                                </Col>
                            </Row>
                        );
                        break;
    
                    case "textMobileShow":
                        cols.push(
                            <Row className='title-children-item' key={item}>
                                <Col>
                                    <span className='title-children-item-label title-show-mobile'>{items[item].label}:&nbsp;</span>
                                    <span className='title-children-item-value'>{items[item].value}</span>
                                </Col>
                            </Row>
                        );
                        break;

                    case "badge":
                        cols.push(
                            <Row className='title-children-item' key={item}>
                                <Col> 
                                    <span className='title-children-item-label'>{items[item].label}:&nbsp;</span>
                                    <Badge color={getBadgeColor(items[item].value)}>{items[item].value}</Badge>
                                </Col>
                            </Row>
                        );
                        break;			
                }
            }      
        }
        return cols;
    }
    	
    render() {
        return (
            <React.Fragment>
			<Row className={'title-children ' + this.props.className}>
                {this.props.items && 
                (<Col>
                    {this.renderChildrenItem(this.props.items)}
                </Col>)}
                {this.props.children && 
                (<Col>
                    {this.props.children}
                </Col>)}

			</Row>
		</React.Fragment>
        );
    }
}

//PropTypes: For prop validation
TitleChildren.propTypes = {
    className: PropTypes.string,
};

// PropTypes: Defaulting value for optional props
TitleChildren.defaultProps = {
    className: '',
};


export default TitleChildren;