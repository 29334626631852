import React from 'react';
import SubSection from '../../../../../../Section/SubSection';
import { Plaintext } from '../../../../../../Shared/Forms';

const IS_UPPER = true;

class CommonSpfLiquor1To4Details extends React.Component {
    render() {
        const { licenceInformation } = this.props;

        return (
            licenceInformation &&
            <>
                <SubSection orientation="left" title="Additional applicant particular">
                    <Plaintext
                        label={"Designation"}
                        name={"AdditionalapplicantparticularDesignation"}
                        value={licenceInformation.AdditionalapplicantparticularDesignation}
                        isUpper={IS_UPPER}
                    />
                </SubSection>
                <SubSection orientation="left" title="Additional company details">
                    <Plaintext
                        label={"Type of company/business/organisation"}
                        name={"AdditionalcompanydetailsTypeofcompanybusinessorganisation"}
                        value={licenceInformation.AdditionalcompanydetailsTypeofcompanybusinessorganisation}
                        isUpper={IS_UPPER}
                    />
                </SubSection>
                <SubSection orientation="left" title="Type of licence applied for">
                    <Plaintext
                        label={"Licence type"}
                        name={"TypeoflicenceappliedforLicencetype"}
                        value={licenceInformation.TypeoflicenceappliedforLicencetype}
                        isUpper={IS_UPPER}
                    />
                    <Plaintext
                        label={"Main Business Activity"}
                        name={"TypeoflicenceappliedforMainBusinessActivity"}
                        value={licenceInformation.TypeoflicenceappliedforMainBusinessActivity}
                        isUpper={IS_UPPER}
                    />
                    <Plaintext
                        label={"Commencement date"}
                        name={"TypeoflicenceappliedforCommencementdate"}
                        value={licenceInformation.TypeoflicenceappliedforCommencementdate}
                        isUpper={IS_UPPER}
                    />
                </SubSection>
                <SubSection orientation="left" title="Address of premise to be licensed">
                    <Plaintext
                        label={"Postal code"}
                        name={"LC_PostalCode"}
                        value={licenceInformation.LC_PostalCode}
                        isUpper={IS_UPPER}
                    />
                    <Plaintext
                        label={"Street Name"}
                        name={"LC_StreetName"}
                        value={licenceInformation.LC_StreetName}
                        isUpper={IS_UPPER}
                    />
                    <Plaintext
                        label={"Building name"}
                        name={"LC_BuildingName"}
                        value={licenceInformation.LC_BuildingName}
                        isUpper={IS_UPPER}
                    />
                    <Plaintext
                        label={"Block/House No"}
                        name={"LC_BlockHouseNo"}
                        value={licenceInformation.LC_BlockHouseNo}
                        isUpper={IS_UPPER}
                    />
                    <Plaintext
                        label={"Level"}
                        name={"LC_Level"}
                        value={licenceInformation.LC_Level}
                        isUpper={IS_UPPER}
                    />
                    <Plaintext
                        label={"Unit number"}
                        name={"LC_Unitnumber"}
                        value={licenceInformation.LC_Unitnumber}
                        isUpper={IS_UPPER}
                    />
                </SubSection>
                <SubSection orientation="left" title="Particulars of Directors/Partners/Office-Bearers">
                    <Plaintext
                        label={"ID Type"}
                        name={"ParticularsofDirectorsPartnersOfficeBearersIDType"}
                        value={licenceInformation.ParticularsofDirectorsPartnersOfficeBearersIDType}
                        isUpper={IS_UPPER}
                    />
                    <Plaintext
                        label={"ID number"}
                        name={"ParticularsofDirectorsPartnersOfficeBearersIDnumber"}
                        value={licenceInformation.ParticularsofDirectorsPartnersOfficeBearersIDnumber}
                        isUpper={IS_UPPER}
                    />
                    <Plaintext
                        label={"Nationality"}
                        name={"ParticularsofDirectorsPartnersOfficeBearersNationality"}
                        value={licenceInformation.ParticularsofDirectorsPartnersOfficeBearersNationality}
                        isUpper={IS_UPPER}
                    />
                    <Plaintext
                        label={"Appointment"}
                        name={"ParticularsofDirectorsPartnersOfficeBearersAppointment"}
                        value={licenceInformation.ParticularsofDirectorsPartnersOfficeBearersAppointment}
                        isUpper={IS_UPPER}
                    />
                    <Plaintext
                        label={"Name"}
                        name={"ParticularsofDirectorsPartnersOfficeBearersName"}
                        value={licenceInformation.ParticularsofDirectorsPartnersOfficeBearersName}
                        isUpper={IS_UPPER}
                    />
                    <Plaintext
                        label={"Appointment Date"}
                        name={"ParticularsofDirectorsPartnersOfficeBearersAppointmentDate"}
                        value={licenceInformation.ParticularsofDirectorsPartnersOfficeBearersAppointmentDate}
                        isUpper={IS_UPPER}
                    />
                    <Plaintext
                        label={"Date of Birth"}
                        name={"ParticularsofDirectorsPartnersOfficeBearersDateofBirth"}
                        value={licenceInformation.ParticularsofDirectorsPartnersOfficeBearersDateofBirth}
                        isUpper={IS_UPPER}
                    />
                </SubSection>
            </>
        );
    }
}

export default CommonSpfLiquor1To4Details;