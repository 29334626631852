import { Divider } from 'antd';
import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import { APPLICATION_LIST_URL, EXCEL_UPDATE, EXPORT_TRANSLATION_URL, GET_SCOPE_LIST_BY_APP_NAME, IMPORT_TRANSLATION_URL, LAYOUT_TYPE } from '../../App/AppSettings';
import Layout from '../../Layout';
import { ERROR } from '../../Localization/index';
import { CONTENT_TYPE, fetchRequest, formParams, getFileRequest, getParams, METHOD_TYPE, navigateTo } from '../../Shared/Actions';
import { FIELD_SIZE, FILE_UPLOAD_SECTION_FILES } from '../../Shared/Constants';
import { DataTable } from '../../Shared/DataTable';
import { getCustomComponents } from '../../Shared/DataTable/BaseTable.js';
import { DEFAULT_TEXT, DEFAULT_NAMESPACE } from '../../Shared/Constants/LanguageKeys.js';
import FileUploadSection from '../../Shared/FileUploadSection';
import Section from '../../Section';
import SectionWrapper from '../../SectionWrapper';
import { withSMARTWrapper, Hidden, SelectList, SMARTForm, toastError, toastSuccess } from '../../Shared/Forms';
import { withLoader } from '../../Shared/Loading';
import PageFooter from '../../Shared/PageFooter';
import './LanguageList.css';


const FORM_CONTEXT = "UploadFile";
const dropDownArrayApplication = 'dropDownArrayApplication';
const SECTION_NAMES = {
    DOWNLOAD_EXCEL: 'Download Language Pack',
    UPLOAD_EXCEL: `Upload Language Pack`
};
const IsValidate = 'IsValidate';
const EXCEL_EXTENSION = '.xlsx';

class ExcelUpdate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            width: window.innerWidth,
            [dropDownArrayApplication]: [],
            data: [],
            langCodes: [],
            LanguageApplication: '',
            LanguageScope: '',
            LanguageApplicationHasError: false,
            LanguageScopeHasError: false,
            filterValues: {
                LanguageScope: ''
            },
            values: {
                [FILE_UPLOAD_SECTION_FILES]: [],
                IsValidate: 'true'
            },
            filteredScopeList: [],
            uploadSuccess: false,
            sectionName: SECTION_NAMES.DOWNLOAD_EXCEL,
            appData: {
                LanguageApplication: '',
                LanguageScope: ''
            },
            sectionState: SECTION_NAMES.DOWNLOAD_EXCEL,
            isPreviewDataEmpty: false,
            showValidateButton: false
        };
    }


    componentDidMount = async () => {
        this.props.loader.start();

        await this.getApplicationDropdownList(APPLICATION_LIST_URL, dropDownArrayApplication);

        this.props.loader.done();
    }


    // Get all Application list
    getApplicationDropdownList = async (url, dropDownName) => {

        let fullurl = url;
        const response = await fetchRequest(fullurl, getParams());
        const { Data, IsSuccess } = response.body;
        let dropDownArray = [];

        if (response.success) {
            if (IsSuccess) {
                Data.forEach(name => {
                    dropDownArray.push({ 'label': name.Name, 'value': name.Name });
                });

                this.setState({
                    [dropDownName]: dropDownArray
                });
            }
            else {
                toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
                return null;
            }
        }
        else {
            toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
            return null;
        }
        return dropDownArray;
    }


    // Get filtered Scope list by Application 
    getFilteredScopeList = async (value) => {
        const params = {
            LanguageApplication: value
        }

        this.props.loader.start();

        const response = await fetchRequest(GET_SCOPE_LIST_BY_APP_NAME, getParams(params));

        this.props.loader.done();

        const { Data, IsSuccess } = response.body;

        let filteredList = [];

        if (IsSuccess) {
            for (let sc in Data) {
                filteredList.push({
                    label: Data[sc].Value,
                    value: Data[sc].Value
                });
            }

            this.setState({
                filteredScopeList: filteredList,
                LanguageApplication: value,
                LanguageScope: "",
                LanguageApplicationHasError: false,
                LanguageScopeHasError: false
            });
        }
    }


    // Set the selected scope
    SetScopeState = async (value) => {
        this.setState({
            LanguageScope: value,
            LanguageScopeHasError: false
        });
    }


    // Construct dynamic columns for DataTable
    ConstructColumns = () => {
        let columnObj = {
            RunningNumber: { title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_CONTENT_TABLE_NO, DEFAULT_TEXT.NUM), width: '5%', DBkey: 'Id' },
            LanguageKey: { title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_CONTENT_TABLE_LANGKEY, DEFAULT_TEXT.LANG_KEY), width: '20%', DBkey: 'LanguageKey' },
            CustomExcel: []
        };

        // Get dynamic column width
        let noOfLanguages = this.state.langCodes.length;
        let columnWidth = 75 / noOfLanguages;

        this.state.langCodes.map(i => {
            return (
                columnObj.CustomExcel.push(
                    {
                        title: i,
                        width: columnWidth + '%',
                        DBkey: i
                    })
            )
        });
        return columnObj;
    };

    // Validate Application and Scope fields
    IsDownloadLanguagePackFieldsValid = () => {
        let isValid = true;

        if (!this.state.LanguageApplication) {
            this.setState({
                LanguageApplicationHasError: true
            });

            isValid = false;
        }

        if (!this.state.LanguageScope) {
            this.setState({
                LanguageScopeHasError: true
            });

            isValid = false;
        }
        return isValid;
    }

    // Export file
    DownloadFile = async () => {
        if (this.IsDownloadLanguagePackFieldsValid()) {
            const params = {
                LanguageApplication: this.state.LanguageApplication,
                LanguageScope: this.state.LanguageScope
            };

            const { loader } = this.props;

            let fileName = this.state.LanguageApplication + '_' + this.state.LanguageScope + EXCEL_EXTENSION;

            loader.start();

            let response = await getFileRequest(EXPORT_TRANSLATION_URL, false, fileName, getParams(params));

            loader.done();

            if (response !== undefined && !response.success) {
                toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
            }
            return response;
        }

    }


    // When user clicks "Validate"
    validateFile = ({ response }) => {
        if (response.success) {

            const { IsSuccess, Message, Data, MessageLanguageKey } = response.body;

            // After Validate
            if (IsSuccess && Data.IsValidate) {

                // If Preview data is empty
                if (Data.TranslationPreviewData.length === 0) {
                    this.setState({
                        isPreviewDataEmpty: true,
                        uploadSuccess: false
                    });
                }

                else {
                    this.setState({
                        uploadSuccess: true,
                        langCodes: Data.LanguageCodes,
                        data: Data.TranslationPreviewData,
                        isPreviewDataEmpty: false,
                        showValidateButton: false
                    });

                    toastSuccess(this.props.getMessage(MessageLanguageKey, Message));
                }
            }

            // After Publish
            else if (IsSuccess && !Data.IsValidate) {
                this.setState({
                    uploadSuccess: false,
                    isPreviewDataEmpty: false
                });

                navigateTo(EXCEL_UPDATE);
                toastSuccess(this.props.getMessage(MessageLanguageKey, Message));
            }

            else {
                this.setState({
                    uploadSuccess: false,
                    isPreviewDataEmpty: false
                });

                toastError(this.props.getMessage(MessageLanguageKey, Message));
            }
        }
        else {
            this.setState({
                uploadSuccess: false,
                isPreviewDataEmpty: false
            });

            toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
        }
    }


    // Render DataTable
    renderLayout = () => {
        return (
            <React.Fragment>
                <div className="row">
                    <Col lg="12" className="label-default">
                        <Divider className="db-divider" orientation="left">
                            {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_DIVIDER_LANGUAGEPACK, DEFAULT_TEXT.DIVIDER_LANG_PACK)}
                        </Divider>
                    </Col>
                </div>

                <Row>
                    <span className="padding-left-right">
                        {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_CONTENT_TEXT_LANGKEYUPDATE, DEFAULT_TEXT.LANG_KEY_UPDATE)}
                    </span>
                </Row>

                <DataTable
                    pageSize='10'
                    minFilterChars='2'
                    data={this.state.data}
                    noResultsMessage={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_TABLE_TEXT_TRANSDATANORECORD, DEFAULT_TEXT.TRANS_DATA_NO_RECORD)}
                    components={getCustomComponents()}
                    columns={this.ConstructColumns()}
                />

                <div className="add-padding-top"></div>

            </React.Fragment>
        );
    }


    // Change IsValidate to false when click "Publish" button
    publishTranslation = (values, submitPlainForm) => {

        if (values.FileUploadSectionFiles.length === 0) {
            toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_ERROR_TITLE_UPLOADFILE, DEFAULT_TEXT.UPLOAD_FILE));
        }
        else {
            values.IsValidate = 'false';
            submitPlainForm();
        }
    }


    // Only show Validate button when file has been uploaded
    showValidateButton = (name, values, onChangeField) => {
        if (values.length === 0) {
            this.setState({
                showValidateButton: false,
                uploadSuccess: false,
                isPreviewDataEmpty: false
            });
        }
        else {
            onChangeField(name, values);

            this.setState({
                showValidateButton: true,
            });
        }
    }


    // Change IsValidate to true
    validateTranslation = (values, submitPlainForm) => {
        if (values.FileUploadSectionFiles.length === 0) {
            this.setState({
                uploadSuccess: false
            });

            toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_ERROR_TITLE_UPLOADFILE, DEFAULT_TEXT.UPLOAD_FILE));
        }
        else {
            values.IsValidate = 'true';
            submitPlainForm();
        }
    }


    // Toggle active sections
    toggleActiveTab = (tabName) => {
        this.setState({
            sectionState: tabName
        });
    }


    // Show validate button only when there is file uploaded
    renderValidateButton = (values, submitPlainForm) => {
        return (
            <Button disabled={!this.state.showValidateButton} color="forward" size="sm" type="button" onClick={() => this.validateTranslation(values, submitPlainForm)}>
                {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_NAVIGATION_BUTTON_VALIDATE, DEFAULT_TEXT.VALIDATE)}
            </Button>
        );
    }

    componentWillMount = () => {
        window.addEventListener('resize', this.handleWindowSizeChange);
    };

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    };

    handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
    };

    render() {
        const { dropDownArrayApplication, filteredScopeList } = this.state;
        return (
            <>
                <Layout type={LAYOUT_TYPE.FORM} title=
                    {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_CONTENT_TITLE_LANGUAGEPACK, DEFAULT_TEXT.LANGUAGE_PACK_UPDATE)}>
                    <SMARTForm
                        formContext={FORM_CONTEXT}
                        formValues={this.state.values}
                        serverURL={IMPORT_TRANSLATION_URL}
                        submitCallback={this.validateFile}
                        defaultSection={SECTION_NAMES.DOWNLOAD_EXCEL}
                        sectionNames={SECTION_NAMES}
                    >
                        {({ values, onChangeField, sectionState, toggleSection, submitPlainForm }) => (
                            <React.Fragment>
                                <Row>
                                    <Col className="body-content">
                                        <SectionWrapper
                                            type={1}
                                            tabs={SECTION_NAMES}
                                            sectionState={sectionState}
                                            toggleSection={toggleSection}
                                            toggleFlex={true}
                                        >
                                            <Section
                                                type={1}
                                                sectionState={sectionState}
                                                sectionName={SECTION_NAMES.DOWNLOAD_EXCEL}
                                                toggleSection={toggleSection}
                                            >
                                                <Row className="body-content">
                                                    <Col>
                                                        <SelectList
                                                            name="LanguageApplication"
                                                            value={this.state.LanguageApplication}
                                                            options={dropDownArrayApplication}
                                                            onChangeField={(name, value) => { this.getFilteredScopeList(value) }}
                                                            placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_FORM_PLACEHOLDER_APPLICATION, DEFAULT_TEXT.SELECT_APP)}
                                                            isMulti={false}
                                                            labelSize={FIELD_SIZE.SMALL}
                                                            inputSize={FIELD_SIZE.MEDIUM}
                                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_FORM_TITLE_APPLICATION, DEFAULT_TEXT.APPLICATION)}
                                                            required
                                                            error={this.state.LanguageApplicationHasError ?
                                                                this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_ERROR_TITLE_APPLICATION, DEFAULT_TEXT.ERROR_SELECT_APP) :
                                                                undefined}
                                                        />
                                                        <SelectList
                                                            name="LanguageScope"
                                                            value={this.state.LanguageScope}
                                                            options={filteredScopeList}
                                                            noOptionsMessage={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_CONTENT_DROPDOWN_SCOPENORECORD, DEFAULT_TEXT.SELECTLIST_SCOPE_NO_RECORD)}
                                                            onChangeField={(name, value) => { this.SetScopeState(value) }}
                                                            placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_FORM_PLACEHOLDER_SCOPE, DEFAULT_TEXT.SELECT_SCOPE)}
                                                            isMulti={false}
                                                            labelSize={FIELD_SIZE.SMALL}
                                                            inputSize={FIELD_SIZE.MEDIUM}
                                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_FORM_TITLE_SCOPE, DEFAULT_TEXT.SCOPE)}
                                                            required
                                                            error={this.state.LanguageScopeHasError ?
                                                                this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_ERROR_TITLE_SCOPE, DEFAULT_TEXT.ERROR_SELECT_SCOPE) :
                                                                undefined}
                                                        />
                                                        <PageFooter type={LAYOUT_TYPE.FORM}>
                                                            <Button color="forward" size="sm" type="button" onClick={() => this.DownloadFile()}>
                                                                {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_NAVIGATION_BUTTON_DOWNLOAD, DEFAULT_TEXT.DOWNLOAD)}
                                                            </Button>
                                                        </PageFooter>
                                                    </Col>
                                                </Row>
                                            </Section>
                                            <Section
                                                type={1}
                                                sectionState={sectionState}
                                                sectionName={SECTION_NAMES.UPLOAD_EXCEL}
                                                toggleSection={toggleSection}
                                            >
                                                <Row className="body-content">
                                                    <Col>
                                                        <FileUploadSection
                                                            sectionName={"Language Pack"}
                                                            btnClassname={"text-right"}
                                                            name={FILE_UPLOAD_SECTION_FILES}
                                                            fileList={values[FILE_UPLOAD_SECTION_FILES]}
                                                            onChangeField={(name, values) => this.showValidateButton(name, values, onChangeField)}
                                                            uploadLimit={1}
                                                            accept={EXCEL_EXTENSION}
                                                            formParams={formParams(METHOD_TYPE.GET, CONTENT_TYPE.FORM_DATA)}
                                                            disableDescription={true}
                                                        />

                                                        <Hidden name={IsValidate} value={values.IsValidate} />

                                                        {this.state.uploadSuccess && this.renderLayout()}

                                                        {this.state.isPreviewDataEmpty && <div className="text-center">
                                                            {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_CONTENT_TEXT_TRANSDATA, DEFAULT_TEXT.NO_TRANSLATION_DATA)}
                                                        </div>}

                                                        <PageFooter type={LAYOUT_TYPE.FORM}>

                                                            {this.renderValidateButton(values, submitPlainForm)}

                                                            <Button disabled={!this.state.uploadSuccess} color="forward" size="sm" type="button" onClick={() => this.publishTranslation(values, submitPlainForm)}>
                                                                {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_NAVIGATION_BUTTON_PUBLISH, DEFAULT_TEXT.PUBLISH)}
                                                            </Button>

                                                        </PageFooter>
                                                    </Col>
                                                </Row>
                                            </Section>
                                        </SectionWrapper>
                                    </Col>
                                </Row>
                            </React.Fragment>
                        )}
                    </SMARTForm>
                </Layout>
            </>
        );
    }

}

export default withSMARTWrapper(withLoader(ExcelUpdate));

