export const getPremiseOperatingAddress = LicenceInformation => {
    let { PremiseInformation_BlockHouseNo,
        PremiseInformation_Buildingname,
        PremiseInformation_Level,
        PremiseInformation_PostalCode,
        PremiseInformation_Streetname,
        PremiseInformation_Unitnumber
    } = LicenceInformation;

    let Address = '-';

    PremiseInformation_BlockHouseNo = PremiseInformation_BlockHouseNo ? PremiseInformation_BlockHouseNo : '';
    PremiseInformation_Buildingname = PremiseInformation_Buildingname ? PremiseInformation_Buildingname : '';
    PremiseInformation_Level = PremiseInformation_Level ? PremiseInformation_Level : '';
    PremiseInformation_PostalCode = PremiseInformation_PostalCode ? PremiseInformation_PostalCode : '';
    PremiseInformation_Streetname = PremiseInformation_Streetname ? PremiseInformation_Streetname : '';
    PremiseInformation_Unitnumber = PremiseInformation_Unitnumber ? PremiseInformation_Unitnumber : '';

    const Space = '\u00A0';

    const BlockNo = `${PremiseInformation_BlockHouseNo && `${PremiseInformation_BlockHouseNo},${Space}`}`;
    const StreetName = `${PremiseInformation_Streetname && `${PremiseInformation_Streetname},${Space}`}`;
    const LevelUnitNumber = `${PremiseInformation_Level && `#${PremiseInformation_Level}`}${PremiseInformation_Unitnumber &&
         `-${PremiseInformation_Unitnumber}`}${(PremiseInformation_Level || PremiseInformation_Unitnumber) && `,${Space}`}`;
    const BuildingName = `${PremiseInformation_Buildingname && `${PremiseInformation_Buildingname},${Space}`}`;
    const PostalCode = `${PremiseInformation_PostalCode}`;

    Address = `${BlockNo}${StreetName}${LevelUnitNumber}${BuildingName}${PostalCode}`;

    return Address;
};