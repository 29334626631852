import React, { useEffect, useState } from "react";
import { DndContext, closestCenter, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import SortableItem from "../dragndrop/SortableItem";
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import Field from "../Field";
import { Button, Row } from "reactstrap";

function DataGridPanel(props) {
    const show = props.show;
    const tempDatagridJson = (props.fieldJson.datagridFields) ? props.fieldJson.datagridFields : {
        fields: []
    }
    const [datagridJson, setDatagridJson] = useState(tempDatagridJson);
    const globalFieldList = props.globalFieldList;
    const [datagridValidation, setDatagridValidation] = useState(props.fieldValidation.datagridFields);
    const isValidated = props.isValidated;
    const parentFieldID = props.parentFieldID;

    //#region Drag and Drop
    const sensors = useSensors(
        useSensor(PointerSensor)
    );

    function handleDragEnd(event) {
        const { active, over } = event;

        if (active.id !== over.id) {
            const oldFieldIndex = datagridJson.fields.findIndex(field => field.id === active.id);
            const newFieldIndex = datagridJson.fields.findIndex(field => field.id === over.id);
            const newFields = arrayMove(datagridJson.fields, oldFieldIndex, newFieldIndex);
            setDatagridJson({
                ...datagridJson,
                fields: (newFields)
            });
        }
    }
    //#endregion
    
    function deleteField(field) {
        const newFieldArray = datagridJson.fields.filter(a => a.id !== field.id);
        const newValidFieldArray = datagridValidation.fields.filter(a => a.id !== field.id);
        setDatagridValidation({
            ...datagridValidation,
            fields: newValidFieldArray
        })
        setDatagridJson({
            ...datagridJson,
            fields: newFieldArray
        })
    }

    function addNewFieldOnClick() {
        const newFieldJson = {
            id: datagridJson.fields.length + 1
        }
        setDatagridJson({
            ...datagridJson,
            fields: [...datagridJson.fields, newFieldJson]
        })

        const newFieldValidation = {
            id: datagridValidation.fields.length + 1,
            inputType: null,
            label: null
        }
        setDatagridValidation({
            ...datagridValidation,
            fields: [...datagridValidation.fields, newFieldValidation]
        })
    }

    useEffect(() => {
        props.onDatagridChange({
            ...props.fieldJson,
            datagridFields: datagridJson
        });
        props.onValidationChange({
            ...props.fieldValidation,
            datagridFields: datagridValidation
        })
    }, [datagridJson])

    if (show) {
        return <div style={{paddingLeft: "15px", paddingRight: "15px"}}>
            <Button color="primary" outline onClick={addNewFieldOnClick} className="mb-3">Add New Field To Datagrid</Button>
            <DndContext onDragEnd={handleDragEnd} sensors={sensors} collisionDetection={closestCenter} modifiers={[restrictToVerticalAxis]}>
                <SortableContext items={datagridJson.fields.map(field => field.id)} strategy={verticalListSortingStrategy}>
                    {datagridJson.fields.map((field) => {
                        const fieldValidation = datagridValidation.fields.find(f => f.id === field.id);
                        return <SortableItem key={field.id} id={field.id}>
                            <Row xs="auto" className="mb-2">
                                <Field field={field} fieldValidation={fieldValidation} isValidated={isValidated} onDelete={deleteField} 
                                    sectionJson={datagridJson} sectionValidation={datagridValidation} onFieldChange={setDatagridJson} 
                                    onValidationChange={setDatagridValidation} globalFieldList={globalFieldList} parentFieldID={parentFieldID}></Field>
                            </Row>
                        </SortableItem>
                    })}
                </SortableContext>
            </DndContext>
        </div>
    } else {
        return <div>
        </div>
    }
}

export default DataGridPanel;