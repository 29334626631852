import React from 'react';
import SubSection from '../../../../../../Section/SubSection';
import { Plaintext } from '../../../../../../Shared/Forms';

const IS_UPPER = true;

const SUB_SECTION_NAMES = {
    PROPERTY_ADDRESS_FOR_NEW_CHANGE_OF_USE: `Property address for new change of use`,
    ADDITIONAL_APPLICANT_PARTICULARS: `Additional applicant particulars`,
    OWNER_CO_OWNER_PARTICULARS: `Owner/Co-Owner particulars`,
    ADDITIONAL_PERSON_PARTICULARS: `Additional person particulars`
}

class CommonUraChangeUseApprovalDetails extends React.Component {
    render() {
        const { licenceInformation } = this.props;

        return (
            licenceInformation &&
            <>
                 <SubSection orientation="left" title={SUB_SECTION_NAMES.PROPERTY_ADDRESS_FOR_NEW_CHANGE_OF_USE}>
                    <Plaintext
                        label={"Postal code"}
                        name={"LC_PostalCode"}
                        value={licenceInformation.LC_PostalCode}
                        isUpper={IS_UPPER}
                    />
                    <Plaintext
                        label={"Street Name"}
                        name={"LC_StreetName"}
                        value={licenceInformation.LC_StreetName}
                        isUpper={IS_UPPER}
                    />
                    <Plaintext
                        label={"Building name"}
                        name={"LC_BuildingName"}
                        value={licenceInformation.LC_BuildingName}
                        isUpper={IS_UPPER}
                    />
                    <Plaintext
                        label={"Block/House No"}
                        name={"LC_BlockHouseNo"}
                        value={licenceInformation.LC_BlockHouseNo}
                        isUpper={IS_UPPER}
                    />
                    <Plaintext
                        label={"Level"}
                        name={"LC_Level"}
                        value={licenceInformation.LC_Level}
                        isUpper={IS_UPPER}
                    />
                    <Plaintext
                        label={"Unit number"}
                        name={"LC_Unitnumber"}
                        value={licenceInformation.LC_Unitnumber}
                        isUpper={IS_UPPER}
                    />
                    <Plaintext
                        label={"Floor size"}
                        name={"LC_Floorsize"}
                        value={licenceInformation.LC_Floorsize}
                        isUpper={IS_UPPER}
                    />
                    <Plaintext
                        label={"Please select your intended use of the premises"}
                        name={"PropertyaddressfornewchangeofusePleaseselectyourintendeduseofthepremises"}
                        value={licenceInformation.PropertyaddressfornewchangeofusePleaseselectyourintendeduseofthepremises}
                        isUpper={IS_UPPER}
                    />
                </SubSection>

                <SubSection orientation="left" title={SUB_SECTION_NAMES.ADDITIONAL_APPLICANT_PARTICULARS}>
                    <Plaintext
                        label={"Interest in Application"}
                        name={"AdditionalapplicantparticularsInterestinApplication"}
                        value={licenceInformation.AdditionalapplicantparticularsInterestinApplication}
                        isUpper={IS_UPPER}
                    />

                </SubSection>
                <SubSection orientation="left" title={SUB_SECTION_NAMES.OWNER_CO_OWNER_PARTICULARS}>
                    <Plaintext
                        label={"Name"}
                        name={"OwnerCoOwnerparticularsName"}
                        value={licenceInformation.OwnerCoOwnerparticularsName}
                        isUpper={IS_UPPER}
                    />
                    <Plaintext
                        label={"Identity Type"}
                        name={"OwnerCoOwnerparticularsIdentityType"}
                        value={licenceInformation.OwnerCoOwnerparticularsIdentityType}
                        isUpper={IS_UPPER}
                    />
                    <Plaintext
                        label={"Identity number"}
                        name={"OwnerCoOwnerparticularsIdentitynumber"}
                        value={licenceInformation.OwnerCoOwnerparticularsIdentitynumber}
                        isUpper={IS_UPPER}
                    />
                    <Plaintext
                        label={"Citizenship"}
                        name={"OwnerCoOwnerparticularsCitizenship"}
                        value={licenceInformation.OwnerCoOwnerparticularsCitizenship}
                        isUpper={IS_UPPER}
                    />
                    <Plaintext
                        label={"Postal code"}
                        name={"OwnerCoOwnerparticularsPostalcode"}
                        value={licenceInformation.OwnerCoOwnerparticularsPostalcode}
                        isUpper={IS_UPPER}
                    />
                    <Plaintext
                        label={"Block/House No"}
                        name={"OwnerCoOwnerparticularsBlockHouseNo"}
                        value={licenceInformation.OwnerCoOwnerparticularsBlockHouseNo}
                        isUpper={IS_UPPER}
                    />
                    <Plaintext
                        label={"Street Name"}
                        name={"OwnerCoOwnerparticularsStreetName"}
                        value={licenceInformation.OwnerCoOwnerparticularsStreetName}
                        isUpper={IS_UPPER}
                    />
                    <Plaintext
                        label={"Level No"}
                        name={"OwnerCoOwnerparticularsLevelNo"}
                        value={licenceInformation.OwnerCoOwnerparticularsLevelNo}
                        isUpper={IS_UPPER}
                    />
                    <Plaintext
                        label={"Unit No"}
                        name={"OwnerCoOwnerparticularsUnitNo"}
                        value={licenceInformation.OwnerCoOwnerparticularsUnitNo}
                        isUpper={IS_UPPER}
                    />
                    <Plaintext
                        label={"Building name"}
                        name={"OwnerCoOwnerparticularsBuildingname"}
                        value={licenceInformation.OwnerCoOwnerparticularsBuildingname}
                        isUpper={IS_UPPER}
                    />
                    <Plaintext
                        label={"Telephone No"}
                        name={"OwnerCoOwnerparticularsTelephoneNo"}
                        value={licenceInformation.OwnerCoOwnerparticularsTelephoneNo}
                        isUpper={IS_UPPER}
                    />
                    <Plaintext
                        label={"Telephone Extension"}
                        name={"OwnerCoOwnerparticularsTelephoneExtension"}
                        value={licenceInformation.OwnerCoOwnerparticularsTelephoneExtension}
                        isUpper={IS_UPPER}
                    />
                    <Plaintext
                        label={"Mobile Number"}
                        name={"OwnerCoOwnerparticularsMobileNumber"}
                        value={licenceInformation.OwnerCoOwnerparticularsMobileNumber}
                        isUpper={IS_UPPER}
                    />
                    <Plaintext
                        label={"Email"}
                        name={"OwnerCoOwnerparticularsEmail"}
                        value={licenceInformation.OwnerCoOwnerparticularsEmail}
                        isUpper={IS_UPPER}
                    />
                </SubSection>
                <SubSection orientation="left" title={SUB_SECTION_NAMES.ADDITIONAL_PERSON_PARTICULARS}>
                    <Plaintext
                        label={"Name"}
                        name={"AdditionalpersonparticularsName"}
                        value={licenceInformation.AdditionalpersonparticularsName}
                        isUpper={IS_UPPER}
                    />
                    <Plaintext
                        label={"Identity Type"}
                        name={"AdditionalpersonparticularsIdentityType"}
                        value={licenceInformation.AdditionalpersonparticularsIdentityType}
                        isUpper={IS_UPPER}
                    />
                    <Plaintext
                        label={"Identity number"}
                        name={"AdditionalpersonparticularsIdentitynumber"}
                        value={licenceInformation.AdditionalpersonparticularsIdentitynumber}
                        isUpper={IS_UPPER}
                    />
                    <Plaintext
                        label={"Citizenship"}
                        name={"AdditionalpersonparticularsCitizenship"}
                        value={licenceInformation.AdditionalpersonparticularsCitizenship}
                        isUpper={IS_UPPER}
                    />
                    <Plaintext
                        label={"Postal code"}
                        name={"AdditionalpersonparticularsPostalcode"}
                        value={licenceInformation.AdditionalpersonparticularsPostalcode}
                        isUpper={IS_UPPER}
                    />
                    <Plaintext
                        label={"Block/House No"}
                        name={"AdditionalpersonparticularsBlockHouseNo"}
                        value={licenceInformation.AdditionalpersonparticularsBlockHouseNo}
                        isUpper={IS_UPPER}
                    />
                    <Plaintext
                        label={"Street Name"}
                        name={"AdditionalpersonparticularsStreetName"}
                        value={licenceInformation.AdditionalpersonparticularsStreetName}
                        isUpper={IS_UPPER}
                    />
                    <Plaintext
                        label={"Level No"}
                        name={"AdditionalpersonparticularsLevelNo"}
                        value={licenceInformation.AdditionalpersonparticularsLevelNo}
                        isUpper={IS_UPPER}
                    />
                    <Plaintext
                        label={"Unit No"}
                        name={"AdditionalpersonparticularsUnitNo"}
                        value={licenceInformation.AdditionalpersonparticularsUnitNo}
                        isUpper={IS_UPPER}
                    />
                    <Plaintext
                        label={"Building name"}
                        name={"AdditionalpersonparticularsBuildingname"}
                        value={licenceInformation.AdditionalpersonparticularsBuildingname}
                        isUpper={IS_UPPER}
                    />
                    <Plaintext
                        label={"Telephone No"}
                        name={"AdditionalpersonparticularsTelephoneNo"}
                        value={licenceInformation.AdditionalpersonparticularsTelephoneNo}
                        isUpper={IS_UPPER}
                    />
                    <Plaintext
                        label={"Telephone Extension"}
                        name={"AdditionalpersonparticularsTelephoneExtension"}
                        value={licenceInformation.AdditionalpersonparticularsTelephoneExtension}
                        isUpper={IS_UPPER}
                    />
                    <Plaintext
                        label={"Mobile Number"}
                        name={"AdditionalpersonparticularsMobileNumber"}
                        value={licenceInformation.AdditionalpersonparticularsMobileNumber}
                        isUpper={IS_UPPER}
                    />
                    <Plaintext
                        label={"Email"}
                        name={"AdditionalpersonparticularsEmail"}
                        value={licenceInformation.AdditionalpersonparticularsEmail}
                        isUpper={IS_UPPER}
                    />
                    <Plaintext
                        label={"Interest in application"}
                        name={"AdditionalpersonparticularsInterestinapplication"}
                        value={licenceInformation.AdditionalpersonparticularsInterestinapplication}
                        isUpper={IS_UPPER}
                    />
                </SubSection>
            </>
        );
    }
}

export default CommonUraChangeUseApprovalDetails;