import { faEdit, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FieldArray } from 'formik';
import React from 'react';
import { Button, Label, Table } from 'reactstrap';
import { REGEX } from '../../../App/AppSettings';
import { Plaintext } from '../../../Shared/Forms';
import { SMARTFormContext, SMARTSectionContext } from '../../../Shared/Forms/SMARTContext';
import { ModalConfirm } from '../../../Shared/Modal';
import RenderTableFieldComponent from '../RenderTableFieldComponent';

const MODAL_NAMES = { TablePopUp: 'TablePopUp', AddRowPopUp: 'AddRowPopUp' };

const withSMARTContext = WrappedComponent => {
    class SMARTContext extends React.Component {
        render() {
            return (
                <SMARTFormContext.Consumer>
                    {updateControlList => (
                        <SMARTSectionContext.Consumer>
                            {sectionName => (
                                <WrappedComponent
                                    context={{
                                        updateControlList,
                                        sectionName
                                    }}
                                    {...this.props}
                                />
                            )}
                        </SMARTSectionContext.Consumer>
                    )}
                </SMARTFormContext.Consumer>
            );
        }
    }

    return SMARTContext;
};

class BigTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    // Lifecycle: Called once when component is mounted
    componentDidMount() {
        // Get context
        const { updateControlList, sectionName } = this.props.context;
        const { formType, formSection } = this.props;

        // Control calls callback to inform its parent SMARTForm their existence
        updateControlList && updateControlList(`${formType}.${formSection.Name}`, sectionName);
    }

    // Confirm for both Add and Edit Modal
    toggleModalConfirm = async (formSection, modalName, sectionName, modalValues, modalError, modalStateError) => {
        const fieldArray = [];
        formSection.forEach(section => {
            section.Table && section.Name === sectionName && section.Table.Fields.forEach(field => {
                if (field.Validation != null && field.Validation.Required === true) {
                    // Check if field is empty, if it's empty, add field name into the array
                    if (modalValues[field.Name] === '') {
                        fieldArray.push(field.Name);
                    }
                    // Check if field type equal to email, if it's email, validate it to check if is the correct format
                    else if (!REGEX.EMAIL.test(modalValues[field.Name]) && field.InputType === 'Email') {
                        fieldArray.push(field.Name);
                    }
                    // Check if field type equal to number, if it's number, validate it to check if is the correct format
                    else if (!REGEX.NUMBER.test(modalValues[field.Name]) && field.InputType === 'Number') {
                        fieldArray.push(field.Name);
                    }
                }
            });
        });

        // If array is empty and there is no error message, close the modal and add the values into the table
        if (fieldArray.length === 0 && modalError[sectionName] === undefined) {
            this.setState({
                modalState: (this.state.modalState !== modalName) ? modalName : undefined
            });
            modalStateError(false);
        }
        // Show error message
        else {
            modalStateError(true);
        }
    }

    // Open Edit Modal
    toggleModal = (modalName, ID, modalValues) => {
        this.setState({
            modalState: (this.state.modalState !== modalName) ? modalName : undefined,
            modalID: ID,
            modalValues
        });
    };

    // Cancel Edit Modal
    toggleCancelEditModal = async (formType, formSection, modalName, ID, modalValues, sectionName, onChangeField) => {
        formSection.forEach(section => {
            if (section.Table && section.Name === sectionName) {
                section.Table.Fields.forEach(field => {
                    // Get previous field value and compare with the new value. If there is a difference in the value then reset it back to the previous value.
                    if (modalValues[field.Name] !== this.state.modalValues[field.Name]) {
                        onChangeField(`${formType}.${sectionName}[${ID}][${field.Name}]`, this.state.modalValues[field.Name]);
                    }
                });
            }
        });

        this.setState({
            modalState: (this.state.modalState !== modalName) ? modalName : undefined,
            modalID: ID
        });
    };

    // Open Add Modal
    toggleModalAddRow = modalName => {
        this.setState({
            modalState: (this.state.modalState !== modalName) ? modalName : undefined,
        });
    };

    // Set state to true if Maximum Row reached
    maximumErrorMessage = maximumRows => {
        this.setState({
            showMaximumRows: maximumRows
        });
    }

    render() {
        const { values, onChangeField, onChange, modalError, modalStateError } = this.props.smartFormValues;
        const { additionalFieldsError, form, formType, formSection, optionDataList } = this.props;
        const newRow = {};

        return (
            <FieldArray
                name={`${formType}.${formSection.Name}`}
                render={arrayHelpers => (
                    <div>
                        <div className="table-add-row">
                            <Button
                                color="neutral"
                                size="md"
                                type="button"
                                className="generic-table-add"
                                onClick=
                                {
                                    // Check table row, if table maximum row not reached, user can continue to add row
                                    (values[formType][formSection.Name] && values[formType][formSection.Name].length !== formSection.Table.MaximumTableRows)
                                        || formSection.Table.MaximumTableRows === undefined
                                        || formSection.Table.MaximumTableRows === 0
                                        ? () => {
                                            this.toggleModalAddRow(MODAL_NAMES.AddRowPopUp);
                                            arrayHelpers.push(newRow);
                                        }
                                        : () => this.maximumErrorMessage(true)
                                }
                                disabled={this.state.showMaximumRows ? true : false}
                            >
                                <FontAwesomeIcon icon={faPlus} />
                                Add
                            </Button>
                        </div>
                        <div className="table-requirement-label">
                            {/* Display label for min & max row */}
                            <Label>
                                {
                                    formSection.Table.MinimumTableRows ?
                                        <p>Require at least {formSection.Table.MinimumTableRows} record(s)</p>
                                        : null
                                }
                                {
                                    formSection.Table.MaximumTableRows ?
                                        <p>Maximum of {formSection.Table.MaximumTableRows} record(s)</p>
                                        : null
                                }
                            </Label>
                            <br />
                            {
                                additionalFieldsError &&
                                values[formType][formSection.Name].length < formSection.Table.MinimumTableRows &&
                                <Label className="required label-error">
                                    Minimum quota not reach: Minimum {formSection.Table.MinimumTableRows} record(s)
                                </Label>
                            }
                            {
                                this.state.showMaximumRows ?
                                    <Label className="required label-error">
                                        Maximum quota hit: Maximum {formSection.Table.MaximumTableRows} record(s)
                                                                        </Label>
                                    : null
                            }
                        </div>
                        <Table className="griddle-table">
                            <thead className="griddle-table-heading">
                                <tr>
                                    {
                                        formSection.Table.Fields && formSection.Table.Fields.map((field, findex) => {
                                            newRow[field.Name] = '';

                                            if (field.ShowField === 'Show') {
                                                if (field.Validation.Required === true) {
                                                    return (
                                                        <th key={findex} className="griddle-table-heading-cell required">
                                                            <span>{field.DisplayName}</span>
                                                        </th>
                                                    );
                                                }
                                                else {
                                                    return (
                                                        <th key={findex} className="griddle-table-heading-cell">
                                                            <span>{field.DisplayName}</span>
                                                        </th>
                                                    );
                                                }
                                            }
                                            else {
                                                return null;
                                            }
                                        })
                                    }
                                    <th className="griddle-table-heading-cell"><span>Actions</span></th>
                                </tr>
                            </thead>
                            <tbody className="griddle-table-body">
                                {values[formType][formSection.Name] && values[formType][formSection.Name].map((row, rindex) => {
                                    return (
                                        <tr key={rindex} className="griddle-row">
                                            {
                                                formSection.Table.Fields && formSection.Table.Fields.map((field, findex) => {
                                                    return (
                                                        field.ShowField === 'Show' && <td key={findex + rindex} className="griddle-cell table-griddle-cell">
                                                            {
                                                                field.InputType !== 'FileUpload' && <Plaintext
                                                                    key={findex + rindex}
                                                                    name={field.Name}
                                                                    value={row[field.Name]}
                                                                    Label={undefined}
                                                                    labelSize={null}
                                                                />
                                                            }
                                                        </td>
                                                    );
                                                })
                                            }
                                            <td className="griddle-cell table-griddle-cell">
                                                <div className="action-button-group table-button">
                                                    <Button
                                                        className="action-btn"
                                                        color="neutral"
                                                        type="button"
                                                        onClick={() => this.toggleModal(
                                                            MODAL_NAMES.TablePopUp,
                                                            rindex,
                                                            values[formType][formSection.Name][rindex]
                                                        )}
                                                    >
                                                        <FontAwesomeIcon icon={faEdit} />
                                                    </Button>
                                                    <Button
                                                        className="action-btn"
                                                        color="hazard"
                                                        type="button"
                                                        onClick={() => {
                                                            arrayHelpers.remove(rindex);
                                                            this.maximumErrorMessage(false);
                                                        }}
                                                    >
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </Button>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                                {/* Edit Table Modal */}
                                <ModalConfirm
                                    className={'modal-table'}
                                    isOpen={this.state.modalState === MODAL_NAMES.TablePopUp}
                                    contentHeader={`Edit ${formSection.DisplayName}`}
                                    contentBody={
                                        values[formType][formSection.Name] && values[formType][formSection.Name][this.state.modalID] &&
                                        formSection.Table.Fields && formSection.Table.Fields.map((field, findex) => {
                                            return (
                                                <RenderTableFieldComponent
                                                    optionDataList={optionDataList}
                                                    popUp={true}
                                                    key={findex + this.state.modalID}
                                                    sectionName={formSection.Name}
                                                    rowIndex={this.state.modalID}
                                                    formType={formType}
                                                    name={field.Name}
                                                    field={field}
                                                    values={values}
                                                    onChange={onChange}
                                                    onChangeField={onChangeField}
                                                    errors={modalError}
                                                    helpLabel={field.HelpText}
                                                    additionalFieldsError={additionalFieldsError}
                                                />
                                            );
                                        })

                                    }
                                    confirmText="Confirm"
                                    confirmCallback={() => {
                                        this.toggleModalConfirm(
                                            form.Sections,
                                            MODAL_NAMES.TablePopUp,
                                            formSection.Name,
                                            values[formType][formSection.Name][values[formType][formSection.Name].length - 1],
                                            modalError,
                                            modalStateError
                                        );
                                    }}
                                    cancelText="Cancel"
                                    cancelCallback={() => {
                                        this.toggleCancelEditModal(
                                            formType,
                                            form.Sections,
                                            MODAL_NAMES.TablePopUp,
                                            this.state.modalID,
                                            values[formType][formSection.Name][this.state.modalID],
                                            formSection.Name,
                                            onChangeField
                                        );
                                    }}
                                />
                                {/* Add Table Modal */}
                                <ModalConfirm
                                    className={'modal-table'}
                                    isOpen={this.state.modalState === MODAL_NAMES.AddRowPopUp}
                                    contentHeader={`Add ${formSection.DisplayName}`}
                                    contentBody={
                                        values[formType][formSection.Name] && values[formType][formSection.Name].length > 0 &&
                                        formSection.Table.Fields && formSection.Table.Fields.map((field, findex) => {
                                            return (
                                                <RenderTableFieldComponent
                                                    optionDataList={optionDataList}
                                                    popUp={true}
                                                    key={findex + values[formType][formSection.Name].length - 1}
                                                    sectionName={formSection.Name}
                                                    rowIndex={values[formType][formSection.Name].length - 1}
                                                    name={field.Name}
                                                    field={field}
                                                    formType={formType}
                                                    values={values}
                                                    onChange={onChange}
                                                    onChangeField={onChangeField}
                                                    errors={modalError}
                                                    helpLabel={field.HelpText}
                                                    additionalFieldsError={additionalFieldsError}
                                                />
                                            );
                                        })
                                    }
                                    confirmText="Confirm"
                                    confirmCallback={() => {
                                        this.toggleModalConfirm(
                                            form.Sections,
                                            MODAL_NAMES.AddRowPopUp,
                                            formSection.Name,
                                            values[formType][formSection.Name][values[formType][formSection.Name].length - 1],
                                            modalError,
                                            modalStateError
                                        );
                                    }}
                                    cancelText="Cancel"
                                    cancelCallback={modalError[formSection.Name] !== undefined
                                        ? () => {
                                            this.toggleModal(
                                                MODAL_NAMES.AddRowPopUp,
                                                arrayHelpers.remove(values[formType][formSection.Name].length - 1)
                                            );
                                            modalStateError(false);
                                        }
                                        : () => this.toggleModal(
                                            MODAL_NAMES.AddRowPopUp,
                                            arrayHelpers.remove(values[formType][formSection.Name].length - 1)
                                        )
                                    }
                                />
                            </tbody>
                        </Table>
                    </div>
                )}
            />
        );
    }
}
export default withSMARTContext(BigTable);