import React, { useEffect, useState } from 'react';
import { Button, Container, Row, Col } from 'reactstrap';
import * as Yup from 'yup';
import { LAYOUT_TYPE, LICENCE_CONFIG_CREATE, REGEX } from '../../App/AppSettings';
import Layout from '../../Layout';
import { navigateTo } from '../../Shared/Actions';
import { SMARTForm } from '../../Shared/Forms';
import { toastError } from '../../Shared/Forms/Toaster.js';
import history from '../../Shared/History';
import PageFooter from '../../Shared/PageFooter';
import Dependency from './Dependency';
import Faq from './Faq';
import LicenceInformation from './LicenceInformation';
// import Publish from './Publish';
import { useTranslation } from 'react-i18next';
import { ERROR, LANGUAGE_KEYS } from '../../Shared/Constants/LanguageKeys';
import { getLangKey } from '../DisplayComponents/DisplayUtils';
import i18n from '../../../i18n';

const FORM_CONTEXT = 'LicenceConfigCreate';
const SERVER_URL = LICENCE_CONFIG_CREATE;
const REGEX_LESS_THAN_TWELVE = /^\s*[1][0-2]\s*$|^\s*0\s*$|^\s*[1-9]\s*$/;
const REGEX_LESS_THAN_THIRTY_ONE = /^\s*[1-2][0-9]\s*$|^\s*0\s*$|^\s*[1-9]\s*$|^\s*[3][0-1]\s*$/;

const FORM_INITIAL_VALUES = {
    LicenceInfo: {
        LicenceName: '',
        LicenceDescription: '',
        AgencyId: '',
        IsOnline: 'false',
        ExternalLink: '',
        FeeDescription: '',
        ProcessTimeDescription: '',
        LicencePrefix: '',
        RFAResponsePeriod: '',
        PaymentPeriod: '',
        TerminationMinPeriod: '',
        ValidityYear: '',
        ValidityMonth: '',
        ValidityDay: '',
        IsRenewable: 'false',
        RenewalPeriod: '',
        LatePenaltyPeriod: ''
        // EffectiveFromDate: '',
        // EffectiveToDate: ''
    },
    LicenceTypeId: '',
    FaqData: [],
    DependencyData: []
};


export default function LicenceConfigMgmtCreate() {
    const { t } = useTranslation()
    const errorInvalidNumber = (ERROR.LABEL + t(ERROR.VALID_NUMBER));

    const displayErrorInvalidNumber = (label) => {
        return `${label} ${t(ERROR.VALID_NUM_GREATER_ZERO)}`
    }

    const displayErrorMonthNumber = (label) => {
        return `${label} ${t(ERROR.VALID_NUM_ZERO_TO_TWELVE)}`
    }

    const displayErrorDayNumber = (label) => {
        return `${label} ${t(ERROR.VALID_NUM_ZERO_TO_THIRTYONE)}`
    }

    const FORM_VALIDATIONS = Yup.object().shape({
        LicenceInfo: Yup.object().shape({
            LicenceName: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED)).min(0, t(ERROR.MIN_LENGTH_LIMIT) + (ERROR.MIN_LENGTH_LABEL)),
            LicenceDescription: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED)).min(0, t(ERROR.MIN_LENGTH_LIMIT) + (ERROR.MIN_LENGTH_LABEL)),
            AgencyId: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED)).min(0, t(ERROR.MIN_LENGTH_LIMIT) + (ERROR.MIN_LENGTH_LABEL)).max(140, t(ERROR.MAX_LENGTH_LIMIT) + (ERROR.MAX_LENGTH_LABEL)),
            IsOnline: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED)),
            ExternalLink: Yup.string()
                .when("IsOnline", {
                    is: "false",
                    then: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED)).min(0, t(ERROR.MIN_LENGTH_LIMIT) + (ERROR.MIN_LENGTH_LABEL))
                }),
            FeeDescription: Yup.string()
                .when("IsOnline", {
                    is: "true",
                    then: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED))
                }),
            ProcessTimeDescription: Yup.string()
                .when("IsOnline", {
                    is: "true",
                    then: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED))
                        .matches(REGEX.INTEGER_POSITIVE_INCLUDE_ZERO, errorInvalidNumber)
                }),
            LicencePrefix: Yup.string()
                .when("IsOnline", {
                    is: "true",
                    then: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED)).min(0, t(ERROR.MIN_LENGTH_LIMIT) + (ERROR.MIN_LENGTH_LABEL)).max(3, t(ERROR.MAX_LENGTH_LIMIT) + (ERROR.MAX_LENGTH_LABEL))
                }),
            RFAResponsePeriod: Yup.string()
                .when("IsOnline", {
                    is: "true",
                    then: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED))
                        .matches(REGEX.INTEGER_POSITIVE_INCLUDE_ZERO, errorInvalidNumber)
                }),
            PaymentPeriod: Yup.string()
                .when("IsOnline", {
                    is: "true",
                    then: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED))
                        .matches(REGEX.INTEGER_POSITIVE_INCLUDE_ZERO, errorInvalidNumber)
                }),
            TerminationMinPeriod: Yup.string()
                .when("IsOnline", {
                    is: "true",
                    then: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED))
                        .matches(REGEX.INTEGER_POSITIVE_INCLUDE_ZERO, errorInvalidNumber)
                }),
            ValidityYear: Yup.string()
                .when("IsOnline", {
                    is: "true",
                    then: Yup.string().required(t(ERROR.YEAR_REQUIRED))
                        .matches(REGEX.INTEGER_POSITIVE_INCLUDE_ZERO, displayErrorInvalidNumber(t(LANGUAGE_KEYS.BLS_INTERNALLICENCEEXTENSION_FORM_FIELD_YEAR)))
                }),
            ValidityMonth: Yup.string()
                .when("IsOnline", {
                    is: "true",
                    then: Yup.string().required(t(ERROR.MONTH_REQUIRED))
                        .matches(REGEX_LESS_THAN_TWELVE, displayErrorMonthNumber(t(LANGUAGE_KEYS.BLS_INTERNALLICENCEEXTENSION_FORM_FIELD_MONTH)))
                }),
            ValidityDay: Yup.string()
                .when("IsOnline", {
                    is: "true",
                    then: Yup.string().required(t(ERROR.DAY_REQUIRED))
                        .matches(REGEX_LESS_THAN_THIRTY_ONE, displayErrorDayNumber(t(LANGUAGE_KEYS.BLS_INTERNALLICENCEEXTENSION_FORM_FIELD_DAY)))
                }),
            IsRenewable: Yup.string()
                .when("IsOnline", {
                    is: "true",
                    then: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED))
                }),
            RenewalPeriod: Yup.string()
                .when("IsRenewable", {
                    is: "true",
                    then: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED))
                        .matches(REGEX.INTEGER_POSITIVE_INCLUDE_ZERO, errorInvalidNumber)
                }),
            LatePenaltyPeriod: Yup.string()
                .when("IsRenewable", {
                    is: "true",
                    then: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED))
                        .matches(REGEX.INTEGER_POSITIVE_INCLUDE_ZERO, errorInvalidNumber)
                })
            // EffectiveFromDate: Yup.date().required((ERROR.LABEL) + t(ERROR.REQUIRED)),
            // EffectiveToDate: Yup.date().min(Yup.ref("EffectiveFromDate"), t(ERROR.EFFECTIVE_DATE_ERROR))
        })
    });

    const { LicenceType } = history.location.state;
    let LicenceTypeId, LicenceName, AgencyId;
    if (LicenceType) {
        LicenceTypeId = LicenceType.LicenceTypeId;
        LicenceName = LicenceType.LicenceName;
        AgencyId = LicenceType.AgencyId;
    }
    const previousPage = history.location.state.from;
    const [values, setValues] = useState(FORM_INITIAL_VALUES);
    // const minEffectiveFromDate = new Date();

    useEffect(() => {
        let isMounted = true;
        if (LicenceName && LicenceTypeId && isMounted) {
            setValues(prevValues => ({
                ...prevValues,
                LicenceInfo: {
                    ...prevValues.LicenceInfo,
                    LicenceName,
                    AgencyId
                },
                LicenceTypeId
            }));
        }
        return () => isMounted = false;
    }, [LicenceName, LicenceTypeId, AgencyId]);

    const submitCallback = ({ response }) => {
        if (response.success) {
            const { IsSuccess, Messages } = response.body;
            if (IsSuccess) {
                navigateTo(previousPage, { LicenceType: LicenceType });
                i18n.reloadResources();
            } else {
                toastError(t(getLangKey(ERROR.BACKEND_ERROR_MESSAGE, Messages)), Messages);
            }
        } else {
            toastError(t(ERROR.SERVER_UNREACHABLE));
        }
    };

    return (
        <React.Fragment>
            <Layout type={LAYOUT_TYPE.FORM} title={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_CONTENT_TITLE_NEWSERVICE)}>
                <SMARTForm
                    formContext={FORM_CONTEXT}
                    formValues={values}
                    validationSchema={FORM_VALIDATIONS}
                    validateOnSubmit={true}
                    serverURL={SERVER_URL}
                    isDummy={false}
                    submitCallback={submitCallback}
                    resetFormAfterSubmit={true}
                >
                    {({ values, errors, onChange, onChangeField, submitPlainForm, toggleSection, toggleAllSections, submitForm }) => {
                        const smartFormValues = {
                            toggleSection,
                            toggleAllSections,
                            values,
                            onChange,
                            onChangeField,
                            errors,
                            submitForm
                        };
                        return (
                            <React.Fragment>
                                <Container className="full-width">
                                    <Row className="body-content">
                                        <Col>
                                            <LicenceInformation
                                                smartFormValues={smartFormValues}
                                                isLicenceNameReadOnly={LicenceName && LicenceName !== ''}
                                                isAgencyReadOnly={AgencyId && AgencyId !== ''}
                                            />
                                            {values.LicenceInfo.IsOnline === 'true' &&
                                                <div>
                                                    <Dependency
                                                        smartFormValues={smartFormValues}
                                                    />
                                                    <Faq
                                                        smartFormValues={smartFormValues}
                                                    />
                                                </div>
                                            }
                                            {/* <Publish
                                                smartFormValues={smartFormValues}
                                                minEffectiveFromDate={minEffectiveFromDate}
                                            /> */}
                                        </Col>
                                    </Row>
                                </Container>

                                <PageFooter type={LAYOUT_TYPE.FORM}>
                                    <Button color="backward" size="sm" type="button" onClick={() => navigateTo(previousPage, { LicenceType: LicenceType })}>
                                        {t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_NAVIGATION_BUTTON_BACK)}
                                    </Button>
                                    <Button color="forward" size="sm" type="button" onClick={submitPlainForm}>
                                        {/* {t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_NAVIGATION_BUTTON_PUBLISH)} */}
                                        {t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_SUBMIT)}
                                    </Button>
                                </PageFooter>
                            </React.Fragment>
                        );
                    }}
                </SMARTForm>
            </Layout>
        </React.Fragment>
    );
}



