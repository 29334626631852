import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { GET_DOCUMENT_NAMES_URL } from '../../../../../App/AppSettings';
import { isValidForm } from '../../../../../Shared/Actions';
import { FIELD_SIZE } from '../../../../../Shared/Constants';
import { ERROR, getLangKey, LANGUAGE_KEYS } from '../../../../../Shared/Constants/LanguageKeys';
import { getParams, useAsyncFetch } from '../../../../../Shared/Fetch';
import { Paragraph, SelectList, toastError } from '../../../../../Shared/Forms';
import history from '../../../../../Shared/History';
import { ModalConfirm } from '../../../../../Shared/Modal';

const SUPPORTING_DOCS_URL = GET_DOCUMENT_NAMES_URL;

const MODAL_NAMES = {
    SUBMIT: 'Submit'
};

export default function SubmitClarification(props) {

    const { state } = history.location;
    const { t } = useTranslation();
    const { command, modal, isCheckOut, clearCommand } = props;

    const [supportingDocs, setSupportingDocsData] = useState([]);
    const [on, setOn] = useState(command && modal && isCheckOut);
    const { values, submitForm, onChange, onChangeField } = props.smartFormValues;
    const [remarksError, setRemarksError] = useState(false);
    const actionResponse = useAsyncFetch(SUPPORTING_DOCS_URL + state.ID, getParams());

    const FORM_VALIDATIONS = Yup.object().shape({
        Remarks: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED))
    });

    useEffect(() => {
        async function setSupportingDocs() {
            const { IsSuccess, Messages, Data } = actionResponse.body;
            if (IsSuccess) {
                setSupportingDocsData(Data);
            } else {
                // Error message to be loaded here
                toastError(t(getLangKey(ERROR.BACKEND_ERROR_MESSAGE, Messages)), Messages);
            }
        }

        if (actionResponse) {
            setSupportingDocs();
        }

        const toggle = () => setOn(!on);

        if (on) {
            modal.toggleModal(MODAL_NAMES.SUBMIT);
            toggle();
        }
    }, [actionResponse, on, modal, t]);

    const prepareToSubmit = async (modalInput, _submitForm) => {
        if (isValidForm(FORM_VALIDATIONS, values)) {
            const additionalDocuments = values.AdditionalDocuments;
            if (additionalDocuments !== undefined) {
                values.workflowParams = { 'AdditionalDocuments': JSON.stringify(additionalDocuments) };
            }
            values.command = command;
            setRemarksError(false);
            modalInput.toggleModal(MODAL_NAMES.SUBMIT);
            _submitForm();
        } else {
            setRemarksError(true);
        }
    };

    const displayModalContent = () => {
        const supportingDocValues = [];
        supportingDocs.forEach(doc => {
            supportingDocValues.push({ label: t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_GLOBALSUPPORTINGDOC_DOCNAME_KEY, doc.toLowerCase())), value: doc });
        });

        return (
            <>
                <Paragraph
                    name='Remarks'
                    value={values.Remarks}
                    placeholder={t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_TABLE_INFORMATION_REMARKS)}
                    onChange={onChange}
                    rows={3}
                    minLength={0}
                    maxLength={140}
                    inputSize={12}
                    labelSize={FIELD_SIZE.XSMALL}
                    label={t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_TABLE_INFORMATION_REMARKS)}
                    error={!values['Remarks'] && remarksError ? t(ERROR.REMARKS_REQUIRED) : ''}
                    required
                />
                <SelectList
                    name='AdditionalDocuments'
                    value={values.AdditionalDocuments}
                    options={supportingDocValues}
                    onChangeField={onChangeField}
                    isMulti={true}
                    labelSize={12}
                    inputSize={12}
                    label={t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_CONTENT_TITLE_ADDITIONALSUPPORTINGDOCUMENTS)}
                />
            </>
        );
    };

    return (
        <>
            {modal &&
                <ModalConfirm
                    className='modal-remarks'
                    isOpen={modal.modalState === MODAL_NAMES.SUBMIT}
                    contentHeader={t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_CONTENT_TITLE_REQUESTFORCLARIFICATION)}
                    confirmText={t(LANGUAGE_KEYS.BLS_INTERNALGENERAL_FORM_BUTTON_CONFIRM)}
                    confirmCallback={() => { prepareToSubmit(modal, submitForm); }}
                    cancelText={t(LANGUAGE_KEYS.BLS_INTERNALGENERAL_FORM_BUTTON_CANCEL)}
                    cancelCallback={() => {
                        modal.toggleModal(MODAL_NAMES.SUBMIT);
                        setTimeout(function () { clearCommand(); }, 1000);
                    }}
                    contentBody={
                        displayModalContent()
                    }
                />
            }
        </>
    );
}
