/* 
This file is used to import and organise css files so that they will be rendered in the correct order 

React will render every single css and js file regardless of which page you go to. 

We found that if we insert the css files directly into the components or pages, 
not only will there be multiple versions of the css file, the order at which these files are rendered
are random an inconsistent.

By putting the imports all in one file, we are better able to control the order and number of copies.
*/

import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/antd.css';

import 'react-s-alert/dist/s-alert-css-effects/slide.css';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-widgets/dist/css/react-widgets.css';
import 'react-vis/dist/style.css';

/* Base css */
// import '../Css/all.min.css';
import '../App/App.css';
import '../App/Fonts.css';

/* Shared css*/
import '../Shared/CustomCards/CustomCards.css'
import '../Shared/CustomCarousel/CustomCarousel.css';
import '../Shared/DataTable/DataTable.css';
import '../Shared/Error/Error.css';
import '../Content/FormComponents/FileUploadSection/FileUploadSection.css';
import '../Shared/Forms/Forms.css';
import '../Shared/Forms/SMARTListGroup.css';
import '../Shared/Loading/Loading.css';
import '../Shared/Modal/Modal.css';
import '../Shared/PageFooter/PageFooter.css';
import '../Shared/DashboardSearch/DashboardSearch.css';
import '../Shared/Forms/RichTextEditor.css'
import '../Shared/Tree/Tree.css';

/* Component css*/
import '../Footer/Footer.css';
import '../Header/Header.css';
import '../Navbar/Layout1D.css';
import '../Navbar/Layout1M.css';
import '../Profile/Profile.css';
import '../Profile/Profile.rtl.css';
import '../Section/Section.css';
import '../Title/Title.css';

/* Content css*/
import '../Content/Content.css';
import '../Content/Dashboard/Dashboard.css';
import '../Content/InternalEnquiryList/Enquiry.css';
import '../Content/InternalInboxList/Inbox.css';
import '../Content/WorkingHour/WorkConfigCreate.css';
import '../Content/WorkingHour/WorkingHourConfig.css';
import '../Content/NotificationTemplate/NotificationTemplate.css';
import '../Content/LicenceAppConfig/FormComponents/Application.css';
import '../Content/LicenceAppConfig/View/Application.css';
import '../Content/NetworkGraph/index.css'
import '../Content/NetworkGraph/bls.css'
import '../Content/NetworkGraph/bls.rtl.css'
import '../Content/LicenceConfig/index.css'

/** Insert custom css here*/
import '../Content/LanguageList/LanguageList.css'
import './InternalCustom.css'
import './InternalCustom.rtl.css'
import './custom-changes.css'
import '../Content/Faq/Faq.css'
/* End of custom css */

class Css extends React.Component {
    render() {
      return (
        <React.Fragment></React.Fragment>
      );
    }
  }

export default Css;