import { Row, Col, Button, Input, Label } from "reactstrap";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

function Value(props) {
    const [dataSourceValues, setDataSourceValues] = useState(props.dataSourceValues);

    function addKeyValuePairOnClick() {
        const newKeyValuePair = {
            id: dataSourceValues.length,
            key: "",
            value: ""
        };
        setDataSourceValues([...dataSourceValues, newKeyValuePair]);
    }

    function keyOnChange(event, keyValuePair) {
        keyValuePair.key = event.target.value;
        const newDataSourceValues = dataSourceValues.map((existingKeyValuePair) => {
            if (existingKeyValuePair.id === keyValuePair.id)
                return keyValuePair;
            else 
                return existingKeyValuePair;
        });
        setDataSourceValues(newDataSourceValues);
    }

    function valueOnChange(event, keyValuePair) {
        keyValuePair.value = event.target.value;
        const newDataSourceValues = dataSourceValues.map((existingKeyValuePair) => {
            if (existingKeyValuePair.id === keyValuePair.id)
                return keyValuePair;
            else 
                return existingKeyValuePair;
        });
        setDataSourceValues(newDataSourceValues);
    }

    useEffect(() => {
        props.onValueAdd(dataSourceValues);
    }, 
    // eslint-disable-next-line 
    [dataSourceValues]);

    return <div>
        <Row className="mb-3">
            <Col xs="auto">
                <Label className="middle-title">Add Values</Label>
            </Col>
            <Col xs="auto">
                <Button color="primary" onClick={addKeyValuePairOnClick} outline>
                    <FontAwesomeIcon icon={faPlus} />
                </Button>
            </Col>
        </Row>
        {dataSourceValues.map((keyValuePair, i) => {
            return <Row key={i} className="mb-2">
                <Col>
                    <Input placeholder="Key" value={keyValuePair.key} onChange={event => keyOnChange(event, keyValuePair)}></Input>
                </Col>
                <Col>
                    <Input placeholder="Value" value={keyValuePair.value} onChange={event => valueOnChange(event, keyValuePair)}></Input>
                </Col>
            </Row>
        })}
    </div>
}

export default Value;