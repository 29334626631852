import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import {
    ADDITIONAL_APPLICATION_USER_ROLES_LIST,
    ADDITIONAL_APPLICATION_WORKFLOW_SCHEME_NAME_LIST,
    LAYOUT_TYPE,
    LICENCE_APP_CONFIG_GET_LICENCE_APP_CONFIG_BY_FORM_ID
} from '../../../App/AppSettings';
import Layout from '../../../Layout';
import Section from '../../../Section';
import SectionWrapper from '../../../SectionWrapper';
import { fetchRequest, navigateTo } from '../../../Shared/Actions';
import { SECTION_WRAPPER_TYPES } from '../../../Shared/Constants';
import { getParams, useAsyncFetch } from '../../../Shared/Fetch';
import { SMARTForm } from '../../../Shared/Forms';
import history from '../../../Shared/History';
import PageFooter from '../../../Shared/PageFooter';
import LicenceAppConfigAdditionalInformation from './AdditionalInformation';
import LicenceAppConfigApplication from './Application';
import LicenceAppConfigDocument from './Document';
import LicenceAppConfigPayment from './Payment';
import Publish from './Publish';
import { useTranslation } from 'react-i18next';
import { LANGUAGE_KEYS } from '../../../Shared/Constants/LanguageKeys';

const FORM_CONTEXT = 'AppConfigView';

export default function LicenceAppConfigMgmtView() {
    const { t } = useTranslation();
    const [values, setValues] = useState(null);
    const previousPage = history.location.state.from;
    const { LicenceAppConfig } = history.location.state;
    const { Id } = LicenceAppConfig;
    const params = {
        formDefinitionId: Id
    };

    const WIZARD_TAB_NAMES = {
        APPLICATION_DETAILS: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_MENU_APPLICATIONDETAILS),
        APPLICATION_DETAILS_OFFICER: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_MENU_APPLICATIONDETAILSOFFICER),
        LICENCE_DOCUMENT: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_MENU_SERVICEDOCUMENT),
        PAYMENT_INFORMATION: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_MENU_PAYMENTINFORMATION),
        PUBLISH: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_MENU_PUBLISH)
    };

    const response = useAsyncFetch(LICENCE_APP_CONFIG_GET_LICENCE_APP_CONFIG_BY_FORM_ID, getParams(params));
    // Pre-set URL(API) for both workflow and user roles that will be used to retrieved from wrokflow controller
    const additionalApplicationWorkflowURL = `${ADDITIONAL_APPLICATION_WORKFLOW_SCHEME_NAME_LIST}schemeCode=`;
    const additionalApplicationUserRolesURL = `${ADDITIONAL_APPLICATION_USER_ROLES_LIST}schemeCode=`;

    //Massage the data to include Id
    useEffect(() => {
        const fetchData = async () => {
            const { Data, IsSuccess } = response.body;
            if (response.success && IsSuccess) {
                Data.ApplicationData.IsDeclarationRequired = Data.ApplicationData.DeclarationData.length > 0 ? 'true' : 'false';
                Data.ApplicationData.IsFormDataNeededByInternalWorkflowScheme = Data.ApplicationData.IsFormDataNeededByInternalWorkflowScheme.toString();
                Data.ApplicationData.IsFormDataNeededByPublicWorkflowScheme = Data.ApplicationData.IsFormDataNeededByPublicWorkflowScheme.toString();
                Data.ApplicationData.IsUsingFormIO = Data.ApplicationData.IsUsingFormIO.toString();
                Data.ApplicationData.IsMappingToCRM = Data.ApplicationData.IsMappingToCRM.toString();
                let count = 0;
                Data.ApplicationData.SupportingDocumentData.forEach(e => {
                    e.IsExistingSupportingDocument = e.IsExistingSupportingDocument.toString();
                    e.IsMandatorySupportingDocument = e.IsMandatorySupportingDocument.toString();
                    e.Id = count;
                    count++;
                });
                count = 0;
                // Get all workflow state from workflow controller
                const additionalApplicationWorkflowResponse = await fetchRequest(`${additionalApplicationWorkflowURL}${Data.ApplicationData.InternalWorkflowScheme}`, getParams(), false);
                // Get all user roles from workflow state controller
                const additionalApplicationUserRolesResponse = await fetchRequest(`${additionalApplicationUserRolesURL}${Data.ApplicationData.InternalWorkflowScheme}`, getParams(), false);
                // Get Additional Information Form Fields
                Data.ApplicationOfficerData.AdditionalApplicationFieldsData && Data.ApplicationOfficerData.AdditionalApplicationFieldsData.forEach(additionalFormData => {
                    additionalFormData.SupportingDocumentData && additionalFormData.SupportingDocumentData.forEach(s => {
                        s.IsMandatorySupportingDocument = s.IsMandatorySupportingDocument.toString();
                    });
                    if (additionalApplicationWorkflowResponse.success && additionalApplicationWorkflowResponse.body.IsSuccess) {
                        const workflowData = additionalApplicationWorkflowResponse.body.Data;
                        additionalFormData.WorkflowStateName = workflowData.find(element => additionalFormData.WorkflowStateId === element.WorkflowStateId) ?
                            workflowData.find(element => additionalFormData.WorkflowStateId === element.WorkflowStateId).WorkflowStateName :
                            null;
                    }
                    if (additionalApplicationUserRolesResponse.success && additionalApplicationUserRolesResponse.body.IsSuccess) {
                        const UserRoles = [];
                        const userRolesData = additionalApplicationUserRolesResponse.body.Data;
                        additionalFormData.UserRoleGUID.forEach(userRole => {
                            UserRoles.push(userRolesData.find(element => userRole === element.UserRoleGUID).UserRoleName);
                        });
                        additionalFormData.UserRoleName = UserRoles;
                    }
                    additionalFormData.RowId = count;
                    count++;
                });
                Data.LicenceDocumentData.forEach(e => {
                    e.RowId = count;
                    if (additionalApplicationWorkflowResponse.success && additionalApplicationWorkflowResponse.body.IsSuccess) {
                        const workflowData = additionalApplicationWorkflowResponse.body.Data;
                        const workflowState = workflowData.find(element => e.WorkflowStateId === element.WorkflowStateId);
                        e.WorkflowStateName = workflowState ? workflowState.WorkflowStateName : null;
                    }
                    count++;
                });
                count = 0;
                setValues(Data);
            }
        };
        if (response) {
            fetchData();
        }
    }, [response, additionalApplicationWorkflowURL, additionalApplicationUserRolesURL]);

    return (
        <React.Fragment>
            <Layout type={LAYOUT_TYPE.FORM} title={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_TITLE_VIEWSERVICEAPPLICATION)}>
                <SMARTForm
                    className="container"
                    formContext={FORM_CONTEXT}
                    sectionNames={WIZARD_TAB_NAMES}
                    defaultSection={WIZARD_TAB_NAMES.APPLICATION_DETAILS}
                    formValues={values}
                    validateOnSubmit={true}
                >
                    {({ toggleSection, toggleAllSections, sectionState, onChange, values, currentStep, prevStep, nextStep }) => {
                        const smartFormValues = {
                            toggleSection: toggleSection,
                            toggleAllSections: toggleAllSections,
                            values: values,
                            onChange: onChange
                        };
                        return (
                            <React.Fragment>
                                <Row className="body-content">
                                    {values &&
                                        <Col>
                                            <SectionWrapper
                                                type={SECTION_WRAPPER_TYPES.WIZARD}
                                                tabs={WIZARD_TAB_NAMES}
                                                sectionState={sectionState}
                                                toggleSection={toggleSection}>
                                                <Section
                                                    type={SECTION_WRAPPER_TYPES.WIZARD}
                                                    sectionState={sectionState}
                                                    toggleSection={toggleSection}
                                                    sectionName={WIZARD_TAB_NAMES.APPLICATION_DETAILS}
                                                    key={WIZARD_TAB_NAMES.APPLICATION_DETAILS}
                                                    container={false}>
                                                    <LicenceAppConfigApplication
                                                        smartFormValues={smartFormValues}
                                                    />
                                                </Section>
                                                <Section
                                                    type={SECTION_WRAPPER_TYPES.WIZARD}
                                                    sectionState={sectionState}
                                                    toggleSection={toggleSection}
                                                    sectionName={WIZARD_TAB_NAMES.APPLICATION_DETAILS_OFFICER}
                                                    key={WIZARD_TAB_NAMES.APPLICATION_DETAILS_OFFICER}
                                                    container={false}>
                                                    <LicenceAppConfigAdditionalInformation
                                                        smartFormValues={smartFormValues}
                                                    />
                                                </Section>
                                                <Section
                                                    type={SECTION_WRAPPER_TYPES.WIZARD}
                                                    sectionState={sectionState}
                                                    toggleSection={toggleSection}
                                                    sectionName={WIZARD_TAB_NAMES.LICENCE_DOCUMENT}
                                                    key={WIZARD_TAB_NAMES.LICENCE_DOCUMENT}
                                                    container={false}>
                                                    <LicenceAppConfigDocument
                                                        smartFormValues={smartFormValues}
                                                    />
                                                </Section>
                                                <Section
                                                    type={SECTION_WRAPPER_TYPES.WIZARD}
                                                    sectionState={sectionState}
                                                    toggleSection={toggleSection}
                                                    sectionName={WIZARD_TAB_NAMES.PAYMENT_INFORMATION}
                                                    key={WIZARD_TAB_NAMES.PAYMENT_INFORMATION}
                                                    container={false}>
                                                    <LicenceAppConfigPayment
                                                        smartFormValues={smartFormValues}
                                                    />
                                                </Section>
                                                <Section
                                                    type={SECTION_WRAPPER_TYPES.WIZARD}
                                                    sectionState={sectionState}
                                                    toggleSection={toggleSection}
                                                    sectionName={WIZARD_TAB_NAMES.PUBLISH}
                                                    key={WIZARD_TAB_NAMES.PUBLISH}
                                                    container={false}>
                                                    <Publish
                                                        smartFormValues={smartFormValues}
                                                    />
                                                </Section>
                                            </SectionWrapper>
                                        </Col>
                                    }
                                </Row>
                                <PageFooter type={LAYOUT_TYPE.FORM}>
                                    <div>
                                        {/* Wizard Previous Button */}
                                        {currentStep !== 0 && <Button color="backward" size="sm" type="button" onClick={prevStep}>{t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_NAVIGATION_BUTTON_PREVIOUS)}</Button>}
                                        {/* Cancel Button */}
                                        {currentStep === 0 &&
                                            <Button
                                                color="backward"
                                                size="sm" type="button"
                                                onClick={() => navigateTo(previousPage, { LicenceAppConfig })}
                                            >
                                                {t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_CANCEL)}
                                            </Button>
                                        }
                                        {/* Wizard Next Button */}
                                        {currentStep !== Object.keys(WIZARD_TAB_NAMES).length - 1 &&
                                            <Button
                                                color="forward"
                                                size="sm"
                                                type="button"
                                                onClick={nextStep}
                                            >
                                                {t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_NAVIGATION_BUTTON_NEXT)}
                                            </Button>
                                        }
                                    </div>
                                </PageFooter>
                            </React.Fragment>
                        );
                    }
                    }
                </SMARTForm>
            </Layout>

        </React.Fragment>
    );
}



