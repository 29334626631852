// Const: Variable Length for Field/Label
export const FIELD_SIZE = {
    NONE: 0,
    XSMALL: 2,
    SMALL: 3,
    MEDIUM: 5,
    LARGE: 7,
    XLARGE: 9,
    MAX: 12
};

// Const: Field/Label Orientation
export const ORIENTATION = {
    ROW: 'ROW',
    INLINE: 'INLINE'
};

// Const: Filters
export const FILTER = "Filter";
export const SELECT_FILTERS = "SelectFilters";
export const SORT_COLUMN = "SortColumn";
export const SORT_DIR = "SortDir";
export const SORT_BY_KEYS = "SortByKeys";

// Const: Response Object
export const Response = (responseData, isSuccess = false, status = 200) => {
    return {
        success: isSuccess,
        body: responseData,
        status: status,
    }
};

export const FILE_UPLOAD_SECTION_FILES = "FileUploadSectionFiles";

export const DELETE_FILES_ARRAY = "SMARTDeleteFilesArray";

export const BLS_FORM_TYPES = {
    SPF_LIQUOR_1TO4: 'SpfLiquor1To4',
    URA_CHANGE_USE_APPROVAL: 'UraChangeUseApproval'
}

export const SECTION_WRAPPER_TYPES = {
    WIZARD: "Wizard",
    TAB: "Tab",
    ACCORDION: "Accordion",
    ACCORDION_MODAL: "AccordionModal"
}

export const BLS_VERSION_STATUS = {
    ACTIVE: "Active"
}
export const PARAGRAPH_TYPES = {
    DEFAULT: 'DEFAULT',
    WITH_ICON: 'WITH_ICON'
};

export const DIRECTIONS = {
    RTL: 'rtl',
    LTR: 'ltr',
    AUTO: 'auto'
}

//Const: Rich text toolbar
export const RICHTEXT = {
    DEFAULT: {
        options: ['inline', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'image'],
    }
}

export const DATE_TIME_FORMAT = {
    DD_MM_YYYY: 'DD-MMM-YYYY',
    DEFAULT_MIN_DATE: '01-01-1900',
    DEFAULT_EMPTY_DATE: '-'
};

export const PREVIEW_SERVICE_STATUS = {
    DRAFT: 'Draft',
    STAGING: 'Staging',
    PRODUCTION: 'Production'
};

export const SELF_SERVICE_MODULE_TYPE = {
    LICENCE_CONFIGURATION: 'LicenceConfiguration',
    LICENCE_APPLICATION_CONFIGURATION: 'LicenceApplicationConfiguration'
};
