import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import { GET_NOTIFICATION_TEMPLATE_URL, LAYOUT_TYPE, NOTIFICATION_TEMPLATE_SMS_LIST } from '../../App/AppSettings';
import Layout from '../../Layout';
import { ERROR } from '../../Localization/index';
import { fetchRequest, getParams, navigateTo } from '../../Shared/Actions';
import { FIELD_SIZE } from '../../Shared/Constants';
import { Plaintext, Hidden, RichTextEditor, SMARTForm, withSMARTWrapper } from '../../Shared/Forms';
import { DEFAULT_TEXT, DEFAULT_NAMESPACE } from '../../Shared/Constants/LanguageKeys.js';
import { toastError } from '../../Shared/Forms/Toaster.js';
import history from '../../Shared/History';
import PageFooter from '../../Shared/PageFooter';
import { RetrieveGlobalVariables } from './RetrieveGlobalVariables';

const FORM_CONTEXT = "SMSTemplateView";

const RICHTEXT_TOOL_BAR = { options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'image', 'remove', 'history'] };

const FORM_INITIAL_VALUES = {
    TemplateName: '',
    Category: '',
    Type: 'SMS',
    TemplateSubjectHTML: '',
    TemplateSubject: '',
    TemplateBody: '',
    TemplateBodyHTML: '',
};

class SmsTemplateView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            values: FORM_INITIAL_VALUES,
            dropDownArrayCategory: [],
            globalVariableList: [],
            globalVariableListIsLoaded: false
        };
    }

    async componentDidMount() {
        const { state } = history.location;
        const { loader } = this.props;
        
        if (state && state.ID) {
            loader.start();
            const response = await fetchRequest(GET_NOTIFICATION_TEMPLATE_URL + state.ID, getParams(), false);
            loader.done();

            const { IsSuccess, Data, Message, MessageLanguageKey } = response.body;
            if (response.success && IsSuccess) {
                Data.HTMLTemplateSubject = Data.TemplateSubject;
                if (Data.Category === null) {
                    Data.Category = '';
                }
                this.setState({ values: Data });
            }
            else {
                // Error message to be loaded here
                loader.error(this.props.getMessage(MessageLanguageKey, Message));
            }
            await this.getGlobalVariables();
        } else {
            toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
        }
    }

    getGlobalVariables = async () => {
        this.setState({
            globalVariableList: await RetrieveGlobalVariables(),
            globalVariableListIsLoaded: true
        })
    }

    render() {
        let { globalVariableList, globalVariableListIsLoaded } = this.state;
        return (
            <React.Fragment>
                <Layout type={LAYOUT_TYPE.FORM} title=
                    {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_NOTITEMPLATESTATIC_CONTENT_TITLE_VIEWSMSTEMPLATE, DEFAULT_TEXT.VIEW_SMS_TEMPLATE)} >
                    <SMARTForm
                        formContext={FORM_CONTEXT}
                        formValues={this.state.values}
                        isDummy={false}
                    >
                        {({ values, onChangeField }) => (
                            <React.Fragment>
                                <Row className="body-content admin-content-wrapper">
                                    <Col>
                                    <Plaintext
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_NOTITEMPLATESTATIC_FORM_TITLE_TEMPLATENAME, DEFAULT_TEXT.CREATED_DATE)}
                                            name="TemplateName"
                                            value={values.TemplateName}
                                            inputSize={FIELD_SIZE.XLARGE}
                                            labelSize={FIELD_SIZE.XSMALL}
                                        />
                                        <Plaintext
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_NOTITEMPLATESTATIC_FORM_TITLE_TYPE, DEFAULT_TEXT.CREATED_DATE)}
                                            name="Type"
                                            value={values.Type}
                                            inputSize={FIELD_SIZE.XLARGE}
                                            labelSize={FIELD_SIZE.XSMALL}
                                        />
                                        <Plaintext
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_NOTITEMPLATESTATIC_FORM_TITLE_CATEGORY, DEFAULT_TEXT.CREATED_DATE)}
                                            name="Category"
                                            value={values.Category}
                                            inputSize={FIELD_SIZE.XLARGE}
                                            labelSize={FIELD_SIZE.XSMALL}
                                        />
                                        {globalVariableListIsLoaded &&
                                            <div>
                                                <RichTextEditor
                                                    name="TemplateSubjectHTML"
                                                    value={values.TemplateSubjectHTML}
                                                    onChangeField={onChangeField}
                                                    inputSize={FIELD_SIZE.XLARGE}
                                                    labelSize={FIELD_SIZE.XSMALL}
                                                    label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_NOTITEMPLATESTATIC_FORM_TITLE_TEMPLATESUBJECT, DEFAULT_TEXT.TEMPLATE_SUBJECT)}
                                                    showHtml={false}
                                                    savePlaintext={true}
                                                    plaintextName="TemplateSubject"
                                                    editorClassName="Email-TemplateSubject-demo-editor"
                                                    wrapperClassName="Email-TemplateSubject-demo-wrapper"
                                                    toolbarHidden={true}
                                                    suggestions={globalVariableList}
                                                    readOnly={true}
                                                />
                                                <Hidden
                                                    className="d-none"
                                                    name="TemplateSubject"
                                                    value={values.TemplateSubject}
                                                />
                                                <RichTextEditor
                                                    name="TemplateBodyHTML"
                                                    value={values.TemplateBodyHTML}
                                                    onChangeField={onChangeField}
                                                    inputSize={FIELD_SIZE.XLARGE}
                                                    labelSize={FIELD_SIZE.XSMALL}
                                                    label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_NOTITEMPLATESTATIC_FORM_TITLE_TEMPLATEBODY, DEFAULT_TEXT.TEMPLATE_BODY)}
                                                    showHtml={false}
                                                    savePlaintext={true}
                                                    editorClassName="Email-TemplateBody-demo-editor"
                                                    plaintextName="TemplateBody"
                                                    toolbar={RICHTEXT_TOOL_BAR}
                                                    suggestions={globalVariableList}
                                                    readOnly={true}
                                                />
                                            </div>
                                        }

                                    </Col>
                                </Row>

                                <PageFooter type={LAYOUT_TYPE.FORM}>
                                    <Button color="backward" size="sm" type="button" onClick={() => navigateTo(NOTIFICATION_TEMPLATE_SMS_LIST)}>
                                        {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_NOTITEMPLATESTATIC_NAVIGATION_BUTTON_BACK, DEFAULT_TEXT.BACK)}
                                    </Button>
                                </PageFooter>

                            </React.Fragment>
                        )}
                    </SMARTForm>
                </Layout>
            </React.Fragment>
        );
    }
}

export default withSMARTWrapper(SmsTemplateView);