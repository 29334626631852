import React from 'react'
import AuthHelper from './AuthHelper.js';
import { GET_USER_PERMISSION_URL } from '../../App/AppSettings';
import { IDENTITY_URL } from '../../Shared/SmartMe/Constants';
import { fetchRequest, getParams } from '../../Shared/Actions';
import { toastError } from '../../Shared/Forms';
import { withTranslation } from 'react-i18next';
import { ERROR } from '../Constants/LanguageKeys.js';
import { getLangKey } from '../../Content/DisplayComponents/DisplayUtils.js';

const AuthContext = React.createContext()

const GET_USER_ACCOUNT_SETTINGS_URL = IDENTITY_URL + '/Account/GetUserAccountSettings';
const IS_USER_SECURITY_QNS_PRESENT_URL = IDENTITY_URL + '/Account/IsSecurityQuestionsPresent/';

class AuthProvider extends React.Component {
  constructor() {
    super()
    this.mgr = AuthHelper.getManager();

    this.state = {
      isAuthenticated: false,
      profile: null,
      roles: [],
      permissions: [],
      isReady: false
    }

  }

  async getUserPermissions() {
    const { t } = this.props;
    const response = await fetchRequest(GET_USER_PERMISSION_URL, getParams());
    const { IsSuccess, Data, Messages } = response.body;
    if (response.success && IsSuccess) {
      return Data;
    }
    else {
      toastError(t(getLangKey(ERROR.BACKEND_ERROR_MESSAGE, Messages)), Messages);
    }
  }

  async getUserAccountSettings() {
    const { t } = this.props;
    const response = await fetchRequest(GET_USER_ACCOUNT_SETTINGS_URL, getParams());
    const { IsSuccess, Data, Messages } = response.body;
    if (response.success && IsSuccess) {
      return Data;
    }
    else {
      toastError(t(getLangKey(ERROR.BACKEND_ERROR_MESSAGE, Messages)), Messages);
    }
  }

  async isSecurityQuestionsPresent(userId) {
    const { t } = this.props;
    const response = await fetchRequest(IS_USER_SECURITY_QNS_PRESENT_URL + userId, getParams());
    const { IsSuccess, Data, Messages } = response.body;
    if (response.success && IsSuccess) {
      return Data;
    }
    else {
      toastError(t(getLangKey(ERROR.BACKEND_ERROR_MESSAGE, Messages)), Messages);
    }
  }

  componentWillMount() {
    let self = this;

    this.mgr.getUser().then(async function (user) {
      let isAuth = !(typeof user === 'undefined' || user === null);
      self.setState({ isAuthenticated: isAuth })

      if (isAuth) {
        self.setState({
          userAccountSettings: await self.getUserAccountSettings(),
          isSecurityQuestionsPresent: await self.isSecurityQuestionsPresent(user.profile.sub),
          profile: user.profile,
          permissions: await self.getUserPermissions()
        });
      }

      self.setState({
        isReady: true
      });
    });
  }

  login = (returnUrl) => {
    this.mgr.signinRedirect({
      state: {
        returnUrl: returnUrl,
        referrerAppUrl: document.referrer
      }
    });
  }

  logout = (e) => {
    e.preventDefault();
    this.setState({
      isAuthenticated: false,
      profile: null
    });
    this.mgr.signoutRedirect();
  }

  render() {
    return (this.state.isReady ?
      <AuthContext.Provider
        value={{
          isAuthenticated: this.state.isAuthenticated,
          userAccountSettings: this.state.userAccountSettings,
          isSecurityQuestionsPresent: this.state.isSecurityQuestionsPresent,
          profile: this.state.profile,
          permissions: this.state.permissions,
          login: this.login,
          logout: this.logout
        }}
      >
        {this.props.children}
      </AuthContext.Provider> :
      <AuthContext.Provider></AuthContext.Provider>
    )
  }
}

export const AuthConsumer = AuthContext.Consumer

const AuthProviderHOC = withTranslation()(AuthProvider);
export { AuthProviderHOC as AuthProvider}