const initialState = {};

function rootReducer(state = initialState, action) {
    
    if (action.type === "NAVBAR_TOGGLE") {
        console.log("Reducer saw action!");
        console.log("Value of toggle:" + action.toggleOn);
    }
    
    return state;
};

export default rootReducer;