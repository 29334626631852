import { faEdit, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Col, Row } from 'reactstrap';
import { GET_SUBCATEGORY_LIST_URL, LAYOUT_TYPE, EDIT_SUBCATEGORY_SYSTEM_URL, VIEW_SUBCATEGORY_SYSTEM_URL, ADD_SUBCATEGORY_SYSTEM_URL } from '../../App/AppSettings';
import Layout from '../../Layout';
import { fetchRequest, getParams, navigateTo } from '../../Shared/Actions';
import { DEFAULT_TEXT, DEFAULT_NAMESPACE } from '../../Shared/Constants/LanguageKeys.js';
import { withSMARTWrapper } from '../../Shared/Forms';
import { ActionButton, DataTable } from '../../Shared/DataTable';
import { getCustomComponents } from '../../Shared/DataTable/BaseTable.js';
import { withLoader } from '../../Shared/Loading';
import './LanguageList.css';


class SubCategoryManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            width: window.innerWidth,
            data: []
        };
    }

    componentDidMount = async () => {
        this.props.loader.start();
        const response = await fetchRequest(GET_SUBCATEGORY_LIST_URL, getParams());
        const { Data, IsSuccess } = response.body;
        if (IsSuccess) {
            this.setState({
                data: Data,
            });
        }
        this.props.loader.done();
    }

    componentWillMount = () => {
        window.addEventListener('resize', this.handleWindowSizeChange);
    };

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    };


    renderLayout = () => {
        return (
            <DataTable
                pageSize="10"
                minFilterChars='2'
                data={this.state.data}
                noResultsMessage={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_TABLE_TEXT_SUBCATEGORYNORECORD, DEFAULT_TEXT.SUBCATEGORY_NO_RECORD)}
                components={getCustomComponents()}
                columns={
                    {
                        RunningNumber: { title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_CONTENT_TABLE_NO, DEFAULT_TEXT.NUM), width: '5%', DBkey: 'Id' },
                        Actions: { title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_CONTENT_TABLE_ACTIONS, DEFAULT_TEXT.ACTIONS), width: '20%' },
                        Name: { title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_CONTENT_TABLE_NAME, DEFAULT_TEXT.NAME), width: '30%', DBkey: 'Name' },
                        Description: { title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_CONTENT_TABLE_DESCRIPTION, DEFAULT_TEXT.DESCRIPTION), width: '45%', DBkey: 'Description' },
                    }
                }
                renderActions={(data) => (
                    <React.Fragment>
                        <ActionButton
                            tooltip={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_TABLE_TOOLTIP_EDIT, DEFAULT_TEXT.EDIT)}
                            color="forward"
                            onClick={() => navigateTo(EDIT_SUBCATEGORY_SYSTEM_URL, { data: data })}>
                            <FontAwesomeIcon icon={faEdit} />
                        </ActionButton>
                        <ActionButton
                            tooltip={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_TABLE_TOOLTIP_VIEW, DEFAULT_TEXT.VIEW)}
                            color="neutral"
                            onClick={() => navigateTo(VIEW_SUBCATEGORY_SYSTEM_URL, { data: data })}>
                            <FontAwesomeIcon icon={faEye} />
                        </ActionButton>
                    </React.Fragment>
                )}
            />
        );
    }


    handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
    };


    render() {
        return (
            <>
                <Layout type={LAYOUT_TYPE.FORM} title=
                    {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_CONTENT_TITLE_SUBCATEGORYMANAGEMENT, DEFAULT_TEXT.SUB_CATEGORY_MANAGEMENT)}>
                    <Row className="body-content admin-content-wrapper">
                        <Col>
                            <Col className='text-right remove-padding'>
                                <ActionButton className='language-btn' onClick={() => navigateTo(ADD_SUBCATEGORY_SYSTEM_URL)}>
                                    {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_NAVIGATION_BUTTON_NEWSUBCATEGORY, DEFAULT_TEXT.NEW_SUB_CATEGORY)}
                                </ActionButton>
                            </Col>
                            {this.renderLayout()}
                        </Col>
                    </Row>
                </Layout>

            </>
        )
    }
}

export default withSMARTWrapper(withLoader(SubCategoryManagement));

