import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GET_FORM_APPLICATION_INFO_URL } from '../../../App/AppSettings';
import { ERROR } from '../../../Shared/Constants/LanguageKeys';
import { getParams, useAsyncFetch } from '../../../Shared/Fetch';
import { toastError } from '../../../Shared/Forms/Toaster.js';
import history from '../../../Shared/History';
import { getLangKey } from '../../DisplayComponents/DisplayUtils';

export default function ApplicationInfo(props) {

    const { state } = history.location;
    const [formData, setFormData] = useState(undefined);
    const [isFormDataLoaded, setFormDataLoaded] = useState(false);

    const response = useAsyncFetch(GET_FORM_APPLICATION_INFO_URL + state.ID, getParams());
    const { t } = useTranslation();

    useEffect(() => {
        async function setData() {
            const { IsSuccess, Messages, Data } = response.body;
            if (response.success && IsSuccess) {
                let { FormData } = Data;
                let retrievedValues = JSON.parse(FormData);

                FormData = Object.assign(Data, retrievedValues);
                setFormData(FormData);
                setFormDataLoaded(true);
            }
            else {
                // Error message to be loaded here
                toastError(t(getLangKey(ERROR.BACKEND_ERROR_MESSAGE, Messages)), Messages);
            }
        }

        if (response) {
            setData()
        }
    }, [response, t])

    const ApplicationInfo = () => {
        const ApplicationInfo = props.applicationInfoComponent[formData.ApplicationType];

        return (
            <ApplicationInfo
                licenceInformation={formData.LicenceInformation}
                licenceDetails={formData.LicenceDetails}
                generalInformation={formData.GeneralInformation}
                toggleSection={props.toggleSection}
                sectionState={props.sectionState}
                sectionName={props.sectionName}
            />
        );
    }

    return (
        <>
            {isFormDataLoaded && <ApplicationInfo />}
        </>
    )
}