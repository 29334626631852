import React from 'react';
import Alert from 'react-s-alert';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faExclamationTriangle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

export const toastInfo = (message) => {
  Alert.closeAll();
  Alert.info(info(message), {
    position: 'top',
    timeout: null
  });
}

export const toastSuccess = (message, defaultmessage, component) => {

  let toastOptions = {
    position: 'top',
    timeout: null
  };

  Alert.closeAll();

  // Case: Component name given
  if (component) {
    // Fill in the name into template
    Alert.success(success(_.template(message)({ component })), toastOptions);
  }

  // Case: Component name not given
  else {
    if (message === '') {
      Alert.success(success(defaultmessage), toastOptions);
    } else {
      Alert.success(success(message), toastOptions);
    }
  }
}

export const toastError = (message, defaultmessage) => {
  Alert.closeAll();
  if (message === '') {
    Alert.error(error(defaultmessage), {
      position: 'top',
      timeout: null
    });
  } else {
    Alert.error(error(message), {
      position: 'top',
      timeout: null
    });
  }
};

const info = (message) => {
  return (
    <React.Fragment>
      <FontAwesomeIcon icon={faInfoCircle} size="lg" className="toaster-icon" />
      {message}
    </React.Fragment>
  );
}

const success = (message) => {
  return (
    <React.Fragment>
      <FontAwesomeIcon icon={faCheck} size="lg" className="toaster-icon" />
      {message}
    </React.Fragment>
  );
}

const error = (message) => {
  return (
    <React.Fragment>
      <div style={{ whiteSpace: 'pre-wrap' }}>
        <FontAwesomeIcon icon={faExclamationTriangle} size="lg" className="toaster-icon" />
        {message}
      </div>
    </React.Fragment>
  );
}


class Toaster extends React.Component {

  render() {
    return <Alert stack={this.props.isStack} />;
  }
}


export default Toaster;