import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { } from 'antd';
import React, { useEffect, useState } from 'react';
import { Button, Col, Label, Row } from 'reactstrap';
import * as Yup from 'yup';
import { LICENCE_APP_CONFIG_GET_DECLARATION } from '../../../App/AppSettings';
import { getParams, isValidForm } from '../../../Shared/Actions';
import { FIELD_SIZE } from '../../../Shared/Constants';
import { ActionButton, DataTable } from '../../../Shared/DataTable';
import { getCustomComponents } from '../../../Shared/DataTable/BaseTable.js';
import { useAsyncFetch } from '../../../Shared/Fetch';
import { CreatableSelectList} from '../../../Shared/Forms';
import { ModalConfirm } from '../../../Shared/Modal';
import { getLangKey, getLicenceTypeNameTranslationKey } from '../../DisplayComponents/DisplayUtils';
import { LANGUAGE_KEYS, ERROR } from '../../../Shared/Constants/LanguageKeys';
import { useTranslation } from 'react-i18next';

const DECLARATION_VALUES = {
    Id: '',
    DeclarationDescription: '',
    ExistingId: ''
};

export default function LACDeclaration(props) {
    const { t } = useTranslation();
    const selectedDeclarationValidation = Yup.object().shape({
        DeclarationDescription: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED))
    });
    const { values, errors, resetDeclarationReadyFunc, isDeclarationReadyClick, licenceName } = props;
    const [declarationData, setDeclarationData] = useState([]);
    const [declarationList, setDeclarationList] = useState([]);
    const [modalState, setModalState] = useState(null);
    const [selectedDeclarationData, setSelectedDeclarationData] = useState(DECLARATION_VALUES);
    const [selectedDeclarationDataError, setSelectedDeclarationDataError] = useState(false);
    const [declarationCount, setDeclarationCount] = useState(values.ApplicationData.DeclarationData.length);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const viewLicenceTypeNameTranslationKey = values.LicenceTypeName ? getLicenceTypeNameTranslationKey(values.LicenceTypeName) : null;

    const ADD_DECLARATION_MODAL = {
        hasModal: true,
        name: 'AddDeclaration',
        modalHeader: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_MODAL_ADDDECLARATION)
    };
    const EDIT_DECLARATION_MODAL = {
        hasModal: true,
        name: 'EditDeclaration',
        modalHeader: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_MODAL_EDITDECLARATION)
    };
    const DELETE_DECLARATION_MODAL = {
        hasModal: true,
        name: 'DeleteDeclaration',
        modalHeader: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_MODAL_DELETE),
        modalContent: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_MESSAGE_MODAL_DELETERECORD)
    };
    useEffect(()=>{
        setDeclarationData(values.ApplicationData.DeclarationData);
    },[
        values.ApplicationData.DeclarationData
    ])
    const declarationResponse =
        useAsyncFetch(LICENCE_APP_CONFIG_GET_DECLARATION, getParams({ LicenceTypeId: values.ApplicationData.LicenceTypeId }),
            isDeclarationReadyClick, resetDeclarationReadyFunc);
    useEffect(()=>{
        if (declarationResponse) {
            const fetchDeclarationListResponse = async response => {
                const { Data, IsSuccess } = response.body;
                if (response.success && IsSuccess) {
                    const declarationOptions = [];

                    const licenceTypeNameTranslationKey = licenceName ? getLicenceTypeNameTranslationKey(licenceName) : viewLicenceTypeNameTranslationKey;
                    Data.forEach(element => {
                        declarationOptions.push({
                            label: t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPEDECLARATION_DECLARATIONDESC_KEY, licenceTypeNameTranslationKey, element.Id)),
                            value: element.Id, existingId: element.Id
                        });
                    });
                    setDeclarationList(declarationOptions);
                }
            };

            fetchDeclarationListResponse(declarationResponse);
        }
    },[
        declarationResponse, t
    ]);
    
    const displayRequiredMessage = label => (
        `${label} ${t(ERROR.REQUIRED)}`
    );
    const onCreateDeclarationOption = (name, value) => {
        setValues(name, value, declarationList);
        const declarations = [...declarationList];
        declarations.push({ label: value, value });
        setDeclarationList(declarations);
    };
    const setValues = (name, value, optionsArray) => {
        const id = optionsArray.findIndex(o => o.value === value);
        const label = optionsArray[id] ? optionsArray[id].label : null;

        if (name === 'DeclarationDescription') {
            const existingId = optionsArray[id] ? optionsArray[id].existingId : null;
            setSelectedDeclarationData(prevValues => ({ ...prevValues, 'DeclarationDescription': (label ? label : value), 'Id': value, 'ExistingId': existingId }));
        }
    };
    const toggleModal = (modalName) => {
        if (modalName === ADD_DECLARATION_MODAL.name) {
            setSelectedDeclarationDataError(false);
        }
        setModalState((modalState !== modalName) ? modalName : null);
    };
    const addDeclaration = () => {
        if (isValidForm(selectedDeclarationValidation, selectedDeclarationData)) {
            setSelectedDeclarationDataError(false);
            const data = [...declarationData];
            data.push({ ...selectedDeclarationData, RowId: declarationCount });
            setDeclarationCount(declarationCount + 1);
            setDeclarationData(data);
            values.ApplicationData.DeclarationData = data;
            toggleModal(ADD_DECLARATION_MODAL.name);
        } else {
            setSelectedDeclarationDataError(true);
        }
    };
    const editDeclaration = data => {
        setSelectedDeclarationData(data);
        const index = values.ApplicationData.DeclarationData.findIndex(val =>
            val.RowId === data.RowId
        );
        setSelectedIndex(index);
        toggleModal(EDIT_DECLARATION_MODAL.name);
    };
    const confirmEditDeclaration = () => {
        if (isValidForm(selectedDeclarationValidation, selectedDeclarationData)) {
            setSelectedDeclarationDataError(false);
            const data = [...declarationData];
            data[selectedIndex] = selectedDeclarationData;
            setDeclarationData(data);
            values.ApplicationData.DeclarationData = data;
            toggleModal(EDIT_DECLARATION_MODAL.name);
            setSelectedDeclarationData(DECLARATION_VALUES);
            setSelectedIndex(null);
        } else {
            setSelectedDeclarationDataError(true);
        }
    };
    const deleteDeclaration = data => {
        toggleModal(DELETE_DECLARATION_MODAL.name);
        const index = values.ApplicationData.DeclarationData.findIndex(val =>
            val.RowId === data.RowId
        );
        setSelectedIndex(index);
    };
    const confirmDeleteDeclaration = () => {
        const data = [...declarationData];
        data.splice(selectedIndex, 1);
        setDeclarationData(data);
        values.ApplicationData.DeclarationData = data;
        setSelectedIndex(null);
    };
    const getDeclarationOptions = () => {
        let updatedDeclarationOptions;
        if (modalState === EDIT_DECLARATION_MODAL.name) {
            updatedDeclarationOptions = declarationList.filter(currDecOption => !declarationData.map(doc => doc.Id).includes(currDecOption.value)
                || currDecOption.value === selectedDeclarationData.Id);
        } else {
            updatedDeclarationOptions = declarationList.filter(currDecOption => !declarationData.map(doc => doc.Id).includes(currDecOption.value));
        }
        return updatedDeclarationOptions;
    };
    const renderDeclarationDataModalContent = () => {
        const declarationOptions = getDeclarationOptions();
        return (
            <CreatableSelectList
                isClearable={false}
                name="DeclarationDescription"
                value={selectedDeclarationData.Id}
                minLength={0}
                maxLength={140}
                options={declarationOptions}
                placeholder={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_PLACEHOLDER_DECLARATION)}
                isMulti={false}
                onChangeField={(name, valueArr) => {
                    setValues(name, valueArr, declarationList);
                }}
                onCreate={onCreateDeclarationOption}
                inputSize={FIELD_SIZE.LARGE}
                label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_DECLARATION)}
                error={!selectedDeclarationData.DeclarationDescription && selectedDeclarationDataError ? displayRequiredMessage(t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_DECLARATION)) : ''}
                required
            />
        );
    };
    const setDeclarationTranslatedString = (rowData) => {
        const licenceTypeNameTranslationKey = licenceName ? getLicenceTypeNameTranslationKey(licenceName) : viewLicenceTypeNameTranslationKey;
        const translatedString = t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPEDECLARATION_DECLARATIONDESC_KEY, licenceTypeNameTranslationKey, rowData.Id));
        return <>{rowData.ExistingId ? translatedString : rowData.DeclarationDescription}</>
    }
    const getDeclarationTranslatedString = (rowData) => {
        const licenceTypeNameTranslationKey = licenceName ? getLicenceTypeNameTranslationKey(licenceName) : viewLicenceTypeNameTranslationKey;
        const translatedString = t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPEDECLARATION_DECLARATIONDESC_KEY, licenceTypeNameTranslationKey, rowData.Id));
        return rowData.ExistingId ? translatedString : rowData.DeclarationDescription;
    }
    return (
        <React.Fragment>
            <Row>
                <Col>
                    <Button
                        className="float-right"
                        color="neutral"
                        onClick={() => {
                            setSelectedDeclarationData(DECLARATION_VALUES);
                            toggleModal(ADD_DECLARATION_MODAL.name);
                        }}
                    >
                        {t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_ADDDECLARATION)}
                    </Button>
                </Col>
            </Row>
            <div className="smart-list">
                <DataTable
                    pageSize="5"
                    minFilterChars='2'
                    data={declarationData}
                    noResultsMessage={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_MESSAGE_PLACEHOLDER_NODECLARATION)}
                    components={getCustomComponents()}
                    columns={
                        {
                            RunningNumber: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_NUMBER), width: '5%' },
                            DeclarationDescription: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_DECLARATION), width: '85%', DBkey: 'DeclarationDescription', setContent: setDeclarationTranslatedString, getTranslatedString: getDeclarationTranslatedString },
                            Actions: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_ACTION), width: '10%' }
                        }
                    }
                    renderActions={({ RowData }) => (
                        <React.Fragment>
                            <ActionButton tooltip={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_EDIT)} color="forward" onClick={() => editDeclaration(RowData)}><FontAwesomeIcon icon={faEdit} /></ActionButton>
                            <ActionButton
                                tooltip={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_DELETE)}
                                color="hazard"
                                onClick={() => deleteDeclaration(RowData)}
                            >
                                <FontAwesomeIcon fixedWidth icon={faTrash} />
                            </ActionButton>
                        </React.Fragment>
                    )}
                />
                {values.ApplicationData.DeclarationData.length <= 0 &&
                    <Label className="label-error">
                        {errors.ApplicationData && errors.ApplicationData.DeclarationData}
                    </Label>
                }
                <ModalConfirm
                    isOpen={(modalState === DELETE_DECLARATION_MODAL.name)}
                    contentHeader={DELETE_DECLARATION_MODAL.modalHeader}
                    contentBody={DELETE_DECLARATION_MODAL.modalContent}
                    confirmText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_CONFIRM)}
                    confirmCallback={() => {
                        toggleModal(DELETE_DECLARATION_MODAL.name);
                        confirmDeleteDeclaration();
                    }}
                    cancelText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_CANCEL)}
                    cancelCallback={() => toggleModal(DELETE_DECLARATION_MODAL.name)}
                />
                <ModalConfirm
                    isOpen={(modalState === ADD_DECLARATION_MODAL.name)}
                    contentHeader={ADD_DECLARATION_MODAL.modalHeader}
                    confirmText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_ADD)}
                    confirmCallback={() => { addDeclaration(); }}
                    cancelText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_CANCEL)}
                    cancelCallback={() => toggleModal(modalState)}
                    contentBody={renderDeclarationDataModalContent()}
                />
                <ModalConfirm
                    isOpen={(modalState === EDIT_DECLARATION_MODAL.name)}
                    contentHeader={EDIT_DECLARATION_MODAL.modalHeader}
                    confirmText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_EDIT)}
                    confirmCallback={() => { confirmEditDeclaration(); }}
                    cancelText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_CANCEL)}
                    cancelCallback={() => toggleModal(EDIT_DECLARATION_MODAL.name)}
                    contentBody={renderDeclarationDataModalContent()}
                />
            </div>
        </React.Fragment>
    );
};