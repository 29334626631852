import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Input, InputGroup, InputGroupAddon, InputGroupText, Label } from 'reactstrap';
import { faUpload, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withBaseControl } from './BaseControl';
import { getFileRequest, getParams } from '../Actions';

const DEFAULT_FILE_NAME = 'No Files Chosen';

class FileUpload extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            file: undefined,
            fileSelected: '',
            fileDescription: '',
            fileSelectedError: '',
            fileDescriptionError: ''
        };
    }

    componentDidMount() {
        this.getFile(this.props);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.fileList !== this.props.fileList) {
            this.getFile(this.props);
        }
    }

    getFile = ({ name, value, onChangeField, fileList, readonly, multipleFilesWithSameName }) => {
        let file = undefined;
        // Find file that is not deleted & found in DB
        if (fileList !== undefined) {
            if (!multipleFilesWithSameName) {
                file = _.find(fileList, { 'FileUploadField': name, 'IsDeleted': false }); // _.find returns undefined if file is not found in fileList
            }
            if (multipleFilesWithSameName && value !== undefined) {
                file = _.find(fileList, { 'FileUploadField': name, 'IsDeleted': false, 'Filename': value.Filename }); //value will have a Filename property
            }
        }

        if (!readonly) {
            // Find file that has just been uploaded
            if (file === undefined && value instanceof File) {
                file = value;

                // Set field to formik
                onChangeField(name, file);
            }
        }

        this.setState({
            file
        });
    };

    // Fn: Handles on File changed/selected in File Explorer
    handleFileChange = async (e, { onChangeField }) => {
        const file = e.currentTarget.files[0];

        // If file is selected
        if (file) {

            // Sieve out fileExtension
            const fileExtension = (/[.]/.exec(file.name)) ? /[^.]+$/.exec(e.currentTarget.files[0].name)[0] : undefined;

            // Append unique key in file object
            // Key format: < UploadedFile_<TimeStamp> >
            Object.defineProperty(file, 'key', {
                writable: true,
                value: `UploadedFile_${new Date().getTime()}.${fileExtension.toLowerCase()}`
            });

            // Set field to formik
            onChangeField(e.currentTarget.name, file);

            // Update file name for display
            this.setState({
                fileName: file.name,
                file: file,
            });
        }

        // In scenario when CANCEL button is clicked in Chrome browsers File Explorer
        else {
            // Perform proper file clear handling
            this.clearFile(this.props);
        }
    };

    // Fn: Generates FileLabel based on value prop
    FileLabel = () => {
        const file = this.state.file;
        let fileLabel = '';

        if (file !== undefined) {
            if (file.FileUploadField === undefined) {
                fileLabel = file.name;
            }
            else if(this.props.documentFileName){
                fileLabel = this.props.documentFileName;
            }
            else {
                fileLabel = file.Filename;
            }
        }
        else {
            fileLabel = DEFAULT_FILE_NAME;
        }
        return fileLabel;
    };

    // Fn: Clear file handler
    // relevant to IE/FF where they do not take in "Cancel" selector in File Explorer
    clearFile = ({ onChangeField, name, fileList }) => {
        const file = this.state.file;

        if (file !== undefined) {
            const fileId = file.Id;
            if (fileId !== undefined) {

                // Mark file as deleted from fileList (to delete file in DB)
                const index = _.findIndex(fileList, { 'FileUploadField': name });
                fileList[index].IsDeleted = true;
            }
        }

        // Remove file from frontend
        this.setState({
            file: undefined
        });

        // Perform update on parent states
        onChangeField(name, '');
    };

    downloadFile = async (fileURL, file, documentName) => {
        
        const response = await getFileRequest(fileURL + '?FileName=' + file.Filename, false, documentName, getParams());
        return response;
    }

    downloadFileLink = fileURL => {
        const file = this.state.file;

        // Show download link if file exists in database or file upload
        if (file !== undefined && file.Filename !== undefined) {
            const documentName = this.props.documentFileName ? this.props.documentFileName : file.Filename;
            
            return (<div onClick={() => this.downloadFile(fileURL, file, documentName)} className="download-link">Download File
                {file.ContentLength && file.ContentType &&
                    <>
                        ({file.ContentLength}KB/{file.ContentType.toUpperCase()})
                    </>
                }</div>);
        }
    }

    // Fn: If there's existing file, show disabled.
    GetFileUploadButtonClassName = file =>
        (file === undefined) ? '' : 'cursor-no-drop';


    // Fn: If there's no existing file, show pointer. Else, show disabled.
    GetFileUploadClassName = file =>
        (file === undefined) ? 'file-input-label-pointer' : 'file-input-label-disabled';

    render() {
        const file = this.state.file;
        
        // Read only
        if (this.props.readonly) {
            if (file === undefined) {
                return (
                    <Input
                        plaintext
                        className="plaintext"
                        value="-"
                        readOnly
                    >
                    </Input >
                );
            }
            if (file.Filename !== undefined) {
                const documentName = this.props.documentFileName ? this.props.documentFileName : file.Filename;
                return (
                    this.props.isRequireAuthorize
                        ?
                        <a onClick={(e) => { e.preventDefault(); this.downloadFile(this.props.getFileURL, file, documentName) }} className="download-link-details">
                            {documentName}
                            {file.ContentLength && file.ContentType &&
                                <>
                                    ({file.ContentLength}KB/{file.ContentType.toUpperCase()})
                                </>
                            }
                        </a>
                        :
                        <a href={this.props.getFileURL + "id=" + file.Id + "&fileName=" + file.Filename} className="download-link-details">
                            {documentName}
                            {file.ContentLength && file.ContentType &&
                                <>
                                    ({file.ContentLength}KB/{file.ContentType.toUpperCase()})
                                </>
                            }
                        </a>
                );
            }
        }

        return (
            <React.Fragment>
                {/* Default UI for FileControl is hidden */}
                <Input
                    className="file-input"
                    type="file"
                    id={this.props.name}
                    name={this.props.name}
                    onClick={e => e.target.value = ''}
                    onChange={e => this.handleFileChange(e, this.props)}
                    accept={this.props.accept}
                    multiple={false}
                    disabled={file !== undefined}
                />

                {/* Display View for hidden FileControl */}
                <InputGroup>

                    {/* Display File Upload icon */}
                    <InputGroupAddon addonType="prepend">
                        <Label className={`inputgroup-label ${this.GetFileUploadButtonClassName(file)}`} for={this.props.name}>
                            <FontAwesomeIcon icon={faUpload} />
                        </Label>
                    </InputGroupAddon>

                    <Label for={this.props.name} className={`form-control ${this.GetFileUploadClassName(file)}`} tabIndex={0}>
                        {this.FileLabel()}
                    </Label>

                    {/* Display Clear File icon */}
                    {
                        <InputGroupAddon addonType="append" onClick={() => this.clearFile(this.props)}>
                            <InputGroupText className="inputgroup-btn">
                                <FontAwesomeIcon icon={faTimes} />
                            </InputGroupText>
                        </InputGroupAddon>
                    }
                    {
                        this.props.contentInfo &&
                        <InputGroupAddon addonType="append" onClick={() => this.props.onOpenContentInfo()}>
                            <InputGroupText className="inputgroup-btn">
                                {this.props.contentInfo}
                            </InputGroupText>
                        </InputGroupAddon>
                    }
                </InputGroup>
                {this.downloadFileLink(this.props.getFileURL)}
            </React.Fragment>
        );
    }
}

// PropTypes: For prop validation
FileUpload.propTypes = {
    name: PropTypes.string.isRequired,
    fileList: PropTypes.array,
    onChangeField: PropTypes.func,
    accept: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    getFileURL: PropTypes.string,
    readonly: PropTypes.bool
};

// PropTypes: Defaulting value for optional props
FileUpload.defaultProps = {
    accept: '.pdf,.gif,.jpg,.jpeg,.png',
    multipleFilesWithSameName: false
};


export default withBaseControl(FileUpload);
