import { Button as AntdButton } from 'antd';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { compose } from 'recompose';
import { GET_LICENCE_APPLICATION_HISTORY, GET_LICENCE_DETAILS, GET_LICENCE_STATUS_HISTORY, LAYOUT_TYPE, PATH, PRINT_LICENCE_URL, GET_LICENCE_DOCUMENT_FILE_URL } from '../../App/AppSettings';
import Layout from '../../Layout';
import { fetchRequest, getFileRequestNewTab, getParams, navigateTo } from '../../Shared/Actions';
import { ERROR, getLangKey, LANGUAGE_KEYS } from '../../Shared/Constants/LanguageKeys';
import { DataTable } from '../../Shared/DataTable';
import { getCustomComponents } from '../../Shared/DataTable/BaseTable.js';
import { AccordionContainer, Plaintext, SMARTView, toastError } from '../../Shared/Forms';
import history from '../../Shared/History';
import { withLoader } from '../../Shared/Loading';
import TitleChildren from '../../Title/TitleChildren';
import { getLicenceTypeNameTranslationKey } from '../DisplayComponents/DisplayUtils';
import { getPremiseOperatingAddress } from './Utils';

const LABEL_SIZE = 6;
const INPUT_SIZE = 6;

const SECTION_NAMES = {
    LICENCE_OVERVIEW: { title: `Licence Overview`, status: true },
    LICENCE_DOCUMENT: { title: `Licence Document`, status: true },
    LICENCE_APPLICATION_HISTORY: { title: `Licence Application History`, status: true },
    LICENCE_STATUS_HISTORY: { title: `Licence Management History`, status: true }
};

const FORM_CONTEXT = 'LicenceDetails';

class LicenceDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            licence: null
        };
        if (history.location.state) {
            this.state = {
                licence: history.location.state.licence
            };
        }
    }

    componentDidMount() {
        // TODO: Refactor SN4
        this.props.loader.start();
        this.getLicenceData();
    }

    getLicenceData = async () => {
        const { t } = this.props;
        const response = await fetchRequest(GET_LICENCE_DETAILS + this.state.licence.LicenceID, getParams(), false);
        const { IsSuccess, Data } = response.body;

        if (IsSuccess) {
            this.setState(prevState => {
                return {
                    licence: Object.assign(prevState.licence, Data, { LicenceData: JSON.parse(Data.LicenceData) })
                };
            });
        } else {
            toastError(t(ERROR.SERVICE_DATA_NOT_FETCHED));
        }

        this.props.loader.done();
    };

    getTitle = () => {
        const { LicenceName, LicenceTypeId } = this.state.licence;
        const { t } = this.props;
        const licenceTypeNameTranslationKey = getLicenceTypeNameTranslationKey(LicenceName);
        const translatedName = t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY, licenceTypeNameTranslationKey, LicenceTypeId));
        return `${translatedName} ${t(LANGUAGE_KEYS.BLS_INTERNALLICENCEDETAILS_CONTENT_INFORMATION_DETAILS)}`;
    }

    setContent = rowData => <div className="licence-application-link" onClick={() => this.redirectToApplicationPage(rowData)}>{rowData.ApplicationRefNumber}</div>;

    setStatusHistoryContent = rowData =>
        <div className="licence-application-link" onClick={() => this.redirectToStatusDetailPage(rowData)}>
            {this.props.t(getLangKey(LANGUAGE_KEYS.BLS_CODE_LICENCESTATUS_TITLE_KEY, rowData.StatusCodeName))}
        </div>;

    getStatusHistoryTranslatedString = rowData =>
        this.props.t(getLangKey(LANGUAGE_KEYS.BLS_CODE_LICENCESTATUS_TITLE_KEY, rowData.StatusCodeName))

    viewDocument = rowData => <div className="application-link" onClick={() => this.getLicenceDocument(rowData)}>{rowData.DocumentName}</div>;

    redirectToApplicationPage = rowData => {
        const FormName = this.state.licence.LicenceName;
        const { SFormId, ApplicationRefNumber, Status, ApplicationTypeCodeName, LicenceTypeId } = rowData;
        return navigateTo(PATH.ENQUIRY.APPLICATION_FORMDETAILS, { FormName, ID: SFormId, InternalDescription: Status, ReferenceNo: ApplicationRefNumber, ApplicationTypeCodeName, LicenceTypeId });
    };

    redirectToStatusDetailPage = rowData => {
        const { StatusId } = rowData;
        return navigateTo(`ServiceStatusDetails`, { StatusId });
    };

    getLicenceDocument = async obj => {
        const { loader, } = this.props;
        loader.start();
        const documentName = obj.Filename;
        const url = GET_LICENCE_DOCUMENT_FILE_URL + "id=" + obj.Id + "&fileName=" + documentName;
        const response = await getFileRequestNewTab(url, true, documentName);
        loader.done();
        return response;
    }

    getCustomDocument = async obj => {
        const { loader, } = this.props;
        loader.start();
        const documentName = obj.LicenceNumber;
        const url = PRINT_LICENCE_URL.concat(obj.LicenceID);
        const response = await getFileRequestNewTab(url, false, documentName);
        loader.done();
        return response;
    };

    renderTitleChildren = () => {
        const { t } = this.props;
        const { AgencyName, LicenceNumber, LicenceStatusCodeName } = this.state.licence;

        return (
            <TitleChildren
                items={[
                    { label: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEDETAILS_CONTENT_INFORMATION_AGENCY), value: AgencyName, type: "textMobileShow" },
                    { label: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEDETAILS_CONTENT_INFORMATION_SERVICENUM), value: LicenceNumber, type: "textMobileShow" },
                    { label: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEDETAILS_CONTENT_INFORMATION_STATUS), value: t(getLangKey(LANGUAGE_KEYS.BLS_CODE_LICENCESTATUS_TITLE_KEY, LicenceStatusCodeName)), type: "badge" }
                ]}
            >
                <Row className="title-custom">
                    <Col className="title-actions">
                        <AntdButton
                            className="btn-backward"
                            onClick={() => history.goBack()}
                        >
                            {t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_NAVIGATION_BUTTON_BACK)}
                        </AntdButton>
                    </Col>
                </Row>
            </TitleChildren>
        );
    };

    setAppTypeTranslatedContent = (rowData) => {
        const { t } = this.props;
        return (
            <>{t(getLangKey(LANGUAGE_KEYS.BLS_CODE_LICENCEAPPLICATIONTYPE_TITLE_KEY, rowData.ApplicationTypeCodeName))}</>
        );
    }

    getAppTypeTranslatedString = (rowData) => {
        const { t } = this.props;
        return t(getLangKey(LANGUAGE_KEYS.BLS_CODE_LICENCEAPPLICATIONTYPE_TITLE_KEY, rowData.ApplicationTypeCodeName));
    }

    render() {
        const { t } = this.props;
        const Licence = this.state.licence;
        const { LicenceData, DocumentsFailedToGenerate, LicenceDocuments = [] } = Licence;
        const { GeneralInformation, LicenceInformation } = LicenceData ? LicenceData : '';
        const Entity = GeneralInformation ? GeneralInformation.GI_EntitydetailsEntityname : null;
        const licenceTypeNameTranslationKey = getLicenceTypeNameTranslationKey(Licence.LicenceName);
        const translatedName = t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY, licenceTypeNameTranslationKey, Licence.LicenceTypeId));

        return (
            <SMARTView
                formContext={FORM_CONTEXT}
                isDummy={false}
            >
                {({ toggleSection }) => (
                    <Layout type={LAYOUT_TYPE.FORM} title={this.getTitle()} footer={false} titleClassName="sticky-title" titleChildren={this.renderTitleChildren()}>
                        <>
                            <Row className="body-content internal-content">
                                <Col className="body-section-col">
                                    <AccordionContainer
                                        title={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEDETAILS_CONTENT_MENU_SERVICEOVERVIEW)}
                                        active={SECTION_NAMES.LICENCE_OVERVIEW.status}
                                        onClick={toggleSection}
                                        isIndividual={true}
                                        sections={SECTION_NAMES}
                                    >
                                        {Licence && GeneralInformation && LicenceInformation &&
                                            <>
                                                <Plaintext
                                                    label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEDETAILS_CONTENT_INFORMATION_SERVICENAME)}
                                                    name="LicenceName"
                                                    className="overview-margin"
                                                    value={translatedName}
                                                    labelSize={LABEL_SIZE}
                                                    inputSize={INPUT_SIZE}
                                                />
                                                <Plaintext
                                                    label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEDETAILS_CONTENT_INFORMATION_SERVICENUM)}
                                                    name="LicenceNumber"
                                                    className="overview-margin"
                                                    value={Licence.LicenceNumber}
                                                    labelSize={LABEL_SIZE}
                                                    inputSize={INPUT_SIZE}
                                                />
                                                <Plaintext
                                                    label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEDETAILS_CONTENT_INFORMATION_APPLICANT)}
                                                    name="Licensee"
                                                    className="overview-margin"
                                                    value={GeneralInformation.GI_Name}
                                                    labelSize={LABEL_SIZE}
                                                    inputSize={INPUT_SIZE}
                                                />
                                                <Plaintext
                                                    label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEDETAILS_CONTENT_INFORMATION_SERVICESTATUS)}
                                                    name="LicenceStatus"
                                                    className="overview-margin"
                                                    value={t(getLangKey(LANGUAGE_KEYS.BLS_CODE_LICENCESTATUS_TITLE_KEY, Licence.LicenceStatusCodeName))}
                                                    labelSize={LABEL_SIZE}
                                                    inputSize={INPUT_SIZE}
                                                />
                                                <Plaintext
                                                    label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEDETAILS_CONTENT_INFORMATION_AGENCY)}
                                                    name="Agency"
                                                    className="overview-margin"
                                                    value={Licence.AgencyName}
                                                    labelSize={LABEL_SIZE}
                                                    inputSize={INPUT_SIZE}
                                                />
                                                {Entity &&
                                                    <Plaintext
                                                        label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEDETAILS_CONTENT_INFORMATION_ENTITY)}
                                                        name="Entity"
                                                        className="overview-margin"
                                                        value={Entity}
                                                        labelSize={LABEL_SIZE}
                                                        inputSize={INPUT_SIZE}
                                                    />
                                                }
                                                <Plaintext
                                                    label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEDETAILS_CONTENT_INFORMATION_ISSUEDDATE)}
                                                    name="IssuedDate"
                                                    className="overview-margin"
                                                    value={Licence.IssuedDate}
                                                    labelSize={LABEL_SIZE}
                                                    inputSize={INPUT_SIZE}
                                                />
                                                <Plaintext
                                                    label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEDETAILS_CONTENT_INFORMATION_EXPIRYDATE)}
                                                    name="ExpiryDate"
                                                    className="overview-margin"
                                                    value={Licence.ExpiryDate}
                                                    labelSize={LABEL_SIZE}
                                                    inputSize={INPUT_SIZE}
                                                />
                                                <Plaintext
                                                    label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEDETAILS_CONTENT_INFORMATION_PREMISEOPERATINGADDRESS)}
                                                    name="PremiseOperatingAddress"
                                                    className="overview-margin"
                                                    value={getPremiseOperatingAddress(LicenceInformation)}
                                                    labelSize={LABEL_SIZE}
                                                    inputSize={INPUT_SIZE}
                                                />
                                            </>
                                        }
                                    </AccordionContainer>

                                    {
                                        <AccordionContainer
                                            title={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEDETAILS_CONTENT_MENU_SERVICEDOCUMENT)}
                                            active={SECTION_NAMES.LICENCE_DOCUMENT.status}
                                            onClick={toggleSection}
                                            isIndividual={true}
                                            sections={SECTION_NAMES}
                                        >
                                            {DocumentsFailedToGenerate && DocumentsFailedToGenerate.length > 0 &&
                                                <p className="ml-3 text-danger text-left">
                                                    {t(ERROR.UNABLETOGENARATEDOCUMENT) + " " + DocumentsFailedToGenerate.join(', ')}
                                                    <br />
                                                    {t(ERROR.CONTACTAGENCYFORSUPPORT)}
                                                </p>
                                            }
                                            {Licence && <DataTable
                                                pageSize="5"
                                                minFilterChars='2'
                                                data={LicenceDocuments}
                                                noResultsMessage={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEDETAILS_MESSAGE_PLACEHOLDER_NOSERVICEHISTORY)}
                                                components={getCustomComponents()}
                                                columns={
                                                    {
                                                        RunningNumber: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEDETAILS_TABLE_TITLE_NUMBER), width: '5%' },
                                                        DocumentName: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEDETAILS_TABLE_TITLE_SERVICEDOCUMENT), width: '45%', DBkey: 'DocumentName', setContent: this.viewDocument },
                                                        ContentType: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEDETAILS_TABLE_TITLE_FILETYPE), width: '20%', DBkey: 'ContentType' },
                                                        CreatedDate: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEDETAILS_TABLE_TITLE_ISSUEDDATE), width: '20%', DBkey: 'CreatedDate', isDate: true }
                                                    }
                                                }
                                            />
                                            }
                                        </AccordionContainer>
                                    }

                                    {Licence.LicenceNumber &&
                                        <AccordionContainer
                                            title={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEDETAILS_CONTENT_MENU_SERVICEAPPHISTORY)}
                                            active={SECTION_NAMES.LICENCE_APPLICATION_HISTORY.status}
                                            onClick={toggleSection}
                                            isIndividual={true}
                                            sections={SECTION_NAMES}
                                        >
                                            <DataTable
                                                pageSize="5"
                                                minFilterChars='2'
                                                url={GET_LICENCE_APPLICATION_HISTORY + Licence.LicenceNumber}
                                                noResultsMessage={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEDETAILS_MESSAGE_PLACEHOLDER_NOSERVICEAPPHISTORY)}
                                                components={getCustomComponents()}
                                                columns={
                                                    {
                                                        RunningNumber: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEDETAILS_TABLE_TITLE_NUMBER), width: '5%' },
                                                        ApplicationRefNumber: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEDETAILS_TABLE_TITLE_APPREFNUMBER), width: '20%', DBkey: 'ApplicationRefNumber', setContent: this.setContent },
                                                        ApplicationType: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEDETAILS_TABLE_TITLE_APPTYPE), width: '20%', DBkey: 'ApplicationType', setContent: this.setAppTypeTranslatedContent, getTranslatedString: this.getAppTypeTranslatedString },
                                                        SubmissionDate: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEDETAILS_TABLE_TITLE_SUBMISSIONDATE), width: '20%', DBkey: 'SubmissionDate', isDate: true },
                                                        Status: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEDETAILS_TABLE_INFORMATION_STATUS), width: '15%', DBkey: 'Status' }
                                                    }
                                                }
                                                initialSort={
                                                    { id: 'SubmissionDate', sortAscending: false }
                                                }
                                            />
                                        </AccordionContainer>
                                    }

                                    {Licence.LicenceNumber &&
                                        <AccordionContainer
                                            title={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEDETAILS_CONTENT_MENU_SERVICEMANAGEMENTHISTORY)}
                                            active={SECTION_NAMES.LICENCE_STATUS_HISTORY.status}
                                            onClick={toggleSection}
                                            isIndividual={true}
                                            sections={SECTION_NAMES}
                                        >
                                            <DataTable
                                                pageSize="5"
                                                minFilterChars='2'
                                                url={GET_LICENCE_STATUS_HISTORY + Licence.LicenceNumber}
                                                noResultsMessage={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEDETAILS_MESSAGE_PLACEHOLDER_NOSERVICESTATUSHISTORY)}
                                                components={getCustomComponents()}
                                                columns={
                                                    {
                                                        RunningNumber: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEDETAILS_TABLE_TITLE_NUMBER), width: '5%' },
                                                        StatusType: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEDETAILS_TABLE_TITLE_STATUSTYPE), width: '20%', DBkey: 'StatusType', setContent: this.setStatusHistoryContent, getTranslatedString: this.getStatusHistoryTranslatedString },
                                                        ProcessedDate: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEDETAILS_TABLE_TITLE_PROCESSEDDATE), width: '20%', DBkey: 'ProcessedDate', isDate: true },
                                                        ProcessedBy: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEDETAILS_TABLE_TITLE_PROCESSEDBY), width: '15%', DBkey: 'ProcessedBy' }
                                                    }
                                                }
                                                initialSort={
                                                    { id: 'ProcessedDate', sortAscending: false }
                                                }
                                            />
                                        </AccordionContainer>
                                    }
                                </Col>
                            </Row>
                        </>
                    </Layout>
                )}
            </SMARTView>
        );
    }
}
export default withTranslation()(withRouter(compose(withLoader)(LicenceDetails)));