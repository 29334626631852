import React from 'react';
import { Col, Row } from 'reactstrap';
import Profile from '../Profile';
import { NavDropdownProvider } from './NavDropdownContext';
import NavHeader from './NavHeader';

class NavLayout2D extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            navDropdown: "",
        }
    }
    setNavDropdown = (input) => {
        this.setState({ navDropdown: input });
    }

    toggleNavDisplay = () => {
        if (this.props.showNavDisplay) {
            return "d-flex"
        }
        else {
            return "d-none"
        }
    }

    adjustNavDisplay = () => {
        return this.props.showNavDisplay ? "" : "no-navdisplay-layout";
    }

    render() {
        return (
            <Col className={`smart-navbar layout-2d ${this.adjustNavDisplay()}`}>
                <div className="nav-container">
                    <NavHeader navbarLayout={this.props.navbarLayout} className={"layout-2d-header"}>
                        <Profile profileDisplay={true} />
                    </NavHeader>
                    <Row className={"layout-2d-container " + this.toggleNavDisplay()}>
                        <Col>
                            <NavDropdownProvider
                                value={{
                                    navDropdown: this.state.navDropdown,
                                    setNavDropdown: this.setNavDropdown,
                                }}>
                                <Row>{this.props.children}</Row>
                            </NavDropdownProvider>
                        </Col>
                    </Row>
                </div>
            </Col>
        )
    }
}


export default NavLayout2D;