import _ from 'lodash';
import React from 'react';
import Creatable from 'react-select/creatable';
import { FormGroup } from 'reactstrap';
import { withBaseControl } from './BaseControl';



class MenulessCreatableSelectList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            options: this.props.options,
            value: this.props.value,
            inputValue: ''
        };

    }



    createOption = (label) => ({
        label,
        value: label,
    });



    valueToOption = (value) => {
        if (value) {
            return { 'label': value, 'value': value }
        }
    }



    handleInputChange = (inputValue) => {
        this.setState({
            inputValue
        });
    }

    handleKeyDown = (event) => {
        const { inputValue } = this.state;
        const { value, name } = this.props;
        if (!inputValue) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab':

                let newValue = this.createOption(inputValue.trim());

                if (!_.find(this.props.value, newValue)) {

                    event.preventDefault();
                    this.props.onChangeField(name, [...value, newValue]);

                }

                this.setState({
                    inputValue: ''
                });
                break;
            default:

        }

    }


    handleRemoveValue = (action, removedObject) => {
        if (removedObject.removedValue) {
            this.props.onChangeField(this.props.name, _.reject(this.props.value, removedObject.removedValue));
        }
    }

    render() {
        return (
            <FormGroup>
                <Creatable
                    key={this.props.name}
                    name={this.props.name}
                    value={this.props.value || []}
                    onInputChange={this.handleInputChange}
                    inputValue={this.state.inputValue}
                    isMulti={true}
                    menuIsOpen={false}
                    components={{
                        IndicatorSeparator: () => null,
                        DropdownIndicator: () => null
                    }}
                    placeholder={this.props.placeholder}
                    onChange={(action, actionName, removedValue) => this.handleRemoveValue(action, actionName, removedValue)}
                    onCreateOption={this.handleCreate}
                    isDisabled={this.props.isDisabled}
                    isClearable={false}
                    onKeyDown={this.handleKeyDown}
                />
            </FormGroup>
        );
    }
}

// // PropTypes: For prop validation
// MenulessCreatableSelectList.propTypes = {
//     name: PropTypes.string.isRequired,
//     value: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]).isRequired,
//     placeholder: PropTypes.string,
//     onChangeField: PropTypes.func.isRequired,
//     options: PropTypes.array.isRequired,
//     isMulti: PropTypes.bool.isRequired
// };


export default withBaseControl(MenulessCreatableSelectList);