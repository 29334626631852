import { faGlobe, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Label, Row } from 'reactstrap';
import { DATE_DISPLAY_FORMAT, GET_APPCONFIG_URL, GET_PREVIEW_SERVICE, LAYOUT_TYPE, POST_PREVIEW_SERVICE_PUBLISH_FOR_LIVE, PREVIEW_SERVICE_DRAFT_LIST, PREVIE_SERVICE_PREVIEW_LIST } from '../../App/AppSettings';
import Layout from '../../Layout';
import Section from '../../Section';
import SectionWrapper from '../../SectionWrapper';
import { fetchRequest, getParams, postParams } from '../../Shared/Actions';
import { FIELD_SIZE, PREVIEW_SERVICE_STATUS } from '../../Shared/Constants';
import { ERROR, LANGUAGE_KEYS, SUCCESS } from '../../Shared/Constants/LanguageKeys';
import { useAsyncFetch } from '../../Shared/Fetch';
import { DateInput, SMARTForm } from '../../Shared/Forms';
import { toastError, toastSuccess } from '../../Shared/Forms/Toaster.js';
import { ModalConfirm } from '../../Shared/Modal';
import { getLangKey } from '../DisplayComponents/DisplayUtils';
import './Custom.css';
import Draft from './Draft';
import Live from './Live';
import './PreviewService.css';
import Preview from './Preview';

const FORM_CONTEXT = 'PreviewService';
const RETRIEVE_URL = GET_PREVIEW_SERVICE;
const SERVER_URL = PREVIEW_SERVICE_DRAFT_LIST;

const APP_CONFIG_CATEGORY = "PreviewService";
const INTRANET_APP_CONFIG_NAME = 'Intranet Site Preview URL';
const PUBLIC_APP_CONFIG_NAME = 'Public Site Preview URL'


const FORM_INITIAL_VALUES = {
    LicenceTypeIds: [],
    PublishStatus: '',
    ShowErrorMessage: false,
    ShowPreviewErrorMessage: false,
    EffectiveFromDate: '',
    EffectiveToDate: ''
};

const CONFIRM_MODAL = {
    hasModal: true,
    name: "Confirm"
};

const CONFIRM_LIVE_MODAL = {
    hasModal: true,
    name: "Confirm_Publish_To_Live"
};

const INVALID_EFFECTIVE_TO_DATE_ERROR = 'Effective To must be equal or later than Effective From.';
const INVALID_EFFECTIVE_FROM_DATE_ERROR = 'Effective From must be earlier or equal than Effective To.';

export default function PreviewService(props) {
    const { t } = useTranslation();
    const { loader } = props;
    const [values, setValues] = useState(FORM_INITIAL_VALUES);
    const [draftData, setDraftData] = useState([]);
    const [errorMessage, setErrorMessage] = useState(values.ShowErrorMessage);
    const [previewErrorMessage, setPreviewErrorMessage] = useState(values.ShowPreviewErrorMessage);
    const [previewData, setPreviewData] = useState([]);
    const [liveData, setLiveData] = useState([]);
    const [sectionName, setSectionName] = useState({ DRAFT: 'Draft (0)', PREVIEW: 'Preview (0)', LIVE: 'Live (0)' });
    const [pageLoaded, setPageLoaded] = useState(false);
    const [publishToPreviewDisabled, setPublishToPreviewDisabled] = useState(true);
    const [publishToLiveDisabled, setPublishToLiveDisabled] = useState(true);
    const [modalState, setModalState] = useState(undefined);
    // const draftTabResponse = useAsyncFetch(`${RETRIEVE_URL}Draft`, getParams());
    // const previewTabResponse = useAsyncFetch(`${RETRIEVE_URL}Staging`, getParams());
    // const liveTabResponse = useAsyncFetch(`${RETRIEVE_URL}Production`, getParams());
    const [serverURL, setServerURL] = useState('');
    const [noTestAccountMessage, setNoTestAccountMessage] = useState('');
    const [effectiveFromDateError, setEffectiveFromDateError] = useState('');
    const [effectiveToDateError, setEffectiveToDateError] = useState('');
    const intranetAppConfigResponse = useAsyncFetch(GET_APPCONFIG_URL, getParams({ category: APP_CONFIG_CATEGORY, name: INTRANET_APP_CONFIG_NAME }));
    const publicAppConfigResponse = useAsyncFetch(GET_APPCONFIG_URL, getParams({ category: APP_CONFIG_CATEGORY, name: PUBLIC_APP_CONFIG_NAME }))
    const licensesCountResponse = useAsyncFetch(`${RETRIEVE_URL}Count`, getParams());
    const [previewSites, setPreviewSites] = useState({ Intranet: '', Public: '' });
    const minEffectiveFromDate = new Date();
    const [currentSection, setCurrentSection] = useState('Draft');
    const [defaultSection, setDefaultSection] = useState('');
    const [isDraftLoaded, setIsDraftLoaded] = useState(false);
    const [isPreviewLoaded, setIsPreviewLoaded] = useState(false);
    const [isLiveLoaded, setIsLiveLoaded] = useState(false);

    useEffect(() => {
        setValues(prevValues => ({
            ...prevValues,
            LicenceTypeIds: [],
            PublishStatus: '',
            EffectiveFromDate: '',
            EffectiveToDate: ''
        }));
        
        const fetchDraftData = async () => {
            setPageLoaded(false);
            const draftTabResponse = await fetchRequest(`${RETRIEVE_URL}Draft`, getParams(), false);
            if (draftTabResponse.success && draftTabResponse.body.IsSuccess) {
                setDraftData(draftTabResponse.body.Data);
                setSectionName(prevState => ({
                    ...prevState,
                    DRAFT: `Draft (${draftTabResponse.body.Data.length})`
                }));
                setDefaultSection(`Draft (${draftTabResponse.body.Data.length})`);
                if (draftTabResponse.body.Data.length !== 0) {
                    setPublishToPreviewDisabled(false);
                } else {
                    setPublishToPreviewDisabled(true);
                }
            }
            setPageLoaded(true);
        };

        if (currentSection.includes("Draft") && !isDraftLoaded) {
            fetchDraftData();
            setIsDraftLoaded(true);
        }

        const fetchPreviewData = async () => {
            setPageLoaded(false);
            const previewTabResponse = await fetchRequest(`${RETRIEVE_URL}Staging`, getParams(), false);
            if (previewTabResponse.success && previewTabResponse.body.IsSuccess) {
                setPreviewData(previewTabResponse.body.Data);
                setSectionName(prevState => ({
                    ...prevState,
                    PREVIEW: `Preview (${previewTabResponse.body.Data.length})`
                }));
                setDefaultSection(`Preview (${previewTabResponse.body.Data.length})`);
                if (previewTabResponse.body.Data.length !== 0) {
                    setPublishToLiveDisabled(false);
                } else {
                    setPublishToLiveDisabled(true);
                }
            }
            setPageLoaded(true);
        };

        if (currentSection.includes("Preview") && !isPreviewLoaded) {
            fetchPreviewData();
            setIsPreviewLoaded(true);
        }

        const fetchLiveData = async () => {
            setPageLoaded(false);
            const liveTabResponse = await fetchRequest(`${RETRIEVE_URL}Production`, getParams(), false);
            if (liveTabResponse.success && liveTabResponse.body.IsSuccess) {
                setLiveData(liveTabResponse.body.Data);
                setSectionName(prevState => ({
                    ...prevState,
                    LIVE: `Live (${liveTabResponse.body.Data.length})`
                }));
                setDefaultSection(`Live (${liveTabResponse.body.Data.length})`);
            }
            setPageLoaded(true);
        }

        if (currentSection.includes("Live") && !isLiveLoaded) {
            fetchLiveData();
            setIsLiveLoaded(true);
        }

    }, [currentSection]);


    useEffect(() => {
        setValues(prevValues => ({
            ...prevValues,
            LicenceTypeIds: [],
            PublishStatus: '',
            EffectiveFromDate: '',
            EffectiveToDate: ''
        }));

        const fetchAppConfig = async () => {
            if (intranetAppConfigResponse.success && intranetAppConfigResponse.body.IsSuccess) {
                setPreviewSites(prevState => ({
                    ...prevState,
                    Intranet: intranetAppConfigResponse.body.Data.AppConfigValue
                }));
            }
            if (publicAppConfigResponse.success && publicAppConfigResponse.body.IsSuccess) {
                setPreviewSites(prevState => ({
                    ...prevState,
                    Public: publicAppConfigResponse.body.Data.AppConfigValue
                }));
            }
            if (licensesCountResponse.success && licensesCountResponse.body.IsSuccess) {
                console.log("count: " + licensesCountResponse.body.Data.DraftCount);
                setSectionName(prevState => ({
                    ...prevState,
                    DRAFT: `Draft (${licensesCountResponse.body.Data.DraftCount})`,
                    PREVIEW: `Preview (${licensesCountResponse.body.Data.StagingCount})`,
                    LIVE: `Live (${licensesCountResponse.body.Data.ProductionCount})`
                }));
            }
            setPageLoaded(true);
        }

        if (intranetAppConfigResponse && publicAppConfigResponse && licensesCountResponse) {
            fetchAppConfig();
        }
    }, [intranetAppConfigResponse, publicAppConfigResponse, licensesCountResponse]);

    const toggleModal = (modalName) => {
        if (modalState !== modalName) {
            setModalState(modalName);
        }
        else {
            setModalState(undefined);
        }
    };

    const submitCallback = async ({ response }) => {
        if (response.success) {
            const { IsSuccess, Messages } = response.body;
            if (IsSuccess) {
                toastSuccess(t(getLangKey(SUCCESS.BACKEND_SUCCESS_MESSAGE, Messages)), Messages);
            } else {
                toastError(t(getLangKey(ERROR.BACKEND_ERROR_MESSAGE, Messages)), Messages);
            }
        } else {
            toastError(t(ERROR.SERVER_UNREACHABLE));
        }

        loader.start();
        setPageLoaded(false);

        const newDraftTabResponse = await fetchRequest(`${RETRIEVE_URL}${PREVIEW_SERVICE_STATUS.DRAFT}`, getParams(), false);
        const newPreviewTabResponse = await fetchRequest(`${RETRIEVE_URL}${PREVIEW_SERVICE_STATUS.STAGING}`, getParams(), false);
        const newLiveTabResponse = await fetchRequest(`${RETRIEVE_URL}${PREVIEW_SERVICE_STATUS.PRODUCTION}`, getParams(), false);

        if (newDraftTabResponse && newPreviewTabResponse && newLiveTabResponse) {
            if (newDraftTabResponse.success && newDraftTabResponse.body.IsSuccess) {
                setDraftData(newDraftTabResponse.body.Data);
                setSectionName(prevState => ({
                    ...prevState,
                    DRAFT: `Draft (${newDraftTabResponse.body.Data.length})`
                }));
                if (newDraftTabResponse.body.Data.length !== 0) {
                    setPublishToPreviewDisabled(false);
                } else {
                    setPublishToPreviewDisabled(true);
                }
            }

            if (newPreviewTabResponse.success && newPreviewTabResponse.body.IsSuccess) {
                setPreviewData(newPreviewTabResponse.body.Data);
                setSectionName(prevState => ({
                    ...prevState,
                    PREVIEW: `Preview (${newPreviewTabResponse.body.Data.length})`
                }));
                if (newPreviewTabResponse.body.Data.length !== 0) {
                    setPublishToLiveDisabled(false);
                } else {
                    setPublishToLiveDisabled(true);
                }
            }

            if (newLiveTabResponse.success && newLiveTabResponse.body.IsSuccess) {
                setLiveData(newLiveTabResponse.body.Data);
                setSectionName(prevState => ({
                    ...prevState,
                    LIVE: `Live (${newLiveTabResponse.body.Data.length})`
                }));
            }
            setPageLoaded(true);

            // End loading
            loader.done();
        }
    };

    const publishDraftToPreview = (values, modalName) => {
        if (values.PublishStatus === '') {
            setErrorMessage(true);
        } else {
            setServerURL(SERVER_URL);
            toggleModal(modalName);
        }
    };

    const publishPreviewToLive = (values, modalName) => {
        var dataString = '';
        var noTestAccount = 0;
        values.LicenceTypeIds.forEach(l => {
            var data = previewData.find(s => s.LicenceTypeId === l)
            if (data.NumberOfTestAccount === 0) {
                if (dataString === '') {
                    dataString = dataString + data.LicenceName
                }
                else {
                    dataString = dataString + `, ${data.LicenceName}`
                }
                noTestAccount++;
            }
        })
        const effectiveFromDate = new Date(values.EffectiveFromDate);
        const effectiveToDate = new Date(values.EffectiveToDate);

        if (values.PublishStatus === '') {
            setPreviewErrorMessage(true);
        } else {
            if (values.EffectiveFromDate === '') {
                setEffectiveFromDateError('Please select the effective from');
            } else if (effectiveToDate < effectiveFromDate) {
                setEffectiveFromDateError(INVALID_EFFECTIVE_FROM_DATE_ERROR);
                setEffectiveToDateError(INVALID_EFFECTIVE_TO_DATE_ERROR);
            } else {
                if (noTestAccount === 0) {
                    setNoTestAccountMessage('');
                }
                else {
                    setNoTestAccountMessage(`There is no test account created for ${dataString}. Are you still want to continue to publish?`);
                }
                setServerURL(PREVIE_SERVICE_PREVIEW_LIST);
                toggleModal(modalName);
            }
        }
    };

    const setEffectiveFromDate = (name, value, onChangeField, effectiveToDate) => {
        const effectiveFromDate = new Date(value);
        const newEffectiveToDate = new Date(effectiveToDate);
        if (value !== '') {
            setEffectiveFromDateError('');
        } else {
            setEffectiveFromDateError('Please select the effective from');
        }

        if (newEffectiveToDate < effectiveFromDate) {
            setEffectiveFromDateError(INVALID_EFFECTIVE_FROM_DATE_ERROR);
            setEffectiveToDateError(INVALID_EFFECTIVE_TO_DATE_ERROR);
        }

        if (+newEffectiveToDate === +effectiveFromDate || newEffectiveToDate > effectiveFromDate) {
            setEffectiveFromDateError('');
            setEffectiveToDateError('');
        }

        onChangeField(name, value);
    }

    const setEffectiveToDate = (name, value, onChangeField, effectiveFromDate) => {
        const newEffectiveFromDate = new Date(effectiveFromDate);
        const effectiveToDate = new Date(value);

        if (value !== '') {
            setEffectiveToDateError('');
        }

        if (effectiveToDate < newEffectiveFromDate) {
            setEffectiveFromDateError(INVALID_EFFECTIVE_FROM_DATE_ERROR);
            setEffectiveToDateError(INVALID_EFFECTIVE_TO_DATE_ERROR);
        }

        if (+effectiveToDate === +newEffectiveFromDate || effectiveToDate > newEffectiveFromDate) {
            setEffectiveFromDateError('');
            setEffectiveToDateError('');
        }
        onChangeField(name, value);
    }

    const onSectionClickHandler = (sectionName) => {
        setCurrentSection(sectionName);
    }

    return (
        pageLoaded && <React.Fragment>
            <Layout
                type={LAYOUT_TYPE.FORM}
                title={t(LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_CONTENT_TITLE_PREVIEWSERVICE)}
                titleChildren={t(LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_CONTENT_INFORMATION_PREVIEWSERVICEINFORMATION)}
                titleClassName='theme-new'
            >
                <SMARTForm
                    formContext={FORM_CONTEXT}
                    formValues={values}
                    submitCallback={submitCallback}
                    defaultSection={defaultSection}
                    serverURL={serverURL}
                    sectionNames={sectionName}
                    isDummy={false}
                    titleClassName='theme-new'
                >
                    {({ values, onChangeField, sectionState, toggleSection, submitForm, submitPlainForm }) => {
                        const smartFormValues = {
                            values,
                            onChangeField,
                            submitForm
                        };
                        return (
                            <React.Fragment>
                                <Row>
                                    <Col className="body-content">
                                        <SectionWrapper
                                            type={'1'}
                                            tabs={sectionName}
                                            sectionState={sectionState}
                                            toggleSection={toggleSection}
                                            toggleFlex={true}
                                            getSectionName={onSectionClickHandler}
                                        >
                                            <Section
                                                type={1}
                                                sectionState={sectionState}
                                                sectionName={sectionName.DRAFT}
                                                toggleSection={toggleSection}
                                            >
                                                <Row>
                                                    <Col>
                                                        <h5>{t(LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_MESSAGE_SECTION_PREVIEW_SERVICE_DRAFT_TAB_TITLE)}</h5>
                                                    </Col>
                                                </Row>
                                                <Row className="body-content">
                                                    <Col>
                                                        <Draft
                                                            smartFormValues={smartFormValues}
                                                            data={draftData}
                                                            loader={loader}
                                                        />
                                                        <Row>
                                                            {errorMessage && values.PublishStatus === '' && <Label className="label-error">
                                                                {t(LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_ERRORMESSAGE_PLACEHOLDERTEXT_SELECTSERVICETOPREVIEW)}
                                                            </Label>}
                                                        </Row>
                                                        <Row className="center-item">
                                                            <Button color="forward" size="sm" type="button" onClick={() => publishDraftToPreview(values, CONFIRM_MODAL.name)} disabled={publishToPreviewDisabled}>
                                                                {t(LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_CONTENT_BUTTON_PUBLISHFORPREVIEW)}
                                                            </Button>
                                                        </Row>
                                                        <ModalConfirm
                                                            isOpen={(modalState === CONFIRM_MODAL.name)}
                                                            contentHeader={t(LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_MODAL_TITLE_PUBLISHFORPREVIEW)}
                                                            contentBody={t(LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_MODAL_CONTENT_CONFIRMPUBLISHFORPREVIEW)}
                                                            confirmText={t(LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_MODAL_BUTTON_CONFIRM)}
                                                            confirmCallback={() => { toggleModal(CONFIRM_MODAL.name); submitPlainForm(); }}
                                                            cancelText={t(LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_MODAL_BUTTON_CANCEL)}
                                                            cancelCallback={() => toggleModal(CONFIRM_MODAL.name)}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Section>
                                            <Section
                                                type={1}
                                                sectionState={sectionState}
                                                sectionName={sectionName.PREVIEW}
                                                toggleSection={toggleSection}
                                            >
                                                <Row style={{ padding: '0 0 1rem 0', color: '#1f72c1' }}>
                                                    <Col md={{ size: 'auto' }}>
                                                        <a href={previewSites.Public} target="_blank" rel="noopener noreferrer">{t(LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_MESSAGE_SECTION_PUBLIC_PREVIEW_SITE)} <FontAwesomeIcon icon={faGlobe} /></a>
                                                    </Col>
                                                    <Col md={{ size: 'auto' }}>
                                                        <a href={previewSites.Intranet} target="_blank" rel="noopener noreferrer">{t(LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_MESSAGE_SECTION_INTRANET_PREVIEW_SITE)} <FontAwesomeIcon icon={faUsers} /></a>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <h5>{t(LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_MESSAGE_SECTION_PREVIEW_SERVICE_PREVIEW_TAB_TITLE)}</h5>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <div className="note"><strong>{t(LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_MESSAGE_SECTION_PREVIEW_SERVICE_NOTE)}</strong>: {t(LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_MESSAGE_SECTION_PREVIEW_SERVICE_NOTE_MESSAGE)}</div>
                                                    </Col>
                                                </Row>
                                                <Row className="body-content">
                                                    <Col>
                                                        <Preview
                                                            smartFormValues={smartFormValues}
                                                            data={previewData}
                                                            setPreviewData={setPreviewData}
                                                            loader={loader}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <DateInput
                                                            name="EffectiveFromDate"
                                                            value={values.EffectiveFromDate}
                                                            placeholder={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_PLACEHOLDER_EFFECTIVEFROM)}
                                                            onChangeField={(name, val) => setEffectiveFromDate(name, val, onChangeField, values.EffectiveToDate)}
                                                            date={true}
                                                            time={false}
                                                            format={DATE_DISPLAY_FORMAT.DATE}
                                                            inputSize={FIELD_SIZE.LARGE}
                                                            min={minEffectiveFromDate}
                                                            label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_EFFECTIVEFROM)}
                                                            error={effectiveFromDateError}
                                                            dropUp
                                                            required
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <DateInput
                                                            name="EffectiveToDate"
                                                            value={values.EffectiveToDate}
                                                            placeholder={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_PLACEHOLDER_EFFECTIVETO)}
                                                            onChangeField={(name, val) => setEffectiveToDate(name, val, onChangeField, values.EffectiveFromDate)}
                                                            date={true}
                                                            time={false}
                                                            min={new Date()}
                                                            format={DATE_DISPLAY_FORMAT.DATE}
                                                            inputSize={FIELD_SIZE.LARGE}
                                                            label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_EFFECTIVETO)}
                                                            error={effectiveToDateError}
                                                            dropUp
                                                        />
                                                    </Col>
                                                    <Col></Col>
                                                </Row>
                                                <Row>
                                                    {previewErrorMessage && values.PublishStatus === '' && <Label className="label-error">
                                                        {t(LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_ERRORMESSAGE_PLACEHOLDERTEXT_SELECTSERVICETOLIVE)}
                                                    </Label>}
                                                </Row>
                                                <Row className="center-item">
                                                    <Button color="forward" size="sm" type="button" onClick={() => publishPreviewToLive(values, CONFIRM_LIVE_MODAL.name)} disabled={publishToLiveDisabled}>
                                                        {t(LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_CONTENT_BUTTON_PUBLISHFORLIVE)}
                                                    </Button>
                                                </Row>
                                                <ModalConfirm
                                                    isOpen={(modalState === CONFIRM_LIVE_MODAL.name)}
                                                    contentHeader={t(LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_MODAL_TITLE_PUBLISHFORLIVE)}
                                                    contentBody={<div><p>{t(LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_MODAL_CONTENT_CONFIRMPUBLISHFORLIVE)}</p><p>{noTestAccountMessage}</p></div>}
                                                    confirmText={t(LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_MODAL_BUTTON_CONFIRM)}
                                                    confirmCallback={async () => { 
                                                        toggleModal(CONFIRM_LIVE_MODAL.name); 
                                                        await submitPlainForm();
                                                        const formData = new FormData()
                                                        for(let i = 0; i < values.LicenceTypeIds.length; i++){
                                                            formData.append(`licenceTypeIds[${i}]`, values.LicenceTypeIds[i])
                                                        }
                                                        
                                                        const params = postParams(formData);
                                                        await fetchRequest(`${POST_PREVIEW_SERVICE_PUBLISH_FOR_LIVE}`, params);
                                                    }}
                                                    cancelText={t(LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_MODAL_BUTTON_CANCEL)}
                                                    cancelCallback={() => toggleModal(CONFIRM_LIVE_MODAL.name)}
                                                />
                                            </Section>
                                            <Section
                                                type={1}
                                                sectionState={sectionState}
                                                sectionName={sectionName.LIVE}
                                                toggleSection={toggleSection}
                                            >
                                                <Row className="body-content">
                                                    <Col>
                                                        <Live
                                                            smartFormValues={smartFormValues}
                                                            data={liveData}
                                                            loader={loader}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Section>
                                        </SectionWrapper>
                                    </Col>
                                </Row>
                            </React.Fragment>
                        );
                    }}
                </SMARTForm>
            </Layout>
        </React.Fragment>
    );
}
