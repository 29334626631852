import React from 'react';
import { LAYOUT_TYPE } from '../../../../../../App/AppSettings';
import Section from '../../../../../../Section';
import CommonUraChangeUseApprovalLicenceDetails from './CommonUraChangeUseApprovalLicenceDetails';

class UraChangeUseApprovalApply extends React.Component {
    render() {
        const { sectionState, toggleSection, sectionName, licenceInformation } = this.props;

        return (
            licenceInformation ?
            (<Section
                type={LAYOUT_TYPE.FORM_CONTENT}
                sectionState={sectionState}
                sectionName={sectionName}
                toggleSection={toggleSection}
            >
               <CommonUraChangeUseApprovalLicenceDetails licenceInformation={licenceInformation} />
            </Section>) : null
        );
    }
}

export default UraChangeUseApprovalApply;