import React, { useEffect, useState } from 'react';
import { GET_CHECKOUT_HISTORY_URL, LAYOUT_TYPE } from '../../../App/AppSettings';
import Section from '../../../Section';
import { DataTable } from '../../../Shared/DataTable';
import { getCustomComponents } from '../../../Shared/DataTable/BaseTable.js';
import { getParams, useAsyncFetch } from '../../../Shared/Fetch';
import { toastError } from '../../../Shared/Forms';
import history from '../../../Shared/History';
import { useTranslation } from "react-i18next";
import { ERROR, getLangKey, LANGUAGE_KEYS } from '../../../Shared/Constants/LanguageKeys';

export default function CheckInCheckOutHistory(props) {

    const {t} = useTranslation();
    const { state } = history.location;
    const [checkInCheckOutHistoryData, setCheckInCheckOutHistoryData] = useState([])
    const [isCheckInCheckOutHistoryDataLoaded, setCheckInCheckOutHistoryDataLoaded] = useState(false)

    const response = useAsyncFetch(GET_CHECKOUT_HISTORY_URL + state.ID, getParams());

    useEffect(() => {
        async function setData() {
            const { IsSuccess, Messages, Data } = response.body;

            if (response.success && IsSuccess) {
                const checkInCheckOutData = Data ? Data : [];
                setCheckInCheckOutHistoryData(checkInCheckOutData);
                setCheckInCheckOutHistoryDataLoaded(true);
            }
            else {
                // Error message to be loaded here
                toastError(t(getLangKey(ERROR.BACKEND_ERROR_MESSAGE, Messages)), Messages);
            }
        }

        if (response) {
            setData()
        }
    }, [response, t])

    const setCheckInStatusTranslatedContent = (rowData) => {
        return <>{t(getLangKey(LANGUAGE_KEYS.BLS_INTERNALINBOX_CHECKINCHECKOUTSTATUS_TITLE_KEY, rowData.Action))}</>
    }

    const getCheckInStatusTranslatedContent = (rowData) => {
        return t(getLangKey(LANGUAGE_KEYS.BLS_INTERNALINBOX_CHECKINCHECKOUTSTATUS_TITLE_KEY, rowData.Action));
    }

    return (
        <>
            {isCheckInCheckOutHistoryDataLoaded &&
                <Section
                    type={LAYOUT_TYPE.FORM_CONTENT}
                    sectionName={props.sectionName}
                    sectionState={props.sectionState}
                    toggleSection={props.toggleSection}
                >
                    <DataTable
                        pageSize="5"
                        minFilterChars='2'
                        data={checkInCheckOutHistoryData}
                        noResultsMessage={t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_MESSAGE_PLACEHOLDER_NOCHECKOUT)}
                        components={getCustomComponents()}
                        columns={
                            {
                                User: { title: t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_TABLE_TITLE_EMAIL),  width: '5%', DBkey: 'Email' },
                                Action: { title: t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_TABLE_TITLE_ACTION),  width: '5%', DBkey: 'Action', setContent: setCheckInStatusTranslatedContent, getTranslatedString: getCheckInStatusTranslatedContent },
                                ActionDateTime: { title: t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_TABLE_TITLE_DATE),  width: '5%', DBkey: 'ActionDateTime', isDate: true }
                            }
                        }
                    />
                </Section>
            }
        </>
    )
}