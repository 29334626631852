import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import * as Yup from 'yup';
import { ADD_NOTIFICATION_TEMPLATE_URL, LAYOUT_TYPE, LIST_NOTIFICATION_TEMPLATE_CATEGORY_URL, NOTIFICATION_TEMPLATE_EMAIL_LIST } from '../../App/AppSettings';
import Layout from '../../Layout';
import { ERROR, SUCCESS } from '../../Localization/index';
import { fetchRequest, getParams, navigateTo } from '../../Shared/Actions';
import { FIELD_SIZE } from '../../Shared/Constants';
import { CreatableSelectList, Hidden, RichTextEditor, SMARTForm, Text, withSMARTWrapper } from '../../Shared/Forms';
import { DEFAULT_TEXT, DEFAULT_NAMESPACE } from '../../Shared/Constants/LanguageKeys.js';
import { toastError, toastSuccess } from '../../Shared/Forms/Toaster.js';
import PageFooter from '../../Shared/PageFooter';
import { RetrieveGlobalVariables } from './RetrieveGlobalVariables';

const FORM_CONTEXT = "NotificationTemplate";
const SERVER_URL = ADD_NOTIFICATION_TEMPLATE_URL;
const DROPDOWN_CATEGORY_URL = LIST_NOTIFICATION_TEMPLATE_CATEGORY_URL;

const RICHTEXT_TOOL_BAR = { options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'image', 'remove', 'history'] };

const FORM_INITIAL_VALUES = {
    TemplateName: '',
    Category: '',
    Type: 'EMAIL',
    TemplateSubjectHTML: '',
    TemplateSubject: '',
    TemplateBody: '',
    TemplateBodyHTML: '',
};

class EmailTemplateCreate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            values: FORM_INITIAL_VALUES,
            dropDownArrayCategory: [],
            globalVariableList: [],
            globalVariableListIsLoaded: false
        };
    }

    componentDidMount = async () => {
        await this.dropdownList(DROPDOWN_CATEGORY_URL, "dropDownArrayCategory");
        await this.getGlobalVariables();
    }

    dropdownList = async (url, dropDownName) => {
        const params = {
            Type: FORM_INITIAL_VALUES.Type
        }
        const response = await fetchRequest(url, getParams(params), false);

        const { Data, IsSuccess, MessageLanguageKey, Message } = response.body;
        let dropDownArray = [];
        if (response.success) {
            if (IsSuccess) {
                Data.forEach(element => {
                    dropDownArray.push({ 'label': element, 'value': element });
                });
                this.setState({ [dropDownName]: dropDownArray })
            } else {
                toastError(this.props.getMessage(MessageLanguageKey, Message));
                return null;
            }
        } else {
            toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
            return null;
        }
        return dropDownArray;
    }

    getGlobalVariables = async () => {
        this.setState({
            globalVariableList: await RetrieveGlobalVariables(),
            globalVariableListIsLoaded: true
        })
    }

    // Fn: Get Yup form validations
    getFormValidations = () => {
        let errorRequired = this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_REQUIRED, ERROR.REQUIRED);
        let minLength = this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_MINLENGTH, ERROR.MIN_LENGTH_LIMIT);
        let maxLength = this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_MAXLENGTH, ERROR.MAX_LENGTH_LIMIT);

        return Yup.object().shape({
            TemplateName: Yup.string()
                .required(errorRequired)
                .min(0, minLength)
                .max(140, maxLength),
            Type: Yup.string()
                .required(errorRequired)
                .min(0, minLength)
                .max(140, maxLength),
            TemplateSubjectHTML: Yup.string()
                .required(errorRequired),
            TemplateBodyHTML: Yup.string()
                .required(errorRequired)
        });
    }

    submitCallback = ({ response }) => {
        if (response.success) {
            const { IsSuccess, Message, MessageLanguageKey} = response.body;
            if (IsSuccess) {
                this.setState({ values: FORM_INITIAL_VALUES });
                navigateTo(NOTIFICATION_TEMPLATE_EMAIL_LIST);
                toastSuccess(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_NOTITEMPLATESTATIC_SUCCESS_TITLE_CREATEEMAILTEMPLATE, SUCCESS.CREATE_SUCCESS), DEFAULT_TEXT.EMAIL_TEMPLATE);
            }
            else {
                toastError(this.props.getMessage(MessageLanguageKey, Message));
            }
        } else {
            toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
        }
    }

    massageValues = (values, submitPlainForm) => {
        //remove href if classname wysiwyg-mention is found in templateSubjectHTML and templateBodyHTML
        values.TemplateSubjectHTML = this.removeAttributeFromClassName(values.TemplateSubjectHTML, "wysiwyg-mention", "href");
        values.TemplateBodyHTML = this.removeAttributeFromClassName(values.TemplateBodyHTML, "wysiwyg-mention", "href");

        submitPlainForm();
    }

    removeAttributeFromClassName(value, className, attribute) {
        const el = document.createElement('div');
        el.innerHTML = value;
        var mentions = el.getElementsByClassName(className);
        for (var i = 0; i < mentions.length; i++) {
            mentions[i].removeAttribute(attribute);
        }
        return el.innerHTML;
    }

    render() {
        let { globalVariableList, globalVariableListIsLoaded } = this.state;
        return (
            <React.Fragment>
                <Layout type={LAYOUT_TYPE.FORM} title=
                    {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_NOTITEMPLATESTATIC_CONTENT_TITLE_CREATEEMAILTEMPLATE, DEFAULT_TEXT.CREATE_EMAIL_TEMPLATE)}>
                    <SMARTForm
                        formContext={FORM_CONTEXT}
                        formValues={this.state.values}
                        validationSchema={this.getFormValidations()}
                        validateOnSubmit={true}
                        serverURL={SERVER_URL}
                        isDummy={false}
                        resetFormAfterSubmit={true}
                        submitCallback={this.submitCallback}
                    >
                        {({ values, onChange, onChangeField, submitPlainForm, errors }) => (
                            <React.Fragment>
                                <Row className="body-content admin-content-wrapper">
                                    <Col>
                                        <Text
                                            name="TemplateName"
                                            value={values.TemplateName}
                                            placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_NOTITEMPLATESTATIC_FORM_PLACEHOLDER_TEMPLATENAME, DEFAULT_TEXT.TEMPLATE_NAME)}
                                            onChange={onChange}
                                            minLength={0}
                                            maxLength={140}
                                            inputSize={FIELD_SIZE.XLARGE}
                                            labelSize={FIELD_SIZE.XSMALL}
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_NOTITEMPLATESTATIC_FORM_TITLE_TEMPLATENAME, DEFAULT_TEXT.TEMPLATE_NAME)}
                                            error={errors.TemplateName}
                                            required
                                        />
                                        <Text
                                            name="Type"
                                            value={values.Type}
                                            onChange={onChange}
                                            minLength={0}
                                            maxLength={140}
                                            inputSize={FIELD_SIZE.XLARGE}
                                            labelSize={FIELD_SIZE.XSMALL}
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_NOTITEMPLATESTATIC_FORM_TITLE_TYPE, DEFAULT_TEXT.TYPE)}
                                            error={errors.Type}
                                            readOnly
                                        />
                                        <CreatableSelectList
                                            isClearable={true}
                                            name="Category"
                                            value={values.Category}
                                            placeholder="Category"
                                            onChangeField={onChangeField}
                                            minLength={0}
                                            maxLength={140}
                                            inputSize={FIELD_SIZE.XLARGE}
                                            labelSize={FIELD_SIZE.XSMALL}
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_NOTITEMPLATESTATIC_FORM_TITLE_CATEGORY, DEFAULT_TEXT.CATEGORY)}
                                            helpLabel={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_NOTITEMPLATESTATIC_FORM_HELPTEXT_CATEGORY, DEFAULT_TEXT.HELPTEXT_NOTITEMP_CATEGORY)}
                                            options={this.state.dropDownArrayCategory}
                                            isMulti={false}
                                            error={errors.Category}
                                        />
                                        {globalVariableListIsLoaded &&
                                            <div>
                                                <RichTextEditor
                                                    name="TemplateSubjectHTML"
                                                    value={values.TemplateSubjectHTML}
                                                    onChangeField={onChangeField}
                                                    inputSize={FIELD_SIZE.XLARGE}
                                                    labelSize={FIELD_SIZE.XSMALL}
                                                    label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_NOTITEMPLATESTATIC_FORM_TITLE_TEMPLATESUBJECT, DEFAULT_TEXT.TEMPLATE_SUBJECT)}
                                                    showHtml={false}
                                                    savePlaintext={true}
                                                    plaintextName="TemplateSubject"
                                                    editorClassName="Email-TemplateSubject-demo-editor"
                                                    wrapperClassName="Email-TemplateSubject-demo-wrapper"
                                                    toolbarHidden={true}
                                                    suggestions={globalVariableList}
                                                    error={errors.TemplateSubjectHTML}
                                                    helpLabel={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_NOTITEMPLATESTATIC_FORM_HELPTEXT_TEMPLATESUBJECT, DEFAULT_TEXT.HELPTEXT_NOTITEMP_TEMPLATESUBJECT)}
                                                    required
                                                />
                                                <Hidden
                                                    className="d-none"
                                                    name="TemplateSubject"
                                                    value={values.TemplateSubject}
                                                />
                                                <RichTextEditor
                                                    name="TemplateBodyHTML"
                                                    value={values.TemplateBodyHTML}
                                                    onChangeField={onChangeField}
                                                    inputSize={FIELD_SIZE.XLARGE}
                                                    labelSize={FIELD_SIZE.XSMALL}
                                                    label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_NOTITEMPLATESTATIC_FORM_TITLE_TEMPLATEBODY, DEFAULT_TEXT.TEMPLATE_BODY)}
                                                    showHtml={false}
                                                    savePlaintext={true}
                                                    editorClassName="Email-TemplateBody-demo-editor"
                                                    plaintextName="TemplateBody"
                                                    toolbar={RICHTEXT_TOOL_BAR}
                                                    suggestions={globalVariableList}
                                                    error={errors.TemplateBodyHTML}
                                                    helpLabel={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_NOTITEMPLATESTATIC_FORM_HELPTEXT_TEMPLATEBODY, DEFAULT_TEXT.HELPTEXT_NOTITEMP_TEMPLATEBODY)}
                                                    required
                                                />
                                            </div>
                                        }
                                    </Col>
                                </Row>

                                <PageFooter type={LAYOUT_TYPE.FORM}>
                                    <Button color="backward" size="sm" type="button" onClick={() => navigateTo(NOTIFICATION_TEMPLATE_EMAIL_LIST)}>
                                        {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_NOTITEMPLATESTATIC_NAVIGATION_BUTTON_BACK, DEFAULT_TEXT.BACK)}
                                    </Button>
                                    <Button color="forward" size="sm" type="button" onClick={() => this.massageValues(values, submitPlainForm)}>
                                        {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_NOTITEMPLATESTATIC_NAVIGATION_BUTTON_CREATE, DEFAULT_TEXT.CREATE)}
                                    </Button>
                                </PageFooter>

                            </React.Fragment>
                        )}
                    </SMARTForm>
                </Layout>
            </React.Fragment>
        );
    }
}

export default withSMARTWrapper(EmailTemplateCreate);