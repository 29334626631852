
import React from 'react';
import { DATE_DISPLAY_FORMAT, MASK, REGEX } from '../../App/AppSettings';
import MultiFileUpload from '../../Content/FormComponents/MultiFileUpload';
import { DELETE_FILES_ARRAY, FIELD_SIZE } from '../../Shared/Constants';
import { Paragraph, Text } from '../../Shared/Forms';
import Checkbox from '../../Shared/Forms/Checkbox';
import DateInput from '../../Shared/Forms/DateInput';
import MaskInput from '../../Shared/Forms/MaskedInput';
import NumberInput from '../../Shared/Forms/NumberInput';
import Radio from '../../Shared/Forms/Radio';
import SelectList from '../../Shared/Forms/SelectList';
import { withTranslation } from 'react-i18next';
import { LANGUAGE_KEYS, getLangKey, ERROR } from '../../Shared/Constants/LanguageKeys';

class RenderFieldComponent extends React.Component {

    constructor(props) {
        super(props);

        const { field, optionDataList } = this.props;
        const isRemote = field.DataSource !== undefined && field.DataSource === 'Remote';
        const isLocal = field.Data && (field.DataSource === undefined || field.DataSource === 'Local');
        let newOptionsList = [];

        if (isRemote) {
            newOptionsList = optionDataList[field.DataParameters.Category];
        } else if (isLocal) {
            newOptionsList = this.getLocalList(field);
        }
        else {
            newOptionsList = [];
        }

        this.state = {
            optionDataList: newOptionsList
        };
    }

    getLocalList = field => field.Data.map(({ Value }) => ({ label: Value, value: Value, key: Value }));

    
    errorMessage = (value, fieldsError, field) => {
        const { t } = this.props;
        if (!value && fieldsError&& field.Validation) {
            if(field.Validation.Required){
                return ((ERROR.LABEL) + t(ERROR.REQUIRED));
            }
        } else if (field.InputType === 'Email' && !REGEX.EMAIL.test(value) && fieldsError) {
            return t(ERROR.EMAIL_INVALID);
        } else if (field.InputType === 'Number' && !REGEX.NUMBER.test(value) && fieldsError) {
            return t(ERROR.NUMBER_INVALID);
        } else if (field.InputType === 'FileUpload' && value.length <= 0 && fieldsError) {
            return `${t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_GLOBALSUPPORTINGDOC_DOCNAME_KEY, field.Name))} ${t(ERROR.REQUIRED)}`;
        } else {
            if (field.Validation && field.Validation.MinimumLength && value.length < field.Validation.MinimumLength && fieldsError) {
                return (t(ERROR.MIN_LENGTH_LIMIT) + (ERROR.MIN_LENGTH_LABEL));
            }
            if (field.Validation && field.Validation.MaximumLength && value.length > field.Validation.MaximumLength && fieldsError) {
                return (t(ERROR.MAX_LENGTH_LIMIT) + (ERROR.MAX_LENGTH_LABEL));
            }
        }
    };

    render() {
        // De-structure props to be used
        const { additionalFieldsError, formType, field, values, onChange, onChangeField, t } = this.props;
        const { optionDataList } = this.state;

        const isFieldDisabled = field.InputDisabled === t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_FIELD_YES) ? true : false;

        switch (field.InputType) {
            case 'Text':
            case 'Email':
                return <Text
                    // className="col-form-label-text"
                    name={`${formType}.${field.Name}`}
                    placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_PLACEHOLDERTEXT_KEY, field.Name))}
                    label={t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_FIELD_KEY, field.Name))}
                    inputSize={FIELD_SIZE.MEDIUM}
                    minLength={field.Validation && field.Validation.MinimumLength ? field.Validation.MinimumLength : undefined}
                    maxLength={field.Validation && field.Validation.MaximumLength ? field.Validation.MaximumLength : undefined}
                    value={values[formType][field.Name]}
                    onChange={onChange}
                    error={this.errorMessage(values[formType][field.Name], additionalFieldsError, field)}
                    key={t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_FIELD_KEY, field.Name))}
                    required={field.Validation.Required === true}
                    labelSize={FIELD_SIZE.MEDIUM}
                    readOnly={isFieldDisabled}
                >
                </Text>;
            case 'Radio':
                return <Radio
                    name={`${formType}.${field.Name}`}
                    placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_PLACEHOLDERTEXT_KEY, field.Name))}
                    label={t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_FIELD_KEY, field.Name))}
                    options={optionDataList}
                    value={values[formType][field.Name]}
                    onChange={onChange}
                    labelSize={FIELD_SIZE.MEDIUM}
                    inputSize={FIELD_SIZE.MEDIUM}
                    error={this.errorMessage(values[formType][field.Name], additionalFieldsError, field)}
                    key={t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_FIELD_KEY, field.Name))}
                    required={field.Validation.Required === true}
                    readOnly={isFieldDisabled}
                >
                </Radio>;

            case 'Date':
                return <DateInput
                    name={`${formType}.${field.Name}`}
                    placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_PLACEHOLDERTEXT_KEY, field.Name))}
                    label={t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_FIELD_KEY, field.Name))}
                    value={values[formType][field.Name]}
                    onChangeField={onChangeField}
                    time={false}
                    date={true}
                    min={field.Validation.MinimumValue}
                    max={field.Validation.MaximumValue === 'Current Date' ? (new Date()).setHours(0, 0, 0, 0) : field.Validation.MaximumValue}
                    format={DATE_DISPLAY_FORMAT.DATE}
                    labelSize={FIELD_SIZE.MEDIUM}
                    inputSize={FIELD_SIZE.MEDIUM}
                    error={this.errorMessage(values[formType][field.Name], additionalFieldsError, field)}
                    key={t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_FIELD_KEY, field.Name))}
                    required={field.Validation.Required === true}
                    isDisabled={isFieldDisabled}
                >
                </DateInput>;

            case 'Time':
                return <DateInput
                    name={`${formType}.${field.Name}`}
                    placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_PLACEHOLDERTEXT_KEY, field.Name))}
                    label={t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_FIELD_KEY, field.Name))}
                    value={values[formType][field.Name]}
                    onChangeField={onChangeField}
                    time={true}
                    date={false}
                    min={field.Validation.MinimumValue}
                    max={field.Validation.MaximumValue}
                    format={DATE_DISPLAY_FORMAT.TIME}
                    labelSize={FIELD_SIZE.MEDIUM}
                    inputSize={FIELD_SIZE.MEDIUM}
                    error={this.errorMessage(values[formType][field.Name], additionalFieldsError, field)}
                    key={t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_FIELD_KEY, field.Name))}
                    required={field.Validation.Required === true}
                    isDisabled={isFieldDisabled}
                >
                </DateInput>;

            case 'DateTime':
                return <DateInput
                    name={`${formType}.${field.Name}`}
                    placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_PLACEHOLDERTEXT_KEY, field.Name))}
                    label={t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_FIELD_KEY, field.Name))}
                    value={values[formType][field.Name]}
                    onChangeField={onChangeField}
                    time={true}
                    date={true}
                    min={field.Validation.MinimumValue}
                    max={field.Validation.MaximumValue === 'Current Date' ? (new Date()).setHours(0, 0, 0, 0) : field.Validation.MaximumValue}
                    format={DATE_DISPLAY_FORMAT.TIME}
                    labelSize={FIELD_SIZE.MEDIUM}
                    inputSize={FIELD_SIZE.MEDIUM}
                    error={this.errorMessage(values[formType][field.Name], additionalFieldsError, field)}
                    key={t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_FIELD_KEY, field.Name))}
                    required={field.Validation.Required === true}
                    isDisabled={isFieldDisabled}
                >
                </DateInput>;

            case 'DropDownList':
                return <SelectList
                    name={`${formType}.${field.Name}`}
                    placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_PLACEHOLDERTEXT_KEY, field.Name))}
                    label={t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_FIELD_KEY, field.Name))}
                    value={values[formType][field.Name]}
                    options={optionDataList}
                    onChangeField={onChangeField}
                    isMulti={false}
                    isClearable={true}
                    labelSize={FIELD_SIZE.MEDIUM}
                    inputSize={FIELD_SIZE.MEDIUM}
                    error={this.errorMessage(values[formType][field.Name], additionalFieldsError, field)}
                    key={t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_FIELD_KEY, field.Name))}
                    required={field.Validation.Required === true}
                    isDisabled={isFieldDisabled}
                >
                </SelectList>;

            case 'Number':
                return <NumberInput
                    name={`${formType}.${field.Name}`}
                    value={values[formType][field.Name]}
                    placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_PLACEHOLDERTEXT_KEY, field.Name))}
                    label={t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_FIELD_KEY, field.Name))}
                    onChangeField={onChangeField}
                    unitOfMeasurement=""
                    step={1.0}
                    min={field.Validation.MinimumValue}
                    max={field.Validation && field.Validation.MaximumLength ? field.Validation.MaximumLength : undefined}
                    labelSize={FIELD_SIZE.MEDIUM}
                    inputSize={FIELD_SIZE.MEDIUM}
                    error={this.errorMessage(values[formType][field.Name], additionalFieldsError, field)}
                    key={t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_FIELD_KEY, field.Name))}
                    required={field.Validation.Required === true}>
                </NumberInput>;

            case 'Paragraph':
                return <Paragraph
                    name={`${formType}.${field.Name}`}
                    value={values[formType][field.Name]}
                    placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_PLACEHOLDERTEXT_KEY, field.Name))}
                    label={t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_FIELD_KEY, field.Name))}
                    onChange={onChange}
                    rows={3}
                    minLength={field.Validation.MinimumLength}
                    maxLength={field.Validation.MaximumLength}
                    labelSize={FIELD_SIZE.MEDIUM}
                    inputSize={FIELD_SIZE.MEDIUM}
                    error={this.errorMessage(values[formType][field.Name], additionalFieldsError, field)}
                    key={t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_FIELD_KEY, field.Name))}
                    required={field.Validation.Required === true}
                    readOnly={isFieldDisabled}
                >
                </Paragraph>;

            case 'Checkbox':
                return <Checkbox
                    name={`${formType}.${field.Name}`}
                    value={values[formType][field.Name]}
                    label={t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_FIELD_KEY, field.Name))}
                    options={optionDataList}
                    onChangeField={onChangeField}
                    labelSize={FIELD_SIZE.MEDIUM}
                    inputSize={FIELD_SIZE.MEDIUM}
                    helpLabel={t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_HELPTEXT_KEY, field.Name))}
                    error={this.errorMessage(values[formType][field.Name], additionalFieldsError, field)}
                    required={field.Validation ? (field.Validation.Required === true) : false}>
                </Checkbox>;

            case 'Multiselect':
                return <SelectList
                    name={`${formType}.${field.Name}`}
                    placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_PLACEHOLDERTEXT_KEY, field.Name))}
                    label={t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_FIELD_KEY, field.Name))}
                    value={values[formType][field.Name]}
                    options={optionDataList}
                    onChangeField={onChangeField}
                    isMulti={true}
                    isClearable={true}
                    labelSize={FIELD_SIZE.MEDIUM}
                    inputSize={FIELD_SIZE.MEDIUM}
                    helpLabel={t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_HELPTEXT_KEY, field.Name))}
                    error={this.errorMessage(values[formType][field.Name], additionalFieldsError, field)}
                    required={field.Validation ? (field.Validation.Required === true) : false}
                    isDisabled={isFieldDisabled}>
                </SelectList>;

            case 'MaskedInput':
                return <MaskInput
                    name={`${formType}.${field.Name}`}
                    placeholder={t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_PLACEHOLDERTEXT_KEY, field.Name))}
                    label={t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_FIELD_KEY, field.Name))}
                    onChange={onChange}
                    mask={MASK.TELEPHONE}
                    labelSize={FIELD_SIZE.MEDIUM}
                    inputSize={FIELD_SIZE.MEDIUM}
                    value={values[formType][field.Name]}
                    helpLabel={t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_HELPTEXT_KEY, field.Name))}
                    error={this.errorMessage(values[formType][field.Name], additionalFieldsError, field)}
                    required={field.Validation ? field.Validation.Required === true : false}
                >
                </MaskInput>;

            case 'FileUpload': //this case for now only support for global supporting doc. And need to use field.name (guid) intead id
                return <MultiFileUpload
                    name={`${formType}.${field.Name}`}
                    label={t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_GLOBALSUPPORTINGDOC_DOCNAME_KEY, field.Name))}
                    description={t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_GLOBALSUPPORTINGDOC_DOCDESC_KEY, field.Name))}
                    fileList={values[formType][field.Name]}
                    onChangeField={onChangeField}
                    key={t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_GLOBALSUPPORTINGDOC_DOCNAME_KEY, field.Name))}
                    error={this.errorMessage(values[formType][field.Name], additionalFieldsError, field)}
                    deleteArray={values[DELETE_FILES_ARRAY]}
                    required={field.Validation.Required === true}
                />;

            default:
                return null;
        }
    }
}

export default withTranslation()(RenderFieldComponent);
