import React from 'react';
import ErrorPage from './ErrorPage';
import { navigateTo } from '../Actions';


export const withPageNotFound = (FormComponent) => {
    class PageNotFound extends React.Component {
        constructor(props) {
            super(props);
            this.state = { isError: false, isPageNotFound: false };
        }

        // Fn: Show PageNotFound
        displayPageNotFound = () => {
            this.setState({ isError: true });
        };

        setPageNotFound = (isPageNotFound) => {
            this.setState({ isPageNotFound: isPageNotFound });
        }

        render() {

            // Conditionally render Not found page based on whether isError is toggled
            return (this.state.isError === true || this.state.isPageNotFound) ?
                (
                    <ErrorPage
                        errorHeader="404 Page Not Found"
                        errorContent="The page you are finding cannot be found. Please try again."
                        reloadCallback={() => navigateTo("/")}
                        reloadButtonText="Home"
                    />
                ) :
                (
                    <FormComponent
                        {...this.props}
                        pageNotFound={this.displayPageNotFound}
                        setPageNotFound={this.setPageNotFound}
                    />
                );
        }
    }

    return PageNotFound;
};