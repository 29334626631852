import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { GET_FILE_URL, LAYOUT_TYPE, TABLE_LAYOUT } from '../../../../App/AppSettings';
import Section from '../../../../Section';
import SubSection from '../../../../Section/SubSection';
import { ActionButton, DataTable } from '../../../../Shared/DataTable';
import { getCustomComponents } from '../../../../Shared/DataTable/BaseTable.js';
import { Plaintext } from '../../../../Shared/Forms';
import { ModalConfirm } from '../../../../Shared/Modal';
import FileUploadSection from '../../../FormComponents/FileUploadSection';
import { additionalFormRegex } from '../../../FormComponents/FormUtils';
import { BigPreviewTable, SmallPreviewTable } from '../../../FormComponents/TableComponent';
import { LANGUAGE_KEYS, getLangKey } from '../../../../Shared/Constants/LanguageKeys';
import utils from 'formiojs/utils';
import { fetchRequest, getParams } from '../../../../Shared/Actions/index.js';

const MODAL_NAMES = { AdditionalFormViewHistory: 'AdditionalFormViewHistory' };
const IS_UPPER = true;

class ViewAdditionalInformation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    formIOFieldsList = [];
    formIOSections = [];
    formIOFields = [];
    formIODatagridFields = [];
    formIODatagrids = [];
    formIODatagridFieldValues = [];

    // Open View Modal
    toggleModal = modalName => {
        this.setState({
            modalState: (this.state.modalState !== modalName) ? modalName : undefined
        });
    };
    viewAdditionalForm = data => {
        this.toggleModal(MODAL_NAMES.AdditionalFormViewHistory);
        this.setState({
            modalId: data.griddleKey
        });
    };

    getFormIODataAsync = async (formIOUrl) => {
        const formIOData = await fetchRequest(formIOUrl, getParams(), false);
        const flattened = utils.flattenComponents(formIOData.body.components, true);
        const flattenedArray = Object.entries(flattened).filter(form => !form[0].includes("column"));
        this.formIOFieldsList = flattenedArray.map(([key, value]) => ({
            label: value.title ?? value.label,
            value: key,
            type: value.type
        }));
        this.mapFormIOFieldsToSections(this.formIOFieldsList);
    }

    mapFormIOFieldsToSections = (flattenedArray) => {
        flattenedArray.forEach(element => {
            if (element.type === "panel" && !this.formIOSections.find(section => section.value === element.value)) {
                this.formIOSections.push(element);
            } else if (element.type === "datagrid" && !this.formIODatagrids.find(datagrid => datagrid.value === element.value)) {
                const parentSection = this.formIOSections.find(section => element.value.includes(section.value));
                if (parentSection && !this.formIODatagrids.find(existingdatagrid => existingdatagrid[0].value === element.value)) {
                    this.formIODatagrids.push([element, parentSection.value]);
                }
            } else if (element.type !== "panel" && element.type !== "datagrid" && !this.formIOFields.find(field => field[0].value === element.value)) {
                const parentGrid = this.formIODatagrids.find(datagrid => element.value.includes(datagrid[0].value));
                if (parentGrid) {
                    if (!this.formIODatagridFields.find(field => field[0].value === element.value))
                        this.formIODatagridFields.push([element, parentGrid[0].value]);
                } else {
                    this.formIOFields.push(([element, this.formIOSections.length - 1]));
                }
            } 
        });
    }

    mapFormIODatagridValueToField = (values) => {
        if (this.formIODatagridFieldValues.length < Object.keys(values).filter(key => key.includes('Datagrid')).length) {
            for (const individualFieldKey in values) {
                const matchingDatagridField = this.formIODatagridFields.find(field => field[0].value.split('.')[field[0].value.split('.').length - 1] === 
                                                                                    individualFieldKey.split('.')[individualFieldKey.split('.').length - 1]);
                if (matchingDatagridField) {
                    this.formIODatagridFieldValues.push([matchingDatagridField[0], matchingDatagridField[1], values[individualFieldKey]]); 
                }
            }
        }
    }

    render() {
        const { t, toggleSection, sectionState, additionalFormDefinition } = this.props;
        const { modalState } = this.state;
        const latestAdditionalFormDefinition = additionalFormDefinition.length > 0 ? additionalFormDefinition[0]['AdditionalFormDefinition'] : [];
        const latestAdditionalFormValues = additionalFormDefinition.length > 0 ? additionalFormDefinition[0]['Values'] : null;
        if (latestAdditionalFormDefinition.FormIOUrl) {
            this.getFormIODataAsync(latestAdditionalFormDefinition.FormIOUrl);
            this.mapFormIODatagridValueToField(latestAdditionalFormValues);
        }

        return (
            <Section
                type={LAYOUT_TYPE.FORM_CONTENT}
                sectionState={sectionState}
                sectionName={t(getLangKey(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_CONTENT_TITLE_KEY ,additionalFormRegex(latestAdditionalFormDefinition.FormType)))}
                toggleSection={toggleSection}
            >
                {
                    latestAdditionalFormDefinition.Sections && latestAdditionalFormDefinition.Sections.length > 0 &&
                    latestAdditionalFormDefinition.Sections.map((section, index) => {
                        return (
                            <SubSection key={index} orientation="left" title={t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_SECTION_KEY, section.Name))}>
                                {section.Fields && section.Fields.map((field, findex) => {
                                    return (
                                        <div key={findex}>
                                            {
                                                <Plaintext
                                                    key={findex}
                                                    label={t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_FIELD_KEY, field.Name))}
                                                    name={field.Name}
                                                    value={latestAdditionalFormValues != null && latestAdditionalFormValues[field.Name]}
                                                    isUpper={IS_UPPER}
                                                />
                                            }
                                        </div>
                                    );
                                })}
                                {section.Table && (section.Table.Fields.length <= TABLE_LAYOUT.COLUMNS) &&
                                    <SmallPreviewTable
                                        section={section}
                                        values={latestAdditionalFormValues != null && latestAdditionalFormValues}
                                    />
                                }
                                {section.Table && (section.Table.Fields.length > TABLE_LAYOUT.COLUMNS) &&
                                    <BigPreviewTable
                                        section={section}
                                        values={latestAdditionalFormValues != null && latestAdditionalFormValues}
                                    />
                                }
                            </SubSection>
                        );
                    })
                }
                {
                    latestAdditionalFormDefinition.FormIOUrl && 
                    this.formIOSections.map((section, index) => {
                        return <SubSection key={index} orientation="left" title={section.label}>
                            {this.formIOFields.filter(field => field[1] === index).map((field, findex) => {
                                return <Plaintext
                                    key={findex}
                                    label={field[0].label}
                                    name={field[0].label}
                                    value={latestAdditionalFormValues[Object.keys(latestAdditionalFormValues).filter(key => key.includes(field[0].value))]}
                                    isUpper={IS_UPPER}
                                />
                            })}
                        </SubSection>
                    })
                }
                {
                    additionalFormDefinition.length > 0 &&
                    additionalFormDefinition[0]['SupportingDocument'] &&
                    additionalFormDefinition[0]['SupportingDocument'].length !== 0 &&
                    <SubSection orientation="left" title={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_SECTION_SUPPORTINGDOCUMENT)}>
                        <FileUploadSection
                            name="SupportingDocuments"
                            fileList={additionalFormDefinition[0]['SupportingDocument']}
                            getFileURL={GET_FILE_URL}
                            isUpper={IS_UPPER}
                            readonly
                        />
                    </SubSection>
                }
                {additionalFormDefinition.length > 0 &&
                    <SubSection orientation="left" title={t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_CONTENT_PAGINATION_HISTORY)}>
                        <DataTable
                            pageSize="5"
                            minFilterChars='2'
                            data={additionalFormDefinition.length > 0 && additionalFormDefinition}
                            noResultsMessage={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_MESSAGE_PLACEHOLDER_NODECLARATION)}
                            components={getCustomComponents()}
                            columns={
                                {
                                    RunningNumber: { title: t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_TABLE_TITLE_NUMBER), width: '5%' },
                                    ActionDate: { title: t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_TABLE_INFORMATION_ACTIONDATE), width: '40%', DBkey: 'CreatedDate', isDate: true },
                                    ProcessedBy: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEDETAILS_TABLE_TITLE_PROCESSEDBY), width: '35%', DBkey: 'ProcessedBy' },
                                    Actions: { title: t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_TABLE_TITLE_ACTION), width: '25%' }
                                }
                            }
                            renderActions={({ RowData }) => (
                                <React.Fragment>
                                    <ActionButton
                                        tooltip={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_VIEW)}
                                        color='forward'
                                        onClick={() => this.viewAdditionalForm(RowData)}
                                    >
                                        <FontAwesomeIcon icon={faEye} />
                                    </ActionButton>
                                </React.Fragment>
                            )}
                        />
                        <ModalConfirm
                            isOpen={(modalState === MODAL_NAMES.AdditionalFormViewHistory)}
                            contentHeader={
                                additionalFormDefinition[this.state.modalId] &&
                                t(getLangKey(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_CONTENT_TITLE_KEY, additionalFormRegex(additionalFormDefinition[this.state.modalId]['AdditionalFormDefinition'].FormType)))
                            }
                            cancelText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_CANCEL)}
                            cancelCallback={() => this.toggleModal(MODAL_NAMES.AdditionalFormViewHistory)}
                            contentBody={
                                <div>
                                    {
                                        this.state.modalId !== undefined &&
                                        additionalFormDefinition[this.state.modalId]['AdditionalFormDefinition'].Sections.length !== 0 &&
                                        additionalFormDefinition[this.state.modalId]['AdditionalFormDefinition'].Sections.map((section, index) => {
                                            return (
                                                <SubSection key={index} orientation="left" title={t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_SECTION_KEY, section.Name))}>
                                                    {section.Fields && section.Fields.map((field, findex) => {
                                                        return (
                                                            <div key={findex}>
                                                                {
                                                                    <Plaintext
                                                                        key={findex}
                                                                        label={t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_FIELD_KEY, field.Name))}
                                                                        name={field.Name}
                                                                        value={additionalFormDefinition[this.state.modalId]['Values'][field.Name]}
                                                                        isUpper={IS_UPPER}
                                                                    />
                                                                }
                                                            </div>
                                                        );
                                                    })}
                                                    {section.Table && (section.Table.Fields.length <= TABLE_LAYOUT.COLUMNS) &&
                                                        <SmallPreviewTable
                                                            section={section}
                                                            values={additionalFormDefinition[this.state.modalId]['Values']}
                                                        />
                                                    }
                                                    {section.Table && (section.Table.Fields.length > TABLE_LAYOUT.COLUMNS) &&
                                                        <BigPreviewTable
                                                            section={section}
                                                            values={additionalFormDefinition[this.state.modalId]['Values']}
                                                        />
                                                    }
                                                </SubSection>
                                            );
                                        })
                                    }
                                    {
                                        additionalFormDefinition[this.state.modalId] &&
                                        additionalFormDefinition[this.state.modalId]['SupportingDocument'] &&
                                        additionalFormDefinition[this.state.modalId]['SupportingDocument'].length > 0 &&
                                        < FileUploadSection
                                            name="SupportingDocuments"
                                            fileList={additionalFormDefinition[this.state.modalId]['SupportingDocument']}
                                            getFileURL={GET_FILE_URL}
                                            isUpper={IS_UPPER}
                                            readonly
                                        />
                                    }
                                </div>
                            }
                        />
                    </SubSection>
                }
            </Section>
        );
    }
}

export default withTranslation()(ViewAdditionalInformation);
