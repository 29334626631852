import React, { useState, useEffect, useMemo } from 'react';
import { GET_FILE_URL, LAYOUT_TYPE, GET_LICENCE_DOCUMENT_FILE_URL, DOCUMENT_TYPE_ISSUED, DOCUMENT_TYPE_SUBMITTED, DOCUMENT_TYPE_ALL } from '../../../App/AppSettings';
import Section from '../../../Section';
import FileUploadSection from '../../FormComponents/FileUploadSection';
import Select from "react-select";
import { useTranslation } from 'react-i18next';
import { toastError } from '../../../Shared/Forms';
import { fetchRequest, getParams } from '../../../Shared/Actions';
import { CODETABLE_LIST_URL } from '../../.././App/AppSettings';
import { ERROR, DEFAULT_NAMESPACE } from '../../../Shared/Constants/LanguageKeys';

const IS_UPPER = true;

export default function SupportingDocuments(props) {
    const [documentTypes, setDocumentTypes] = useState([]);
    const { supportingDocData = [], licenceDocuments = [] } = props;
    const licenceDocumentsTransformed = licenceDocuments.map(x => {
        return {
            FileDescription: x.DocumentName,
            ContentType: x.ContentType,
            ContentLength: x.ContentLength,
            DocumentType: x.DocumentType,
            Id: x.Id,
            Filename: x.Filename
        }
    });
    const allDocuments = useMemo(() => {
        return supportingDocData.concat(licenceDocumentsTransformed).sort((a, b) => a.FileDescription.localeCompare(b.FileDescription));
    }, [licenceDocumentsTransformed, supportingDocData])

    const [docs, setDocs] = useState(allDocuments);

    useEffect(() => {
        async function fetchData() {
            const params = {
                codeCategoryFilter: 'DocumentType'
            }
            const response = await fetchRequest(CODETABLE_LIST_URL, getParams(params), false);
            const { Data, IsSuccess } = response.body;
            let dropDownArray = [];
            if (response.success) {
                if (IsSuccess) {
                    Data.Data.forEach(element => {
                        dropDownArray.push({ 'label': element.CodeTitle, 'value': element.CodeValue });
                    });
                    setDocumentTypes(dropDownArray)
                } else {
                    toastError(response.body.Messages.toString());
                }
            } else {
                toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
            }
        }
        props.showSelectDocumentType && fetchData();
    }, []);

    const onDocumentTypeFilterChange = (option) => {
        switch (option.label) {
            case DOCUMENT_TYPE_ISSUED:
                setDocs(licenceDocumentsTransformed);
                break;
            case DOCUMENT_TYPE_SUBMITTED:
                setDocs(supportingDocData);
                break;
            default:
                setDocs(allDocuments);
                break;
        }
    }

    return (
        <>
            {supportingDocData &&
                <Section
                    type={LAYOUT_TYPE.FORM_CONTENT}
                    sectionName={props.sectionName}
                    sectionState={props.sectionState}
                    toggleSection={props.toggleSection}
                >
                    {props.showSelectDocumentType && <Select
                        defaultValue={documentTypes.length > 0 ? documentTypes[0] : { label: DOCUMENT_TYPE_ALL, value: DOCUMENT_TYPE_ALL }}
                        onChange={onDocumentTypeFilterChange}
                        options={documentTypes}
                        maxMenuHeight={150}
                        menuPlacement="auto"
                        styles={{
                            menuPortal: styles => ({ ...styles, zIndex: 100 }) //  >= dialog's z-index
                        }}
                        className="select-filter select-list" //  class for css
                    />
                    }
                    <FileUploadSection
                        name="SupportingDocuments"
                        fileList={docs}
                        getFileURL={GET_FILE_URL}
                        isUpper={IS_UPPER}
                        readonly
                    />
                </Section>
            }
        </>
    );
}
