import React, { useState, useEffect } from "react";
import { Row, Col, Label, Input } from "reactstrap";
import { FORMIO_GET_RESOURCES } from "../../AppConstants";

function Resource(props) {
    const [dataSourceResourceID, setDataSourceResourceID] = useState(props.dataSourceResourceID);
    const [dataSourceResource, setDataSourceResource] = useState(props.dataSourceResource);
    const [dataSourceResourceDependencyKey, setDataSourceResourceDependencyKey] = useState(props.dataSourceResourceDependencyKey);
    const [dataSourceResourceDependencyValue, setDataSourceResourceDependencyValue] = useState(props.dataSourceResourceDependencyValue);
    const [resourceOptions, setResourceOptions] = useState([]);
    const [isDynamicFiltering, setIsDynamicFiltering] = useState(props.isDynamicFiltering);
    const [dataSourceResourceDependencyKeyOptions, setDataSourceResourceDependencyKeyOptions] = useState([]);
    const globalFieldList = props.globalFieldList;
    const currentComponentID = props.currentComponentID;
    
    useEffect(() => {
        fetch(FORMIO_GET_RESOURCES)
        .then(response => response.json())
        .then(json => getResourceOptions(json))
        .catch(error => console.error(error))
    },
    // eslint-disable-next-line 
    []);

    function onResourceNameSelected(event) {
        const components = resourceOptions.filter((option) => option.key === event.target.value)[0].components;
        setDataSourceResourceID(event.target.value);
        setDataSourceResource(components[components.length - 1].key);
        setDataSourceResourceDependencyKeyOptions((resourceOptions.filter((option) => option.key === event.target.value)[0]).components);
    }

    function getResourceOptions(json) {
        let resourceOptions = [];
        json.forEach((resource) => {
            const resourceOption = {
                value: resource.name,
                name: resource.title,
                key: resource._id,
                components: resource.components.filter((component) => component.type !== "button").map((component) => {
                    return {
                        key: component.key,
                        label: component.label
                    }
                })
            }
            console.log(resourceOption);
            resourceOptions = [...resourceOptions, resourceOption];
        })
        setResourceOptions(resourceOptions);
        const selectedDataSourceResource = resourceOptions.filter((option) => option.value === dataSourceResource);
        if (selectedDataSourceResource && selectedDataSourceResource.length > 0) {
            setDataSourceResourceDependencyKeyOptions(selectedDataSourceResource[0].components);
        }
    }

    function dynamicFilteringOnChange(event) {
        setIsDynamicFiltering(event.target.checked);
    }

    function onDataSourceResourceDependencyKeySelected(event) {
        setDataSourceResourceDependencyKey(event.target.value);
    }

    function onDataSourceResourceDependencyValueSelected(event) {
        setDataSourceResourceDependencyValue(event.target.value);
    }

    useEffect(() => {
        props.onResourceSelected(dataSourceResourceID, dataSourceResource, dataSourceResourceDependencyKey, dataSourceResourceDependencyValue, isDynamicFiltering);
    }, 
    // eslint-disable-next-line 
    [dataSourceResourceID, dataSourceResource, dataSourceResourceDependencyKey, dataSourceResourceDependencyValue])

    return <div>
        <Row className="mb-3">
            <Col xs="auto">
                <Label className="middle-title">Resource</Label>
            </Col>
            <Col xs="auto">
                <Input type="select" onChange={onResourceNameSelected} value={dataSourceResourceID}>
                    <option value="" disabled>Select a resource</option>
                    {resourceOptions.map((option, index) => {
                        return <option key={index} value={option.key}>{option.name}</option>
                    })}
                </Input>
            </Col>
            <Col xs="auto">
                <Input type="checkbox" className="middle-title" label="Dynamic Filtering" onChange={dynamicFilteringOnChange} checked={isDynamicFiltering}/>
            </Col>
        </Row>
        {isDynamicFiltering && <div><Row className="mb-3">
            <Col xs="auto">
                <Label className="middle-title">This component uses this reference:</Label>
            </Col>
            <Col xs="auto">
                <Input type="select" value={dataSourceResourceDependencyValue} onChange={onDataSourceResourceDependencyValueSelected}>
                    <option value="" disabled>Select reference</option>
                    {dataSourceResourceDependencyKeyOptions.map((option, index) => {
                        return <option key={index} value={option.key}>{option.label}</option>
                    })}
                </Input>
            </Col>
        </Row>
        <Row className="mb-3">
            <Col xs="auto">
                <Label className="middle-title">based on this component:</Label>
            </Col>
            <Col xs="auto">
                <Input type="select" value={dataSourceResourceDependencyKey} onChange={onDataSourceResourceDependencyKeySelected}>
                    <option value="" disabled>Select parent component</option>
                    {globalFieldList.filter((field) => field.fieldID !== currentComponentID).map((field, index) => {
                        return <option key={index} value={field.fieldID}>{field.label}</option>
                    })}
                </Input>
            </Col>
        </Row></div>}
    </div>
}

export default Resource;