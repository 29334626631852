import React from 'react'
import { AuthConsumer } from './AuthContext'
import AuthHelper from '../Authentication/AuthHelper'

class AuthComponent extends React.Component {

  intersectPermission = (permissions, givenPermissions) => {
    permissions = 
        permissions === undefined ? [] : 
        permissions.map(function (x) { return x.toUpperCase() });
    
    givenPermissions = 
        givenPermissions === undefined ? [] : 
        givenPermissions.map(function (x) { return x.toUpperCase() });

    return AuthHelper.intersect(permissions, givenPermissions).length > 0;
  }

  render() {
    return (
      <AuthConsumer>
        {({ permissions }) =>
          typeof this.props.permissions === 'undefined' ||
            this.props.permissions.length === 0 ||
            this.intersectPermission(permissions, this.props.permissions) ?
            this.props.children : ""
        }
      </AuthConsumer>
    )
  }
}

export default AuthComponent
