import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ApplicationEnquiry, LicenceDetails, LicenceEnquiry, LicenceStatusDetails } from '../Content/InternalEnquiryList';
import InternalInboxList from '../Content/InternalInboxList';
// FAQ (End)
//liquor licence 1 to 4 - List Applications Bundle (Start)
import { FormDetails, FormProcess } from '../Content/JsonToForm';
//AdminLanguage
//AdminLanguage - List Applications Bundle (Start)
import LanguageList from '../Content/LanguageList';
import SpfLiquor1To4DetailsV1 from '../Content/Licences/SpfLiquor1To4/V1/SpfLiquor1To4Details';
import SpfLiquor1To4ProcessingV1 from '../Content/Licences/SpfLiquor1To4/V1/SpfLiquor1To4Processing';
//liquor licence 1 to 4 - - List Applications Bundle (End)
//URA change of use approval - List Applications Bundle (Start)
import UraChangeUseApprovalDetailsV1 from '../Content/Licences/UraChangeUseApproval/V1/UraChangeUseApprovalDetails';
import UraChangeUseApprovalProcessingV1 from '../Content/Licences/UraChangeUseApproval/V1/UraChangeUseApprovalProcessing';
import PaymentSuccess from '../Content/Payment/PaymentSuccess';
import PaymentSummary from '../Content/Payment/PaymentSummary';
import { RouterExtension } from '../Extensions/RouterExtension';
import { withEmptyLayout } from '../Layout/EmptyLayout';
import { withSMARTLayout } from '../Layout/SMARTLayout';
import Callback from '../Shared/Authentication/CallBack';
import Logout from '../Shared/Authentication/Logout';
import ProtectedRoute from '../Shared/Authentication/ProtectedRoute';
import Silent from '../Shared/Authentication/Silent';
import { DirectionContextProvider } from '../Shared/DirectionContext';
import { ApplicationConfigCreate, ApplicationConfigList, ApplicationConfigUpdate, ApplicationConfigView } from './AppConfig';
//URA change of use approval - List Applications Bundle (End)
import { CodeTableCreate, CodeTableGrid, CodeTableUpdate, CodeTableView } from './Code';
import './Content.css';
import Dashboard from './Dashboard';
//AdminLanguage - List Applications Bundle (End)
// FAQ (Start)
import AddFaq from './Faq/AddFaq';
import EditFaq from './Faq/EditFaq';
import FAQManagement from './Faq/FaqManagement';
import ViewFaq from './Faq/ViewFaq';
import ViewFaqSettings from './Faq/ViewFaqSettings';
import AddCategory from './LanguageList/AddCategory';
import AddScope from './LanguageList/AddScope';
import AddSubCategory from './LanguageList/AddSubCategory';
import ApplicationsLanguageList from './LanguageList/ApplicationsLanguageList';
import CategoryManagement from './LanguageList/CategoryManagement';
import CreateNewKey from './LanguageList/CreateNewKey';
import EditApplicationLanguage from './LanguageList/EditApplicationLanguage';
import EditCategory from './LanguageList/EditCategory';
import EditKey from './LanguageList/EditKey';
import EditScope from './LanguageList/EditScope';
import EditSubCategory from './LanguageList/EditSubCategory';
import ExcelUpdate from './LanguageList/ExcelUpdate';
import ScopeManagement from './LanguageList/ScopeManagement';
import SubCategoryManagement from './LanguageList/SubCategoryManagement';
import UploadFile from './LanguageList/UploadFile';
import ViewCategory from './LanguageList/ViewCategory';
import ViewScope from './LanguageList/ViewScope';
import ViewSubCategory from './LanguageList/ViewSubCategory';
import {
    LicenceAppConfigCreate,
    LicenceAppConfigCreateFromExisting, LicenceAppConfigEdit, LicenceAppConfigList, LicenceAppConfigVersionList,
    LicenceAppConfigView
} from './LicenceAppConfig';
import { LicenceConfigCreate, LicenceConfigCreateFromExisting, LicenceConfigEdit, LicenceConfigList, LicenceConfigVersion, LicenceConfigView } from './LicenceConfig';
import PreviewService from './PreviewService';
import LicenceExtension from './LicenceExtension';
import AddNewLicenceExtension from './LicenceExtension/AddNewLicenceExtension';
import LicenceManagement from './LicenceManagement';
import EditSuspendLicence from './LicenceManagement/EditSuspendLicence';
import ReinstateLicence from './LicenceManagement/ReinstateLicence';
import RevokeLicence from './LicenceManagement/RevokeLicence';
import SuspendLicence from './LicenceManagement/SuspendLicence';
import NetworkGraph from './NetworkGraph';
import {
    EmailTemplateCreate, EmailTemplateList, EmailTemplateUpdate, LetterTemplateCreate,
    LetterTemplateList, LetterTemplateUpdate, SmsTemplateCreate, SmsTemplateList, SmsTemplateUpdate, SmsTemplateView
} from './NotificationTemplate';
import { OnlineHelpCreate, OnlineHelpList, OnlineHelpUpdate } from './OnlineHelp';
import MakePayment from './Payment';
import PaymentInbox from './PaymentInbox';
import { PublicHolidayCreate, PublicHolidayList, PublicHolidayUpdate, PublicHolidayView } from './PublicHoliday';
import LicenceReport from './ReportGeneration/LicenceReport';
import PaymentReport from './ReportGeneration/PaymentReport';
import RenewalReport from './ReportGeneration/RenewalReport';
import { WorkingHourCreate, WorkingHourCreateException, WorkingHourList, WorkingHourUpdate, WorkingHourUpdateException } from './WorkingHour';
import { FormIOCreate } from './FormIO';

class Content extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: ''
        };
    }

    render() {
        return (
            <React.Fragment>
                {/*routing order matters*/}
                {/*please add any routes related to Auth here in this switch to prevent re rendering of the
                direction context in any authentication request/redirect*/}
                <Switch>
                    <Route path="/Authentication/CallBack" component={withEmptyLayout(Callback)} />
                    <Route path="/Authentication/Silent" component={withEmptyLayout(Silent)} />
                    <Route path="/Authentication/Logout" component={withEmptyLayout(Logout)} />
                </Switch>

                <DirectionContextProvider>
                    <Switch>
                        {/*all other routes not related to Auth*/}
                        <Route exact path="/" render={() => <Redirect to="/dashboard" />} />

                        <ProtectedRoute exact path="/inbox" component={withSMARTLayout(InternalInboxList)} />
                        <ProtectedRoute exact path="/inbox/FormProcessing" component={withSMARTLayout(FormProcess)} />
                        <ProtectedRoute path="/dashboard" component={withSMARTLayout(Dashboard)} />
                        <ProtectedRoute path="/paymentInbox" component={withSMARTLayout(PaymentInbox)} />

                        <ProtectedRoute path="/makePayment" component={withSMARTLayout(MakePayment)} />
                        <ProtectedRoute path="/paymentSummary" component={withSMARTLayout(PaymentSummary)} />
                        <ProtectedRoute path="/paymentSuccess" component={withSMARTLayout(PaymentSuccess)} />

                        {/* Intranet Enquiry */}
                        <ProtectedRoute exact path="/enquiry/application" component={withSMARTLayout(ApplicationEnquiry)} />
                        <ProtectedRoute exact path="/enquiry/application/FormDetails" component={withSMARTLayout(FormDetails)} />
                        <ProtectedRoute exact path="/enquiry/service" component={withSMARTLayout(LicenceEnquiry)} />
                        <ProtectedRoute exact path="/enquiry/service/serviceDetails" component={withSMARTLayout(LicenceDetails)} />
                        <ProtectedRoute exact path="/enquiry/service/serviceStatusDetails" component={withSMARTLayout(LicenceStatusDetails)} />

                        {/* SpfLiquor1To4 - List Applications Bundle (Start)*/}
                        <ProtectedRoute path="/services/SpfLiquor1To4/v1/details" component={withSMARTLayout(SpfLiquor1To4DetailsV1)} />
                        <ProtectedRoute path="/services/SpfLiquor1To4/v1/workflow" component={withSMARTLayout(SpfLiquor1To4ProcessingV1)} />
                        <ProtectedRoute path="/services/SpfLiquor1To4New/v1/details" component={withSMARTLayout(SpfLiquor1To4DetailsV1)} />
                        <ProtectedRoute path="/services/SpfLiquor1To4New/v1/workflow" component={withSMARTLayout(SpfLiquor1To4ProcessingV1)} />
                        <ProtectedRoute path="/services/SpfLiquor1To4Amend/v1/details" component={withSMARTLayout(SpfLiquor1To4DetailsV1)} />
                        <ProtectedRoute path="/services/SpfLiquor1To4Amend/v1/workflow" component={withSMARTLayout(SpfLiquor1To4ProcessingV1)} />
                        <ProtectedRoute path="/services/SpfLiquor1To4Renew/v1/details" component={withSMARTLayout(SpfLiquor1To4DetailsV1)} />
                        <ProtectedRoute path="/services/SpfLiquor1To4Renew/v1/workflow" component={withSMARTLayout(SpfLiquor1To4ProcessingV1)} />
                        <ProtectedRoute path="/services/SpfLiquor1To4Cancel/v1/details" component={withSMARTLayout(SpfLiquor1To4DetailsV1)} />
                        <ProtectedRoute path="/services/SpfLiquor1To4Cancel/v1/workflow" component={withSMARTLayout(SpfLiquor1To4ProcessingV1)} />
                        {/* SpfLiquor1To4 - List Applications Bundle (End)*/}

                        {/* UraChangeUseApproval - List Applications Bundle (Start)*/}
                        <ProtectedRoute path="/services/UraChangeUseApproval/v1/details" component={withSMARTLayout(UraChangeUseApprovalDetailsV1)} />
                        <ProtectedRoute path="/services/UraChangeUseApprovalCancel/v1/details" component={withSMARTLayout(UraChangeUseApprovalDetailsV1)} />
                        <ProtectedRoute path="/services/UraChangeUseApproval/v1/workflow" component={withSMARTLayout(UraChangeUseApprovalProcessingV1)} />
                        <ProtectedRoute path="/services/UraChangeUseApprovalNew/v1/details" component={withSMARTLayout(UraChangeUseApprovalDetailsV1)} />
                        <ProtectedRoute path="/services/UraChangeUseApprovalNew/v1/workflow" component={withSMARTLayout(UraChangeUseApprovalProcessingV1)} />
                        <ProtectedRoute path="/services/UraChangeUseApprovalAmend/v1/details" component={withSMARTLayout(UraChangeUseApprovalDetailsV1)} />
                        <ProtectedRoute path="/services/UraChangeUseApprovalAmend/v1/workflow" component={withSMARTLayout(UraChangeUseApprovalProcessingV1)} />
                        <ProtectedRoute path="/services/UraChangeUseApprovalRenew/v1/details" component={withSMARTLayout(UraChangeUseApprovalDetailsV1)} />
                        <ProtectedRoute path="/services/UraChangeUseApprovalRenew/v1/workflow" component={withSMARTLayout(UraChangeUseApprovalProcessingV1)} />
                        <ProtectedRoute path="/services/UraChangeUseApprovalCancel/v1/details" component={withSMARTLayout(UraChangeUseApprovalDetailsV1)} />
                        <ProtectedRoute path="/services/UraChangeUseApprovalCancel/v1/workflow" component={withSMARTLayout(UraChangeUseApprovalProcessingV1)} />
                        {/* UraChangeUseApproval - List Applications Bundle (End)*/}

                        <ProtectedRoute exact path="/service/management" component={withSMARTLayout(LicenceManagement)} />
                        <ProtectedRoute exact path="/service/management/revoke" component={withSMARTLayout(RevokeLicence)} />
                        <ProtectedRoute exact path="/service/management/reinstate" component={withSMARTLayout(ReinstateLicence)} />
                        <ProtectedRoute exact path="/service/management/suspend" component={withSMARTLayout(SuspendLicence)} />
                        <ProtectedRoute exact path="/service/management/editSuspension" component={withSMARTLayout(EditSuspendLicence)} />

                        <ProtectedRoute exact path="/service/extension" component={withSMARTLayout(LicenceExtension)} />
                        <ProtectedRoute exact path="/service/addserviceextension" component={withSMARTLayout(AddNewLicenceExtension)} />

                        <ProtectedRoute exact path="/SystemConfig/Codes" component={withSMARTLayout(CodeTableGrid)} />
                        <ProtectedRoute path="/SystemConfig/Codes/Update" component={withSMARTLayout(CodeTableUpdate)} />
                        <ProtectedRoute path="/SystemConfig/Codes/View" component={withSMARTLayout(CodeTableView)} />
                        <ProtectedRoute exact path="/SystemConfig/Codes/Create" component={withSMARTLayout(CodeTableCreate)} />

                        <ProtectedRoute exact path="/SystemConfig/ApplicationConfig/Create" component={withSMARTLayout(ApplicationConfigCreate)} />
                        <ProtectedRoute exact path="/SystemConfig/ApplicationConfig" component={withSMARTLayout(ApplicationConfigList)} />
                        <ProtectedRoute exact path="/SystemConfig/ApplicationConfig/Update" component={withSMARTLayout(ApplicationConfigUpdate)} />
                        <ProtectedRoute exact path="/SystemConfig/ApplicationConfig/View" component={withSMARTLayout(ApplicationConfigView)} />

                        <ProtectedRoute exact path="/Config/ServiceConfig/Create" component={withSMARTLayout(LicenceConfigCreate)} />
                        <ProtectedRoute exact path="/Config/ServiceConfig" component={withSMARTLayout(LicenceConfigList)} />
                        <ProtectedRoute exact path="/Config/ServiceConfig/Version" component={withSMARTLayout(LicenceConfigVersion)} />
                        <ProtectedRoute exact path="/Config/ServiceConfig/View" component={withSMARTLayout(LicenceConfigView)} />
                        <ProtectedRoute exact path="/Config/ServiceConfig/Edit" component={withSMARTLayout(LicenceConfigEdit)} />
                        <ProtectedRoute exact path="/Config/ServiceConfig/CreateFromExisting" component={withSMARTLayout(LicenceConfigCreateFromExisting)} />


                        <ProtectedRoute exact path="/Config/FormIO/Create" component={withSMARTLayout(FormIOCreate)} />

                        <ProtectedRoute exact path="/Config/ServiceAppConfig/Create" component={withSMARTLayout(LicenceAppConfigCreate)} />
                        <ProtectedRoute exact path="/Config/ServiceAppConfig" component={withSMARTLayout(LicenceAppConfigList)} />
                        <ProtectedRoute exact path="/Config/ServiceAppConfig/Version" component={withSMARTLayout(LicenceAppConfigVersionList)} />
                        <ProtectedRoute exact path="/Config/ServiceAppConfig/View" component={withSMARTLayout(LicenceAppConfigView)} />
                        <ProtectedRoute exact path="/Config/ServiceAppConfig/Edit" component={withSMARTLayout(LicenceAppConfigEdit)} />
                        <ProtectedRoute exact path="/Config/ServiceAppConfig/CreateFromExisting" component={withSMARTLayout(LicenceAppConfigCreateFromExisting)} />

                        <ProtectedRoute exact path="/Config/PreviewService" component={withSMARTLayout(PreviewService)} />

                        <ProtectedRoute exact path="/SystemConfig/NotificationTemplate/EmailCreate" component={withSMARTLayout(EmailTemplateCreate)} />
                        <ProtectedRoute exact path="/SystemConfig/NotificationTemplate/EmailList" component={withSMARTLayout(EmailTemplateList)} />
                        <ProtectedRoute exact path="/SystemConfig/NotificationTemplate/EmailUpdate" component={withSMARTLayout(EmailTemplateUpdate)} />

                        <ProtectedRoute exact path="/SystemConfig/NotificationTemplate/LetterCreate" component={withSMARTLayout(LetterTemplateCreate)} />
                        <ProtectedRoute exact path="/SystemConfig/NotificationTemplate/LetterList" component={withSMARTLayout(LetterTemplateList)} />
                        <ProtectedRoute exact path="/SystemConfig/NotificationTemplate/LetterUpdate" component={withSMARTLayout(LetterTemplateUpdate)} />

                        <ProtectedRoute exact path="/SystemConfig/NotificationTemplate/SmsCreate" component={withSMARTLayout(SmsTemplateCreate)} />
                        <ProtectedRoute exact path="/SystemConfig/NotificationTemplate/SmsList" component={withSMARTLayout(SmsTemplateList)} />
                        <ProtectedRoute exact path="/SystemConfig/NotificationTemplate/SmsUpdate" component={withSMARTLayout(SmsTemplateUpdate)} />
                        <ProtectedRoute exact path="/SystemConfig/NotificationTemplate/SmsView" component={withSMARTLayout(SmsTemplateView)} />

                        <ProtectedRoute exact path="/SystemConfig/PublicHoliday" component={withSMARTLayout(PublicHolidayList)} />
                        <ProtectedRoute exact path="/SystemConfig/PublicHoliday/Create" component={withSMARTLayout(PublicHolidayCreate)} />
                        <ProtectedRoute exact path="/SystemConfig/PublicHoliday/Update" component={withSMARTLayout(PublicHolidayUpdate)} />
                        <ProtectedRoute exact path="/SystemConfig/PublicHoliday/View" component={withSMARTLayout(PublicHolidayView)} />

                        <ProtectedRoute exact path="/SystemConfig/WorkingHour" component={withSMARTLayout(WorkingHourList)} />
                        <ProtectedRoute exact path="/SystemConfig/WorkingHour/Create" component={withSMARTLayout(WorkingHourCreate)} />
                        <ProtectedRoute exact path="/SystemConfig/WorkingHour/Update" component={withSMARTLayout(WorkingHourUpdate)} />
                        <ProtectedRoute exact path="/SystemConfig/WorkingHour/CreateException" component={withSMARTLayout(WorkingHourCreateException)} />
                        <ProtectedRoute exact path="/SystemConfig/WorkingHour/UpdateException" component={withSMARTLayout(WorkingHourUpdateException)} />

                        <ProtectedRoute exact path="/SystemConfig/OnlineHelp" component={withSMARTLayout(OnlineHelpList)} />
                        <ProtectedRoute exact path="/SystemConfig/OnlineHelp/Create" component={withSMARTLayout(OnlineHelpCreate)} />
                        <ProtectedRoute exact path="/SystemConfig/OnlineHelp/Update" component={withSMARTLayout(OnlineHelpUpdate)} />

                        <ProtectedRoute exact path="/ReportGeneration/ServiceReport" component={withSMARTLayout(LicenceReport)} />
                        <ProtectedRoute exact path="/ReportGeneration/PaymentReport" component={withSMARTLayout(PaymentReport)} />
                        <ProtectedRoute exact path="/ReportGeneration/RenewalReport" component={withSMARTLayout(RenewalReport)} />

                        <ProtectedRoute path="/NetworkGraph" component={withSMARTLayout(NetworkGraph)} />

                        {/* LanguageAdminMgmt - List Applications Bundle (Start)*/}
                        <ProtectedRoute path="/SystemConfig/language/list" component={withSMARTLayout(LanguageList)} />
                        <ProtectedRoute path="/SystemConfig/language/addEntry" component={withSMARTLayout(CreateNewKey)} />
                        <ProtectedRoute path="/SystemConfig/language/editEntry" component={withSMARTLayout(EditKey)} />
                        <ProtectedRoute path="/SystemConfig/language/uploadFile" component={withSMARTLayout(UploadFile)} />
                        <ProtectedRoute path="/SystemConfig/language/addScope" component={withSMARTLayout(AddScope)} />
                        <ProtectedRoute path="/SystemConfig/language/viewScope" component={withSMARTLayout(ViewScope)} />
                        <ProtectedRoute path="/SystemConfig/language/editScope" component={withSMARTLayout(EditScope)} />
                        <ProtectedRoute path="/SystemConfig/language/addCategory" component={withSMARTLayout(AddCategory)} />
                        <ProtectedRoute path="/SystemConfig/language/viewCategory" component={withSMARTLayout(ViewCategory)} />
                        <ProtectedRoute path="/SystemConfig/language/editCategory" component={withSMARTLayout(EditCategory)} />
                        <ProtectedRoute path="/SystemConfig/language/addSubCategory" component={withSMARTLayout(AddSubCategory)} />
                        <ProtectedRoute path="/SystemConfig/language/viewSubCategory" component={withSMARTLayout(ViewSubCategory)} />
                        <ProtectedRoute path="/SystemConfig/language/editSubCategory" component={withSMARTLayout(EditSubCategory)} />
                        <ProtectedRoute path="/SystemConfig/language/scopeManagement" component={withSMARTLayout(ScopeManagement)} />
                        <ProtectedRoute path="/SystemConfig/language/categoryManagement" component={withSMARTLayout(CategoryManagement)} />
                        <ProtectedRoute path="/SystemConfig/language/subcategoryManagement" component={withSMARTLayout(SubCategoryManagement)} />
                        <ProtectedRoute path="/SystemConfig/language/languageRegistration" component={withSMARTLayout(ApplicationsLanguageList)} />
                        <ProtectedRoute path="/SystemConfig/language/editApplicationLanguages" component={withSMARTLayout(EditApplicationLanguage)} />
                        <ProtectedRoute path="/SystemConfig/language/excelUpdate" component={withSMARTLayout(ExcelUpdate)} />

                        <ProtectedRoute path="/SystemConfig/faq/faqManagement" component={withSMARTLayout(FAQManagement)} />
                        <ProtectedRoute path="/SystemConfig/faq/addFaq" component={withSMARTLayout(AddFaq)} />
                        <ProtectedRoute path="/SystemConfig/faq/editFaq" component={withSMARTLayout(EditFaq)} />
                        <ProtectedRoute path="/SystemConfig/faq/viewFaq" component={withSMARTLayout(ViewFaq)} />
                        <ProtectedRoute path="/SystemConfig/faq/viewFaqSettings" component={withSMARTLayout(ViewFaqSettings)} />

                        {/* LanguageAdminMgmt - List Applications Bundle (End)*/}
                        <ProtectedRoute exact path="/SystemConfig/OnlineHelp" component={withSMARTLayout(OnlineHelpList)} />
                        <ProtectedRoute exact path="/SystemConfig/OnlineHelp/Create" component={withSMARTLayout(OnlineHelpCreate)} />
                        <ProtectedRoute exact path="/SystemConfig/OnlineHelp/Update" component={withSMARTLayout(OnlineHelpUpdate)} />

                        <Route path="/Authentication/CallBack" component={withEmptyLayout(Callback)} />
                        <Route path="/Authentication/Silent" component={withEmptyLayout(Silent)} />
                        <RouterExtension />
                    </Switch>
                </DirectionContextProvider>
            </React.Fragment>

        );
    }
}

export default Content;
