import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from 'reactstrap';
import { LANGUAGE_KEYS } from '../../../Shared/Constants/LanguageKeys';
import { AccordionContainer, Plaintext } from '../../../Shared/Forms';
import { DATE_TIME_FORMAT } from '../../../Shared/Constants';

export default function Publish(props) {
    const { t } = useTranslation();
    const { values, toggleSection } = props.smartFormValues;

    const SECTION_NAMES = {
        PUBLISH: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_SECTION_PUBLISH), status: true }
    }

    const setEffectiveFromDate = () => {
        if (Date.parse(values.PublishData.EffectiveFromDate) > Date.parse(DATE_TIME_FORMAT.DEFAULT_MIN_DATE)) {
            return values.PublishData.EffectiveFromDate;
        } else {
            return DATE_TIME_FORMAT.DEFAULT_EMPTY_DATE;
        }
    };

    const setEffectiveToDate = () => {
        if (Date.parse(values.PublishData.EffectiveToDate) > Date.parse(DATE_TIME_FORMAT.DEFAULT_MIN_DATE)) {
            return values.PublishData.EffectiveToDate;
        } else {
            return DATE_TIME_FORMAT.DEFAULT_EMPTY_DATE;
        }
    };

    return (
        <React.Fragment>
            <Container className="full-width">
                <AccordionContainer
                    title={SECTION_NAMES.PUBLISH.title}
                    active={SECTION_NAMES.PUBLISH.status}
                    onClick={toggleSection}
                    isIndividual={true}
                    sections={SECTION_NAMES
                    }
                >
                    <Plaintext
                        label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_EFFECTIVEFROM)}
                        name="EffectiveFromDate"
                        value={setEffectiveFromDate()}
                    />
                    <Plaintext
                        label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_EFFECTIVETO)}
                        name="EffectiveToDate"
                        value={setEffectiveToDate()}
                    />
                </AccordionContainer>
            </Container>
        </React.Fragment>
    );
}


