import { Input } from 'antd';
import { default as React, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Label } from 'reactstrap';
import * as Yup from 'yup';
import { ADD_LICENCE_EXTENSION, LAYOUT_TYPE, LICENCE_CONFIG_ACTIVE_LICENCE_TYPE_BY_AGENCY_LIST, REGEX } from '../../App/AppSettings';
import Layout from '../../Layout';
import { getParams, navigateTo } from '../../Shared/Actions';
import { FIELD_SIZE } from '../../Shared/Constants';
import { ERROR, LANGUAGE_KEYS, SUCCESS } from '../../Shared/Constants/LanguageKeys';
import { useAsyncFetch } from '../../Shared/Fetch';
import { AccordionContainer, Paragraph, SelectList, SMARTForm } from '../../Shared/Forms';
import { toastError, toastSuccess } from '../../Shared/Forms/Toaster.js';
import history from '../../Shared/History';
import PageFooter from '../../Shared/PageFooter';
import { getLangKey, getLicenceTypeNameTranslationKey } from '../DisplayComponents/DisplayUtils';
import InlineInput from '../LicenceConfig/InlineInput';

const FORM_CONTEXT = 'AddNewLicenceExtension';
const SERVER_URL = ADD_LICENCE_EXTENSION;
const REGEX_LESS_THAN_TWELVE = /^\s*[1][0-2]\s*$|^\s*0\s*$|^\s*[1-9]\s*$/;

const FORM_INITIAL_VALUES = {
    LicenceName: '',
    ExtensionDurationYear: '',
    ExtensionDurationMonth: '',
    ExtensionDurationDay: '',
    Remarks: '',
    LicenceTypeId: '',
    FaqData: [],
    DependencyData: []
};


export default function AddNewLicenceExtension() {
    const { t } = useTranslation();
    const [values] = useState(FORM_INITIAL_VALUES);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const previousPage = history.location.state.from;
    const { LicenceType } = history.location.state;
    
    const displayErrorInvalidNumber = label => `${label} ${t(ERROR.VALID_NUM_GREATER_ZERO)}`;

    const displayErrorMonthNumber = label => `${label} ${t(ERROR.VALID_NUM_ZERO_TO_TWELVE)}`;

    const displayErrorDayNumber = label => `${label} ${t(ERROR.VALID_NUMBER)}`;

    const FORM_VALIDATIONS = Yup.object().shape({
        LicenceName: Yup.string().required(t(ERROR.YEAR_REQUIRED)).min(0, ERROR.MIN_LENGTH_LIMIT),
        ExtensionDurationYear: Yup.string().required(t(ERROR.YEAR_REQUIRED)).matches(REGEX.INTEGER_POSITIVE_INCLUDE_ZERO, displayErrorInvalidNumber(t(LANGUAGE_KEYS.BLS_INTERNALLICENCEEXTENSION_FORM_FIELD_YEAR))),
        ExtensionDurationMonth: Yup.string().required(t(ERROR.MONTH_REQUIRED)).matches(REGEX_LESS_THAN_TWELVE, displayErrorMonthNumber(t(LANGUAGE_KEYS.BLS_INTERNALLICENCEEXTENSION_FORM_FIELD_MONTH))),
        ExtensionDurationDay: Yup.string().required(t(ERROR.DAY_REQUIRED)).matches(REGEX.INTEGER_POSITIVE_INCLUDE_ZERO, displayErrorDayNumber(t(LANGUAGE_KEYS.BLS_INTERNALLICENCEEXTENSION_FORM_FIELD_DAY))),
        Remarks: Yup.string().required(t(ERROR.REMARKS_REQUIRED))
    });

    const SECTION_NAMES = {
        SERVICE_EXTENSION: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEEXTENSION_CONTENT_SECTION_SERVICEEXTENSION), status: true }
    };

    //Retrieve Licence Name
    const retrieveLicenceNameResponse = useAsyncFetch(LICENCE_CONFIG_ACTIVE_LICENCE_TYPE_BY_AGENCY_LIST, getParams());

    useEffect(() => {
        if (retrieveLicenceNameResponse) {
            const fetchLicenceNameResponse = async response => {
                const { Data, IsSuccess } = response.body;
                if (response.success && IsSuccess) {
                    const categoryOptions = [];
                    Data.forEach(e => {
                        const licenceTypeNameTranslationKey = getLicenceTypeNameTranslationKey(e.LicenceName);
                        const licenceName = t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY, licenceTypeNameTranslationKey, e.LicenceTypeId));
                        categoryOptions.push({ label: licenceName, value: e.LicenceName });
                    });
                    setCategoryOptions(categoryOptions);
                }
            };

            fetchLicenceNameResponse(retrieveLicenceNameResponse);
        }
    }, [retrieveLicenceNameResponse, t]);

    const submitCallback = ({ response }) => {
        if (response.success) {
            const { IsSuccess, Messages } = response.body;
            if (IsSuccess) {
                navigateTo(previousPage, { LicenceType });
                toastSuccess(t(getLangKey(SUCCESS.BACKEND_SUCCESS_MESSAGE, Messages)), Messages);
            } else {
                toastError(t(getLangKey(ERROR.BACKEND_ERROR_MESSAGE, Messages)), Messages);
            }
        } else {
            toastError(t(ERROR.SERVER_UNREACHABLE));
        }
    };

    return (
        <React.Fragment>
            <Layout type={LAYOUT_TYPE.FORM} title={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEEXTENSION_CONTENT_TITLE_NEWSERVICEEXTENSION)}>
                <SMARTForm
                    formContext={FORM_CONTEXT}
                    formValues={values}
                    validationSchema={FORM_VALIDATIONS}
                    validateOnSubmit={true}
                    serverURL={SERVER_URL}
                    isDummy={false}
                    submitCallback={submitCallback}
                    resetFormAfterSubmit={true}
                >
                    {({ values, errors, onChange, onChangeField, submitPlainForm, toggleSection }) => {
                        return (
                            < React.Fragment >
                                <AccordionContainer
                                    title={SECTION_NAMES.SERVICE_EXTENSION.title}
                                    active={SECTION_NAMES.SERVICE_EXTENSION.status}
                                    onClick={toggleSection}
                                    isIndividual={true}
                                    sections={SECTION_NAMES
                                    }
                                >
                                    <SelectList
                                        isClearable={false}
                                        name='LicenceName'
                                        value={values.LicenceName}
                                        placeholder={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEEXTENSION_FORM_PLACEHOLDER_SERVICENAME)}
                                        minLength={0}
                                        maxLength={140}
                                        inputSize={FIELD_SIZE.MEDIUM}
                                        label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEEXTENSION_FORM_FIELD_SERVICENAME)}
                                        required
                                        options={categoryOptions}
                                        onChangeField={onChangeField}
                                        isMulti={false}
                                        error={errors.LicenceName}
                                    />
                                    <div>
                                        <InlineInput label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEEXTENSION_FORM_FIELD_SERVICEVALIDITYPERIOD)}>
                                            <div className='col-lg-2 licence-config-year-input'>
                                                <Input
                                                    type='text'
                                                    name='ExtensionDurationYear'
                                                    value={values.ExtensionDurationYear}
                                                    placeholder={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEEXTENSION_FORM_PLACEHOLDER_YEAR)}
                                                    onChange={onChange}
                                                    minLength={0}
                                                    maxLength={140}
                                                    error={errors.ExtensionDurationYear}
                                                />
                                                <div>
                                                    <Label className='label-feedback'>
                                                        {errors.ExtensionDurationYear}
                                                    </Label>
                                                </div>
                                            </div>
                                            <Label className='label-default col-form-label'>{t(LANGUAGE_KEYS.BLS_INTERNALLICENCEEXTENSION_FORM_FIELD_YEAR)}</Label>
                                            <div className='col-lg-4'>
                                                <Input
                                                    type='text'
                                                    name='ExtensionDurationMonth'
                                                    value={values.ExtensionDurationMonth}
                                                    placeholder={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEEXTENSION_FORM_PLACEHOLDER_MONTH)}
                                                    onChange={onChange}
                                                    minLength={0}
                                                    maxLength={140}
                                                    autoComplete='off'
                                                    error={errors.ExtensionDurationMonth}
                                                />
                                                <div>
                                                    <Label className='label-feedback'>
                                                        {errors.ExtensionDurationMonth}
                                                    </Label>
                                                </div>
                                            </div>
                                            <Label className='label-default col-form-label'>{t(LANGUAGE_KEYS.BLS_INTERNALLICENCEEXTENSION_FORM_FIELD_MONTH)}</Label>
                                            <div className='col-lg-2'>
                                                <Input
                                                    type='text'
                                                    name='ExtensionDurationDay'
                                                    value={values.ExtensionDurationDay}
                                                    placeholder={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEEXTENSION_FORM_PLACEHOLDER_DAY)}
                                                    onChange={onChange}
                                                    minLength={0}
                                                    maxLength={140}
                                                    autoComplete='off'
                                                    error={errors.ExtensionDurationDay}
                                                />
                                                <div>
                                                    <Label className='label-feedback'>
                                                        {errors.ExtensionDurationDay}
                                                    </Label>
                                                </div>
                                            </div>
                                            <Label className='label-default col-form-label'>{t(LANGUAGE_KEYS.BLS_INTERNALLICENCEEXTENSION_FORM_FIELD_DAY)}</Label>
                                        </InlineInput>
                                    </div>
                                    <Paragraph
                                        name="Remarks"
                                        value={values.Remarks}
                                        placeholder={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEEXTENSION_FORM_PLACEHOLDER_REMARKS)}
                                        onChange={onChange}
                                        rows={3}
                                        minLength={0}
                                        maxLength={140}
                                        inputSize={FIELD_SIZE.MEDIUM}
                                        label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEEXTENSION_FORM_FIELD_REMARKS)}
                                        error={errors.Remarks}
                                        required
                                    />
                                </AccordionContainer>
                                <PageFooter type={LAYOUT_TYPE.FORM}>
                                    <Button color="backward" size="sm" type="button" onClick={() => navigateTo(previousPage, { LicenceType })}>{t(LANGUAGE_KEYS.BLS_INTERNALLICENCEEXTENSION_NAVIGATION_BUTTON_BACK)}</Button>
                                    <Button color="forward" size="sm" type="button" onClick={submitPlainForm}>{t(LANGUAGE_KEYS.BLS_INTERNALLICENCEEXTENSION_NAVIGATION_BUTTON_PUBLISH)}</Button>
                                </PageFooter>
                            </React.Fragment>
                        );
                    }}
                </SMARTForm>
            </Layout >
        </React.Fragment >
    );
}
