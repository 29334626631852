import { faCopy, faEdit, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import { LAYOUT_TYPE, LICENCE_CONFIG_CREATE_FROM_EXISTING_PAGE, LICENCE_CONFIG_CREATE_PAGE, LICENCE_CONFIG_EDIT_PAGE, LICENCE_CONFIG_LICENCE_LIST_PAGE, LICENCE_CONFIG_LICENCE_VERSIONS_BY_TYPE_LIST, LICENCE_CONFIG_VIEW_PAGE } from '../../App/AppSettings';
import Layout from '../../Layout';
import { getParams, navigateTo } from '../../Shared/Actions';
import { DATE_TIME_FORMAT } from '../../Shared/Constants';
import { getLangKey, LANGUAGE_KEYS } from '../../Shared/Constants/LanguageKeys';
import { ActionButton, DataTable, MobileCardActionButton } from '../../Shared/DataTable';
import { getCustomComponents } from '../../Shared/DataTable/BaseTable.js';
import { useAsyncFetch } from '../../Shared/Fetch';
import history from '../../Shared/History';
import PageFooter from '../../Shared/PageFooter';

const RETRIEVE_URL = LICENCE_CONFIG_LICENCE_VERSIONS_BY_TYPE_LIST;

const LICENCE_STATUS = {
    ACTIVE: "Active",
    INACTIVE: "Inactive",
    PENDING_VERIFICATION: "Pending_Verification"
}

export default function LicenceConfigVersionList() {
    const { t } = useTranslation();
    const { LicenceType } = history.location.state;
    const { LicenceTypeId, AgencyId } = LicenceType;

    const [data, setData] = useState([]);
    const params = {
        licenceTypeId: LicenceTypeId,
        agencyId: AgencyId
    }
    const response = useAsyncFetch(RETRIEVE_URL, getParams(params));

    useEffect(() => {
        const fetchData = async () => {
            const { Data, IsSuccess } = response.body;
            if (response.success && IsSuccess) {
                setData(Data);
            }
        }
        if (response) {
            fetchData();
        }
    }, [response])

    const renderMobileCardActions = (RowData) => (
        <MobileCardActionButton
            onClick={() => navigateTo(LICENCE_CONFIG_VIEW_PAGE, { ID: RowData.Id })}
            icon={faEye}
            label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_CONTENT_BUTTON_VIEW)}
        />
    )

    const setStatusTranslatedContent = (rowData) => {
        return <>{t(getLangKey(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_VERSIONSTATUS_TITLE_KEY, rowData.Status))}</>;
    }

    const getStatusTranslatedString = (rowData) => {
        return t(getLangKey(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_VERSIONSTATUS_TITLE_KEY, rowData.Status));
    }

    const setEffectiveFromDate = (rowData) => {
        if (Date.parse(rowData.EffectiveFrom) > Date.parse(DATE_TIME_FORMAT.DEFAULT_MIN_DATE)) {
            return <>{rowData.EffectiveFrom}</>;
        } else {
            return <>{DATE_TIME_FORMAT.DEFAULT_EMPTY_DATE}</>;
        }
    };

    const setEffectiveToDate = (rowData) => {
        if (Date.parse(rowData.EffectiveTo) > Date.parse(DATE_TIME_FORMAT.DEFAULT_MIN_DATE)) {
            return <>{rowData.EffectiveTo}</>;
        } else {
            return <>{DATE_TIME_FORMAT.DEFAULT_EMPTY_DATE}</>;
        }
    };

    const renderGrid = () => {
        return (
            <React.Fragment>
                <div className="smart-list">
                    <DataTable
                        pageSize="5"
                        minFilterChars='2'
                        data={data}
                        noResultsMessage={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_MESSAGE_PLACEHOLDER_NOVERSIONFOUND)}
                        components={getCustomComponents()}
                        columns={
                            {
                                Version: { title: "Version No.", width: '8%', DBkey: 'Version' },
                                EffectiveFrom: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_TABLE_TITLE_EFFECTIVEFROM), width: '10%', DBkey: 'EffectiveFrom', setContent: setEffectiveFromDate },
                                EffectiveTo: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_TABLE_TITLE_EFFECTIVETO), width: '8%', DBkey: 'EffectiveTo', setContent: setEffectiveToDate },
                                Status: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_TABLE_TITLE_STATUS), width: '8%', DBkey: 'Status', setContent: setStatusTranslatedContent, getTraslatedString: getStatusTranslatedString },
                                CreatedDate: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_TABLE_TITLE_CREATEDDATE), width: '8%', DBkey: 'CreatedDate', isDate: true },
                                UpdatedDate: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_TABLE_TITLE_UPDATEDDATE), width: '8%', DBkey: 'UpdatedDate', isDate: true },
                                UpdatedBy: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_TABLE_TITLE_UPDATEDBY), width: '8%', DBkey: 'UpdatedBy' },
                                Actions: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_TABLE_TITLE_ACTION), width: '10%' }
                            }
                        }
                        renderActions={({ RowData }) => (
                            <React.Fragment>
                                <ActionButton tooltip={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_CONTENT_BUTTON_VIEW)} color="forward" onClick={() => navigateTo(LICENCE_CONFIG_VIEW_PAGE, { LicenceType: RowData })}><FontAwesomeIcon icon={faEye} /></ActionButton>
                                {
                                    (RowData.Status === LICENCE_STATUS.ACTIVE || RowData.Status === LICENCE_STATUS.INACTIVE || RowData.Status === LICENCE_STATUS.PENDING_VERIFICATION)
                                    && <ActionButton tooltip={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_CONTENT_BUTTON_EDIT)} color="forward" onClick={() => navigateTo(LICENCE_CONFIG_EDIT_PAGE, { LicenceType: RowData })}><FontAwesomeIcon icon={faEdit} /></ActionButton>

                                }
                                <ActionButton tooltip={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_CONTENT_BUTTON_USEVERSIONTEMPLATE)} color="primary" onClick={() => navigateTo(LICENCE_CONFIG_CREATE_FROM_EXISTING_PAGE, { LicenceType: RowData })}><FontAwesomeIcon fixedWidth icon={faCopy} /></ActionButton>
                            </React.Fragment>
                        )}
                        renderMobileCardActions={renderMobileCardActions}
                    />
                </div>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <Layout type={LAYOUT_TYPE.FORM} title={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_CONTENT_TITLE_VIEWSERVICEVERSIONS)}>
                <div className="grid-container">
                    <Row>
                        <Col>
                            <Button className="add-action-button" color="neutral" onClick={() => navigateTo(LICENCE_CONFIG_CREATE_PAGE, { LicenceType: LicenceType })}>{t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_NAVIGATION_BUTTON_CREATENEWVERSION)}</Button>
                        </Col>
                    </Row>
                    {renderGrid()}
                </div>
            </Layout>
            <PageFooter type={LAYOUT_TYPE.FORM}>
                <Button color="backward" size="sm" type="button" onClick={() => navigateTo(LICENCE_CONFIG_LICENCE_LIST_PAGE)}>{t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_NAVIGATION_BUTTON_BACK)}</Button>
            </PageFooter>
        </React.Fragment>
    );
}

