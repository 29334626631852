import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GENERAL_INFORMATION_FORMTYPE, GET_FORM_GENERAL_INFO_URL, LAYOUT_TYPE } from '../../../App/AppSettings';
import InternalGeneralInformationCorporateSection from '../../../Content/FormComponents/InternalGeneralInformationCorporateSection';
import InternalGeneralInformationSection from '../../../Content/FormComponents/InternalGeneralInformationSection';
import { ERROR, getLangKey } from '../../../Shared/Constants/LanguageKeys';
import { getParams, useAsyncFetch } from '../../../Shared/Fetch';
import { toastError } from '../../../Shared/Forms/Toaster.js';
import history from '../../../Shared/History';

const GeneralInfoComponent = {
    [GENERAL_INFORMATION_FORMTYPE.INDIVIDUAL]: InternalGeneralInformationSection,
    [GENERAL_INFORMATION_FORMTYPE.CORPORATE]: InternalGeneralInformationCorporateSection,
}

export default function GeneralInfo(props) {

    const { state } = history.location;
    const [generalInfo, setGeneralInfo] = useState(undefined);
    const [isGeneralInfoLoaded, setGeneralInfoLoaded] = useState(false);
    const { t } = useTranslation();
    const response = useAsyncFetch(GET_FORM_GENERAL_INFO_URL + state.ID, getParams());

    useEffect(() => {
        async function setData() {
            const { IsSuccess, Messages, Data } = response.body;

            if (response.success && IsSuccess) {
                const { FormData } = Data;
                const retrievedValues = JSON.parse(FormData);
                const generalInfo = retrievedValues.GeneralInformation;
                const GeneralInfoData = Object.assign(Data, generalInfo);

                setGeneralInfo(GeneralInfoData);
                setGeneralInfoLoaded(true);
            }
            else {
                // Error message to be loaded here
                toastError(t(getLangKey(ERROR.BACKEND_ERROR_MESSAGE, Messages)), Messages);
            }
        }

        if (response) {
            setData();
        }
    }, [response, t]);


    const GeneralInfo = () => {
        const GeneralInfo = GeneralInfoComponent[generalInfo.GeneralInformationType];

        return (
            <GeneralInfo
                values={generalInfo}
                type={LAYOUT_TYPE.FORM_CONTENT}
                sectionName={props.sectionName}
                toggleSection={props.toggleSection}
                sectionState={props.sectionState}
            />
        );
    };

    return (
        <>
            {isGeneralInfoLoaded && <GeneralInfo />}
        </>
    );
}
