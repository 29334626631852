import { faEdit } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { INBOX_LIST_URL, IS_DEMO, LAYOUT_TYPE, PATH } from '../../App/AppSettings';
import Layout from '../../Layout';
import { fetchRequest, getParams, navigateTo } from '../../Shared/Actions';
import { DataTable, MobileCardActionButton } from '../../Shared/DataTable';
import { getCustomComponents } from '../../Shared/DataTable/BaseTable.js';
import { withSMARTWrapper } from '../../Shared/Forms';
import './Inbox.css';
import { getLangKey, LANGUAGE_KEYS } from '../../Shared/Constants/LanguageKeys';
import { getLicenceTypeNameTranslationKey } from '../DisplayComponents/DisplayUtils';

const RETRIEVE_URL = INBOX_LIST_URL;

class InternalInboxList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            count: 0
        };
    }

    // LIFECYCLE METHODS - START
    componentDidMount = async () => {
        const { loader } = this.props;

        loader.start();

        const response = await fetchRequest(RETRIEVE_URL, getParams(), IS_DEMO);
        const { Data } = response.body;

        this.setState({
            success: response.success,
            data: Data,
            count: ((Data !== undefined) ? Data.length : 0)
        });

        loader.done();
    };
    // LIFECYCLE METHODS - END

    // CLASS METHODS
    setContent = rowData => {
        const { ID, FormName, InternalDescription, ReferenceNo, WorkflowStateId, LicenceTypeId, ApplicationTypeCodeName } = rowData;
        return <Link to={
            {
                pathname: PATH.INBOX.FORMPROCESSING,
                state: {
                    ID, FormName, InternalDescription, ReferenceNo, WorkflowStateId, LicenceTypeId, ApplicationTypeCodeName
                }
            }
        }>{rowData.ReferenceNo}</Link>;
    };

    renderMobileCardActions = data => {
        const { ID, FormName, InternalDescription, ReferenceNo, WorkflowStateId, LicenceTypeId, ApplicationTypeCodeName } = data;
        return (
            <React.Fragment>
                <MobileCardActionButton
                    onClick={() => navigateTo((PATH.INBOX.FORMPROCESSING),
                            { ID, FormName, InternalDescription, ReferenceNo, WorkflowStateId, LicenceTypeId, ApplicationTypeCodeName })}
                    icon={faEdit}
                    label='Update'
                />
            </React.Fragment>
        );
    };

    setLicenceNameTranslatedContent = (rowData) => {
        const { t } = this.props;
        const licenceTypeNameTranslationKey = getLicenceTypeNameTranslationKey(rowData.FormName);
        return (
            <>{t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY, licenceTypeNameTranslationKey, rowData.LicenceTypeId))}</>
        );
    }

    getTranslatedString = (rowData) => {
        const { t } = this.props;
        const licenceTypeNameTranslationKey = getLicenceTypeNameTranslationKey(rowData.FormName);
        return t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY, licenceTypeNameTranslationKey, rowData.LicenceTypeId));
    }
    
    setAppTypeTranslatedContent = (rowData) => {
        const { t } = this.props;
        return (
            <>{t(getLangKey(LANGUAGE_KEYS.BLS_CODE_LICENCEAPPLICATIONTYPE_TITLE_KEY, rowData.ApplicationTypeCodeName))}</>
        );
    }

    getAppTypeTranslatedString = (rowData) => {
        const { t } = this.props;
        return t(getLangKey(LANGUAGE_KEYS.BLS_CODE_LICENCEAPPLICATIONTYPE_TITLE_KEY, rowData.ApplicationTypeCodeName));
    }

    renderGrid = () => {
        const { t } = this.props;
        if (this.state.success) {
            return (
                <React.Fragment>
                    <Row>
                        <Col>
                            <DataTable
                                pageSize="5"
                                minFilterChars='2'
                                data={this.state.data}
                                noResultsMessage={t(LANGUAGE_KEYS.BLS_INTERNALINBOX_MESSAGE_INFORMATION_NOTASKS)}
                                components={getCustomComponents()}
                                columns={
                                    {
                                        RunningNumber: { title: t(LANGUAGE_KEYS.BLS_INTERNALINBOX_TABLE_TITLE_NUMBER), width: '5%', DBkey: 'Id' },
                                        IsCheckedOut: { title: t(LANGUAGE_KEYS.BLS_INTERNALINBOX_TABLE_TITLE_LOCKED), DBkey: 'IsCheckedOut' },
                                        ReferenceNo: { title: t(LANGUAGE_KEYS.BLS_INTERNALINBOX_TABLE_TITLE_REFNO), width: '10%', DBkey: 'ReferenceNo', setContent: this.setContent },
                                        FormName: { title: t(LANGUAGE_KEYS.BLS_INTERNALINBOX_TABLE_TITLE_SERVICENAME), width: '25%', DBkey: 'FormName', setContent: this.setLicenceNameTranslatedContent, getTranslatedString: this.getTranslatedString },
                                        FormType: { title: 'Form Type', visible: false, DBkey: 'FormType' },
                                        ApplicationType: { title: t(LANGUAGE_KEYS.BLS_INTERNALINBOX_TABLE_TITLE_APPLICATIONTYPE), width: '10%', DBkey: 'ApplicationType', setContent: this.setAppTypeTranslatedContent, getTranslatedString: this.getAppTypeTranslatedString },
                                        SubmissionDate: { title: t(LANGUAGE_KEYS.BLS_INTERNALINBOX_TABLE_TITLE_SUBMISSIONDATE), width: '15%', DBkey: 'RequestDate', isDate: true },
                                        LastUpdatedDate: { title: t(LANGUAGE_KEYS.BLS_INTERNALINBOX_TABLE_TITLE_LASTUPDATED), width: '18%', DBkey: 'LastUpdatedDate', isDate: true },
                                        SLADate: {
                                            title: t(LANGUAGE_KEYS.BLS_INTERNALINBOX_TABLE_TITLE_SLADATE),
                                            width: '15%',
                                            DBkey: 'ExpectedResolutionDate',
                                            DependantKey: 'SLAStatus',
                                            isDate: true
                                        },
                                        SLAStatus: { title: t(LANGUAGE_KEYS.BLS_INTERNALINBOX_TABLE_TITLE_STATUS), width: '0%', visible: false, DBkey: 'SLAStatus' },
                                        StatusBadge: { title: t(LANGUAGE_KEYS.BLS_INTERNALINBOX_TABLE_TITLE_STATUS), width: '8%', DBkey: 'InternalDescription' }
                                    }
                                }
                                renderMobileCardActions={this.renderMobileCardActions}
                            />
                        </Col>
                    </Row>
                </React.Fragment>
            );
        }
    };

    render() {
        const { t } = this.props;
        return (
            <React.Fragment>
                <Layout type={LAYOUT_TYPE.FORM} title={t(LANGUAGE_KEYS.BLS_INTERNALINBOX_CONTENT_TITLE_MYTASKS)}>
                    <div className="grid-container">
                        <div className="inbox-grid-container">
                            {this.renderGrid()}
                        </div>
                    </div>
                </Layout>
            </React.Fragment>
        );
    }
}

export default withTranslation()(withSMARTWrapper(InternalInboxList));

