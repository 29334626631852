import React from 'react';
import SubSection from '../../../../../../Section/SubSection';
import { Plaintext } from '../../../../../../Shared/Forms';

const IS_UPPER = true;

class CommonUraChangeUseApprovalLicenceOverview extends React.Component {
    render() {
        const { licenceDetails, generalInformation } = this.props;
        const entity = generalInformation.GI_EntitydetailsEntityname;

        return (
            <>
                <SubSection orientation="left" title="Licence overview">
                    <Plaintext
                        label="Licence Name"
                        name="LicenceName"
                        value={licenceDetails.LicenceName}
                        isUpper={IS_UPPER}
                    />
                    <Plaintext
                        label="Licence Number"
                        name="LicenceNumber"
                        value={licenceDetails.LicenceNumber}
                        isUpper={IS_UPPER}
                    />
                    <Plaintext
                        label="Licensee"
                        name="Licensee"
                        value={generalInformation.GI_Name}
                        isUpper={IS_UPPER}
                    />
                    <Plaintext
                        label="Licence Status"
                        name="LicenceStatus"
                        value={licenceDetails.LicenceStatus}
                        isUpper={IS_UPPER}
                    />
                    <Plaintext
                        label="Agency"
                        name="Agency"
                        value={licenceDetails.Agency}
                        isUpper={IS_UPPER}
                    />
                    {entity &&
                        <Plaintext
                            label="Entity"
                            name="Entity"
                            value={entity}
                            isUpper={IS_UPPER}
                        />
                    }
                    <Plaintext
                        label="Issued Date"
                        name="IssuedDate"
                        value={licenceDetails.IssuedDate}
                        isUpper={IS_UPPER}
                    />
                    <Plaintext
                        label="Expiry Date"
                        name="ExpiryDate"
                        value={licenceDetails.ExpiryDate}
                        isUpper={IS_UPPER}
                    />
                </SubSection>
            </>
        );
    }
}

export default CommonUraChangeUseApprovalLicenceOverview;