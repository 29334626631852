import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { SMARTForm, SelectList, Text } from '../../../Shared/Forms';
import { FIELD_SIZE } from '../../../Shared/Constants';
import { ERROR } from '../../../Shared/Constants/LanguageKeys';
import { isValidForm } from '../../../Shared/Actions';
import { LANGUAGE_KEYS } from '../../../Shared/Constants/LanguageKeys';

const FORM_CONTEXT = 'AddNewTestAccount';
const FORM_INITIAL_VALUES = {
  UserType: '',
  Status: '',
  Email: '',
  WorkflowUserRoleGuidList: [],
};

const FORM_ERROR_INITIAL_VALUES = {
  UserType: '',
  Email: '',
  WorkflowUserRoleGuidList: '',
};


const ACTIVE_STATUS = 'Active';
const INACTIVE_STATUS = 'Inactive';
const BLANK_STATUS = '';
const INTERNAL_USER_TYPE = 'Internal';

export default function TestAccountFormModal(props) {
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState(FORM_INITIAL_VALUES);
  const [formErrors, setFormErrors] = useState(FORM_ERROR_INITIAL_VALUES);
  const [roleSelection, setRoleSelection] = useState([]);

  useEffect(() => {
    if (props.isEdit) {
      setRolesList(props.updateData.UserType);
      setFormValues((prev) => ({ ...prev, ...props.updateData }));
    }
  }, [props.isEdit, props.updateData,]);
  //Form Validation
  const FORM_VALIDATIONS = Yup.object().shape({
    UserType: Yup.string().required(ERROR.LABEL + t(ERROR.REQUIRED)),
    WorkflowUserRoleGuidList: Yup.array()
      .required(ERROR.LABEL + t(ERROR.REQUIRED))
      .min(1, ERROR.LABEL + t(ERROR.REQUIRED)),
    Email: Yup.string().trim()
      .required(ERROR.LABEL + t(ERROR.REQUIRED))
      .email(t(ERROR.EMAIL_INVALID)),
  });

  //Cancel
  const prepareCancel = () => {
    setFormValues(FORM_INITIAL_VALUES);
    setFormErrors(FORM_ERROR_INITIAL_VALUES);
    props.cancelCallback();
  };

  //Set form error
  const reSetFormError = (error) => {
    setFormErrors((prev) => ({ ...prev, ...error }));
  };

  //Check array items available in another array
  const isItemExistInArray = (a, b) => a.some((i) => b.includes(i));

  //Generate Previous Test Account array
  const formPreviousTestAccounts = () => {
    return props.testAccounts.map((d) => {
      return {
        WorkflowUserRoleGuid: d.WorkflowUserRoleGuid.split(','),
        Email: d.Email,
        UserType: d.UserType,
        Status: d.Status,
        LicenceTypeId: props.licenceTypeId, 
        IsDeleted: d.IsDeleted
      };
    });
  };

  //Data to save new test account
  const prepareToSaveNewData = (values) => {
    const isFormValid = isValidForm(
      FORM_VALIDATIONS,
      prepareSubmitValues(values)
    );
    if (isFormValid) {
      setFormErrors(FORM_ERROR_INITIAL_VALUES);
      const previousTestAccounts = formPreviousTestAccounts();
      validateEmailAndSubmit(previousTestAccounts, values, props.saveCallback);
    } else {
      formValidation(values);
    }
  };

  const prepareSubmitValues = (values) => {
    if (values.WorkflowUserRoleGuidList === '') {
      values.WorkflowUserRoleGuidList = [];
    }
    return values;
  };

  //Data to update a test account
  const prepareToUpdateData = (values) => {
    const isFormValid = isValidForm(
      FORM_VALIDATIONS,
      prepareSubmitValues(values)
    );
    if (isFormValid) {
      setFormErrors(FORM_ERROR_INITIAL_VALUES);
      ////exclude updateData test account details from props.testAccounts array and validate the new data.
      const previousTestAccounts = formPreviousTestAccounts().filter(
        (d) =>
          !(
            d.Email === props.updateData.Email &&
            d.UserType === props.updateData.UserType && 
            d.IsDeleted === false
          )
      );
      validateEmailAndSubmit(
        previousTestAccounts,
        values,
        props.updateCallback
      );
    } else {
      formValidation(values);
    }
  };

  //Manual form validation
  const formValidation = (values) => {
    FORM_VALIDATIONS.validate(values, { abortEarly: false }).catch((err) => {
      const validationErrors = err.inner.reduce((acc, errorMessage) => {
        return {
          ...acc,
          [errorMessage.path]: errorMessage.message,
        };
      }, {});
      reSetFormError(validationErrors);
    });
  };

  //Check email already used
  const validateEmailAndSubmit = (previousTestAccounts, values, action) => {

    if(values && values.Email){
      values.Email = values.Email.trim();
    }

    if (previousTestAccounts.length > 0) {
      const availableTestAccounts = previousTestAccounts.filter(
        (d) => d.UserType === values.UserType && d.Email.toLowerCase() === values.Email.toLowerCase() && d.IsDeleted === false
      );

      if (availableTestAccounts.length === 0) {
        //User type and email not available. can add
        action(values);
      } else {
        //test accounts available for the values.UserType and values.Email
        const previousTestAccount = availableTestAccounts.find(
          (d) =>
            d.UserType === values.UserType &&
            d.Email.toLowerCase() === values.Email.toLowerCase() &&
            isItemExistInArray(d.WorkflowUserRoleGuid, values.WorkflowUserRoleGuidList)
        );
        if (
          previousTestAccount === undefined ||
          (previousTestAccount !== undefined &&
            previousTestAccount.Status === INACTIVE_STATUS)
        ) {
          action(values);
        } else {
          reSetFormError({
            Email:
            t(LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_MODAL_NO_DUPLICATE_EMAIL_MESSAGE),
          });
        }
      }
    } else {
      action(values);
    }
  };

  //onChange of user type set the available roles list
  const setRolesList = (value) => {
    setRoleSelection(props.workflowRoles.filter((d) => d.userType === value));
  };

  //onChange of user type clear the selected roles
  const clearRoles = (values, onChangeField) => {
    values.WorkflowUserRoleGuidList = '';
    onChangeField('WorkflowUserRoleGuidList', []);
  };

  return (
    <Modal
      isOpen={props.isOpen}
      className={'modal-content ' + props.modalClassName}
    >
      <SMARTForm
        titleClassName='theme-new'
        formContext={FORM_CONTEXT}
        formValues={formValues}
        serverURL='null'
      >
        {({ onChangeField, onChange, values }) => (
          <React.Fragment>
            <ModalHeader className={props.modalHeaderClassName}>
              {props.title}
            </ModalHeader>
            <ModalBody>
              <Row className='body-content'>
                <Col>
                  <SelectList
                    name='UserType'
                    value={values.UserType}
                    options={props.userTypes}
                    onChangeField={(name, value) => {
                      reSetFormError({ UserType: '' });
                      clearRoles(values, onChangeField);
                      setRolesList(value);
                      onChangeField(name, value);
                    }}
                    isMulti={false}
                    error={formErrors.UserType}
                    label='User Type'
                    isDisabled={
                      values.Status === ACTIVE_STATUS &&
                      values.UserType === INTERNAL_USER_TYPE
                    }
                    required
                  />
                  <SelectList
                    name='WorkflowUserRoleGuidList'
                    value={values.WorkflowUserRoleGuidList}
                    options={roleSelection}
                    onChangeField={(name, value) => {
                      reSetFormError({ WorkflowUserRoleGuidList: '' });
                      onChangeField(name, value);
                    }}
                    placeholder={t(LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_FORM_PLACEHOLDER_TESTER_ROLE)}
                    inputSize={FIELD_SIZE.LARGE}
                    label='Tester Role'
                    error={formErrors.WorkflowUserRoleGuidList}
                    isMulti={true}
                    isClearable={false}
                    required
                  />
                  <Text
                    name='Email'
                    value={values.Email}
                    placeholder={t(LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_FORM_PLACEHOLDER_TESTER_EMAIL)}
                    onChange={(e) => {
                      reSetFormError({ Email: '' });
                      onChange(e);
                    }}
                    minLength={0}
                    maxLength={255}
                    inputSize={FIELD_SIZE.LARGE}
                    label='Email'
                    error={formErrors.Email}
                    readOnly={values.Status !== BLANK_STATUS}
                    required
                  />
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              {props.cancelCallback && (
                <Button
                  color='backward'
                  size='sm'
                  onClick={() => {
                    prepareCancel();
                  }}
                >
                  {props.cancelText}
                </Button>
              )}
              {props.saveCallback && (
                <Button
                  color='success'
                  size='sm'
                  onClick={() => {
                    prepareToSaveNewData(values);
                  }}
                >
                  {props.saveText}
                </Button>
              )}
              {props.updateCallback && (
                <Button
                  color='success'
                  size='sm'
                  onClick={() => {
                    prepareToUpdateData(values);
                  }}
                >
                  {props.updateText}
                </Button>
              )}
            </ModalFooter>
          </React.Fragment>
        )}
      </SMARTForm>
    </Modal>
  );
}
