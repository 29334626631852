import React from 'react';
import { InputGroup } from 'reactstrap';

export default function InlineInput(props) {

    return (
        <div className="row form-group">
            <label className="required label-default col-lg-3 col-form-label">{props.label}</label>
            <div className="col-lg-7">
                <InputGroup>
                    {props.children}
                </InputGroup>
            </div>
        </div>
    );
}