import React from 'react';
import { APPLICATION_TYPE, LAYOUT_TYPE } from '../../../../../App/AppSettings';
import Layout from '../../../../../Layout';
import history from '../../../../../Shared/History';
import '../../../../Content.css';
import { FormProcessing, LicenceDetailsView } from '../../../Components';
import * as Actions from '../../../Components/FormProcessing/Actions';
import { UraChangeUseApprovalAmend, UraChangeUseApprovalApply, UraChangeUseApprovalCancel } from '../UraChangeUseApprovalComponents/ApplicationInfoSection';

const ApplicationInfoComponent = {
	[APPLICATION_TYPE.APPLY]: UraChangeUseApprovalApply,
	[APPLICATION_TYPE.AMEND]: UraChangeUseApprovalAmend,
	[APPLICATION_TYPE.CANCEL]: UraChangeUseApprovalCancel
}

const CustomActions = {
	'Request For Clarification': Actions.SubmitClarification
}

// THIS COMPONENT IS DEPRECITATED
class ChangeofuseapprovalProcessing extends React.Component {

	constructor(props) {
		super(props);
		this.state = { toggleCheckOut: true };
	}

	async componentDidMount() {
		const { setPageNotFound } = this.props;
		const { state } = history.location;
		const isPageNotFound = state === undefined || state.ID === undefined;

		await this.setState({ isPageNotFound });

		setPageNotFound(isPageNotFound);
	}

	toggleCheckInCheckOut = () => {
		this.setState((prevState) => {
			return {
				toggleCheckOut: !prevState.toggleCheckOut
			}
		})
	}

	render() {
		const { isPageNotFound } = this.state;
		if (isPageNotFound === undefined || isPageNotFound) return null;

		return (
			<Layout type={LAYOUT_TYPE.FORM} title="Review Application" footer={false} titleClassName="sticky-title"
				titleChildren={
					<FormProcessing
						isAction={true}
						customActions={CustomActions}
						toggleCheckInCheckOut={this.toggleCheckInCheckOut}
					/>
				}>
				<LicenceDetailsView
					toggleCheckInCheckOut={this.state.toggleCheckOut}
					applicationInfoComponent={ApplicationInfoComponent}
				/>
			</Layout>
		);
	}
}

export default ChangeofuseapprovalProcessing;