import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { withRouter } from 'react-router-dom'


class ModalConfirm extends React.Component {
    
    render() {
        return (
            <Modal isOpen={this.props.isOpen} className={"modal-content " + this.props.className}>
                <ModalHeader>{this.props.contentHeader}</ModalHeader>
                <ModalBody>{this.props.contentBody}</ModalBody>
                <ModalFooter>
                    {this.props.cancelCallback && <Button color="backward" size="sm" onClick={this.props.cancelCallback}>{this.props.cancelText}</Button>}
                    {this.props.confirmCallback && <Button color="forward" size="sm" onClick={this.props.confirmCallback}>{this.props.confirmText}</Button>}
                </ModalFooter>
            </Modal>
        );
    }
}

// PropTypes: For prop validation
ModalConfirm.propTypes = {
    isOpen: PropTypes.bool,
    contentHeader: PropTypes.string,
    confirmText: PropTypes.string,
    cancelText: PropTypes.string,
    confirmCallback: PropTypes.func,
    cancelCallback: PropTypes.func
};

// PropTypes: Defaulting value for optional props
ModalConfirm.defaultProps = {
    isOpen: false,
    confirmText: 'Yes',
    cancelText: 'No'
};


export default withRouter(ModalConfirm);