import React from 'react';
import { Row } from 'reactstrap';
import { SITE_LOGO, SITE_LOGO_ALT_TEXT } from '../App/AppSettings';

class NavHeader extends React.Component {
     
    render() {
        return (
            <Row className={"navbar-header " + (this.props.className !== undefined? this.props.className: "")}>
                 <img src={ SITE_LOGO } className="nav-header-logo col-auto" alt={ SITE_LOGO_ALT_TEXT }></img>
                {this.props.children}
            </Row>
        );
    }
}

export default NavHeader;