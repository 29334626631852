import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row, TabPane } from 'reactstrap';

class WizardContainer extends React.Component {

    render() {
        const { container } = this.props;

        return (
            <div>
                {container &&
                    <TabPane
                        className={"wizard-container form-content"}
                        tabId={this.props.tabId}
                    >
                        <Row>
                            <Col className="wizard-content">
                                {this.props.children}
                            </Col>
                        </Row>
                    </TabPane>
                }
                {!container &&
                    <div>
                        {this.props.children}
                    </div>
                }
            </div>
        );
    }
}

// PropTypes: For prop validation
WizardContainer.propTypes = {
    tab: PropTypes.string.isRequired,
    container: PropTypes.bool
};

WizardContainer.defaultProps = {
    container: true
}


export default WizardContainer;