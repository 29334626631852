import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Label, Row } from 'reactstrap';
import * as Yup from 'yup';
import { LICENCE_APP_CONFIG_GET_SUPPORTING_DOC_NAME } from '../../../App/AppSettings';
import { getParams, isValidForm } from '../../../Shared/Actions';
import { FIELD_SIZE } from '../../../Shared/Constants';
import { ERROR, LANGUAGE_KEYS } from '../../../Shared/Constants/LanguageKeys';
import { ActionButton, DataTable } from '../../../Shared/DataTable';
import { getCustomComponents } from '../../../Shared/DataTable/BaseTable.js';
import { useAsyncFetch } from '../../../Shared/Fetch';
import { CreatableSelectList, Paragraph, Radio } from '../../../Shared/Forms';
import { ModalConfirm } from '../../../Shared/Modal';
import { getLangKey } from '../../DisplayComponents/DisplayUtils';

const ADDITIONAL_APPLICATION_SUPPORTING_DOC_VALUES = {
    GlobalDocumentId: '',
    IsExistingSupportingDocument: 'true',
    SupportingDocumentName: '',
    SupportingDocumentDescription: '',
    IsMandatorySupportingDocument: ''
};

const DUPLICATE_DOC_ERROR_MSG = 'There is already a selected document with the same name. Please select a document with a different name.';

export default function AdditionalApplicationSupportingDocument(props) {
    const { t } = useTranslation();
    const additionalApplicationSupportingDocValidation = Yup.object().shape({
        IsExistingSupportingDocument: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED)),
        SupportingDocumentName: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED)),
        SupportingDocumentDescription: Yup.string().when('IsExistingSupportingDocument', {
            is: 'false',
            then: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED))
        }),
        IsMandatorySupportingDocument: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED))
    });

    const { values } = props;
    const [additionalApplicationModalState, setAdditionalApplicationModalState] = useState(null);
    const [additionalApplicationSupportingDocValues, setAdditionalApplicationSupportingDocValues] = useState(ADDITIONAL_APPLICATION_SUPPORTING_DOC_VALUES);
    const [additionalApplicationSupportingDocumentData, setAdditionalApplicationSupportingDocumentData] = useState([]);
    const [additionalApplicationSupportingDocumentList, setAdditionalApplicationSupportingDocumentList] = useState([]);
    const [globalAdditionalApplicationSupportingDocumentList, setGlobalAdditionalApplicationSupportingDocumentList] = useState([]);
    const [duplicateAdditionalApplicationDocumentNameError, setDuplicateAdditionalApplicationDocumentNameError] = useState(false);
    const [selectedAdditionalApplicationSupportingDocError, setSelectedAdditionalApplicationSupportingDocError] = useState(false);
    const [additionalApplicationDocCount, setAdditionalApplicationDocCount] = useState(values.SupportingDocumentData.length);
    const [selectedIndex, setSelectedIndex] = useState(-1);

    const DELETE_MODAL = {
        hasModal: true,
        name: 'Delete',
        modalHeader: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_MODAL_DELETE),
        modalContent: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_MESSAGE_MODAL_DELETERECORD)
    };

    const ADD_ADDITIONAL_APPLICATION_SUPPORTING_DOCUMENT_MODAL = {
        hasModal: true,
        name: 'AddAdditionalApplicationSupportingDocument',
        modalHeader: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_MODAL_ADDADDITIONALAPPSUPPORTINGDOC)
    };

    const EDIT_ADDITIONAL_APPLICATION_SUPPORTING_DOCUMENT_MODAL = {
        hasModal: true,
        name: 'EditAdditionalApplicationSupportingDocument',
        modalHeader: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_MODAL_EDITADDITIONALAPPSUPPORTINGDOC)
    };

    //Set relevant data from props
    useEffect(() => {
        if (values.SupportingDocumentData.length > 0) {
            setAdditionalApplicationSupportingDocumentData(values.SupportingDocumentData);
        } else {
            setAdditionalApplicationSupportingDocumentData([]);
        }
    }, [values.SupportingDocumentData]);

    const supportingDocResponse = useAsyncFetch(LICENCE_APP_CONFIG_GET_SUPPORTING_DOC_NAME, getParams());

    //Fetch select list options
    useEffect(() => {
        if (supportingDocResponse) {
            const fetchSupportingDocListResponse = async response => {
                const { Data, IsSuccess } = response.body;
                if (response.success && IsSuccess) {
                    const supportingDocs = [];
                    Data.forEach(element => {
                        supportingDocs.push({
                            label: t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_GLOBALSUPPORTINGDOC_DOCNAME_KEY, element.GlobalDocumentGuid)),
                            value: element.GlobalDocumentId
                        });
                    });
                    setAdditionalApplicationSupportingDocumentList(supportingDocs);
                    setGlobalAdditionalApplicationSupportingDocumentList(Data);
                }
            };

            fetchSupportingDocListResponse(supportingDocResponse);
        }
    }, [supportingDocResponse, t]);

    const resetAdditionalApplicationSupportingDocModal = () => {
        setAdditionalApplicationSupportingDocValues(ADDITIONAL_APPLICATION_SUPPORTING_DOC_VALUES);
        setDuplicateAdditionalApplicationDocumentNameError(false);
    };

    const displayRequiredMessage = label => (
        `${label} ${t(ERROR.REQUIRED)}`
    );

    const getSupportingDocOptions = () => {
        let updatedSuppDocOptions;
        if (additionalApplicationModalState === EDIT_ADDITIONAL_APPLICATION_SUPPORTING_DOCUMENT_MODAL.name) {
            updatedSuppDocOptions = additionalApplicationSupportingDocumentList.filter(currDocOption =>
                !additionalApplicationSupportingDocumentData.map(doc => doc.GlobalDocumentId).includes(currDocOption.value)
                || currDocOption.value === additionalApplicationSupportingDocValues.GlobalDocumentId);
        } else {
            updatedSuppDocOptions = additionalApplicationSupportingDocumentList.filter(currDocOption =>
                !additionalApplicationSupportingDocumentData.map(doc => doc.GlobalDocumentId).includes(currDocOption.value));
        }
        return updatedSuppDocOptions;
    };

    const toggleAdditionalApplicationModal = modalName => {
        if (modalName === ADD_ADDITIONAL_APPLICATION_SUPPORTING_DOCUMENT_MODAL.name) {
            setSelectedAdditionalApplicationSupportingDocError(false);
        }
        setAdditionalApplicationModalState((additionalApplicationModalState !== modalName) ? modalName : null);
    };

    const setAdditionalApplicationSupportingDocumentValues = (name, value, optionsArray) => {
        const id = optionsArray.findIndex(o => o.value === value);
        const label = optionsArray[id] ? optionsArray[id].label : null;

        if (name === 'SupportingDocumentName') {
            setAdditionalApplicationSupportingDocValues(prevValues => ({ ...prevValues, 'SupportingDocumentName': (label ? label : value), 'GlobalDocumentId': value }));
        }
    };

    const setDocValues = (name, value) => {
        setAdditionalApplicationSupportingDocValues(prevValues => ({ ...prevValues, [name]: value }));
    };

    const populateAdditionalApplicationDocumentDescription = (name, value) => {
        const selectedSuppDoc = globalAdditionalApplicationSupportingDocumentList.find(d => d.GlobalDocumentId === value);
        setDocValues('SupportingDocumentDescription', selectedSuppDoc ?
            t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_GLOBALSUPPORTINGDOC_DOCDESC_KEY, selectedSuppDoc.GlobalDocumentGuid)) :
            ''
        );
    };

    const onCreateSuppDocOption = (name, value) => {
        setAdditionalApplicationSupportingDocumentValues(name, value, additionalApplicationSupportingDocumentList);
        const supportingDocs = [...additionalApplicationSupportingDocumentList];
        supportingDocs.push({ label: value, value });
        setAdditionalApplicationSupportingDocumentList(supportingDocs);
    };

    const isDuplicateDocumentNameCheck = () => {
        const trimmedChosenDocName = additionalApplicationSupportingDocValues.SupportingDocumentName.trim().toLowerCase();
        for (const doc of additionalApplicationSupportingDocumentData) {
            if (doc.SupportingDocumentName.toLowerCase() === trimmedChosenDocName) {
                return true;
            }
        }
        return false;
    };

    const addAdditionalApplicationSupportingDocument = () => {
        const isDuplicateDocumentName = isDuplicateDocumentNameCheck();
        setDuplicateAdditionalApplicationDocumentNameError(isDuplicateDocumentName ? true : false);
        if (!isDuplicateDocumentName && isValidForm(additionalApplicationSupportingDocValidation, additionalApplicationSupportingDocValues)) {
            setSelectedAdditionalApplicationSupportingDocError(false);
            const data = [...additionalApplicationSupportingDocumentData];
            data.push({ ...additionalApplicationSupportingDocValues, RowId: additionalApplicationDocCount });
            setAdditionalApplicationDocCount(additionalApplicationDocCount + 1);
            setAdditionalApplicationSupportingDocumentData(data);
            values.SupportingDocumentData = data;
            toggleAdditionalApplicationModal(ADD_ADDITIONAL_APPLICATION_SUPPORTING_DOCUMENT_MODAL.name);
        } else {
            setSelectedAdditionalApplicationSupportingDocError(true);
        }
    };

    const editAdditionalApplicationSupportingDoc = data => {
        setAdditionalApplicationSupportingDocValues(data);
        const index = values.SupportingDocumentData.findIndex(val =>
            val.RowId === data.RowId
        );
        setSelectedIndex(index);
        toggleAdditionalApplicationModal(EDIT_ADDITIONAL_APPLICATION_SUPPORTING_DOCUMENT_MODAL.name);
    };

    const confirmEditAdditionalApplicationSupportingDoc = () => {
        if (isValidForm(additionalApplicationSupportingDocValidation, additionalApplicationSupportingDocValues)) {
            setSelectedAdditionalApplicationSupportingDocError(false);
            const data = [...additionalApplicationSupportingDocumentData];
            data[selectedIndex] = additionalApplicationSupportingDocValues;
            setAdditionalApplicationSupportingDocumentData(data);
            values.SupportingDocumentData = data;
            toggleAdditionalApplicationModal(EDIT_ADDITIONAL_APPLICATION_SUPPORTING_DOCUMENT_MODAL.name);
            setSelectedIndex(null);
        } else {
            setSelectedAdditionalApplicationSupportingDocError(true);
        }
    };

    const deleteItem = data => {
        toggleAdditionalApplicationModal(DELETE_MODAL.name);
        const index = values.SupportingDocumentData.findIndex(val =>
            val.RowId === data.RowId
        );
        setSelectedIndex(index);
    };

    const confirmDeleteItem = () => {
        const data = [...additionalApplicationSupportingDocumentData];
        data.splice(selectedIndex, 1);
        setAdditionalApplicationSupportingDocumentData(data);
        values.SupportingDocumentData = data;
        setSelectedIndex(null);
    };

    const setMandatorySuppDoc = rowData => (
        rowData.IsMandatorySupportingDocument === 'true' ? t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_YES) : t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_NO)
    );

    const renderAdditionalApplicationSupportingDocModalContent = () => {
        const supportingDocOptions = getSupportingDocOptions();
        return (
            <div>
                <CreatableSelectList
                    isClearable={false}
                    name="SupportingDocumentName"
                    value={additionalApplicationSupportingDocValues.GlobalDocumentId}
                    minLength={0}
                    maxLength={140}
                    options={supportingDocOptions}
                    placeholder={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_SUPPORTINGDOCNAME)}
                    isMulti={false}
                    onChangeField={(name, valueArr) => {
                        setAdditionalApplicationSupportingDocumentValues(name, valueArr, additionalApplicationSupportingDocumentList);
                        populateAdditionalApplicationDocumentDescription(name, valueArr);
                    }}
                    onCreate={onCreateSuppDocOption}
                    inputSize={FIELD_SIZE.LARGE}
                    label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_PLACEHOLDER_SUPPORTINGDOCNAME)}
                    error={
                        !additionalApplicationSupportingDocValues.SupportingDocumentName && selectedAdditionalApplicationSupportingDocError ?
                            displayRequiredMessage(t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_SUPPORTINGDOCNAME)) :
                            ''
                    }
                    required
                />
                <Paragraph
                    name="SupportingDocumentDescription"
                    value={additionalApplicationSupportingDocValues.SupportingDocumentDescription}
                    placeholder={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_PLACEHOLDER_DESCRIPTION)}
                    onChange={e => setDocValues(e.target.name, e.target.value)}
                    minLength={0}
                    inputSize={FIELD_SIZE.LARGE}
                    label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_DESCRIPTION)}
                    required
                    readOnly={additionalApplicationSupportingDocValues.GlobalDocumentId > 0}
                    error={
                        !additionalApplicationSupportingDocValues.SupportingDocumentDescription && selectedAdditionalApplicationSupportingDocError ?
                            displayRequiredMessage(t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_DESCRIPTION)) :
                            ''
                    }
                />
                <Radio
                    name="IsMandatorySupportingDocument"
                    value={additionalApplicationSupportingDocValues.IsMandatorySupportingDocument}
                    options={[
                        { label: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_YES), value: 'true' },
                        { label: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_NO), value: 'false' }]}
                    onChange={e => setDocValues(e.target.name, e.target.value)}
                    inputSize={7}
                    label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_ISMANDATORY)}
                    error={
                        !additionalApplicationSupportingDocValues.IsMandatorySupportingDocument && selectedAdditionalApplicationSupportingDocError ?
                            displayRequiredMessage(t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_ISMANDATORY)) :
                            ''
                    }
                    required
                />
                {additionalApplicationSupportingDocValues.SupportingDocumentName && duplicateAdditionalApplicationDocumentNameError &&
                    <Label className="label-error">
                        {DUPLICATE_DOC_ERROR_MSG}
                    </Label>
                }
            </div>
        );
    };

    const setSuppDocNameTranslatedString = (rowData) => {
        const translatedString = t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_GLOBALSUPPORTINGDOC_DOCNAME_KEY, rowData.GlobalDocumentGuid));
        return <>{rowData.GlobalDocumentGuid ? translatedString : rowData.SupportingDocumentName}</>;
    };

    const setSuppDocDescTranslatedString = (rowData) => {
        const translatedString = t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_GLOBALSUPPORTINGDOC_DOCDESC_KEY, rowData.GlobalDocumentGuid));
        return <>{rowData.GlobalDocumentGuid ? translatedString : rowData.SupportingDocumentDescription}</>;
    };

    const getSuppDocNameTranslatedString = (rowData) => {
        const translatedString = t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_GLOBALSUPPORTINGDOC_DOCNAME_KEY, rowData.GlobalDocumentGuid));
        return rowData.GlobalDocumentGuid ? translatedString : rowData.SupportingDocumentName;
    };

    const getSuppDocDescTranslatedString = (rowData) => {
        const translatedString = t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_GLOBALSUPPORTINGDOC_DOCDESC_KEY, rowData.GlobalDocumentGuid));
        return rowData.GlobalDocumentGuid ? translatedString : rowData.SupportingDocumentDescription;
    };

    return (
        <React.Fragment>
            <Row>
                <Col >
                    <Button
                        className="float-right"
                        color="neutral"
                        onClick={() => {
                            resetAdditionalApplicationSupportingDocModal();
                            toggleAdditionalApplicationModal(ADD_ADDITIONAL_APPLICATION_SUPPORTING_DOCUMENT_MODAL.name);
                        }}
                    >
                        {t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_ADDDOCUMENT)}
                    </Button>
                </Col>
            </Row>
            <div className="smart-list">
                <DataTable
                    pageSize="3"
                    minFilterChars='2'
                    data={additionalApplicationSupportingDocumentData}
                    noResultsMessage={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_MESSAGE_PLACEHOLDER_NOSUPPORTINGDOC)}
                    components={getCustomComponents()}
                    columns={
                        {
                            RunningNumber: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_NUMBER), width: '5%' },
                            SupportingDocumentName: {
                                title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_SUPPORTINGDOCUMENT),
                                width: '30%',
                                DBkey: 'SupportingDocumentName',
                                setContent: setSuppDocNameTranslatedString,
                                getTranslatedString: getSuppDocNameTranslatedString
                            },
                            SupportingDocumentDescription: {
                                title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_DESCRIPTION),
                                width: '25%',
                                DBkey: 'SupportingDocumentDescription',
                                setContent: setSuppDocDescTranslatedString,
                                getTranslatedString: getSuppDocDescTranslatedString
                            },
                            IsMandatorySupportingDocument: {
                                title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_MANDATORY),
                                width: '10%', DBkey: 'IsMandatorySupportingDocument',
                                setContent: setMandatorySuppDoc
                            },
                            Actions: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_ACTION), width: '10%' }
                        }
                    }
                    renderActions={({ RowData }) => (
                        <React.Fragment>
                            <ActionButton
                                tooltip={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_EDIT)}
                                color="forward"
                                onClick={() => editAdditionalApplicationSupportingDoc(RowData)}
                            >
                                <FontAwesomeIcon icon={faEdit} />
                            </ActionButton>
                            <ActionButton
                                tooltip={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_DELETE)}
                                color="hazard"
                                onClick={() => deleteItem(RowData)}
                            >
                                <FontAwesomeIcon fixedWidth icon={faTrash} />
                            </ActionButton>
                        </React.Fragment>
                    )}
                />
                <ModalConfirm
                    isOpen={(additionalApplicationModalState === DELETE_MODAL.name)}
                    contentHeader={DELETE_MODAL.modalHeader}
                    contentBody={DELETE_MODAL.modalContent}
                    confirmText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_CONFIRM)}
                    confirmCallback={() => {
                        toggleAdditionalApplicationModal(DELETE_MODAL.name);
                        confirmDeleteItem();
                    }}
                    cancelText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_CANCEL)}
                    cancelCallback={() => toggleAdditionalApplicationModal(DELETE_MODAL.name)}
                />
                <ModalConfirm
                    isOpen={(additionalApplicationModalState === ADD_ADDITIONAL_APPLICATION_SUPPORTING_DOCUMENT_MODAL.name)}
                    contentHeader={ADD_ADDITIONAL_APPLICATION_SUPPORTING_DOCUMENT_MODAL.modalHeader}
                    confirmText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_ADD)}
                    confirmCallback={() => {
                        addAdditionalApplicationSupportingDocument();
                    }}
                    cancelText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_CANCEL)}
                    cancelCallback={() => toggleAdditionalApplicationModal(ADD_ADDITIONAL_APPLICATION_SUPPORTING_DOCUMENT_MODAL.name)}
                    contentBody={renderAdditionalApplicationSupportingDocModalContent()}
                />
                <ModalConfirm
                    isOpen={(additionalApplicationModalState === EDIT_ADDITIONAL_APPLICATION_SUPPORTING_DOCUMENT_MODAL.name)}
                    contentHeader={EDIT_ADDITIONAL_APPLICATION_SUPPORTING_DOCUMENT_MODAL.modalHeader}
                    confirmText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_EDIT)}
                    confirmCallback={() => {
                        confirmEditAdditionalApplicationSupportingDoc();
                    }}
                    cancelText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_CANCEL)}
                    cancelCallback={() => toggleAdditionalApplicationModal(EDIT_ADDITIONAL_APPLICATION_SUPPORTING_DOCUMENT_MODAL.name)}
                    contentBody={renderAdditionalApplicationSupportingDocModalContent()}
                />
            </div>
        </React.Fragment>
    );
}
