import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { Button, Col, Row } from 'reactstrap';
import { LICENCE_APP_CONFIG_CREATE_PAGE, LICENCE_APP_CONFIG_GET_LICENCE_APP_LIST, LICENCE_APP_CONFIG_VERSION_PAGE } from '../../App/AppSettings';
import { getParams, navigateTo } from '../../Shared/Actions';
import { DATE_TIME_FORMAT, FILTER, SELECT_FILTERS } from '../../Shared/Constants';
import { LANGUAGE_KEYS } from '../../Shared/Constants/LanguageKeys';
import { ActionButton, DataTable, MobileCardActionButton } from '../../Shared/DataTable';
import { getCustomComponents } from '../../Shared/DataTable/BaseTable.js';
import { useAsyncFetch } from '../../Shared/Fetch';
import Title from '../../Title';
import { getLangKey, getLicenceTypeNameTranslationKey } from '../DisplayComponents/DisplayUtils';

const RETRIEVE_URL = LICENCE_APP_CONFIG_GET_LICENCE_APP_LIST;

export default function LicenceConfigMgmtList() {
    const [data, setData] = useState([]);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [filterValues, setFilterValues] = useState({ categoryFilter: '', searchFilter: '' });
    const response = useAsyncFetch(RETRIEVE_URL, getParams());
    const { t } = useTranslation();

    useEffect(() => {
        const fetchData = async () => {
            const { Data, IsSuccess } = response.body;
            if (response.success && IsSuccess) {
                setData(Data);
                const categoryOptions = [];
                Data.forEach(sformType => {
                    if (categoryOptions.findIndex(option => option.value === sformType.LicenceName) < 0) {
                        const licenceTypeNameTranslationKey = getLicenceTypeNameTranslationKey(sformType.LicenceName);
                        const translatedName = t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY, licenceTypeNameTranslationKey, sformType.LicenceTypeId));
                        categoryOptions.push({ label: translatedName, value: sformType.LicenceName });
                    }
                });
                setCategoryOptions(categoryOptions);
            }
        };
        if (response) {
            fetchData();
        }
    }, [response, t]);

    const renderMobileCardActions = RowData => (
        <MobileCardActionButton
            onClick={() => navigateTo(LICENCE_APP_CONFIG_VERSION_PAGE, { ID: RowData.Id })}
            icon={faEye}
            label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_VIEW)}
        />
    );

    const filter = (setFilter, newData) => {
        const filterFields = {
            [FILTER]: newData.searchFilter,
            [SELECT_FILTERS]: {
                'LicenceName': newData.categoryFilter
            }
        };
        setFilter(filterFields);
    };

    const onFilterChange = (filterName, target, setFilter) => {
        let value = '';
        if (target !== null) {
            value = target.value;
        }
        let data;
        setFilterValues(prevState => {
            data = { ...prevState, [filterName]: value };
            return ({ ...prevState, [filterName]: value });
        });

        filter(setFilter, data);
    };

    const categoryFilter = props => {
        let categoryValue = filterValues.categoryFilter;
        categoryValue = _.find(categoryOptions, { 'value': filterValues.categoryFilter });

        return (
            <React.Fragment>
                <Select
                    isClearable
                    defaultValue={categoryValue}
                    onChange={selectedOption => onFilterChange('categoryFilter', selectedOption, props.setFilter)}
                    options={categoryOptions}
                    maxMenuHeight={150}
                    placeholder={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_SELECTSERVICE)}
                    menuPlacement="auto"
                    styles={{
                        menuPortal: styles => ({ ...styles, zIndex: 100 })
                    }}
                    className="select-filter select-list"
                />
                <input
                    type="text"
                    name="filter"
                    defaultValue={filterValues.searchFilter}
                    placeholder={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_NAVIGATION_FIELD_FILTER)}
                    className="custom-griddle-filter griddle-filter"
                    onChange={e => onFilterChange('searchFilter', e.target, props.setFilter)}
                />
            </React.Fragment>
        );
    };

    const getComponents = () => {
        let customComponents = getCustomComponents();
        customComponents.Filter = categoryFilter;

        return customComponents;
    };

    const setLicenceName = rowData => {
        const licenceTypeNameTranslationKey = getLicenceTypeNameTranslationKey(rowData.LicenceName);
        const licenceName = t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY, licenceTypeNameTranslationKey, rowData.LicenceTypeId));

        return <div>{licenceName}</div>;
    };

    const setStatusTranslatedContent = (rowData) => {
        return <>{t(getLangKey(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_VERSIONSTATUS_TITLE_KEY, rowData.Status))}</>;
    }

    const getStatusTranslatedString = (rowData) => {
        return t(getLangKey(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_VERSIONSTATUS_TITLE_KEY, rowData.Status));
    }

    const setAppTypeTranslatedContent = (rowData) => {
        return (
            <>{t(getLangKey(LANGUAGE_KEYS.BLS_CODE_LICENCEAPPLICATIONTYPE_TITLE_KEY, rowData.ApplicationTypeCodeName))}</>
        );
    }

    const getAppTypeTranslatedString = (rowData) => {
        return t(getLangKey(LANGUAGE_KEYS.BLS_CODE_LICENCEAPPLICATIONTYPE_TITLE_KEY, rowData.ApplicationTypeCodeName));
    }

    const getLicenceNameTranslatedString = (rowData) => {
        const licenceTypeNameTranslationKey = getLicenceTypeNameTranslationKey(rowData.LicenceName);
        return t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY, licenceTypeNameTranslationKey, rowData.LicenceTypeId));
    }

    const setEffectiveFromDate = (rowData) => {
        if (Date.parse(rowData.EffectiveFrom) > Date.parse(DATE_TIME_FORMAT.DEFAULT_MIN_DATE)) {
            return <>{rowData.EffectiveFrom}</>;
        } else {
            return <>{DATE_TIME_FORMAT.DEFAULT_EMPTY_DATE}</>;
        }
    };

    const setEffectiveToDate = (rowData) => {
        if (Date.parse(rowData.EffectiveTo) > Date.parse(DATE_TIME_FORMAT.DEFAULT_MIN_DATE)) {
            return <>{rowData.EffectiveTo}</>;
        } else {
            return <>{DATE_TIME_FORMAT.DEFAULT_EMPTY_DATE}</>;
        }
    };

    const renderLayout = () => {
        return (
            <>
                <Row>
                    <Col>
                        <Button className="add-action-button" color="neutral" onClick={() => navigateTo(LICENCE_APP_CONFIG_CREATE_PAGE)}>
                            {t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_NAVIGATION_BUTTON_ADDNEWSERVICEAPP)}
                        </Button>
                    </Col>
                </Row>
                <div className="smart-list">
                    <DataTable
                        pageSize="5"
                        minFilterChars='2'
                        data={data}
                        noResultsMessage={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_MESSAGE_PLACEHOLDER_NOSERVICECONFIG)}
                        components={getComponents()}
                        isCustomFilter={true}
                        columns={
                            {
                                RunningNumber: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_NUMBER), width: '5%' },
                                LicenceName: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_SERVICENAME), width: '20%', DBkey: 'LicenceName', setContent: setLicenceName, getTranslatedString: getLicenceNameTranslatedString },
                                ApplicationType: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_APPLICATIONTYPE), width: '20%', DBkey: 'ApplicationType', setContent: setAppTypeTranslatedContent, getTranslatedString: getAppTypeTranslatedString  },
                                EffectiveFrom: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_EFFECTIVEFROM), width: '20%', DBkey: 'EffectiveFrom', isDate: true, setContent: setEffectiveFromDate },
                                EffectiveTo: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_EFFECTIVETO), width: '20%', DBkey: 'EffectiveTo', isDate: true, setContent: setEffectiveToDate },
                                Status: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_STATUS), width: '10%', DBkey: 'Status', setContent: setStatusTranslatedContent, getTraslatedString: getStatusTranslatedString },
                                Actions: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_ACTION), width: '5%' }
                            }
                        }
                        renderActions={({ RowData }) => (
                            <>
                                <ActionButton
                                    tooltip={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_VIEW)}
                                    color="forward"
                                    onClick={() => navigateTo(LICENCE_APP_CONFIG_VERSION_PAGE, { LicenceAppConfig: RowData })}
                                >
                                    <FontAwesomeIcon icon={faEye} />
                                </ActionButton>
                            </>
                        )}
                        renderMobileCardActions={renderMobileCardActions}
                    />
                </div>
            </>
        );
    };

    return (
        <>
            <Title className="first body-title body-title-text" titleValue={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_TITLE_SERVICEAPPCONFIGURATIONMANAGEMENT)} />
            <div className="grid-container">
                <Row>
                    <Col>
                        {renderLayout()}
                    </Col>
                </Row>
            </div>
        </>
    );
}
