import React from 'react';
import Graph from 'react-graph-vis';
import { withTranslation } from 'react-i18next';
import { Button, Label, Nav, NavItem, NavLink, TabContent } from 'reactstrap';
import * as Yup from 'yup';
import { ADD_LICENCE_RULE_URL, GET_LICENCE_GRAPH_URL, IS_DEMO, REMOVE_LICENCE_RULE_URL } from '../../App/AppSettings';
import { fetchRequest, getParams, isValidForm, postParams } from '../../Shared/Actions';
import { ERROR, LANGUAGE_KEYS, SUCCESS } from '../../Shared/Constants/LanguageKeys';
import { SelectList, SMARTForm, toastError, toastSuccess } from '../../Shared/Forms';
import ModalConfirm from '../../Shared/Modal/ModalConfirm';
import { withModalWrapper } from '../../Shared/Modal/ModalWrapper';
import { getLangKey, getLicenceTypeNameTranslationKey } from '../DisplayComponents/DisplayUtils';
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const REMOVE_MODAL = {
    hasModal: true,
    name: 'Remove',
    modalHeader: 'Remove Licence Relation',
    modalContent: 'Are you sure you want to remove this rule?'
};

const FORM_VALIDATIONS = Yup.object().shape({
    From: Yup.string().required('Source is required.'),
    To: Yup.string().required('Target is required.'),
    RuleType: Yup.string().required('Rule type is required.')
});

const FORM_INITIAL_VALUES = {
    From: '',
    To: '',
    RuleType: '',
    Filter: ''
};

//Rule type
const LICENCE_RULE_TYPE = [];

const DEFAULT_ACTIVE_TAB = 'RuleGraph';

class NetworkGraph extends React.Component {
    constructor(props) {
        super(props);
        const { t } = this.props;
        const tabs = {
            RULEGRAPH: 'RuleGraph',
            ARM: 'ARM'
        };
        if(LICENCE_RULE_TYPE.length === 0) {
            LICENCE_RULE_TYPE.push(
                { label: t(getLangKey(LANGUAGE_KEYS.BLS_AI_LICENCERECOMMENDER_RULETYPE, 'InclusionOptional')), value: 'Inclusion Optional' },
                { label: t(getLangKey(LANGUAGE_KEYS.BLS_AI_LICENCERECOMMENDER_RULETYPE, 'Exclusion')), value: 'Exclusion' }
            );
        }

        this.state = {
            graph: undefined,
            tabs: tabs,
            activeTab: DEFAULT_ACTIVE_TAB,
            graphData: undefined,
            rulesGraph: undefined,
            armGraph: undefined,
            showAddButton: true,
            nodesListOptions: [],
            network: null,
            options: {
                layout: {
                    hierarchical: false, //set note movable
                    improvedLayout: true
                },
                nodes: {
                    fixed: {
                        x: false,
                        y: false
                    },
                    shape: 'dot'
                },
                edges: {
                    color: '#000000', //set edge default color
                    arrows: {
                    },
                    smooth: {
                        enabled: true,
                        type: 'curvedCW',
                        forceDirection: 'vertical', //'horizontal', 'vertical', 'none'
                        roundness: 0.2
                    },
                    width: 1, //thickness of edge
                    length: 200
                },
                height: '800px', //set canvas size
                interaction: {
                    hover: true
                },
                manipulation: {
                    enabled: false
                }
            },
            modalState: undefined,
            nodeId: 0,
            edgeId: 0,
            ADD_MODAL: {
                hasModal: true,
                name: 'Add',
                modalHeader: t(LANGUAGE_KEYS.BLS_INTERNALLICENCERECOMMENDATION_CONTENT_MODAL_ADDSERVICERELATION),
                modalContent: 'Are you sure you want to add this rule?'
            },
        };
    }

    //Modal dialog handler for add/remove relation
    addButtonHandler = (modal, resetForm) => {
        modal.toggleModal(this.state.ADD_MODAL.name);
        resetForm();
    }
    removeButtonHandler = (ID, modal) => {
        this.setState({
            deleteId: ID,
            edgeId: ID
        });
        modal.toggleModal(REMOVE_MODAL.name);
    }

    //Map tooltip values
    addToolTipValues = (edges, nodes, isHardRule = false) => {
        const { t } = this.props;
        const ruleTypeLabel = t(LANGUAGE_KEYS.BLS_INTERNALLICENCERECOMMENDATION_CONTENT_MODAL_RULETYPE);
        const sourceLabel = t(LANGUAGE_KEYS.BLS_INTERNALLICENCERECOMMENDATION_CONTENT_MODAL_SOURCE);
        const targetLabel = t(LANGUAGE_KEYS.BLS_INTERNALLICENCERECOMMENDATION_CONTENT_MODAL_TARGET);
        edges.forEach((edge) => {
            const ruleType =  t(getLangKey(LANGUAGE_KEYS.BLS_AI_LICENCERECOMMENDER_RULETYPE, edge.ruleType.replace(/\s+/g, '')));
            let licenceNameFrom = edge.fromLicenceName;
            let licenceNameTo = edge.toLicenceName;

            let filtered = nodes.filter(l => l.id === edge.from);
            const licenceFrom = filtered.length > 0 ? filtered[0] : null;
            if (licenceFrom !== null) {
                const licenceTypeNameFromTranslationKey = getLicenceTypeNameTranslationKey(licenceFrom.label);
                licenceNameFrom = t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY, licenceTypeNameFromTranslationKey, licenceFrom.licenceId));
            }
            
            filtered = nodes.filter(l => l.id === edge.to);
            const licenceTo = filtered.length > 0 ? filtered[0] : null;
            if (licenceTo !== null) {
                const licenceTypeNameToTranslationKey = getLicenceTypeNameTranslationKey(licenceTo.label);
                licenceNameTo = t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY, licenceTypeNameToTranslationKey, licenceTo.licenceId));
            }
            //todo: add static lavel translation
            if (isHardRule) {
                edge.title = `<div><label for='licenceType' sm={2}>${ruleTypeLabel}:
                </label>${ruleType}</div>
                <div><label for='fromLicenceName' sm={2}>${sourceLabel}:
                </label>${licenceNameFrom}</div>
                <div><label for='toLicenceName' sm={2}>${targetLabel}:
                </label>${licenceNameTo}</div>`;
            }         
            else {
                edge.title = `<div><label for='licenceType' sm={2}>${ruleTypeLabel}:
                </label>${ruleType}</div>
                <div><label for='fromLicenceName' sm={2}>${sourceLabel}:
                </label>${licenceNameFrom}</div>
                <div><label for='toLicenceName' sm={2}>${targetLabel}:
                </label>${licenceNameTo}</div>
                <div><label for='lift' sm={2}>Lift:
                </label>${edge.relationWeight}</div>`;
            }
        });
    }

    componentDidMount() {
        this.getLicenceGraph();
    }

    async getLicenceGraph(values, activeTab) {
        const { loader, t } = this.props;

        if (values !== undefined) {
            values.RuleType = this.state.activeTab;
        }

        //set default active tab
        if (activeTab === undefined) {
            activeTab = DEFAULT_ACTIVE_TAB;
        }

        loader.start();
        const response = await fetchRequest(GET_LICENCE_GRAPH_URL, getParams(values));
        loader.done();
        const { success, body } = response;

        if (success) {
            const licenceNodes = body.rulesGraph.licenceNodes;
            const nodesValue = [];
            const originalNodeValue = [];

            //Only map the value first time when graph loaded
            if (this.state.nodesListOptions.length <= 0) {
                licenceNodes.forEach((node) => {
                    const licenceTypeNameTranslationKey = getLicenceTypeNameTranslationKey(node.label);
                    const licenceName = t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY, licenceTypeNameTranslationKey, node.licenceId));
                    nodesValue.push({ label: licenceName, value: node.licenceId });
                    originalNodeValue.push({ label: node.label, value: node.licenceId });
                });
            }

            //Add tooltip mapping
            this.addToolTipValues(body.rulesGraph.licenceEdges, body.rulesGraph.licenceNodes, true);
            this.addToolTipValues(body.armGraph.licenceEdges, body.rulesGraph.licenceNodes, false);

            body.rulesGraph.licenceNodes.forEach(licenceNode => {
                const licenceTypeNameTranslationKey = getLicenceTypeNameTranslationKey(licenceNode.label);
                const licenceName = t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY, licenceTypeNameTranslationKey, licenceNode.licenceId));
                licenceNode.label = licenceName;
            });

            body.armGraph.licenceNodes.forEach(armLicenceNode => {
                const licenceTypeNameTranslationKey = getLicenceTypeNameTranslationKey(armLicenceNode.label);
                const licenceName = t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY, licenceTypeNameTranslationKey, armLicenceNode.licenceId));
                armLicenceNode.label = licenceName;
            });

            body.rulesGraph.licenceEdges.forEach(licenceEdge => {
                const fromLicenceNameObj = originalNodeValue.find(nv => nv.label === licenceEdge.fromLicenceName);
                if (fromLicenceNameObj) {
                    const fromLicenceTypeNameTranslationKey = getLicenceTypeNameTranslationKey(licenceEdge.fromLicenceName);
                    const fromLicenceName = t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY, fromLicenceTypeNameTranslationKey, fromLicenceNameObj.value));
                    licenceEdge.fromLicenceName = fromLicenceName;
                }

                const toLicenceNameObj = originalNodeValue.find(nv => nv.label === licenceEdge.toLicenceName);
                if (toLicenceNameObj) {
                    const toLicenceTypeNameTranslationKey = getLicenceTypeNameTranslationKey(licenceEdge.toLicenceName);
                    const toLicenceName = t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY, toLicenceTypeNameTranslationKey, toLicenceNameObj.value));
                    licenceEdge.toLicenceName = toLicenceName;
                }
            });

            this.setState({
                nodesListOptions: nodesValue.length > 0 ? nodesValue : this.state.nodesListOptions,
                rulesGraph: { nodes: body.rulesGraph.licenceNodes, edges: body.rulesGraph.licenceEdges },
                armGraph: { nodes: body.armGraph.licenceNodes, edges: body.armGraph.licenceEdges },
                graphData: activeTab === DEFAULT_ACTIVE_TAB ?
                    { nodes: body.rulesGraph.licenceNodes, edges: body.rulesGraph.licenceEdges } :
                    { nodes: body.armGraph.licenceNodes, edges: body.armGraph.licenceEdges }
            });
        }
    }

    async removeLicenceRelation(Id) {
        const { t } = this.props;
        const response = await fetchRequest(REMOVE_LICENCE_RULE_URL + Id, postParams(), false);
        if (response.success) {
            const { IsSuccess } = response.body;
            if (IsSuccess) {
                this.getLicenceGraph();
                toastSuccess(t(SUCCESS.RECOMMEND_RULE_UPDATE_SUCCESS));
            }
            else {
                toastError(t(ERROR.RECOMMEND_RULE_NOT_UPDATED));
            }
        } else {
            toastError(t(ERROR.SERVER_UNREACHABLE));
        }
    }

    //Dropdown List
    selectValues = (name, values, errors, onChangeField) => {
        const { t } = this.props;
        const { nodesListOptions } = this.state;
        return (
            <>
                {nodesListOptions && nodesListOptions.length > 0 &&
                    <SelectList
                        name={name}
                        value={values[name]}
                        options={name === 'RuleType' ? LICENCE_RULE_TYPE : nodesListOptions}
                        onChangeField={onChangeField}
                        isMulti={false}
                        isClearable={true}
                        labelSize={12}
                        inputSize={12}
                        placeholder={t(LANGUAGE_KEYS.BLS_INTERNALLICENCERECOMMENDATION_CONTENT_MODAL_PLEASESELECT)}
                        error={errors[name]}
                    />
                }
            </>
        );
    };

    getValidationSchema = () => {
        return FORM_VALIDATIONS;
    };

    prepareToSubmit = async (modal, _submitForm, values) => {
        if (isValidForm(FORM_VALIDATIONS, values)) {
            modal.toggleModal(this.state.ADD_MODAL.name);
        }
        _submitForm();
    };

    submitCallback = ({ response }) => {
        const { t } = this.props;
        if (response.success) {
            const { IsSuccess, Messages } = response.body;
            if (IsSuccess) {
                this.getLicenceGraph();
                toastSuccess(t(SUCCESS.RECOMMEND_RULE_UPDATE_SUCCESS));
            }
            else {
                toastError(t(getLangKey(ERROR.BACKEND_ERROR_MESSAGE, Messages)), Messages);
            }
        } else {
            toastError(t(ERROR.SERVER_UNREACHABLE));
        }
    };

    render() {

        const { rulesGraph, armGraph, showAddButton, graphData, options, activeTab, tabs } = this.state;
        const { modal, t } = this.props;

        const setActiveTab = (tab) => {
            this.setState({
                activeTab: tab,
                graphData: tab === 'RuleGraph' ? rulesGraph : armGraph,
                showAddButton: tab === 'RuleGraph' ? true : false
            });
        };

        const toggleTab = (tab, values) => {
            if (activeTab !== tab) {
                setActiveTab(tab);
            }
            values.Filter = '';
            this.getLicenceGraph(values, tab);
        };

        return (
            <>
                <SMARTForm
                    formContext={'FORM_CONTEXT'}
                    formValues={FORM_INITIAL_VALUES}
                    validationSchema={this.getValidationSchema()}
                    serverURL={ADD_LICENCE_RULE_URL}
                    retrieveURL={'RETRIEVE_URL'}
                    isDummy={IS_DEMO}
                    submitCallback={this.submitCallback}
                    validateOnSubmit={true}
                    defaultSection={'SECTION_NAMES.GENERAL_INFORMATION'}
                >
                    {({ values, errors, submitForm, onChangeField, resetForm }) => {
                        return <React.Fragment>
                            {
                                this.state.rulesGraph &&
                                <ModalConfirm
                                    isOpen={(modal.modalState === this.state.ADD_MODAL.name)}
                                    contentHeader={this.state.ADD_MODAL.modalHeader}
                                    confirmText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCERECOMMENDATION_NAVIGATION_BUTTON_CONFIRM)}
                                    confirmCallback={() => {
                                        this.prepareToSubmit(modal, submitForm, values, errors);
                                    }}
                                    cancelText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCERECOMMENDATION_NAVIGATION_BUTTON_CANCEL)}
                                    cancelCallback={() => {
                                        modal.toggleModal(this.state.ADD_MODAL.name);
                                    }}
                                    contentBody={
                                        <div className='row form-group'>
                                            <div className='col-lg-12'>
                                                <Label className='required label-default col-form-label'>{t(LANGUAGE_KEYS.BLS_INTERNALLICENCERECOMMENDATION_CONTENT_MODAL_SOURCE)}</Label>
                                                {this.selectValues('From', values, errors, onChangeField)}
                                            </div>
                                            <div className='col-lg-12'>
                                                <Label className='required label-default col-form-label'>{t(LANGUAGE_KEYS.BLS_INTERNALLICENCERECOMMENDATION_CONTENT_MODAL_TARGET)}</Label>
                                                {this.selectValues('To', values, errors, onChangeField)}
                                            </div>
                                            <div className='col-lg-12'>
                                                <Label className='required label-default col-form-label'>{t(LANGUAGE_KEYS.BLS_INTERNALLICENCERECOMMENDATION_CONTENT_MODAL_RULETYPE)}</Label>
                                                {this.selectValues('RuleType', values, errors, onChangeField)}
                                            </div>
                                        </div>
                                    }
                                />
                            }
                            {
                                this.state.rulesGraph &&
                                <ModalConfirm
                                    isOpen={(modal.modalState === REMOVE_MODAL.name)}
                                    contentHeader={REMOVE_MODAL.modalHeader}
                                    confirmText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCERECOMMENDATION_NAVIGATION_BUTTON_CONFIRM)}
                                    confirmCallback={() => { modal.toggleModal(REMOVE_MODAL.name); this.removeLicenceRelation(this.state.edgeId) }}
                                    cancelText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCERECOMMENDATION_NAVIGATION_BUTTON_CANCEL)}
                                    cancelCallback={() => modal.toggleModal(REMOVE_MODAL.name)}
                                    contentBody={
                                        REMOVE_MODAL.modalContent
                                    }
                                />
                            }

                            <div className='dashboard graph-content'>
                                <Nav tabs className='main-tab-graph tab-nav-tabs'>
                                    <NavItem key={0} className='tab-nav-item'>
                                        <NavLink
                                            className={'tab-header tab-nav-link'}
                                            active={(activeTab === tabs['RULEGRAPH'])}
                                            id='RuleGraph'
                                            onClick={() => toggleTab(tabs['RULEGRAPH'], values)}
                                        >
                                            <span><i className='fas fa-book'></i></span>
                                            {t(LANGUAGE_KEYS.BLS_INTERNALLICENCERECOMMENDATION_NAVIGATION_MENU_DEFINEDRULES)}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem key={1} className='tab-nav-item'>
                                        <NavLink
                                            className={'tab-header tab-nav-link'}
                                            active={(activeTab === tabs['ARM'])}
                                            id='ARM'
                                            onClick={() => toggleTab(tabs['ARM'], values)}
                                        >
                                            <span><i className='fas fa-list-alt'></i></span>
                                            {t(LANGUAGE_KEYS.BLS_INTERNALLICENCERECOMMENDATION_NAVIGATION_MENU_AIASSOCIATIONRULEMODEL)}
                                        </NavLink>
                                    </NavItem>
                                </Nav>

                                <TabContent activeTab={activeTab}>
                                    <div className={'dashboard-serach'} id='licence-search'>
                                        <div className='search'>
                                            <div className='search-box select-search-box'>
                                                {showAddButton &&
                                                    <div className='search-cell'>
                                                        <Button id='add-button' onClick={() => this.addButtonHandler(modal, resetForm)}>
                                                            <i className='fas fa-plus-circle'></i> {t(LANGUAGE_KEYS.BLS_INTERNALLICENCERECOMMENDATION_CONTENT_BUTTON_ADDCONNECTION)}
                                                        </Button>
                                                    </div>
                                                }
                                                <div className='search-cell'>
                                                    <div className='input-group'>
                                                        {this.selectValues('Filter', values, errors, onChangeField)}
                                                        <div className='input-group-append'>
                                                            <Button className='btn btn-spacer' type='button' onClick={() => { this.getLicenceGraph(values, activeTab) }}>
                                                                <FontAwesomeIcon icon={faSearch} />
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id='graph'>
                                        {graphData &&
                                            <Graph
                                                graph={graphData}
                                                options={options}
                                                events={
                                                    {
                                                        selectEdge: (event) => {
                                                            const { edges } = event;
                                                            this.removeButtonHandler(edges[0], modal);
                                                        }
                                                    }
                                                }
                                            />
                                        }
                                    </div>
                                </TabContent>
                            </div>
                        </React.Fragment>;
                    }}
                </SMARTForm>
            </>
        );
    }
}

export default withTranslation()(withModalWrapper(NetworkGraph));