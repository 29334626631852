import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Label, Row } from 'reactstrap';
import * as Yup from 'yup';
import { LICENCE_APP_CONFIG_GET_SUPPORTING_DOC_NAME } from '../../../App/AppSettings';
import { getParams, isValidForm } from '../../../Shared/Actions';
import { FIELD_SIZE } from '../../../Shared/Constants';
import { ActionButton, DataTable } from '../../../Shared/DataTable';
import { getCustomComponents } from '../../../Shared/DataTable/BaseTable.js';
import { useAsyncFetch } from '../../../Shared/Fetch';
import { CreatableSelectList, Paragraph, Radio, } from '../../../Shared/Forms';
import { ModalConfirm } from '../../../Shared/Modal';
import { getLangKey } from '../../DisplayComponents/DisplayUtils';
import { LANGUAGE_KEYS, ERROR } from '../../../Shared/Constants/LanguageKeys';

const SUPPORTING_DOC_VALUES = {
    GlobalDocumentId: '',
    IsExistingSupportingDocument: 'true',
    SupportingDocumentName: '',
    SupportingDocumentDescription: '',
    IsMandatorySupportingDocument: '',
    GlobalDocumentGuid: ''
};

const DUPLICATE_DOC_ERROR_MSG = 'There is already a selected document with the same name. Please select a document with a different name.';

export default function LACSupportingDocument(props) {
    const { t } = useTranslation();
    const supportingDocValidation = Yup.object().shape({
        IsExistingSupportingDocument: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED)),
        SupportingDocumentName: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED)),
        SupportingDocumentDescription: Yup.string().when('IsExistingSupportingDocument', {
            is: 'false',
            then: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED))
        }),
        IsMandatorySupportingDocument: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED))
    });
    const { values } = props;
    const [modalState, setModalState] = useState(null);
    const [supportingDocValues, setSupportingDocValues] = useState(SUPPORTING_DOC_VALUES);
    const [supportingDocumentData, setSupportingDocumentData] = useState([]);
    const [supportingDocumentList, setSupportingDocumentList] = useState([]);
    const [globalSupportingDocumentList, setGlobalSupportingDocumentList] = useState([]);
    const [duplicateDocumentNameError, setDuplicateDocumentNameError] = useState(false);
    const [selectedSupportingDocError, setSelectedSupportingDocError] = useState(false);
    const [docCount, setDocCount] = useState(values.ApplicationData.SupportingDocumentData.length);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const ADD_SUPPORTING_DOCUMENT_MODAL = {
        hasModal: true,
        name: 'AddSupportingDocument',
        modalHeader: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_MODAL_ADDSUPPORTINGDOCUMENT)
    };
    const EDIT_SUPPORTING_DOCUMENT_MODAL = {
        hasModal: true,
        name: 'EditSupportingDocument',
        modalHeader: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_MODAL_EDITSUPPORTINGDOCUMENT)
    };
    const DELETE_MODAL = {
        hasModal: true,
        name: 'Delete',
        modalHeader: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_MODAL_DELETE),
        modalContent: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_MESSAGE_MODAL_DELETERECORD)
    };
    //Set relevant data from props
    useEffect(() => {
        if (values.ApplicationData.SupportingDocumentData.length > 0) {
            setSupportingDocumentData(values.ApplicationData.SupportingDocumentData);
        } else {
            setSupportingDocumentData([]);
        }
    }, [values.ApplicationData.SupportingDocumentData]);
    const supportingDocResponse = useAsyncFetch(LICENCE_APP_CONFIG_GET_SUPPORTING_DOC_NAME, getParams());
    useEffect(()=>{
        if (supportingDocResponse) {
            const fetchSupportingDocListResponse = async response => {
                const { Data, IsSuccess } = response.body;
                if (response.success && IsSuccess) {
                    const supportingDocs = [];
                    Data.forEach(element => {
                        supportingDocs.push({
                            label: t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_GLOBALSUPPORTINGDOC_DOCNAME_KEY, element.GlobalDocumentGuid)),
                            value: element.GlobalDocumentId, globalDocumentGuid: element.GlobalDocumentGuid
                        });
                    });
                    setSupportingDocumentList(supportingDocs);
                    setGlobalSupportingDocumentList(Data);
                }
            };
            fetchSupportingDocListResponse(supportingDocResponse);
        }
    },[supportingDocResponse, t]);
    const resetSupportingDocModal = () => {
        setSupportingDocValues(SUPPORTING_DOC_VALUES);
        setDuplicateDocumentNameError(false);
    };
    const displayRequiredMessage = label => (
        `${label} ${t(ERROR.REQUIRED)}`
    );
    const isDuplicateDocumentNameCheck = () => {
        const trimmedChosenDocName = supportingDocValues.SupportingDocumentName.trim().toLowerCase();
        for (const doc of supportingDocumentData) {
            if (doc.SupportingDocumentName.toLowerCase() === trimmedChosenDocName) {
                return true;
            }
        }
        return false;
    };
    const getSupportingDocOptions = () => {
        let updatedSuppDocOptions;
        if (modalState === EDIT_SUPPORTING_DOCUMENT_MODAL.name) {
            updatedSuppDocOptions = supportingDocumentList.filter(currDocOption => !supportingDocumentData.map(doc => doc.GlobalDocumentId).includes(currDocOption.value)
                || currDocOption.value === supportingDocValues.GlobalDocumentId);
        } else {
            updatedSuppDocOptions = supportingDocumentList.filter(currDocOption => !supportingDocumentData.map(doc => doc.GlobalDocumentId).includes(currDocOption.value));
        }
        return updatedSuppDocOptions;
    };
    const toggleModal = (modalName) => {
        if (modalName === ADD_SUPPORTING_DOCUMENT_MODAL.name || modalName === EDIT_SUPPORTING_DOCUMENT_MODAL.name) {
            setSelectedSupportingDocError(false);
        }
        setModalState((modalState !== modalName) ? modalName : null);
    };
    const setDocValues = (name, value) => {
        setSupportingDocValues(prevValues => ({ ...prevValues, [name]: value }));
    };
    const setValues = (name, value, optionsArray) => {
        const id = optionsArray.findIndex(o => o.value === value);
        const label = optionsArray[id] ? optionsArray[id].label : null;
        if (name === 'SupportingDocumentName') {
            const globalDocumentGuid = optionsArray[id] ? optionsArray[id].globalDocumentGuid : null;
            setSupportingDocValues(prevValues => ({ ...prevValues, 'SupportingDocumentName': (label ? label : value), 'GlobalDocumentId': value, 'GlobalDocumentGuid': globalDocumentGuid }));
        }
    };
    const addSupportingDocument = () => {
        const isDuplicateDocumentName = isDuplicateDocumentNameCheck();
        setDuplicateDocumentNameError(isDuplicateDocumentName ? true : false);
        if (!isDuplicateDocumentName && isValidForm(supportingDocValidation, supportingDocValues)) {
            setSelectedSupportingDocError(false);
            const data = [...supportingDocumentData];
            data.push({ ...supportingDocValues, RowId: docCount });
            setDocCount(docCount + 1);
            setSupportingDocumentData(data);
            values.ApplicationData.SupportingDocumentData = data;
            toggleModal(ADD_SUPPORTING_DOCUMENT_MODAL.name);
        } else {
            setSelectedSupportingDocError(true);
        }
    };
    const editSupportingDoc = data => {
        setSupportingDocValues(data);
        const index = values.ApplicationData.SupportingDocumentData.findIndex(val =>
            val.RowId === data.RowId
        );
        setSelectedIndex(index);
        toggleModal(EDIT_SUPPORTING_DOCUMENT_MODAL.name);
    };
    const confirmEditSupportingDoc = () => {
        if (isValidForm(supportingDocValidation, supportingDocValues)) {
            setSelectedSupportingDocError(false);
            const data = [...supportingDocumentData];
            data[selectedIndex] = supportingDocValues;
            setSupportingDocumentData(data);
            values.ApplicationData.SupportingDocumentData = data;
            toggleModal(EDIT_SUPPORTING_DOCUMENT_MODAL.name);
            setSelectedIndex(null);
        } else {
            setSelectedSupportingDocError(true);
        }
    };
    const deleteItem = data => {
        toggleModal(DELETE_MODAL.name);
        const index = values.ApplicationData.SupportingDocumentData.findIndex(val =>
            val.RowId === data.RowId
        );
        setSelectedIndex(index);
    };
    const confirmDeleteItem = () => {
        const data = [...supportingDocumentData];
        data.splice(selectedIndex, 1);
        setSupportingDocumentData(data);
        values.ApplicationData.SupportingDocumentData = data;
        setSelectedIndex(null);
    };
    const setMandatorySuppDoc = rowData => (
        rowData.IsMandatorySupportingDocument === 'true' ? t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_YES) : t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_NO)
    );

    const getMandatorySuppDocTranslatedString = (rowData) => {
        return rowData.IsMandatorySupportingDocument === 'true' ? t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_YES) :
            t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_NO);
    };
    const onCreateSuppDocOption = (name, value) => {
        setValues(name, value, supportingDocumentList);
        const supportingDocs = [...supportingDocumentList];
        supportingDocs.push({ label: value, value });
        setSupportingDocumentList(supportingDocs);
    };
    const populateDocumentDescription = (name, value) => {
        const selectedSuppDoc = globalSupportingDocumentList.find(d => d.GlobalDocumentId === value);
        setDocValues('SupportingDocumentDescription', selectedSuppDoc ?
            t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_GLOBALSUPPORTINGDOC_DOCDESC_KEY, selectedSuppDoc.GlobalDocumentGuid)) :
            ''
        );
    };
    const renderSupportingDocModalContent = () => {
        const supportingDocOptions = getSupportingDocOptions();
        return (
            <div>
                <CreatableSelectList
                    isClearable={false}
                    name="SupportingDocumentName"
                    value={supportingDocValues.GlobalDocumentId}
                    minLength={0}
                    maxLength={140}
                    options={supportingDocOptions}
                    placeholder={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_PLACEHOLDER_SUPPORTINGDOCNAME)}
                    isMulti={false}
                    onChangeField={(name, valueArr) => {
                        setValues(name, valueArr, supportingDocumentList);
                        populateDocumentDescription(name, valueArr);
                    }}
                    onCreate={onCreateSuppDocOption}
                    inputSize={FIELD_SIZE.LARGE}
                    label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_SUPPORTINGDOCNAME)}
                    error={!supportingDocValues.SupportingDocumentName && selectedSupportingDocError ? displayRequiredMessage(t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_SUPPORTINGDOCNAME)) : ''}
                    required
                />
                <Paragraph
                    name="SupportingDocumentDescription"
                    value={supportingDocValues.SupportingDocumentDescription}
                    placeholder={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_PLACEHOLDER_DESCRIPTION)}
                    onChange={e => setDocValues(e.target.name, e.target.value)}
                    minLength={0}
                    inputSize={FIELD_SIZE.LARGE}
                    label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_DESCRIPTION)}
                    required
                    readOnly={supportingDocValues.GlobalDocumentId > 0}
                    error={!supportingDocValues.SupportingDocumentDescription && selectedSupportingDocError ? displayRequiredMessage(t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_DESCRIPTION)) : ''}
                />
                <Radio
                    name="IsMandatorySupportingDocument"
                    value={supportingDocValues.IsMandatorySupportingDocument}
                    options={[
                        { label: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_YES), value: 'true' },
                        { label: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_NO), value: 'false' }]}
                    onChange={e => setDocValues(e.target.name, e.target.value)}
                    inputSize={7}
                    label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_ISMANDATORY)}
                    error={!supportingDocValues.IsMandatorySupportingDocument && selectedSupportingDocError ? displayRequiredMessage(t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_ISMANDATORY)) : ''}
                    required
                />
                {supportingDocValues.SupportingDocumentName && duplicateDocumentNameError &&
                    <Label className="label-error">
                        {DUPLICATE_DOC_ERROR_MSG}
                    </Label>
                }
            </div>
        );
    };
    const setSuppDocNameTranslatedString = (rowData) => {
        const translatedString = t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_GLOBALSUPPORTINGDOC_DOCNAME_KEY, rowData.GlobalDocumentGuid));
        return <>{rowData.GlobalDocumentGuid ? translatedString : rowData.SupportingDocumentName}</>
    };
    const setSuppDocDescTranslatedString = (rowData) => {
        const translatedString = t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_GLOBALSUPPORTINGDOC_DOCDESC_KEY, rowData.GlobalDocumentGuid));
        return <>{rowData.GlobalDocumentGuid ? translatedString : rowData.SupportingDocumentDescription}</>
    };
    const getSuppDocNameTranslatedString = (rowData) => {
        const translatedString = t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_GLOBALSUPPORTINGDOC_DOCNAME_KEY, rowData.GlobalDocumentGuid));
        return rowData.GlobalDocumentGuid ? translatedString : rowData.SupportingDocumentName;
    };
    const getSuppDocDescTranslatedString = (rowData) => {
        const translatedString = t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_GLOBALSUPPORTINGDOC_DOCDESC_KEY, rowData.GlobalDocumentGuid));
        return rowData.GlobalDocumentGuid ? translatedString : rowData.SupportingDocumentDescription;
    };

    return (
        <React.Fragment>
            <Row>
                <Col >
                    <Button
                        className="float-right"
                        color="neutral"
                        onClick={() => {
                            resetSupportingDocModal();
                            toggleModal(ADD_SUPPORTING_DOCUMENT_MODAL.name);
                        }}
                    >
                        {t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_ADDDOCUMENT)}
                    </Button>
                </Col>
            </Row>
            <div className="smart-list">
                <DataTable
                    pageSize="3"
                    minFilterChars='2'
                    data={supportingDocumentData}
                    noResultsMessage={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_MESSAGE_PLACEHOLDER_NOSUPPORTINGDOC)}
                    components={getCustomComponents()}
                    columns={
                        {
                            RunningNumber: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_NUMBER), width: '5%' },
                            SupportingDocumentName: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_SUPPORTINGDOCUMENT), width: '30%', DBkey: 'SupportingDocumentName', setContent: setSuppDocNameTranslatedString, getTranslatedString: getSuppDocNameTranslatedString },
                            SupportingDocumentDescription: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_DESCRIPTION), width: '25%', DBkey: 'SupportingDocumentDescription', setContent: setSuppDocDescTranslatedString, getTranslatedString: getSuppDocDescTranslatedString },
                            IsMandatorySupportingDocument: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_MANDATORY), width: '10%', DBkey: 'IsMandatorySupportingDocument', setContent: setMandatorySuppDoc, getTranslatedString: getMandatorySuppDocTranslatedString },
                            Actions: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_ACTION), width: '10%' }
                        }
                    }
                    renderActions={({ RowData }) => (
                        <React.Fragment>
                            <ActionButton tooltip={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_EDIT)} color="forward" onClick={() => editSupportingDoc(RowData)}><FontAwesomeIcon icon={faEdit} /></ActionButton>
                            <ActionButton tooltip={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_DELETE)} color="hazard" onClick={() => deleteItem(RowData)}><FontAwesomeIcon fixedWidth icon={faTrash} /></ActionButton>
                        </React.Fragment>
                    )}
                />
                <ModalConfirm
                    isOpen={(modalState === DELETE_MODAL.name)}
                    contentHeader={DELETE_MODAL.modalHeader}
                    contentBody={DELETE_MODAL.modalContent}
                    confirmText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_CONFIRM)}
                    confirmCallback={() => {
                        toggleModal(DELETE_MODAL.name);
                        confirmDeleteItem();
                    }}
                    cancelText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_CANCEL)}
                    cancelCallback={() => toggleModal(DELETE_MODAL.name)}
                />
                <ModalConfirm
                    isOpen={(modalState === ADD_SUPPORTING_DOCUMENT_MODAL.name)}
                    contentHeader={ADD_SUPPORTING_DOCUMENT_MODAL.modalHeader}
                    confirmText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_ADD)}
                    confirmCallback={() => {
                        addSupportingDocument();
                    }}
                    cancelText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_CANCEL)}
                    cancelCallback={() => toggleModal(ADD_SUPPORTING_DOCUMENT_MODAL.name)}
                    contentBody={renderSupportingDocModalContent()}
                />
                <ModalConfirm
                    isOpen={(modalState === EDIT_SUPPORTING_DOCUMENT_MODAL.name)}
                    contentHeader={EDIT_SUPPORTING_DOCUMENT_MODAL.modalHeader}
                    confirmText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_EDIT)}
                    confirmCallback={() => {
                        confirmEditSupportingDoc();
                    }}
                    cancelText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_CANCEL)}
                    cancelCallback={() => toggleModal(EDIT_SUPPORTING_DOCUMENT_MODAL.name)}
                    contentBody={renderSupportingDocModalContent()}
                />
            </div>
        </React.Fragment>
    );

};