import Oidc from 'oidc-client';
import { useContext, useEffect, useState } from 'react';
import { AUTHORITY_CONFIG } from '../../App/AppSettings.js';
import { Response } from '../Constants';
import Context from './context';

let mgr = new Oidc.UserManager(AUTHORITY_CONFIG.openid_client_configuration.authentication_settings);

export const METHOD_TYPE = {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    DELETE: 'DELETE'
}

// Const: Fetch Content Type
export const CONTENT_TYPE = {
    JSON: 'Json',
    FORM_DATA: 'FormData'
}

const getAuthToken = async () => {
    //Get JWT Token
    var user = await mgr.getUser();
    var token = "";

    if (user !== null) {
        token = user.access_token;
    }

    return token;
};

export function useAsyncFetch(baseUrl, params, ready, callback) {
    const [response, setResponse] = useState(undefined);
    const loaderCtx = useContext(Context);

    const isReady = (ready === undefined ? true : ready);

    useEffect(() => {
        async function asyncFetch() {

            // Set loader's global state
            loaderCtx.setCounter(1);

            let url = baseUrl;

            // Retrieve token
            const token = await getAuthToken();

            // Set authorization header
            const headers = { 'Authorization': 'Bearer ' + token };

            // Process Query String
            if (params) {
                if (params.queryString) {
					if (!url.endsWith("?")) {
						url += "?";
					}
                    
                    let queryString = params.queryString;

                    for (let i in queryString) {
                        if (queryString[i] !== "") {
                            url = url.concat(i + "=" + queryString[i] + "&");
                        }
                    }
                }

                if (params.method === METHOD_TYPE.GET) {
                    await fetch(url,
                        {
                            method: METHOD_TYPE.GET,
                            headers: headers
                        })
                        .then(response => response.json())
                        .then(result => setResponse(Response(result, true, 200))) // Return response when result is successful
                        .then(() => {
                            if (callback) {
                                callback();
                            }
                        })
                        .catch(error => setResponse(Response(error, false, 500))); // Process response when error is caught
                }
                else if (params.method === METHOD_TYPE.POST) {
                    // Append Content Type for JSON
                    if (params.contentType === CONTENT_TYPE.JSON) {
                        headers['Content-Type'] = 'application/json'
                    }

                    await fetch(url, {
                        method: METHOD_TYPE.POST,
                        headers: headers,
                        body: params.data
                    })
                        .then(response => response.json())
                        .then(result => setResponse(Response(result, true, 200))) // Return response when result is successful
                        .then(() => {
                            if (callback) {
                                callback();
                            }
                        })
                        .catch(error => setResponse(Response(error, false, 500))); // Process response when error is caught
                }
            }

            // Set loader's global state
            loaderCtx.setCounter(-1);
        };

        if (isReady) {
            asyncFetch();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [baseUrl, isReady]);

    return response;
}

//Form necessary params for GET usage
export const getParams = (queryString = {}) => {
    return {
        method: METHOD_TYPE.GET,
        queryString
    };
};

//Form necessary params for POST usage
export const postParams = (data = '', queryString = {}, contentType = CONTENT_TYPE.FORM_DATA) => {
    return {
        method: METHOD_TYPE.POST,
        data: (data === null || data === '') ? '' : data,
        contentType,
        queryString
    };
};
