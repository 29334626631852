import { faEdit, faTrash, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row, Modal, ModalHeader, ModalBody } from 'reactstrap';
import * as Yup from 'yup';
import {
    ADDITIONAL_APPLICATION_REQUIRED_ACTIONS_LIST,
    GET_FILE_BY_NAME_URL,
    LICENCE_APP_CONFIG_GET_DOCUMENT_PLACEHOLDER_LIST,
    ADDITIONAL_APPLICATION_WORKFLOW_SCHEME_NAME_LIST
} from '../../../App/AppSettings';
import { getParams, isValidForm, getFileRequest, fetchRequest } from '../../../Shared/Actions';
import { FIELD_SIZE } from '../../../Shared/Constants';
import { ActionButton, DataTable } from '../../../Shared/DataTable';
import { getCustomComponents } from '../../../Shared/DataTable/BaseTable.js';
import { AccordionContainer, FileUpload, SelectList, Text, Paragraph } from '../../../Shared/Forms';
import { ModalConfirm } from '../../../Shared/Modal';
import { useTranslation } from 'react-i18next';
import { LANGUAGE_KEYS, ERROR, DEFAULT_TEXT, DEFAULT_NAMESPACE } from '../../../Shared/Constants/LanguageKeys';
import { GET_APPCONFIG_URL } from '../../../App/AppSettings';

const LICENCE_DOC_VALUES = {
    WorkflowStateId: 0,
    WorkflowStateName: '',
    DocumentFile: '',
    DocumentName: '',
    RequiredActionId: '',
    RequiredActionName: '',
    FileName: '',
    FileKey: '',
    DocumentDescription: '', 
    DocumentFileName: '',
}

const MAX_LENGTH_DOC_DESC = 140;
const MAX_LENGTH_DOC_NAME = 50;
const RETRIEVE_APPCONFIG_URL = GET_APPCONFIG_URL;
const MAX_FILESIZE_CONFIG_CAT = 'LicenceDocument';
const MAX_FILESIZE_CONFIG_NAME = 'MaxFileSize';


export default function LicenceAppConfigDocument(props) {
    const { t } = useTranslation();
    const { values, toggleSection } = props.smartFormValues;
    const [associatedWorkflowStateList, setAssociatedWorkflowStateList] = useState([]);
    const [requiredActionsList, setRequiredActionsList] = useState([]);
    const [modalState, setModalState] = useState(null);
    const [openInfoBox, setOpenInfoBox] = useState(false);
    const [selectedDocValues, setSelectedDocValues] = useState(LICENCE_DOC_VALUES);
    const [licenceDocumentData, setLicenceDocumentData] = useState([]);
    const [documentNameError, setDocumentNameError] = useState('');
    const [documentPlaceHolderData, setDocumentPlaceHolderData] = useState([]);
    const [licenceCount, setLicenceCount] = useState(values.LicenceDocumentData.length);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [fileIndex, setFileIndex] = useState(-1);
    const [licenceDocError, setLicenceDocError] = useState(false);
    const [documentDescriptionError, setDocumentDescriptionError] = useState('');
    const [maxFileSize, setMaxFileSize] = useState('');

    const VALIDATION = Yup.object().shape({
        DocumentFile: Yup.string().required(t(ERROR.FILE_ITEM_REQUIRED)),
        WorkflowStateId: Yup.string().required(t(ERROR.FILE_ITEM_REQUIRED)),
        WorkflowStateName: Yup.string().required(t(ERROR.FILE_ITEM_REQUIRED)),
        DocumentName: Yup.string().trim().max(MAX_LENGTH_DOC_NAME)
            .required(t(ERROR.FILE_ITEM_REQUIRED)),
        RequiredActionName: Yup.string().required(t(ERROR.FILE_ITEM_REQUIRED)),
        DocumentDescription: Yup.string().max(MAX_LENGTH_DOC_DESC)
    })

    const SECTION_NAMES = {
        LICENCE_DOCUMENT: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_SECTION_SERVICEDOCUMENT), status: true }
    }

    const ADD_DOCUMENT_MODAL = {
        hasModal: true,
        name: 'AddDocument',
        modalHeader: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_MODAL_ADDSERVICEDOCUMENT)
    };

    const EDIT_DOCUMENT_MODAL = {
        hasModal: true,
        name: 'EditDocument',
        modalHeader: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_MODAL_EDITSERVICEDOCUMENT)
    };

    const DELETE_MODAL = {
        hasModal: true,
        name: 'Delete',
        modalHeader: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_MODAL_DELETE),
        modalContent: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_MESSAGE_MODAL_DELETERECORD)
    }

    //Set relevant data from props
    useEffect(() => {
        setLicenceDocumentData(values.LicenceDocumentData);
    }, [values.LicenceDocumentData]);

    //Fetch select list options
    useEffect(() => {
        if (values.ApplicationData.InternalWorkflowScheme) {
            // Retrieve Workflow State Id and Name by using Scheme Code
            const fetchDocumentWorkflowResponse = async schemeCode => {
                const response = await fetchRequest(`${ADDITIONAL_APPLICATION_WORKFLOW_SCHEME_NAME_LIST}schemeCode=${schemeCode}`, getParams(), false);
                const { Data, IsSuccess } = response.body;
                if (response.success && IsSuccess) {
                    const documentWorkflowSchemeOptions = [];
                    Data.forEach(data => {
                        documentWorkflowSchemeOptions.push({ label: data.WorkflowStateName, value: data.WorkflowStateId });
                    });
                    documentWorkflowSchemeOptions.sort((a, b) => a.label.localeCompare(b.label));
                    setAssociatedWorkflowStateList(documentWorkflowSchemeOptions);
                }
            };

            // React-Hook that is use to call the above 2 methods
            fetchDocumentWorkflowResponse(values.ApplicationData.InternalWorkflowScheme);
        }
    },
        [values.ApplicationData.InternalWorkflowScheme, t]
    );

    //Fetch select list options
    useEffect(() => {
        const fetchDocumentPlaceholderResponse = async () => {
            const response = await fetchRequest(`${LICENCE_APP_CONFIG_GET_DOCUMENT_PLACEHOLDER_LIST}`, getParams(), false);
            const { Data, IsSuccess } = response.body;
            if (response.success && IsSuccess) {
                const documentPlaceHolderOptions = [];
                Data.forEach(data => {
                    documentPlaceHolderOptions.push({ PlaceholderValue: data.PlaceholderValue, PlaceholderDescription: data.PlaceholderDescription });
                });
                setDocumentPlaceHolderData(documentPlaceHolderOptions);
            }
        };

        // React-Hook that is use to call the above method
        fetchDocumentPlaceholderResponse();
    },
        []
    );

    //get max file size config
    useEffect(() => {
        const fetchMaxFileSizeConfig = async () =>{
            const response = await fetchRequest(RETRIEVE_APPCONFIG_URL, getParams({ Category: MAX_FILESIZE_CONFIG_CAT, Name: MAX_FILESIZE_CONFIG_NAME }), false);
            const { IsSuccess, Data, Messages } = response.body;
            if(response.success && IsSuccess){
                setMaxFileSize(Data.AppConfigValue);
            }
        }
        fetchMaxFileSizeConfig();
    }, [])

    const addDocument = () => {
        const { WorkflowStateId, WorkflowStateName, DocumentName, RequiredActionId, RequiredActionName, FileKey, FileName, DocumentDescription, DocumentFileName } = selectedDocValues;
        if (DocumentName.trim() === "") {
            setDocumentNameError(displayRequiredMessage(t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_DOCUMENTNAME)));
        } else if (DocumentName.length > MAX_LENGTH_DOC_NAME) {
            setDocumentNameError(displayErrorMessage(t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_DOCUMENTNAME), `${t(ERROR.MAX_LENGTH_LIMIT)} ${MAX_LENGTH_DOC_NAME}`));
        }
        if (DocumentDescription && DocumentDescription.length > MAX_LENGTH_DOC_DESC) {
            setDocumentDescriptionError(displayErrorMessage(t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_DOCUMENTDESCRIPTION), `${t(ERROR.MAX_LENGTH_LIMIT)} ${MAX_LENGTH_DOC_DESC}`));
        }
        if (isValidForm(VALIDATION, selectedDocValues)) {
            setLicenceDocError(false);
            const data = [...licenceDocumentData];
            if (data.some(e => e.DocumentName.toLowerCase() === DocumentName.toLowerCase())) {
                setDocumentNameError(t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_ERROR_EXISTEDDOCUMENTNAME));
            } else {
                data.push({
                    WorkflowStateId, WorkflowStateName, DocumentName, RequiredActionId, RequiredActionName,
                    FileName, FileKey, DocumentDescription, DocumentFileName, RowId: licenceCount
                });
                setLicenceCount(licenceCount + 1);
                setLicenceDocumentData(data);
                values.FileUploadSectionFiles.push(selectedDocValues.DocumentFile);
                values.LicenceDocumentData = data;
                setDocumentNameError('');
                setDocumentDescriptionError('');
                toggleModal(ADD_DOCUMENT_MODAL.name);
            }
        } else {
            setLicenceDocError(true);
        }
    };

    const editDocument = (data) => {
        populateRequiredActions(data.WorkflowStateId);
        //Document File object not populated into RowData from Datatable so need to fetch
        let fileIndex = values.FileUploadSectionFiles.findIndex(val => val.key === data.FileKey);
        if (fileIndex < 0) {
            fileIndex = values.FileUploadSectionFiles.findIndex(val => val.Filename === data.FileKey);
        }
        data.DocumentFile = values.FileUploadSectionFiles[fileIndex];
        setSelectedDocValues(data);
        const index = values.LicenceDocumentData.findIndex(val =>
            val.RowId === data.RowId
        );
        setFileIndex(fileIndex);
        setSelectedIndex(index);
        toggleModal(EDIT_DOCUMENT_MODAL.name);
    };

    const confirmEditDocument = () => {
        const { WorkflowStateId, WorkflowStateName, DocumentName, RequiredActionId, RequiredActionName, FileKey, FileName, DocumentFile, DocumentDescription, ContentType, ContentLength, FileUploadField, DocumentFileName } = selectedDocValues;
        if (DocumentName.trim() === "") {
            setDocumentNameError(displayRequiredMessage(t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_DOCUMENTNAME)));
        } else if (DocumentName.length > MAX_LENGTH_DOC_NAME) {
            setDocumentNameError(displayErrorMessage(t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_DOCUMENTNAME), `${t(ERROR.MAX_LENGTH_LIMIT)} ${MAX_LENGTH_DOC_NAME}`));
        }
        if (DocumentDescription && DocumentDescription.length > MAX_LENGTH_DOC_DESC) {
            setDocumentDescriptionError(displayErrorMessage(t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_DOCUMENTDESCRIPTION), `${t(ERROR.MAX_LENGTH_LIMIT)} ${MAX_LENGTH_DOC_DESC}`));
        }
        if (isValidForm(VALIDATION, selectedDocValues)) {
            setLicenceDocError(false);
            const data = [...licenceDocumentData];
            const existedDocumentIndex = data.findIndex(e => e.DocumentName.toLowerCase() === DocumentName.toLowerCase());
            if (existedDocumentIndex >= 0 && existedDocumentIndex !== selectedIndex) {
                setDocumentNameError(t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_ERROR_EXISTEDDOCUMENTNAME));
            } else {
                data[selectedIndex] = { WorkflowStateId, WorkflowStateName, DocumentName, RequiredActionId, RequiredActionName, FileName, FileKey, DocumentDescription, ContentType, ContentLength, FileUploadField, DocumentFileName };
                setLicenceDocumentData(data);
                values.LicenceDocumentData = data;
                values.FileUploadSectionFiles[fileIndex] = DocumentFile;

                values.LicenceDocumentData = data;
                setDocumentNameError('');
                setDocumentDescriptionError('');
                toggleModal(EDIT_DOCUMENT_MODAL.name);
                setSelectedIndex(null);
            }
        } else {
            setLicenceDocError(true);
        }
    };

    const deleteDocument = data => {
        toggleModal(DELETE_MODAL.name);
        const index = values.LicenceDocumentData.findIndex(val =>
            val.RowId === data.RowId
        );
        setSelectedIndex(index);
    };

    const confirmDeleteDocument = () => {
        const data = [...licenceDocumentData];
        data.splice(selectedIndex, 1);
        setLicenceDocumentData(data);
        values.LicenceDocumentData = data;
        setSelectedIndex(null);
    };

    const toggleModal = modalName => {
        setModalState((modalState !== modalName) ? modalName : null);
    };

    const viewFile = async (fileName) => {
        await getFileRequest(GET_FILE_BY_NAME_URL + '?fileName=' + fileName, false, fileName);
    };

    const populateRequiredActions = async workflowStateId => {
        if (workflowStateId > 0) {
            const requestURL = `${ADDITIONAL_APPLICATION_REQUIRED_ACTIONS_LIST}schemeCode=${values.ApplicationData.InternalWorkflowScheme}&workflowStateId=${workflowStateId}`;
            const response = await fetchRequest(requestURL, getParams(), false);
            const { Data, IsSuccess } = response.body;
            if (response.success && IsSuccess) {
                const documentRequiredActionsOptions = [];
                Data.forEach(element => {
                    documentRequiredActionsOptions.push({ label: element.RequiredActions, value: element.RequiredActions });
                });
                documentRequiredActionsOptions.sort((a, b) => a.label.localeCompare(b.label));
                setRequiredActionsList(documentRequiredActionsOptions);
            }
        }
    };

    const getRequiredActionsOptions = () => {
        let updatedRequiredActionsOptions;
        if (modalState === EDIT_DOCUMENT_MODAL.name) {
            updatedRequiredActionsOptions = requiredActionsList.filter(currDocOption =>
                !licenceDocumentData.map(doc => doc.WorkflowStateId).includes(currDocOption.value)
                || currDocOption.value === selectedDocValues.WorkflowStateId);
        } else {
            updatedRequiredActionsOptions = requiredActionsList.filter(currDocOption =>
                !licenceDocumentData.map(doc => doc.WorkflowStateId).includes(currDocOption.value));
        }
        return updatedRequiredActionsOptions;
    };

    const openDocumentModal = () => {
        setSelectedDocValues(LICENCE_DOC_VALUES);
        toggleModal(ADD_DOCUMENT_MODAL.name);
    };

    const displayRequiredMessage = label => (
        `${label} ${t(ERROR.REQUIRED)}`
    );

    const displayErrorMessage = (label, error) => (
        `${label} ${error}`
    )

    const onChangeFileUploadField = (name, file) => {
        // If file is selected
        if (file) {

            // Sieve out fileExtension
            const fileExtension = (/[.]/.exec(file.name)) ? /[^.]+$/.exec(file.name)[0] : undefined;
            if (!".doc,.docx".includes(fileExtension)) {
                alert(t(ERROR.FILE_FORMAT_ERROR));
                throw t(ERROR.FILE_FORMAT_ERROR);
            }
            else if (maxFileSize && file.size > parseFloat(maxFileSize) * 1000000) {
                alert(t(ERROR.FILE_SIZE_ERROR)  + ` (${maxFileSize} MB)`);
                throw t(ERROR.FILE_SIZE_ERROR);
            }
            else {
                setSelectedDocValues(prevValues => ({ ...prevValues, [name]: file, FileName: file.name, FileKey: file.key, DocumentFileName: file.name }));
            }
        } else {
            setSelectedDocValues(prevValues => ({ ...prevValues, [name]: undefined, FileName: '', FileKey: '', DocumentFileName: '' }));
        }
    };

    const setValues = (name, value, optionsArray) => {
        const id = optionsArray.findIndex(o => o.value === value);
        const label = optionsArray[id] ? optionsArray[id].label : null;

        if (name === 'WorkflowStateName') {
            setSelectedDocValues(prevValues => ({ ...prevValues, 'WorkflowStateId': value, 'WorkflowStateName': (label ? label : value) }));
        } else if (name === 'RequiredActionName') {
            setSelectedDocValues(prevValues => ({ ...prevValues, 'RequiredActionId': value, 'RequiredActionName': (label ? label : value) }));
        }
    };

    const setDocumentFieldsValues = (name, value) => {
        setSelectedDocValues(prevValues => ({ ...prevValues, [name]: value }));
        if (name === 'DocumentName' && documentNameError !== '') {
            const data = [...licenceDocumentData];
            if (data.some(e => e.DocumentName === value)) {
                setDocumentNameError(t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_ERROR_EXISTEDDOCUMENTNAME));
            } else {
                setDocumentNameError('');
            }
        }
    };

    const renderBody = () => {
        const requiredActionOptions = getRequiredActionsOptions();
        const getDocument = selectedDocValues.DocumentFile ? selectedDocValues.DocumentFile : selectedDocValues.FileName;
        return (
            <>
                <SelectList
                    name="WorkflowStateName"
                    value={selectedDocValues.WorkflowStateId}
                    options={associatedWorkflowStateList}
                    onChangeField={(name, value) => {
                        setValues(name, value, associatedWorkflowStateList);
                        populateRequiredActions(value);
                    }}
                    placeholder={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_PLACEHOLDER_ASSOCIATEDWORKFLOWSTATE)}
                    isMulti={false}
                    labelSize={FIELD_SIZE.SMALL}
                    inputSize={FIELD_SIZE.LARGE}
                    label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_ASSOCIATEDWORKFLOWSTATE)}
                    error={!selectedDocValues.WorkflowStateId && licenceDocError ? displayRequiredMessage(t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_ASSOCIATEDWORKFLOWSTATE)) : ''}
                    required
                />
                <Text
                    name="DocumentName"
                    value={selectedDocValues.DocumentName}
                    onChange={e => setDocumentFieldsValues(e.target.name, e.target.value)}
                    minLength={0}
                    inputSize={FIELD_SIZE.LARGE}
                    label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_DOCUMENTNAME)}
                    error={!selectedDocValues.DocumentName && licenceDocError ? displayRequiredMessage(t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_DOCUMENTNAME)) : documentNameError}
                    required
                />
                <SelectList
                    name="RequiredActionName"
                    value={selectedDocValues.RequiredActionName}
                    options={requiredActionOptions}
                    placeholder={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_PLACEHOLDER_REQUIREDACTIONS)}
                    isMulti={false}
                    onChangeField={(name, value) => {
                        setValues(name, value, requiredActionsList);
                    }}
                    inputSize={FIELD_SIZE.LARGE}
                    label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_REQUIREDACTIONS)}
                    error={
                        (!selectedDocValues.RequiredActionName && licenceDocError) ?
                            displayRequiredMessage(t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_REQUIREDACTIONS)) :
                            ''
                    }
                    required

                />
                <FileUpload
                    fileList={values.FileUploadSectionFiles}
                    labelSize={FIELD_SIZE.SMALL}
                    inputSize={FIELD_SIZE.LARGE}
                    name="DocumentFile"
                    label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_DOCUMENTFILE)}
                    accept=".doc,.docx"
                    onChangeField={(name, value) => onChangeFileUploadField(name, value)}
                    value={getDocument}
                    documentFileName={selectedDocValues.DocumentFileName}
                    multipleFilesWithSameName={true}
                    error={!selectedDocValues.DocumentFile && licenceDocError ? displayRequiredMessage(t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_DOCUMENTFILE)) : ''}
                    multiple={true}
                    getFileURL={GET_FILE_BY_NAME_URL}
                    contentInfo={<FontAwesomeIcon style={{ color: '#CC3333' }} icon={faInfoCircle} />}
                    onOpenContentInfo={() => setOpenInfoBox(true)}
                    required
                />
                <Paragraph
                    name='DocumentDescription'
                    value={selectedDocValues.DocumentDescription}
                    placeholder={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_PLACEHOLDER_DOCUMENTDESCRIPTION)}
                    onChange={e => setDocumentFieldsValues(e.target.name, e.target.value)}
                    rows={3}
                    minLength={0}
                    labelSize={FIELD_SIZE.SMALL}
                    label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_PLACEHOLDER_DOCUMENTDESCRIPTION)}
                    error={licenceDocError ? documentDescriptionError : ''}
                />
                <Modal isOpen={openInfoBox}>
                    <ModalHeader toggle={() => setOpenInfoBox(false)}></ModalHeader>
                    <ModalBody>
                        {t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_DOCUMENTHINT)}
                        <DataTable
                            pageSize="5"
                            data={documentPlaceHolderData}
                            noResultsMessage={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_MESSAGE_PLACEHOLDER_NODOCUMENTPLACEHOLDER)}
                            columns={
                                {
                                    Placeholder: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_PLACEHOLDERVALUE),  width: '50ch', DBkey: 'PlaceholderValue' },
                                    ValueToReplaceWith: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_PLACEHOLDERDESCRIPTION), DBkey: 'PlaceholderDescription' },
                                }
                            }
                        />
                    </ModalBody>
                </Modal>

            </>
        );
    };

    const renderDocumentGrid = () => {
        return (
            <React.Fragment>
                <Row>
                    <Col>
                        <Button className="float-right" color="neutral" onClick={() => { openDocumentModal() }}>{t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_ADDSERVICEDOCUMENT)}</Button>
                    </Col>
                </Row>
                <div className="smart-list">
                    <DataTable
                        pageSize="5"
                        minFilterChars='2'
                        data={licenceDocumentData}
                        noResultsMessage={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_MESSAGE_PLACEHOLDER_NOSERVICESELECTED)}
                        components={getCustomComponents()}
                        columns={
                            {
                                RunningNumber: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_NUMBER), width: '5%' },
                                WorkflowState: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_WORKFLOWSTATE), width: '20%', DBkey: 'WorkflowStateName' },
                                DocumentName: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_DOCUMENTNAME), width: '20%', DBkey: 'DocumentName' },
                                RequiredAction: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_REQUIREDACTIONS), width: '20%', DBkey: 'RequiredActionName' },
                                DocumentFile: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_UPLOADEDDOCUMENT), DBkey: 'DocumentFileName' },
                                Actions: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_ACTION), width: '10%' }
                            }
                        }
                        renderActions={({ RowData }) => (
                            <React.Fragment>
                                <ActionButton tooltip={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_EDIT)} color="forward" onClick={() => editDocument(RowData)}><FontAwesomeIcon icon={faEdit} /></ActionButton>
                                <ActionButton tooltip={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_DELETE)} color="hazard" onClick={() => deleteDocument(RowData)}><FontAwesomeIcon fixedWidth icon={faTrash} /></ActionButton>
                            </React.Fragment>
                        )}
                    />
                    <ModalConfirm
                        isOpen={(modalState === ADD_DOCUMENT_MODAL.name)}
                        contentHeader={ADD_DOCUMENT_MODAL.modalHeader}
                        confirmText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_ADD)}
                        confirmCallback={() => addDocument()}
                        cancelText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_CANCEL)}
                        cancelCallback={() => toggleModal(ADD_DOCUMENT_MODAL.name)}
                        contentBody={renderBody()}
                    />
                    <ModalConfirm
                        isOpen={(modalState === EDIT_DOCUMENT_MODAL.name)}
                        contentHeader={EDIT_DOCUMENT_MODAL.modalHeader}
                        confirmText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_EDIT)}
                        confirmCallback={() => confirmEditDocument()}
                        cancelText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_CANCEL)}
                        cancelCallback={() => toggleModal(EDIT_DOCUMENT_MODAL.name)}
                        contentBody={renderBody()}
                    />
                    <ModalConfirm
                        isOpen={(modalState === DELETE_MODAL.name)}
                        contentHeader={DELETE_MODAL.modalHeader}
                        contentBody={DELETE_MODAL.modalContent}
                        confirmText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_CONFIRM)}
                        confirmCallback={() => {
                            toggleModal(DELETE_MODAL.name); confirmDeleteDocument();
                        }}
                        cancelText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_CANCEL)}
                        cancelCallback={() => toggleModal(DELETE_MODAL.name)}
                    />
                </div>
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            <Container className="full-width">
                <AccordionContainer
                    title={SECTION_NAMES.LICENCE_DOCUMENT.title}
                    active={SECTION_NAMES.LICENCE_DOCUMENT.status}
                    onClick={toggleSection}
                    isIndividual={true}
                    sections={SECTION_NAMES
                    }
                >
                    {renderDocumentGrid()}
                </AccordionContainer>
            </Container>
        </React.Fragment>
    );
}
