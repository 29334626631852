import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Bar, BarChart, CartesianGrid, Label, LabelList, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

class StackedBarChart extends PureComponent {

    render() {

        return (
            <ResponsiveContainer width='99.8%' aspect={16.0 / 9.0}>
                <BarChart
                    width={this.props.barChartWidth}
                    height={this.props.barChartHeight}
                    data={this.props.data.Data}
                    margin={this.props.barChartMargin}
                >
                    <CartesianGrid
                        x={this.props.cartesianGridX}
                        y={this.props.cartesianGridY}
                        vertical={this.props.cartesianGridVertical}
                        strokeDasharray={this.props.cartesianGridStrokeDashArray}
                    />
                    <XAxis dataKey={this.props.xAxisDataKey}>
                        <Label
                            fontSize={this.props.xAxisLabelFontSize}
                            value={this.props.xAxisLabelValue}
                            position={this.props.xAxisLabelPosition}
                            offset={this.props.xAxisLabelOffset}
                        />
                    </XAxis>
                    <YAxis
                        domain={this.props.yAxisDomain}
                        scale={this.props.yAxisScale}
                        axisLine={this.props.yAxisAxisLine}
                        label={this.props.yAxisLabel}
                    />
                    <Legend
                        iconType={this.props.legendIconType}
                        iconSize={this.props.legendIconSize}
                        verticalAlign={this.props.legendVerticalAlign}
                        height={this.props.legendHeight}
                        wrapperStyle={this.props.legendWrapperStyle}
                    />
                    <Tooltip offset={this.props.toolTipOffset} />
                    {this.props.data && this.props.data.Fieldkeys && this.props.data.Fieldkeys.map((fk, index) => {
                        return (<Bar
                            key={index}
                            name={fk.name}
                            maxBarSize={this.props.barMaxBarSize}
                            dataKey={fk.name}
                            stackId={this.props.barStackId}
                            fill={this.props.barFill[index % this.props.barFill.length]}
                        >
                            <LabelList
                                key={index}
                                dataKey={fk.key}
                                position={this.props.labelListPosition ? this.props.labelListPosition : 'center'}
                            />
                        </Bar>);
                    })};
                </BarChart>
            </ResponsiveContainer>
        );
    }
}

StackedBarChart.propTypes = {
    barChartWidth: PropTypes.number.isRequired,
    barChartHeight: PropTypes.number.isRequired
};

StackedBarChart.defaultProps = {
    barFill: ['#8884d8', '#82ca9d', '#F5796D', '#64C195', '#FCC777', '#5664AF', '#ADD68A',
        '#BD7CB4', '#F5A872', '#5C88C5', '#FBF583', '#7E6AAF', '#F68C72', '#56C4C3', '#00C49F',
        '#528DF7', '#FF8042', '#FFDA6B', '#b19cd9', 'lightgreen'],
    barChartWidth: 970,
    barChartHeight: 500,
    cartesianGridX: 55,
    cartesianGridY: 0,
    cartesianGridVertical: false,
    xAxisDataKey: 'XDataKey',
    xAxisLabelValue: 'XAxis',
    xAxisLabelPosition: 'bottom',
    yAxisDomain: [0, 'dataMax + 1'],
    yAxisScale: 'linear',
    yAxisLabel: {
        offset: 20,
        fontSize: 18,
        value: 'YAxis',
        angle: -90,
        position: 'insideLeft'
    },
    legendVerticalAlign: 'top',
    labelListPosition: 'center'
};

export default StackedBarChart;
