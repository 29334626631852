import React from 'react';
import { withRouter } from 'react-router-dom';
import { CODES_LIST, CODETABLE_ADD_URL } from '../../App/AppSettings';
import { withFormAlert, withSMARTWrapper } from '../../Shared/Forms';
import { withLoader } from '../../Shared/Loading';
import { DEFAULT_TEXT, DEFAULT_NAMESPACE } from '../../Shared/Constants/LanguageKeys.js';
import Form from './Form.js';

const SERVER_URL = CODETABLE_ADD_URL;
class Create extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false
        }
    }

    async componentDidMount() {
        this.setState({
            values: {
                CodeTableCategory: '',
                CodeTableTitle: '',
                CodeTableValue: '',
                CodeTableDescription: '',
                CodeTableStatus: true,
                CodeTableEffectiveStartDate: '',
                CodeTableEffectiveEndDate: '',
                CodeTableSequenceNumber: 0,
                CodeTableParentCategory: "",
                CodeTableParent: "",
            },
            isLoaded: true
        });
    }
    
    render() {
        return (
            <React.Fragment>
                {this.state.isLoaded ?
                    <Form
                        values={this.state.values}
                        methodName="POST"
                        server_url={SERVER_URL}
                        title={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_CODESTATIC_CONTENT_TITLE_NEWCODETABLEENTRY, DEFAULT_TEXT.NEW_CODE)}
                        contentHeader={"Create Code Table Confirmation"}
                        contentBody={"Confirm creation of Code Table?"}
                        prevState={this.state}
                        next_url={CODES_LIST}
                        submitBtnLabel={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_CODESTATIC_NAVIGATION_BUTTON_CREATE, DEFAULT_TEXT.CREATE)}
                    />
                    :
                    null}
            </React.Fragment>
        )
    }
}
export default withSMARTWrapper(withFormAlert(withLoader(withRouter(Create))));