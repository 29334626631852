import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import * as Yup from 'yup';
import { DATE_DISPLAY_FORMAT, LAYOUT_TYPE, ADD_PUBLIC_HOLIDAY_URL, PUBLIC_HOLIDAY_MANAGEMENT_URL } from '../../App/AppSettings';
import Layout from '../../Layout';
import { SUCCESS, ERROR } from '../../Localization';
import { navigateTo } from '../../Shared/Actions';
import { FIELD_SIZE } from '../../Shared/Constants';
import { withSMARTWrapper, DateInput, SMARTForm, Text } from '../../Shared/Forms';
import { DEFAULT_TEXT, DEFAULT_NAMESPACE } from '../../Shared/Constants/LanguageKeys.js';
import { toastError, toastSuccess } from '../../Shared/Forms/Toaster.js';
import PageFooter from '../../Shared/PageFooter';

const FORM_CONTEXT = "AddPublicHoliday";

const FORM_INITIAL_VALUES = {
    Date: '',
    Title: '',
    Remarks: ''
};

class PublicHolidayCreate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            values: {}
        }
    }

    // Fn: Get Yup form validations
    getFormValidations = () => {
        return Yup.object().shape({
            Date: Yup.string()
                .required(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_REQUIRED, ERROR.REQUIRED)),
            Title: Yup.string()
                .required(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_REQUIRED, ERROR.REQUIRED))
                .min(0, this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_MINLENGTH, ERROR.MIN_LENGTH_LIMIT))
                .max(140, this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_MAXLENGTH, ERROR.MAX_LENGTH_LIMIT)),
            Remarks: Yup.string()
                .min(0, this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_MINLENGTH, ERROR.MIN_LENGTH_LIMIT))
                .max(140, this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_MAXLENGTH, ERROR.MAX_LENGTH_LIMIT))
        });
    }

    submitCallback = ({ response }) => {
        if (response.success) {
            const { IsSuccess, RedirectURL, MessageLanguageKey, Message } = response.body;
            if (IsSuccess) {
                if (RedirectURL) {
                    navigateTo(RedirectURL, "");
                } else {
                    navigateTo(PUBLIC_HOLIDAY_MANAGEMENT_URL, "");
                }
                toastSuccess(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_PUBLICHOLIDAYSTATIC_SUCCESS_TITLE_CREATEPUBLICHOLIDAY, SUCCESS.CREATE_SUCCESS), DEFAULT_TEXT.PUBLIC_HOLIDAY);
            }
            else {
                toastError(this.props.getMessage(MessageLanguageKey, Message));
            }
        } 
        else {
            toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
        }
    }

    render() {
        return (
            <React.Fragment>
                <Layout type={LAYOUT_TYPE.FORM} title=
                    {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_PUBLICHOLIDAYSTATIC_CONTENT_TITLE_ADDPUBLICHOLIDAY, DEFAULT_TEXT.ADD_PUBLIC_HOLIDAY)}>
                    <SMARTForm
                        formContext={FORM_CONTEXT}
                        formValues={FORM_INITIAL_VALUES}
                        validationSchema={this.getFormValidations()}
                        validateOnSubmit={true}
                        serverURL={ADD_PUBLIC_HOLIDAY_URL}
                        isDummy={false}
                        submitCallback={this.submitCallback}
                        resetFormAfterSubmit={true}
                    >
                        {({ values, errors, onChange, onChangeField, submitPlainForm }) => (
                            <React.Fragment>
                                <Row className="body-content admin-content-wrapper">
                                    <Col>
                                        <DateInput
                                            name="Date"
                                            value={values.Date}
                                            placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_PUBLICHOLIDAYSTATIC_FORM_PLACEHOLDER_DATE, DEFAULT_TEXT.DATE)}
                                            onChangeField={onChangeField}
                                            time={false}
                                            date={true}
                                            min="1990/01/01"
                                            max="2119/12/31"
                                            format={DATE_DISPLAY_FORMAT.DATE}
                                            inputSize={FIELD_SIZE.SMALL}
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_PUBLICHOLIDAYSTATIC_FORM_TITLE_DATE, DEFAULT_TEXT.DATE)}
                                            error={errors.Date}
                                            required
                                        />
                                        <Text
                                            name="Title"
                                            value={values.Title}
                                            placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_PUBLICHOLIDAYSTATIC_FORM_PLACEHOLDER_NAME, DEFAULT_TEXT.NAME_OF_PH)}
                                            onChange={onChange}
                                            minLength={0}
                                            maxLength={140}
                                            inputSize={FIELD_SIZE.LARGE}
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_PUBLICHOLIDAYSTATIC_FORM_TITLE_PUBLICHOLIDAY, DEFAULT_TEXT.PUBLIC_HOLIDAY)}
                                            error={errors.Title}
                                            required
                                        />
                                        <Text
                                            name="Remarks"
                                            value={values.Remarks}
                                            placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_PUBLICHOLIDAYSTATIC_FORM_PLACEHOLDER_REMARKS, DEFAULT_TEXT.REMARKS)}
                                            onChange={onChange}
                                            minLength={0}
                                            maxLength={140}
                                            inputSize={FIELD_SIZE.LARGE}
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_PUBLICHOLIDAYSTATIC_FORM_TITLE_REMARKS, DEFAULT_TEXT.REMARKS)}
                                        />
                                    </Col>
                                </Row>
                                <PageFooter type={LAYOUT_TYPE.FORM}>
                                    <Button color="backward" size="sm" type="button" onClick={() => navigateTo(PUBLIC_HOLIDAY_MANAGEMENT_URL)}>
                                        {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_PUBLICHOLIDAYSTATIC_NAVIGATION_BUTTON_BACK, DEFAULT_TEXT.BACK)}
                                    </Button>
                                    <Button color="forward" size="sm" type="button" onClick={submitPlainForm}>
                                        {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_PUBLICHOLIDAYSTATIC_NAVIGATION_BUTTON_CREATE, DEFAULT_TEXT.CREATE)}
                                    </Button>
                                </PageFooter>
                            </React.Fragment>
                        )}
                    </SMARTForm>
                </Layout>
            </React.Fragment>
        );
    }
}

export default withSMARTWrapper(PublicHolidayCreate);

