import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import momentLocalizer from 'react-widgets-moment';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import { DATE_DISPLAY_FORMAT } from '../../App/AppSettings';
import { withBaseControl } from './BaseControl';


momentLocalizer();

class DateInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
        };
    }

    clearField = ({ onChangeField, name }) => {
        onChangeField(name, '');
    }

    formatDateToString = (dateFormat, e, onChangeField) => {
        const eMoment = moment(e).format(dateFormat);
        e = this.isValidDate(eMoment) ? eMoment.toString() : '';
        onChangeField(this.props.name, e);
    }

    onChangeDate = (e, onChangeField) => {

        if (this.props.time === true && this.props.date === true) {
            this.formatDateToString(DATE_DISPLAY_FORMAT.DATE_TIME, e, onChangeField);
        } else if (this.props.time === false && this.props.date === true) {
            this.formatDateToString(DATE_DISPLAY_FORMAT.DATE, e, onChangeField);
        } else if (this.props.time === true && this.props.date === false) {
            this.formatDateToString(DATE_DISPLAY_FORMAT.TIME, e, onChangeField);
        }
        this.setState({ isOpen: false });
    };

    isValidDate = eMoment => {
        if (eMoment === 'Invalid date') {
            return false;
        }
        return true;
    };

    render() {
        const timeEnabled = this.props.time;
        const dateEnabled = this.props.date;
        let checkTimeDate;

        if (timeEnabled) {
            checkTimeDate = 'time';
        }
        else if (dateEnabled) {
            checkTimeDate = 'date';
        }
        else {
            checkTimeDate = '';
        }


        if (this.props.date && this.props.time) {
            return (
                <div className={(this.props.date && this.props.time) ? "datepicker" : "datetimepicker"}>

                    <DateTimePicker
                        placeholder={this.props.placeholder}
                        name={this.props.name}
                        value={this.props.value ? moment(this.props.value, this.props.format).toDate() : null}
                        onChange={e => this.onChangeDate(e, this.props.onChangeField)}
                        time={this.props.time}
                        date={this.props.date}
                        dropUp={this.props.dropUp}
                        format={this.props.format}
                        min={new Date(this.props.min)}
                        max={new Date(this.props.max)}
                        inputProps={{ readOnly: this.props.disableKeyboardInput }}
                        disabled={this.props.disabled}
                        onToggle={this.props.onToggle}
                        onFocus={this.props.disabled ? () => { } :
                            () => {
                                this.setState({
                                    isOpen: true,
                                });
                            }}
                        onBlur={() => { this.setState({ isOpen: false }); }}
                    />
                    {
                        this.props.clearFieldEnabled && !this.props.disabled ?
                            ((this.props.value !== "") ?
                                <span className="clearField" onClick={() => this.clearField(this.props)}>
                                    <FontAwesomeIcon className="" icon={faTimes} />
                                </span>
                                : '')
                            : ''
                    }
                </div>
            );
        }

        else {
            return (
                <div className={(this.props.date && this.props.time) ? "datepicker" : "datetimepicker"}>

                    <DateTimePicker
                        placeholder={this.props.placeholder}
                        name={this.props.name}
                        value={this.props.value ? moment(this.props.value, this.props.format).toDate() : null}
                        onChange={e => this.onChangeDate(e, this.props.onChangeField)}
                        time={this.props.time}
                        date={this.props.date}
                        dropUp={this.props.dropUp}
                        open={this.state.isOpen ? checkTimeDate : false}
                        format={this.props.format}
                        min={new Date(this.props.min)}
                        max={new Date(this.props.max)}
                        inputProps={{ readOnly: this.props.disableKeyboardInput }}
                        disabled={this.props.disabled}
                        onToggle={this.props.onToggle}
                        onFocus={this.props.disabled ? () => { } :
                            () => {
                                this.setState({
                                    isOpen: true
                                });
                            }}
                        onBlur={() => { this.setState({ isOpen: false }); }}
                    />
                    {
                        this.props.clearFieldEnabled && !this.props.disabled ?
                            ((this.props.value !== "") ?
                                <span className="clearField" onClick={() => this.clearField(this.props)}>
                                    <FontAwesomeIcon className="" icon={faTimes} />
                                </span>
                                : '')
                            : ''
                    }
                </div>
            );
        }
    }
}

//PropTypes: For prop validation
DateInput.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    selected: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    placeholder: PropTypes.string,
    onChangeField: PropTypes.func.isRequired,
    clearFieldEnabled: PropTypes.bool,
    disableKeyboardInput: PropTypes.bool
};

// PropTypes: Defaulting value for optional props
DateInput.defaultProps = {
    min: '1900-01-01 00:00',
    max: '2100-12-31 00:00',
    clearFieldEnabled: true,
    disableKeyboardInput: true,
    disabled: false,
    onToggle: () => { }
};


export default withBaseControl(DateInput);


