import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import React from 'react';
import { Collapse } from 'reactstrap';

class AccordionContainer extends React.Component {

    // Fn: Render the styling of accordion title based on the state of accordion 
    TitleState = () => {
        return (this.props.active) ? 'form-title-active' : 'form-title';
    };

    // Fn: Render the icon of caret based on the state of accordion 
    Caret = () => {
        return (this.props.active) ? faCaretDown : faCaretRight;
    };

    render() {
        return (
            <div className={"form-content accordion-wrapper"}>
                <div className="panel-heading" onClick={() => this.props.onClick(true, this.props.isIndividual, this.props.title, this.props.sections)} >
                    <h2 className={this.TitleState()}>
                        {this.props.title}
                    </h2>
                </div>
                <Collapse className="collapse-content clearfix" isOpen={this.props.active}>
                    <div className="accordion-content container">
                        {this.props.children}
                    </div>
                </Collapse>
            </div>
        );
    }
}

// PropTypes: For prop validation
AccordionContainer.propTypes = {
    active: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    isIndividual: PropTypes.bool,
    sections: PropTypes.object
};

AccordionContainer.defaultProps = {
    isIndividual: false
};

export default AccordionContainer;