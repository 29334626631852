import PropTypes from 'prop-types';
import React from 'react';
import Creatable from 'react-select/creatable';
import { withBaseControl } from './BaseControl';

const createOption = (label) => ({
    label,
    value: label,
});

class CreatableSelectList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            options: this.props.options,
            value: this.props.value
        };
    }

    //update props.options so that options can be queried from the backend
    componentDidUpdate = (prevProps) => {
        if (this.props.options !== prevProps.options && prevProps.options.length === 0) {
            this.setState({ options: this.props.options })
        }
        if (this.props.value !== prevProps.value) {
            this.setState({ value: this.valueToOption(this.props.value) })
        }
        if (this.props.value !== null && this.props.options !== prevProps.options && prevProps.options.length === 0) {
            this.setState({ value: this.valueToOption(this.props.value) })
        }
    }
    // Fn: Handle changes in checkbox group 
    handleSelectChange = (event, { onChangeField, name }) => {
        let selections = event;
        if (selections !== null) {
            // Only takes in 'value' prop for each object
            let valueArr = selections.value;
            // Update changes in Formik handleChange event
            this.setState({ value: selections });
            onChangeField(name, valueArr);
        } else {
            this.setState({ value: '' });
            onChangeField(name, '');
        }

    };

    valueToOption = (value) => {
        if (value) {
            // this.setState({value: {'label':value, 'value': value}})
            return { 'label': value, 'value': value }
        }
    }

    matchObjectValue = (valueArray, key, option) => {
        if (key === option.value) {
            return valueArray.push(option)
        }
    }

    // Fn: Retrieve value and convert it to react-select readable label-value pair
    mapValuesToOptions = ({ options, value }) => {
        // Only takes in 'value' prop for each object
        let valueArr = [];
        if (value !== "") {
            if (Array.isArray(value)) {
                value.forEach(element => {
                    options.forEach(option => {
                        if (element === option.value) {
                            valueArr.push(option);
                        }
                    });
                })
            }
            else if (typeof value === "object") {
                Object.keys(value).map(key =>
                    options.forEach(option => {
                        this.matchObjectValue(valueArr, value[key], option)
                    })
                );
            }
            else {
                let option = this.state.options.find(option => option.value === value);
                valueArr.push(option);
            }
            return valueArr;
        }
        return valueArr;
    };


    handleCreate = (inputValue) => {
        const { options } = this.state;
        const newOption = createOption(inputValue);
        this.setState({
            options: [...options, newOption],
            value: newOption,
        });
        this.props.onChangeField(this.props.name, newOption.value);
        if (this.props.onCreate) {
            this.props.onCreate(this.props.name, newOption.value);
        }
    };

    render() {

        return (
            <Creatable
                name={this.props.name}
                value={this.mapValuesToOptions(this.props)}
                // value={this.valueFunction(this.props)}
                placeholder={this.props.placeholder}
                onChange={(e) => this.handleSelectChange(e, this.props)}
                onCreateOption={this.handleCreate}
                options={this.state.options}
                isMulti={false}
                isDisabled={this.props.isDisabled}
                isClearable={this.props.isClearable}
                maxMenuHeight={150}
                menuPlacement={(this.props.menuPlacement ? this.props.menuPlacement : "auto")}
                // menuPortalTarget={document.body}
                styles={{
                    menuPortal: styles => ({ ...styles, zIndex: 100 }) //  >= dialog's z-index
                }}
                className={this.props.className + " creatable-select-list"} //  class for css
            />
        );
    }
}

// PropTypes: For prop validation
CreatableSelectList.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object, PropTypes.number]).isRequired,
    placeholder: PropTypes.string,
    onChangeField: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    isMulti: PropTypes.bool.isRequired
};


export default withBaseControl(CreatableSelectList);