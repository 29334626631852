import React from 'react';
import { withTranslation } from 'react-i18next';
import { getLangKey, LANGUAGE_KEYS } from '../../../Shared/Constants/LanguageKeys';
import { getLicenceTypeNameTranslationKey } from '../../DisplayComponents/DisplayUtils';

class PaymentSummary extends React.Component {
    render() {
        const { licenceFees, totalLicenceFee, hidePaymentOption, t } = this.props;
        return (
            <>
                <div className="heading-bg">{t(LANGUAGE_KEYS.BLS_INTERNALPAYMENTINBOX_CONTENT_TITLE_PAYMENTS)} </div>
                <div className="payments">
                    {!hidePaymentOption &&
                        <p className="verify">{t(LANGUAGE_KEYS.BLS_INTERNALPAYMENTINBOX_MESSAGE_PLACEHOLDER_PAYMENTTEXT)}</p>
                    }
                    {
                        hidePaymentOption &&
                        <p className="verify">{t(LANGUAGE_KEYS.BLS_INTERNALPAYMENTINBOX_MESSAGE_PLACEHOLDER_NOPAYMENTREQUIRED)}</p>
                    }
                    <div className="data-table">
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>{t(LANGUAGE_KEYS.BLS_INTERNALPAYMENTINBOX_TABLE_TITLE_SERVICES)}</th>
                                        <th>{t(LANGUAGE_KEYS.BLS_INTERNALPAYMENTINBOX_TABLE_TITLE_FEES)}</th>
                                        <th>{t(LANGUAGE_KEYS.BLS_INTERNALPAYMENTINBOX_TABLE_TITLE_TAX)}</th>
                                        <th>{t(LANGUAGE_KEYS.BLS_INTERNALPAYMENTINBOX_TABLE_TITLE_SUBTOTAL)}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {licenceFees.map((licenceFeeItem, i) => {
                                        const licenceTypeNameTranslationKey = getLicenceTypeNameTranslationKey(licenceFeeItem.LicenceName);

                                        return (
                                            <React.Fragment key={licenceFeeItem.LicenceName}>
                                                <tr>
                                                    <td colSpan="4">{t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY, licenceTypeNameTranslationKey, licenceFeeItem.LicenceTypeId))}</td>
                                                </tr>
                                                {licenceFeeItem.LicenceFeeTypeItems.map((feeType, i) => {
                                                    return (
                                                        <tr className="child-row" key={licenceFeeItem.LicenceName + i}>
                                                            <td><span>{t(getLangKey(LANGUAGE_KEYS.BLS_LICENCE_LICENCEAPPLICATIONFEE_APPLICATIONFEE_KEY, licenceTypeNameTranslationKey, feeType.FeeId))}</span></td>
                                                            <td>{t(LANGUAGE_KEYS.BLS_INTERNALPAYMENTINBOX_CONTENT_INFORMATION_CURRENCYSYMBOL)}{feeType.MainFee}</td>
                                                            <td>{t(LANGUAGE_KEYS.BLS_INTERNALPAYMENTINBOX_CONTENT_INFORMATION_CURRENCYSYMBOL)}{feeType.TaxFee}</td>
                                                            <td className="payment-fee">{t(LANGUAGE_KEYS.BLS_INTERNALPAYMENTINBOX_CONTENT_INFORMATION_CURRENCYSYMBOL)}{feeType.TotalFee}</td>
                                                        </tr>
                                                    );
                                                })}
                                            </React.Fragment>
                                        )
                                    })}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colSpan="4"><span>{t(LANGUAGE_KEYS.BLS_INTERNALPAYMENTINBOX_CONTENT_INFORMATION_GRANDTOTAL)} <strong>{t(LANGUAGE_KEYS.BLS_INTERNALPAYMENTINBOX_CONTENT_INFORMATION_CURRENCYSYMBOL)}{totalLicenceFee}</strong></span></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>

                </div>
            </>
        );
    }
}

export default withTranslation()(PaymentSummary);