import 'core-js/es6/object';
import 'core-js/es7/object'; // ES7 Object needed
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';
import { LAYOUT_TYPE } from '../App/AppSettings';
import { withAppWrapper } from '../App/AppWrapper';
import Header from '../Header';
import Navbar from '../Navbar';
import { navigateToSamePage } from '../Shared/Actions';
import { DirectionContextConsumer } from '../Shared/DirectionContext';
import Context from '../Shared/Fetch/context';
import Loader from '../Shared/Fetch/loader';
import { Toaster } from '../Shared/Forms';

export const withSMARTLayout = (LoadedComponent, showNavDisplay) => {
  class SMARTLayout extends React.Component {

    constructor(props) {
      super(props);
      this.state = {
        collapseNavbar: false,
        isCollapsing: false,
        navbarLayoutD: LAYOUT_TYPE.NAVBAR_DESKTOP,
        navbarLayoutM: LAYOUT_TYPE.NAVBAR_MOBILE,
        width: window.innerWidth,
        showNavDisplay: showNavDisplay === false ? false : true,
        counter: 0
      };
    }
    toggleSidebar = () => {
      if (!this.state.isCollapsing) {
        this.setState({ collapseNavbar: !this.state.collapseNavbar });
      }
    }
    toggleIsCollapsing = () => {
      this.setState({ isCollapsing: !this.state.isCollapsing });
    }

    //Mobile Responsive Listener
    componentWillMount() {
      window.addEventListener('resize', this.handleWindowSizeChange);
    }
    componentWillUnmount() {
      window.removeEventListener('resize', this.handleWindowSizeChange);
    }
    componentDidUpdate = (prevProps) => {
      if (prevProps.context.currLang && prevProps.context.currLang !== this.props.context.currLang) {
        navigateToSamePage();
      }
    }

    handleWindowSizeChange = () => {
      this.setState({ width: window.innerWidth });
    };


    //Layout Split 
    LayoutToggle = () => {
      const { width } = this.state;
      const isMobile = width <= 992;

      if (isMobile) {
        return this.state.navbarLayoutM + "M";
      } else {
        return this.state.navbarLayoutD + "D";
      }

    }

    NavbarVerticalChange = () => {
      if (this.LayoutToggle() === "1D" || this.LayoutToggle() === "1M") {
        return (<Header onClick={this.toggleSidebar} />)
      }
    }

    adjustNavDisplay = () => {
      return this.state.showNavDisplay ? "" : "no-navdisplay";
    }

    setPageNotFound = (isPageNotFound) => {
      const { setPageNotFound } = this.props;
      setPageNotFound(isPageNotFound);
    }

    setCounter = (value) => {
      this.setState((prevState) => {
        return {
          counter: prevState.counter + value
        }
      });
    }
    render() {
      const { Provider } = Context;
      const { i18n } = this.props;

      return (
        <Provider value={{ setCounter: this.setCounter }}>
          <Loader counter={this.state.counter} />
          <Container fluid className="app-container">
            <Row>
              <Navbar showNavDisplay={this.state.showNavDisplay} navbarLayout={this.LayoutToggle()} collapseNavbar={this.state.collapseNavbar} onClick={this.toggleSidebar} onUpdate={this.toggleIsCollapsing} />
              <Col className={`site-container ${this.adjustNavDisplay()}`}>
                {this.NavbarVerticalChange()}
                <LoadedComponent key={i18n.language} {...this.props} setPageNotFound={this.setPageNotFound} />
              </Col>
              <Toaster isStack={false} />
              {/* <ScrollToTop /> */}
            </Row>
          </Container>
        </Provider>
      )
    }
  }
  return withTranslation()(withDirectionContext(withAppWrapper(SMARTLayout)));
};

const withDirectionContext = WrappedComponent => {
  class DirectionOnPageContext extends React.Component {
    render() {
      return (
        <DirectionContextConsumer>
          {({ currLang, changeLanguage, supportedLangResponse }) => {
            return (
              <WrappedComponent
                context={{ currLang, changeLanguage, supportedLangResponse }}
                {...this.props}
              />);
          }}
        </DirectionContextConsumer>
      );
    }
  }
  return DirectionOnPageContext;
}