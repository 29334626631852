import { faChevronDown, faChevronUp, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, Col, Collapse, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import { DATE_DISPLAY_FORMAT, IS_DEMO, LAYOUT_TYPE, LICENCE_ENQUIRY_LIST_URL, LICENCE_ENQUIRY_PARAMS_URL, PATH, REGEX } from '../../App/AppSettings';
import Layout from '../../Layout';
import { fetchRequest, getParams, navigateTo } from '../../Shared/Actions';
import { ERROR, getLangKey, LANGUAGE_KEYS } from '../../Shared/Constants/LanguageKeys';
import { DataTable, MobileCardActionButton } from '../../Shared/DataTable';
import { getCustomComponents } from '../../Shared/DataTable/BaseTable.js';
import { DateInput, SelectList, SMARTForm, Text, toastError, withSMARTWrapper } from '../../Shared/Forms';
import { arrayToLicenceStatusSelectListOptions, arrayToSelectListOptions } from '../../Shared/Forms/FormUtils.js';
import { getLicenceTypeNameTranslationKey } from '../DisplayComponents/DisplayUtils';

const FORM_CONTEXT = 'LicenceEnquiry';

// Backend URL
const RETRIEVE_URL = LICENCE_ENQUIRY_LIST_URL;
const PARAMS_URL = LICENCE_ENQUIRY_PARAMS_URL;

const FORM_INITIAL_VALUES = {
    AgencyName: '',
    LicenceName: '',
    LicenceNumber: '',
    LicenceStatus: '',
    IssuedDate: '',
    ExpiryDate: ''
};

const LABEL_SIZE = 2;
const INPUT_SIZE = 5;

class LicenceEnquiry extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            values: FORM_INITIAL_VALUES,
            data: [],
            openEnquiryView: true,
            count: 0,
            modalState: undefined,
            agencyOptions: [],
            licenceNameOptions: [],
            licenceStatusOptions: []
        };
    }

    componentDidMount = async () => {
        const { loader, t } = this.props;
        // Start loading
        loader.start();

        const response = await fetchRequest(PARAMS_URL, getParams(), IS_DEMO);
        const { IsSuccess, Data, Messages } = response.body;

        if (IsSuccess) {
            const { LicenceAgency, LicenceStatus } = Data;
            const agencyOptions = [];
            const licenceNameOptions = [];
            const licenceStatusOptions = [];

            LicenceAgency.forEach(licenceA => {
                const licenceTypeNameTranslationKey = getLicenceTypeNameTranslationKey(licenceA.LicenceTypeName);
                const licenceName = t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY, licenceTypeNameTranslationKey, licenceA.LicenceTypeId));
                const agencyName = t(getLangKey(LANGUAGE_KEYS.BLS_LICENCEAGENCY_AGENCY_AGENCYNAME, licenceA.LicenceAgencyId));

                licenceNameOptions.push({ label: licenceName, value: licenceA.LicenceTypeName });
                agencyOptions.push({ label: agencyName, value: licenceA.LicenceAgencyName });
            });

            // Format filter params into SelectList format
            arrayToLicenceStatusSelectListOptions(LicenceStatus, licenceStatusOptions, t);

            this.setState({
                // actual select list that keeps applicable agencies
                agencyOptions,
                // actual select list that keeps applicable licences
                licenceNameOptions,
                licenceStatusOptions,
                // to keep a copy of all agencies
                allAgencyOptions: agencyOptions,
                // to keep a copy of all licences
                allLicenceOptions: licenceNameOptions,
                licenceAgency: LicenceAgency,
                values: {
                    ...this.state.values,
                    AgencyName: agencyOptions.length === 1 ? agencyOptions[0].value : ''
                }
            });
        }
        else {
            toastError(t(getLangKey(ERROR.BACKEND_ERROR_MESSAGE, Messages)), Messages);
        }

        // End loading
        loader.done();
    }

    // CLASS METHODS
    setContent = rowData => {
        return <Link to={
            {
                pathname: PATH.ENQUIRY.SERVICE_DETAILS,
                state: {
                    licence: rowData
                }
            }
        }>{rowData.LicenceNumber}</Link>;
    }

    onAgencyChangeField = (agencyName, agencyValue, onChangeField) => {
        // Set Agency field
        onChangeField(agencyName, agencyValue);

        if (agencyValue === '') {
            // Re-populate licence field with all licence options if clear agency field
            this.setState({
                licenceNameOptions: this.state.allLicenceOptions
            });
        }
        else {
            // Re-populate licence field with applicable licence options
            const newLicenceOptions = [];
            const newLicenceDropdownOptions = [];

            this.state.licenceAgency.forEach(function (item) {
                if (item.LicenceAgencyName === agencyValue) {
                    newLicenceOptions.push(item.LicenceTypeName);
                }
            });

            // Format filter params into SelectList format
            arrayToSelectListOptions(newLicenceOptions, newLicenceDropdownOptions);

            this.setState({
                licenceNameOptions: newLicenceDropdownOptions
            });
        }
    }

    onLicenceChangeField = (licenceName, licenceValue, onChangeField) => {
        // Set Licence field
        onChangeField(licenceName, licenceValue);

        if (licenceValue === '') {
            // Re-populate agency field with all agency options if clear licence field
            this.setState({
                agencyOptions: this.state.allAgencyOptions
            });
        }
        else {
            // Populate to selected licence's agency
            const newAgencyOptions = [];
            const newAgencyDropdownOptions = [];

            this.state.licenceAgency.forEach(function (item) {
                if (item.LicenceTypeName === licenceValue) {
                    newAgencyOptions.push(item.LicenceAgencyName);
                    return;
                }
            });

            // Format filter params into SelectList format
            arrayToSelectListOptions(newAgencyOptions, newAgencyDropdownOptions);

            // Set to the licence's agency
            if (newAgencyDropdownOptions.length === 1) {
                onChangeField('AgencyName', newAgencyOptions[0]);
            }

            this.setState({
                agencyOptions: newAgencyDropdownOptions
            });
        }
    }

    parseSearch = async ({ response }) => {
        const { loader, t } = this.props;

        // Start loading
        loader.start();
        if (response.success) {
            const { IsSuccess, Data, Messages } = response.body;

            //set state
            if (IsSuccess) {
                await this.setState({
                    success: IsSuccess,
                    data: Data,
                    count: ((Data !== undefined) ? Data.length : 0)
                });
                // End loading
                loader.done();
            }
            else {
                // Error message to be loaded here
                loader.error(t(getLangKey(ERROR.BACKEND_ERROR_MESSAGE, Messages)));
            }
        }

    }

    toggleEnquiryView = () => {
        this.setState({ openEnquiryView: !this.state.openEnquiryView });
    }

    renderMobileCardActions = data => {
        const { LicenceID } = data;
        return (
            <React.Fragment>
                <MobileCardActionButton
                    onClick={() => navigateTo(PATH.ENQUIRY.SERVICE_DETAILS, { licence: { LicenceID } })}
                    icon={faEye}
                    label='View'
                />
            </React.Fragment>
        );
    }

    setLicenceStatusTranslatedContent = (rowData) => {
        const { t } = this.props;
        return (
            <>{t(getLangKey(LANGUAGE_KEYS.BLS_CODE_LICENCESTATUS_TITLE_KEY, rowData.LicenceStatusCodeName))}</>
        );
    }

    getLicenceStatusTranslatedString = (rowData) => {
        const { t } = this.props;
        return t(getLangKey(LANGUAGE_KEYS.BLS_CODE_LICENCESTATUS_TITLE_KEY, rowData.LicenceStatusCodeName));
    }

    setLicenceNameTranslatedContent = (rowData) => {
        const { t } = this.props;
        const licenceTypeNameTranslationKey = getLicenceTypeNameTranslationKey(rowData.LicenceName);
        return (
            <>{t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY, licenceTypeNameTranslationKey, rowData.LicenceTypeId))}</>
        );
    }

    getLicenceNameTranslatedString = (rowData) => {
        const { t } = this.props;
        const licenceTypeNameTranslationKey = getLicenceTypeNameTranslationKey(rowData.LicenceName);
        return t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY, licenceTypeNameTranslationKey, rowData.LicenceTypeId));
    }

    renderGrid = () => {
        const { t } = this.props;
        if (this.state.success) {
            return (
                <React.Fragment>
                    <Row>
                        <Col>
                            <DataTable
                                pageSize={5}
                                minFilterChars='2'
                                data={this.state.data}
                                components={getCustomComponents()}
                                noResultsMessage={t(LANGUAGE_KEYS.BLS_INTERNALAPPENQUIRY_MESSAGE_PLACEHOLDER_NORESULTS)}
                                columns={
                                    {
                                        RunningNumber: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENQUIRY_TABLE_TITLE_NUMBER), width: '5%', DBkey: 'Id' },
                                        ServiceNumber: {
                                            title: t(LANGUAGE_KEYS.BLS_INTERNALLICENQUIRY_TABLE_TITLE_SERVICENO),
                                            width: '5%',
                                            DBkey: 'ServiceNumber',
                                            setContent: this.setContent
                                        },
                                        ServiceName: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENQUIRY_TABLE_TITLE_SERVICE), width: '18%', DBkey: 'ServiceName', setContent: this.setLicenceNameTranslatedContent, getTranslatedString: this.getLicenceNameTranslatedString },
                                        ApplicantName: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENQUIRY_TABLE_TITLE_APPLICANT), width: '15%', DBkey: 'LicenseeName' },
                                        AgencyName: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENQUIRY_TABLE_TITLE_AGENCY), width: '20%', DBkey: 'AgencyName' },
                                        ServiceStatus: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENQUIRY_TABLE_TITLE_STATUS), width: '8%', DBkey: 'ServiceStatus', setContent: this.setLicenceStatusTranslatedContent, getTranslatedString: this.getLicenceStatusTranslatedString },
                                        IssuedDate: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENQUIRY_TABLE_TITLE_ISSUEDDATE), isDate: true, width: '12%', DBkey: 'IssuedDate' },
                                        ExpiryDate: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENQUIRY_TABLE_TITLE_EXPIRYDATE), isDate: true, width: '12%', DBkey: 'ExpiryDate' }
                                    }
                                }
                                renderMobileCardActions={this.renderMobileCardActions}
                            />
                        </Col>
                    </Row>
                </React.Fragment>
            );
        } else {
            return null;
        }
    }

    render() {
        const { t } = this.props;
        const FORM_VALIDATIONS = Yup.object().shape({
            AgencyName: Yup.string(),
            LicenceName: Yup.string(),
            LicenceNumber: Yup.string(),
            LicenceStatus: Yup.string(),
            IssuedDate: Yup.string().matches(REGEX.DATE, t(ERROR.DATE_INVALID)),
            ExpiryDate: Yup.string().matches(REGEX.DATE, t(ERROR.DATE_INVALID))
        });
        return (
            <React.Fragment>
                <Layout type={LAYOUT_TYPE.FORM} title={t(LANGUAGE_KEYS.BLS_INTERNALLICENQUIRY_CONTENT_TITLE_APPROVALENQUIRY)}>
                    <div className="grid-container">
                        <SMARTForm
                            formContext={FORM_CONTEXT}
                            formValues={this.state.values}
                            validationSchema={FORM_VALIDATIONS}
                            validateOnSubmit={true}
                            serverURL={RETRIEVE_URL}
                            isDummy={IS_DEMO}
                            submitCallback={this.parseSearch}
                        >
                            {({ values, errors, onChange, onChangeField, submitForm }) => (
                                <Container className="full-width">
                                    <Row>
                                        <Col className="col-gutter">
                                            <div onClick={this.toggleEnquiryView} className="chevron">
                                                <FontAwesomeIcon icon={this.state.openEnquiryView ? faChevronUp : faChevronDown} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Collapse className="collapse-enquiry" isOpen={this.state.openEnquiryView}>
                                            <Card className="enquiry-container">
                                                <CardBody className="enquiry-card-body">
                                                    <SelectList
                                                        name="AgencyName"
                                                        value={values.AgencyName}
                                                        options={this.state.agencyOptions}
                                                        onChangeField={(name, value) => this.onAgencyChangeField(name, value, onChangeField)}
                                                        placeholder={t(LANGUAGE_KEYS.BLS_INTERNALLICENQUIRY_FORM_PLACEHOLDER_AGENCY)}
                                                        isMulti={false}
                                                        isClearable={true}
                                                        inputSize={INPUT_SIZE}
                                                        label={t(LANGUAGE_KEYS.BLS_INTERNALLICENQUIRY_FORM_PLACEHOLDER_AGENCY)}
                                                        labelSize={LABEL_SIZE}
                                                        error={errors.AgencyName}
                                                        isDisabled={this.state.agencyOptions.length === 1}
                                                    />
                                                    <SelectList
                                                        name="LicenceName"
                                                        value={values.LicenceName}
                                                        options={this.state.licenceNameOptions}
                                                        onChangeField={(name, value) => this.onLicenceChangeField(name, value, onChangeField)}
                                                        placeholder={t(LANGUAGE_KEYS.BLS_INTERNALLICENQUIRY_FORM_PLACEHOLDER_SERVICE)}
                                                        isMulti={false}
                                                        isClearable={true}
                                                        inputSize={INPUT_SIZE}
                                                        label={t(LANGUAGE_KEYS.BLS_INTERNALLICENQUIRY_FORM_PLACEHOLDER_SERVICE)}
                                                        labelSize={LABEL_SIZE}
                                                        error={errors.LicenceName}
                                                    />
                                                    <Text
                                                        name="LicenceNumber"
                                                        value={values.LicenceNumber}
                                                        placeholder={t(LANGUAGE_KEYS.BLS_INTERNALLICENQUIRY_FORM_PLACEHOLDER_SERVICENO)}
                                                        onChange={onChange}
                                                        inputSize={INPUT_SIZE}
                                                        label={t(LANGUAGE_KEYS.BLS_INTERNALLICENQUIRY_FORM_PLACEHOLDER_SERVICENO)}
                                                        labelSize={LABEL_SIZE}
                                                        error={errors.LicenceNumber}
                                                    />
                                                    <SelectList
                                                        name="LicenceStatus"
                                                        value={values.LicenceStatus}
                                                        options={this.state.licenceStatusOptions}
                                                        onChangeField={onChangeField}
                                                        placeholder={t(LANGUAGE_KEYS.BLS_INTERNALLICENQUIRY_FORM_PLACEHOLDER_STATUS)}
                                                        isMulti={false}
                                                        isClearable={true}
                                                        inputSize={INPUT_SIZE}
                                                        label={t(LANGUAGE_KEYS.BLS_INTERNALLICENQUIRY_FORM_PLACEHOLDER_STATUS)}
                                                        labelSize={LABEL_SIZE}
                                                        error={errors.LicenceStatus}
                                                    />
                                                    <DateInput
                                                        name="IssuedDate"
                                                        value={values.IssuedDate}
                                                        placeholder={t(LANGUAGE_KEYS.BLS_INTERNALLICENQUIRY_FORM_PLACEHOLDER_ISSUEDDATE)}
                                                        onChangeField={onChangeField}
                                                        date={true}
                                                        time={false}
                                                        format={DATE_DISPLAY_FORMAT.DATE}
                                                        inputSize={INPUT_SIZE}
                                                        label={t(LANGUAGE_KEYS.BLS_INTERNALLICENQUIRY_FORM_PLACEHOLDER_ISSUEDDATE)}
                                                        labelSize={LABEL_SIZE}
                                                        error={errors.IssuedDate}
                                                        clearFieldEnabled={true}
                                                        disableKeyboardInput={true}
                                                    />
                                                    <DateInput
                                                        name="ExpiryDate"
                                                        value={values.ExpiryDate}
                                                        placeholder={t(LANGUAGE_KEYS.BLS_INTERNALLICENQUIRY_FORM_PLACEHOLDER_EXPIRYDATE)}
                                                        onChangeField={onChangeField}
                                                        date={true}
                                                        time={false}
                                                        format={DATE_DISPLAY_FORMAT.DATE}
                                                        inputSize={INPUT_SIZE}
                                                        label={t(LANGUAGE_KEYS.BLS_INTERNALLICENQUIRY_FORM_PLACEHOLDER_EXPIRYDATE)}
                                                        labelSize={LABEL_SIZE}
                                                        error={errors.ExpiryDate}
                                                        clearFieldEnabled={true}
                                                        disableKeyboardInput={true}
                                                    />
                                                    <Row className="button-row">
                                                        <Button
                                                            color="forward"
                                                            size="sm"
                                                            type="button"
                                                            onClick={submitForm}>
                                                            {t(LANGUAGE_KEYS.BLS_INTERNALLICENQUIRY_FORM_BUTTON_SEARCH)}
                                                        </Button>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Collapse>
                                    </Row>
                                    <Row>
                                        <Col className="col-gutter record-count">
                                            {t(LANGUAGE_KEYS.BLS_INTERNALLICENQUIRY_FORM_TITLE_RECORDS)} ({this.state.count})
                                        </Col>
                                    </Row>
                                    <Row>
                                        <div className="enquiry-grid-container">
                                            {this.renderGrid()}
                                        </div>
                                    </Row>
                                </Container>
                            )}
                        </SMARTForm>
                    </div>
                </Layout>
            </React.Fragment>
        );
    }
}

export default withTranslation()(withSMARTWrapper(LicenceEnquiry));