import PropTypes from 'prop-types';
import React, { PureComponent } from "react";
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Sector } from "recharts";
import { withTranslation } from 'react-i18next';
import { LANGUAGE_KEYS } from '../../Shared/Constants/LanguageKeys';
import i18n from 'i18next';

const renderActiveShape = props => {
	const RADIAN = Math.PI / 180;
	const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
	const sin = Math.sin(-RADIAN * midAngle);
	const cos = Math.cos(-RADIAN * midAngle);
	const sx = cx + (outerRadius + 10) * cos;
	const sy = cy + (outerRadius + 10) * sin;
	const mx = cx + (outerRadius + 30) * cos;
	const my = cy + (outerRadius + 30) * sin;
	const ex = mx + (cos >= 0 ? 1 : -1) * 22;
	const ey = my;
	const textAnchor = cos >= 0 ? "start" : "end";

	return (
		<g>
			{/*text positioned in the center for currently selected pie*/}
			<text x={cx} y={cy} dy={8} textAnchor="middle" fill="#000000">
				{payload.name}
			</text>
			<Sector
				cx={cx}
				cy={cy}
				innerRadius={innerRadius}
				outerRadius={outerRadius}
				startAngle={startAngle}
				endAngle={endAngle}
				fill={fill}
			/>
			<Sector
				cx={cx}
				cy={cy}
				startAngle={startAngle}
				endAngle={endAngle}
				innerRadius={outerRadius + 6}
				outerRadius={outerRadius + 10}
				fill={fill}
			/>
			{/*SVG arrow pointer for pie*/}
			<path
				d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
				stroke={fill}
				fill="none"
			/>
			<circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />

			{/*text for value of currently selected pie */}
			<text
				x={ex + (cos >= 0 ? 1 : -1) * 12}
				y={ey}
				textAnchor={textAnchor}
				fill="#333"
			>{`${value}`}</text>

			{/*text for percentages */}
			<text
				x={ex + (cos >= 0 ? 1 : -1) * 12}
				y={ey}
				dy={18}
				textAnchor={textAnchor}
				fill="#999"
			>
				{`(${(percent * 100).toFixed(1)}%)`}
			</text>
		</g>
	);
};

class ActivePieChart extends PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			activeIndex: 0
		};
	}

	//function for currently selected pie
	onPieEnter = (data, index) => {
		this.setState({
			activeIndex: index,
		});
	};

	legendContent = data => {
		let totalNumber = 0;
		data.forEach(element => (totalNumber += element.value));

		return (
			<>			
				<ul className="active-pie-chart-legend"
					style={{ listStyleType: "none", display: "inline" }}
				>
					{data.map((entry, index) => (
						<li style={{ display: "inline-block" }} key={index}>
							<span
								style={{
									backgroundColor: this.props.colors[
										index % this.props.colors.length
									]
								}}
							>
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
							</span>
							<span className="legend-percentage" >&nbsp;{entry.name}&nbsp;<b>({((entry.value / totalNumber) * 100).toFixed(1)}%)</b>&nbsp;</span>
						</li>
					))}
				</ul>
			</>
		);
	};

	render() {
		let totalNumber = 0;
		this.props.data.forEach(element => (totalNumber += element.value));
		if (totalNumber === 0) {
			return (
				<div className="pie-no-data">
					{this.props.emptyDataMessage}
				</div>
			)
		}
		else {
			return (
				<ResponsiveContainer width="99.8%" aspect={16.0 / 9.0}>
					<PieChart
						width={this.props.pieChartWidth}
						height={this.props.pieChartHeight}
					>
						<Pie
							activeIndex={this.state.activeIndex}
							activeShape={renderActiveShape}
							data={this.props.data}
							cx={this.props.pieCX}
							cy={this.props.pieCY}
							innerRadius={this.props.pieInnerRadius}
							outerRadius={this.props.pieOuterRadius}
							dataKey={this.props.pieDataKey}
							onMouseEnter={this.onPieEnter}
						>
							{this.props.data.map((entry, index) => (
								<Cell
									key={index}
									fill={this.props.colors[index % this.props.colors.length]}
								/>
							))}
						</Pie>
						<Legend
							content={this.legendContent(this.props.data)}
							iconSize={this.props.pieLegendIconSize}
							height={this.props.pieLegendHeight}
							width={this.props.pieLegendWidth}
							verticalAlign={this.props.pieLegendVerticalAlign}
							align={this.props.pieLegendAlign}
							margin={this.props.pieLegendMargin}
						/>
					</PieChart>
				</ResponsiveContainer>
			);
		}

	}
}

ActivePieChart.propTypes = {
	pieChartWidth: PropTypes.number.isRequired,
	pieChartHeight: PropTypes.number.isRequired
}

ActivePieChart.defaultProps = {
	emptyDataMessage: i18n.isInitialized ? i18n.t(LANGUAGE_KEYS.BLS_INTERNALDASHBOARD_CONTENT_TITLE_NOCURRENTDATA) : '',
	colors: ["#F5796D", "#64C195", "#FCC777", "#5664AF", "#ADD68A", "#BD7CB4", "#F5A872", "#5C88C5", "#FBF583", "#7E6AAF", "#F68C72", "#56C4C3", "#00C49F", "#528DF7", "#FF8042", "#FFDA6B", "#b19cd9", "lightgreen"],
	pieChartWidth: 470,
	pieChartHeight: 250,
	pieInnerRadius: "58%",
	pieOuterRadius: "78%",
	pieDataKey: "value",
	pieLabelListDataKey: "value",
	pieLegendMargin: { top: 30, right: 0, left: 0, bottom: 0 }

}

export default withTranslation()(ActivePieChart);