import _ from 'lodash';
import { faEye, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { default as React } from 'react';
import { Button, Col, Row } from 'reactstrap';
import Select from "react-select";
import { ERROR, SUCCESS } from '../../Localization/index';
import { fetchRequest, postParams, getParams, navigateTo } from '../../Shared/Actions';
import { ActionButton, DataTable, MobileCardActionButton } from '../../Shared/DataTable';
import { getCustomComponents } from '../../Shared/DataTable/BaseTable.js';
import { withSMARTWrapper } from '../../Shared/Forms';
import { DEFAULT_TEXT, DEFAULT_NAMESPACE } from '../../Shared/Constants/LanguageKeys.js';
import { toastError, toastSuccess } from '../../Shared/Forms/Toaster';
import { ModalConfirm } from '../../Shared/Modal';
import { DELETE_PUBLIC_HOLIDAY_URL, GET_PUBLIC_HOLIDAY_LIST_URL, PUBLIC_HOLIDAY_VIEW_URL, PUBLIC_HOLIDAY_CREATE_URL, PUBLIC_HOLIDAY_UPDATE_URL } from '../../App/AppSettings';
import { FILTER, SELECT_FILTERS } from '../../Shared/Constants';
import Title from '../../Title';

const DELETE_MODAL = {
    hasModal: true,
    name: "Delete"
};

class PublicHolidayList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            collapse: false,
            count: 0,
            modalState: undefined,
            searchFilter: "",
            yearFilter: ""
        };
    }

    componentDidMount = async () => {
        this.loadGrid();
    }

    // Load Datatable
    loadGrid = async () => {
        this.props.loader.start();

        const response = await fetchRequest(GET_PUBLIC_HOLIDAY_LIST_URL, getParams(), false);
        const { Data, IsSuccess } = response.body;

        if (response.success && IsSuccess) {
            this.setState({
                success: response.success,
                data: Data,
                publicHolidayYearOptions: this.getPublicHolidayYearOptions(Data),
                count: ((Data !== undefined) ? Data.length : 0)
            });
        }

        this.props.loader.done();
    }

    // Get list of unique Public Holiday years
    getPublicHolidayYearOptions = (Data) => {
        let publicHolidayYear = [];

        // Add all years into array
        _.forEach(Data, function (ph) {
            publicHolidayYear.push(ph.Year);
        });

        // Get unique years
        publicHolidayYear = _.uniqBy(_.sortBy(publicHolidayYear, [function(y) { return -y; }]));

        // Initialize DropdownArray
        let publicHolidayDropDownArray = [{ label: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_PUBLICHOLIDAYSTATIC_CONTENT_DROPDOWN_ALLYEARS, DEFAULT_TEXT.ALL_YEARS), value: "" }];

        // Add unique years into DropdownArray
        publicHolidayYear.forEach(val => {
            publicHolidayDropDownArray.push({ 'label': val.toString(), 'value': val.toString() });
        });

        return publicHolidayDropDownArray;
    }

    onFilterChange = (filterName, value, setFilter) => {
        this.setState({
            [filterName]: value
        }, () => {
            this.filter(setFilter);
        });
    }

    filter = (setFilter) => {
        const filterFields = {
            [FILTER]: this.state.searchFilter,
            [SELECT_FILTERS]: {
                'Year': this.state.yearFilter
            }
        };

        setFilter(filterFields);
    }

    CustomGridFilter = (props) => {
        let publicHolidayYearOptions = this.state.publicHolidayYearOptions;
        let publicHolidayValue = this.state.yearFilter;
        publicHolidayValue = publicHolidayValue === "" ? publicHolidayYearOptions[0] :
            _.find(publicHolidayYearOptions, { 'value': publicHolidayValue.toString() });

        return (
            <React.Fragment>
                <Select
                    defaultValue={publicHolidayValue}
                    placeholder="All Categories"
                    onChange={(selectedOption) => this.onFilterChange("yearFilter", selectedOption.value, props.setFilter)}
                    options={publicHolidayYearOptions}
                    maxMenuHeight={150}
                    menuPlacement="auto"
                    styles={{
                        menuPortal: styles => ({ ...styles, zIndex: 100 }) //  >= dialog's z-index
                    }}
                    className="select-filter select-list" //  class for css
                />
                <input
                    type="text"
                    name="filter"
                    defaultValue={this.state.searchFilter}
                    placeholder="Filter"
                    className="custom-griddle-filter griddle-filter"
                    onChange={e => this.onFilterChange("searchFilter", e.target.value, props.setFilter)}
                />
            </React.Fragment>
        );
    };

    // Toggle Delete modal
    toggleModal = (modalName, ID) => {
        this.setState({
            modalState: (this.state.modalState !== modalName) ? modalName : undefined,
            modalID: ID
        })
    };

    // Toggle Delete Public Holiday modal and delete record
    deletePublicHoliday = async() => {
        this.toggleModal(DELETE_MODAL.name); 
        
        const params = {
            Id: this.state.modalID
        }
        const response = await fetchRequest(DELETE_PUBLIC_HOLIDAY_URL, postParams('', params));
        const { IsSuccess, Messages } = response.body;

        if (response.success) {
            if (IsSuccess) {
                await this.loadGrid();
                
                toastSuccess(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_PUBLICHOLIDAYSTATIC_SUCCESS_TITLE_DELETEPUBLICHOLIDAY, SUCCESS.DELETE_SUCCESS), DEFAULT_TEXT.PUBLIC_HOLIDAY);
            }
            else {
                // Error message to be loaded here
                toastError(Messages);
            }
        }
        else {
            toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
        }

    }

    renderMobileCardActions = (RowData) => {
        return (
            <React.Fragment>
                <MobileCardActionButton
                    onClick={() => navigateTo(PUBLIC_HOLIDAY_UPDATE_URL, { ID: RowData.Id })}
                    icon={faEdit}
                    label='Update'
                />
                <MobileCardActionButton
                    onClick={() => this.toggleModal(DELETE_MODAL.name, RowData.Id)}
                    icon={faTrash}
                    label='Delete'
                />
            </React.Fragment>
        )
    }

    getMobileCardSelectFilterValues = () => {
        let publicHolidayYearOptions = this.state.publicHolidayYearOptions;
        let selectFilterValues = [
            {
                name: "Year",
                placeholder: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_PUBLICHOLIDAYSTATIC_CONTENT_DROPDOWN_ALLYEARS, DEFAULT_TEXT.ALL_YEARS),
                options: publicHolidayYearOptions
            }
        ]
        return selectFilterValues;
    }

    getCustomComponents = () => {
        let customComponents = getCustomComponents();
        customComponents.Filter = this.CustomGridFilter;

        return customComponents;
    }

    
    setFilterText = (filterText) => {
        this.setState({
            yearFilter: filterText[SELECT_FILTERS]['Year'],
            searchFilter: filterText[FILTER]
        })
    }

    // Render Datatable
    renderLayout = () => {
        if (this.state.success) {
            return (
                <React.Fragment>
                    <Col className='text-right remove-padding'>
                        <Button color="neutral" onClick={() => navigateTo(PUBLIC_HOLIDAY_CREATE_URL)}>
                            {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_PUBLICHOLIDAYSTATIC_NAVIGATION_BUTTON_NEWPUBLICHOLIDAY, DEFAULT_TEXT.NEW_PUBLIC_HOLIDAY)}
                        </Button>
                    </Col>
                    <div className="smart-list">
                        <DataTable
                            pageSize="5"
                            isCustomFilter={true}
                            minFilterChars='2'
                            setFilterText={this.setFilterText}
                            data={this.state.data}
                            noResultsMessage={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_PUBLICHOLIDAYSTATIC_TABLE_TEXT_PUBLICHOLIDAYNORECORD, DEFAULT_TEXT.PH_NO_RECORD)}
                            components={this.getCustomComponents()}
                            columns={
                                {
                                    RunningNumber: { title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_PUBLICHOLIDAYSTATIC_CONTENT_TABLE_NO, DEFAULT_TEXT.NUM), width: '5%' },
                                    Actions: { title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_PUBLICHOLIDAYSTATIC_CONTENT_TABLE_ACTIONS, DEFAULT_TEXT.ACTIONS), width: '10%', DBkey: 'Action' },
                                    Year: { title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_PUBLICHOLIDAYSTATIC_CONTENT_TABLE_YEAR, DEFAULT_TEXT.YEAR), width: '5%', DBkey: 'Year' },
                                    Date: { title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_PUBLICHOLIDAYSTATIC_CONTENT_TABLE_DATE, DEFAULT_TEXT.DATE), width: '10%', DBkey: 'FormattedDate', isDate: true },
                                    Title: { title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_PUBLICHOLIDAYSTATIC_CONTENT_TABLE_TITLE, DEFAULT_TEXT.TITLE), width: '25%', DBkey: 'Title' },
                                    Remarks: { title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_PUBLICHOLIDAYSTATIC_CONTENT_TABLE_REMARKS, DEFAULT_TEXT.REMARKS), width: '25%', DBkey: 'Remarks' },
                                }
                            }
                            renderActions={({ RowData }) => (
                                <React.Fragment>
                                    <ActionButton 
                                    tooltip={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_PUBLICHOLIDAYSTATIC_TABLE_TOOLTIP_EDIT, DEFAULT_TEXT.EDIT)}
                                    color="forward" 
                                    onClick={() => navigateTo(PUBLIC_HOLIDAY_UPDATE_URL, { ID: RowData.Id })}>
                                    <FontAwesomeIcon icon={faEdit} />
                                </ActionButton>
                                
                                <ActionButton 
                                    tooltip={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_PUBLICHOLIDAYSTATIC_TABLE_TOOLTIP_VIEW, DEFAULT_TEXT.VIEW)} 
                                    color="neutral" 
                                    onClick={() => navigateTo(PUBLIC_HOLIDAY_VIEW_URL, { ID: RowData.Id })}>
                                    <FontAwesomeIcon icon={faEye} />
                                </ActionButton>

                                <ActionButton 
                                    tooltip={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_PUBLICHOLIDAYSTATIC_TABLE_TOOLTIP_DELETE, DEFAULT_TEXT.DELETE)}
                                    color="hazard" 
                                    onClick={() => this.toggleModal(DELETE_MODAL.name, RowData.Id)}>
                                    <FontAwesomeIcon fixedWidth icon={faTrash} />
                                </ActionButton>
                                </React.Fragment>
                            )}
                            getMobileCardSelectFilterValues={this.getMobileCardSelectFilterValues}
                            renderMobileCardActions={this.renderMobileCardActions}
                        />
                        <ModalConfirm
                            isOpen={(this.state.modalState === DELETE_MODAL.name)}
                            contentHeader={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_PUBLICHOLIDAYSTATIC_MODAL_TITLE_DELETEPUBLICHOLIDAY, DEFAULT_TEXT.DELETE_PH_MODAL_TITLE)}
                            contentBody={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_PUBLICHOLIDAYSTATIC_MODAL_TEXT_DELETEPUBLICHOLIDAY, DEFAULT_TEXT.DELETE_PH_MODAL_TEXT, true)}
                            confirmText={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_PUBLICHOLIDAYSTATIC_NAVIGATION_BUTTON_CONFIRM, DEFAULT_TEXT.CANCEL)}
                            confirmCallback={() => { this.deletePublicHoliday(); }}
                            cancelText={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_PUBLICHOLIDAYSTATIC_NAVIGATION_BUTTON_CANCEL, DEFAULT_TEXT.CANCEL)}
                            cancelCallback={() => this.toggleModal(DELETE_MODAL.name)}
                        />
                    </div>
                </React.Fragment>
            );
        } else {
            return null;
        }
    }

    render() {
        return (
            <React.Fragment>
                <Title className="first body-title body-title-text" titleValue=
                    {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_PUBLICHOLIDAYSTATIC_CONTENT_TITLE_PUBLICHOLIDAYMGMT, DEFAULT_TEXT.PUBLIC_HOLIDAY_MANAGEMENT)} />
                <div className="grid-container">
                    <Row>
                        <Col>
                            {this.renderLayout()}
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}

export default withSMARTWrapper(PublicHolidayList);

