import React, { useState } from "react";
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Input } from "reactstrap";

function SelectFileModal(props) {
    const [formJson, setFormJson] = useState(props.formJson);
    function closeDisplayModal() {
        props.onModalClose();
    }

    function uploadFileOnClick() {
        props.onModalUpdate(formJson);
    }

    function onFileUploadChange(event) {
        const reader = new FileReader();
        reader.onload = async (event) => {
            setFormJson(event.target.result)
        }
        reader.readAsText(event.target.files[0]);
    }

    return <Modal className="modal-content" isOpen={props.show} backdrop={false} keyboard={false}>
        <ModalHeader>Select Form Json File:</ModalHeader>
        <ModalBody>
            <Row xs="auto">
                <Col>
                    <Form>
                        <FormGroup>
                            <Input name="file" type="file" onChange={onFileUploadChange} />
                        </FormGroup>
                    </Form>
                </Col>
            </Row>
        </ModalBody>
        <ModalFooter>
            <Button onClick={closeDisplayModal} outline>Cancel</Button>
            <Button color="success" onClick={uploadFileOnClick} outline>Upload</Button>
        </ModalFooter>
    </Modal>
}

export default SelectFileModal;