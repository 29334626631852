import { default as React } from 'react';
import { withTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import * as Yup from 'yup';
import { LAYOUT_TYPE, ONLINEHELP_URL } from '../../App/AppSettings';
import { CONTENT_TYPE, fetchRequest, formParams, getParams, navigateTo } from '../../Shared/Actions';
import { FIELD_SIZE } from '../../Shared/Constants';
import { ERROR, SUCCESS } from '../../Shared/Constants/LanguageKeys';
import { Paragraph, SMARTForm, Text, withSMARTWrapper } from '../../Shared/Forms';
import { toastError, toastSuccess } from '../../Shared/Forms/Toaster.js';
import history from '../../Shared/History';
import { NavigationPrompt } from '../../Shared/Modal';
import PageFooter from '../../Shared/PageFooter';
import { Tree } from '../../Shared/Tree';
import Title from '../../Title';
import { getLangKey } from '../DisplayComponents/DisplayUtils';


const SERVER_URL = ONLINEHELP_URL + "/";
const RETRIEVE_URL = ONLINEHELP_URL + "/";

const FORM_CONTEXT = "Online Help"

const FORM_INITIAL_VALUES = {
    OnlineHelpName: '',
    OnlineHelpDescription: '',
    OnlineHelpTree: '',
};

class OnlineHelpMgmtTree extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            values: FORM_INITIAL_VALUES,
            documentReady: false
        }
    }



    async componentDidMount() {
        const { state } = history.location;
        const { loader, pageNotFound, t } = this.props;

        if (state && state.ID) {
            loader.start();
            const response = await fetchRequest(RETRIEVE_URL + state.ID, getParams(), false);
            loader.done();
            const { IsSuccess, Data, Messages } = response.body;
            if (response.success && IsSuccess) {
                this.setState({
                    values: Data,
                    documentReady: true
                });
            }
            else {
                // Error message to be loaded here
                loader.error(t(getLangKey(ERROR.BACKEND_ERROR_MESSAGE, Messages)));
            }
        }
        else {
            pageNotFound();
        }

    }

    submitCallback = ({ response }) => {
        const { t } = this.props;
        if (response.success) {
            const { IsSuccess, RedirectURL } = response.body;
            if (IsSuccess) {
                if (RedirectURL) {
                    navigateTo(RedirectURL, "");
                }
                toastSuccess(t(SUCCESS.HELP_TREE_UPDATE_SUCCESS));
            }
            else {
                toastError(t(ERROR.HELP_TREE_NOT_UPDATED));
            }
        } else {
            toastError(t(ERROR.SERVER_UNREACHABLE))
        }
    }

    render() {
        const { t } = this.props;
        const FORM_VALIDATIONS = Yup.object().shape({
            OnlineHelpName: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED)).min(0, t(ERROR.MIN_LENGTH_LIMIT) + (ERROR.MIN_LENGTH_LABEL)).max(140, t(ERROR.MAX_LENGTH_LIMIT) + (ERROR.MAX_LENGTH_LABEL)),
            OnlineHelpDescription: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED)),
            OnlineHelpTree: Yup.string()
        });
        return (
            <React.Fragment>
                <Title className={"first body-title body-title-text"} titleValue={"Online Help"}></Title>
                <div className="onlinehelp-container">
                    {this.state.documentReady &&
                        <SMARTForm
                            formContext={FORM_CONTEXT}
                            formValues={this.state.values}
                            formParams={formParams("PUT", CONTENT_TYPE.JSON)}
                            serverURL={SERVER_URL + history.location.state.ID}
                            isDummy={false}
                            submitCallback={this.submitCallback}
                            validationSchema={FORM_VALIDATIONS}
                            validateOnSubmit={true}
                        >
                            {({ values, onChange, onChangeField, submitPlainForm, errors, dirty }) => (
                                <React.Fragment>
                                    <Text
                                        name="OnlineHelpName"
                                        value={values.OnlineHelpName}
                                        placeholder="Online Help Name"
                                        onChange={onChange}
                                        minLength={0}
                                        maxLength={140}
                                        inputSize={10}
                                        labelSize={FIELD_SIZE.XSMALL}
                                        label="Online Help Name"
                                        error={errors.OnlineHelpName}
                                        required
                                    />
                                    <Paragraph
                                        name="OnlineHelpDescription"
                                        value={values.OnlineHelpDescription}
                                        placeholder="Description"
                                        onChange={onChange}
                                        rows={3}
                                        minLength={0}
                                        inputSize={10}
                                        labelSize={FIELD_SIZE.XSMALL}
                                        label="Description"
                                        error={errors.OnlineHelpDescription}
                                        required
                                    />
                                    <Tree
                                        treeHeader="Help Tree"
                                        renderActions={true}
                                        error={errors.OnlineHelpTree}
                                        name="OnlineHelpTree"
                                        value={values.OnlineHelpTree}
                                        onChangeField={onChangeField}
                                    />

                                    <PageFooter type={LAYOUT_TYPE.FORM}>
                                        <Button color="backward" size="sm" type="button" onClick={() => history.goBack()}>Back</Button>
                                        <Button color="forward" size="sm" type="button" onClick={() => { submitPlainForm(); this.setState({ values: values }) }}>Save</Button>
                                    </PageFooter>
                                    <NavigationPrompt
                                        when={dirty}
                                        contentHeader={"Form not saved"}
                                        contentBody={"The form has not been saved, are you sure you want to leave this page?"}
                                        confirmText={"Leave"}
                                        cancelText={"Stay"}
                                    />
                                </React.Fragment>
                            )}
                        </SMARTForm>}
                </div>
            </React.Fragment>
        );
    }
}

export default withTranslation()(withSMARTWrapper(OnlineHelpMgmtTree));

