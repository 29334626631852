import React from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Col, Row } from 'reactstrap';
import * as Yup from 'yup';
import { DATE_DISPLAY_FORMAT, LAYOUT_TYPE, NOTIFICATIONTEMPLATE_URL } from '../../App/AppSettings';
import Layout from '../../Layout';
import { CONTENT_TYPE, fetchRequest, formParams, getParams } from '../../Shared/Actions';
import { FIELD_SIZE } from '../../Shared/Constants';
import { ERROR, SUCCESS } from '../../Shared/Constants/LanguageKeys';
import { CreatableSelectList, DateInput, Paragraph, RichTextEditor, SMARTForm, Text, withSMARTWrapper } from '../../Shared/Forms';
import { toastError, toastSuccess } from '../../Shared/Forms/Toaster.js';
import history from '../../Shared/History';
import PageFooter from '../../Shared/PageFooter';
import { getLangKey } from '../DisplayComponents/DisplayUtils';
import { RetrieveGlobalVariables } from './RetrieveGlobalVariables';

const FORM_CONTEXT = "NotificationTemplate";
const SERVER_URL =  NOTIFICATIONTEMPLATE_URL;
const DROPDOWN_CATEGORY_URL = NOTIFICATIONTEMPLATE_URL + "/category";

const FORM_INITIAL_VALUES = {
    TemplateName : '', 
    Category : '', 
    Type : 'LETTER', 
    ReturnAddress: '',
    TemplateBody : '', 
    DateOfLetter : ''
};

class LetterTemplateCreate extends React.Component {

    constructor(props) {
        super(props);
        this.state = { 
            values: FORM_INITIAL_VALUES,
            dropDownArrayCategory: [],
            globalVariableList: []
        };
    }

    componentDidMount = async () => {
        await this.dropdownList(DROPDOWN_CATEGORY_URL, "dropDownArrayCategory");
        await this.getGlobalVariables();
    }

    dropdownList = async (url,dropDownName) => {

        const response = await fetchRequest(url, getParams(), false);
        const { t } = this.props;
        const { Data, IsSuccess } = response.body;
        let dropDownArray = [];
        if(response.success){
            if(IsSuccess) {
                Data.forEach(element => {
                    dropDownArray.push({'label': element.label , 'value': element.value });
                });
                this.setState({[dropDownName]: dropDownArray})
            } else {
                toastError(t(getLangKey(ERROR.BACKEND_ERROR_MESSAGE, response.body.Messages.toString())), response.body.Messages.toString());
                return null;
            }
        } else {
            toastError(t(ERROR.SERVER_UNREACHABLE2));
            return null;
        }
        return dropDownArray;
    }

    getGlobalVariables = async () => {
        const { t } = this.props;
        this.setState({
            globalVariableList: await RetrieveGlobalVariables(t)
        })
    }
    
    submitCallback = ({ response }) => {
        const { t } = this.props;
        if (response.success) {
            const { IsSuccess } = response.body;
            if (IsSuccess) {
                toastSuccess(t(SUCCESS.NOTIF_TEMPLATE_ADD_SUCCESS));
                this.setState({ values: FORM_INITIAL_VALUES })
            }
            else {
                toastError(t(ERROR.NOTIF_TEMPLATE_NOT_ADDED));
            }
        } else {
            toastError(t(ERROR.SERVER_UNREACHABLE))
        }
    }

  render() {
    const { t } = this.props;
    const FORM_VALIDATIONS = Yup.object().shape({
        TemplateName : Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED)).min(0, t(ERROR.MIN_LENGTH_LIMIT) + (ERROR.MIN_LENGTH_LABEL)).max(140, t(ERROR.MAX_LENGTH_LIMIT) + (ERROR.MAX_LENGTH_LABEL)),
        Category : Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED)).min(0, t(ERROR.MIN_LENGTH_LIMIT) + (ERROR.MIN_LENGTH_LABEL)).max(140, t(ERROR.MAX_LENGTH_LIMIT) + (ERROR.MAX_LENGTH_LABEL)),
        Type : Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED)).min(0, t(ERROR.MIN_LENGTH_LIMIT) + (ERROR.MIN_LENGTH_LABEL)).max(140, t(ERROR.MAX_LENGTH_LIMIT) + (ERROR.MAX_LENGTH_LABEL)),
        ReturnAddress : Yup.string(),
        DateOfLetter : Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED)),
        TemplateBody: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED))
    });
    return (
			<React.Fragment>
                <Layout type={LAYOUT_TYPE.FORM} title="Create Letter Template">
					<SMARTForm
                        formContext={FORM_CONTEXT}
                        formValues={this.state.values}
                        formParams={formParams("POST", CONTENT_TYPE.JSON)}
                        validationSchema={FORM_VALIDATIONS}
                        validateOnSubmit={true}
                        serverURL={SERVER_URL}
                        isDummy={false}
                        resetFormAfterSubmit={true}
                        submitCallback= {this.submitCallback}
					>
        			{({ values, onChange, onChangeField, submitPlainForm, errors }) => (
                        <React.Fragment>
                            <Row className="body-content admin-content-wrapper">
                                <Col>
                                    <Text 
                                        name="TemplateName"
                                        value={values.TemplateName}
                                        placeholder="Template Name"
                                        onChange={onChange}
                                        minLength={0}
                                        maxLength={140}
                                        inputSize= {FIELD_SIZE.XLARGE}
                                        labelSize= {FIELD_SIZE.XSMALL}
                                        label = "Template Name"
                                        error = {errors.TemplateName} 
                                        required
                                    />
                                    <Text 
                                        name="Type"
                                        value={values.Type}
                                        onChange={onChange}
                                        minLength={0}
                                        maxLength={140}
                                        inputSize= {FIELD_SIZE.XLARGE}
                                        labelSize= {FIELD_SIZE.XSMALL}
                                        label = "Type"
                                        error = {errors.Type} 
                                        readOnly
                                    />
                                    <CreatableSelectList 
                                        isClearable={true}
                                        name="Category"
                                        value={values.Category}
                                        placeholder="Category"
                                        onChangeField={onChangeField}
                                        minLength={0}
                                        maxLength={140}
                                        inputSize= {FIELD_SIZE.XLARGE}
                                        labelSize= {FIELD_SIZE.XSMALL}
                                        label = "Category"
                                        helpLabel="Please choose an existing category, or type a new one then press enter to create a new option."
                                        options={this.state.dropDownArrayCategory}
                                        isMulti={false}
                                        error = {errors.Category} 
                                        required
                                    />
                                    <Paragraph 
                                        name="ReturnAddress"
                                        value={values.ReturnAddress}
                                        placeholder="Return Address"
                                        onChange={onChange}
                                        minLength={0}
                                        maxLength={140}
                                        inputSize= {FIELD_SIZE.XLARGE}
                                        labelSize= {FIELD_SIZE.XSMALL}
                                        label = "Return Address"
                                        error = {errors.ReturnAddress} 
                                    />
                                    <DateInput 
                                        name="DateOfLetter"
                                        value={values.DateOfLetter}
                                        placeholder="Date of Letter"
                                        onChangeField={onChangeField}
                                        time={false}
                                        date={true}
                                        min="1900/01/01"
                                        max="2100/01/01"
                                        format={DATE_DISPLAY_FORMAT.DATE}
                                        inputSize= {FIELD_SIZE.SMALL}
                                        labelSize= {FIELD_SIZE.XSMALL}
                                        label = "Date of Letter"
                                        error = {errors.DateOfLetter} 
                                        required
                                    />
                                    <RichTextEditor 
                                        name= "TemplateBody"
                                        value= {values.TemplateBody}
                                        onChangeField= {onChangeField}
                                        inputSize= {FIELD_SIZE.XLARGE}
                                        labelSize= {FIELD_SIZE.XSMALL}
                                        label= "Template Body"
                                        showHtml= {false}
                                        suggestions={this.state.globalVariableList}
                                        error={errors.TemplateBody}
                                        required
                                    />

                                </Col>
                            </Row>
					
							<PageFooter type={LAYOUT_TYPE.FORM}>
								<Button color="backward" size="sm" type="button" onClick={() => history.goBack()}>Back</Button>
								<Button color="forward" size="sm" type="button" onClick={submitPlainForm}>Create</Button>
							</PageFooter>

                        </React.Fragment>
				)}
			</SMARTForm>
            </Layout>
		</React.Fragment>
	);
   }
}

export default withTranslation()(withSMARTWrapper(LetterTemplateCreate));

