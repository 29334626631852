import React from 'react';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import './FaqTree.css';

class TreeMenuContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        };
    }

    toggle = () => {
        this.setState({ open: !this.state.open });
    };

    render() {
        const { toggleContent } = this.props;
           return (
               <Dropdown
                   isOpen={this.state.open}
                   toggle={this.toggle}>
                   <DropdownToggle
                       tag="span"
                       data-toggle="dropdown"
                       aria-expanded={this.state.open}
                       onClick={e => e.stopPropagation()}
                   >
                       {toggleContent}
                   </DropdownToggle>
                   <DropdownMenu size="sm">{this.props.children}</DropdownMenu>
            </Dropdown>
          );
    }
}

export default TreeMenuContainer;