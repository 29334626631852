import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { withBaseControl } from './BaseControl';

class RichText extends React.Component {
    
    render() {
        return <div>{ReactHtmlParser(this.props.html)}</div>
    }
}

// PropTypes: For prop validation
RichText.propTypes = {
    html: PropTypes.any
};

export default withBaseControl(RichText);