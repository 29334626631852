import React from 'react';
import { LAYOUT_TYPE } from '../../../../../../App/AppSettings';
import Section from '../../../../../../Section';
import CommonUraChangeUseApprovalLicenceOverview from './CommonUraChangeUseApprovalLicenceOverview';
import CommonUraChangeUseApprovalLicenceDetails from './CommonUraChangeUseApprovalLicenceDetails';

class UraChangeUseApprovalRenewal extends React.Component {
    render() {
        const { sectionState, toggleSection, sectionName, licenceInformation, licenceDetails, generalInformation } = this.props;

        return (
            licenceInformation && generalInformation && licenceDetails ?
            (<Section
                type={LAYOUT_TYPE.FORM_CONTENT}
                sectionState={sectionState}
                sectionName={sectionName}
                toggleSection={toggleSection}
            >
                <CommonUraChangeUseApprovalLicenceOverview
                    licenceDetails={licenceDetails}
                    generalInformation={generalInformation}
                />
                <CommonUraChangeUseApprovalLicenceDetails licenceInformation={licenceInformation} />
            </Section>) : null
        );
    }
}

export default UraChangeUseApprovalRenewal;