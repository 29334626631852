import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Table } from 'reactstrap';
import { Plaintext } from '../../../Shared/Forms';
import { ModalConfirm } from '../../../Shared/Modal';
import { getTableListValues } from '../FormUtils';
import { FIELD_SIZE } from '../../../Shared/Constants';

const MODAL_NAMES = { SUBMIT: 'Submit', TablePopUp: 'TablePopUp' };

class BigPreviewTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }


    toggleModal = (modalName, ID) => {
        this.setState({
            modalState: (this.state.modalState !== modalName) ? modalName : undefined,
            modalID: ID
        });
    };

    render() {
        const { values, section } = this.props;
        const tableValueKeys = Object.keys(values);

        return (
            <Table className="griddle-table">
                <thead className="griddle-table-heading">
                    <tr>
                        {section.Table.Fields && section.Table.Fields.map((field, findex) => {
                            return (
                                field.ShowField === 'Show' && <th key={findex} className="griddle-table-heading-cell">{field.DisplayName}</th>
                            );
                        })}
                        <th className="griddle-table-heading-cell">Action</th>
                    </tr>
                </thead>
                <tbody className="griddle-table-body">
                    {
                        getTableListValues(tableValueKeys, section.Name) &&
                        getTableListValues(tableValueKeys, section.Name)[section.Name] &&
                        getTableListValues(tableValueKeys, section.Name)[section.Name].map((row, rindex) => {
                            return (

                                <tr key={rindex} className="griddle-row">
                                    {section.Table.Fields && section.Table.Fields.map((field, findex) => {
                                        return (
                                            field.ShowField === 'Show' && <td key={findex + rindex} className="griddle-cell table-griddle-cell">
                                                {
                                                    field.InputType !== 'FileUpload' && <Plaintext
                                                        label={undefined}
                                                        className="modal-plaintext"
                                                        key={findex + rindex}
                                                        name={field.Name}
                                                        value={values[`${section.Name}.${rindex}.${field.Name}`]}
                                                        labelSize={null}
                                                    />
                                                }
                                            </td>
                                        );
                                    })}
                                    <td className="griddle-cell table-griddle-cell">
                                        <div className="action-button-group table-button">
                                            <Button
                                                className="action-btn"
                                                color="forward"
                                                type="button"
                                                onClick={() => this.toggleModal(MODAL_NAMES.TablePopUp, rindex)}
                                            >
                                                <FontAwesomeIcon icon={faEye} />
                                            </Button>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })
                    }
                    <ModalConfirm
                        className="modal-table"
                        isOpen={this.state.modalState === MODAL_NAMES.TablePopUp}
                        contentHeader={`View ${section.DisplayName}`}
                        contentBody={
                            values &&
                            section.Table.Fields && section.Table.Fields.map((field, findex) => {
                                return (
                                    <div key={findex + this.state.modalID}>
                                        {
                                            field.InputType !== 'FileUpload' && <Plaintext
                                                className="modal-plaintext"
                                                name={field.Name}
                                                label={field.DisplayName}
                                                value={values[`${section.Name}.${this.state.modalID}.${field.Name}`]}
                                                labelSize={FIELD_SIZE.MEDIUM}
                                                inputSize={FIELD_SIZE.MEDIUM}
                                            />
                                        }
                                    </div>
                                );
                            })
                        }
                        confirmText="Close"
                        confirmCallback={() => { this.toggleModal(MODAL_NAMES.TablePopUp, this.state.modalID); }}
                    />
                </tbody>
            </Table>
        );
    }
}
export default BigPreviewTable;