import { Divider } from 'antd';
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import '../../../../node_modules/react-vis/dist/style.css';
import { DASHBOARD_DATA_URL, LAYOUT_TYPE } from '../../App/AppSettings';
import Layout from '../../Layout';
import { fetchRequest, getParams } from '../../Shared/Actions';
import ActivePieChart from '../../Shared/CustomCards/ActivePieChart';
import ChartCard from '../../Shared/CustomCards/ChartCard';
import NumberCard from '../../Shared/CustomCards/NumberCard';
import StackedBarChart from '../../Shared/CustomCards/StackedBarChart';
import { withTranslation } from 'react-i18next';
import { LANGUAGE_KEYS } from '../../Shared/Constants/LanguageKeys';
import { getLicenceTypeNameTranslationKey, getLangKey } from '../DisplayComponents/DisplayUtils';

const colorPendingApp = ['#00C49F', '#528DF7', '#FF8042'];
const colorRevenue = ['#FFDA6B', '#b19cd9', 'lightgreen'];

class DashBoard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataApplicationsToday: 0,
            dataPendingApplications: 0,
            dataLicenceIssuedToday: 0,
            dataRevenueToday: 0,
            dataPendingApplicationChart: [],
            dataRevenueBreakDown: [],
            dataDailyIssuedLicences: {}
        };
    }

    async componentDidMount() {
        const response = await fetchRequest(DASHBOARD_DATA_URL, getParams(), false);
        if (response.success && response.body.IsSuccess) {
            this.setState({
                dataApplicationsToday: response.body.Data.ApplicationsToday,
                dataPendingApplications: response.body.Data.PendingApplications,
                dataLicenceIssuedToday: response.body.Data.LicenceIssuedToday,
                dataRevenueToday: response.body.Data.RevenueToday,
                dataPendingApplicationChart: response.body.Data.PendingApplicationChart,
                dataRevenueBreakDown: response.body.Data.RevenueBreakdown,
                dataDailyIssuedLicences: response.body.Data.DailyIssuedLicences
            });
        }
    }

    render() {
        const { t } = this.props;
        const {
            dataApplicationsToday,
            dataPendingApplications,
            dataLicenceIssuedToday,
            dataRevenueToday,
            dataPendingApplicationChart,
            dataRevenueBreakDown,
            dataDailyIssuedLicences
        } = this.state;

        dataDailyIssuedLicences.Fieldkeys && dataDailyIssuedLicences.Fieldkeys.length > 0 &&
            dataDailyIssuedLicences.Fieldkeys.forEach(licence => {
                licence.name = t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY, getLicenceTypeNameTranslationKey(licence.name), licence.key));
            });

        return (
            <React.Fragment>
                <Layout type={LAYOUT_TYPE.FORM} title={t(LANGUAGE_KEYS.BLS_INTERNALDASHBOARD_CONTENT_TITLE_DASHBOARD)}>
                    <Row className='body-content'>
                        <Container fluid className='dashboard-container'>
                            <Row>
                                <Divider className='db-divider' orientation='left'>
                                  {t(LANGUAGE_KEYS.BLS_INTERNALDASHBOARD_CONTENT_TITLE_TASKS)}
                                </Divider>
                                <Col xl={3} className='card-col'>
                                    <NumberCard
                                        className='app-today-nc'
                                        header={t(LANGUAGE_KEYS.BLS_INTERNALDASHBOARD_CONTENT_TITLE_APPLICATIONSTODAY)}
                                        description=''
                                        number={dataApplicationsToday}
                                    // to='/Inbox'
                                    />
                                </Col>
                                <Col xl={3} className='card-col'>
                                    <NumberCard
                                        className='pending-app-nc'
                                        header={t(LANGUAGE_KEYS.BLS_INTERNALDASHBOARD_CONTENT_TITLE_PENDINGAPPLICATIONS)}
                                        description=''
                                        number={dataPendingApplications}
                                    // to='/Inbox'
                                    />
                                </Col>
                                <Col xl={3} className='card-col'>
                                    <NumberCard
                                        className='licence-today-nc'
                                        header={t(LANGUAGE_KEYS.BLS_INTERNALDASHBOARD_CONTENT_TITLE_SERVICEAPPLICATIONISSUEDTODAY)}
                                        description=''
                                        number={dataLicenceIssuedToday}
                                    // to='/Inbox'
                                    />
                                </Col>
                                <Col xl={3} className='card-col'>
                                    <NumberCard
                                        className='revenue-today-nc'
                                        header={t(LANGUAGE_KEYS.BLS_INTERNALDASHBOARD_CONTENT_TITLE_REVENUETODAY)}
                                        description=''
                                        number={dataRevenueToday}
                                    // to='/Inbox'
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={12} className='stats-col'>
                                    <Row>
                                        <Col xl={6}>
                                            <ChartCard
                                                className='dbchartcard-pie'
                                                header={t(LANGUAGE_KEYS.BLS_INTERNALDASHBOARD_CONTENT_TITLE_PENDINGAPPLICATIONS)}
                                                description=''
                                            >
                                                <ActivePieChart
                                                    data={dataPendingApplicationChart}
                                                    colors={colorPendingApp}
                                                    pieChartWidth={485}
                                                    pieChartHeight={300}
                                                    pieCX='50%'
                                                    pieCY='50%'
                                                    pieInnerRadius='63%'
                                                    pieOuterRadius='80%'
                                                    pieDataKey='value'
                                                    pieLabelListDataKey='value'
                                                    pieLabelListPosition='inside'
                                                    pieLegendIconSize={17}
                                                    pieLegendVerticalAlign='bottom'
                                                    pieLegendHeight={30}
                                                    pieLegendWidth={480}
                                                    pieLegendAlign='center'
                                                    pieLegendMargin={{
                                                        top: 10,
                                                        right: 0,
                                                        left: 0,
                                                        bottom: 0
                                                    }}
                                                />
                                            </ChartCard>
                                        </Col>
                                        <Col xl={6}>
                                            <ChartCard
                                                className='dbchartcard-pie'
                                                header={t(LANGUAGE_KEYS.BLS_INTERNALDASHBOARD_CONTENT_TITLE_REVENUEBREAKDOWN)}
                                                description=''
                                            >
                                                <ActivePieChart
                                                    data={dataRevenueBreakDown}
                                                    colors={colorRevenue}
                                                    pieChartWidth={485}
                                                    pieChartHeight={300}
                                                    pieCX='50%'
                                                    pieCY='50%'
                                                    pieInnerRadius='63%'
                                                    pieOuterRadius='80%'
                                                    pieDataKey='value'
                                                    pieLabelListDataKey='value'
                                                    pieLabelListPosition='inside'
                                                    pieLegendIconSize={17}
                                                    pieLegendVerticalAlign='bottom'
                                                    pieLegendHeight={30}
                                                    pieLegendWidth={480}
                                                    pieLegendAlign='center'
                                                    pieLegendMargin={{
                                                        top: 10,
                                                        right: 0,
                                                        left: 0,
                                                        bottom: 0
                                                    }}
                                                />

                                            </ChartCard>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={12} className='stats-col-bar-chart'>
                                    <ChartCard
                                        className='dbchartcard'
                                        header={t(LANGUAGE_KEYS.BLS_INTERNALDASHBOARD_CONTENT_TITLE_DAILYISSUEDSERVICEAPPLICATIONS)}
                                        description=''
                                    >
                                        <StackedBarChart
                                            data={dataDailyIssuedLicences}
                                            barChartWidth={970}
                                            barChartHeight={500}
                                            barChartMargin={{
                                                top: 40,
                                                right: 5,
                                                left: 5,
                                                bottom: 20
                                            }}
                                            cartesianGridStrokeDashArray='3 3'
                                            xAxisDataKey='Date'
                                            xAxisLabelFontSize={18}
                                            xAxisLabelValue= {t(LANGUAGE_KEYS.BLS_INTERNALDASHBOARD_CONTENT_TITLE_BARCHARTDATES)}
                                            xAxisLabelOffset={5}
                                            yAxisDomain={[0, 'dataMax + 1']}
                                            yAxisScale='linear'
                                            yAxisAxisLine={false}
                                            yAxisLabel={{
                                                offset: 20,
                                                fontSize: 20,
                                                value: t(LANGUAGE_KEYS.BLS_INTERNALDASHBOARD_CONTENT_TITLE_BARCHARTSERVICEAPPLICATIONISSUED),
                                                angle: -90,
                                                position: 'insideLeft'
                                            }}
                                            legendIconType='rect'
                                            legendIconSize={20}
                                            legendVerticalAlign='top'
                                            legendHeight={36}
                                            legendWrapperStyle={{ top: 0, left: 0 }}
                                            toolTipOffset={5}
                                            barMaxBarSize={60}
                                            barStackId='a'
                                            labelListPosition='center'
                                        />
                                    </ChartCard>
                                </Col>
                            </Row>
                        </Container>
                    </Row>
                </Layout>
            </React.Fragment>
        );
    }
}
export default withTranslation()(DashBoard);
