// Data Source options
export const DATA_SOURCE_VALUES = "Values";
export const DATA_SOURCE_VALUES_ID = "values";
export const DATA_SOURCE_RESOURCE = "Resource";
export const DATA_SOURCE_RESOURCE_ID = "resource";
export const DATA_SOURCE_CODE_CAT = "Code Category";
export const DATA_SOURCE_CODE_CAT_ID = "codeCategory";
export const DATA_SOURCE_APP_CONFIG = "Application Configuration";
export const DATA_SOURCE_APP_CONFIG_ID = "appConfig";

// Field input
export const FIELD_INPUT_TYPE_ARRAY = [
    "Text",
    "Number",
    "Password",
    "Email",
    "Currency",
    "DateTime",
    "Day",
    "Time",
    "Textarea",
    "Select",
    "Selectbox",
    "Radio",
    "Checkbox",
    "Submit",
    "Paragraph",
    "File",
    "Datagrid"
];

export const FIELD_INPUT_TYPE_LENGTH_REQUIRED = [
    "Text",
    "Password",
    "Email",
    "Textarea",
    "Paragraph",
]

// Comparator options
export const COMPARATOR_OPTIONS = [
    "=",
    ">",
    ">=",
    "<",
    "<=",
    "!="
]

// Form.IO Instance
const FORMIO_URL = process.env.REACT_APP_FORMIO_URL;
export const FORMIO_LOGIN_PATH = `${FORMIO_URL}/user/login`;
export const FORMIO_LOGIN_USERNAME = "admin@example.com";
export const FORMIO_LOGIN_PASSWORD = "CHANGEME";
export const FORMIO_GET_RESOURCES = `${FORMIO_URL}/form?type=resource`;

// DSP Data Source
const DSP_FORMGENERATOR_API = process.env.REACT_APP_DSP_FORMGENERATOR_API;
export const DSP_CODE_CATEGORY = `${DSP_FORMGENERATOR_API}/DataSource/GetCodeCategory`;
export const DSP_APP_CONFIG = `${DSP_FORMGENERATOR_API}/DataSource/GetAppConfig`;
export const DSP_SUBMISSION = `${DSP_FORMGENERATOR_API}/Submission/SubmitForm`;

// Generic Message
export const SUCCESSFUL_MESSAGE_SAVE_FORM = "Form downloaded successfully!";
export const SUCCESSFUL_MESSAGE_SUBMIT_FORM = "Form has been generated.";
export const FAILED_MESSAGE_API_UNAVAILABLE_SUBMIT_FORM = "Form Generator is currently inaccessible, please contact the administrator for assistance.";
export const FAILED_MESSAGE_EXISTING_FORM = "The form name has already been generated and exists in Form.IO. To regenerate the form, choose a different form name.";
export const SUCCESSFUL_ALERT_VARIANT = "success";
export const FAILED_ALERT_VARIANT = "danger";