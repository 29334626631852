import PropTypes from 'prop-types';
import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { LAYOUT_TYPE } from '../App/AppSettings';
import Footer from '../Footer';
import Title from '../Title';

class Layout extends React.Component {

    renderLayout(param) {
        switch (param) {
            case 1:
            default:
                return (
                    <React.Fragment>
                        {this.props.title && (<Title className={"first body-title body-title-text " + this.props.titleClassName + " " + (this.props.titleChildren ? "title-with-children" : "")} titleValue={this.props.title}>
                            {this.props.titleChildren}
                        </Title>)}

                        {this.props.children}
                        {this.props.footer ? <Footer footerExtension={this.props.footerExtension} /> : ""}
                    </React.Fragment>
                );
            case 2:
                return (
                    <React.Fragment>
                        <div className={"background-content"}>
                            <Card className="panel-card">
                                {this.props.title && (<Title className={"second body-title body-title-text " + this.props.titleClassName + " " + (this.props.titleChildren ? "title-with-children" : "")} titleValue={this.props.title}>
                                    {this.props.titleChildren}
                                </Title>)}

                                <CardBody className="start-card-body">
                                    {this.props.children}
                                </CardBody>
                            </Card>
                            <Footer footerExtension={this.props.footerExtension} />
                        </div>
                    </React.Fragment>
                );
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.renderLayout(this.props.type)}
            </React.Fragment>
        );
    }
}

// PropTypes: For prop validation
Layout.propTypes = {
    type: PropTypes.number,
    footer: PropTypes.bool
};

// PropTypes: Defaulting value for optional props
Layout.defaultProps = {
    type: LAYOUT_TYPE.FORM,
    title: "",
    footer: false,
};

export default Layout;