import React, {Component} from 'react';
import Oidc from 'oidc-client'
import { withRouter } from 'react-router-dom'
import { deleteCookie } from '../SmartMe/Actions'
import { REDIRECT_URI } from '../SmartMe/Constants'

class Callback extends Component {
  constructor() {
    super();
    this.state = { 
      path: 'Error/Page404'
    }
  }
  
  componentDidMount() {
    let self = this;
     
    new Oidc.UserManager().signinRedirectCallback().then(function (user){
      deleteCookie(REDIRECT_URI);
      self.props.history.replace({pathname: user.state.returnUrl, state: { prevPath: user.state.referrerAppUrl }});
    });
  }

  render(){
      return (
        <div></div>
      );
  }
}

export default withRouter(Callback);