import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Container, Row } from 'reactstrap';
import { LAYOUT_TYPE, LICENCE_CONFIG_GET_LICENCE_VERSION_BY_ID } from '../../App/AppSettings';
import Layout from '../../Layout';
import { CONTENT_TYPE, formParams, getParams, navigateTo } from '../../Shared/Actions';
import { DATE_TIME_FORMAT } from '../../Shared/Constants';
import { LANGUAGE_KEYS } from '../../Shared/Constants/LanguageKeys';
import { ActionButton, DataTable } from '../../Shared/DataTable';
import { getCustomComponents } from '../../Shared/DataTable/BaseTable.js';
import { useAsyncFetch } from '../../Shared/Fetch';
import { AccordionContainer, Plaintext, SMARTForm } from '../../Shared/Forms';
import history from '../../Shared/History';
import { ModalConfirm } from '../../Shared/Modal';
import PageFooter from '../../Shared/PageFooter';
import { getLangKey, getLicenceTypeNameTranslationKey } from '../DisplayComponents/DisplayUtils';

const FORM_CONTEXT = 'LicenceConfigView';
const IS_UPPER = true;

const INITIAL_VALUES = {
    LicenceDependencyType: '',
    LicenceName: '',
    FAQQuestion: '',
    FAQAnswer: '',
    FAQSequence: '',
    FaqId: 0,
};

export default function LicenceConfigMgmtView() {
    const { t } = useTranslation();
    const { LicenceType } = history.location.state;
    const { Id, LicenceTypeId } = LicenceType;
    const [modalState, setModalState] = useState(null);
    const [dependencyValues, setDependencyValues] = useState(INITIAL_VALUES);
    const [faqValues, setFaqValues] = useState(INITIAL_VALUES);
    const [licenceTypeNameTranslationKey, setLicenceTypeNameTranslationKey] = useState("");

    const previousPage = history.location.state.from;

    const VIEW_FAQ_MODAL = {
        hasModal: true,
        name: 'ViewFAQ',
        modalHeader: t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_CONTENT_MODAL_VIEWFAQ)
    };

    const VIEW_DEPENDENCY_MODAL = {
        hasModal: true,
        name: 'ViewDependency',
        modalHeader: t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_CONTENT_MODAL_VIEWDEPENDENCY)
    };

    const SECTION_NAMES = {
        LICENCE_INFORMATION: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_CONTENT_SECTION_SERVICEINFORMATION), status: true },
        LICENCE_DEPENDENCY: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_CONTENT_SECTION_SERVICEDEPENDENCY), status: true },
        FAQ: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_CONTENT_SECTION_FAQ), status: true },
        PUBLISH_LICENCE: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_CONTENT_SECTION_PUBLISHSERVICE), status: true }
    };

    const [data, setData] = useState(null);
    let retrieveUrl = LICENCE_CONFIG_GET_LICENCE_VERSION_BY_ID;
    retrieveUrl += Id;
    const response = useAsyncFetch(retrieveUrl, getParams());

    useEffect(() => {
        const fetchData = async () => {
            const { Data, IsSuccess } = response.body;
            if (response.success && IsSuccess) {

                if (Data.LicenceInfo) {
                    const { ValidityYear, ValidityMonth, ValidityDay } = Data.LicenceInfo;
                    Data.LicenceInfo.ValidityPeriod =
                        (ValidityYear ? `${ValidityYear}${' ' + t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_CONTENT_HELPTEXT_YEAR) + ' '}` : '') +
                        (ValidityMonth ? `${ValidityMonth}${' ' + t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_CONTENT_HELPTEXT_MONTH) + ' '}` : '') +
                        (ValidityDay ? `${ValidityDay}${' ' + t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_CONTENT_HELPTEXT_DAY) + ' '}` : '');
                }
                Data.LicenceTypeId = LicenceTypeId;
                setLicenceTypeNameTranslationKey(getLicenceTypeNameTranslationKey(Data.LicenceInfo.LicenceName));
                setData(Data);
            }
        };
        if (response) {
            fetchData();
        }
    }, [response, LicenceTypeId, t]);

    const toggleModal = modalName => {
        setModalState((modalState !== modalName) ? modalName : null);
    };

    const viewDependencyItem = data => {
        setDependencyValues(data);
        toggleModal(VIEW_DEPENDENCY_MODAL.name);
    };

    const viewFaqItem = data => {
        setFaqValues(data);
        toggleModal(VIEW_FAQ_MODAL.name);
    };

    const setDependencyType = (rowData) => t(getLangKey(LANGUAGE_KEYS.BLS_CODE_CATEGORY_TITLE_KEY, rowData.DependencyCategory, rowData.DependencyType));
    const getDependencyType = (rowData) => t(getLangKey(LANGUAGE_KEYS.BLS_CODE_CATEGORY_TITLE_KEY, rowData.DependencyCategory, rowData.DependencyType));

    const setLicenceName = (rowData) => {
        const getDependencyLicenceTranslationKey = getLicenceTypeNameTranslationKey(rowData.LicenceName);
        return t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY, getDependencyLicenceTranslationKey, rowData.LicenceTypeId));
    };
    const getLicenceName = (rowData) => {
        return t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY, licenceTypeNameTranslationKey, rowData.LicenceTypeId));
    }

    const renderDependencyGrid = () => {
        const translatedDependencyValue = t(getLangKey(LANGUAGE_KEYS.BLS_CODE_CATEGORY_TITLE_KEY, dependencyValues.DependencyCategory, dependencyValues.DependencyType));
        const getDependencyLicenceTranslationKey = getLicenceTypeNameTranslationKey(dependencyValues.LicenceName);
        const translatedLicenceNameValue = t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY, getDependencyLicenceTranslationKey, dependencyValues.LicenceTypeId));

        return (
            <React.Fragment>
                <div className="smart-list">
                    <DataTable
                        pageSize="5"
                        minFilterChars='2'
                        data={data.DependencyData}
                        noResultsMessage={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_MESSAGE_PLACEHOLDER_NODEPENDENCY)}
                        components={getCustomComponents()}
                        columns={
                            {
                                RunningNumber: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_TABLE_TITLE_NUMBER), width: '5%' },
                                DependencyType: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_TABLE_TITLE_DEPENDENCYTYPE), width: '25%', DBkey: 'DependencyType', setContent: setDependencyType, getTranslatedString: getDependencyType },
                                LicenceName: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_TABLE_TITLE_SERVICENAME), width: '60%', DBkey: 'LicenceName', setContent: setLicenceName, getTranslatedString: getLicenceName },
                                Actions: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_TABLE_TITLE_ACTION), width: '10%' }
                            }
                        }
                        renderActions={({ RowData }) => (
                            <React.Fragment>
                                <ActionButton tooltip={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_CONTENT_BUTTON_VIEW)} color="forward" onClick={() => viewDependencyItem(RowData)}>
                                    <FontAwesomeIcon icon={faEye} />
                                </ActionButton>
                            </React.Fragment>
                        )}

                    />
                    <ModalConfirm
                        isOpen={(modalState === VIEW_DEPENDENCY_MODAL.name)}
                        contentHeader={VIEW_DEPENDENCY_MODAL.modalHeader}
                        cancelText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_CONTENT_BUTTON_CANCEL)}
                        cancelCallback={() => toggleModal(VIEW_DEPENDENCY_MODAL.name)}
                        contentBody={
                            <div>
                                <Plaintext
                                    name="DependencyType"
                                    value={translatedDependencyValue}
                                    label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_FIELD_DEPENDENCYTYPE)}
                                    IS_UPPER={IS_UPPER}
                                />
                                <Plaintext
                                    name="LicenceName"
                                    value={translatedLicenceNameValue}
                                    label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_FIELD_SERVICENAME)}
                                    IS_UPPER={IS_UPPER}
                                />
                            </div>
                        }
                    />
                </div>
            </React.Fragment>
        );
    };

    const setAgencyName = agencyId => t(getLangKey(LANGUAGE_KEYS.BLS_LICENCEAGENCY_AGENCY_AGENCYNAME, agencyId));

    const licenceName = (licenceTypeId) => {
        if (licenceTypeId) {
            return t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY, licenceTypeNameTranslationKey, licenceTypeId));
        } else {
            return '';
        }
    };

    const licenceDescription = (licenceDefinitionId) => {
        if (licenceDefinitionId) {
            return t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCEDEFINITION_LICENCEDESCRIPTION_KEY, licenceTypeNameTranslationKey, licenceDefinitionId));
        } else {
            return '';
        }
    };

    const setFeeDesc = (feeDescId) => {
        if(feeDescId) {
            return t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCEDEFINITION_LICENCEFEEDESCRIPTION_KEY, licenceTypeNameTranslationKey, feeDescId));
        }
        else return '';
    }

    const setQuestionTranslatedString = (rowData) => {
        const translatedString = t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCEDEFINITIONFAQ_QUESTION_KEY, licenceTypeNameTranslationKey, rowData.FaqId));
        return <>{translatedString}</>
    }

    const setAnswerTranslatedString = (rowData) => {
        const translatedString = t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCEDEFINITIONFAQ_ANSWER_KEY, licenceTypeNameTranslationKey, rowData.FaqId));
        return <>{translatedString}</>
    }

    const getQuestionTranslatedString = (rowData) => {
        return t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCEDEFINITIONFAQ_QUESTION_KEY, licenceTypeNameTranslationKey, rowData.FaqId));
    }

    const getAnswerTranslatedString = (rowData) => {
        return t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCEDEFINITIONFAQ_ANSWER_KEY, licenceTypeNameTranslationKey, rowData.FaqId));
    }

    const renderFAQGrid = () => {
        return (
            <React.Fragment>
                <div className="smart-list">
                    <DataTable
                        pageSize="5"
                        minFilterChars='2'
                        data={data.FaqData}
                        noResultsMessage={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_MESSAGE_PLACEHOLDER_NOFAQ)}
                        components={getCustomComponents()}
                        columns={
                            {
                                FaqId: { title: 'FAQId', DBkey: 'FaqId', visible: false, sortable: false},
                                RunningNumber: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_TABLE_TITLE_NUMBER), width: '5%' },
                                Question: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_TABLE_TITLE_QUESTIONS), width: '35%', DBkey: 'Question', setContent: setQuestionTranslatedString, getTranslatedString: getQuestionTranslatedString },
                                Answer: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_TABLE_TITLE_ANSWER), width: '30%', DBkey: 'Answer', setContent: setAnswerTranslatedString, getTranslatedString: getAnswerTranslatedString },
                                Sequence: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_TABLE_TITLE_DISPLAYSEQUENCE), width: '20%', DBkey: 'Sequence' },
                                Actions: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_TABLE_TITLE_ACTION), width: '10%' }
                            }
                        }
                        renderActions={({ RowData }) => (
                            <React.Fragment>
                                <ActionButton tooltip={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_CONTENT_BUTTON_VIEW)} color="forward" onClick={() => viewFaqItem(RowData)}>
                                    <FontAwesomeIcon icon={faEye} />
                                </ActionButton>
                            </React.Fragment>
                        )}
                    />
                    <ModalConfirm
                        isOpen={(modalState === VIEW_FAQ_MODAL.name)}
                        contentHeader={VIEW_FAQ_MODAL.modalHeader}
                        cancelText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_CONTENT_BUTTON_CANCEL)}
                        cancelCallback={() => toggleModal(VIEW_FAQ_MODAL.name)}
                        contentBody={
                            <div>
                                <Plaintext
                                    name="Question"
                                    value={getQuestionTranslatedString(faqValues)}
                                    label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_FIELD_QUESTION)}
                                    IS_UPPER={IS_UPPER}
                                />
                                <Plaintext
                                    name="Answer"
                                    value={getAnswerTranslatedString(faqValues)}
                                    label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_FIELD_ANSWER)}
                                    IS_UPPER={IS_UPPER}
                                />
                                <Plaintext
                                    name="Sequence"
                                    value={faqValues.Sequence}
                                    label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_FIELD_DISPLAYSEQUENCE)}
                                    IS_UPPER={IS_UPPER}
                                />
                            </div>
                        }
                    />
                </div>
            </React.Fragment>
        );
    };

    const setEffectiveFromDate = () => {
        if (Date.parse(data.LicenceInfo.EffectiveFromDate) > Date.parse(DATE_TIME_FORMAT.DEFAULT_MIN_DATE)) {
            return data.LicenceInfo.EffectiveFromDate;
        } else {
            return DATE_TIME_FORMAT.DEFAULT_EMPTY_DATE;
        }
    };

    const setEffectiveToDate = () => {
        if (Date.parse(data.LicenceInfo.EffectiveToDate) > Date.parse(DATE_TIME_FORMAT.DEFAULT_MIN_DATE)) {
            return data.LicenceInfo.EffectiveToDate;
        } else {
            return DATE_TIME_FORMAT.DEFAULT_EMPTY_DATE;
        }
    };

    return (
        <React.Fragment>
            <Layout type={LAYOUT_TYPE.FORM} title={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_CONTENT_TITLE_VIEWSERVICE)}>
                <SMARTForm
                    formContext={FORM_CONTEXT}
                    formParams={formParams('POST', CONTENT_TYPE.JSON)}
                    isDummy={false}
                    resetFormAfterSubmit={true}
                    formValues={data}
                >
                    {({ values, toggleSection }) => (
                        <React.Fragment>
                            <Container className="full-width">
                                <Row className="body-content">
                                    <Col>
                                        {values &&
                                            <>
                                                <AccordionContainer
                                                    title={SECTION_NAMES.LICENCE_INFORMATION.title}
                                                    active={SECTION_NAMES.LICENCE_INFORMATION.status}
                                                    onClick={toggleSection}
                                                    isIndividual={true}
                                                    sections={SECTION_NAMES
                                                    }
                                                >
                                                    <Plaintext
                                                        label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_FIELD_SERVICENAME)}
                                                        name="LicenceName"
                                                        value={licenceName(values.LicenceTypeId)}
                                                        isUpper={IS_UPPER}
                                                    />
                                                    <Plaintext
                                                        name="LicenceDescription"
                                                        value={licenceDescription(values.LicenceDefinitionId)}
                                                        label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_FIELD_SERVICEDESCRIPTION)}
                                                        IS_UPPER={IS_UPPER}
                                                    />
                                                    <Plaintext
                                                        name="Agency"
                                                        value={setAgencyName(values.LicenceInfo.AgencyId)}
                                                        label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_FIELD_AGENCY)}
                                                        IS_UPPER={IS_UPPER}
                                                    />
                                                    <Plaintext
                                                        name="IsOnline"
                                                        value={values.LicenceInfo.IsOnline ?
                                                            t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_FIELD_YES) :
                                                            t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_FIELD_NO)
                                                        }
                                                        label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_FIELD_ISONLINE)}
                                                        isUpper={IS_UPPER}
                                                    />
                                                    {!values.LicenceInfo.IsOnline &&
                                                        <Plaintext
                                                            name="ExternalLink"
                                                            value={values.LicenceInfo.ExternalLink}
                                                            label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_FIELD_EXTERNALLINK)}
                                                            isUpper={IS_UPPER}
                                                        />
                                                    }
                                                    {values.LicenceInfo.IsOnline &&
                                                        <div>
                                                            <Plaintext
                                                                name="FeeDescription"
                                                                value={setFeeDesc(values.LicenceDefinitionId)}
                                                                label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_FIELD_SERVICEFEEDESC)}
                                                                isUpper={IS_UPPER}
                                                            />
                                                            <Plaintext
                                                                name="ProcessTimeDescription"
                                                                value={values.LicenceInfo.ProcessTimeDescription}
                                                                label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_FIELD_SERVICEPROCESSTIME)}
                                                                isUpper={IS_UPPER}
                                                            />
                                                            <Plaintext
                                                                name="LicencePrefix"
                                                                value={values.LicenceInfo.LicencePrefix}
                                                                label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_FIELD_SERVICEPREFIX)}
                                                                isUpper={IS_UPPER}
                                                            />
                                                            <Plaintext
                                                                name="RFAResponsePeriod"
                                                                value={values.LicenceInfo.RFAResponsePeriod}
                                                                label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_FIELD_RFARESPONSEPERIOD)}
                                                                isUpper={IS_UPPER}
                                                            />
                                                            <Plaintext
                                                                name="PaymentPeriod"
                                                                value={values.LicenceInfo.PaymentPeriod}
                                                                label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_FIELD_DAYSTOMAKEPAYMENT)}
                                                                isUpper={IS_UPPER}
                                                            />
                                                            <Plaintext
                                                                name="TerminationMinPeriod"
                                                                value={values.LicenceInfo.TerminationMinPeriod}
                                                                label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_FIELD_TERMINATIONMINPERIOD)}
                                                                isUpper={IS_UPPER}
                                                            />
                                                            <Plaintext
                                                                name="ValidityPeriod"
                                                                value={values.LicenceInfo.ValidityPeriod}
                                                                label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_FIELD_SERVICEVALIDITYPERIOD)}
                                                                isUpper={IS_UPPER}
                                                            />
                                                            <Plaintext
                                                                name="IsRenewable"
                                                                value={values.LicenceInfo.IsRenewable ?
                                                                    t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_FIELD_YES) :
                                                                    t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_FIELD_NO)
                                                                }
                                                                label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_FIELD_CANBERENEWED)}
                                                                isUpper={IS_UPPER}
                                                            />
                                                            {values.LicenceInfo.IsRenewable &&
                                                                <div>
                                                                    <Plaintext
                                                                        name="RenewalPeriod"
                                                                        value={values.LicenceInfo.RenewalPeriod}
                                                                        label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_FIELD_RENEWALPERIOD)}
                                                                        isUpper={IS_UPPER}
                                                                    />
                                                                    <Plaintext
                                                                        name="LatePenaltyPeriod"
                                                                        value={values.LicenceInfo.LatePenaltyPeriod}
                                                                        label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_FIELD_LATEPENALTYPERIOD)}
                                                                        isUpper={IS_UPPER}
                                                                    />
                                                                </div>
                                                            }
                                                        </div>
                                                    }
                                                </AccordionContainer>
                                                <>
                                                    {values.LicenceInfo.IsOnline &&
                                                        <div>
                                                            <AccordionContainer
                                                                title={SECTION_NAMES.LICENCE_DEPENDENCY.title}
                                                                active={SECTION_NAMES.LICENCE_DEPENDENCY.status}
                                                                onClick={toggleSection}
                                                                isIndividual={true}
                                                                sections={SECTION_NAMES}
                                                            >
                                                                {renderDependencyGrid()}
                                                            </AccordionContainer>
                                                            <AccordionContainer
                                                                title={SECTION_NAMES.FAQ.title}
                                                                active={SECTION_NAMES.FAQ.status}
                                                                onClick={toggleSection}
                                                                isIndividual={true}
                                                                sections={SECTION_NAMES}
                                                            >
                                                                {renderFAQGrid()}
                                                            </AccordionContainer>
                                                        </div>
                                                    }
                                                </>
                                                <AccordionContainer
                                                    title={SECTION_NAMES.PUBLISH_LICENCE.title}
                                                    active={SECTION_NAMES.PUBLISH_LICENCE.status}
                                                    onClick={toggleSection}
                                                    isIndividual={true}
                                                    sections={SECTION_NAMES}
                                                >
                                                    <Plaintext
                                                        name="EffectiveFromDate"
                                                        value={setEffectiveFromDate()}
                                                        label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_FIELD_EFFECTIVEFROM)}
                                                        isUpper={IS_UPPER}
                                                    />
                                                    <Plaintext
                                                        name="EffectiveToDate"
                                                        value={setEffectiveToDate()}
                                                        label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_FIELD_EFFECTIVETO)}
                                                        isUpper={IS_UPPER}
                                                    />
                                                </AccordionContainer>
                                            </>
                                        }
                                    </Col>
                                </Row>
                            </Container>
                            <PageFooter type={LAYOUT_TYPE.FORM}>
                                <Button color="backward" size="sm" type="button" onClick={() => navigateTo(previousPage, { LicenceType: LicenceType })}>
                                    {t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_NAVIGATION_BUTTON_BACK)}
                                </Button>
                            </PageFooter>
                        </React.Fragment>
                    )}
                </SMARTForm>
            </Layout>
        </React.Fragment>
    );
}



