import React from 'react';
import PropTypes from 'prop-types';
import { TabContainer, AccordionContainer, WizardContainer } from '../Shared/Forms';
import { SMARTSectionContext } from '../Shared/Forms/SMARTContext';
import { SECTION_WRAPPER_TYPES } from '../Shared/Constants';


class Section extends React.Component {

    renderSection({ type, sectionState, sectionName, toggleSection, tabId, container }) {
        switch (type) {
            case SECTION_WRAPPER_TYPES.TAB:
            default:
                return ( // Tab 
                    <React.Fragment>
                        <TabContainer
                            tab={sectionName}
                        >
                            <SMARTSectionContext.Provider value={sectionName}>
                                {this.props.children}
                            </SMARTSectionContext.Provider>
                        </TabContainer>
                    </React.Fragment>
                );

            case SECTION_WRAPPER_TYPES.ACCORDION:
                return ( // Accordion 
                    <React.Fragment>
                        <AccordionContainer
                            title={sectionName}
                            active={sectionState === sectionName}
                            onClick={toggleSection}
                        >
                            <SMARTSectionContext.Provider value={sectionName}>
                                {this.props.children}
                            </SMARTSectionContext.Provider>
                        </AccordionContainer>
                    </React.Fragment>
                );
            case SECTION_WRAPPER_TYPES.WIZARD:
                return ( // Wizard 
                    <React.Fragment>
                        <WizardContainer
                            tab={sectionName}
                            tabId={tabId}
                            container={container}
                        >
                            <SMARTSectionContext.Provider value={sectionName}>
                                {this.props.children}
                            </SMARTSectionContext.Provider>
                        </WizardContainer>
                    </React.Fragment>
                );
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.renderSection(this.props)}
            </React.Fragment>
        );
    }
}

// PropTypes: For prop validation
Section.propTypes = {
    type: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    sectionName: PropTypes.string,
    toggleSection: PropTypes.func
};

// PropTypes: Defaulting value for optional props
Section.defaultProps = {
    type: SECTION_WRAPPER_TYPES.TAB,
    sectionName: ""
};

export default Section;