import React from 'react';
import { withTranslation } from 'react-i18next';
import { GET_FILE_URL, LAYOUT_TYPE, TABLE_LAYOUT } from '../../.././App/AppSettings';
import Section from '../../../Section';
import SubSection from '../../../Section/SubSection';
import { FileUpload, Plaintext } from '../../../Shared/Forms';
import { BigPreviewTable, SmallPreviewTable } from '../../FormComponents/TableComponent';
import { getPremiseOperatingAddress } from '../../InternalEnquiryList/Utils';
import { LANGUAGE_KEYS, getLangKey } from '../../../Shared/Constants/LanguageKeys';
import utils from 'formiojs/utils';
import { fetchRequest, getParams } from '../../../Shared/Actions';

const IS_UPPER = true;
const LABEL_SIZE = 3;
const INPUT_SIZE = 9;


class ApplicationInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //Check whether webpage is loaded
            isLoaded: false,
            licence: {}
        };
    }

    formIOFieldsList = [];
    formIOSections = [];
    formIOFields = [];
    formIODatagridFields = [];
    formIODatagrids = [];
    formIODatagridFieldValues = [];
    
    getFormIODataAsync = async (formIOUrl) => {
        const formIOData = await fetchRequest(formIOUrl, getParams(), false);
        const flattened = utils.flattenComponents(formIOData.body.components, true);
        const flattenedArray = Object.entries(flattened).filter(form => !form[0].includes("column"));
        this.formIOFieldsList = flattenedArray.map(([key, value]) => ({
            label: value.title ?? value.label,
            value: key,
            type: value.type
        }));
        this.mapFormIOFieldsToSections(this.formIOFieldsList);
    }

    mapFormIOFieldsToSections = (flattenedArray) => {
        flattenedArray.forEach(element => {
            if (element.type === "panel" && !this.formIOSections.find(section => section.value === element.value)) {
                this.formIOSections.push(element);
            } else if (element.type === "datagrid" && !this.formIODatagrids.find(datagrid => datagrid.value === element.value)) {
                const parentSection = this.formIOSections.find(section => element.value.includes(section.value));
                if (parentSection && !this.formIODatagrids.find(existingdatagrid => existingdatagrid[0].value === element.value)) {
                    this.formIODatagrids.push([element, parentSection.value]);
                }
            } else if (element.type !== "panel" && element.type !== "datagrid" && !this.formIOFields.find(field => field[0].value === element.value)) {
                const parentGrid = this.formIODatagrids.find(datagrid => element.value.includes(datagrid[0].value));
                if (parentGrid) {
                    if (!this.formIODatagridFields.find(field => field[0].value === element.value))
                        this.formIODatagridFields.push([element, parentGrid[0].value]);
                } else {
                    this.formIOFields.push(([element, this.formIOSections.length - 1]));
                }
            } 
        });
    }

    mapFormIODatagridValueToField = (values) => {
        if (this.formIODatagridFieldValues.length < Object.keys(values).filter(key => key.includes('Datagrid')).length) {
            for (const individualFieldKey in values) {
                const matchingDatagridField = this.formIODatagridFields.find(field => field[0].value.split('.')[field[0].value.split('.').length - 1] === 
                                                                                    individualFieldKey.split('.')[individualFieldKey.split('.').length - 1]);
                if (matchingDatagridField) {
                    this.formIODatagridFieldValues.push([matchingDatagridField[0], matchingDatagridField[1], values[individualFieldKey]]); 
                }
            }
        }
    }

    render() {
        const { t, values, toggleSection, sectionState, sectionName, applicationDetails, licence } = this.props;
        const applicationType = applicationDetails.ApplicationType;
        const licenceExist = licence && licence.LicenceData && licence.LicenceData.GeneralInformation && licence.LicenceData.LicenceInformation;
        const showLicenceDetails = applicationType !== 'Apply' && licenceExist;
        this.getFormIODataAsync(applicationDetails.FormDefinition.FormIOUrl);
        this.mapFormIODatagridValueToField(values);

        return (
            <Section
                type={LAYOUT_TYPE.FORM_CONTENT}
                sectionState={sectionState}
                // TODO: Consult Ker Yu if this one needs to be a special key cause it is static and not dynaForm Json
                sectionName={sectionName}
                toggleSection={toggleSection}
            >
                {showLicenceDetails && <SubSection orientation="left"
                    title={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEDETAILS_CONTENT_MENU_LICENCEOVERVIEW)}
                >
                    <>
                        <Plaintext
                            label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEDETAILS_CONTENT_INFORMATION_SERVICENAME)}
                            name="LicenceName"
                            className="overview-margin"
                            value={licence.LicenceName}
                            labelSize={LABEL_SIZE}
                            inputSize={INPUT_SIZE}
                        />
                        <Plaintext
                            label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEDETAILS_CONTENT_INFORMATION_SERVICENUM)}
                            name="LicenceNumber"
                            className="overview-margin"
                            value={licence.LicenceNumber}
                            labelSize={LABEL_SIZE}
                            inputSize={INPUT_SIZE}
                        />
                        <Plaintext
                            label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEDETAILS_CONTENT_INFORMATION_APPLICANT)}
                            name="Licensee"
                            className="overview-margin"
                            value={licence.LicenceData.GeneralInformation.GI_Name}
                            labelSize={LABEL_SIZE}
                            inputSize={INPUT_SIZE}
                        />
                        <Plaintext
                            label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEDETAILS_CONTENT_INFORMATION_SERVICESTATUS)}
                            name="LicenceStatus"
                            className="overview-margin"
                            value={licence.LicenceStatus}
                            labelSize={LABEL_SIZE}
                            inputSize={INPUT_SIZE}
                        />
                        <Plaintext
                            label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEDETAILS_CONTENT_INFORMATION_AGENCY)}
                            name="Agency"
                            className="overview-margin"
                            value={licence.Agency}
                            labelSize={LABEL_SIZE}
                            inputSize={INPUT_SIZE}
                        />
                        {licence.LicenceData.GeneralInformation.GI_EntitydetailsEntityname &&
                            <Plaintext
                                label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEDETAILS_CONTENT_INFORMATION_ENTITY)}
                                name="Entity"
                                className="overview-margin"
                                value={licence.LicenceData.GeneralInformation.GI_EntitydetailsEntityname}
                                labelSize={LABEL_SIZE}
                                inputSize={INPUT_SIZE}
                            />
                        }
                        <Plaintext
                            label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEDETAILS_CONTENT_INFORMATION_ISSUEDDATE)}
                            name="IssuedDate"
                            className="overview-margin"
                            value={licence.IssuedDate}
                            labelSize={LABEL_SIZE}
                            inputSize={INPUT_SIZE}
                        />
                        <Plaintext
                            label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEDETAILS_CONTENT_INFORMATION_EXPIRYDATE)}
                            name="ExpiryDate"
                            className="overview-margin"
                            value={licence.ExpiryDate}
                            labelSize={LABEL_SIZE}
                            inputSize={INPUT_SIZE}
                        />
                        <Plaintext
                            label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEDETAILS_CONTENT_INFORMATION_PREMISEOPERATINGADDRESS)}
                            name="PremiseOperatingAddress"
                            className="overview-margin"
                            value={getPremiseOperatingAddress(licence.LicenceData.LicenceInformation)}
                            labelSize={LABEL_SIZE}
                            inputSize={INPUT_SIZE}
                        />
                    </>
                </SubSection>}

                {applicationType === 'Cancel' &&
                    <SubSection orientation="left" title={t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_CONTENT_TITLE_CANCELLATIONINFORMATION)}>
                        <div className="card-body">
                            <div className="form-body form-preview">
                                <div className="multi-row-form">

                                    <Plaintext
                                        label={t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_TABLE_INFORMATION_REMARKS)}
                                        name={'Remarks'}
                                        value={values.Remarks}
                                        isUpper={IS_UPPER}
                                        labelSize={LABEL_SIZE}
                                        inputSize={INPUT_SIZE}
                                    />
                                    <Plaintext
                                        label={t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_TABLE_INFORMATION_EFFECTIVEENDDATE)}
                                        name={'EndDate'}
                                        value={values.EndDate}
                                        isUpper={IS_UPPER}
                                        labelSize={LABEL_SIZE}
                                        inputSize={INPUT_SIZE}
                                    />

                                </div>
                            </div>
                        </div>
                    </SubSection>
                }
                {applicationDetails.FormDefinition.Sections !== null && applicationDetails.FormDefinition.Sections !== undefined &&
                    applicationDetails.FormDefinition.Sections.length !== 0 && applicationDetails.FormDefinition.Sections.map((section, index) => {
                        return (
                            <SubSection key={index} orientation="left" title={t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_SECTION_KEY, section.Name))}>
                                {section.Fields && section.Fields.map((field, findex) => {
                                    return (
                                        <div key={findex}>
                                            {
                                                field.InputType !== 'FileUpload' && field.DataSource !== 'Remote' && <Plaintext
                                                    key={findex}
                                                    label={t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_FIELD_KEY, field.Name))}
                                                    name={field.Name}
                                                    value={values[field.Name]}
                                                    isUpper={IS_UPPER}
                                                />
                                            }
                                            {
                                                field.InputType !== 'FileUpload' && field.DataSource === 'Remote' && <Plaintext
                                                    key={findex}
                                                    label={t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_FIELD_KEY, field.Name))}
                                                    name={field.Name}
                                                    value={t(getLangKey(LANGUAGE_KEYS.BLS_CODE_CATEGORY_TITLE_KEY, field.DataParameters.Category, values[field.Name]))}
                                                    isUpper={IS_UPPER}
                                                />
                                            }
                                            {
                                                field.InputType === 'FileUpload' && <FileUpload
                                                    key={findex}
                                                    name={field.Name}
                                                    fileList={values.FileUploadFiles}
                                                    placeholder={field.Placeholder}
                                                    value={values[field.Name]}
                                                    label={field.DisplayName}
                                                    getFileURL={GET_FILE_URL}
                                                    readonly
                                                />
                                            }
                                        </div>
                                    );
                                })}
                                {section.Table && (section.Table.Fields.length <= TABLE_LAYOUT.COLUMNS) &&
                                    <SmallPreviewTable
                                        section={section}
                                        values={values}
                                    />
                                }
                                {section.Table && (section.Table.Fields.length > TABLE_LAYOUT.COLUMNS) &&
                                    <BigPreviewTable
                                        section={section}
                                        values={values}
                                    />
                                }
                            </SubSection>
                        );
                    })
                }
                {applicationDetails.FormDefinition.FormIOUrl !== null && applicationDetails.FormDefinition.FormIOUrl !== undefined &&
                    this.formIOSections.map((section, index) => {
                        return (
                            <SubSection key={index} orientation="left" title={section.label}>
                                {this.formIOFields.filter(field => field[1] === index).map((field, findex) => {
                                    return (
                                        <Plaintext
                                            key={findex}
                                            label={field[0].label}
                                            name={field[0].label}
                                            value={values[field[0].value]}
                                            isUpper={IS_UPPER}
                                        />
                                    );
                                })}
                                {this.formIODatagrids.filter(grid => grid[1] === section.value).map((datagrid, index) => {
                                    return (
                                        <div key={index}>
                                            <h3>{datagrid[0].label}</h3>
                                            {this.formIODatagridFieldValues.filter(field => field[1] === datagrid[0].value).map((field, index) => {
                                                return (
                                                    <Plaintext
                                                        key={index}
                                                        label={field[0].label}
                                                        name={field[0].label}
                                                        value={field[2]}
                                                        isUpper={IS_UPPER}
                                                        labelSize={LABEL_SIZE}
                                                        inputSize={INPUT_SIZE}
                                                    />
                                                );
                                            })}
                                        </div>
                                    ) 
                                })}
                            </SubSection>
                        );
                    })
                }
            </Section>
        );
    }
}

export default withTranslation()(ApplicationInfo);