import React, { useEffect, useState } from 'react';
import { GET_PAYMENT_DETAILS_URL, GET_PAYMENT_RECEIPT_URL, LAYOUT_TYPE } from '../../../App/AppSettings';
import Section from '../../../Section';
import { getFileRequestNewTab } from '../../../Shared/Actions';
import { DataTable } from '../../../Shared/DataTable';
import { getCustomComponents } from '../../../Shared/DataTable/BaseTable.js';
import { getParams, useAsyncFetch } from '../../../Shared/Fetch';
import { toastError } from '../../../Shared/Forms/Toaster.js';
import history from '../../../Shared/History';
import { useTranslation } from "react-i18next";
import { ERROR, getLangKey, LANGUAGE_KEYS } from '../../../Shared/Constants/LanguageKeys';

export default function PaymentInfo(props) {

    const { t } = useTranslation();
    const { state } = history.location;
    const [paymentData, setPaymentData] = useState([])
    const [isPaymentDataLoaded, setPaymentDataLoaded] = useState(false);

    const response = useAsyncFetch(GET_PAYMENT_DETAILS_URL + state.ID, getParams());

    useEffect(() => {
        async function setData() {
            const { IsSuccess, Messages, Data } = response.body;

            if (response.success && IsSuccess) {
                const paymentData = Data ? Data : [];
                setPaymentData(paymentData);
                setPaymentDataLoaded(true);
            }
            else {
                // Error message to be loaded here
                toastError(t(getLangKey(ERROR.BACKEND_ERROR_MESSAGE, Messages)), Messages);
            }
        }

        if (response) {
            setData()
        }
    }, [response, t])

    const getReceipt = async (PaymentReferenceNumber, userGuid) => {
        // const { loader } = this.props;
        // loader.start();

        let queryString = Object.assign({}, {
            applicationPaymentReferenceNo: PaymentReferenceNumber,
            userGuid: userGuid
        });

        let response = await getFileRequestNewTab(GET_PAYMENT_RECEIPT_URL, false, getParams(queryString));

        // loader.done();

        return response;
    }

    const setPaymentContent = (rowData) => {
        return <div className="application-link div-table-link" onClick={() => getReceipt(rowData.PaymentReferenceNumber, rowData.userGuid)}>{rowData.PaymentReferenceNumber}</div>
    }

    const setPaymentModeTranslatedContent = (rowData) => {
        return (
            <>{t(getLangKey(LANGUAGE_KEYS.BLS_DEFAULT_PAYMENTTYPE_PAYMENTOPTION_KEY, rowData.PaymentMode.toLowerCase()))}</>
        );
    }

    const getTranslatedString = (rowData) => {
        return t(getLangKey(LANGUAGE_KEYS.BLS_DEFAULT_PAYMENTTYPE_PAYMENTOPTION_KEY, rowData.PaymentMode.toLowerCase()));
    }

    return (
        <>

            {isPaymentDataLoaded &&
                <Section
                    type={LAYOUT_TYPE.FORM_CONTENT}
                    sectionName={props.sectionName}
                    sectionState={props.sectionState}
                    toggleSection={props.toggleSection}
                >
                    <DataTable
                        pageSize="5"
                        minFilterChars='2'
                        data={paymentData}
                        noResultsMessage={t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_MESSAGE_PLACEHOLDER_NOPAYMENT)}
                        components={getCustomComponents()}
                        columns={
                            {
                                PaymentReferenceNumber: { title: t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_TABLE_TITLE_PAYMENTREFERENCE), width: '5%', DBkey: 'PaymentReferenceNumber', setContent: setPaymentContent },
                                PaymentDate: { title: t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_TABLE_TITLE_PAYMENTDATE), width: '5%', DBkey: 'PaymentDate', isDate: true },
                                AmountPaid: { title: t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_TABLE_TITLE_AMOUNT), width: '5%', DBkey: 'AmountPaid' },
                                PaymentMode: { title: t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_TABLE_TITLE_PAYMENTMODE), width: '5%', DBkey: 'PaymentMode', setContent: setPaymentModeTranslatedContent, getTranslatedString: getTranslatedString },
                            }
                        }
                    // renderMobileCardActions={this.renderMobileCardActions}
                    />
                </Section>
            }
        </>
    )
}