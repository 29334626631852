import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import { LAYOUT_TYPE, TRANSLATION_MANAGEMENT_LIST } from '../../App/AppSettings';
import Layout from '../../Layout';
import { navigateTo } from '../../Shared/Actions';
import { FILE_UPLOAD_SECTION_FILES } from '../../Shared/Constants';
import FileUploadSection from '../../Shared/FileUploadSection';
import { DEFAULT_TEXT, DEFAULT_NAMESPACE } from '../../Shared/Constants/LanguageKeys.js';
import { SMARTForm } from '../../Shared/Forms';
import history from '../../Shared/History';
import PageFooter from '../../Shared/PageFooter';
import './LanguageList.css';

const FORM_CONTEXT = "UploadFile";

class UploadFile extends React.Component {
    constructor(props) {
        super(props);
        if (history.location.state) {
            const { chosenLanguageApplication, chosenLanguage, chosenFilterValues } = history.location.state;
            this.state = {
                languages: [],
                languageValues: [],
                values: {
                    [FILE_UPLOAD_SECTION_FILES]: []
                },
                chosenLanguageApplication,
                chosenLanguage,
                chosenFilterValues
            }
        }
    }

    render() {
        const { values, chosenLanguageApplication, chosenLanguage, chosenFilterValues } = this.state;
        return (
            <>
                <Layout type={LAYOUT_TYPE.FORM} title=
                    {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_CONTENT_TITLE_UPLOADFILE, DEFAULT_TEXT.UPLOAD_FILE_TITLE)}>
                    <SMARTForm
                        formContext={FORM_CONTEXT}
                        formValues={values}
                    >
                        {({ values, onChangeField, submitPlainForm }) => (
                            <Row className="body-content">
                                <Col>
                                    <FileUploadSection
                                        sectionName={"Language Json File"}
                                        name={FILE_UPLOAD_SECTION_FILES}
                                        fileList={values[FILE_UPLOAD_SECTION_FILES]}
                                        onChangeField={onChangeField}
                                        uploadLimit={1}
                                        accept={".json"}
                                    />
                                    <PageFooter type={LAYOUT_TYPE.FORM}>
                                        <Button color="backward" size="sm" type="button" onClick={() => navigateTo(TRANSLATION_MANAGEMENT_LIST, { chosenLanguageApplication, chosenLanguage, chosenFilterValues })}>
                                            {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_NAVIGATION_BUTTON_BACK, DEFAULT_TEXT.BACK)}
                                        </Button>
                                        <Button color="forward" size="sm" type="button" onClick={submitPlainForm}>
                                            {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_NAVIGATION_BUTTON_SUBMIT, DEFAULT_TEXT.SUBMIT)}
                                        </Button>
                                    </PageFooter>
                                </Col>
                            </Row>

                        )}

                    </SMARTForm>
                </Layout>
            </>
        );
    }
}

export default UploadFile;
