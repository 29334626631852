import React from 'react';
import { Table } from 'reactstrap';
import { Plaintext } from '../../../Shared/Forms';
import { getTableListValues } from '../FormUtils';

class SmallPreviewTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    maximumErrorMessage = maximumRows => {
        this.setState({
            showMaximumRows: maximumRows
        });
    };

    render() {
        const { values, section } = this.props;
        const tableValueKeys = Object.keys(values);

        return (
            <Table className="griddle-table">
                <thead className="griddle-table-heading">
                    <tr>
                        {section.Table.Fields && section.Table.Fields.map((field, findex) => {
                            return (
                                <th key={findex} className="griddle-table-heading-cell">{field.DisplayName}</th>
                            );
                        })}
                    </tr>
                </thead>
                <tbody className="griddle-table-body">
                    {
                        getTableListValues(tableValueKeys, section.Name) &&
                        getTableListValues(tableValueKeys, section.Name)[section.Name] &&
                        getTableListValues(tableValueKeys, section.Name)[section.Name].map((row, rindex) => {
                            return (
                                <tr key={rindex} className="griddle-row">
                                    {section.Table.Fields && section.Table.Fields.map((field, findex) => {
                                        return (
                                            <td key={findex + rindex} className="griddle-cell table-griddle-cell">
                                                {
                                                    field.InputType !== 'FileUpload' && <Plaintext
                                                        label={undefined}
                                                        className="modal-plaintext"
                                                        key={findex + rindex}
                                                        name={field.Name}
                                                        value={values[`${section.Name}.${rindex}.${field.Name}`]}
                                                        labelSize={null}
                                                    />
                                                }
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })
                    }
                </tbody>
            </Table>
        );
    }
}
export default SmallPreviewTable;