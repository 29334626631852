import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';
import { default as MaskedInputField } from 'react-text-mask';
import { withBaseControl } from './BaseControl';


class MaskedInput extends React.Component {

    render() {
        return (
            <Input
                tag={MaskedInputField}
                name={this.props.name}
                value={this.props.value}
                placeholder={this.props.placeholder}
                onChange={this.props.onChange}
                mask={this.props.mask}
                keepCharPositions={true}
            />
        );
    }
}

// PropTypes: For prop validation
MaskedInput.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    mask: PropTypes.array.isRequired
};


export default withBaseControl(MaskedInput);