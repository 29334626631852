import React, { useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ActionButton, DataTable } from '../../../Shared/DataTable';
import { getCustomComponents } from '../../../Shared/DataTable/BaseTable.js';
import { ERROR, LANGUAGE_KEYS } from '../../../Shared/Constants/LanguageKeys';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { FIELD_SIZE } from '../../../Shared/Constants';
import { SelectList } from '../../../Shared/Forms';
import * as Yup from 'yup';
import { isValidForm } from '../../../Shared/Actions';
import { ModalConfirm } from '../../../Shared/Modal';
import AddCRMMappingModalConfirm from '../../../Shared/Modal/AddCRMMappingModalConfirm';

const ADD_CRM_MAPPING_MODAL = {
    hasModal: true,
    name: 'addCRMMapping',
    modalHeader: 'Add New Mapping'
};
const EDIT_CRM_MAPPING_MODAL = {
    hasModal: true,
    name: 'editCRMMapping',
    modalHeader: 'Edit Mapping'
};
const DELETE_CRM_MAPPING_MODAL = {
    hasModal: true,
    name: 'deleteCRMMapping',
    modalHeader: 'Delete Mapping'
};

const CRM_MAPPING_VALUES = {
    FormField: '',
    CRMField: ''
}

export default function CRMMapping(props) {
    const { t } = useTranslation();
    const [modalState, setModalState] = useState(null);
    const [mappingGridData, setMappingGridData] = useState([]);
    const {formIOFormFieldOptions, crmFieldOptions, values} = props;
    const [crmMappingValues, setCRMMappingValues] = useState(CRM_MAPPING_VALUES);
    const [crmMappingValuesError, setCRMMappingValuesError] = useState(false);
    const addMappingValueValidation = Yup.object().shape({
        FormField: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED)),
        CRMField: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED))
    }); 
    const [selectedIndex, setSelectedIndex] = useState('');

    const toggleModal = modalName => {
        if (modalName === ADD_CRM_MAPPING_MODAL.name) {
            resetMappingValues();
            setCRMMappingValuesError(false);
        };

        setModalState((modalState !== modalName) ? modalName : null);
    };

    const editFieldMapping = (rowData) => {
        setSelectedIndex(rowData.griddleKey);
        setCRMMappingValues(rowData);
        toggleModal(EDIT_CRM_MAPPING_MODAL.name);
    }

    const confirmEditMappingValue = () => {
        if (isValidForm(addMappingValueValidation, crmMappingValues)) {
            setCRMMappingValuesError(false);
            const data = [...mappingGridData];
            data[selectedIndex] = crmMappingValues;
            setMappingGridData(data);
            values.ApplicationData.CRMMappingData = data;
            toggleModal(EDIT_CRM_MAPPING_MODAL.name);
        } else {
            setCRMMappingValuesError(true);
        }
    }

    const deleteFieldMapping = (rowData) => {
        toggleModal(DELETE_CRM_MAPPING_MODAL.name);
        setSelectedIndex(rowData.griddleKey);
    }

    const confirmDeleteMappingValue = () => {
        const data = [...mappingGridData];
        data.splice(selectedIndex, 1);
        setMappingGridData(data);
        setSelectedIndex(null);
    }

    const renderAddMappingModal = () => {
        return (
            <React.Fragment>
                <SelectList
                    isClearable={false}
                    name="FormField"
                    value={crmMappingValues.FormField}
                    minLength={0}
                    maxLength={140}
                    options={formIOFormFieldOptions}
                    placeholder="Form Field Value"
                    isMulti={false}
                    onChangeField={(name, valueArr) => {
                        mappingValuesChangeHandler(name, valueArr);
                    }}
                    inputSize={FIELD_SIZE.LARGE}
                    label="Form Field Value"
                    error={(!crmMappingValues.FormField && crmMappingValuesError) ? displayRequiredMessage('Form Field Value') : ''}
                    required
                />
                <SelectList
                    isClearable={false}
                    name="CRMField"
                    value={crmMappingValues.CRMField}
                    minLength={0}
                    maxLength={140}
                    options={crmFieldOptions}
                    placeholder="CRM Field Value"
                    isMulti={false}
                    onChangeField={(name, valueArr) => {
                        mappingValuesChangeHandler(name, valueArr);
                    }}
                    inputSize={FIELD_SIZE.LARGE}
                    label="CRM Field Value"
                    error={(!crmMappingValues.DataType && crmMappingValuesError) ? displayRequiredMessage('CRM Field Value') : ''}
                    required
                />
            </React.Fragment>
        );
    };

    const mappingValuesChangeHandler = (name, value) => {
        setCRMMappingValues(prevValues => ({...prevValues, [name]: value}));
    }

    const displayRequiredMessage = label => (
        `${label} ${t(ERROR.REQUIRED)}`
    );

    const addMappingValue = () => {
        if (isValidForm(addMappingValueValidation, crmMappingValues)) {
            setCRMMappingValuesError(false);
            const data = [...mappingGridData];
            data.push(crmMappingValues);
            setMappingGridData(data);
            values.ApplicationData.CRMMappingData = data;
            toggleModal(ADD_CRM_MAPPING_MODAL.name);
        } else {
            setCRMMappingValuesError(true);
        }
    }

    const resetMappingValues = () => {
        setCRMMappingValues(CRM_MAPPING_VALUES);
    }

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <Button
                        className="float-right"
                        color="neutral"
                        onClick={() => {
                            toggleModal(ADD_CRM_MAPPING_MODAL.name);
                        }}>
                            Add Mapping
                    </Button>
                </Col>
            </Row>
            <DataTable
                pageSize="5"
                noResultsMessage="No mapping added"
                components={getCustomComponents()}
                data={mappingGridData}
                columns={
                    {
                        RunningNumber: { title: "Running Number", width: '15%' },
                        FormField: { title: "Form Field", width: '30%', DBkey: 'FormField'},
                        CRMField: { title: "CRM Field", width: '30%', DBkey: 'CRMField'},
                        Actions: { title: "Action", width: '15%' },
                    }
                }
                renderActions={({ RowData }) => (
                    <React.Fragment>
                        <ActionButton tooltip={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_EDIT)} color="forward" onClick={() => editFieldMapping(RowData)} >
                            <FontAwesomeIcon icon={faEdit} />
                        </ActionButton>
                        <ActionButton tooltip={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_DELETE)} color="hazard" onClick={() => deleteFieldMapping(RowData)} >
                            <FontAwesomeIcon fixedWidth icon={faTrash} />
                        </ActionButton>
                    </React.Fragment>
                )}
            />
            <AddCRMMappingModalConfirm
                isOpen={(modalState === ADD_CRM_MAPPING_MODAL.name)}
                contentHeader={ADD_CRM_MAPPING_MODAL.modalHeader}
                saveCloseText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_NAVIGATION_BUTTON_SAVEANDCLOSE)}
                saveCloseCallback={() => {
                    addMappingValue();
                }}
                // Cancel Modal Without Saving
                cancelText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_CANCEL)}
                cancelCallback={() => toggleModal(ADD_CRM_MAPPING_MODAL.name)}
                previousDisabled='true'
                nextDisabled='true'
                contentBody={renderAddMappingModal()}>
            </AddCRMMappingModalConfirm>
            <AddCRMMappingModalConfirm
                isOpen={(modalState === EDIT_CRM_MAPPING_MODAL.name)}
                contentHeader={EDIT_CRM_MAPPING_MODAL.modalHeader}
                saveCloseText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_NAVIGATION_BUTTON_SAVEANDCLOSE)}
                saveCloseCallback={() => {
                    confirmEditMappingValue();
                }}
                // Cancel Modal Without Saving
                cancelText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_CANCEL)}
                cancelCallback={() => toggleModal(EDIT_CRM_MAPPING_MODAL.name)}
                previousDisabled='true'
                nextDisabled='true'
                contentBody={renderAddMappingModal()}>
            </AddCRMMappingModalConfirm>
            <ModalConfirm
                isOpen={(modalState === DELETE_CRM_MAPPING_MODAL.name)}
                contentHeader={DELETE_CRM_MAPPING_MODAL.modalHeader}
                contentBody={DELETE_CRM_MAPPING_MODAL.modalContent}
                confirmText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_CONFIRM)}
                confirmCallback={() => {
                    toggleModal(DELETE_CRM_MAPPING_MODAL.name);
                    confirmDeleteMappingValue();
                }}
                cancelText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_CANCEL)}
                cancelCallback={() => toggleModal(DELETE_CRM_MAPPING_MODAL.name)}
            />
        </React.Fragment>
    );
}