import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import { VIEW_FAQ_URL, EDIT_FAQ_URL, LAYOUT_TYPE, FAQ_MANAGEMENT_URL, PUBLISH_FAQ_URL, SUPPORTED_LANGUAGE_LIST_URL } from '../../App/AppSettings';
import Layout from '../../Layout';
import { ERROR, SUCCESS } from '../../Localization/index';
import { FIELD_SIZE } from '../../Shared/Constants';
import { navigateTo, fetchRequest, getParams, postParams } from '../../Shared/Actions';
import { withSMARTWrapper, Plaintext, SMARTForm, SelectList } from '../../Shared/Forms';
import { DEFAULT_TEXT, DEFAULT_NAMESPACE } from '../../Shared/Constants/LanguageKeys.js';
import { toastError, toastSuccess } from '../../Shared/Forms/Toaster.js';
import history from '../../Shared/History';
import { withLoader } from '../../Shared/Loading';
import PageFooter from '../../Shared/PageFooter';
import { FaqTree } from '../../Shared/Tree';
import { ModalConfirm } from '../../Shared/Modal';
import PreviewFaq from './PreviewFaq';
import './Faq.css';


const FORM_CONTEXT = "ViewFaq";
const dropDownArrayApplication = 'dropDownArrayApplication';
const dropDownArraySupportedLanguage = 'dropDownArraySupportedLanguage';
const LABEL_SIZE = 3;
const INPUT_SIZE = 9;
const FORM_INITIAL_VALUES = {
    Name: '',
    Id: '',
    Description: '',
    FaqId: '',
    ApplicationName: '',
    IsLocaleEnabled: '',
    LanguageCode: '',
    Language: ''
}

const PREVIEW_MODAL = {
    hasModal: true,
    name: "Preview"
};

class ViewFaq extends React.Component {
    constructor(props) {
        super(props);
        if (history.location.state) {
            this.state = {
                values: FORM_INITIAL_VALUES,
                Id: history.location.state.data.RowData.Id,
                serverUrl: EDIT_FAQ_URL,
                [dropDownArrayApplication]: [],
                [dropDownArraySupportedLanguage]: [],
                app: '',
                languageCheckboxOptions: [], 
                faqTree: [],
                isSuccess: false,
                modalMessage: '',
                isFirstLoad: true,
            }
        }
    }

    componentDidMount = async () => {
        // Get Faq details
        await this.getFaq(VIEW_FAQ_URL, "");

        await this.getSupportedLanguageDropdownList(SUPPORTED_LANGUAGE_LIST_URL, dropDownArraySupportedLanguage);
                
    }

    // Fn: Get language list from code table
    getSupportedLanguageDropdownList = async (url, dropDownName) => {

        const response = await fetchRequest(url, getParams({ applicationName: this.state.values.ApplicationName }));

        const { Data, IsSuccess, Message } = response.body;

        let dropDownArray = [];

        if (response.success) {
            if (IsSuccess) {

                // Add data to array
                Data.forEach(name => {
                    dropDownArray.push({ 'label': name.LanguageCodeTitle, 'value': name.LanguageCodeValue });
                });

                // Set array to dropDownArraySupportedLanguage
                this.setState({
                    [dropDownName]: dropDownArray,
                    //values: { 'Language': this.state.values.LanguageTitle }
                });

            } else {
                toastError(Message);
            }
        } else {
            toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
        }

        return dropDownArray;
    }
    
    
    // Fn: Get Faq details
    getFaq = async (url, languageCode) => {
        this.props.loader.start();
        let fullurl = url;

        const params = {
            Id: this.state.Id,
            LanguageCode: languageCode,
            IsPublished: true
        };
        
        const response = await fetchRequest(fullurl, getParams(params));
        const { Data, IsSuccess, Message } = response.body;

        this.props.loader.done();

        if (response.success) {
            if (IsSuccess) {

                this.setState({
                    values: Data,
                    isSuccess: true, 
                    isFirstLoad: false
                });

                await this.getSupportedLanguageDropdownList(SUPPORTED_LANGUAGE_LIST_URL, dropDownArraySupportedLanguage);
                
            } else {
                toastError(Message);
            }
        } else {
            toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
        }
    }


    changePageBasedOnLanguage = async (name, value) => {
        if (!this.state.isFirstLoad)
        {
            this.setState({
                isSuccess: false
            });
            
            await this.getFaq(VIEW_FAQ_URL, value);
        }
    }

    renderLanguage = (values, onChangeField) => {
        return (this.state.values.IsLocaleEnabled) ?
        <SelectList 
            name="LanguageCode"
            value={values.LanguageCode}
            options={this.state.dropDownArraySupportedLanguage}
            onChangeField={(name, value) => this.changePageBasedOnLanguage(name, value)}
            placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_PLACEHOLDER_SELECTLANG, DEFAULT_TEXT.SELECT_A_LANGUAGE)}
            isMulti={false}
            labelSize={FIELD_SIZE.SMALL}
            inputSize={FIELD_SIZE.MEDIUM}
            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_LANGUAGE, DEFAULT_TEXT.LANGUAGE)}
            required
        /> 
        :
        <Plaintext
            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_LANGUAGE, DEFAULT_TEXT.LANGUAGE)}
            name="Language"
            value={values.Language}
            labelSize={LABEL_SIZE}
            inputSize={INPUT_SIZE}
        />

    }

    // Fn: Create array of language object for checkbox options
    createLanguageCheckboxOptions = (languageList) => {
        
        const languageObjArray = [];

        // Add Language object to array
        languageList.map((lang) => 
            languageObjArray.push({
                label: lang,
                value: lang
            })
        );

        return languageObjArray;

    }

    publishForm = async () => {

        const params = {
            FaqId: this.state.Id,
            Application: this.state.values.ApplicationName
        };

        const { loader } = this.props;
        loader.start();
        const { toggleUpdate } = this.state;

        // Get results of server-side form posting
        const response = await fetchRequest(PUBLISH_FAQ_URL, postParams('', params));
        loader.done();
        const { IsSuccess, Messages } = response.body;
        if (response.success) {
            if (IsSuccess) {

                //update translation table
                this.setState({
                    toggleUpdate: !toggleUpdate
                });

                navigateTo(FAQ_MANAGEMENT_URL); 
                toastSuccess(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_SUCCESS_TITLE_PUBLISH, SUCCESS.PUBLISH_SUCCESS), DEFAULT_TEXT.FAQ);
                
            }
            else {
                // Error message to be loaded here
                toastError(Messages);
            }
        }
        else {
            toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
        }
    }

    toggleModal = (modalName, ID) => {
        this.setState({
            modalState: (this.state.modalState !== modalName) ? modalName : undefined,
            modalID: ID
        })
    };

    render() {
        return (this.state.isSuccess &&
            <>
                <Layout type={LAYOUT_TYPE.FORM} title=
                    {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_CONTENT_TITLE_VIEWFAQ, DEFAULT_TEXT.VIEW_FAQ)}>
                    <SMARTForm
                        formContext={FORM_CONTEXT}
                        formValues={this.state.values}
                        validateOnSubmit={true}
                        nextURL={FAQ_MANAGEMENT_URL}
                    >
                        {({ values, errors, onChange, onChangeField, submitPlainForm }) => (
                            <Row className="body-content">
                                <Col xs="6" sm="6">
                                      <Plaintext
                                        label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_APPLICATION, DEFAULT_TEXT.APPLICATION)}
                                        name="Application"
                                        value={values.ApplicationName}
                                        labelSize={LABEL_SIZE}
                                        inputSize={INPUT_SIZE}
                                    />
                                    <Plaintext
                                        label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_TITLE, DEFAULT_TEXT.TITLE)}
                                        name="Title"
                                        value={values.Title}
                                        labelSize={LABEL_SIZE}
                                        inputSize={INPUT_SIZE}
                                    />
                                    
                                    <Plaintext
                                        label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_LASTUPDATEDBY, DEFAULT_TEXT.LAST_UPDATED_BY)}
                                        name="LastUpdatedBy"
                                        value={values.LastUpdatedBy}
                                        labelSize={LABEL_SIZE}
                                        inputSize={INPUT_SIZE}
                                    />
                                </Col>
                                <Col xs="6" sm="6">
                                    {this.renderLanguage(values, onChangeField)}

                                    <Plaintext
                                      label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_DESCRIPTION, DEFAULT_TEXT.DESCRIPTION)}
                                      name="Description"
                                      value={values.Description}
                                      labelSize={LABEL_SIZE}
                                      inputSize={INPUT_SIZE}
                                    />
                                    <Plaintext
                                        label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_LASTUPDATEDDATE, DEFAULT_TEXT.LAST_UPDATED_DATE)}
                                        name="LastUpdatedDate"
                                        value={values.FormattedLastUpdatedDate}
                                        labelSize={LABEL_SIZE}
                                        inputSize={INPUT_SIZE}
                                    />
                                </Col>
                                <Col>
                                    <FaqTree
                                        treeHeader={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_TREE_TITLE_FAQTREE, DEFAULT_TEXT.FAQ_TREE)}
                                        renderActions={true}
                                        renderChapter={false}
                                        error={errors.OnlineHelpTree}
                                        name="OnlineHelpTree"
                                        value={this.state.faqTree}
                                        onChangeField={onChangeField}
                                        leftTreeContainer="left-tree-container"
                                        rightTreeContainer="right-tree-container"
                                        faqId={values.Id}
                                        faqName={values.Name}
                                        faqLanguageCode={values.LanguageCode}
                                        success={this.state.isSuccess}
                                        status={values.Status}
                                        applicationName={values.ApplicationName}
                                        isLocaleEnabled={values.IsLocaleEnabled}
                                        isDefaultLanguage={values.IsDefaultLanguage}
                                        isViewFaq={true}
                                    />
                                </Col>

                                <ModalConfirm
                                    className="preview-faq"
                                    isOpen={(this.state.modalState === PREVIEW_MODAL.name)}
                                    contentHeader={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_MODAL_TITLE_PREVIEWFAQ, DEFAULT_TEXT.PREVIEW_FAQ_MODAL_TITLE)}
                                    contentBody={<PreviewFaq languageCode={values.LanguageCode} application={values.ApplicationName} faqGuid={values.FaqGuid}
                                    isPublished={true}
                                    isLocaleEnabled={this.state.values.IsLocaleEnabled}></PreviewFaq>}
                                    cancelText={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_CANCEL, DEFAULT_TEXT.CANCEL)}
                                    cancelCallback={() => this.toggleModal(PREVIEW_MODAL.name)}
                                />


                                <PageFooter type={LAYOUT_TYPE.FORM}>
                                    <Button color="backward" size="sm" type="button" onClick={() => navigateTo(FAQ_MANAGEMENT_URL)}>
                                        {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_BACK,  DEFAULT_TEXT.BACK)}
                                    </Button>
                                    <Button color="neutral" size="sm" type="button" onClick={() => this.toggleModal(PREVIEW_MODAL.name, this.state.Id)}>
                                        {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_PREVIEW,  DEFAULT_TEXT.PREVIEW)}
                                    </Button>
                                </PageFooter>
                            </Row>
                        )}

                    </SMARTForm>
                </Layout>
            </>
        );
    }
}

export default withSMARTWrapper(withLoader(ViewFaq));
