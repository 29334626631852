import { LIST_NOTIFICATION_TEMPLATE_PLACEHOLDER_URL } from '../../App/AppSettings';
import { fetchRequest, getParams } from '../../Shared/Actions';
import { ERROR } from '../../Shared/Constants/LanguageKeys';
import { toastError } from '../../Shared/Forms/Toaster.js';
import { getLangKey } from '../DisplayComponents/DisplayUtils';

export const RetrieveGlobalVariables = async (t) => {
    const RETRIEVE_GLOBAL_VARIABLES_URL = LIST_NOTIFICATION_TEMPLATE_PLACEHOLDER_URL;
    const response = await fetchRequest(RETRIEVE_GLOBAL_VARIABLES_URL, getParams(), false);

    const { Data, IsSuccess } = response.body;
    let globalVariableList = [];
    if (response.success) {
        if (IsSuccess) {
            Data.forEach(variable => {
                globalVariableList.push({ 'text': variable.PlaceHolderCodeTitle, 'value': variable.PlaceHolderCodeValue, url: '' });
            });
        } else {
            toastError(t(getLangKey(ERROR.BACKEND_ERROR_MESSAGE, response.body.Messages.toString())), response.body.Messages.toString());
            return null;
        }
    } else {
        toastError(t(ERROR.SERVER_UNREACHABLE2));
        return null;
    }
    return globalVariableList;
}