import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { CODES_LIST, LAYOUT_TYPE } from '../../App/AppSettings';
import Layout from '../../Layout';
import { navigateTo } from '../../Shared/Actions';
import { Plaintext, withSMARTWrapper } from '../../Shared/Forms';
import history from '../../Shared/History';
import { withLoader } from '../../Shared/Loading';
import { DEFAULT_TEXT, DEFAULT_NAMESPACE } from '../../Shared/Constants/LanguageKeys.js';
import PageFooter from '../../Shared/PageFooter';

const LABEL_SIZE = 3;
const INPUT_SIZE = 9;

class View extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false
        }
    }

    updateDisplayValue = (checkValue, displayValue) => {
        return checkValue === undefined || checkValue === null ? "-" : displayValue;
    }

    async componentDidMount() {
        let data = history.location.state.Data;
        this.setState({
            values: {
                id: data.Id,
                CodeTableCategory: data.CodeCategory,
                CodeTableTitle: data.CodeTitle,
                CodeTableValue: data.CodeValue,
                CodeTableDescription: data.CodeDescription,
                CodeTableStatus: data.IsActive ?
                    this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_CODESTATIC_FORM_TITLE_ACTIVE, DEFAULT_TEXT.ACTIVE) :
                    this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_CODESTATIC_FORM_TITLE_INACTIVE, DEFAULT_TEXT.INACTIVE),
                CodeTableEffectiveStartDate: this.updateDisplayValue(data.EffectiveStartDate, data.EffectiveStartDateFormatted),
                CodeTableEffectiveEndDate: this.updateDisplayValue(data.EffectiveEndDate, data.EffectiveEndDateFormatted),
                CodeTableSequenceNumber: data.SequenceNumber.toString(),
                CodeTableParentCategory: this.updateDisplayValue(data.ParentCodeCategory, data.ParentCodeCategory),
                CodeTableParent: this.updateDisplayValue(data.ParentId, data.ParentId),
                CodeTableParentTitle: this.updateDisplayValue(data.ParentTitle, data.ParentTitle),

                CodeTableCreatedDate: this.updateDisplayValue(data.CreatedDateFormatted, data.CreatedDateFormatted),
                CodeTableCreatedBy: this.updateDisplayValue(data.CreatedBy, data.CreatedBy),
                CodeTableUpdatedDate: this.updateDisplayValue(data.LastModifiedDateFormatted, data.LastModifiedDateFormatted),
                CodeTableUpdatedBy: this.updateDisplayValue(data.LastModifiedBy, data.LastModifiedBy),
            },
            isLoaded: true
        });
    }

    render() {
        return (
            <React.Fragment>
                {this.state.isLoaded ?
                    (<Layout type={LAYOUT_TYPE.FORM} title=
                        {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_CODESTATIC_CONTENT_TITLE_CODETABLEENTRYDETAILS, DEFAULT_TEXT.VIEW_CODE)}>
                        <React.Fragment>
                            <Row className="body-content">
                                <Col>
                                    <Plaintext
                                        label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_CODESTATIC_FORM_TITLE_CATEGORY, DEFAULT_TEXT.CATEGORY)}
                                        name="Category"
                                        value={this.state.values.CodeTableCategory}
                                        labelSize={LABEL_SIZE}
                                        inputSize={INPUT_SIZE}
                                    />
                                    <Plaintext
                                        label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_CODESTATIC_FORM_TITLE_TITLE, DEFAULT_TEXT.TITLE)}
                                        name="Title"
                                        value={this.state.values.CodeTableTitle}
                                        labelSize={LABEL_SIZE}
                                        inputSize={INPUT_SIZE}
                                    />
                                    <Plaintext
                                        label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_CODESTATIC_FORM_TITLE_VALUE, DEFAULT_TEXT.VALUE)}
                                        name="Value"
                                        value={this.state.values.CodeTableValue}
                                        labelSize={LABEL_SIZE}
                                        inputSize={INPUT_SIZE}
                                    />
                                    <Plaintext
                                        label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_CODESTATIC_FORM_TITLE_DESCRIPTION, DEFAULT_TEXT.DESCRIPTION)}
                                        name="Description"
                                        value={this.state.values.CodeTableDescription}
                                        labelSize={LABEL_SIZE}
                                        inputSize={INPUT_SIZE}
                                    />
                                    <Plaintext
                                        label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_CODESTATIC_FORM_TITLE_PARENTCODECATEGORY, DEFAULT_TEXT.PARENT_CODE_CATEGORY)}
                                        name="ParentCategory"
                                        value={this.state.values.CodeTableParentCategory}
                                        labelSize={LABEL_SIZE}
                                        inputSize={INPUT_SIZE}
                                    />
                                    <Plaintext
                                        label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_CODESTATIC_FORM_TITLE_PARENTCODE, DEFAULT_TEXT.PARENT_CODE)}
                                        name="ParentCode"
                                        value={this.state.values.CodeTableParentTitle}
                                        labelSize={LABEL_SIZE}
                                        inputSize={INPUT_SIZE}
                                    />
                                    <Plaintext
                                        label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_CODESTATIC_FORM_TITLE_STATUS, DEFAULT_TEXT.STATUS)}
                                        name="Status"
                                        value={this.state.values.CodeTableStatus}
                                        labelSize={LABEL_SIZE}
                                        inputSize={INPUT_SIZE}
                                    />
                                    <Plaintext
                                        label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_CODESTATIC_FORM_TITLE_EFFECTIVESTARTDATE, DEFAULT_TEXT.EFFECTIVE_START_DATE)}
                                        name="EffectiveStartDate"
                                        value={this.state.values.CodeTableEffectiveStartDate}
                                        labelSize={LABEL_SIZE}
                                        inputSize={INPUT_SIZE}
                                    />
                                    <Plaintext
                                        label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_CODESTATIC_FORM_TITLE_EFFECTIVEENDDATE, DEFAULT_TEXT.EFFECTIVE_END_DATE)}
                                        name="EffectiveEndDate"
                                        value={this.state.values.CodeTableEffectiveEndDate}
                                        labelSize={LABEL_SIZE}
                                        inputSize={INPUT_SIZE}
                                    />
                                    <Plaintext
                                        label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_CODESTATIC_FORM_TITLE_SEQUENCENUMBER, DEFAULT_TEXT.SEQUENCE_NO)}
                                        name="SequenceNumber"
                                        value={this.state.values.CodeTableSequenceNumber}
                                        labelSize={LABEL_SIZE}
                                        inputSize={INPUT_SIZE}
                                    />
                                    <Plaintext
                                        label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_CODESTATIC_FORM_TITLE_CREATEDDATE, DEFAULT_TEXT.CREATED_DATE)}
                                        name="CreatedDate"
                                        value={this.state.values.CodeTableCreatedDate}
                                        labelSize={LABEL_SIZE}
                                        inputSize={INPUT_SIZE}
                                    />

                                    <Plaintext
                                        label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_CODESTATIC_FORM_TITLE_CREATEDBY, DEFAULT_TEXT.CREATED_BY)}
                                        name="CreatedBy"
                                        value={this.state.values.CodeTableCreatedBy}
                                        labelSize={LABEL_SIZE}
                                        inputSize={INPUT_SIZE}
                                    />

                                    <Plaintext
                                        label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_CODESTATIC_FORM_TITLE_LASTUPDATEDDATE, DEFAULT_TEXT.LAST_UPDATED_DATE)}
                                        name="LastUpdatedDate"
                                        value={this.state.values.CodeTableUpdatedDate}
                                        labelSize={LABEL_SIZE}
                                        inputSize={INPUT_SIZE}
                                    />

                                    <Plaintext
                                        label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_CODESTATIC_FORM_TITLE_LASTUPDATEDBY, DEFAULT_TEXT.LAST_UPDATED_BY)}
                                        name="LastUpdatedBy"
                                        value={this.state.values.CodeTableUpdatedBy}
                                        labelSize={LABEL_SIZE}
                                        inputSize={INPUT_SIZE}
                                    />


                                </Col>
                            </Row>
                            <PageFooter>
                                <Button color="backward" size="sm" type="button" onClick={() => navigateTo(CODES_LIST)}>
                                    {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_CODESTATIC_NAVIGATION_BUTTON_BACK, DEFAULT_TEXT.BACK)}
                                </Button>
                            </PageFooter>
                        </React.Fragment>
                    </Layout>)
                    : null}
            </React.Fragment>
        )
    }
}

export default withSMARTWrapper(withLoader(withRouter(View)));