import React from 'react';
import { APPLICATION_TYPE, LAYOUT_TYPE } from '../../../../../App/AppSettings';
import Layout from '../../../../../Layout';
import history from '../../../../../Shared/History';
import { FormProcessing, LicenceDetailsView } from '../../../Components';
import { SpfLiquor1To4Amend, SpfLiquor1To4Apply, SpfLiquor1To4Cancel, SpfLiquor1To4Renewal } from '../../../SpfLiquor1To4/V1/SpfLiquor1To4Components/ApplicationInfoSection';
import { withTranslation } from "react-i18next";
import { LANGUAGE_KEYS } from '../../../../../Shared/Constants/LanguageKeys';

const ApplicationInfoComponent = {
	[APPLICATION_TYPE.APPLY]: SpfLiquor1To4Apply,
	[APPLICATION_TYPE.RENEWAL]: SpfLiquor1To4Renewal,
	[APPLICATION_TYPE.AMEND]: SpfLiquor1To4Amend,
	[APPLICATION_TYPE.CANCEL]: SpfLiquor1To4Cancel
}

// THIS COMPONENT IS DEPRECITATED
class LiquorLicence1To4EnquiryDetails extends React.Component {

	constructor(props) {
		super(props);
		this.state = {};
	}

	async componentDidMount() {
		const { setPageNotFound } = this.props;
		const { state } = history.location;
		const isPageNotFound = state === undefined || state.ID === undefined;

		await this.setState({ isPageNotFound });

		setPageNotFound(isPageNotFound);
	}

	render() {
		const {t} = this.props;
		const { isPageNotFound } = this.state;
		if (isPageNotFound === undefined || isPageNotFound) return null;

		return (
			<Layout type={LAYOUT_TYPE.FORM} title={t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_CONTENT_TITLE_HEADER)} footer={false} titleClassName="sticky-title"
				titleChildren={
					<FormProcessing
						isAction={false}
					/>
				}>
				<LicenceDetailsView applicationInfoComponent={ApplicationInfoComponent} />
			</Layout>
		);
	}
}

export default withTranslation()(LiquorLicence1To4EnquiryDetails);