import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { GET_FILE_URL, LAYOUT_TYPE, RFA_DOCUMENT_URL, RFA_FORM_HISTORY_URL } from '../../../App/AppSettings';
import Section from '../../../Section';
import { fetchRequest } from '../../../Shared/Actions';
import { FIELD_SIZE } from '../../../Shared/Constants';
import { ActionButton, DataTable } from '../../../Shared/DataTable';
import { getCustomComponents } from '../../../Shared/DataTable/BaseTable.js';
import { getParams, useAsyncFetch } from '../../../Shared/Fetch';
import { Plaintext, toastError } from '../../../Shared/Forms';
import history from '../../../Shared/History';
import { Modal, ModalRemarksView } from '../../../Shared/Modal';
import FileUploadSection from '../../FormComponents/FileUploadSection';
import { useTranslation } from "react-i18next";
import { ERROR, getLangKey, LANGUAGE_KEYS } from '../../../Shared/Constants/LanguageKeys';

const IS_UPPER = true;

const MODAL_NAMES = {
    REMARKS_VIEW: "Remarks"
};

const MODAL_HEADER = {
    RFA_DETAILS: "Request For Action Details"
}

export default function RFAHistory(props) {

    const { t } = useTranslation();
    const { state } = history.location;
    const [RFAHistoryData, setRFAHistoryData] = useState([])
    const [isRFAHistoryDataLoaded, setRFAHistoryDataLoaded] = useState(false);

    const [currInternalRemarks, setCurrInternalRemarks] = useState('')
    const [currPublicResponse, setCurrPublicResponse] = useState('')
    const [supportingDocs, setSupportinDocs] = useState([])

    const response = useAsyncFetch(RFA_FORM_HISTORY_URL + state.ID, getParams());

    useEffect(() => {
        async function setData() {
            const { IsSuccess, Messages, Data } = response.body;

            if (response.success && IsSuccess) {
                let historyData = Data;
                historyData.map((rfaHistory) => {
                    let { LastUpdatedBy, LastUpdatedDate } = rfaHistory;

                    rfaHistory.LastUpdatedBy = !LastUpdatedBy ? "-" : LastUpdatedBy;
                    rfaHistory.LastUpdatedDate = !LastUpdatedDate ? "-" : LastUpdatedDate;

                    return rfaHistory;
                })
                setRFAHistoryData(historyData);
                setRFAHistoryDataLoaded(true);
            }
            else {
                // Error message to be loaded here
                toastError(t(getLangKey(ERROR.BACKEND_ERROR_MESSAGE, Messages)), Messages);
            }

        }

        if (response) {
            setData()
        }
    }, [response, t])

    const openRFAHistoryRemarks = async (modal, internalRemarks, publicResponse, rfaHistoryId) => {
        const response = await fetchRequest(RFA_DOCUMENT_URL + rfaHistoryId, getParams());
        const { IsSuccess, Messages, Data } = response.body;

        if (IsSuccess) {
            setCurrInternalRemarks(internalRemarks ? internalRemarks : '');
            setCurrPublicResponse(publicResponse ? publicResponse : '');
            setSupportinDocs(Data);
        }
        else {
            toastError(t(getLangKey(ERROR.BACKEND_ERROR_MESSAGE, Messages)), Messages);
        }

        modal.toggleModal(MODAL_NAMES.REMARKS_VIEW);
    }

    const displayModalContent = () => {
        return (
            <>
                <h5><b>{t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_TABLE_INFORMATION_REMARKS)}</b></h5>
                <Plaintext
                    name="InternalRemarks"
                    value={currInternalRemarks}
                    labelSize={FIELD_SIZE.NONE}
                />
                {(currPublicResponse !== "" || (supportingDocs && supportingDocs.length > 0)) &&
                    <>
                        <h5><b>{t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_TABLE_INFORMATION_RESPONSE)}</b></h5>
                        {currPublicResponse &&
                            <Plaintext
                                name="PublicResponse"
                                value={currPublicResponse}
                                labelSize={FIELD_SIZE.NONE}
                            />
                        }
                        {supportingDocs && supportingDocs.length > 0 &&
                            <FileUploadSection
                                name="SupportingDocuments"
                                fileList={supportingDocs}
                                getFileURL={GET_FILE_URL}
                                isUpper={IS_UPPER}
                                readonly
                            />
                        }
                    </>
                }
            </>
        )
    }

    return (
        <>
            {isRFAHistoryDataLoaded &&
                <Section
                    type={LAYOUT_TYPE.FORM_CONTENT}
                    sectionName={props.sectionName}
                    sectionState={props.sectionState}
                    toggleSection={props.toggleSection}
                >
                    <Modal render={({ modal }) => (
                        <>
                            <DataTable
                                pageSize="5"
                                minFilterChars='2'
                                data={RFAHistoryData}
                                noResultsMessage={t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_MESSAGE_PLACEHOLDER_NORFAHISTORY)}
                                components={getCustomComponents()}
                                columns={
                                    {
                                        RunningNumber: { title: t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_TABLE_TITLE_NUMBER), width: '5%' },
                                        RFAType: { title: t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_TABLE_INFORMATION_RFATYPE), width: '15%', DBkey: 'RFAType' },
                                        DueDate: { title: t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_TABLE_INFORMATION_DUEDATE), isDate: true, width: '15%', DBkey: 'DueDate' },
                                        CreatedBy: { title: t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_TABLE_INFORMATION_CREATEDBY), width: '12%', DBkey: 'CreatedBy' },
                                        CreatedDate: { title: t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_TABLE_INFORMATION_REQUESTDATE), isDate: true, width: '15%', DBkey: 'CreatedDate' },
                                        LastUpdatedBy: { title: t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_TABLE_INFORMATION_RESPONSEBY), width: '12%', DBkey: 'LastUpdatedBy' },
                                        LastUpdatedDate: { title: t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_TABLE_INFORMATION_RESPONSEDATE), isDate: true, width: '15%', DBkey: 'LastUpdatedDate' },
                                        Actions: { title: t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_TABLE_INFORMATION_ACTIONS), width: '12%', DBkey: 'Actions' }
                                    }
                                }
                                renderActions={({ RowData }) => (
                                    <>
                                        <ActionButton tooltip={t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_CONTENT_BUTTON_VIEWREMARKS)} color="forward" onClick={() => { openRFAHistoryRemarks(modal, RowData.InternalRemarks, RowData.PublicResponse, RowData.Id); }}><FontAwesomeIcon icon={faEye} /></ActionButton>
                                    </>
                                )}
                                initialSort={
                                    { id: 'CreatedDate', sortAscending: false }
                                }
                            />

                            <ModalRemarksView
                                isOpen={modal.modalState === MODAL_NAMES.REMARKS_VIEW}
                                modalClassName="rfaDetailsModal"
                                modalHeaderClassName="rfaDetailsHeaderFont"
                                contentHeader={MODAL_HEADER.RFA_DETAILS}
                                cancelText={t(LANGUAGE_KEYS.BLS_INTERNALREPORTHISTORY_CONTENT_BUTTON_CLOSE)}
                                cancelCallback={() => modal.toggleModal(MODAL_NAMES.REMARKS_VIEW)}
                                contentBody={displayModalContent()}
                            />
                        </>
                    )} />
                </Section>
            }
        </>
    )
}