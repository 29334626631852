import React, { useState, useEffect } from 'react';
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { LANGUAGE_KEYS, ERROR } from '../../../Shared/Constants/LanguageKeys';
import { DateInput, SMARTForm } from '../../../Shared/Forms';
import { DATE_DISPLAY_FORMAT } from '../../../App/AppSettings';
import { FIELD_SIZE } from '../../../Shared/Constants';
import { ActionButton, DataTable } from '../../../Shared/DataTable';
import { getCustomComponents } from '../../../Shared/DataTable/BaseTable';
import TestAccountFormModal from './TestAccountFormModal';
import { ModalConfirm } from '../../../Shared/Modal';
import { isValidForm } from '../../../Shared/Actions';
import { toastSuccess, toastError } from '../../../Shared/Forms/Toaster';
import { PREVIEW_SERVICE_SAVE_TEST_ACCOUNTS } from '../../../App/AppSettings';
import { getLangKey } from '../../DisplayComponents/DisplayUtils';

const FORM_CONTEXT = 'TestAccountCreate';

const FORM_INITIAL_VALUES = {
  LicenceName: '',
  AgencyName: '',
  CompleteTestByDate: '',
  TestAccounts: [],
};

const FORM_ERROR_INITIAL_VALUES = {
  CompleteTestByDate: '',
  TestAccounts: '',
};

const MODAL_NAMES = {
  SELF_SERVICE_ADD_TEST_ACCOUNT_POP_UP: 'Self_Service_Add_Test_Account_Pop_Up',
  SELF_SERVICE_UPDATE_TEST_ACCOUNT_POP_UP:
    'Self_Service_Update_Test_Account_Pop_Up',
  SELF_SERVICE_DELETE_TEST_ACCOUNT_POP_UP:
    'Self_service_Delete_Test_Account_Pop_Up',
};

const SERVER_URL = PREVIEW_SERVICE_SAVE_TEST_ACCOUNTS;

const ACTIVE_STATUS = 'Active';
const BLANK_STATUS = '';
const INACTIVE_STATUS = 'Inactive';
const DELETE_STATUS = ['', 'Active'];
const INTERNAL_USER_TYPE = 'Internal';

export default function TestAccountModal(props) {
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState(FORM_INITIAL_VALUES);
  const [isEdit, setIsEdit] = useState(false);
  const [updateRowData, setUpdateRowData] = useState({});
  const [deleteData, setDeleteData] = useState({});
  const [updateData, setUpdateData] = useState({});
  const [addNewModal, setAddNewModal] = useState({
    nestedModal: undefined,
  });
  const [deleteModal, setDeleteModal] = useState({
    nestedDeleteModal: undefined,
  });

  const [formErrors, setFormErrors] = useState(FORM_ERROR_INITIAL_VALUES);

  useEffect(() => {
    if (props.editTestAccounts) {
      if (props.testAccountData) {
        setFormValues((prev) => ({
          ...prev, TestAccounts: props.testAccountData,
          CompleteTestByDate: props.completeTestByDate,
          LicenceTypeId: props.licenceTypeId
        }));
      }
    }
  }, [props.editTestAccounts, props.testAccountData, props.completeTestByDate, props.licenceTypeId]);

  //Form Validation
  const FORM_VALIDATIONS = Yup.object().shape({
    TestAccounts: Yup.array()
      .required(ERROR.LABEL + t(ERROR.REQUIRED))
      .test('Minimum test accounts', t(LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_MODAL_MINIMAL_TEST_ACCOUNT_ERROR_MESSAGE),
        value => {
          return value.filter(o =>
            o.UserType === INTERNAL_USER_TYPE ||
            !o.Status ||
            o.IsDeleted
          ).length > 0
        }),
    CompleteTestByDate: Yup.string().required(ERROR.LABEL + t(ERROR.REQUIRED)),
  });

  //Check both array items are same
  const isSameArray = (a, b) => a.length === b.length && a.every((i) => b.includes(i));

  const setWorkflowUserRoleName = rowData => {
    if (rowData && rowData.WorkflowUserRoleGuid) {
      return rowData.WorkflowUserRoleGuid.split(',').map(d => {
        return props.workflowRoles.find(wr => wr.value === d).label
      }).join(', ')
    }
    return '';
  };
  //Add/Update modal
  const toggleNestedModel = (modalName) => {
    let newModalState =
      addNewModal.nestedModal !== modalName ? modalName : undefined;
    setAddNewModal(() => ({
      nestedModal: newModalState,
    }));
  };
  //Delete Modal
  const toggleDeleteModal = (modalName) => {
    let newModalState =
      deleteModal.nestedDeleteModal !== modalName ? modalName : undefined;
    setDeleteModal(() => ({
      nestedDeleteModal: newModalState,
    }));
  };

  const prepareCancel = () => {
    const data = props.editTestAccounts ? props.testAccountData : [];
    setFormValues((prev) => ({ ...prev, TestAccounts: data }));
    setFormErrors(FORM_ERROR_INITIAL_VALUES);
    props.toggle();
  };

  const prepareTestAccountSubmit = (formData, _submitForm) => {
    const isFormValid = isValidForm(FORM_VALIDATIONS, formData);
    if (isFormValid) {
      _submitForm();
    } else {
      formValidation(formData);
    }
  };

  const submitCallback = ({ response }) => {
    if (response.success) {
      const { IsSuccess, Messages } = response.body;

      if (IsSuccess) {
        toastSuccess(
          t(LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_MODAL_ADD_TEST_ACCOUNT_SUCCESS_MESSAGE),
          t(LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_MODAL_ADD_TEST_ACCOUNT_DEFAULT_MESSAGE)
        );
        const data = props.editTestAccounts ? props.testAccountData : [];
        setFormValues((prev) => ({ ...prev, TestAccounts: data }));
        setFormErrors(FORM_ERROR_INITIAL_VALUES);
        props.reloadData();
        props.toggle();
      } else {
        toastError(t(getLangKey(ERROR.BACKEND_ERROR_MESSAGE, Messages)), Messages);
      }
    } else {
      toastError(t(ERROR.SERVER_UNREACHABLE));
    }
  }

  //Manual form validation
  const formValidation = (values) => {
    console.log(values);
    FORM_VALIDATIONS.validate(values, { abortEarly: false }).catch((err) => {
      const validationErrors = err.inner.reduce((acc, errorMessage) => {
        return {
          ...acc,
          [errorMessage.path]: errorMessage.message,
        };
      }, {});
      setFormErrors((prevErrors) => ({ ...prevErrors, ...validationErrors }));
    });
  };

  //Add
  const addNewTestAccount = (data) => {
    const inactiveTestAccount = formValues.TestAccounts.find(
      (d) =>
        d.UserType === data.UserType &&
        d.Email === data.Email &&
        d.Status === INACTIVE_STATUS
    );

    if (inactiveTestAccount === undefined) {
      const newTestAccount = {
        UserType: data.UserType,
        WorkflowUserRoleGuid: data.WorkflowUserRoleGuidList.sort().join(','),
        Email: data.Email,
        Username: '',
        Status: '',
        LicenceTypeId: props.licenceTypeId,
        IsDeleted: false,
        IsCreated: true
      };
      setFormValues((prev) => ({
        ...prev,
        TestAccounts: [...prev.TestAccounts, newTestAccount],
      }));
    } else {
      //// re add an inactive test account
      const newData = formValues.TestAccounts.map((d) => {
        if (
          d.UserType === data.UserType &&
          d.Email === data.Email &&
          d.Status === INACTIVE_STATUS
        ) {
          return {
            ...d,
            Status: '',
            WorkflowUserRoleGuid: data.WorkflowUserRoleGuidList.sort().join(','),
            IsUpdated: true
          };
        } else return d;
      });

      setFormValues((prev) => ({ ...prev, TestAccounts: newData }));
    }

    toggleNestedModel(MODAL_NAMES.SELF_SERVICE_ADD_TEST_ACCOUNT_POP_UP);
  };

  //Update
  const updateTestAccount = (data) => {
    const newData = formValues.TestAccounts.map(d => {
      if (
        d.UserType === updateRowData.UserType &&
        d.Email === updateRowData.Email &&
        d.IsDeleted === false &&
        isSameArray(d.WorkflowUserRoleGuid.split(','), updateRowData.WorkflowUserRoleGuid.split(','))
      ) {
        return {
          ...d,
          UserType: data.UserType,
          Email: data.Email,
          WorkflowUserRoleGuid: data.WorkflowUserRoleGuidList.sort().join(','),
          IsUpdated: true
        };
      } else return {
        ...d
      };
    });

    setFormValues((prev) => ({ ...prev, TestAccounts: newData }));

    toggleNestedModel(MODAL_NAMES.SELF_SERVICE_UPDATE_TEST_ACCOUNT_POP_UP);
  };

  const prepareUpdateData = (rowData) => {
    setIsEdit(true);
    setUpdateRowData({
      ...rowData,
    });
    setUpdateData({
      UserType: rowData.UserType,
      Status: rowData.Status,
      Email: rowData.Email,
      WorkflowUserRoleGuidList: rowData.WorkflowUserRoleGuid.split(','),
      LicenceTypeId: props.licenceTypeId,
      IsDeleted: false
    });
    toggleNestedModel(MODAL_NAMES.SELF_SERVICE_UPDATE_TEST_ACCOUNT_POP_UP);
  };

  //Delete
  const prepareDeleteData = (rowData) => {
    setDeleteData(rowData);
    toggleDeleteModal(MODAL_NAMES.SELF_SERVICE_DELETE_TEST_ACCOUNT_POP_UP);
  };

  const confirmDeleteTestAccount = () => {
    const roles = deleteData.WorkflowUserRoleGuid.split(',');
    if (deleteData.Status === '') {
      //test accounts not yet save to DB
      const testAccounts = formValues.TestAccounts.filter(
        (d) =>
          !(
            d.Email === deleteData.Email &&
            d.UserType === deleteData.UserType &&
            isSameArray(d.WorkflowUserRoleGuid.split(','), roles)
          )
      );
      setFormValues((prev) => ({ ...prev, TestAccounts: testAccounts }));
    } else {
      //test accounts saved to DB
      const testAccounts = formValues.TestAccounts.map((d) => {
        if (
          d.Email === deleteData.Email &&
          d.UserType === deleteData.UserType &&
          d.IsDeleted === false &&
          isSameArray(d.WorkflowUserRoleGuid.split(','), roles)
        ) {
          return { ...d, IsDeleted: true, Status: INACTIVE_STATUS };
        } else return d;
      });
      setFormValues((prev) => ({ ...prev, TestAccounts: testAccounts }));
    }
    setDeleteData({});
    toggleDeleteModal(MODAL_NAMES.SELF_SERVICE_DELETE_TEST_ACCOUNT_POP_UP);

  };

  const reSetFormError = (data) => {
    setFormErrors((prev) => ({ ...prev, ...data }));
  };

  const getData = (testAccounts) => {
    //const { workflowRoles } = props;
    const addWorkfolowRoleNameColums = testAccounts.map(x => {
      return {
        ...x, workflowRoleName: setWorkflowUserRoleName(x)
      }
    }).filter(x => !x.IsDeleted);

    return [...addWorkfolowRoleNameColums];
  };

  const tableData = getData(formValues.TestAccounts);
  const minCompleteTestDate = props.completeTestByDate === null || props.completeTestByDate === ''? new Date() : props.completeTestByDate;
  return (
    <>
      <Modal
        isOpen={props.isOpen}
        className={'modal-content ' + props.modalClassName}
      >
        <SMARTForm
          titleClassName='theme-new'
          submitCallback={submitCallback}
          formContext={FORM_CONTEXT}
          validationSchema={FORM_VALIDATIONS}
          formValues={formValues}
          serverURL={SERVER_URL}
        >
          {({ onChangeField, submitForm, values }) => (
            <React.Fragment>
              <ModalHeader
                className={props.modalHeaderClassName}
                toggle={props.toggle}
                charCode='X'
              >
                {props.title}
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col>
                    <h4>
                      {props.licenceName} [{props.agencyName}]
                    </h4>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button
                      color='primary'
                      className='add-action-button'
                      type='button'
                      onClick={() =>
                        toggleNestedModel(
                          MODAL_NAMES.SELF_SERVICE_ADD_TEST_ACCOUNT_POP_UP
                        )
                      }
                    >
                      {t(LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_TABLE_BUTTON_ADDTESTER)}
                    </Button>
                  </Col>
                </Row>
                <Row className='test-accounts'>
                  <Col>
                    <DataTable
                      pageSize='5'
                      minFilterChars='2'
                      data={tableData}
                      noResultsMessage={t(LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_TABLE_NO_TEST_ACCOUNT_RESULT)}
                      components={getCustomComponents()}
                      columns={{
                        UserType: {
                          title: t(
                            LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_TABLE_TITLE_USER_TYPE
                          ),
                          width: '10%',
                          DBkey: 'UserType',
                        },
                        WorkflowUserRoleGuid: {
                          title: t(
                            LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_TABLE_TITLE_TESTERROLES
                          ),
                          width: '25%',
                          DBkey: 'workflowRoleName',
                        },
                        Username: {
                          title: t(
                            LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_TABLE_TITLE_USER_NAME
                          ),
                          width: '25%',
                          DBkey: 'Username',
                        },
                        Email: {
                          title: t(
                            LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_TABLE_TITLE_EMAIL
                          ),
                          width: '20%',
                          DBkey: 'Email',
                        },
                        Status: {
                          title: t(
                            LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_TABLE_TITLE_STATUS
                          ),
                          width: '10%',
                          DBkey: 'Status',
                        },
                        Actions: {
                          title: t(
                            LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_TABLE_TITLE_ACTIONS
                          ),
                          width: '10%',
                        },
                      }}
                      renderActions={({ RowData }) => (
                        <React.Fragment>
                          {(RowData.Status === BLANK_STATUS ||
                            (RowData.Status === ACTIVE_STATUS &&
                              RowData.UserType === INTERNAL_USER_TYPE)) && (
                              <ActionButton
                                tooltip={t(
                                  LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_TABLE_TOOLTIP_EDIT_TEST_ACCOUNT
                                )}
                                color='forward'
                                onClick={() => prepareUpdateData(RowData)}
                              >
                                <FontAwesomeIcon icon={faEdit} />
                              </ActionButton>
                            )}
                          {DELETE_STATUS.includes(RowData.Status) && (
                            <ActionButton
                              tooltip={t(
                                LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_TABLE_TOOLTIP_DELETE_TEST_ACCOUNT
                              )}
                              color='danger'
                              onClick={() => prepareDeleteData(RowData)}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </ActionButton>
                          )}
                        </React.Fragment>
                      )}
                    />
                    {formErrors.TestAccounts !== '' && (
                      <div>
                        <label className='label-feedback'>
                          {formErrors.TestAccounts}
                        </label>
                      </div>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col className='test-account-complete-by-date'>
                    <DateInput
                      name='CompleteTestByDate'
                      value={formValues.CompleteTestByDate}
                      placeholder='DD-MMM-YYYY'
                      onChangeField={(name, value) => {
                        reSetFormError({ CompleteTestByDate: '' });
                        setFormValues((prev) => ({ ...prev, CompleteTestByDate: value }));
                      }}
                      time={false}
                      date={true}
                      min={minCompleteTestDate} // Get current date
                      format={DATE_DISPLAY_FORMAT.DATE}
                      inputSize={FIELD_SIZE.MEDIUM}
                      label={t(
                        LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_TABLE_FILED_COMPLETE_TESTING_BY
                      )}
                      dropUp
                      disableKeyboardInput={true}
                      required
                      error={formErrors.CompleteTestByDate}
                    />
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                {props.cancelText && (
                  <Button
                    color='backward'
                    size='sm'
                    onClick={() => prepareCancel()}
                  >
                    {props.cancelText}
                  </Button>
                )}
                {props.saveText && (
                  <Button
                    color='success'
                    size='sm'
                    onClick={() => prepareTestAccountSubmit(values, submitForm)}
                  >
                    {props.saveText}
                  </Button>
                )}
                {props.updateText && (
                  <Button
                    color='success'
                    size='sm'
                    onClick={() => prepareTestAccountSubmit(values, submitForm)}
                  >
                    {props.updateText}
                  </Button>
                )}
              </ModalFooter>
            </React.Fragment>
          )}
        </SMARTForm>
      </Modal>
      <TestAccountFormModal
        modalClassName='theme-new'
        isOpen={
          addNewModal.nestedModal ===
          MODAL_NAMES.SELF_SERVICE_ADD_TEST_ACCOUNT_POP_UP
        }
        testAccounts={formValues.TestAccounts}
        title={t(
          LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_MODAL_TITLE_ADD_NEW_TEST_ACCOUNT
        )}
        isEdit={false}
        userTypes={props.userTypes}
        workflowRoles={props.workflowRoles}
        licenceTypeId={props.licenceTypeId}
        updateData={null}
        cancelText={t(
          LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_MODAL_BUTTON_CANCEL
        )}
        cancelCallback={() =>
          toggleNestedModel(MODAL_NAMES.SELF_SERVICE_ADD_TEST_ACCOUNT_POP_UP)
        }
        saveText={t(LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_MODAL_BUTTON_SAVE)}
        saveCallback={(data) => addNewTestAccount(data)}
      />

      <TestAccountFormModal
        modalClassName='theme-new'
        isOpen={
          addNewModal.nestedModal ===
          MODAL_NAMES.SELF_SERVICE_UPDATE_TEST_ACCOUNT_POP_UP
        }
        testAccounts={formValues.TestAccounts}
        title={t(
          LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_MODAL_TITLE_UPDATE_TEST_ACCOUNT
        )}
        isEdit={isEdit}
        userTypes={props.userTypes}
        workflowRoles={props.workflowRoles}
        licenceTypeId={props.licenceTypeId}
        updateData={updateData}
        cancelText={t(
          LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_MODAL_BUTTON_CANCEL
        )}
        cancelCallback={() => {
          setIsEdit(false);
          toggleNestedModel(
            MODAL_NAMES.SELF_SERVICE_UPDATE_TEST_ACCOUNT_POP_UP
          );
        }}
        updateText={t(LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_MODAL_BUTTON_UPDATE)}
        updateCallback={(data) => updateTestAccount(data)}
      />
      <ModalConfirm
        isOpen={
          deleteModal.nestedDeleteModal ===
          MODAL_NAMES.SELF_SERVICE_DELETE_TEST_ACCOUNT_POP_UP
        }
        contentHeader={t(
          LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_MODAL_TITLE_DELETE_TEST_ACCOUNT
        )}
        contentBody={deleteData.UserType === INTERNAL_USER_TYPE ? t(
          LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_MODEL_CONFIRM_TEST_ACCOUNT_TRUE
        ) : t(
          LANGUAGE_KEYS.BLS_INTERNALPREVIEWSERVICE_MODEL_CONFIRM_TEST_ACCOUNT_FALSE
        )}
        confirmText={t(
          LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_FIELD_YES
        )}
        confirmCallback={() => confirmDeleteTestAccount()}
        cancelText={t(
          LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_FORM_FIELD_NO
        )}
        cancelCallback={() =>
          toggleDeleteModal(MODAL_NAMES.SELF_SERVICE_DELETE_TEST_ACCOUNT_POP_UP)
        }
      />
    </>
  );
}
