import { Steps } from 'antd';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, NavLink, Row, TabContent } from 'reactstrap';
 
class WizardHeader extends React.Component {

    // Fn: Render Wizard Items
    WizardItems = ({ tabs, activeTab, toggleTab }) => {
        const Step = Steps.Step;

        return _.map(tabs, (tab, i) => {
            return (
                <Step key={i} className="wizard-step" title={tab}>
                    <NavLink
                        className= {"tab-header tab-nav-link"}
                        active={(activeTab === tab)}
                        onClick={() => toggleTab(false, false, tab, null)}
                    >
                        {tab}
                    </NavLink>
                </Step>
            );
        });
    };

    // Retrieve children of activeTab
    tabIndex = (tabsArray) => {

        // Loop through array to find index
        for (let i = 0; i < tabsArray.length; i++) {
            if (tabsArray[i] === this.props.activeTab) {
                return i;
            }
        }
    }

    // Load children of current tab
    loadTab = (currentTabIndex) => {
        return this.props.children[currentTabIndex];
    }

    loadTabContents = (tabsArray) => {
        let tabContents = [];

        // Loop through array to find index
        for (let i = 0; i < tabsArray.length; i++) {
            
            // Show only current tab
            let classname = tabsArray[i] === this.props.activeTab ? "" : "d-none";

            tabContents.push(
                <TabContent key={i} className={classname}>
                    {this.loadTab(i)}
                </TabContent>
            )
        }

        return tabContents;
    }

    render() {
        // Change Object into Array
        let tabsArray = Object.values(this.props.tabs);

        return (
            <React.Fragment>
                <Row>
                    <Col className="wizard-steps-container">
                        <Steps className={( ((this.props.children).length > 3) ? "wizard-flow" : "wizard-flex")} current={this.tabIndex(tabsArray)} >
                            {this.WizardItems(this.props)}
                        </Steps>
                    </Col>
                </Row>
                {this.loadTabContents(tabsArray)}
            </React.Fragment>
        );
    }
}

// PropTypes: For prop validation
WizardHeader.propTypes = {
    tabs: PropTypes.object.isRequired,
    activeTab: PropTypes.string.isRequired,
    toggleTab: PropTypes.func.isRequired
};


export default WizardHeader;