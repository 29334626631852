import React from "react";
import { Button, Modal, ModalHeader, ModalFooter } from "reactstrap";

function ConfirmationModal(props) {
    function onNoClick() {
        props.onModalClose();
    }

    function onYesClick() {
        props.onModalUpdate();
    }

    return <Modal className="modal-content" isOpen={props.show} backdrop={false} keyboard={false}>
        <ModalHeader>Are you sure?</ModalHeader>
        <ModalFooter>
            <Button color="success" onClick={onNoClick} outline>No</Button>
            <Button color="danger" onClick={onYesClick} outline>Yes</Button>
        </ModalFooter>
    </Modal>
}

export default ConfirmationModal;