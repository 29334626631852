import moment from 'moment';
import { default as React } from 'react';
import { Button, Col, Row } from 'reactstrap';
import * as Yup from 'yup';
import { CODES_LIST, CODETABLE_CATEGORY_LIST_URL, CODETABLE_LIST_URL, DATE_DISPLAY_FORMAT, LAYOUT_TYPE, REGEX } from '../../App/AppSettings';
import Layout from '../../Layout';
import { ERROR, SUCCESS } from '../../Localization/index';
import { fetchRequest, getParams, navigateTo } from '../../Shared/Actions';
import { FIELD_SIZE } from '../../Shared/Constants';
import { CreatableSelectList, DateInput, NumberInput, Paragraph, SelectList, SMARTForm, Text, toastError, toastSuccess, ToggleSwitchBoolean, withSMARTWrapper } from '../../Shared/Forms';
import { DEFAULT_TEXT, DEFAULT_NAMESPACE } from '../../Shared/Constants/LanguageKeys.js';
import PageFooter from '../../Shared/PageFooter';


const DROPDOWN_CATEGORY_URL = CODETABLE_CATEGORY_LIST_URL;
const DROPDOWN_CODE_URL = CODETABLE_LIST_URL;

class Form extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            values: props.values,
            server_url: props.server_url,
            methodName: props.methodName,
            genders: props.genders,
            title: props.title,
            contentHeader: props.contentHeader,
            contentBody: props.contentBody,
            next_url: props.next_url,
            dropDownArrayCategory: [],
            dropDownArrayCode: [],
            isUpdate: props.isUpdate,
            submitBtnLabel: props.submitBtnLabel
        };
    }

    componentDidMount = async () => {
        let newValues = this.state.values;
        if (newValues.CodeTableStatus && newValues.CodeTableEffectiveStartDate === "") {

            let currentDate = moment(new Date()).format(DATE_DISPLAY_FORMAT.DATE);
            newValues.CodeTableEffectiveStartDate = currentDate;
            this.setState({
                values: newValues
            });
        }
        await this.dropdownList(DROPDOWN_CATEGORY_URL, "dropDownArrayCategory");
        await this.dropdownListCode(DROPDOWN_CODE_URL, "dropDownArrayCode", this.state.values.CodeTableParentCategory);
    }

    dropdownList = async (url, dropDownName) => {
        const { loader } = this.props;
        loader.start();
        const response = await fetchRequest(url, getParams(), false);
        loader.done();
        const { Data, IsSuccess } = response.body;

        let dropDownArray = [];
        if (response.success) {
            if (IsSuccess) {
                Data.forEach(element => {
                    dropDownArray.push({ 'label': element, 'value': element });
                });
                this.setState({ [dropDownName]: dropDownArray })
            } else {
                toastError(response.body.Messages.toString());
                return null;
            }
        } else {
            toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
            return null;
        }
        return dropDownArray;
    }

    dropdownListCode = async (url, dropDownName, chosenCategory) => {
        const params = {
            category: chosenCategory
        }
        const response = await fetchRequest(url, getParams(params), false);
        const { Data, IsSuccess } = response.body;
        let dropDownArray = [];
        if (response.success) {
            if (IsSuccess) {
                Data.Data.forEach(element => {
                    dropDownArray.push({ 'label': element.CodeTitle, 'value': element.ID.toString() });
                });
                this.setState({ [dropDownName]: dropDownArray })
            } else {
                toastError(response.body.Messages.toString());
                return null;
            }
        } else {
            toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
            return null;
        }
        return dropDownArray;
    }

    // Fn: Get Yup form validations
    getFormValidations = () => {
        return Yup.object().shape({
            CodeTableCategory: Yup.string()
                .required(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_REQUIRED, ERROR.REQUIRED))
                .min(0, this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_MINLENGTH, ERROR.MIN_LENGTH_LIMIT))
                .max(50, this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_MAXLENGTH, ERROR.MAX_LENGTH_LIMIT)),
            CodeTableTitle: Yup.string()
                .required(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_REQUIRED, ERROR.REQUIRED))
                .min(0, this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_MINLENGTH, ERROR.MIN_LENGTH_LIMIT))
                .max(100, this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_MAXLENGTH, ERROR.MAX_LENGTH_LIMIT)),
            CodeTableValue: Yup.string()
                .required(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_REQUIRED, ERROR.REQUIRED))
                .min(0, this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_MINLENGTH, ERROR.MIN_LENGTH_LIMIT))
                .max(200, this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_MAXLENGTH, ERROR.MAX_LENGTH_LIMIT)),
            CodeTableDescription: Yup.string(),
            CodeTableStatus: Yup.string()
                .required(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_REQUIRED, ERROR.REQUIRED)),
            CodeTableEffectiveStartDate: Yup.string().matches(REGEX.DATE, this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_DATEINVALID, ERROR.DATE_INVALID))
                .when("CodeTableStatus", {
                    is: "true",
                    then: Yup.string()
                        .required(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_REQUIRED, ERROR.REQUIRED))
                        .matches(REGEX.DATE, this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_DATEINVALID, ERROR.DATE_INVALID))
                }),
            CodeTableEffectiveEndDate: Yup.string()
                .matches(REGEX.DATE, this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_DATEINVALID, ERROR.DATE_INVALID)),
            CodeTableSequenceNumber: Yup.number()
                .typeError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_CODESTATIC_ERROR_TITLE_SEQUENCENUMBER, ERROR.SEQUENCE_NO)),
        });
    }

    updateEffectDates = (isActive, onChangeField) => {
        let clearval = "";
        if (!isActive) {
            let newValues = this.state.values;
            newValues.CodeTableEffectiveStartDate = clearval;
            newValues.CodeTableEffectiveEndDate = clearval;
            this.setState({
                values: newValues
            });
            onChangeField("CodeTableEffectiveStartDate", clearval);
            onChangeField("CodeTableEffectiveEndDate", clearval);
        }
        else {
            let currentDate = moment(new Date()).format(DATE_DISPLAY_FORMAT.DATE);
            let newValues = this.state.values;
            newValues.CodeTableEffectiveStartDate = currentDate;
            this.setState({
                values: newValues
            });
            onChangeField("CodeTableEffectiveStartDate", currentDate);
        }

    }

    render() {
        const { isUpdate, submitBtnLabel } = this.state;
        return (
            <React.Fragment>
                <Layout type={LAYOUT_TYPE.FORM} title={this.state.title}>
                    <SMARTForm
                        formValues={this.state.values}
                        validationSchema={this.getFormValidations()}
                        validateOnSubmit={true}
                        serverURL={this.state.server_url}
                        isDummy={false}
                        submitCallback={({ response }) => {
                            if (response.success) {
                                if (response.body.IsSuccess) {
                                    navigateTo(this.state.next_url);
                                    if (isUpdate) {
                                        toastSuccess(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_CODESTATIC_SUCCESS_TITLE_UPDATE, SUCCESS.UPDATE_SUCCESS), DEFAULT_TEXT.CODE);
                                    }
                                    else {
                                        toastSuccess(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_CODESTATIC_SUCCESS_TITLE_CREATE, SUCCESS.CREATE_SUCCESS), DEFAULT_TEXT.CODE);
                                    }
                                }
                                else {
                                    toastError(response.body.Messages);
                                }
                            } else {
                                toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
                            }
                        }}
                    >
                        {
                            ({ values, errors, onChange, onChangeField, submitPlainForm }) => (
                                <React.Fragment>
                                    <Row className="body-content">
                                        <Col>
                                            <CreatableSelectList
                                                isClearable={true}
                                                name="CodeTableCategory"
                                                value={values.CodeTableCategory}
                                                placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_CODESTATIC_FORM_PLACEHOLDER_CATEGORY, DEFAULT_TEXT.CATEGORY)}
                                                minLength={0}
                                                maxLength={140}
                                                inputSize={FIELD_SIZE.LARGE}
                                                label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_CODESTATIC_FORM_TITLE_CATEGORY, DEFAULT_TEXT.CATEGORY)}
                                                helpLabel={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_CODESTATIC_FORM_HELPTEXT_CATEGORY, DEFAULT_TEXT.HELPTEXT_PARENT_CATEGORY)}
                                                required
                                                options={this.state.dropDownArrayCategory}
                                                onChangeField={onChangeField}
                                                isMulti={false}
                                                error={errors.CodeTableCategory}
                                                isDisabled={isUpdate}
                                            />
                                            <Text
                                                name="CodeTableTitle"
                                                value={values.CodeTableTitle}
                                                placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_CODESTATIC_FORM_PLACEHOLDER_TITLE, DEFAULT_TEXT.TITLE)}
                                                onChange={onChange}
                                                minLength={0}
                                                maxLength={100}
                                                inputSize={FIELD_SIZE.LARGE}
                                                label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_CODESTATIC_FORM_TITLE_TITLE, DEFAULT_TEXT.TITLE)}
                                                error={errors.CodeTableTitle}
                                                required
                                            />
                                            <Text
                                                name="CodeTableValue"
                                                value={values.CodeTableValue}
                                                placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_CODESTATIC_FORM_PLACEHOLDER_VALUE, DEFAULT_TEXT.VALUE)}
                                                onChange={onChange}
                                                minLength={0}
                                                maxLength={200}
                                                inputSize={FIELD_SIZE.LARGE}
                                                label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_CODESTATIC_FORM_TITLE_VALUE, DEFAULT_TEXT.VALUE)}
                                                error={errors.CodeTableValue}
                                                readOnly={isUpdate}
                                                required
                                            />
                                            <Paragraph
                                                name="CodeTableDescription"
                                                value={values.CodeTableDescription}
                                                placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_CODESTATIC_FORM_PLACEHOLDER_DESCRIPTION, DEFAULT_TEXT.DESCRIPTION)}
                                                onChange={onChange}
                                                rows={3}
                                                minLength={0}
                                                maxLength={200}
                                                inputSize={FIELD_SIZE.LARGE}
                                                label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_CODESTATIC_FORM_TITLE_DESCRIPTION, DEFAULT_TEXT.DESCRIPTION)}
                                                error={errors.CodeTableDescription}
                                                helpLabel={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_CODESTATIC_FORM_HELPTEXT_DESCRIPTION, DEFAULT_TEXT.HELPTEXT_DESCRIPTION)}
                                            />
                                            <SelectList
                                                isClearable={true}
                                                name="CodeTableParentCategory"
                                                value={values.CodeTableParentCategory}
                                                placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_CODESTATIC_FORM_PLACEHOLDER_PARENTCODECATEGORY, DEFAULT_TEXT.PARENT_CODE_CATEGORY)}
                                                inputSize={FIELD_SIZE.LARGE}
                                                label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_CODESTATIC_FORM_TITLE_PARENTCODECATEGORY, DEFAULT_TEXT.PARENT_CODE_CATEGORY)}
                                                options={this.state.dropDownArrayCategory}
                                                onChangeField={(name, value) => { onChangeField(name, value); this.dropdownListCode(DROPDOWN_CODE_URL, "dropDownArrayCode", value); onChangeField("CodeTableParent", ""); }}
                                                isMulti={false}
                                                error={errors.CodeTableParentCategory}
                                                helpLabel={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_CODESTATIC_FORM_HELPTEXT_PARENTCODECATEGORY, DEFAULT_TEXT.HELPTEXT_PARENT_CATEGORY)}
                                            />
                                            <SelectList
                                                isClearable={true}
                                                name="CodeTableParent"
                                                value={values.CodeTableParent}
                                                placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_CODESTATIC_FORM_PLACEHOLDER_PARENTCODE, DEFAULT_TEXT.PARENT_CODE)}
                                                inputSize={FIELD_SIZE.LARGE}
                                                label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_CODESTATIC_FORM_TITLE_PARENTCODE, DEFAULT_TEXT.PARENT_CODE)}
                                                options={this.state.dropDownArrayCode}
                                                onChangeField={onChangeField}
                                                isMulti={false}
                                                error={errors.CodeTableParent}
                                            />
                                            <ToggleSwitchBoolean
                                                name="CodeTableStatus"
                                                value={values.CodeTableStatus}
                                                onChangeField={(name, value) => { onChangeField(name, value); this.updateEffectDates(value, onChangeField) }}
                                                inputSize={FIELD_SIZE.SMALL}
                                                label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_CODESTATIC_FORM_TITLE_STATUS, DEFAULT_TEXT.STATUS)}
                                                error={errors.CodeTableStatus}
                                                trueText={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_CODESTATIC_FORM_TITLE_ACTIVE, DEFAULT_TEXT.ACTIVE)}
                                                falseText={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_CODESTATIC_FORM_TITLE_INACTIVE, DEFAULT_TEXT.INACTIVE)}
                                                defaultOn={true}
                                                required
                                            />
                                            <DateInput
                                                name="CodeTableEffectiveStartDate"
                                                value={values.CodeTableEffectiveStartDate}
                                                placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_CODESTATIC_FORM_PLACEHOLDER_EFFECTIVESTARTDATE, DEFAULT_TEXT.EFFECTIVE_START_DATE)}
                                                onChangeField={onChangeField}
                                                time={false}
                                                date={true}
                                                min="1990/01/01"
                                                format={DATE_DISPLAY_FORMAT.DATE}
                                                inputSize={FIELD_SIZE.LARGE}
                                                label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_CODESTATIC_FORM_TITLE_EFFECTIVESTARTDATE, DEFAULT_TEXT.EFFECTIVE_START_DATE)}
                                                disabled={!values.CodeTableStatus}
                                                error={errors.CodeTableEffectiveStartDate}
                                                required={values.CodeTableStatus}
                                                dropUp
                                            />
                                            <DateInput
                                                name="CodeTableEffectiveEndDate"
                                                value={values.CodeTableEffectiveEndDate}
                                                placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_CODESTATIC_FORM_PLACEHOLDER_EFFECTIVEENDDATE, DEFAULT_TEXT.EFFECTIVE_END_DATE)}
                                                onChangeField={onChangeField}
                                                time={false}
                                                date={true}
                                                min="1990/01/01"
                                                format={DATE_DISPLAY_FORMAT.DATE}
                                                inputSize={FIELD_SIZE.LARGE}
                                                label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_CODESTATIC_FORM_TITLE_EFFECTIVEENDDATE, DEFAULT_TEXT.EFFECTIVE_END_DATE)}
                                                disabled={!values.CodeTableStatus}
                                                dropUp
                                            />
                                            <NumberInput
                                                name="CodeTableSequenceNumber"
                                                value={values.CodeTableSequenceNumber}
                                                placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_CODESTATIC_FORM_PLACEHOLDER_SEQUENCENUMBER, DEFAULT_TEXT.SEQUENCE_NO)}
                                                onChangeField={onChangeField}
                                                unitOfMeasurement=""
                                                step={1.0}
                                                max={999}
                                                min={-999}
                                                defaultValue={0}
                                                inputSize={FIELD_SIZE.LARGE}
                                                label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_CODESTATIC_FORM_TITLE_SEQUENCENUMBER, DEFAULT_TEXT.SEQUENCE_NO)}
                                                error={errors.CodeTableSequenceNumber}
                                                helpLabel={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_CODESTATIC_FORM_HELPTEXT_SEQUENCENUMBER, DEFAULT_TEXT.HELPTEXT_SEQUENCE_NUMBER)}
                                            />
                                        </Col>
                                    </Row>
                                    <PageFooter>
                                        <Button color="backward" size="sm" type="button" onClick={() => navigateTo(CODES_LIST)}>
                                            {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_CODESTATIC_NAVIGATION_BUTTON_BACK, DEFAULT_TEXT.BACK)}
                                        </Button>
                                        <Button color="forward" size="sm" type="button" onClick={() => { submitPlainForm() }}>{submitBtnLabel}</Button>
                                    </PageFooter>

                                </React.Fragment>
                            )}
                    </SMARTForm>
                </Layout>
            </React.Fragment>
        );
    }
}

export default withSMARTWrapper(Form);
