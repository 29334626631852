import { FORM_ENGINE_PREVIEW_ADDITIONAL_FORM_EXCEL_URL } from '../../../App/AppSettings';
import { fetchRequest, postParams } from '../../../Shared/Actions';
import { ERROR } from '../../../Shared/Constants/LanguageKeys';
import { convertToFormData } from '../../../Shared/Forms/FormUtils';
import { toastError } from '../../../Shared/Forms/Toaster.js';

export const previewAdditionalForm = async (t, previewValues) => {

    if (!!previewValues.AdditionalApplicationDocumentFile || !!previewValues.FileName) {
        const data = convertToFormData(previewValues);
        const response = await fetchRequest(FORM_ENGINE_PREVIEW_ADDITIONAL_FORM_EXCEL_URL, postParams(data), false);

        if (response.success) {
            const { IsSuccess, Data } = response.body;
            if (IsSuccess) {
                window.open(Data, '_blank');
            } else {
                toastError(t(ERROR.FILE_PROCESS_FAIL));
            }
        } else {
            toastError(t(ERROR.SERVER_UNREACHABLE));
        }
    }
    else{
        toastError(t(ERROR.PREVIEW_NO_FILE_FOUND));
    }
};
