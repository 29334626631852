import React from 'react';
import PropTypes from 'prop-types';
import { NavItem, Collapse, Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { NavDropdownConsumer } from "./NavDropdownContext"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AuthComponent from '../Shared/Authentication/AuthComponent';
import { PATH_TO_PERMISSIONS } from '../App/AppSettings';


class NavContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            collapse: this.matchPath(this.props.location.pathname, this.props.path),
            currentPath: this.props.location.pathname,
            dropdownCollapse: false
        };
        this.renderChildren.bind(this);
    }

    // Fn: Match (location.pathname) with (this.props.path)
    matchPath = (pathname, path) => {
        var pathArray = pathname.split('/');
        var newPath = path.split('/');
        var j;
        for (j = newPath.length - 1; j >= 0; j--) {
            if (newPath[j] !== "") {
                newPath = newPath[j]
                break;
            }
        }
        var i;
        for (i = 0; i < pathArray.length; i++) {
            if (pathArray[i] === newPath) {
                return true
            }
        }
        return false;

        // return (_.includes(pathname, path)) ? true : false;
    };


    // Lifecycle Fn: Assists in re-rendering logic for NavContainer state toggling
    shouldComponentUpdate(nextProps, nextState) {
        // If navigation url has changed
        if (this.state.currentPath !== nextProps.location.pathname) {

            // Render active if path is a subset of pathname
            this.setState({
                collapse: this.matchPath(nextProps.location.pathname, nextProps.path),
                currentPath: nextProps.location.pathname
            });
        }

        return true;
    }

    //Optional font awesome icon
    getIcon = ({ icon }) => {
        const iconString = icon;
        if (!icon) {
            return "";
        } else {
            return <i aria-hidden="false"><FontAwesomeIcon className="align-middle navbar-item-icon" size="lg" fixedWidth icon={iconString} /></i>;
        }
    }

    // Fn: Toggle state of collapse
    toggle = () => {
        this.setState({ collapse: !this.state.collapse });

    }

    toggleDropdownCollapse = () => {
        this.setState({ dropdownCollapse: !this.state.dropdownCollapse });
    }

    getClassName = () => {
        return "navbar-item" + ((this.props.navbarLayout === "1D" || this.props.navbarLayout === "1M") ? " dropdown-toggle" : "")
            + (this.state.collapse ? " active" : " ")
            + (this.props.dropdownlevel !== undefined ? " dropdownlevel-" + this.props.dropdownlevel : "")
    }

    dropdownlevelDirection = () => {
        return (this.props.dropdownlevel !== undefined ? "right" : "down")
    }

    renderChildren() {
        switch (this.props.navbarLayout) {
            case "1D":
            case "1M":
            default:
                return (
                    <React.Fragment>
                        <NavItem className={this.getClassName()}
                            onClick={this.toggle} active={this.state.collapse}>
                            {this.getIcon(this.props)}
                            {this.props.name}
                        </NavItem>
                        <Collapse isOpen={this.state.collapse} className="flex-column nav-collapse">
                            {this.props.children}
                        </Collapse>
                    </React.Fragment>)

            case "2D":
            case "2M":
                return (
                    <NavDropdownConsumer>
                        {({ navDropdown, setNavDropdown }) =>
                            <React.Fragment>
                                <div className={"overlay" + (this.state.dropdownCollapse && (navDropdown === this.props.navPath) ? " overlay-appear" : "")} id="overlayContainer"></div>
                                <Dropdown
                                    isOpen={(this.state.dropdownCollapse && (navDropdown === this.props.navPath))}
                                    //fix for 
                                    //async setState necessitate this toggle else statement, tried to do "this.setState(prevState => ({ dropdownCollapse: !prevState.dropdownCollapse }))" but got "too many nested updates" error
                                    toggle={(navDropdown === this.props.navPath ? this.toggleDropdownCollapse : () => { this.setState({ dropdownCollapse: true }) })}
                                    direction={this.dropdownlevelDirection()}
                                >
                                    <DropdownToggle className="toggle-dropdown" tag="div" data-toggle="dropdown" data-boundary="viewport" aria-expanded="false">
                                        <NavItem
                                            className={this.getClassName()}
                                            onClick={() => setNavDropdown(this.props.navPath)}
                                        >
                                            {this.getIcon(this.props)}
                                            <div className="navbar-text">{this.props.name}</div>
                                        </NavItem>
                                    </DropdownToggle>
                                    <DropdownMenu className="navbar-header-dropdown">
                                        {this.props.children}
                                    </DropdownMenu>
                                </Dropdown>
                            </React.Fragment>
                        }
                    </NavDropdownConsumer>
                )
        }
    }

    render() {
        const { path } = this.props;
        let permissions = PATH_TO_PERMISSIONS[path];
        permissions = permissions === undefined ? []: permissions;

        return (
            <AuthComponent permissions={permissions}>
                {this.renderChildren()}
            </AuthComponent>
        );
    }
}

NavContainer.propTypes = {
    name: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,   // Trailing path, to be matched with location.pathname
    dropdownlevel: PropTypes.string
};


export default withRouter(NavContainer);