import React from 'react';
import { Col, Row } from 'reactstrap';
import SectionWrapper from '../../../SectionWrapper';
import { SECTION_WRAPPER_TYPES } from '../../../Shared/Constants';
import { SMARTView } from '../../../Shared/Forms';
import { ApplicationInfo, CheckInCheckOutHistory, FormHistory, GeneralInfo, PaymentInfo, RFAHistory, SupportingDocuments } from '../Components';

const SECTION_LAYOUT_TYPE = SECTION_WRAPPER_TYPES.TAB;

const SECTION_NAMES = {
	GENERAL_INFORMATION: `General`,
	APPLICATION_INFORMATION: `Application`,
	SUPPORTING_DOCUMENTS: `Documents`,
	PAYMENT_INFORMATION: `Payment`,
	FORM_HISTORY: `History`,
	RFA_HISTORY: `RFA`,
	CHECK_IN_CHECK_OUT_HISTORY: `Check-Outs`
};

export default function LicenceDetailsView(props) {

    return (
        <SMARTView
            sectionNames={SECTION_NAMES}
            defaultSection={SECTION_NAMES.GENERAL_INFORMATION}
        >
            {({ sectionState, toggleSection }) => {

                return (
                    <>
                        <Row className="body-content internal-content">
                            <Col className="body-section-col">

                                <SectionWrapper
                                    type={SECTION_LAYOUT_TYPE}
                                    tabs={SECTION_NAMES}
                                    sectionState={sectionState}
                                    toggleSection={toggleSection}
                                >
                                    {/* General Info */}
                                    <GeneralInfo
                                        toggleSection={toggleSection}
                                        sectionState={sectionState}
                                        sectionName={SECTION_NAMES.GENERAL_INFORMATION}
                                    />

                                    {/* Application Info */}
                                    <ApplicationInfo
                                        applicationInfoComponent={props.applicationInfoComponent}
                                        toggleSection={toggleSection}
                                        sectionState={sectionState}
                                        sectionName={SECTION_NAMES.APPLICATION_INFORMATION}
                                    />

                                    {/* Supporting Docs */}
                                    <SupportingDocuments
                                        toggleSection={toggleSection}
                                        sectionState={sectionState}
                                        sectionName={SECTION_NAMES.SUPPORTING_DOCUMENTS}
                                    />

                                    {/* Payment Information */}
                                    <PaymentInfo
                                        toggleSection={toggleSection}
                                        sectionState={sectionState}
                                        sectionName={SECTION_NAMES.PAYMENT_INFORMATION}
                                    />

                                    {/* Form History */}
                                    <FormHistory
                                        toggleSection={toggleSection}
                                        sectionState={sectionState}
                                        sectionName={SECTION_NAMES.FORM_HISTORY}
                                    />

                                    {/* RFA history */}
                                    <RFAHistory
                                        toggleSection={toggleSection}
                                        sectionState={sectionState}
                                        sectionName={SECTION_NAMES.RFA_HISTORY}
                                    />

                                    {/* Check-In-Check-Out history */}
                                    <CheckInCheckOutHistory
                                        key={props.toggleCheckInCheckOut}
                                        toggleSection={toggleSection}
                                        sectionState={sectionState}
                                        sectionName={SECTION_NAMES.CHECK_IN_CHECK_OUT_HISTORY}
                                    />
                                </SectionWrapper>
                            </Col>
                        </Row>
                    </>
                )
            }}
        </SMARTView>
    )
}