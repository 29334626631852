import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { ERROR, LANGUAGE_KEYS } from '../../../../../Shared/Constants/LanguageKeys';
import { isValidForm } from '../../../../../Shared/Actions';
import { FIELD_SIZE } from '../../../../../Shared/Constants';
import { Paragraph } from '../../../../../Shared/Forms';
import { ModalConfirm } from '../../../../../Shared/Modal';
import { useTranslation } from 'react-i18next';

const MODAL_NAMES = {
    SUBMIT: 'Submit'
};


export default function SubmitRemarks(props) {
    const command = props.command;
    const modal = props.modal;
    const isCheckOut = props.isCheckOut;
    const clearCommand = props.clearCommand;

    const [on, setOn] = useState(command && modal && isCheckOut);
    const { values, submitForm, onChange } = props.smartFormValues;
    const [remarksError, setRemarksError] = useState(false);
    const { t } = useTranslation();

    const FORM_VALIDATIONS = Yup.object().shape({
        Remarks: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED))
    });

    useEffect(() => {
        const toggle = () => setOn(!on);

        if (on) {
            modal.toggleModal(MODAL_NAMES.SUBMIT);
            toggle();
        }
    }, [on, modal]);

    const prepareToSubmit = async (_submitForm) => {
        if (isValidForm(FORM_VALIDATIONS, values)) {
            values.command = command;
            modal.toggleModal(MODAL_NAMES.SUBMIT);
            setRemarksError(false);
            await _submitForm();
        } else {
            setRemarksError(true);
        }
    };

    const displayModalContent = () => {
        return (
            <Paragraph
                name="Remarks"
                value={values.Remarks}
                placeholder={t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_TABLE_INFORMATION_REMARKS)}
                onChange={onChange}
                rows={3}
                minLength={0}
                maxLength={140}
                inputSize={12}
                labelSize={FIELD_SIZE.XSMALL}
                label={t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_TABLE_INFORMATION_REMARKS)}
                error={!values['Remarks'] && remarksError ? t(ERROR.REMARKS_REQUIRED) : ''}
                required
            />
        );
    };


    return (
        <>
            {modal &&
                <ModalConfirm
                    className="modal-remarks"
                    isOpen={modal.modalState === MODAL_NAMES.SUBMIT}
                    contentHeader={command}
                    confirmText={t(LANGUAGE_KEYS.BLS_INTERNALGENERAL_FORM_BUTTON_CONFIRM)}
                    confirmCallback={() => { prepareToSubmit(submitForm); }}
                    cancelText={t(LANGUAGE_KEYS.BLS_INTERNALGENERAL_FORM_BUTTON_CANCEL)}
                    cancelCallback={() => {
                        modal.toggleModal(MODAL_NAMES.SUBMIT);
                        setTimeout(function () { clearCommand(); }, 1000);
                    }}
                    contentBody={
                        displayModalContent()
                    }
                />
            }
        </>
    );
}
