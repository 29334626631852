import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Card, CardBody, Col, Collapse, Row } from 'reactstrap';
import { ActionButton } from '../../Shared/DataTable';
import './Faq.css';

class AccordionTopicSubTopic extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

            active: false,
            collapseIcon: faAngleDown
        };

    }

    Caret = () => {
        return (this.props.active) ? faAngleUp : faAngleDown;
    };

    toggleCollapse = () => {
        this.setState((prevState) => {
            return {
                active: (!prevState.active),
                collapseIcon: ((prevState.active) ? (faAngleDown) : (faAngleUp))
            }
        });
    };

    render() {
        const { topic } = this.props;
        return (
            <>
                <Card className="card-container">
                    <CardBody className="key-card-body">
                        <Row>
                            <Col xs="3">
                                <div className="key-card-action-button action-button-group">
                                    <ActionButton className="caret-icon" tooltip="Display Answer" color="link" onClick={() => this.toggleCollapse()}>
                                        <FontAwesomeIcon fixedWidth icon={this.state.collapseIcon} />
                                    </ActionButton>
                                </div>
                            </Col>
                            <Col xs="9" className="accordion-topic">
                                <div><b>{topic.TopicName}</b></div>
                            </Col>
                        </Row>

                        <Collapse isOpen={this.state.active}>
                            <Row >
                                <Col xs={{ size: 8, offset: 1 }}>
                                    {topic.SubTopics.map((subTopic, subTopicIndex) => (

                                        <div key={"subTopic" + subTopicIndex}>
                                            <div className="accordion-sub-topic" onClick={() => this.props.handleOnClickSubTopic(subTopic.QuestionSets, topic.TopicName, subTopic.SubTopicName)}>
                                                {subTopic.SubTopicName}
                                            </div>
                                        </div>
                                    ))}
                                </Col>
                            </Row>
                        </Collapse>
                    </CardBody>
                </Card>

            </>
        );
    }
}

export default AccordionTopicSubTopic;