import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import _ from 'lodash';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { ActionButton} from '../DataTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from "react-i18next";
import { LANGUAGE_KEYS } from '../Constants/LanguageKeys';

class FileUploadSectionViewer extends React.Component {

    // Fn: Get classname based on if Description is enabled
    getClassname = (props) => {
        let classname = props.disableDescription ? "docs-table-no-desc-col" : "docs-table-normal-col";
        return classname;
    }

    // Fn: Render uploaded formik files
    UploadedFiles = ({fileName, fileList, onChangeField, readonly, isUpper, disableDescription}) => {
        let classname = disableDescription ?  "docs-table-no-desc-col" : "docs-table-normal-col";
        let tableNo = 0;
        let activeFileList = _.filter(fileList, function (f) {
            return !f.IsDeleted;
        });

        if (activeFileList !== '' && activeFileList !== undefined && activeFileList !== null && activeFileList.length !== 0) {
            return _.map(fileList, (fileItem, i) => {
                if (fileItem.IsDeleted) return null; // Do not display IsDeleted
                return (
                    /*  i is the index of the fileList, which includes deleted file items.
                        tableNo is the key for each row (regardless of deleted file items), which is also used to display table number. */
                    <tbody key={tableNo+=1} className="docs-table-body">
                        <tr>
                            <td className="docs-table-id">{tableNo}</td>
                            {(readonly) ?
                                (null)
                                :
                                (<td className="docs-table-action">
                                    <ActionButton 
                                        className="file-delete-btn"
                                        tooltip="Delete" 
                                        color="danger" 
                                        onClick={() => this.removeFile(fileName, fileList, onChangeField, i)}
                                    >
                                        <FontAwesomeIcon icon={faTrash} />
                                    </ActionButton>
                                </td>)
                            }
                            {(disableDescription) ? 
                                (null)
                                :
                                (<td className="docs-table-desc">
                                    {(fileItem.FileDescription !== undefined) ?
                                        (<a href={this.props.getFileURL + "id=" + fileItem.Id + "&fileName=" + fileItem.Filename} className="file-upload-text">{
                                        isUpper ? (fileItem.FileDescription).toUpperCase() : fileItem.FileDescription }</a>) : 
                                        isUpper ? (fileItem.description).toUpperCase() : fileItem.description
                                    }
                                </td>)}
                            <td className= {classname}>
                                {((fileItem.ContentLength !== undefined) ?
                                    fileItem.ContentLength
                                    : (fileItem.size / 1000)).toFixed(1)
                                    + " KB"
                                }
                            </td>
                            <td className={classname}>
                                {((fileItem.ContentType !== undefined) ?
                                    fileItem.ContentType
                                    : (/[^.]+$/.exec(fileItem.name)[0])
                                ).toUpperCase()
                                }
                            </td>
                        </tr>
                    </tbody>
                );
            });
        } else {
            return (
                <tbody className="docs-table-body-nofiles">
                    <tr className="docs-table-text-body">
                        <td colSpan={(this.props.readonly) ? "4" : "5"}
                            className="docs-table-text">No files have been uploaded</td>
                    </tr>
                </tbody>
            )
        }
    };

    // Fn: Remove the file object based on given array index
    removeFile = (fileName, fileList, onChangeField, index) => {
        let fileItem = fileList[index];

        // Marks file as deleted
        fileItem.IsDeleted = true;

        if (fileItem instanceof File) {
            // Removes 1 file from the given index
            fileList.splice(index, 1);
        }

        // Updates changes in parent state
        onChangeField(fileName, fileList);

        // Updates File Limit error in Adder component
        this.props.hasFileLimitReached();
    };

    render() {
        const {t} = this.props;

        return (
            <div className={(this.props.readonly) ? "docs-table-viewer form-group" : "docs-table form-group"}>
                <Table bordered size="sm" className="table-fixed">
                    <thead className="docs-table-header">
                        <tr>
                            <th className="docs-table-id">{t(LANGUAGE_KEYS.BLS_INTERNALLANGPACKUPDATE_TABLE_TITLE_NUMBER)}</th>

                            {(this.props.readonly) ?
                                (null) :
                                (<th className="docs-table-action">{t(LANGUAGE_KEYS.BLS_INTERNALLANGPACKUPDATE_TABLE_TITLE_ACTION)}</th>)
                            }
                            <th className="docs-table-desc">{t(LANGUAGE_KEYS.BLS_INTERNALLANGPACKUPDATE_TABLE_TITLE_DESCRIPTION)}</th>
                            <th className="docs-table-normal-col">{t(LANGUAGE_KEYS.BLS_INTERNALLANGPACKUPDATE_TABLE_TITLE_SIZE)}</th>
                            <th className="docs-table-normal-col">{t(LANGUAGE_KEYS.BLS_INTERNALLANGPACKUPDATE_TABLE_TITLE_TYPE)}</th>
                        </tr>
                    </thead>

                    {this.UploadedFiles(this.props)}

                </Table>
            </div >
        );
    }
}

// PropTypes: For prop validation
FileUploadSectionViewer.propTypes = {
    name: PropTypes.string.isRequired,
    fileList: PropTypes.array,
    getFileURL: PropTypes.string,
    onChangeField: PropTypes.func,                      // Required if readonly={false}
    hasFileLimitReached: PropTypes.func,                // Required if readonly={false}
    isUpper: PropTypes.bool,
    readonly: PropTypes.bool
};

// PropTypes: Defaulting value for optional props
FileUploadSectionViewer.defaultProps = {
    fileList: [],
    onChangeField: () => { },
    hasFileLimitReached: () => { },
    readonly: false,
};


export default withTranslation()(FileUploadSectionViewer);