import React from 'react';
import { withTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import {
	GET_ADDITIONAL_INFORMATION_DATA_FROM_SFORMID_URL,
	GET_FORM_LICENCE_DETAILS_FROM_SFORMID_URL,
	GET_FORM_URL, LAYOUT_TYPE
} from '../.././App/AppSettings';
import Layout from '../../Layout';
import SectionWrapper from '../../SectionWrapper';
import { fetchRequest, getParams } from '../../Shared/Actions';
import { SECTION_WRAPPER_TYPES } from '../../Shared/Constants';
import { ERROR, getLangKey, LANGUAGE_KEYS } from '../../Shared/Constants/LanguageKeys';
import { SMARTView, toastError } from '../../Shared/Forms';
import history from '../../Shared/History';
import { withLoader } from '../../Shared/Loading';
import { withModalWrapper } from '../../Shared/Modal/ModalWrapper';
import { getLicenceTypeNameTranslationKey } from '../DisplayComponents/DisplayUtils';
import { additionalFormRegex } from '../FormComponents/FormUtils';
import {
	ApplicationInfo,
	CheckInCheckOutHistory,
	FormHistory,
	FormProcessing,
	GeneralInfo,
	PaymentInfo,
	RFAHistory,
	SupportingDocuments,
	ViewAdditionalInformation
} from './Components';

const SECTION_LAYOUT_TYPE = SECTION_WRAPPER_TYPES.TAB;
class FormDetails extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoaded: false,
			licence: {}
		};
	}

	async getAmendLicence() {
		const { ID } = history.location.state;
		const response = await fetchRequest(GET_FORM_URL + ID, getParams());
		const { IsSuccess, Data } = response.body;
		if (response.success && IsSuccess) {
			const applicationDetails = Object.assign({}, Data, { FormData: JSON.parse(Data.FormData) });

			this.setState(() => {
				return {
					applicationDetails
				};
			});
		}
	}

	async componentDidMount() {
		this.props.loader.start();

		await this.getAmendLicence();
		await this.getAdditionalInformationData();
		await this.getLicenceDetails();
		const retrievedValues = this.state.applicationDetails.FormData.LicenceInformation;

		this.setState({
			values: Object.assign({}, this.state.values, retrievedValues),
			isLoaded: true
		});

		this.props.loader.done();
	}


	async getLicenceDetails() {
		const { ID } = history.location.state;
		const { t } = this.props;
		const response = await fetchRequest(GET_FORM_URL + ID, getParams());
		const { IsSuccess, Data } = response.body;
		if (response.success && IsSuccess) {
			const applicationDetails = Object.assign({}, Data, { FormData: JSON.parse(Data.FormData) });
			if (applicationDetails.ApplicationType !== 'Apply') {
				await this.getLicenceData();
			}
			const licenceTypeNameTranslationKey = getLicenceTypeNameTranslationKey(applicationDetails.LicenceTypeName);
			const translatedLicenceName = t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY, licenceTypeNameTranslationKey, applicationDetails.LicenceTypeId));
			this.setState(() => {
				return {
					ID,
					applicationDetails,
					translatedLicenceName
				};
			});
		}
	}

	getLicenceData = async () => {
		const { t } = this.props;
		const { ID } = history.location.state;
		const response = await fetchRequest(GET_FORM_LICENCE_DETAILS_FROM_SFORMID_URL + ID, getParams(), false);
		const { IsSuccess, Data } = response.body;

		if (IsSuccess) {

			this.setState(prevState => {
				return {
					licence: Object.assign(prevState.licence, Data, { LicenceData: JSON.parse(Data.LicenceData) })
				};
			});
		} else {
			toastError(t(ERROR.SERVICE_DATA_NOT_FETCHED));
		}
	};

	getAdditionalInformationData = async () => {
		const { ID } = history.location.state;
		const response = await fetchRequest(GET_ADDITIONAL_INFORMATION_DATA_FROM_SFORMID_URL + ID, getParams(), false);
		const { IsSuccess, Data } = response.body;

		if (IsSuccess && Data.length > 0) {
			const groupedAdditionalFormDefinition = Data.reduce((allAFD, {
				AdditionalFormData, WorkflowStateId, AdditionalFormDefinition, CreatedDate, ProcessedBy, AdditionalFormDocument
			}) => {
				if (!allAFD[WorkflowStateId]) {
					allAFD[WorkflowStateId] = [];
				}
				const additionalFormDefinitionsObj = {};
				additionalFormDefinitionsObj['Values'] = AdditionalFormData.DataList;
				additionalFormDefinitionsObj['AdditionalFormDefinition'] = AdditionalFormDefinition;
				additionalFormDefinitionsObj['CreatedDate'] = CreatedDate;
				additionalFormDefinitionsObj['ProcessedBy'] = ProcessedBy;
				additionalFormDefinitionsObj['SupportingDocument'] = AdditionalFormDocument;
				allAFD[WorkflowStateId].push(additionalFormDefinitionsObj);
				return allAFD;
			}, {});

			this.setState({
				groupedAdditionalFormDefinition
			});
		}
	};

	addToObject = (obj, key, value, index) => {
		// Create a temp object and index variable
		const temp = {};

		// Loop through the original object
		for (const prop in obj) {
			if (obj.hasOwnProperty(prop)) {
				// If the indexes match, add the new item
				if (prop === index && key && value) {
					temp[key] = value;
				}
				// Add the current item in the loop to the temp obj
				temp[prop] = obj[prop];
			}
		}

		// If no index, add to the end
		if (!index && key && value) {
			temp[key] = value;
		}
		return temp;
	};

	render() {
		const { t } = this.props;

		let SECTION_NAMES = {
			GENERAL_INFORMATION: t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_CONTENT_PAGINATION_GENERAL),
			APPLICATION_INFORMATION: t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_CONTENT_PAGINATION_APPLICATION),
			SUPPORTING_DOCUMENTS: t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_CONTENT_PAGINATION_DOCUMENTS),
			PAYMENT_INFORMATION: t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_CONTENT_PAGINATION_PAYMENT),
			FORM_HISTORY: t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_CONTENT_PAGINATION_HISTORY),
			RFA_HISTORY: t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_CONTENT_PAGINATION_RFA),
			CHECK_IN_CHECK_OUT_HISTORY: t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_CONTENT_PAGINATION_CHECKOUTS)
		};

		const { groupedAdditionalFormDefinition, values, isLoaded, applicationDetails, licence, translatedLicenceName } = this.state;
		
		if (groupedAdditionalFormDefinition) {
			Object.keys(groupedAdditionalFormDefinition).forEach(key =>
				groupedAdditionalFormDefinition[key].forEach(formDefinition => {
					const tabSectionName = formDefinition['AdditionalFormDefinition'].FormType;
					SECTION_NAMES = this.addToObject(SECTION_NAMES, t(getLangKey(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_CONTENT_TITLE_KEY, additionalFormRegex(tabSectionName))),
						t(getLangKey(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_CONTENT_TITLE_KEY, additionalFormRegex(tabSectionName))), 'PAYMENT_INFORMATION');
				})
			);
		}
		const supportingDocuments = applicationDetails?.SupportingDocuments;
		const licenceDocuments = applicationDetails?.LicenceDocuments;
		const documentsFailedToGenerate = applicationDetails?.DocumentsFailedToGenerate;

		return (
			<React.Fragment>
				<SMARTView
					sectionNames={SECTION_NAMES}
					defaultSection={SECTION_NAMES.GENERAL_INFORMATION}
				>
					{({ sectionState, toggleSection }) => (
						isLoaded && <Layout type={LAYOUT_TYPE.FORM}
							title={translatedLicenceName}
							footer={false}
							titleClassName="sticky-title"
							titleChildren={
								<FormProcessing
									isAction={false}
									smartFormValues={this.state}
								/>
							}>
							{this.state.isLoaded && <React.Fragment>
								<Row className="body-content">
									<Col>
										{documentsFailedToGenerate && documentsFailedToGenerate.length > 0 &&
											<p className="ml-3 text-danger text-left">
												{t(ERROR.UNABLETOGENARATEDOCUMENT) + " " + documentsFailedToGenerate.join(', ')}
												<br />
												{t(ERROR.CONTACTAGENCYFORSUPPORT)}
											</p>
										}
										<SectionWrapper
											type={SECTION_LAYOUT_TYPE}
											tabs={SECTION_NAMES}
											sectionState={sectionState}
											toggleSection={toggleSection}
										>

											{/* General Info */}
											<GeneralInfo
												toggleSection={toggleSection}
												sectionState={sectionState}
												sectionName={SECTION_NAMES.GENERAL_INFORMATION}
											/>

											{/* Application Info */}
											<ApplicationInfo
												applicationDetails={applicationDetails}
												values={values}
												toggleSection={toggleSection}
												sectionState={sectionState}
												sectionName={SECTION_NAMES.APPLICATION_INFORMATION}
												licence={licence}
											/>

											{/* Supporting Docs */}
											<SupportingDocuments
												toggleSection={toggleSection}
												sectionState={sectionState}
												sectionName={SECTION_NAMES.SUPPORTING_DOCUMENTS}
												supportingDocData={supportingDocuments}
												licenceDocuments={licenceDocuments}
												showSelectDocumentType={true}
											/>

											{/* View Additional Information */}
											{
												groupedAdditionalFormDefinition && Object.keys(groupedAdditionalFormDefinition).map((key, index) => {
													return (
														<ViewAdditionalInformation
															key={index}
															additionalFormDefinition={groupedAdditionalFormDefinition[key]}
															toggleSection={toggleSection}
															sectionState={sectionState}
														/>
													);
												})
											}

											{/* Payment Information */}
											<PaymentInfo
												toggleSection={toggleSection}
												sectionState={sectionState}
												sectionName={SECTION_NAMES.PAYMENT_INFORMATION}
											/>

											{/* Form History */}
											<FormHistory
												toggleSection={toggleSection}
												sectionState={sectionState}
												sectionName={SECTION_NAMES.FORM_HISTORY}
											/>

											{/* RFA history */}
											<RFAHistory
												toggleSection={toggleSection}
												sectionState={sectionState}
												sectionName={SECTION_NAMES.RFA_HISTORY}
											/>

											{/* Check-In-Check-Out history */}
											<CheckInCheckOutHistory
												//key={props.toggleCheckInCheckOut}
												toggleSection={toggleSection}
												sectionState={sectionState}
												sectionName={SECTION_NAMES.CHECK_IN_CHECK_OUT_HISTORY}
											/>
										</SectionWrapper>
									</Col>
								</Row>
							</React.Fragment>}
						</Layout>
					)}
				</SMARTView>
			</React.Fragment>
		);
	}
}

export default withTranslation()(withLoader(withModalWrapper(FormDetails)));
