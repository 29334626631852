import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input } from 'reactstrap';
import _ from 'lodash';
import { withBaseControl } from './BaseControl';


class Checkbox extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            counter: 0
        };
    }

    componentDidUpdate(prevProps) {
        const { name, onChangeField, value } = this.props;
        if (prevProps.value !== value) {
            if (value !== "") { // value is not empty
                if (Array.isArray(value)) { // already in correct array format
                    this.setState({
                        counter: value.length
                    })
                }
                else if (typeof value === 'string' || value instanceof String) {
                    // require convert from string to array
                    let valueArr = value.split(",");
                    this.setState({
                        counter: valueArr.length
                    })
                    onChangeField(name, valueArr);
                }
            }
            else { // nothing in value
                this.setState({
                    counter: 0
                })
            }
        }
    }

    // Fn: Handle changes in checkbox group 
    handleCheckboxChange = (onChangeField, name, valuesArr, value, isChecked) => {

        // If checked, add item into array (non-unique),
        // Otherwise, remove item from array
        let itemsChecked = [];
        if (isChecked) {
            if (!this.props.limit) {
                itemsChecked = [...valuesArr, value]
            }
            else {
                //check for limit reached - Dom
                if (this.state.counter >= this.props.limit) {
                    itemsChecked = valuesArr;
                }
                else {
                    this.setState((prevState) => ({
                        counter: prevState.counter + 1
                    }))
                    itemsChecked = [...valuesArr, value]
                }
            }
        }
        else {
            if (!this.props.limit) {
                itemsChecked = _.without(valuesArr, value)
            }
            else {
                this.setState((prevState) => ({
                    counter: prevState.counter - 1
                }))
                itemsChecked = _.without(valuesArr, value)
            }
        }

        // Update changes in Formik handleChange event
        onChangeField(name, itemsChecked);
    };

    // Fn: Return whether checkbox has been checked on retrieval
    isChecked = (valuesArr, value) => {
        return (valuesArr && _.includes(valuesArr, value)) ? true : false;
    };

    checkboxLabel = () => {
        return (this.props.labelRequired ? "checkbox-label required" : "checkbox-label")
    }

    render() {
        // De-structure props to be used
        const { name, options, onChangeField, value } = this.props;

        // For each option item
        return (options.map((option) => {
            var id = name + option.value
            id = id.replace(/\s/g, '');
            // Render an inline checkbox
            return (
                <FormGroup key={option.value} check inline>

                    <Label className="checkbox-group" check>
                        {/* Hide original Checkbox UI, but all react behavior still happens here */}
                        <Input
                            /* for checkboxes that do not have a limit set or if is check */
                            className={(!this.props.limit || this.isChecked(value, option.value)) ? "checkbox-control" :
                                /* check for box that limit is reached */
                                (this.state.counter >= this.props.limit) ? "checkbox-control disabled" : "checkbox-control"}
                            type="checkbox"
                            id={id}
                            name={name}
                            value={option.value}
                            onChange={(e) => this.handleCheckboxChange(onChangeField, name, value, option.value, e.target.checked)}
                            checked={this.isChecked(value, option.value)}
                        />

                        {/* Re-styled Checkbox UI for cross-browser compatibility */}
                        <Label for={id} className="checkbox-display"></Label>

                        {/* Checkbox Text */}
                        <Label for={id} className={this.checkboxLabel()}>{option.label}</Label>
                    </Label>
                </FormGroup>
            );
        }));
    }
}

// PropTypes: For prop validation
Checkbox.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    options: PropTypes.array.isRequired,
    onChangeField: PropTypes.func.isRequired
};

// PropTypes: Defaulting value for optional props
Checkbox.defaultProps = {
    value: [],
    limit: 0
};


export default withBaseControl(Checkbox);

