import { faEllipsisH, faAngleLeft, faAngleRight, faCaretDown, faCaretRight, faCircle, faEdit, faEye, faAngleDoubleDown, faAngleDoubleUp, faPlusSquare, faTimesCircle, faTrash, faList } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Breadcrumb, Spin } from 'antd';
import _ from 'lodash';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Card, CardBody, CardDeck, CardHeader, Col, DropdownItem, Label, Row } from 'reactstrap';
import {
    ADD_FAQ_QUESTION_SET_URL, ADD_FAQ_SUB_TOPIC_URL, ADD_FAQ_TOPIC_URL,
    DELETE_FAQ_QUESTION_SET_URL, DELETE_FAQ_SUB_TOPIC_URL, DELETE_FAQ_TOPIC_URL, EDIT_FAQ_QUESTION_SET_URL, EDIT_FAQ_SUB_TOPIC_URL, EDIT_FAQ_TOPIC_URL, EDIT_FAQ_URL,
    GET_FAQ_QUESTION_SET_LIST_URL, GET_FAQ_QUESTION_SET_URL, GET_FAQ_SUB_TOPIC_URL,
    GET_FAQ_TOPIC_URL, GET_FAQ_TREE_URL,
    GET_FAQ_URL, SORT_FAQ_RANK_URL, SEARCH_FAQ_URL
} from '../../App/AppSettings';
import { ERROR, SUCCESS } from '../../Localization/index';
import { getCustomComponents } from '../../Shared/DataTable/BaseTable.js';
import { deleteParams, fetchRequest, getParams, postParams } from '../Actions';
import { FIELD_SIZE, ORIENTATION, RICHTEXT } from '../Constants';
import { ActionButton, DataTable } from '../DataTable';
import { MenulessCreatableSelectList, Paragraph, Plaintext, RichText, RichTextEditor, SMARTSubForm, Text, ToggleSwitch, withSMARTWrapper } from '../Forms';
import { toastError, toastSuccess } from '../Forms/Toaster.js';
import { DEFAULT_TEXT, DEFAULT_NAMESPACE } from '../../Shared/Constants/LanguageKeys.js';
import { ModalConfirm } from '../Modal';
import { Select } from 'antd';
import TreeMenuContainer from './TreeMenuContainer';
import TreeMenuItem from './TreeMenuItem';
import './FaqTree.css';
import { DELETE_MODAL, FORM_CONTEXT,
    FORM_INITIAL_VALUES_FAQ_EDIT, FORM_INITIAL_VALUES_FAQ_QUESTION_SET_EDIT,
    FORM_INITIAL_VALUES_FAQ_QUESTION_SET_VIEW,
    FORM_INITIAL_VALUES_FAQ_SUB_TOPIC_EDIT, FORM_INITIAL_VALUES_FAQ_SUB_TOPIC_VIEW, FORM_INITIAL_VALUES_FAQ_TOPIC_EDIT,
    FORM_INITIAL_VALUES_FAQ_TOPIC_VIEW, FORM_INITIAL_VALUES_FAQ_VIEW, FORM_INITIAL_VALUES_QUESTION_SET, FORM_INITIAL_VALUES_SUB_TOPIC, 
    FORM_INITIAL_VALUES_TOPIC, UPDATE_TREE_STATUS
} from './TreeConstants';

// Documentation:
// 1. Node - Each element on the tree
// 2. FAQ - First node; i.e. Parent node
// 3. Topic, Sub-Topic - Child nodes of a FAQ node; Sub-Topic being the final node.
// 4. FAQ, Topic & Sub-Topic nodes have the function to add child nodes. e.g. A chapter node can add multiple section nodes.
// 5. All nodes on the tree have at least 3 functions - View, Edit, Delete.
// 6. Sort function - parent nodes & nodes with the same parent can be sorted.

const FAQ = "Faq";
const FAQ_TOPIC = "Faq_Topic";
const FAQ_SUB_TOPIC = "Faq_SubTopic";
const FAQ_QUESTION_SET = "Faq_QuestionSet";

const DRAFT_STATUS = "Draft";
const LABEL_SIZE = 4;
const INPUT_SIZE = 8;
const EDIT = "Edit";
const VIEW = "View";

class FaqTree extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            treeFilterInput: "",
            values_topic: FORM_INITIAL_VALUES_TOPIC,
            values_subTopic: FORM_INITIAL_VALUES_SUB_TOPIC,
            values_questionSet: FORM_INITIAL_VALUES_QUESTION_SET,
            values_faq_view: FORM_INITIAL_VALUES_FAQ_VIEW,
            values_faq_topic_view: FORM_INITIAL_VALUES_FAQ_TOPIC_VIEW,
            values_faq_sub_topic_view: FORM_INITIAL_VALUES_FAQ_SUB_TOPIC_VIEW,
            values_faq_question_set_view: FORM_INITIAL_VALUES_FAQ_QUESTION_SET_VIEW,
            values_faq_edit: FORM_INITIAL_VALUES_FAQ_EDIT,
            values_faq_sub_topic_edit: FORM_INITIAL_VALUES_FAQ_SUB_TOPIC_EDIT,
            values_faq_topic_edit: FORM_INITIAL_VALUES_FAQ_TOPIC_EDIT,
            values_faq_question_set_edit: FORM_INITIAL_VALUES_FAQ_QUESTION_SET_EDIT,
            modalState: undefined,
            renderFilterStatus: false,
            renderFilterChildStatus: false,
            renderSort: false,
            documentReady: false,
            updateTreeStatus: undefined,
            nodeToBeDeleted: undefined,
            minimizeTree: false,
            currId: 0,
            parentId: this.props.faqId,
            faqId: this.props.faqId,
            faqName: this.props.faqName,
            faqTitle: this.props.faqTitle,
            questionSetGridData: [],
            selectedSubTopicId: 0,
            toggleUpdate: false,
            status: this.props.status,
            applicationName: this.props.applicationName,
            selectedNodeId: 0,
            selectedNodeType: '',
            tags: [],
            togglePrimary: "0",
            isToggleYes: false,
            loadingTree: true,
            loadingGridContent: true,
            tagsList: [],
            faqTopicId: 0,
            faqSubTopicId: 0,
            questionSetId: 0,
            dropdownVisible: false,
            dataSearch: [],
            valueSearch: undefined,
            searchLoading: false
        }
    }

    componentDidMount = async () => {

        // Get Faq Tree
        await this.getFaqTree(GET_FAQ_TREE_URL, "");

        this.setState({
            documentReady: true
        })

    }

    deleteNode = async () => {
        const { nodeToBeDeleted } = this.state;
        const { faqId } = this.props;

        //delete for faq topic
        if (nodeToBeDeleted.Type === FAQ_TOPIC) {
            const params = {
                FaqId: faqId,
                FaqTopicId: nodeToBeDeleted.ID
            }

            const response = await fetchRequest(DELETE_FAQ_TOPIC_URL, deleteParams('', params));

            const { IsSuccess, Messages } = response.body;

            if (response.success) {
                if (IsSuccess) {

                    this.setState({
                        currId: faqId,
                        parentID: faqId,
                        updateTreeStatus: UPDATE_TREE_STATUS.emptyStatus
                    })
                    //reload tree
                    this.getFaqTree(GET_FAQ_TREE_URL, FAQ);

                    toastSuccess(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_SUCCESS_TITLE_DELETETOPIC, SUCCESS.DELETE_SUCCESS), DEFAULT_TEXT.FAQ_TOPIC);
                }
                else {
                    // Error message to be loaded here
                    toastError(Messages);
                }
            }
            else {
                toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
            }
        }

        //delete for faq sub topic
        else if (nodeToBeDeleted.Type === FAQ_SUB_TOPIC) {
            const params = {
                FaqId: faqId,
                FaqTopicId: nodeToBeDeleted.ParentID,
                FaqSubTopicId: nodeToBeDeleted.ID
            }

            const response = await fetchRequest(DELETE_FAQ_SUB_TOPIC_URL, deleteParams('', params));

            const { IsSuccess, Messages } = response.body;

            if (response.success) {
                if (IsSuccess) {

                    this.setState({
                        currId: nodeToBeDeleted.ParentID,
                        parentID: faqId,
                        updateTreeStatus: UPDATE_TREE_STATUS.emptyStatus
                    })
                    //reload tree
                    this.getFaqTree(GET_FAQ_TREE_URL, FAQ_TOPIC);

                    toastSuccess(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_SUCCESS_TITLE_DELETESUBTOPIC, SUCCESS.DELETE_SUCCESS), DEFAULT_TEXT.FAQ_SUB_TOPIC);
                }
                else {
                    // Error message to be loaded here
                    toastError(Messages);
                }
            }
            else {
                toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
            }
        }

        //delete for faq question set
        else if (nodeToBeDeleted.Type === FAQ_QUESTION_SET) {
            const params = {
                FaqId: faqId,
                FaqTopicId: nodeToBeDeleted.FaqTopicId,
                FaqSubTopicId: nodeToBeDeleted.FaqSubTopicId,
                FaqQuestionSetId: nodeToBeDeleted.ID
            }

            const response = await fetchRequest(DELETE_FAQ_QUESTION_SET_URL, deleteParams('', params));

            const { IsSuccess, Messages } = response.body;

            if (response.success) {
                if (IsSuccess) {

                    //reload grid
                    this.getQuestionSetGridList(nodeToBeDeleted.FaqSubTopicId);

                    toastSuccess(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_SUCCESS_TITLE_DELETEQNSET, SUCCESS.DELETE_SUCCESS), DEFAULT_TEXT.FAQ_QN_SET);
                }
                else {
                    // Error message to be loaded here
                    toastError(Messages);
                }
            }
            else {
                toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
            }
        }

        else {
            toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
        }

    }

    toggleDeleteNodeModal = (modalName, node) => {
        this.setState({
            modalState: (this.state.modalState !== modalName) ? modalName : undefined,
            nodeToBeDeleted: node
        })
    };

    //Toggles a node
    toggleParent = (ob) => {
        ob.toggleParent = !ob.toggleParent;
        this.setState({ ob });
    }

    /******* NODE MANIPULATION START *******/

    //Checks if an element is the final node
    isFinalNode = (node) => {
        let isFinal = false;
        let x = this.state.data.map(y => y.children).reduce((a, b) => a.concat(b), []);
        let y = x.map(z => z.children).reduce((a, b) => a.concat(b), []);
        let q = y.map(z => z.children).reduce((a, b) => a.concat(b), []);

        q.map(function (e) {
            if (e.ID === node.ID) {
                isFinal = true;
            }
            return isFinal;
        })
        return isFinal;
    }

    //Generates a list of nodes where the element is the only child
    updateOnlyChildArray = () => {
        let onlyChildArray = [];
        let fullTree = _.cloneDeep(this.state.data);
        onlyChildArray = this.computeOnlyChildArray(fullTree);

        this.setState({
            onlyChildArray: onlyChildArray
        })
    }

    computeOnlyChildArray = (data) => {
        var OnlyChildArray = []
        if (data.length === 1) {
            OnlyChildArray.push(data[0].ID);
        }
        data.forEach((node) => {
            if (node.children.length > 0) {
                OnlyChildArray = OnlyChildArray.concat(this.computeOnlyChildArray(node.children));
            }
        })
        return OnlyChildArray;
    }

    //Generates list of child nodes & final nodes for sort function
    listNodes = async () => {
        let listFinalNodes = [];
        let listChildNodes = []

        // let z = this.state.data.map(y => y);
        let x = this.state.data.map(y => y.children).reduce((a, b) => a.concat(b), []);
        let y = x.map(z => z.children).reduce((a, b) => a.concat(b), []);
        let q = y.map(z => z.children).reduce((a, b) => a.concat(b), []);

        //Get Final nodes
        let fullTree = _.cloneDeep(this.state.data);
        listFinalNodes = this.computeFinalNodesArray(fullTree);

        //Get Child Nodes
        x.map(function (e) { return listChildNodes.push(e) })
        y.map(function (e) { return listChildNodes.push(e) })
        q.map(function (e) { return listChildNodes.push(e) })
        await this.setState({
            listFinalNodes: listFinalNodes,
            listChildNodes: listChildNodes
        })
    }

    computeFinalNodesArray = (data) => {
        var finalNodesArray = []

        let finalNode = data.slice(-1).pop();

        if (finalNode) {
            finalNodesArray.push(finalNode.ID);
        }

        data.forEach((node) => {
            if (node.children.length > 0) {
                finalNodesArray = finalNodesArray.concat(this.computeFinalNodesArray(node.children));
            }
        })
        return finalNodesArray;
    }

    updateTreeValues = async (oldData) => {
        const { name, onChangeField } = this.props

        let newData = this.sortTree(oldData)
        await this.setState({ data: newData });
        onChangeField(name, JSON.stringify(newData));
        await this.listNodes();
        await this.updateOnlyChildArray();
    }


    //Moves the node one level up
    sortNodeUp = async (element, reloadGrid) => {
        const { faqId } = this.props;
        // Set params for sorting rank
        const params = {
            Id: element.ID,
            Type: element.Type,
            IsShiftUp: true
        };
        //this.props.loader.start();
        const response = await fetchRequest(SORT_FAQ_RANK_URL, postParams('', params));

        // this.props.loader.done();

        const { IsSuccess, Message } = response.body;

        if (response.success) {
            if (IsSuccess) {
                if (reloadGrid) {

                    //reload grid
                    this.getQuestionSetGridList(this.state.selectedSubTopicId);
                }

                else {

                    this.setState({
                        currId: element.ID,
                        parentID: faqId
                    })

                    //reload tree
                    this.getFaqTree(GET_FAQ_TREE_URL, element.Type);
                }
            }
            else {
                toastError(Message);
            }
        } else {
            toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
        }

        // Select node
        this.setSelectedNode(element);
    }

    //Moves the node one level down
    sortNodeDown = async (element, reloadGrid) => {
        const { faqId } = this.props;
        // Set params for sorting rank
        const params = {
            Id: element.ID,
            Type: element.Type,
            IsShiftUp: false
        };
        
        const response = await fetchRequest(SORT_FAQ_RANK_URL, postParams('', params));
        const { IsSuccess, Message } = response.body;

        if (response.success) {
            if (IsSuccess) {
                if (reloadGrid) {

                    //reload grid
                    this.getQuestionSetGridList(this.state.selectedSubTopicId);
                }

                else {
                    this.setState({
                        currId: element.ID,
                        parentID: faqId
                    })

                    //reload tree
                    this.getFaqTree(GET_FAQ_TREE_URL, element.Type);
                }
            }
            else {
                toastError(Message);
            }
        } else {
            toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
        }

        // Select node
        this.setSelectedNode(element);
    }


    // Filter tree function
    onChangeFilter = (e) => {
        const { data } = this.state;
        const inputValue = e.target.value;
        this.setState({
            treeFilterInput: inputValue,
            renderFilterChildStatus: false
        });

        //Filter Chapter nodes
        let w = data.map(y => y).reduce((a, b) => a.concat(b), []);
        let filterChaptNode = this.filterData(w, inputValue);

        //Filter Section nodes
        let x = data.map(y => y.children).reduce((a, b) => a.concat(b), []);
        let filterSecNode = this.filterData(x, inputValue);

        //Filter Sub-Section nodes
        let y = x.map(z => z.children).reduce((a, b) => a.concat(b), []);
        let filterSubSecNode = this.filterData(y, inputValue);

        //Filter Title nodes
        let q = y.map(z => z.children).reduce((a, b) => a.concat(b), []);
        let filterTitleNode = this.filterData(q, inputValue);

        if (filterChaptNode !== undefined && filterChaptNode.length !== 0 && this.state.treeFilterInput.length >= 2) {
            this.setState({
                filteredNode: filterChaptNode,
                renderFilterStatus: true,
                renderFilterChildStatus: false
            })
        } else if (filterSecNode !== undefined && filterSecNode.length > 0 && this.state.treeFilterInput.length >= 2) {
            let newData = this.getParentChild(filterSecNode, this.state.data);

            //Toggle parent to display filtered child nodes
            newData.forEach(function (e) {
                e.toggleParent = true
            })
            this.setState({
                filteredNode: newData,
                renderFilterStatus: true,
                renderFilterChildStatus: true
            })
        } else if (filterSubSecNode !== undefined && filterSubSecNode.length > 0 && this.state.treeFilterInput.length >= 2) {
            let newData = this.getParentChild(filterSubSecNode, x);
            if (newData.length > 0) {
                let newestData = this.getParentChild(newData, this.state.data);

                //Toggle parent to display filtered child nodes
                newestData.forEach(function (e) {
                    e.toggleParent = true
                })
                newData.forEach(function (e) {
                    e.toggleParent = true
                })
                this.setState({
                    filteredNode: newestData,
                    renderFilterStatus: true,
                    renderFilterChildStatus: true
                })
            }
        } else if (filterTitleNode !== undefined && filterTitleNode.length > 0 && this.state.treeFilterInput.length >= 2) {
            let newData = this.getParentChild(filterTitleNode, y);
            if (newData.length > 0) {
                let newestData = this.getParentChild(newData, x);
                if (newestData.length > 0) {
                    let totalData = this.getParentChild(newestData, this.state.data);

                    //Toggle parent to display filtered child nodes
                    totalData.forEach(function (e) {
                        e.toggleParent = true
                    })
                    newestData.forEach(function (e) {
                        e.toggleParent = true
                    })
                    newData.forEach(function (e) {
                        e.toggleParent = true
                    })
                    this.setState({
                        filteredNode: totalData,
                        renderFilterStatus: true,
                        renderFilterChildStatus: true
                    })
                }
            }
        } else {
            this.setState({
                renderFilterStatus: false,
                renderFilterChildStatus: false
            })
        }
    }

    filterData(data, inputValue) {
        let filteredData = data.filter(item =>
            item.name.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1);

        if (filteredData.length > 0) {
            return filteredData
        } else {
            return []
        }
    }

    //Matches parent and child nodes from ID & ParentID
    getParentChild = (key, tree) => {
        let newData;
        let x = [];
        tree.forEach(treeElement => {
            var node = treeElement;
            key.forEach(element => {
                if (element.ParentID === node.ID) {
                    let y = Object.assign({}, node, {
                        children: [element]
                    })
                    x.push(y)
                };
            });
        })

        //Removes duplicates
        var output = x.reduce(function (x, y) {
            var duplicate = x.reduce(function (n, item, i) {
                return (item.ID === y.ID) ? i : n;
            }, -1);

            if (duplicate >= 0) {
                x[duplicate].children = x[duplicate].children.concat(y.children);

            } else {
                var obj = { ID: y.ID, ParentID: y.ParentID, name: y.name, content: y.content, children: y.children };
                x = x.concat([obj]);
            }
            return x;
        }, []);

        if (output.length > 0) {
            newData = output
        } else {
            newData = x;
        }
        return newData;
    };



    //Adding Section, Sub-section & Title
    addChild = (node) => {
        //Adding a topic
        if (node.ParentID === 0) {
            this.setState({
                values_topic: {
                    FaqTitle: node.Title,
                    FaqName: node.name,
                    FaqId: node.ID,
                    Name: '',
                    Title: '',
                    Description: ''
                },
                updateTreeStatus: UPDATE_TREE_STATUS.addTopicStatus
            })
        }

        //Adding a sub-topic
        this.state.data.forEach((element) => {
            return element.children.map((child) => {
                return (child.ParentID === element.ID && element.children.includes(node)) ?
                    this.setState({
                        values_subTopic: {
                            FaqTitle: this.props.faqTitle,
                            FaqId: this.state.faqId,
                            FaqTopicTitle: node.Title,
                            FaqTopicId: node.ID,
                            Name: '',
                            Title: '',
                            Description: ''
                        },
                        updateTreeStatus: UPDATE_TREE_STATUS.addSubTopicStatus
                    }) : null
            })
        });

        //Adding a question set
        this.state.data.forEach((element) => {
            return element.children.map((child) => {
                return (child.children.includes(node) && element.children.includes(child)) ?
                    this.setState({
                        values_questionSet: {
                            FaqTitle: this.state.faqTitle,
                            FaqId: this.state.faqId,
                            FaqTopicTitle: child.Title,
                            FaqTopicId: child.ID,
                            FaqSubTopicTitle: node.Title,
                            FaqSubTopicId: node.ID,
                            Name: '',
                            Description: '',
                            Question: { 0: '' },
                            Answer: '',
                            TagList: []
                        },
                        togglePrimary: "0",
                        updateTreeStatus: UPDATE_TREE_STATUS.addQuestionSetStatus,
                        selectedSubTopicId: node.ID
                    }) : null
            })
        });

        // Select node
        this.setSelectedNode(node);
    }


    viewNode = async (node) => {
        let url = '';

        if (node.Type === FAQ) url = GET_FAQ_URL;
        if (node.Type === FAQ_TOPIC) url = GET_FAQ_TOPIC_URL;
        if (node.Type === FAQ_SUB_TOPIC) url = GET_FAQ_SUB_TOPIC_URL;

        const params = {
            ParentId: this.props.faqId,
            Id: node.ID,
            language: this.props.faqLanguageCode,
            ApplicationName: this.props.applicationName,
            IsPublished: true
        };

        const response = await fetchRequest(url, getParams(params));
        const { Data, IsSuccess, Message } = response.body;

        if (response.success) {
            if (IsSuccess) {
                if (node.Type === FAQ) {
                    this.setState({
                        values_faq_view: Data,
                        updateTreeStatus: UPDATE_TREE_STATUS.viewFaqStatus,
                        app: Data.ApplicationName,
                        faqName: Data.Name,
                        isSuccess: true
                    });

                }

                if (node.Type === FAQ_TOPIC) {
                    this.setState({
                        values_faq_topic_view: Data,
                        updateTreeStatus: UPDATE_TREE_STATUS.viewFaqTopicStatus,
                        app: Data.ApplicationName,
                        faqName: Data.Name,
                        isSuccess: true
                    });

                }

                if (node.Type === FAQ_SUB_TOPIC) {
                    this.setState({
                        values_faq_sub_topic_view: Data,
                        updateTreeStatus: UPDATE_TREE_STATUS.viewFaqSubTopicStatus,
                        app: Data.ApplicationName,
                        faqName: Data.Name,
                        isSuccess: true
                    });

                }
            } else {
                toastError(Message);
            }
        } else {
            toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
        }
    }

    //Edit tree data
    editNode = (node) => {

        //Edit a FAQ
        this.state.data.forEach((chapter) => {
            if (node.ID === chapter.ID) {
                this.setState({
                    values_faq_edit: {
                        Name: node.name,
                        Title: node.Title,
                        FaqId: node.ID,
                        Description: node.content
                    },
                    updateTreeStatus: UPDATE_TREE_STATUS.editFaqStatus
                })
                return;
            }
        });

        //Edit a Topic
        this.state.data.forEach((chapter) => {
            return chapter.children.map((section) => {
                return (section.ParentID === chapter.ID && chapter.children.includes(node)) ? (
                    //Reassigns the values of selected node to the form initial values.
                    this.setState({
                        values_faq_topic_edit: {
                            Name: node.name,
                            Title: node.Title,
                            Description: node.content,
                            FaqTopicId: node.ID,
                            FaqId: this.state.faqId,
                            FaqName: chapter.name,
                            FaqTitle: chapter.Title
                        },
                        updateTreeStatus: UPDATE_TREE_STATUS.editFaqTopicStatus
                    })
                ) : null
            })
        });

        //Edit a Sub-Topic
        this.state.data.forEach((chapter) => {
            return chapter.children.map((section) => {
                return (section.children.length > 0) ? (
                    section.children.map((subsection) => {
                        return (subsection.ParentID === section.ID && section.children.includes(node)) ? (
                            //Reassigns the values of selected node to the form initial values.
                            this.setState({
                                values_faq_sub_topic_edit: {
                                    Name: node.name,
                                    Title: node.Title,
                                    Description: node.content,
                                    FaqId: this.state.faqId,
                                    FaqTopicId: node.ParentID,
                                    FaqSubTopicId: node.ID,
                                    FaqTopicName: section.name,
                                    FaqTopicTitle: section.Title,
                                    FaqName: chapter.name,
                                    FaqTitle: chapter.Title
                                },
                                updateTreeStatus: UPDATE_TREE_STATUS.editFaqSubTopicStatus
                            })
                        ) : null
                    })
                ) : null
            })
        });

        //Edit a title
        this.state.data.forEach((chapter) => {
            return chapter.children.map((section) => {
                return (section.children.length > 0) ? (
                    section.children.map((subsection) => {
                        return (subsection.children.length > 0) ? (
                            subsection.children.map((title) => {
                                return (title.ParentID === subsection.ID && subsection.children.includes(node)) ? (
                                    //Reassigns the values of selected node to the form initial values.
                                    this.setState({
                                        values_title_edit: {
                                            TreeTitle: node.name,
                                            TreeTitleContent: node.content,
                                            TreeNodeID: node.ID
                                        },
                                        updateTreeStatus: UPDATE_TREE_STATUS.editTitleStatus
                                    })
                                ) : null
                            })
                        ) : null
                    })
                ) : null
            })
        });

        // Select node
        this.setSelectedNode(node);
    }

    // Process final node when it has been clicked
    loadQuestionSetList = async (node) => {
        // Set selected sub-topic used for refresh grid purposes
        this.setState({
            selectedSubTopicId: node.ID
        });

        // Get question set grid info
        this.getQuestionSetGridList(node.ID);

        // Select node
        this.setSelectedNode(node);
    };

    getQuestionSetGridList = async (subTopicId) => {
        // Set params
        const params = {
            FaqId: this.state.faqId,
            FaqSubTopicId: subTopicId,
            Language: this.props.faqLanguageCode,
            IsPublished: (this.state.status !== DRAFT_STATUS)
        };

        this.setState({ loadingGridContent: true });

        // Request question set list from server-end
        const response = await fetchRequest(GET_FAQ_QUESTION_SET_LIST_URL, getParams(params));

        const { Data, IsSuccess, Message } = response.body;

        if (response.success) {
            if (IsSuccess) {
                // Set data to state, change to render ViewQuestionSetList card
                this.setState({
                    questionSetGridData: Data,
                    updateTreeStatus: UPDATE_TREE_STATUS.viewQuestionSetListStatus,
                    toggleUpdate: !this.state.toggleUpdate,
                    loadingGridContent: false
                });
            }
            else {
                toastError(Message);
            }
        } else {
            toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
        }
    };


    // Fn: Get Faq Tree details
    getFaqTree = async (url, type) => {

        let fullurl = url;
        const params = {
            ID: this.state.currId,
            ParentID: this.state.parentId,
            Type: type,
            LanguageCode: this.props.faqLanguageCode,
            IsPublished: this.props.isViewFaq
        };

        this.setState({ loadingTree: true });

        const response = await fetchRequest(fullurl, getParams(params));
        const { Data, IsSuccess, Message } = response.body;

        if (response.success) {
            if (IsSuccess) {
                let arr = [];

                arr.push(JSON.parse(Data));
                this.setState({
                    success: true,
                    data: arr,
                    loadingTree: false
                });

                await this.updateOnlyChildArray();
                await this.listNodes();

            } else {
                toastError(Message);
            }
        } else {
            toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
        }
    }


    // Add more question button
    addQuestionInput = (values, onChangeField) => {
        let uId = new Date();

        onChangeField("Question", { ...values.Question, [uId]: '' });
    };

    renderQuestionAndAnswer = (values, onChange) => {
        let count = 0;
        
        return (_.map(values.Question, (v, i) => {
            count++;
            return (
                <Row key={i}>
                    <Col xs="8">
                        <Paragraph
                            key={i}
                            name={`Question.${i}`}
                            value={values.Question[i] || ""}
                            placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_PLACEHOLDER_QUESTION, DEFAULT_TEXT.QUESTION)}
                            onChange={onChange}
                            minLength={0}
                            orientation={ORIENTATION.INLINE}
                            inputSize={FIELD_SIZE.MAX}
                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_QUESTION, DEFAULT_TEXT.QUESTION) + ` ${count}`}
                            required
                        />
                    </Col>
                    <Col xs="1">
                        {this.props.isDefaultLanguage && (count !== 1) && 
                            (<FontAwesomeIcon 
                                key={i} 
                                tooltip={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_TABLE_TOOLTIP_REMOVE, DEFAULT_TEXT.REMOVE)} 
                                className="remove-faq-question-icon" 
                                icon={faTimesCircle} 
                                onClick={() => this.removeQuestion(i, values)} />)}
                    </Col>
                    <Col xs="3">
                        <Label xs="12" className={"font-weight-bold text-center tree-toggle"}>{(count === 1) && this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_PRIMARY, DEFAULT_TEXT.PRIMARY)}</Label>
                        <ToggleSwitch
                            key={i}
                            name={i}
                            value={i === this.state.togglePrimary ? this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_YES, DEFAULT_TEXT.YES) : this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_NO, DEFAULT_TEXT.NO)}
                            onChangeField={() => this.setToggleValue("togglePrimary", i)}
                            labelSize={FIELD_SIZE.SMALL}
                            inputSize={FIELD_SIZE.MEDIUM}
                            showToggleText={false}
                        />
                    </Col>
                </Row>
            )
        }));
    }


    removeQuestion = (i, values) => {
        delete values.Question[i];

        let newTogglePrimary = (i === this.state.togglePrimary) ? "0" : this.state.togglePrimary;

        this.setState({
            togglePrimary: newTogglePrimary
        });
    }


    removeQuestionForEdit = (i, values, onChangeField) => {
        delete values.QuestionDictionary[i];
        //onChangeField();
        this.setState({
            togglePrimary: this.state.togglePrimary
        });
    }

    // Add more question button
    addQuestionInputForEdit = (values, onChangeField) => {
        let uId = "Id_" + Date.now();

        onChangeField(`QuestionDictionary.${uId}`, { "IsPrimary": false, "Id": uId, "Question": '' });
    };

    setToggleValue = (name, qnKey) => {
        this.setState({
            [name]: qnKey
        });

    }

    renderEditFaqQuestionSet = () => {
        return (
            <React.Fragment>
                <SMARTSubForm
                    key={UPDATE_TREE_STATUS.editFaqQuestionSetStatus}
                    formContext={FORM_CONTEXT}
                    formValues={this.state.questionSetDetail}
                    serverURL={EDIT_FAQ_QUESTION_SET_URL}
                    validateOnSubmit={true}
                    resetFormAfterSubmit={true}
                    submitCallback={this.submitCallbackForUpdateFaqQuestionSet}
                >
                    {({ values, errors, onChangeField, submitPlainForm }) => (
                        <React.Fragment>
                            <React.Fragment>
                                <Row className="admin-content-wrapper">
                                    <Col>
                                        {this.renderBreadCrumb([
                                            this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_FAQ, DEFAULT_TEXT.FAQ),
                                            this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_TOPIC, DEFAULT_TEXT.TOPIC),
                                            this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_SUBTOPIC, DEFAULT_TEXT.SUB_TOPIC),
                                            this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_EDITQNSET, DEFAULT_TEXT.EDIT_QNSET)])}

                                        <MenulessCreatableSelectList
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_TAGS, DEFAULT_TEXT.TAGS)}
                                            name="TagList"
                                            value={values.TagList || []}
                                            onChangeField={onChangeField}
                                            placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_PLACEHOLDER_TAGS, DEFAULT_TEXT.TAGS)}
                                            isDisabled={false}
                                            inputSize={FIELD_SIZE.MAX}
                                            isClearable
                                            helpLabel={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_HELPTEXT_TAGS, DEFAULT_TEXT.HELPTEXT_TAGS)}
                                        />

                                        {this.renderQuestionList(values, onChangeField)}

                                        {this.props.isDefaultLanguage &&
                                            <Button className="more-qn-btn" color="forward" size="sm" type="button" onClick={() => this.addQuestionInputForEdit(values, onChangeField)}>
                                                {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_ADDQN, DEFAULT_TEXT.ADD_MORE_QN)}
                                            </Button>
                                        }

                                        <RichTextEditor
                                            name="Answer"
                                            value={values.Answer}
                                            onChangeField={onChangeField}
                                            showHtml={false}
                                            orientation={ORIENTATION.INLINE}
                                            inputSize={FIELD_SIZE.MAX}
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_ANSWER, DEFAULT_TEXT.ANSWER)}
                                            error={errors.Answer}
                                            toolbar={RICHTEXT.DEFAULT}
                                        />
                                    </Col>
                                </Row>
                                <div className="oh-card-footer">
                                    <Button color="backward" size="sm" type="button" onClick={() => this.backToFaqQuestionSetList(this.state.questionSetDetail)}>
                                        {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_BACK, DEFAULT_TEXT.BACK)}
                                    </Button>
                                    <Button color="forward" size="sm" type="button" onClick={() => this.updateQuestionSet(values, submitPlainForm)}>
                                        {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_UPDATE, DEFAULT_TEXT.UPDATE)}
                                    </Button>
                                </div>
                            </React.Fragment>
                        </React.Fragment>
                    )}
                </SMARTSubForm>
            </React.Fragment>
        );
    }

    renderQuestionSetListDetail = (values, i, onChangeField, count, isEditable) => {
        return (
            <Row key={i}>
                <Col xs={(isEditable) ? 8 : 12}>
                    <Paragraph
                        key={i}
                        name={`QuestionDictionary.${i}`}
                        value={(values.QuestionDictionary[i] && values.QuestionDictionary[i].Question) || ""}
                        placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_PLACEHOLDER_QUESTION, DEFAULT_TEXT.QUESTION)}
                        onChange={(e, name) => this.handleQuestionUpdate(e, name, values.QuestionDictionary[i], onChangeField)}
                        minLength={0}
                        orientation={ORIENTATION.INLINE}
                        inputSize={FIELD_SIZE.MAX}
                        label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_QUESTION, DEFAULT_TEXT.QUESTION) + ` ${count} ${((!isEditable) && 
                            (count === 1)) ? this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_PRIMARY, DEFAULT_TEXT.PRIMARY) : ""}`}
                        required={isEditable}
                        readOnly={!isEditable}
                    />
                </Col>
                {(isEditable &&
                    (<>
                        <Col xs="1">
                            {this.props.isDefaultLanguage && (i !== this.state.togglePrimary) && 
                                (<FontAwesomeIcon 
                                key={i} 
                                tooltip={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_TABLE_TOOLTIP_REMOVE, DEFAULT_TEXT.REMOVE)} 
                                className="remove-faq-question-icon" 
                                icon={faTimesCircle} 
                                onClick={() => this.removeQuestionForEdit(i, values)} />)}
                        </Col>
                        <Col xs="3">
                            <Label xs="12" className={"font-weight-bold text-center tree-toggle"}>{(count === 1) && this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_PRIMARY, DEFAULT_TEXT.PRIMARY)}</Label>
                            <ToggleSwitch
                                key={i}
                                name={i}
                                value={i === this.state.togglePrimary ? this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_YES, DEFAULT_TEXT.YES) : this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_NO, DEFAULT_TEXT.NO)}
                                onChangeField={() => this.setToggleValue("togglePrimary", i)}
                                labelSize={FIELD_SIZE.SMALL}
                                inputSize={FIELD_SIZE.MEDIUM}
                                showToggleText={false}
                            />
                        </Col>
                    </>)
                )}
            </Row>
        );
    };

    // Fn: Render question list for Edit Faq Question Set
    renderQuestionList = (values, onChangeField) => {
        let count = 0;

        return (_.map(values.QuestionDictionary, (v, i) => 
        { 
            count++; 
            return this.renderQuestionSetListDetail(values, i, onChangeField, count, true); 
        }));
    };
    
    
    
        // Fn: Set new updated Question for Edit Faq Question Set
    handleQuestionUpdate = (e, name, qnValues, onChangeField) => {
        let newValues = { ...qnValues, 'Question': e.target.value };

        onChangeField(name, newValues);

    }

    submitCallbackForUpdateFaqQuestionSet = ({ response }) => {
        if (response.success) {
            const { IsSuccess, Message } = response.body;
            if (IsSuccess) {

                toastSuccess(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_SUCCESS_TITLE_UPDATEQNSET, SUCCESS.UPDATE_SUCCESS), DEFAULT_TEXT.FAQ_QN_SET);

                //Get Faq Tree
                this.getFaqTree(GET_FAQ_TREE_URL, FAQ_SUB_TOPIC);

                this.setState({
                    updateTreeStatus: UPDATE_TREE_STATUS.viewQuestionSetListStatus,
                    togglePrimary: "0"
                });

                //reload grid
                this.getQuestionSetGridList(this.state.currId);

            }
            else {
                toastError(Message);
            }
        } else {
            toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
        }

    }



    // Fn: Button to go back to Question Set listing page
    backToFaqQuestionSetList = async (values) => {
        this.setState({ loadingGridContent: true });

        // Reload grid
        this.getQuestionSetGridList(this.state.faqSubTopicId);

        this.setState({
            selectedNodeId: this.state.faqSubTopicId,
            selectedNodeType: FAQ_SUB_TOPIC,
            updateTreeStatus: UPDATE_TREE_STATUS.viewQuestionSetListStatus
        });

        this.setState({ loadingGridContent: false });
    }

    updateQuestionSet = (values, submitPlainForm) => {

        // Provide custom validation to ensure Questions are validated
        if (this.validateQuestionListAndAnswerForEdit(values.QuestionDictionary, values.Answer)) {

            let valueArray = [];

            Object.entries(values.TagList).forEach(([key, value]) => valueArray.push(value.value));
            let obj = Object.assign({}, valueArray);

            values.FaqId = this.state.faqId;
            values.FaqTopicId = this.state.faqTopicId;
            values.FaqSubTopicId = this.state.faqSubTopicId;
            values.ID = this.state.questionSetId;
            values.Tags = obj;
            values.LanguageCode = this.props.faqLanguageCode;
            values.ApplicationName = this.props.applicationName;

            if (values.QuestionDictionary[values.PrimaryQuestionGuid]) {
                values.QuestionDictionary[values.PrimaryQuestionGuid].IsPrimary = false;
            }

            values.QuestionDictionary[this.state.togglePrimary].IsPrimary = true;
            values.SerializedQuestionSetList = JSON.stringify(values.QuestionDictionary);

            this.setState({
                currId: values.FaqSubTopicId
            });

            submitPlainForm();
        }
    }

    // Fn: Get Yup form validations object for Title field
    getValidationSchemaForTitle = () => {
        return Yup.object().shape({
            Title: Yup.string()
            .required(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_REQUIRED, ERROR.REQUIRED))
        });
    };

    renderEditFaq = () => {
        return (
            <React.Fragment>
                <SMARTSubForm
                    formContext={FORM_CONTEXT}
                    formValues={this.state.values_faq_edit}
                    serverURL={EDIT_FAQ_URL}
                    validationSchema={this.getValidationSchemaForTitle()}
                    validateOnSubmit={true}
                    resetFormAfterSubmit={true}
                    submitCallback={this.submitCallbackForUpdateFaq}
                >
                    {({ values, errors, onChange, onChangeField, submitPlainForm }) => (
                        <React.Fragment>
                            <React.Fragment>
                                <Row className="admin-content-wrapper">
                                    <Col>
                                        {this.renderBreadCrumb([
                                            this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_CONTENT_TITLE_EDITFAQ, DEFAULT_TEXT.EDIT_FAQ)])}
                                        <Text
                                            name="Title"
                                            value={values.Title}
                                            placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_PLACEHOLDER_FAQTITLE, DEFAULT_TEXT.FAQ_TITLE)}
                                            onChange={onChange}
                                            minLength={0}
                                            orientation={ORIENTATION.INLINE}
                                            inputSize={FIELD_SIZE.MAX}
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_TITLE, DEFAULT_TEXT.TITLE)}
                                            error={errors.Title}
                                            required
                                        />
                                        <Paragraph
                                            name="Description"
                                            value={values.Description}
                                            placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_PLACEHOLDER_FAQDESC, DEFAULT_TEXT.FAQ_DESCRIPTION)}
                                            onChange={onChange}
                                            minLength={0}
                                            orientation={ORIENTATION.INLINE}
                                            inputSize={FIELD_SIZE.MAX}
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_DESCRIPTION, DEFAULT_TEXT.DESCRIPTION)}
                                            error={errors.Description}
                                        />
                                    </Col>
                                </Row>
                                <div className="oh-card-footer">
                                    <Button color="forward" size="sm" type="button" onClick={() => this.editFaq(values, submitPlainForm)}>
                                        {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_UPDATE, DEFAULT_TEXT.UPDATE)}
                                    </Button>
                                </div>
                            </React.Fragment>
                        </React.Fragment>
                    )}
                </SMARTSubForm>
            </React.Fragment>
        );
    }


    editFaq = (values, submitPlainForm) => {
        values.Language = this.props.faqLanguageCode;
        values.Application = this.state.applicationName;

        this.setState({
            parentId: this.state.faqId,
            currId: values.FaqId
        });

        submitPlainForm();
    }

    editFaqTopic = (values, submitPlainForm) => {

        values.Language = this.props.faqLanguageCode;
        values.Application = this.state.applicationName;

        this.setState({
            parentId: this.state.faqId,
            currId: values.FaqTopicId
        });

        submitPlainForm();
    }

    editFaqSubTopic = (values, submitPlainForm) => {
        values.Language = this.props.faqLanguageCode;
        values.Application = this.state.applicationName;

        this.setState({
            parentId: this.state.faqId,
            currId: values.FaqSubTopicId
        });

        submitPlainForm();
    }

    submitCallbackForUpdateFaqTopic = async ({ response }) => {

        this.setState({
            updateTreeStatus: UPDATE_TREE_STATUS.emptyStatus
        });

        if (response.success) {
            const { IsSuccess, Message } = response.body;
            if (IsSuccess) {

                toastSuccess(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_SUCCESS_TITLE_UPDATETOPIC, SUCCESS.UPDATE_SUCCESS), DEFAULT_TEXT.FAQ_TOPIC);
                
                //Get Faq Tree
                await this.getFaqTree(GET_FAQ_TREE_URL, FAQ_TOPIC);

                this.setState({
                    selectedNodeId: this.state.currId,
                    selectedNodeType: FAQ_TOPIC
                });


            }
            else {
                toastError(Message);
            }
        } else {
            toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
        }
    }

    submitCallbackForUpdateFaq = async ({ response }) => {

        this.setState({
            updateTreeStatus: UPDATE_TREE_STATUS.emptyStatus
        });

        if (response.success) {
            const { IsSuccess, Message } = response.body;
            if (IsSuccess) {
                toastSuccess(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_SUCCESS_TITLE_UPDATEFAQ, SUCCESS.UPDATE_SUCCESS), DEFAULT_TEXT.FAQ);
                //Get Faq Tree
                await this.getFaqTree(GET_FAQ_TREE_URL, FAQ);

                this.setState({
                    selectedNodeId: this.state.currId,
                    selectedNodeType: FAQ
                });
            }
            else {
                toastError(Message);
            }
        } else {
            toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
        }
    }

    submitCallbackForUpdateFaqSubTopic = async ({ response }) => {

        this.setState({
            updateTreeStatus: UPDATE_TREE_STATUS.emptyStatus
        });

        if (response.success) {
            const { IsSuccess, Message } = response.body;
            if (IsSuccess) {
                toastSuccess(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_SUCCESS_TITLE_UPDATESUBTOPIC, SUCCESS.UPDATE_SUCCESS), DEFAULT_TEXT.FAQ_SUB_TOPIC);
                
                await this.getFaqTree(GET_FAQ_TREE_URL, FAQ_SUB_TOPIC);

                this.setState({
                    selectedNodeId: this.state.currId,
                    selectedNodeType: FAQ_SUB_TOPIC
                });

            }
            else {
                toastError(Message);
            }
        } else {
            toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
        }
    }

    renderBreadCrumb = (titleArray) => {
        return (
            <Breadcrumb className="breadcrumb-padding" separator=">">
                {titleArray.map((item, i) =>
                    <Breadcrumb.Item key={i}>
                        {
                            (item && item.length > 35) ? (item.substring(0, 35) + "...") : item
                        }
                    </Breadcrumb.Item>
                )}
            </Breadcrumb>);
    }

    renderEditFaqTopic = () => {
        return (
            <React.Fragment>
                <SMARTSubForm
                    formContext={FORM_CONTEXT}
                    formValues={this.state.values_faq_topic_edit}
                    serverURL={EDIT_FAQ_TOPIC_URL}
                    validationSchema={this.getValidationSchemaForTitle()}
                    validateOnSubmit={true}
                    resetFormAfterSubmit={true}
                    submitCallback={this.submitCallbackForUpdateFaqTopic}
                >
                    {({ values, errors, onChange, submitPlainForm }) => (
                        <React.Fragment>
                            <React.Fragment>
                                <Row className="admin-content-wrapper">
                                    <Col>
                                        {this.renderBreadCrumb([
                                            this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_FAQ, DEFAULT_TEXT.FAQ), 
                                            this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_EDITFAQTOPIC, DEFAULT_TEXT.EDIT_FAQ_TOPIC)])}
                                        <Text
                                            name="Title"
                                            value={values.Title}
                                            placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_PLACEHOLDER_TITLE, DEFAULT_TEXT.TITLE)}
                                            onChange={onChange}
                                            minLength={0}
                                            orientation={ORIENTATION.INLINE}
                                            inputSize={FIELD_SIZE.MAX}
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_TITLE, DEFAULT_TEXT.TITLE)}
                                            error={errors.Title}
                                            required
                                        />
                                        <Paragraph
                                            name="Description"
                                            value={values.Description}
                                            placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_PLACEHOLDER_FAQTOPICDESC, DEFAULT_TEXT.FAQ_TOPIC_DESCRIPTION)}
                                            onChange={onChange}
                                            minLength={0}
                                            orientation={ORIENTATION.INLINE}
                                            inputSize={FIELD_SIZE.MAX}
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_DESCRIPTION, DEFAULT_TEXT.DESCRIPTION)}
                                            error={errors.Description}
                                        />
                                    </Col>
                                </Row>
                                <div className="oh-card-footer">
                                    <Button color="forward" size="sm" type="button" onClick={() => this.editFaqTopic(values, submitPlainForm)}>
                                        {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_UPDATE, DEFAULT_TEXT.UPDATE)}
                                    </Button>
                                </div>
                            </React.Fragment>
                        </React.Fragment>
                    )}
                </SMARTSubForm>
            </React.Fragment>
        );
    }

    renderEditFaqSubTopic = () => {
        return (
            <React.Fragment>
                <SMARTSubForm
                    key={UPDATE_TREE_STATUS.editFaqSubTopicStatus}
                    formContext={FORM_CONTEXT}
                    formValues={this.state.values_faq_sub_topic_edit}
                    serverURL={EDIT_FAQ_SUB_TOPIC_URL}
                    validationSchema={this.getValidationSchemaForTitle()}
                    validateOnSubmit={true}
                    resetFormAfterSubmit={true}
                    submitCallback={this.submitCallbackForUpdateFaqSubTopic}
                >
                    {({ values, errors, onChange, submitPlainForm }) => (
                        <React.Fragment>
                            <React.Fragment>
                                <Row className="admin-content-wrapper">
                                    <Col>
                                        {this.renderBreadCrumb([
                                            this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_FAQ, DEFAULT_TEXT.FAQ),
                                            this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_TOPIC, DEFAULT_TEXT.TOPIC),
                                            this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_SUBTOPIC, DEFAULT_TEXT.SUB_TOPIC),
                                            this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_EDITFAQSUBTOPIC, DEFAULT_TEXT.TITLE)])}
                                        <Text
                                            name="Title"
                                            value={values.Title}
                                            placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_PLACEHOLDER_TITLE, DEFAULT_TEXT.TITLE)}
                                            onChange={onChange}
                                            minLength={0}
                                            orientation={ORIENTATION.INLINE}
                                            inputSize={FIELD_SIZE.MAX}
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_TITLE, DEFAULT_TEXT.TITLE)}
                                            error={errors.Title}
                                            required
                                        />
                                        <Paragraph
                                            name="Description"
                                            value={values.Description}
                                            placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_PLACEHOLDER_FAQSUBTOPICDESC, DEFAULT_TEXT.FAQ_SUB_TOPIC_DESCRIPTION)}
                                            onChange={onChange}
                                            minLength={0}
                                            orientation={ORIENTATION.INLINE}
                                            inputSize={FIELD_SIZE.MAX}
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_DESCRIPTION, DEFAULT_TEXT.DESCRIPTION)}
                                            error={errors.Description}
                                        />
                                    </Col>
                                </Row>
                                <div className="oh-card-footer">
                                    <Button color="forward" size="sm" type="button" onClick={() => this.editFaqSubTopic(values, submitPlainForm)}>
                                        {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_UPDATE, DEFAULT_TEXT.UPDATE)}
                                    </Button>
                                </div>
                            </React.Fragment>
                        </React.Fragment>
                    )}
                </SMARTSubForm>
            </React.Fragment>
        );
    }

    getCustomComponents = () => {
        let components = getCustomComponents();
        components.Layout = this.CustomLayout;
        return components;
    }

    CustomLayout = ({ Table }) => {
        return (
            <div>
                <Table />
            </div>
        );
    }

    renderViewQuestionSetList = () => {
        return (
            (this.state.loadingGridContent) ?
                (<Spin tip="Loading..." className={"tree-content-loader"} />) :
                (<DataTable
                    pageSize="5"
                    minFilterChars='2'
                    data={this.state.questionSetGridData}
                    noResultsMessage={(<div className="text-center">{this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_TABLE_TEXT_QNSETNORECORD, DEFAULT_TEXT.FAQQNSET_NO_RECORD)}</div>)}
                    components={this.getCustomComponents()}
                    toggleUpdate={this.state.toggleUpdate}
                    isQuestionSetCard
                    isCustomTable
                    isCustomLayout
                    columns={
                        {
                            ID: { title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_CONTENT_TABLE_NO, DEFAULT_TEXT.NUM), DBkey: 'ID' },
                            PrimaryQuestion: { title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_CONTENT_TABLE_PRIQN, DEFAULT_TEXT.PRIMARY_QN), DBkey: 'PrimaryQuestion' },
                            Answer: { title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_CONTENT_TABLE_ANSWER, DEFAULT_TEXT.ANSWER), DBkey: 'Answer' },
                            Rank: { title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_CONTENT_TABLE_RANK, DEFAULT_TEXT.RANK), DBkey: 'Rank' },
                            Type: { title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_CONTENT_TABLE_TYPE, DEFAULT_TEXT.TYPE), DBkey: 'Type' },
                            AbleSortRankUp: { title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_CONTENT_TABLE_SORTRANKUP, DEFAULT_TEXT.TYPE), DBkey: 'AbleSortRankUp' },
                            AbleSortRankDown: { title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_CONTENT_TABLE_SORTRANKDOWN, DEFAULT_TEXT.TYPE), DBkey: 'AbleSortRankDown' },
                        }
                    }
                    renderActions={(data) => this.renderQuestionSetButtons(data)}
                />)
        );
    };


    // Render Faq Question Set buttons
    renderQuestionSetButtons = (data) => {
        return (
            <span className="actions">
                {
                    this.state.status === DRAFT_STATUS ?
                        <><i className="fa-edit tree-icon" onClick={() => { this.getFaqQuestionSet(data.FaqId, data.FaqTopicId, data.FaqSubTopicId, data.ID, this.props.faqLanguageCode, EDIT) }}><FontAwesomeIcon fixedWidth icon={faEdit} /></i>
                            <i className="fa-del tree-icon" onClick={() => { this.toggleDeleteNodeModal(DELETE_MODAL.name, data) }}><FontAwesomeIcon fixedWidth icon={faTrash} /></i></>
                        :
                        <i className="fa-edit tree-icon" onClick={() => { this.getFaqQuestionSet(data.FaqId, data.FaqTopicId, data.FaqSubTopicId, data.ID, this.props.faqLanguageCode, VIEW) }}><FontAwesomeIcon fixedWidth icon={faEye} /></i>
                }
                {this.props.isDefaultLanguage && ((data.AbleSortRankUp) && (<i className="fa-sort tree-icon" onClick={() => { this.sortNodeUp(data, true) }}><FontAwesomeIcon fixedWidth icon={faAngleDoubleUp} /></i>))}
                {this.props.isDefaultLanguage && ((data.AbleSortRankDown) && (<i className="fa-sort tree-icon" onClick={() => { this.sortNodeDown(data, true) }}><FontAwesomeIcon fixedWidth icon={faAngleDoubleDown} /></i>))}
            </span>
        );
    }

    getFaqQuestionSet = async (faqId, faqTopicId, faqSubTopicId, qnSetId, languageCode, mode) => {

        let treeStatus = (mode === EDIT) ? UPDATE_TREE_STATUS.editFaqQuestionSetStatus : UPDATE_TREE_STATUS.viewFaqQuestionSetStatus;

        const params = {
            Id: faqId,
            FaqTopicId: faqTopicId,
            SubTopicId: faqSubTopicId,
            QuestionSetId: qnSetId,
            LanguageCode: languageCode,
            IsPublished: (mode === EDIT) ? false : true
        };

        this.props.loader.start();

        const response = await fetchRequest(GET_FAQ_QUESTION_SET_URL, getParams(params));

        this.props.loader.done();

        const { Data, IsSuccess, Message } = response.body;

        if (response.success) {
            if (IsSuccess) {
                Data.TagList = Data.TagList.map((tag) => { return { 'label': tag, 'value': tag } });
                
                this.setState({
                    values_faq_question_set_view: Data,
                    questionSetDetail: Data,
                    updateTreeStatus: treeStatus,
                    faqTopicId: faqTopicId,
                    faqSubTopicId: faqSubTopicId,
                    questionSetId: qnSetId,
                    togglePrimary: Data.PrimaryQuestionGuid,
                    primaryQuestionGuid: Data.PrimaryQuestionGuid,
                    selectedSubTopicId: faqSubTopicId
                });

            }
            else {
                toastError(Message);
            }
        } else {
            toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
        }
    }



    // Fn: Render adding faq topic view
    renderAddTopic = () => {
        return (
            <React.Fragment>
                <SMARTSubForm
                    key={UPDATE_TREE_STATUS.addTopicStatus}
                    formContext={FORM_CONTEXT}
                    formValues={this.state.values_topic}
                    serverURL={ADD_FAQ_TOPIC_URL}
                    validationSchema={this.getValidationSchemaForTitle()}
                    validateOnSubmit={true}
                    resetFormAfterSubmit={true}
                    submitCallback={this.submitCallbackForTopic}
                >
                    {({ values, errors, onChange, onChangeField, submitPlainForm }) => (
                        <React.Fragment>
                            <React.Fragment>
                                <Row className="admin-content-wrapper">
                                    <Col>
                                        {this.renderBreadCrumb([
                                            this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_FAQ, DEFAULT_TEXT.FAQ),
                                            this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_ADDNEWFAQTOPIC, DEFAULT_TEXT.ADD_NEW_FAQ_TOPIC)])}​
                                        <Text
                                            name="Title"
                                            value={values.Title}
                                            placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_PLACEHOLDER_FAQTOPICTITLE, DEFAULT_TEXT.FAQ_TOPIC_TITLE)}
                                            onChange={onChange}
                                            minLength={0}
                                            orientation={ORIENTATION.INLINE}
                                            inputSize={FIELD_SIZE.MAX}
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_TITLE, DEFAULT_TEXT.TITLE)}
                                            error={errors.Title}
                                            required
                                        />
                                        <Paragraph
                                            name="Description"
                                            value={values.Description}
                                            placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_PLACEHOLDER_FAQTOPICDESC, DEFAULT_TEXT.FAQ_TOPIC_DESCRIPTION)}
                                            onChange={onChange}
                                            minLength={0}
                                            orientation={ORIENTATION.INLINE}
                                            inputSize={FIELD_SIZE.MAX}
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_DESCRIPTION, DEFAULT_TEXT.DESCRIPTION)}
                                            error={errors.Description}
                                        />
                                    </Col>
                                </Row>
                                <div className="oh-card-footer">
                                    <Button color="forward" size="sm" type="button" onClick={() => this.addTopic(values, submitPlainForm)}>
                                        {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_ADD, DEFAULT_TEXT.ADD)}
                                    </Button>
                                </div>
                            </React.Fragment>
                        </React.Fragment>
                    )}
                </SMARTSubForm>
            </React.Fragment>
        );
    }

    // Fn: Callbck for adding faq topic 
    submitCallbackForTopic = async ({ response }) => {

        this.setState({
            updateTreeStatus: UPDATE_TREE_STATUS.emptyStatus
        });

        if (response.success) {
            const { IsSuccess, Message, Data } = response.body;
            if (IsSuccess) {
                toastSuccess(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_SUCCESS_TITLE_CREATETOPIC, SUCCESS.CREATE_SUCCESS), DEFAULT_TEXT.FAQ_TOPIC);

                this.setState({
                    currId: Data
                });

                //Get Faq Tree
                await this.getFaqTree(GET_FAQ_TREE_URL, FAQ_TOPIC);

                this.setState({
                    selectedNodeId: Data,
                    selectedNodeType: FAQ_TOPIC
                });

            }
            else {
                toastError(Message);
            }
        } else {
            toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
        }
    }

    // Fn: Set parent Id
    addTopic = (values, submitPlainForm) => {
        this.setState({
            parentId: values.FaqId
        });

        submitPlainForm();
    }


    // Fn: Render adding faq sub-topic view
    renderAddSubTopic = () => {
        return (
            <React.Fragment>
                <SMARTSubForm
                    key={UPDATE_TREE_STATUS.addSubTopicStatus}
                    formContext={FORM_CONTEXT}
                    formValues={this.state.values_subTopic}
                    serverURL={ADD_FAQ_SUB_TOPIC_URL}
                    validationSchema={this.getValidationSchemaForTitle()}
                    validateOnSubmit={true}
                    resetFormAfterSubmit={true}
                    submitCallback={this.submitCallbackForSubTopic}
                >
                    {({ values, errors, onChange, submitPlainForm }) => (
                        <React.Fragment>
                            <React.Fragment>
                                <Row className="admin-content-wrapper">
                                    <Col>
                                        {this.renderBreadCrumb([
                                            this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_FAQ, DEFAULT_TEXT.FAQ),
                                            this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_TOPIC, DEFAULT_TEXT.TOPIC),
                                            this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_ADDNEWFAQSUBTOPIC, DEFAULT_TEXT.ADD_NEW_FAQ_SUB_TOPIC)])}
                                        <Text
                                            name="Title"
                                            value={values.Title}
                                            placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_PLACEHOLDER_FAQSUBTOPICTITLE, DEFAULT_TEXT.FAQ_SUB_TOPIC_TITLE)}
                                            onChange={onChange}
                                            minLength={0}
                                            orientation={ORIENTATION.INLINE}
                                            inputSize={FIELD_SIZE.MAX}
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_TITLE, DEFAULT_TEXT.TITLE)}
                                            error={errors.Title}
                                            required
                                        />
                                        <Paragraph
                                            name="Description"
                                            value={values.Description}
                                            placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_PLACEHOLDER_FAQSUBTOPICDESC, DEFAULT_TEXT.FAQ_SUB_TOPIC_DESCRIPTION)}
                                            onChange={onChange}
                                            minLength={0}
                                            orientation={ORIENTATION.INLINE}
                                            inputSize={FIELD_SIZE.MAX}
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_DESCRIPTION, DEFAULT_TEXT.DESCRIPTION)}
                                            error={errors.Description}
                                        />
                                    </Col>
                                </Row>
                                <div className="oh-card-footer">
                                    <Button color="forward" size="sm" type="button" onClick={() => this.addSubTopic(submitPlainForm)}>
                                        {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_ADD, DEFAULT_TEXT.ADD)}
                                    </Button>
                                </div>
                            </React.Fragment>
                        </React.Fragment>
                    )}
                </SMARTSubForm>
            </React.Fragment>
        );
    }

    // Fn: Call back for adding sub-topic
    submitCallbackForSubTopic = async ({ response }) => {
        if (response.success) {
            const { IsSuccess, Message, Data } = response.body;
            if (IsSuccess) {
                toastSuccess(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_SUCCESS_TITLE_CREATESUBTOPIC, SUCCESS.CREATE_SUCCESS), DEFAULT_TEXT.FAQ_SUB_TOPIC);

                this.setState({
                    currId: Data
                });

                //reload grid
                this.getQuestionSetGridList(Data);

                //Get Faq Tree
                await this.getFaqTree(GET_FAQ_TREE_URL, FAQ_SUB_TOPIC);

                this.setState({
                    selectedNodeId: Data,
                    selectedNodeType: FAQ_SUB_TOPIC,
                    updateTreeStatus: UPDATE_TREE_STATUS.viewQuestionSetListStatus
                });


            }
            else {
                toastError(Message);
            }
        } else {
            toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
        }
    }

    // Fn: Set parent Id
    addSubTopic = (submitPlainForm) => {
        this.setState({
            parentId: this.state.faqId
        });

        submitPlainForm();
    }


    // Fn: Render adding queestion set view
    renderAddQuestionSet = () => {
        return (
            <React.Fragment>
                <SMARTSubForm
                    key={UPDATE_TREE_STATUS.addQuestionSetStatus}
                    formContext={FORM_CONTEXT}
                    formValues={this.state.values_questionSet}
                    serverURL={ADD_FAQ_QUESTION_SET_URL}
                    validateOnSubmit={true}
                    resetFormAfterSubmit={true}
                    submitCallback={this.submitCallbackForQuestionSet}
                >
                    {({ values, errors, onChange, onChangeField, submitPlainForm }) => (
                        <React.Fragment>
                            <React.Fragment>
                                <Row className="admin-content-wrapper">
                                    <Col>
                                        {this.renderBreadCrumb([
                                            this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_FAQ, DEFAULT_TEXT.FAQ),
                                            this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_TOPIC, DEFAULT_TEXT.TOPIC),
                                            this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_SUBTOPIC, DEFAULT_TEXT.SUB_TOPIC),
                                            this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_ADDNEWQNSET, DEFAULT_TEXT.ADD_NEW_QN_SET)])}

                                        <MenulessCreatableSelectList
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_TAGS, DEFAULT_TEXT.TAGS)}
                                            name="TagList"
                                            value={values.TagList || []}
                                            onChangeField={onChangeField}
                                            placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_PLACEHOLDER_TAGS, DEFAULT_TEXT.TAGS)}
                                            isDisabled={false}
                                            inputSize={FIELD_SIZE.MAX}
                                            isClearable
                                            helpLabel={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_HELPTEXT_TAGS, DEFAULT_TEXT.HELPTEXT_TAGS)}
                                        />

                                        {this.renderQuestionAndAnswer(values, onChange, onChangeField)}

                                        {this.props.isDefaultLanguage &&
                                            <Button className="more-qn-btn" color="forward" size="sm" type="button"
                                                onClick={() => this.addQuestionInput(values, onChangeField)}>
                                                {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_ADDQN, DEFAULT_TEXT.ADD_MORE_QN)}
                                            </Button>
                                        }
                                        <RichTextEditor
                                            name="Answer"
                                            value={values.Answer}
                                            onChangeField={onChangeField}
                                            showHtml={false}
                                            orientation={ORIENTATION.INLINE}
                                            inputSize={FIELD_SIZE.MAX}
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_ANSWER, DEFAULT_TEXT.ANSWER)}
                                            error={errors.Answer}
                                            toolbar={RICHTEXT.DEFAULT}
                                        />

                                    </Col>
                                </Row>
                                <div className="oh-card-footer">
                                    <Button color="backward" size="sm" type="button" onClick={() => this.backToFaqQuestionSetList(this.state.questionSetDetail)}>
                                        {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_BACK, DEFAULT_TEXT.BACK)}
                                    </Button>
                                    <Button color="forward" size="sm" type="button" onClick={() => this.addQuestionSet(values, submitPlainForm)}>
                                        {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_ADD, DEFAULT_TEXT.ADD)}
                                    </Button>
                                </div>
                            </React.Fragment>
                        </React.Fragment>
                    )}
                </SMARTSubForm>
            </React.Fragment>
        );
    }

    // Fn: Call back for adding question set
    submitCallbackForQuestionSet = ({ response }) => {
        if (response.success) {
            const { IsSuccess, Message } = response.body;
            if (IsSuccess) {
                toastSuccess(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_SUCCESS_TITLE_CREATEQNSET, DEFAULT_TEXT.FAQ_QN_SET_UPDATE_SUCCESS));
                
                this.getQuestionSetGridList(this.state.currId);

                this.setState({
                    updateTreeStatus: UPDATE_TREE_STATUS.viewQuestionSetListStatus,
                    togglePrimary: "0"
                });

                //Get Faq Tree
                this.getFaqTree(GET_FAQ_TREE_URL, FAQ_SUB_TOPIC);

            }
            else {
                toastError(Message);
            }
        } else {
            toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
        }
    }

    // Fn: Set parent Id
    addQuestionSet = (values, submitPlainForm) => {

        // Provide custom validation to ensure Questions are validated
        if (this.validateQuestionListAndAnswer(values.Question, values.Answer)) {

            let valueArray = [];
            Object.entries(values.TagList).forEach(([key, value]) => valueArray.push(value.value));

            let obj = Object.assign({}, valueArray);

            let value = values.Question[this.state.togglePrimary];

            delete values.Question[this.state.togglePrimary];

            values.Question["-1"] = value;

            values.Tags = obj;

            values.LanguageCode = this.props.faqLanguageCode;


            this.setState({
                parentId: this.state.faqId,
                currId: values.FaqSubTopicId
            });

            submitPlainForm();

        }
    }

    // Fn: Custom validation for question set
    validateQuestionListAndAnswer = (questions, answer) => {

        // Check Answer
        if (answer === null || answer.trim() === '') {
            toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_ERROR_TITLE_ANSWERREQUIRED, DEFAULT_TEXT.FILL_UP_ANSWER))
            return false;
        }

        // Convert to array
        let qnArr = Object.values(questions);

        // For each question
        for (let qn of qnArr) {
            // Check undefined, null, empty string and whitespace
            if (qn === null || qn.trim() === '') {
                // Toast error
                toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_ERROR_TITLE_ALLQUESTIONSREQUIRED, DEFAULT_TEXT.FILL_UP_ALL_QUESTIONS))

                // Set to false and break from loop
                return false;
            }
        }

        return true;
    };

    // Fn: Custom validation for question set
    validateQuestionListAndAnswerForEdit = (questions, answer) => {

        // Check Answer
        if (answer === null || answer.trim() === '') {
            toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_ERROR_TITLE_ANSWERREQUIRED, DEFAULT_TEXT.FILL_UP_ANSWER))
            return false;
        }

        for (let qnItem of Object.entries(questions)) {

            let qn = qnItem[1].Question;

            // Check undefined, null, empty string and whitespace
            if (qn === null || qn.trim() === '') {

                // Toast error
                toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_ERROR_TITLE_ALLQUESTIONSREQUIRED, DEFAULT_TEXT.FILL_UP_ALL_QUESTIONS))

                // Set to false and break from loop
                return false;
            }
        }

        return true;
    };


    // Fn: Render view Faq view
    renderViewFaq = () => {
        return (
            <React.Fragment>
                <SMARTSubForm
                    formContext={FORM_CONTEXT}
                    formValues={this.state.values_faq_view}
                >
                    {({ values }) => (
                        <React.Fragment>
                            <React.Fragment>
                                <Row className="admin-content-wrapper">
                                    <Col>
                                        <Plaintext
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_TITLE, DEFAULT_TEXT.TITLE)}
                                            name="Title"
                                            value={values.Title}
                                            labelSize={LABEL_SIZE}
                                            inputSize={INPUT_SIZE}
                                        />
                                        <Plaintext
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_DESCRIPTION, DEFAULT_TEXT.DESCRIPTION)}
                                            name="Description"
                                            value={values.Description}
                                            labelSize={LABEL_SIZE}
                                            inputSize={INPUT_SIZE}
                                        />
                                        <Plaintext
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_CREATEDBY, DEFAULT_TEXT.CREATEDBY)}
                                            name="CreatedBy"
                                            value={values.CreatedBy}
                                            labelSize={LABEL_SIZE}
                                            inputSize={INPUT_SIZE}
                                        />
                                        <Plaintext
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_LASTUPDATEDBY, DEFAULT_TEXT.LAST_UPDATED_BY)}
                                            name="LastUpdatedBy"
                                            value={values.LastUpdatedBy}
                                            labelSize={LABEL_SIZE}
                                            inputSize={INPUT_SIZE}
                                        />
                                        <Plaintext
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_LASTUPDATEDDATE, DEFAULT_TEXT.LAST_UPDATED_DATE)}
                                            name="FormattedLastUpdatedDate"
                                            value={values.FormattedLastUpdatedDate}
                                            labelSize={LABEL_SIZE}
                                            inputSize={INPUT_SIZE}
                                        />
                                    </Col>
                                </Row>
                            </React.Fragment>
                        </React.Fragment>
                    )}
                </SMARTSubForm>
            </React.Fragment>
        );
    }

    // Fn: Render view Faq Topic view
    renderViewFaqTopic = () => {
        return (
            <React.Fragment>
                <SMARTSubForm
                    formContext={FORM_CONTEXT}
                    formValues={this.state.values_faq_topic_view}
                >
                    {({ values }) => (
                        <React.Fragment>
                            <React.Fragment>
                                <Row className="admin-content-wrapper">
                                    <Col>
                                        <Plaintext
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_TITLE, DEFAULT_TEXT.TITLE)}
                                            name="Title"
                                            value={values.Title}
                                            labelSize={LABEL_SIZE}
                                            inputSize={INPUT_SIZE}
                                        />
                                        <Plaintext
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_DESCRIPTION, DEFAULT_TEXT.DESCRIPTION)}
                                            name="Description"
                                            value={values.Description}
                                            labelSize={LABEL_SIZE}
                                            inputSize={INPUT_SIZE}
                                        />
                                        <Plaintext
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_CREATEDBY, DEFAULT_TEXT.CREATED_BY)}
                                            name="CreatedBy"
                                            value={values.CreatedBy}
                                            labelSize={LABEL_SIZE}
                                            inputSize={INPUT_SIZE}
                                        />
                                        <Plaintext
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_LASTUPDATEDBY, DEFAULT_TEXT.LAST_UPDATED_BY)}
                                            name="LastUpdatedBy"
                                            value={values.LastUpdatedBy}
                                            labelSize={LABEL_SIZE}
                                            inputSize={INPUT_SIZE}
                                        />
                                        <Plaintext
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_LASTUPDATEDDATE, DEFAULT_TEXT.LAST_UPDATED_DATE)}
                                            name="FormattedLastUpdatedDate"
                                            value={values.FormattedLastUpdatedDate}
                                            labelSize={LABEL_SIZE}
                                            inputSize={INPUT_SIZE}
                                        />
                                    </Col>
                                </Row>
                            </React.Fragment>
                        </React.Fragment>
                    )}
                </SMARTSubForm>
            </React.Fragment>
        );
    }

    // Fn: Render view Faq Sub-Topic view
    renderViewFaqSubTopic = () => {
        return (
            <React.Fragment>
                <SMARTSubForm
                    formContext={FORM_CONTEXT}
                    formValues={this.state.values_faq_sub_topic_view}
                >
                    {({ values }) => (
                        <React.Fragment>
                            <React.Fragment>
                                <Row className="admin-content-wrapper">
                                    <Col>
                                        <Plaintext
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_TITLE, DEFAULT_TEXT.TITLE)}
                                            name="Title"
                                            value={values.Title}
                                            labelSize={LABEL_SIZE}
                                            inputSize={INPUT_SIZE}
                                        />
                                        <Plaintext
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_DESCRIPTION, DEFAULT_TEXT.DESCRIPTION)}
                                            name="Description"
                                            value={values.Description}
                                            labelSize={LABEL_SIZE}
                                            inputSize={INPUT_SIZE}
                                        />
                                        <Plaintext
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_CREATEDBY, DEFAULT_TEXT.CREATED_BY)}
                                            name="CreatedBy"
                                            value={values.CreatedBy}
                                            labelSize={LABEL_SIZE}
                                            inputSize={INPUT_SIZE}
                                        />
                                        <Plaintext
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_LASTUPDATEDBY, DEFAULT_TEXT.LAST_UPDATED_BY)}
                                            name="LastUpdatedBy"
                                            value={values.LastUpdatedBy}
                                            labelSize={LABEL_SIZE}
                                            inputSize={INPUT_SIZE}
                                        />
                                        <Plaintext
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_LASTUPDATEDDATE, DEFAULT_TEXT.LAST_UPDATED_DATE)}
                                            name="FormattedLastUpdatedDate"
                                            value={values.FormattedLastUpdatedDate}
                                            labelSize={LABEL_SIZE}
                                            inputSize={INPUT_SIZE}
                                        />
                                    </Col>
                                </Row>
                            </React.Fragment>
                        </React.Fragment>
                    )}
                </SMARTSubForm>
            </React.Fragment>
        );
    }

    // Fn: Render View Faq Question Set view
    renderViewFaqQuestionSet = () => {
        return (
            <React.Fragment>
                <SMARTSubForm
                    key={UPDATE_TREE_STATUS.viewFaqQuestionSetStatus}
                    formContext={FORM_CONTEXT}
                    formValues={this.state.questionSetDetail}
                >
                    {({ values, errors, onChange, onChangeField }) => (
                        <React.Fragment>
                            <React.Fragment>
                                <Row className="admin-content-wrapper">
                                    <Col>
                                        {this.renderBreadCrumb([
                                            this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_FAQ, DEFAULT_TEXT.FAQ),
                                            this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_TOPIC, DEFAULT_TEXT.TOPIC),
                                            this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_SUBTOPIC, DEFAULT_TEXT.SUB_TOPIC),
                                            this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_VIEWQNSET, DEFAULT_TEXT.VIEW_QNSET)])}

                                        <MenulessCreatableSelectList
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_TAGS, DEFAULT_TEXT.TAGS)}
                                            name="TagList"
                                            value={values.TagList || []}
                                            onChangeField={onChangeField}
                                            placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_PLACEHOLDER_TAGS, DEFAULT_TEXT.TAGS)}
                                            isDisabled={true}
                                            inputSize={FIELD_SIZE.MAX}
                                            isClearable
                                        />

                                        {this.renderViewQuestionList(values)}
                                        
                                        <RichText 
                                            html={values.Answer}
                                            inputSize={FIELD_SIZE.MAX}
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_FAQANS, DEFAULT_TEXT.FAQ_ANSWER)}
                                        />
                                    </Col>
                                </Row>
                            </React.Fragment>
                        </React.Fragment>
                    )}
                </SMARTSubForm>
            </React.Fragment>
        );
    }

    // Fn: Render question list for View Faq Question Set
    renderViewQuestionList = (values) => {
        let count = 0;

        return (_.map(values.QuestionDictionary, (v, i) => { count++; return this.renderQuestionSetListDetail(values, i, undefined, count, false); }));
    }

    setSelectedNode = (node) => {

        // Case: Only set if its not QuestionSet type, as it is not part of Tree
        if (node.Type !== FAQ_QUESTION_SET) {
            this.setState({
                selectedNodeId: node.ID,
                selectedNodeType: node.Type
            });
        }
    };

    isSelectedNode = (node) => {
        return ((node.ID === this.state.selectedNodeId) && (node.Type === this.state.selectedNodeType)) ?
            ("node selected-node") :
            ("node");
    };

    // Fn: Toggle minimize or maximize tree horizontally
    toggleMinimizeTree = () => {
        this.setState({
            minimizeTree: !this.state.minimizeTree
        })
    }

    handleSearch = valueSearch => {
        //only search if user enters more than 3 chars
        if (valueSearch && valueSearch.length>=3) {
            this.fetchSearchData(valueSearch);
        } else {
            this.setState({
                dataSearch: [],
                valueSearch: undefined
            });
        }
    };

    fetchSearchData = async (valueSearch) => {
        const { faqId, status } = this.state;
        const { faqLanguageCode } = this.props;
        const params = {
            faqId: faqId,
            searchText: valueSearch,
            LanguageCode: faqLanguageCode,
            IsPublished: status !== DRAFT_STATUS
        }

        this.setState({
            searchLoading: true,
            valueSearch
        })
        const response = await fetchRequest(SEARCH_FAQ_URL, getParams(params));
        const { Data, IsSuccess } = response.body;
        if (response.success) {
            if (IsSuccess) {
                let dataSearch = [];

                Data.forEach((dataQuestionSet) => {
                    dataSearch.push({
                        value: dataQuestionSet.TopicId + "." + dataQuestionSet.SubTopicId + "." + dataQuestionSet.FaqQuestionSetId + "." + dataQuestionSet.Id + "." + dataQuestionSet.Type,
                        text: <div className="search-values">
                            {dataQuestionSet.SearchResult}
                            <div className="search-bread-crumb">
                                {dataQuestionSet.TopicName} {">"} {dataQuestionSet.SubTopicName} </div>
                        </div>
                    })
                })

                this.setState({
                    dataSearch
                });
            }
            else {
                toastError(response.body.Messages.toString());
            }
        } else {
            toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
        }
        this.setState({
            searchLoading: false
        })
    }


    // handle change after selecting from search drop down
    handleSelectSearchChange = valueSearch => {
        const { faqId, status } = this.state;
        var valuesArray = valueSearch.split('.');

        var subTopicID= parseInt(valuesArray[1]);
        var node = {
            ID: subTopicID,
            Type: FAQ_SUB_TOPIC
        };
        // Set selected sub-topic used for refresh grid purposes
        this.setState({
            selectedSubTopicId: subTopicID
        });
        this.setSelectedNode(node);
        //this.getFaqTree(GET_FAQ_TREE_URL, FAQ_SUB_TOPIC);
        if (status === DRAFT_STATUS) {
            this.getFaqQuestionSet(faqId, valuesArray[0], valuesArray[1], valuesArray[2], this.props.faqLanguageCode, EDIT);
        }
        else {
            this.getFaqQuestionSet(faqId, valuesArray[0], valuesArray[1], valuesArray[2], this.props.faqLanguageCode, VIEW);
        }
        //clear the search box after clicked
        this.handleSearch(undefined);

    };


    // Render tree view header
    renderTreeHeader = () => {
        // Get minimize tree icon
        let minimizeTreeIcon = (this.state.minimizeTree) ? faAngleRight : faAngleLeft;

        let { searchLoading, valueSearch, dataSearch } = this.state;

        //fill 
        const optionsSearch = dataSearch.map(d => <Select.Option key={d.value}>{d.text}</Select.Option>);

        // Return display view
        return (
            <>
                <Col xs="5" className="toggle-display">
                    <ActionButton 
                        color="neutral" 
                        className='btn-minimize' 
                        tooltip={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_TABLE_TOOLTIP_TOGGLETREEVIEW, DEFAULT_TEXT.TOGGLE_TREE_VIEW)} 
                        onClick={() => this.toggleMinimizeTree()}>
                        <FontAwesomeIcon fixedWidth icon={minimizeTreeIcon} />
                    </ActionButton>

                    {(!this.state.minimizeTree) && (<p>{this.props.treeHeader}</p>)}
                </Col>
                <Col xs="7">
                    <Select
                        showSearch
                        value={valueSearch}
                        placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_PLACEHOLDER_SEARCHQNSET, DEFAULT_TEXT.SEARCH_QNSET)} 
                        defaultActiveFirstOption={false}
                        showArrow={searchLoading}
                        filterOption={false}
                        onSearch={this.handleSearch}
                        onChange={this.handleSelectSearchChange}
                        notFoundContent={null}
                        loading={searchLoading}
                        autoClearSearchValue={true}
                        className="select-search"
                    >
                        {optionsSearch}
                    </Select>
                </Col>
            </>
        );
    };

    // Fn: Render Tree View
    renderTreeView = () => {
        return (this.state.loadingTree) ?
            (<Spin tip={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_TABLE_TOOLTIP_LOADING, DEFAULT_TEXT.LOADING)} className={"tree-content-loader"} />) :
            (this.state.documentReady && this.renderNodes());
    };


    // Fn: Render Tree nodes
    renderNodes = () => {
        const { className } = this.props;
        if (!this.state.success) return null;
        let elements;
        (this.state.treeFilterInput.length > 2 && this.state.renderFilterStatus === true ? elements = this.renderChildren(this.state.filteredNode) : elements = this.renderChildren(this.state.data));
        return (
            <div className={"tree " + className}>
                {elements}
            </div>
        );
    }

    // Render tree data
    renderChildren = (nodeItem) => {
        const { renderActions } = this.props;
        if (typeof nodeItem === 'undefined' || nodeItem.length === 0) {
            return null;
        } else {
            let elements = [];
            elements = nodeItem.map((element, index) => {
                let item = null;
                let node;

                // A node with children
                if (element.children.length > 0 && element.toggleParent) {
                    let children = this.renderChildren(element.children);
                    (
                        renderActions === false ?
                            node = (
                                <React.Fragment>
                                    <div className={this.isSelectedNode(element)}>
                                        <i className="fa-close"><FontAwesomeIcon fixedWidth icon={faCaretDown} /></i><span className="node-name">{element.Title}</span>
                                        <span className="actions">
                                            <i className="fa-edit" onClick={(e) => { e.stopPropagation(); this.viewNode(element); }}><FontAwesomeIcon fixedWidth icon={faEye} /></i>
                                        </span>
                                    </div>
                                </React.Fragment>
                            ) :
                            node = (
                                <React.Fragment>
                                    <div className={this.isSelectedNode(element)}>
                                        <i className="fa-close"><FontAwesomeIcon fixedWidth icon={faCaretDown} /></i><div className="node-name">{element.Title}{this.NodeMenuToggleIcon(element, (index === nodeItem.length - 1))}</div>
                                    </div>
                                </React.Fragment>
                            )
                    )
                    item = (
                        <li key={index} onClick={(e) => { e.stopPropagation(); this.toggleParent(element) }}>
                            {node}
                            {children}
                        </li>
                    )
                }

                // Toggle parent node
                else if (element.children.length > 0 && !element.toggleParent) {
                    item = (
                        <li key={index} onClick={(e) => { e.stopPropagation(); this.toggleParent(element) }}>
                            <div className={this.isSelectedNode(element)}>
                                <i className="fa-open"><FontAwesomeIcon fixedWidth icon={faCaretRight} /></i><span className="node-name">{element.Title}{this.NodeMenuToggleIcon(element, (index === nodeItem.length - 1))}</span>
                            </div>
                        </li>
                    );
                }

                // A final node or a node with no children
                else if (element.children.length === 0) {
                    renderActions === false ?
                        node = (
                            <React.Fragment>
                                <div className={this.isSelectedNode(element)}>
                                    <i><FontAwesomeIcon className="tree-fa-circle" fixedWidth icon={faCircle} /></i><span className="node-name">{element.Title}</span>
                                    <span className="actions">
                                        <i className="fa-edit" onClick={(e) => { e.stopPropagation(); this.viewNode(element) }}><FontAwesomeIcon fixedWidth icon={faEye} /></i>
                                    </span>
                                </div>
                            </React.Fragment>
                        ) :
                        node = (
                            <React.Fragment>
                                <div className={this.isSelectedNode(element)}>
                                    <i><FontAwesomeIcon className="tree-fa-circle" fixedWidth icon={faCircle} /></i><span className="node-name">{element.Title}{this.FinalNodeMenuToggleIcon(element, (index === nodeItem.length - 1))}</span>
                                </div>
                            </React.Fragment>
                        )
                    item = (
                        <li key={index} onClick={(e) => e.stopPropagation()}>
                            {node}
                        </li>
                    );
                }
                return item;
            });
            return (
                <ul >
                    {elements}
                </ul>
            );
        }
    }

    // Fn: Render node action menu based on status Published / Draft
    NodeMenuToggleIcon = (element, isLastItem) => {
        return (
            <TreeMenuContainer
                toggleContent={(<i className="actions-custom fa-ellipsis-h"><FontAwesomeIcon icon={faEllipsisH} /></i>)}>
                {(this.state.status === DRAFT_STATUS) ?
                    (<>
                        <TreeMenuItem onClick={() => this.editNode(element)}><i className="fa-edit"><FontAwesomeIcon fixedWidth icon={faEdit} /></i> 
                            {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_EDIT, DEFAULT_TEXT.EDIT)}
                        </TreeMenuItem>

                        {this.props.isDefaultLanguage &&
                            (<>
                                <DropdownItem divider />

                                <TreeMenuItem onClick={() => this.addChild(element)}><i className="fa-add"><FontAwesomeIcon fixedWidth icon={faPlusSquare} /></i> 
                                    {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_ADD, DEFAULT_TEXT.ADD)}
                                </TreeMenuItem>
                                {(element.Type !== FAQ) && (<><DropdownItem divider /><TreeMenuItem onClick={() => this.toggleDeleteNodeModal(DELETE_MODAL.name, element)}><i className="fa-del"><FontAwesomeIcon fixedWidth icon={faTrash} /></i>
                                    {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_DELETE, DEFAULT_TEXT.DELETE)}
                                 </TreeMenuItem></>)}
                                {this.SortMenuItem(element, isLastItem)}
                            </>)}
                    </>)
                    :
                    (<TreeMenuItem onClick={() => this.viewNode(element)}><i className="fa-edit"><FontAwesomeIcon fixedWidth icon={faEye} /></i>
                        {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_VIEW, DEFAULT_TEXT.VIEW)}
                    </TreeMenuItem>)}
            </TreeMenuContainer>);
    };

    // Fn: Render parent node action buttons based on status Published / Draft
    FinalNodeMenuToggleIcon = (element, isLastItem) => {

        let isSubTopic = (element.Type === FAQ_SUB_TOPIC);

        return (
            <TreeMenuContainer
                toggleContent={(<i className="actions-custom fa-ellipsis-h"><FontAwesomeIcon icon={faEllipsisH} /></i>)}>
                {(this.state.status === DRAFT_STATUS) ?
                    (<>
                        {(isSubTopic) && 
                        (<>
                            <TreeMenuItem onClick={() => this.loadQuestionSetList(element)}><i className="fa-del"><FontAwesomeIcon fixedWidth icon={faList} /></i> 
                                {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_FAQQNSETS, DEFAULT_TEXT.BTN_FAQ_QNSETS)}
                            </TreeMenuItem>
                            <DropdownItem divider />
                        </>)}

                        <TreeMenuItem onClick={() => this.editNode(element)}><i className="fa-edit"><FontAwesomeIcon fixedWidth icon={faEdit} /></i> 
                            {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_EDIT, DEFAULT_TEXT.EDIT)}
                        </TreeMenuItem>

                        {this.props.isDefaultLanguage &&
                            (<>
                                <DropdownItem divider />
                                <TreeMenuItem onClick={() => this.addChild(element)}><i className="fa-add"><FontAwesomeIcon fixedWidth icon={faPlusSquare} /></i> 
                                    {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_ADD, DEFAULT_TEXT.ADD)}
                                </TreeMenuItem>

                                {(element.Type !== FAQ) && 
                                    (<><DropdownItem divider /><TreeMenuItem onClick={() => this.toggleDeleteNodeModal(DELETE_MODAL.name, element)}><i className="fa-del"><FontAwesomeIcon fixedWidth icon={faTrash} /></i> 
                                        {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_DELETE, DEFAULT_TEXT.DELETE)}
                                    </TreeMenuItem></>)}
                                {this.SortMenuItem(element, isLastItem)}
                            </>)}
                    </>)
                    :
                    (<>
                        {(isSubTopic) && 
                            (<>
                            <TreeMenuItem onClick={() => this.loadQuestionSetList(element)}><i className="fa-del"><FontAwesomeIcon fixedWidth icon={faList} /></i> 
                                {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_FAQQNSETS, DEFAULT_TEXT.BTN_FAQ_QNSETS)}
                            </TreeMenuItem>
                            <DropdownItem divider /></>)}
                            <TreeMenuItem onClick={() => this.viewNode(element)}><i className="fa-edit"><FontAwesomeIcon fixedWidth icon={faEye} /></i> 
                                {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_VIEW, DEFAULT_TEXT.VIEW)}
                            </TreeMenuItem>
                    </>)}
            </TreeMenuContainer>);
    };

    SortMenuItem = (element, isLastItem) => {
        return (
            <>
                {(element.Rank > 1) && (<><DropdownItem divider /><TreeMenuItem onClick={() => { this.setSelectedNode(element); this.sortNodeUp(element); }}><i className="fa-sort"><FontAwesomeIcon fixedWidth icon={faAngleDoubleUp} /></i> 
                    {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_MOVEUP, DEFAULT_TEXT.MOVEUP)}
                </TreeMenuItem></>)}
                {(!isLastItem) && (<><DropdownItem divider /><TreeMenuItem onClick={() => { this.setSelectedNode(element); this.sortNodeDown(element); }}><i className="fa-sort"><FontAwesomeIcon fixedWidth icon={faAngleDoubleDown} /></i> 
                    {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_MOVEDOWN, DEFAULT_TEXT.MOVEDOWN)}
                </TreeMenuItem></>)}
            </>);
    };

    render() {
        const { renderActions, className } = this.props;
        return (
            <React.Fragment>
                <CardDeck className="codetable-mgmt-list">
                    <Col xl={this.state.minimizeTree ? "3" : "6"} className={this.props.leftTreeContainer}>
                        <Card className={"tree-card " + className}>
                            <CardHeader className={"tree-card-header " + className}>
                                {this.renderTreeHeader()}
                            </CardHeader>
                            <CardBody className={"tree-card-body" + className}>
                                {this.renderTreeView()}
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xl={this.state.minimizeTree ? "9" : "6"} className={this.props.rightTreeContainer}>
                        <Card className={"tree-card " + className}>
                            <CardHeader className={"tree-card-header " + className}>
                                <p>
                                    {this.state.updateTreeStatus &&
                                        (renderActions ?
                                            this.UpdateTreeCardDetails[this.state.updateTreeStatus].title
                                            : this.UpdateTreeCardDetails[this.state.updateTreeStatus].noActionTitle)
                                    }

                                </p>
                            </CardHeader>
                            <CardBody>
                                {this.state.updateTreeStatus && this.UpdateTreeCardDetails[this.state.updateTreeStatus].body()}
                            </CardBody>
                        </Card>
                    </Col>
                </CardDeck>
                <ModalConfirm
                    isOpen={(this.state.modalState === DELETE_MODAL.name)}
                    contentHeader={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_MODAL_TITLE_DELETENODE, DEFAULT_TEXT.DELETE_NODE_MODAL_TITLE)}
                    contentBody={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_MODAL_TEXT_DELETENODE, DEFAULT_TEXT.DELETE_NODE_MODAL_TEXT, true)}
                    confirmText={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_CONFIRM, DEFAULT_TEXT.CONFIRM)}
                    confirmCallback={() => { this.toggleDeleteNodeModal(DELETE_MODAL.name); this.deleteNode(); }}
                    cancelText={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_CANCEL, DEFAULT_TEXT.CANCEL)}
                    cancelCallback={() => this.toggleDeleteNodeModal(DELETE_MODAL.name)}
                />
            </React.Fragment>
        );
    }


    UpdateTreeCardDetails = {
        emptyStatus: { title: "", noActionTitle: "", body: "" },

        addTopicStatus: { 
            title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_NEWFAQTOPIC, DEFAULT_TEXT.NEW_FAQ_TOPIC), 
            noActionTitle: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_NEWFAQTOPIC, DEFAULT_TEXT.NEW_FAQ_TOPIC), 
            body: this.renderAddTopic },
        addSubTopicStatus: { 
            title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_NEWFAQSUBTOPIC, DEFAULT_TEXT.NEW_FAQ_SUB_TOPIC), 
            noActionTitle: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_NEWFAQSUBTOPIC, DEFAULT_TEXT.NEW_FAQ_SUB_TOPIC), 
            body: this.renderAddSubTopic },
        addQuestionSetStatus: { 
            title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_NEWFAQQNSET, DEFAULT_TEXT.NEW_FAQ_QNSET), 
            noActionTitle: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_NEWFAQQNSET, DEFAULT_TEXT.NEW_FAQ_QNSET), 
            body: this.renderAddQuestionSet },

        editFaqStatus: { 
            title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_EDITFAQ, DEFAULT_TEXT.EDIT_FAQ), 
            noActionTitle: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_EDITFAQ, DEFAULT_TEXT.EDIT_FAQ), 
            body: this.renderEditFaq },
        editFaqTopicStatus: { 
            title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_EDITFAQTOPIC, DEFAULT_TEXT.EDIT_FAQ_TOPIC), 
            noActionTitle: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_EDITFAQTOPIC, DEFAULT_TEXT.EDIT_FAQ_TOPIC), 
            body: this.renderEditFaqTopic },
        editFaqSubTopicStatus: { 
            title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_EDITFAQSUBTOPIC, DEFAULT_TEXT.EDIT_FAQ_SUB_TOPIC), 
            noActionTitle: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_EDITFAQSUBTOPIC, DEFAULT_TEXT.EDIT_FAQ_SUB_TOPIC), 
            body: this.renderEditFaqSubTopic },
        editFaqQuestionSetStatus: { 
            title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_EDITFAQQNSET, DEFAULT_TEXT.EDIT_FAQ_QNSET), 
            noActionTitle: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_EDITFAQQNSET, DEFAULT_TEXT.EDIT_FAQ_QNSET), 
            body: this.renderEditFaqQuestionSet },

        viewFaqStatus: { 
            title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_VIEWFAQ, DEFAULT_TEXT.VIEW_FAQ), 
            noActionTitle: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_VIEWFAQ, DEFAULT_TEXT.VIEW_FAQ), 
            body: this.renderViewFaq },
        viewFaqTopicStatus: { 
            title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_VIEWFAQTOPIC, DEFAULT_TEXT.VIEW_FAQ_TOPIC), 
            noActionTitle: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_VIEWFAQTOPIC, DEFAULT_TEXT.VIEW_FAQ_TOPIC), 
            body: this.renderViewFaqTopic },
        viewFaqSubTopicStatus: { 
            title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_VIEWFAQSUBTOPIC, DEFAULT_TEXT.VIEW_FAQ_SUB_TOPIC), 
            noActionTitle: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_VIEWFAQSUBTOPIC, DEFAULT_TEXT.VIEW_FAQ_SUB_TOPIC), 
            body: this.renderViewFaqSubTopic },
        viewFaqQuestionSetStatus: { 
            title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_VIEWFAQQNSET, DEFAULT_TEXT.VIEW_FAQ_QNSET), 
            noActionTitle: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_VIEWFAQQNSET, DEFAULT_TEXT.VIEW_FAQ_QNSET), 
            body: this.renderViewFaqQuestionSet },
        viewQuestionSetListStatus: { 
            title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_VIEWQNSET, DEFAULT_TEXT.VIEW_QNSET), 
            noActionTitle: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_VIEWQNSET, DEFAULT_TEXT.VIEW_QNSET), 
            body: this.renderViewQuestionSetList }
    }

}

// PropTypes: For prop validation
FaqTree.propTypes = {
    renderActions: PropTypes.bool.isRequired,
    className: PropTypes.string
};

FaqTree.defaultProps = {
    className: ""
}

export default withSMARTWrapper(FaqTree);
