import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import i18n from '../../../i18n';
import { LAYOUT_TYPE, LICENCE_CONFIG_EDIT, LICENCE_CONFIG_GET_LICENCE_VERSION_BY_ID, REGEX } from '../../App/AppSettings';
import Layout from '../../Layout';
import { getParams, navigateTo } from '../../Shared/Actions';
import { BLS_VERSION_STATUS } from '../../Shared/Constants';
import { ERROR, LANGUAGE_KEYS } from '../../Shared/Constants/LanguageKeys';
import { useAsyncFetch } from '../../Shared/Fetch';
import { SMARTForm } from '../../Shared/Forms';
import { toastError } from '../../Shared/Forms/Toaster.js';
import history from '../../Shared/History';
import PageFooter from '../../Shared/PageFooter';
import { getLangKey, getLicenceTypeNameTranslationKey } from '../DisplayComponents/DisplayUtils';
import Dependency from './Dependency';
import Faq from './Faq';
import LicenceInformation from './LicenceInformation';
// import Publish from './Publish';

const FORM_CONTEXT = 'LicenceConfigEdit';
const SERVER_URL = LICENCE_CONFIG_EDIT;
const REGEX_LESS_THAN_TWELVE = /^\s*[1][0-2]\s*$|^\s*0\s*$|^\s*[1-9]\s*$/;
const REGEX_LESS_THAN_THIRTY_ONE = /^\s*[1-2][0-9]\s*$|^\s*0\s*$|^\s*[1-9]\s*$|^\s*[3][0-1]\s*$/;

export default function LicenceConfigMgmtEdit() {
    const { t } = useTranslation();
    const { LicenceType } = history.location.state;
    const { Status, Id, LicenceTypeId } = LicenceType;
    const [data, setData] = useState(null);
    // const [minEffectiveFromDate, setMinEffectiveFromDate] = useState(new Date());
    const previousPage = history.location.state.from;
    let retrieveUrl = LICENCE_CONFIG_GET_LICENCE_VERSION_BY_ID;
    retrieveUrl += Id;
    const response = useAsyncFetch(retrieveUrl, getParams());

    const errorInvalidNumber = (ERROR.LABEL + t(ERROR.VALID_NUMBER));
    const displayErrorInvalidNumber = (label) => {
        return `${label} ${t(ERROR.VALID_NUM_GREATER_ZERO)}`
    }
    const displayErrorMonthNumber = (label) => {
        return `${label} ${t(ERROR.VALID_NUM_ZERO_TO_TWELVE)}`
    }
    const displayErrorDayNumber = (label) => {
        return `${label} ${t(ERROR.VALID_NUM_ZERO_TO_THIRTYONE)}`
    }
    const FORM_VALIDATIONS = Yup.object().shape({
        LicenceInfo: Yup.object().shape({
            LicenceName: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED)).min(0, t(ERROR.MIN_LENGTH_LIMIT) + (ERROR.MIN_LENGTH_LABEL)),
            LicenceDescription: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED)).min(0, t(ERROR.MIN_LENGTH_LIMIT) + (ERROR.MIN_LENGTH_LABEL)),
            AgencyId: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED)).min(0, t(ERROR.MIN_LENGTH_LIMIT) + (ERROR.MIN_LENGTH_LABEL)).max(140, t(ERROR.MAX_LENGTH_LIMIT) + (ERROR.MAX_LENGTH_LABEL)),
            IsOnline: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED)),
            ExternalLink: Yup.string()
                .when("IsOnline", {
                    is: "false",
                    then: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED)).min(0, t(ERROR.MIN_LENGTH_LIMIT) + (ERROR.MIN_LENGTH_LABEL))
                }),
            FeeDescription: Yup.string()
                .when("IsOnline", {
                    is: "true",
                    then: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED))
                }),
            ProcessTimeDescription: Yup.string()
                .when("IsOnline", {
                    is: "true",
                    then: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED))
                        .matches(REGEX.INTEGER_POSITIVE_INCLUDE_ZERO, errorInvalidNumber)
                }),
            LicencePrefix: Yup.string()
                .when("IsOnline", {
                    is: "true",
                    then: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED)).min(0, t(ERROR.MIN_LENGTH_LIMIT) + (ERROR.MIN_LENGTH_LABEL)).max(3, t(ERROR.MAX_LENGTH_LIMIT) + (ERROR.MAX_LENGTH_LABEL))
                }),
            RFAResponsePeriod: Yup.string()
                .when("IsOnline", {
                    is: "true",
                    then: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED))
                        .matches(REGEX.INTEGER_POSITIVE_INCLUDE_ZERO, errorInvalidNumber)
                }),
            PaymentPeriod: Yup.string()
                .when("IsOnline", {
                    is: "true",
                    then: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED))
                        .matches(REGEX.INTEGER_POSITIVE_INCLUDE_ZERO, errorInvalidNumber)
                }),
            TerminationMinPeriod: Yup.string()
                .when("IsOnline", {
                    is: "true",
                    then: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED))
                        .matches(REGEX.INTEGER_POSITIVE_INCLUDE_ZERO, errorInvalidNumber)
                }),
            ValidityYear: Yup.string()
                .when("IsOnline", {
                    is: "true",
                    then: Yup.string().required(t(ERROR.YEAR_REQUIRED))
                        .matches(REGEX.INTEGER_POSITIVE_INCLUDE_ZERO, displayErrorInvalidNumber(t(LANGUAGE_KEYS.BLS_INTERNALLICENCEEXTENSION_FORM_FIELD_YEAR)))
                }),
            ValidityMonth: Yup.string()
                .when("IsOnline", {
                    is: "true",
                    then: Yup.string().required(t(ERROR.MONTH_REQUIRED))
                        .matches(REGEX_LESS_THAN_TWELVE, displayErrorMonthNumber(t(LANGUAGE_KEYS.BLS_INTERNALLICENCEEXTENSION_FORM_FIELD_MONTH)))
                }),
            ValidityDay: Yup.string()
                .when("IsOnline", {
                    is: "true",
                    then: Yup.string().required(t(ERROR.DAY_REQUIRED))
                        .matches(REGEX_LESS_THAN_THIRTY_ONE, displayErrorDayNumber(t(LANGUAGE_KEYS.BLS_INTERNALLICENCEEXTENSION_FORM_FIELD_DAY)))
                }),
            IsRenewable: Yup.string()
                .when("IsOnline", {
                    is: "true",
                    then: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED))
                }),
            RenewalPeriod: Yup.string()
                .when("IsRenewable", {
                    is: "true",
                    then: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED))
                        .matches(REGEX.INTEGER_POSITIVE_INCLUDE_ZERO, errorInvalidNumber)
                }),
            LatePenaltyPeriod: Yup.string()
                .when("IsRenewable", {
                    is: "true",
                    then: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED))
                        .matches(REGEX.INTEGER_POSITIVE_INCLUDE_ZERO, errorInvalidNumber)
                }),
            // EffectiveFromDate: Yup.date().required((ERROR.LABEL) + t(ERROR.REQUIRED)),
            // EffectiveToDate: Yup.date().min(Yup.ref("EffectiveFromDate"), t(ERROR.EFFECTIVE_DATE_ERROR))
        })
    });

    useEffect(() => {
        const fetchData = async () => {
            const { Data, IsSuccess } = response.body;
            if (response.success && IsSuccess) {
                Data.LicenceInfo.IsOnline = Data.LicenceInfo.IsOnline.toString();
                Data.LicenceInfo.IsRenewable = Data.LicenceInfo.IsRenewable.toString();
                Data.LicenceDefinitionId = Id;
                if (Status === BLS_VERSION_STATUS.ACTIVE) {
                    Data.LicenceInfo.EffectiveFromDate = '';
                    let newDate = new Date();
                    newDate.setDate(newDate.getDate() + 1);
                    //setMinEffectiveFromDate(newDate);
                }
                let count = 0;
                Data.DependencyData.forEach(e => {
                    e.DependencyType = t(getLangKey(LANGUAGE_KEYS.BLS_CODE_CATEGORY_TITLE_KEY, e.DependencyCategory, e.DependencyType));
                    const licenceTypeNameTranslationKey = getLicenceTypeNameTranslationKey(e.LicenceName);
                    e.licenceName = t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY, licenceTypeNameTranslationKey, e.LicenceTypeId));
                    e.RowId = count;
                    count++;
                });
                count = 0;
                Data.FaqData.forEach(e => {
                    e.RowId = count;
                    count++;
                    e.IsEdit = false;
                });
                Data.LicenceTypeId = LicenceTypeId;
                setData(Data);
            }
        };
        if (response) {
            fetchData();
        }
    }, [response, Id, Status, LicenceTypeId, t]);

    const submitCallback = ({ response }) => {
        if (response.success) {
            const { IsSuccess, Messages } = response.body;
            if (IsSuccess) {
                navigateTo(previousPage, { LicenceType: LicenceType });
                i18n.reloadResources();
            } else {
                toastError(t(getLangKey(ERROR.BACKEND_ERROR_MESSAGE, Messages)), Messages);
            }
        } else {
            toastError(t(ERROR.SERVER_UNREACHABLE));
        }
    };

    return (
        <React.Fragment>
            <Layout type={LAYOUT_TYPE.FORM} title={t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_CONTENT_TITLE_EDITSERVICE)}>
                <SMARTForm
                    formContext={FORM_CONTEXT}
                    formValues={data}
                    validationSchema={FORM_VALIDATIONS}
                    validateOnSubmit={true}
                    serverURL={SERVER_URL}
                    isDummy={false}
                    submitCallback={submitCallback}
                    resetFormAfterSubmit={true}
                >
                    {({ values, errors, onChange, onChangeField, submitPlainForm, toggleSection, toggleAllSections, submitForm }) => {
                        const smartFormValues = {
                            toggleSection,
                            toggleAllSections,
                            values,
                            onChange,
                            onChangeField,
                            errors,
                            submitForm
                        };
                        return (
                            <React.Fragment>
                                {values &&
                                    <Container className="full-width">
                                        <Row className="body-content">
                                            <Col>
                                                <LicenceInformation
                                                    smartFormValues={smartFormValues}
                                                    isLicenceNameReadOnly={true}
                                                    isAgencyReadOnly={true}
                                                />
                                                {values.LicenceInfo.IsOnline === 'true' &&
                                                    <div>
                                                        <Dependency
                                                            smartFormValues={smartFormValues}
                                                        />
                                                        <Faq
                                                            smartFormValues={smartFormValues}
                                                        />
                                                    </div>
                                                }
                                                {/* <Publish
                                                    smartFormValues={smartFormValues}
                                                    minEffectiveFromDate={minEffectiveFromDate}
                                                /> */}
                                            </Col>
                                        </Row>
                                    </Container>
                                }

                                <PageFooter type={LAYOUT_TYPE.FORM}>
                                    <Button color="backward" size="sm" type="button" onClick={() => navigateTo(previousPage, { LicenceType: LicenceType })}>
                                        {t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_NAVIGATION_BUTTON_BACK)}
                                    </Button>
                                    <Button color="forward" size="sm" type="button" onClick={submitPlainForm}>
                                        {/* {t(LANGUAGE_KEYS.BLS_INTERNALLICENCECONFIG_NAVIGATION_BUTTON_PUBLISH)} */}
                                        {t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_SUBMIT)}
                                    </Button>
                                </PageFooter>
                            </React.Fragment>
                        );
                    }}
                </SMARTForm>
            </Layout>
        </React.Fragment>
    );
}



