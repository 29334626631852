import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Card, Media } from 'reactstrap';

class ChartCard extends Component {

    render() {
        return (
            <Card className={"cc-card" + (this.props.className? " " + this.props.className:"")}
            style={this.props.containerStyle}
            href={this.props.href}
            >
                <Media>
                    <Media body >
                        <Media heading>
                            <span 
                            className="cc-header" 
                            style={this.props.headerStyle}
                            >
                                {this.props.header}
                            </span>
                            
                            <p 
                            className="cc-description" 
                            style={this.props.descriptionStyle}
                            >
                                {this.props.description}
                            </p>
                        </Media>
                        <div className="cc-chart">
                            {this.props.children}
                        </div>
                    </Media>
                </Media>
            </Card>
        )
    }
}

ChartCard.propTypes = {
    header: PropTypes.string,
    description: PropTypes.string,
    containerStyle:  PropTypes.object,
    headerStyle:  PropTypes.object,
    descriptionStyle:  PropTypes.object,
} 


export default ChartCard;