import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FIELD_SIZE } from '../Constants';
import { withSMARTWrapper, SelectList, ToggleSwitchBoolean } from '../Forms';
import { DEFAULT_TEXT, DEFAULT_NAMESPACE } from '../Constants/LanguageKeys.js';

class ModalPublishTranslation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            toggleValue: true
        };
    }

    // Fn: Clears modal fields
    clearFieldStates = (props) => {
        
        props.toggleModal();
        props.onChangeField(props.toggleName, true);
        props.onChangeField(props.selectListName, "");
    };

    render() {
        return (
            <div>
                <Modal isOpen={this.props.isOpen} className={"modal-content " + this.props.className} >
                    <ModalHeader>{this.props.contentHeader}</ModalHeader>
                    <ModalBody>
                        {this.props.toggleValue ? 
                            this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_MODAL_TEXT_PUBLISHBYSCOPE, DEFAULT_TEXT.PUBLISH_BY_APP_MODAL_TEXT, true)
                            : 
                            this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_MODAL_TEXT_PUBLISHBYAPP, DEFAULT_TEXT.PUBLISH_BY_SCOPE_MODAL_TEXT, true)
                        }
                        <ToggleSwitchBoolean
                            name={this.props.toggleName}
                            value={this.props.toggleValue}
                            onChangeField={this.props.onChangeField}
                            inputSize={FIELD_SIZE.SMALL}
                            trueText={this.props.toggleTrueText}
                            falseText={this.props.toggleFalseText}               
                            defaultOn={true}
                            label={this.props.toggleLabel}
                        />
                        {this.props.toggleValue && this.props.isScopeLoaded ?
                            <SelectList
                                name={this.props.selectListName}
                                value={this.props.selectListvalue}
                                options={this.props.options}
                                onChangeField={this.props.onChangeField}
                                placeholder={this.props.selectListPlaceholder}
                                isMulti={true}
                                labelSize={FIELD_SIZE.SMALL}
                                inputSize={FIELD_SIZE.XLARGE}
                                isClearable
                                label={this.props.selectListLabel}
                                error={this.props.errorSelect}
                                required
                            />
                            :
                            ""
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button color="backward" size="sm" onClick={() => this.clearFieldStates(this.props)}>{this.props.cancelText}</Button>
                        {this.props.confirmCallback && <Button color="forward" size="sm" onClick={this.props.confirmCallback}>{this.props.confirmText}</Button>}
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

// PropTypes: For prop validation
ModalPublishTranslation.propTypes = {
    selectListName: PropTypes.string.isRequired,
    toggleName: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    contentHeader: PropTypes.string.isRequired,
    confirmText: PropTypes.string,
    cancelText: PropTypes.string
};

// PropTypes: Defaulting value for optional props
ModalPublishTranslation.defaultProps = {
    confirmText: 'Confirm',
    cancelText: 'Cancel'
};


export default withSMARTWrapper(ModalPublishTranslation);