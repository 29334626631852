import history from '../../History';
import { Response } from '../../Constants';
import { CLIENT_URL, REDIRECT_URI } from '../../../Shared/SmartMe/Constants';

export const getRequest = (serverURL) => {
    let getCall = fetch(serverURL)
    return getCall
      .then((response) => {
        if (!response.ok) {
          throw response;
        }
        return response.json();
      })
     .then((result) => {
        return Response(result, true, 200)
     })
     .catch((error) => {
       return Response(error, false, 500)
     });
}

export const processGenders = (response) => {
  var genders = [];
  response.map(x => {
    var obj = {label: x.CodeTitle, value: x.CodeValue} 
    genders.push(obj);
    return obj;
  })
  return genders;
}

export const serverTableNavigateTo = (nextURL, values) => {
  // Ensure scroll to top of page on navigation
  window.scrollTo(0, 0);

  // Perform navigation
  history.push({
    pathname: nextURL + values.Id
  })
};

export const selectListMapper = (itemList, val, lab) => {
  let list = [];
  itemList.map(x =>{
      var obj = {value: x[val], label : x[lab]};
      return list.push(obj);
  })
  return list;
}

export const isValidForm = (validationSchema, values) => {
  return validationSchema.isValidSync(values);
}

export const redirect = (path) => {
  document.cookie = REDIRECT_URI + "=" + CLIENT_URL;
  window.location = path;
}

export const setCookie = (name, value, days) => {
  var d = new Date();
  d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
  document.cookie = name + "=" + value + ",path='/',expires=" + d.toGMTString();
}

export const deleteCookie = (name) => {
  setCookie(name, '', -1);
}