import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { Card, CardBody, Media } from 'reactstrap';


class NumberCard extends Component {

    hasRouter = () => {
        if(this.props.to){
            return (
                <RouterLink to={this.props.to}>
                    {this.content()}
                </RouterLink>
            )
        } else {
            return ( 
                this.content()
            )
        }
    }
    
    content = () =>{
        return ( 
        <CardBody>
            <Media heading>
                <span 
                className="nc-header" 
                style={this.props.headerStyle}
                >
                    {this.props.header}
                </span>
                
                <p 
                className="nc-description" 
                style={this.props.descriptionStyle}
                >
                    {this.props.description}
                </p>
            </Media>
            <span 
            className="nc-number" 
            style={this.props.numberStyle}
            >
                {this.props.number}
            </span>
        </CardBody>
        )
    }
    render() {
        return (
            <Card className={"nc-card" + (this.props.className? " " + this.props.className:"")} 
            style={this.props.containerStyle}
            >
                {this.hasRouter()}
            </Card>
        )
    }
}

NumberCard.propTypes = {
    header: PropTypes.string.isRequired,
    description: PropTypes.string,
    number: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]).isRequired,
    containerStyle:  PropTypes.object,
    headerStyle:  PropTypes.object,
    descriptionStyle:  PropTypes.object,
    numberStyle:  PropTypes.object,
} 


export default NumberCard;