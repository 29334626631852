import React from 'react';
import { DropdownItem } from 'reactstrap';
import './FaqTree.css';

class TreeMenuItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        };
    }

    toggle = () => {
        this.setState({ open: !this.state.open });
    };

    render() {
        const { children, onClick } = this.props;
        return (
            <DropdownItem
                className="tree-menu-item"
                onClick={(e) => { e.stopPropagation(); onClick && onClick(); }}
            >
                {children}
            </DropdownItem>
        );
    }
}

export default TreeMenuItem;