import { Button as AntdButton } from 'antd';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { GET_INTERNAL_FILE_URL, GET_LICENCE_STATUS_DETAILS, GET_LICENCE_STATUS_SUPPORTING_DOCUMENTS, LAYOUT_TYPE, STATUS_TYPES } from '../../App/AppSettings';
import Layout from '../../Layout';
import Section from '../../Section';
import SectionWrapper from '../../SectionWrapper';
import { fetchRequest, getParams } from '../../Shared/Actions';
import { SECTION_WRAPPER_TYPES } from '../../Shared/Constants';
import { ERROR, getLangKey, LANGUAGE_KEYS } from '../../Shared/Constants/LanguageKeys';
import { Plaintext, SMARTView, toastError, withSMARTWrapper } from '../../Shared/Forms';
import history from '../../Shared/History';
import TitleChildren from '../../Title/TitleChildren';
import FileUploadSection from '../FormComponents/FileUploadSection';

const SECTION_LAYOUT_TYPE = SECTION_WRAPPER_TYPES.TAB;

const SECTION_NAMES = {
    STATUS_DETAILS: `Details`,
    SUPPORTING_DOCUMENTS: `Documents`
};

const IS_UPPER = false;

class LicenceStatusDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            supportingDocuments: [],
            statusDetails: {
                ProcessedBy: ''
            },
            supportingDocumentsLoaded: false,
            statusDetailsLoaded: false
        };
    }

    componentDidMount() {
        const { loader } = this.props;

        this.getStatusDetails(loader);
        this.getSupportingDocuments(loader);
    }

    getStatusDetails = async loader => {
        const { t } = this.props;
        const response = await fetchRequest(GET_LICENCE_STATUS_DETAILS + this.props.location.state.StatusId, getParams(), false);
        const { IsSuccess, Data } = response.body;

        if (IsSuccess) {

            this.setState(() => {
                return {
                    statusDetails: Data,
                    statusDetailsLoaded: true
                };
            });
            this.stopLoader(loader);
        } else {
            this.setState({
                statusDetailsLoaded: true
            });
            toastError(t(ERROR.STATUS_DATA_NOT_FETCHED));
            this.stopLoader(loader);
        }
    }

    getSupportingDocuments = async loader => {
        const { t } = this.props;
        const response = await fetchRequest(GET_LICENCE_STATUS_SUPPORTING_DOCUMENTS + this.props.location.state.StatusId, getParams(), false);

        const { IsSuccess, Data } = response.body;

        if (IsSuccess) {

            this.setState(() => {
                return {
                    supportingDocuments: Data,
                    supportingDocumentsLoaded: true
                };
            });
            this.stopLoader(loader);
        } else {
            this.setState({
                supportingDocumentsLoaded: true
            });
            toastError(t(ERROR.SUPPORTING_DOCS_NOT_FETCHED));
            this.stopLoader(loader);
        }
    }

    // Loader done
    stopLoader = async loader => {
        const { statusDetailsLoaded, supportingDocumentsLoaded } = this.state;

        if (statusDetailsLoaded && supportingDocumentsLoaded) {
            loader.done();
        }
    }

    renderTitleChildren = () => {
        const { t } =  this.props;
        return (
            <TitleChildren>
                <Row className="title-custom">
                    <Col className="title-actions">
                        <AntdButton
                            className="btn-backward"
                            onClick={() => history.goBack()}
                        >
                            {t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_NAVIGATION_BUTTON_BACK)}
                        </AntdButton>
                    </Col>
                </Row>
            </TitleChildren>
        );
    }

    render() {
        const { statusDetails, supportingDocuments } = this.state;
        const { t } = this.props;
        return (
            <React.Fragment>
                <SMARTView
                    sectionNames={SECTION_NAMES}
                    defaultSection={SECTION_NAMES.STATUS_DETAILS}
                >
                    {({ sectionState, toggleSection }) => (
                        <Layout type={LAYOUT_TYPE.FORM} title="Licence Status Details" footer={false} titleClassName="sticky-title" titleChildren={this.renderTitleChildren()}>
                            <React.Fragment>
                                <Row className="body-content internal-content">
                                    <Col className="body-section-col">
                                        <SectionWrapper
                                            type={SECTION_LAYOUT_TYPE}
                                            tabs={SECTION_NAMES}
                                            sectionState={sectionState}
                                            toggleSection={toggleSection}
                                        >
                                            <Section></Section>
                                            {/* Status Info */}
                                            <Section
                                                type={LAYOUT_TYPE.FORM_CONTENT}
                                                sectionState={sectionState}
                                                sectionName={SECTION_NAMES.STATUS_DETAILS}
                                                toggleSection={toggleSection}
                                            >

                                                <Plaintext
                                                    label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEDETAILS_TABLE_TITLE_STATUSTYPE)}
                                                    name="StatusType"
                                                    value={t(getLangKey(LANGUAGE_KEYS.BLS_CODE_LICENCESTATUS_TITLE_KEY, statusDetails.StatusCodeName))}
                                                    isUpper={IS_UPPER}
                                                />
                                                {statusDetails.StatusType === STATUS_TYPES.SUSPENDED &&
                                                    <Plaintext
                                                        label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEDETAILS_TABLE_TITLE_ENDDATE)}
                                                        name="EndDate"
                                                        value={statusDetails.EndDate}
                                                        isUpper={IS_UPPER}
                                                    />
                                                }
                                                <Plaintext
                                                    label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEDETAILS_TABLE_TITLE_REMARK)}
                                                    name="Remark"
                                                    value={statusDetails.StatusRemark}
                                                    isUpper={IS_UPPER}
                                                />
                                                <Plaintext
                                                    label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEDETAILS_TABLE_TITLE_PROCESSEDBY)}
                                                    name="ProcessedBy"
                                                    value={statusDetails.ProcessedBy}
                                                    isUpper={IS_UPPER}
                                                />
                                                <Plaintext
                                                    label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEDETAILS_TABLE_TITLE_PROCESSEDDATE)}
                                                    name="ProcessedDate"
                                                    value={statusDetails.ProcessedDate}
                                                    isUpper={IS_UPPER}
                                                />
                                            </Section>

                                            {/* Supporting Docs */}
                                            <Section
                                                type={LAYOUT_TYPE.FORM_CONTENT}
                                                sectionState={sectionState}
                                                sectionName={SECTION_NAMES.SUPPORTING_DOCUMENTS}
                                                toggleSection={toggleSection}
                                            >
                                                <FileUploadSection
                                                    name="SupportingDocuments"
                                                    fileList={supportingDocuments}
                                                    getFileURL={GET_INTERNAL_FILE_URL}
                                                    isUpper={IS_UPPER}
                                                    readonly={true}
                                                />
                                            </Section>
                                        </SectionWrapper>
                                    </Col>
                                </Row>
                            </React.Fragment>
                        </Layout>
                    )}
                </SMARTView>
            </React.Fragment>
        );
    }
}

export default withTranslation()(withSMARTWrapper(LicenceStatusDetails));