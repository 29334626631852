import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
// import { faEdit, faTrash, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import React from 'react';
import Select from "react-select";
import { Button, Col, Row } from 'reactstrap';
// import { DELETE_NOTIFICATION_TEMPLATE_URL, LIST_NOTIFICATION_TEMPLATE_URL, NOTIFICATION_TEMPLATE_SMS_CREATE, NOTIFICATION_TEMPLATE_SMS_VIEW, NOTIFICATION_TEMPLATE_SMS_UPDATE } from '../../App/AppSettings';
import { DELETE_NOTIFICATION_TEMPLATE_URL, LIST_NOTIFICATION_TEMPLATE_URL, NOTIFICATION_TEMPLATE_SMS_CREATE, NOTIFICATION_TEMPLATE_SMS_UPDATE } from '../../App/AppSettings';
import { ERROR, SUCCESS } from '../../Localization/index';
import { deleteParams, fetchRequest, getParams, navigateTo } from '../../Shared/Actions';
import { FILTER, SELECT_FILTERS } from '../../Shared/Constants';
import { ActionButton, DataTable, MobileCardActionButton } from '../../Shared/DataTable';
import { getCustomComponents } from '../../Shared/DataTable/BaseTable.js';
import { withSMARTWrapper } from '../../Shared/Forms';
import { DEFAULT_TEXT, DEFAULT_NAMESPACE } from '../../Shared/Constants/LanguageKeys.js';
import { toastError, toastSuccess } from '../../Shared/Forms/Toaster';
import { ModalConfirm } from '../../Shared/Modal';
import Title from '../../Title';

const TYPE = "SMS";

const DELETE_MODAL = {
    hasModal: true,
    name: "Delete"
};

class SmsTemplateList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            collapse: false,
            count: 0,
            modalState: undefined,
            searchFilter: "",
            categoryFilter: ""
        };
    }

    componentDidMount = async () => {
        this.loadGrid();
    }

    // Load Datatable with list of SMS templates
    loadGrid = async () => {
        const { loader } = this.props;
        const params = {
            Type: TYPE
        }

        loader.start();
        const response = await fetchRequest(LIST_NOTIFICATION_TEMPLATE_URL, getParams(params));
        loader.done();

        const { Data, IsSuccess } = response.body;

        if (response.success && IsSuccess) {
            this.setState({
                success: response.success,
                data: Data,
                categoryOptions: this.getCategoryOptions(Data),
                count: ((Data !== undefined) ? Data.length : 0)
            });
        }
    }

    onFilterChange = (filterName, value, setFilter) => {
        this.setState({
            [filterName]: value
        }, () => {
            this.filter(setFilter);
        });
    }

    filter = (setFilter) => {
        const filterFields = {
            [FILTER]: this.state.searchFilter,
            [SELECT_FILTERS]: {
                'Category': this.state.categoryFilter
            }
        };
        setFilter(filterFields);
    }

    Filter = (props) => {
        let categoryOptions = this.state.categoryOptions;
        let categoryValue = this.state.categoryFilter;
        categoryValue = categoryValue === "" ? categoryOptions[0] :
            _.find(categoryOptions, { 'value': categoryValue });

        return (
            <React.Fragment>
                <Select
                    defaultValue={categoryValue}
                    placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_NOTITEMPLATESTATIC_CONTENT_DROPDOWN_ALLCATEGORIES, DEFAULT_TEXT.ALL_CATEGORIES)}
                    onChange={(selectedOption) => this.onFilterChange("categoryFilter", selectedOption.value, props.setFilter)}
                    options={categoryOptions}
                    maxMenuHeight={150}
                    menuPlacement="auto"
                    styles={{
                        menuPortal: styles => ({ ...styles, zIndex: 100 }) //  >= dialog's z-index
                    }}
                    className="select-filter select-list" //  class for css
                />
                <input
                    type="text"
                    name="filter"
                    defaultValue={this.state.searchFilter}
                    placeholder="Filter"
                    className="custom-griddle-filter griddle-filter"
                    onChange={e => this.onFilterChange("searchFilter", e.target.value, props.setFilter)}
                />
            </React.Fragment>
        );
    };

    getCategoryOptions = (Data) => {
        let allCategoriesOption = [{ 
            label: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_NOTITEMPLATESTATIC_CONTENT_DROPDOWN_ALLCATEGORIES, DEFAULT_TEXT.ALL_CATEGORIES), 
            value: "" }];

        // Existing Category Options
        let categoryOptions = [];
        _.forEach(Data, function (smsTemplate) {
            if (smsTemplate.Category !== null) {
                let val = smsTemplate.Category;
                categoryOptions.push({ 'label': val.toString(), 'value': val });
            }
        });

        // Sort Unique Category Options
        let otherCategoryOptions = _.sortBy(_.uniqBy(categoryOptions, 'label'), ['label']);
        categoryOptions = _.concat(allCategoriesOption, otherCategoryOptions);

        return categoryOptions;
    }

    toggle = () => {
        this.setState({ collapse: !this.state.collapse });
    }

    toggleModal = (modalName, ID) => {
        this.setState({
            modalState: (this.state.modalState !== modalName) ? modalName : undefined,
            modalID: ID
        })
    };

    deleteSmsTemplate = async () => {
        this.toggleModal(DELETE_MODAL.name); 

        const { loader } = this.props;
        // Set URL
        const DELETE_URL = (DELETE_NOTIFICATION_TEMPLATE_URL + this.state.modalID);
        // Start loading
        loader.start();

        // Get results of server-side form posting
        const response = await fetchRequest(DELETE_URL, deleteParams(), false);
        if (response.success) {
            const { IsSuccess, Message, MessageLanguageKey } = response.body;
            if (IsSuccess) {
                // Reload Grid Data after deleting form
                await this.loadGrid();

                toastSuccess(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_NOTITEMPLATESTATIC_SUCCESS_TITLE_DELETESMSTEMPLATE, SUCCESS.DELETE_SUCCESS), DEFAULT_TEXT.SMS_TEMPLATE);
                // End loading
                loader.done();
            }
            else {
                // Error message to be loaded here
                loader.error(this.props.getMessage(MessageLanguageKey, Message));
            }
        }
        else {
            toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
        }
    }

    renderMobileCardActions = (RowData) => {
        return (
            <React.Fragment>
                <MobileCardActionButton
                    onClick={() => navigateTo(NOTIFICATION_TEMPLATE_SMS_UPDATE, { ID: RowData.ID })}
                    icon={faEdit}
                    label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_NOTITEMPLATESTATIC_NAVIGATION_BUTTON_UPDATE, DEFAULT_TEXT.UPDATE)}
                />
                <MobileCardActionButton
                    onClick={() => this.toggleModal(DELETE_MODAL.name, RowData.TemplateName)}
                    icon={faTrash}
                    label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_NOTITEMPLATESTATIC_NAVIGATION_BUTTON_DELETE, DEFAULT_TEXT.DELETE)}
                />
            </React.Fragment>
        )
    }

    getMobileCardSelectFilterValues = () => {
        let categoryOptions = this.state.categoryOptions;
        let selectFilterValues = [
            {
                name: "Category",
                placeholder: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_NOTITEMPLATESTATIC_CONTENT_DROPDOWN_ALLCATEGORIES, DEFAULT_TEXT.ALL_CATEGORIES),
                options: categoryOptions
            }
        ]
        return selectFilterValues;
    }

    getCustomComponents = () => {
        let customComponents = getCustomComponents();
        customComponents.Filter = this.Filter;

        return customComponents;
    }

    setFilterText = (filterText) => {
        this.setState({
            categoryFilter: filterText[SELECT_FILTERS]['Category'],
            searchFilter: filterText[FILTER]
        })
    }

    renderLayout = () => {
        if (this.state.success) {
            return (
                <React.Fragment>
                    <Col className='text-right remove-padding'>
                        <Button color="neutral" onClick={() => navigateTo(NOTIFICATION_TEMPLATE_SMS_CREATE)}>
                            {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_NOTITEMPLATESTATIC_NAVIGATION_BUTTON_NEWSMSTEMPLATE, DEFAULT_TEXT.NEW_SMS_TEMPLATE)}
                        </Button>
                    </Col>
                    <div className="smart-list">
                        <DataTable
                            pageSize="5"
                            isCustomFilter={true}
                            setFilterText={this.setFilterText}
                            minFilterChars='2'
                            data={this.state.data}
                            noResultsMessage={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_NOTITEMPLATESTATIC_TABLE_TEXT_SMSTEMPLATENORECORD, DEFAULT_TEXT.SMSTEMPLATE_NO_RECORD)}
                            components={this.getCustomComponents()}
                            columns={
                                {
                                    RunningNumber: { title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_NOTITEMPLATESTATIC_CONTENT_TABLE_NO, DEFAULT_TEXT.NUM), width: '5%' },
                                    Actions: { title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_NOTITEMPLATESTATIC_CONTENT_TABLE_ACTIONS, DEFAULT_TEXT.ACTIONS), width: '10%', DBkey: 'Action' },
                                    TemplateName: { title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_NOTITEMPLATESTATIC_CONTENT_TABLE_TEMPLATENAME, DEFAULT_TEXT.TEMPLATE_NAME), width: '40%', DBkey: 'TemplateName' },
                                    Category: { title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_NOTITEMPLATESTATIC_CONTENT_TABLE_CATEGORY, DEFAULT_TEXT.CATEGORY), width: '20%', DBkey: 'Category' },
                                    Version: { title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_NOTITEMPLATESTATIC_CONTENT_TABLE_VERSION, DEFAULT_TEXT.VERSION), width: '10%', DBkey: 'Version' },
                                    UpdatedDate: { title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_NOTITEMPLATESTATIC_CONTENT_TABLE_UPDATEDDATE, DEFAULT_TEXT.UPDATED_DATE), width: '15%', DBkey: 'LastUpdatedDate', isDate: true }
                                }
                            }
                            renderActions={({ RowData }) => (
                                <React.Fragment>
                                    <ActionButton 
                                        tooltip={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_NOTITEMPLATESTATIC_TABLE_TOOLTIP_EDIT, DEFAULT_TEXT.EDIT)}
                                        color="forward" 
                                        onClick={() => navigateTo(NOTIFICATION_TEMPLATE_SMS_UPDATE, { ID: RowData.ID })}>
                                        <FontAwesomeIcon icon={faEdit} />
                                    </ActionButton>

                                    {/* <ActionButton 
                                        tooltip={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_NOTITEMPLATESTATIC_TABLE_TOOLTIP_VIEW, DEFAULT_TEXT.VIEW)}
                                        color="neutral" 
                                        onClick={() => navigateTo(NOTIFICATION_TEMPLATE_SMS_VIEW, { ID: RowData.ID })}>
                                        <FontAwesomeIcon icon={faEye} />
                                    </ActionButton> */}

                                    <ActionButton 
                                        tooltip={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_NOTITEMPLATESTATIC_TABLE_TOOLTIP_DELETE, DEFAULT_TEXT.DELETE)}
                                        color="hazard" 
                                        onClick={() => this.toggleModal(DELETE_MODAL.name, RowData.TemplateName)}>
                                        <FontAwesomeIcon fixedWidth icon={faTrash} />
                                    </ActionButton>
                                </React.Fragment>
                            )}
                            getMobileCardSelectFilterValues={this.getMobileCardSelectFilterValues}
                            renderMobileCardActions={this.renderMobileCardActions}
                        />
                        <ModalConfirm
                            isOpen={(this.state.modalState === DELETE_MODAL.name)}
                            contentHeader={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_NOTITEMPLATESTATIC_MODAL_TITLE_DELETESMSTEMPLATE, DEFAULT_TEXT.DELETE_SMSTEMP_MODAL_TITLE)}
                            contentBody={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_NOTITEMPLATESTATIC_MODAL_TEXT_DELETESMSTEMPLATE, DEFAULT_TEXT.DELETE_SMSTEMP_MODAL_TEXT, true)}
                            confirmText={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_NOTITEMPLATESTATIC_NAVIGATION_BUTTON_CONFIRM, DEFAULT_TEXT.CONFIRM)}
                            confirmCallback={() => { this.deleteSmsTemplate(); }}
                            cancelText={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_NOTITEMPLATESTATIC_NAVIGATION_BUTTON_CANCEL, DEFAULT_TEXT.CANCEL)}
                            cancelCallback={() => this.toggleModal(DELETE_MODAL.name)}
                        />
                    </div>
                </React.Fragment>
            );
        } else {
            return null;
        }
    }

    render() {
        return (
            <React.Fragment>
                <Title className="first body-title body-title-text" titleValue=
                    {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_NOTITEMPLATESTATIC_CONTENT_TITLE_SMSTEMPLATEMANAGEMENT, DEFAULT_TEXT.SMS_TEMPLATE_MANAGEMENT)} />
                <div className="grid-container">
                    <Row className="body-content admin-content-wrapper">
                        <Col>
                            {this.renderLayout()}
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}

export default withSMARTWrapper(SmsTemplateList);