import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from 'reactstrap';
import { getLangKey, LANGUAGE_KEYS, SCOPE_CAT_SUBCAT_STRINGS, ERROR } from '../../../Shared/Constants/LanguageKeys';
import { ActionButton, DataTable } from '../../../Shared/DataTable';
import { getCustomComponents } from '../../../Shared/DataTable/BaseTable.js';
import { AccordionContainer, Plaintext } from '../../../Shared/Forms';
import { ModalConfirm } from '../../../Shared/Modal';
import { getLicenceTypeNameTranslationKey } from '../../DisplayComponents/DisplayUtils';
import { getParams, useAsyncFetch } from '../../../Shared/Fetch';
import { GET_WORKFLOW_STATE_LIST, GET_CODES_BY_CODE_CATEGORIES } from '../../../App/AppSettings';
import { toastError } from '../../../Shared/Forms/Toaster.js';
import i18next from 'i18next';

const LICENCE_PAYMENT_CODE = "LicenceApplicationFeeType";

const PAYMENT_TYPES = [
    { label: "Upfront", value: "Upfront" },
    { label: "Additional", value: "Additional" }
]

export default function LicenceAppConfigPayment(props) {
    const { t } = useTranslation();
    const { values, toggleSection } = props.smartFormValues;
    const [selectedPaymentValues, setSelectedPaymentValues] = useState('');
    const [modalState, setModalState] = useState(null);
    const [ workflowStates, setWorkflowStates ] = useState([]);
    //get list of workflow states and keep in state 
    const statesResponse = useAsyncFetch(GET_WORKFLOW_STATE_LIST, getParams());
    const paymentTypeCat = SCOPE_CAT_SUBCAT_STRINGS.LICENCE_APP_FEE_TYPE;
    const [paymentTypes, setPaymentTypes] = useState(PAYMENT_TYPES);
    
    const params = {
        categoryList: LICENCE_PAYMENT_CODE
    }
    const paymentResponse = useAsyncFetch(GET_CODES_BY_CODE_CATEGORIES, getParams(params));

    useEffect(()=>{
        async function setStateData() {
            const { IsSuccess, Messages, Data } = statesResponse.body;

            if (statesResponse.success && IsSuccess) {
                const states = (Data ? Data : []);
                setWorkflowStates(states);
            }
            else {
                // Error message to be loaded here
                toastError(t(getLangKey(ERROR.BACKEND_ERROR_MESSAGE, Messages)), Messages);
            }
        }
        if(statesResponse) {
            setStateData();
        }
            
        if (paymentResponse) {
            const fetchPaymentTypeListResponse = async (response) => {
                const { Data, IsSuccess } = response.body;
                if (response.success && IsSuccess) {
                    let paymentTypeOptions = [];
                    Data.forEach(element => {
                        paymentTypeOptions.push({ label: t(getLangKey(LANGUAGE_KEYS.BLS_CODE_CATEGORY_TITLE_KEY, element.CodeCategory, element.CodeName)), value: element.CodeTitle });
                    });
                    setPaymentTypes(paymentTypeOptions);
                }
            };
    
            fetchPaymentTypeListResponse(paymentResponse);
        }
    },[statesResponse, paymentResponse, t]);

    const SECTION_NAMES = {
        PAYMENT: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_SECTION_PAYMENT), status: true }
    };

    const VIEW_PAYMENT_ITEM_MODAL = {
        hasModal: true,
        name: 'ViewPaymentItem',
        modalHeader: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_MODAL_VIEWPAYMENTITEM)
    };

    const viewPaymentItem = (data) => {
        data.IsIncludeTax = data.IsIncludeTax ? t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_YES) : t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_NO);
        setSelectedPaymentValues(data);
        toggleModal(VIEW_PAYMENT_ITEM_MODAL.name);
    };

    const toggleModal = (modalName) => {
        setModalState((modalState !== modalName) ? modalName : null);
    };

    const setIncludeTaxContent = (rowData) => {
        return (
            rowData.IsIncludeTax === true ? t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_YES) : t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_NO)
        );
    };

    const getIncludeTaxContent = (rowData) => {
        return (
            rowData.IsIncludeTax === "true" ? t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_YES) : t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_NO)
        )
    }

    const setPaymentDesc = rowData => {
        const licenceTypeNameTranslationKey = getLicenceTypeNameTranslationKey(values.LicenceTypeName);
        return t(getLangKey(LANGUAGE_KEYS.BLS_LICENCE_LICENCEAPPLICATIONFEE_APPLICATIONFEE_KEY, licenceTypeNameTranslationKey, rowData.Id));
    };

    const getPaymentDescTranslatedString = rowData => {
        const licenceTypeNameTranslationKey = getLicenceTypeNameTranslationKey(values.LicenceTypeName);
        return t(getLangKey(LANGUAGE_KEYS.BLS_LICENCE_LICENCEAPPLICATIONFEE_APPLICATIONFEE_KEY, licenceTypeNameTranslationKey, rowData.Id));
    };

    const setPaymentType = (rowData) => {
        if (i18next.exists(getLangKey(LANGUAGE_KEYS.BLS_CODE_CATEGORY_TITLE_KEY, paymentTypeCat, rowData.PaymentTypeCodeName))) {
            return t(getLangKey(LANGUAGE_KEYS.BLS_CODE_CATEGORY_TITLE_KEY, paymentTypeCat, rowData.PaymentTypeCodeName));
        } else {
            return paymentTypes.find(pt => pt.value === rowData.PaymentType).label;
        }
    };
    const getPaymentTypeTranslatedString = (rowData) => t(getLangKey(LANGUAGE_KEYS.BLS_CODE_CATEGORY_TITLE_KEY, SCOPE_CAT_SUBCAT_STRINGS.LICENCE_APP_FEE_TYPE, rowData.PaymentType));
    //todo: this is a quick fix to display workflow state label. No multilingual.
    //future may change to Fusion instead smart work
    const setPaymentWorkflowSate = (rowData) => {
        if(rowData.PaymentWorkflowState !== '' && rowData.PaymentWorkflowState.trim() !== '') {
            const states = workflowStates.filter(el => el.value === rowData.PaymentWorkflowState.trim());
            if(states.length > 0) 
                return states[0].label;
            else
                return '';
        }
        return '';
    }
    const renderPaymentGrid = (values) => {
        return (
            <React.Fragment>
                <div className="smart-list">
                    <DataTable
                        
                        pageSize="5"
                        minFilterChars='2'
                        data={values.PaymentData}
                        noResultsMessage={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_MESSAGE_PLACEHOLDER_NOPAYMENTITEM)}
                        components={getCustomComponents()}
                        columns={
                            {
                                RunningNumber: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_NUMBER), width: '5%' },
                                PaymentDescription: {
                                    title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_PAYMENTITEMDESC),
                                    width: '30%',
                                    DBkey: 'PaymentDescription',
                                    setContent: setPaymentDesc,
                                    getTraslatedString: getPaymentDescTranslatedString
                                },
                                PaymentType: {
                                    title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_PAYMENTTYPE),
                                    width: '20%',
                                    DBkey: 'PaymentType',
                                    setContent: setPaymentType,
                                    getTraslatedString: getPaymentTypeTranslatedString
                                },
                                PaymentAmount: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_PAYMENTAMOUNT), width: '15%', DBkey: 'PaymentAmount' },
                                IsIncludeTax: {
                                    title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_INCLUDETAX),
                                    width: '10%',
                                    DBkey: 'IsIncludeTax',
                                    setContent: setIncludeTaxContent,
                                    getTranslatedString: getIncludeTaxContent
                                },
                                PaymentWorkflowState: {
                                    title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_WORKFLOWSTATE),
                                    width: '10%',
                                    DBkey: 'PaymentWorkflowState',
                                    setContent: setPaymentWorkflowSate
                                },
                                Actions: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_ACTION), width: '10%' }
                            }
                        }
                        renderActions={({ RowData }) => (
                            <React.Fragment>
                                <ActionButton tooltip={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_VIEW)} color="forward" onClick={() => viewPaymentItem(RowData)}>
                                    <FontAwesomeIcon icon={faEye} />
                                </ActionButton>
                            </React.Fragment>
                        )}
                    />
                </div>
                <ModalConfirm
                    isOpen={(modalState === VIEW_PAYMENT_ITEM_MODAL.name)}
                    contentHeader={VIEW_PAYMENT_ITEM_MODAL.modalHeader}
                    cancelText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_CANCEL)}
                    cancelCallback={() => toggleModal(VIEW_PAYMENT_ITEM_MODAL.name)}
                    contentBody={
                        <div>
                            <Plaintext
                                name="PaymentDescription"
                                value={selectedPaymentValues.PaymentDescription}
                                label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_PAYMENTDESCRIPTION)}
                            />
                            <Plaintext
                                name="PaymentType"
                                value={selectedPaymentValues.PaymentType}
                                label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_PAYMENTTYPE)}
                            />
                            <Plaintext
                                name="PaymentAmount"
                                value={selectedPaymentValues.PaymentAmount}
                                label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_PAYMENTAMOUNT)}
                            />
                            <Plaintext
                                name="IsIncludeTax"
                                value={selectedPaymentValues.IsIncludeTax}
                                label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_INCLUDETAX)}
                            />
                            <Plaintext
                                name="PaymentWorkflowState"
                                value={selectedPaymentValues.PaymentWorkflowState}
                                label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_WORKFLOWSTATE)}
                            />
                        </div>
                    }
                />
            </React.Fragment >
        );
    };

    return (
        <React.Fragment>
            <Container className="full-width">
                <AccordionContainer
                    title={SECTION_NAMES.PAYMENT.title}
                    active={SECTION_NAMES.PAYMENT.status}
                    onClick={toggleSection}
                    isIndividual={true}
                    sections={SECTION_NAMES}
                >
                    {workflowStates.length > 0 ? renderPaymentGrid(values) : <></>}
                </AccordionContainer>
            </Container>
        </React.Fragment>
    );
}
