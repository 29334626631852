import React from 'react';
import { COPYRIGHT_TEXT } from '../App/AppSettings';

class Footer extends React.Component {

    render() {
        return (
            <div className={"navbar-footer"}>
                {this.props.footerExtension}
                
                <div className="footer-copyright">{ COPYRIGHT_TEXT }</div>
            </div>
        );
    }
}

export default Footer;