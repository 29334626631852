import { } from 'antd';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import {
    FORM_ENGINE_DOWNLOAD_EXCEL_FILE_URL, FORM_ENGINE_EXCEL_URL,
    FORM_ENGINE_GENERATE_USER_TOKEN, FORM_ENGINE_RETRIEVE_EXCEL_TEMPLATE_URL, GET_CODES_BY_CODE_CATEGORIES,
    LICENCE_APP_CONFIG_GET_WF_SCHEME_LIST, LICENCE_CONFIG_ACTIVE_LICENCE_TYPE_BY_AGENCY_LIST,
    FORMIO_URL_GET_ALL_FORMS, FORMIO_FORM_TYPE, FORMIO_URL, GET_SUITECRM_MODULES, GET_SUITECRM_FIELDS, PATH
} from '../../../App/AppSettings';
import { fetchRequest, getFileRequest, getParams, postParams, navigateTo } from '../../../Shared/Actions';
import { AuthConsumer } from '../../../Shared/Authentication/AuthContext';
import { FIELD_SIZE } from '../../../Shared/Constants';
import { useAsyncFetch } from '../../../Shared/Fetch';
import { AccordionContainer, FileUpload, Radio, SelectList } from '../../../Shared/Forms';
import { convertToFormData } from '../../../Shared/Forms/FormUtils';
import { toastError, toastSuccess } from '../../../Shared/Forms/Toaster.js';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { getLicenceTypeNameTranslationKey, getLangKey } from '../../DisplayComponents/DisplayUtils';
import { LANGUAGE_KEYS, ERROR, SUCCESS } from '../../../Shared/Constants/LanguageKeys';
import LACSupportingDocument from './LACSupportingDocument';
import LACDeclaration from './LACDeclaration';
import utils from 'formiojs/utils';
import CRMMapping from './CRMMapping';

const LICENCE_APPLICATION_TYPE_CODE = 'LicenceApplicationType';

const DUPLICATE_DOC_ERROR_MSG = 'There is already a selected document with the same name. Please select a document with a different name.';

export default function LicenceAppConfigApplication(props) {
    const { t } = useTranslation();

    const { values, errors, onChange, onChangeField, toggleSection } = props.smartFormValues;
    const { isLicenceNameReadOnly, isApplicationTypeReadOnly } = props;
    const [internalWorkflowSchemeList, setInternalWorkflowSchemeList] = useState([]);
    const [publicWorkflowSchemeList, setPublicWorkflowSchemeList] = useState([]);
    const [applicationTypeList, setApplicationTypeList] = useState([]);
    const licenceAppParams = { categoryList: LICENCE_APPLICATION_TYPE_CODE };
    const [isDeclarationReadyClick, setDeclarationReadyClick] = useState(false);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [licenceName, setLicenceName] = useState('');
    const [formIOFormFieldList, setFormIOFormFieldList] = useState([]);
    const [CRMModuleNameList, setCRMModuleNameList] = useState([
        { label: "Module 1", value: "module1" },
        { label: "Module 2", value: "module2" },
        { label: "Module 3", value: "module3" }
    ]);
    const [CRMFieldList, setCRMFieldList] = useState([]);
    const CRMFieldListExample = [
        { module: "module1", field: "name" },
        { module: "module1", field: "age" },
        { module: "module1", field: "gender" },
        { module: "module2", field: "location" },
        { module: "module2", field: "job" },
        { module: "module3", field: "title" }
    ];

    const SECTION_NAMES = {
        APPLICATION_DETAILS: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_SECTION_APPLICATIONDETAILS), status: true },
        DECLARATION: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_SECTION_DECLARATION), status: true },
        SUPPORTING_DOCUMENT: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_SECTION_SUPPORTINGDOCUMENT), status: true },
        APPLICATION_FIELDS: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_SECTION_APPLICATIONFIELDS), status: true },
        ADDITIONAL_APPLICATION_FORMS: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_SECTION_ADDITIONALAPPLICATIONFORMS), status: true },
        CRMM_MAPPING: { title: "SuiteCRM Mapping", status: true }
    };

    const DELETE_MODAL = {
        hasModal: true,
        name: 'Delete',
        modalHeader: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_MODAL_DELETE),
        modalContent: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_MESSAGE_MODAL_DELETERECORD)
    };

    const getFileTemplate = async profile => {
        const userDetails = {
            'userName': `${profile.given_name} ${(profile.middle_name ? `${profile.middle_name} ` : '')}${profile.family_name}}`,
            'userEmail': profile.email,
            'userGuid': profile.userguid
        };
        const response = await fetchRequest(FORM_ENGINE_GENERATE_USER_TOKEN, postParams('', userDetails), false);

        if (response && response.success) {

            if (response.body.IsSuccess === true) {
                getFileRequest(FORM_ENGINE_RETRIEVE_EXCEL_TEMPLATE_URL, false, 'ExcelFileTemplate.xlsm', getParams());
                toastSuccess(t(getLangKey(SUCCESS.BACKEND_SUCCESS_MESSAGE, response.body.Messages)));
            } else {
                toastError(t(getLangKey(ERROR.BACKEND_ERROR_MESSAGE, response.body.Messages)), response.body.Messages);
            }
        } else {
            toastError(t(ERROR.SERVER_UNREACHABLE));
        }
    };

    //Retrieve Licence Name
    const retrieveLicenceNameResponse = useAsyncFetch(LICENCE_CONFIG_ACTIVE_LICENCE_TYPE_BY_AGENCY_LIST, getParams());
    //Retrieve Declarations
    const resetDeclarationReadyFunc = () => {
        setDeclarationReadyClick(false);
    };

    const applicationTypeResponse = useAsyncFetch(GET_CODES_BY_CODE_CATEGORIES, getParams(licenceAppParams));
    // The workflow scheme list for public and internal are the same(it's obtained from the same source)
    const workflowResponse = useAsyncFetch(LICENCE_APP_CONFIG_GET_WF_SCHEME_LIST, getParams());

    const crmModuleResponse = useAsyncFetch(GET_SUITECRM_MODULES, getParams());

    // Retrieve list of forms from Form.IO
    const [formIOData, setFormIOData] = useState([]);

    useEffect(() => {
        var request = new Request(FORMIO_URL_GET_ALL_FORMS, {
            method: 'GET',
            headers: new Headers({
                'Range': 'items=0-'
            })
        });
        fetch(request).then((response) => response.json())
            .then((responseJson) => {
                setFormIOData(responseJson);
            })
    }, []);

    const formIOFormOptions = (formIOData !== undefined) ?
        formIOData.filter(form => form.type === FORMIO_FORM_TYPE).map(form => ({
            label: form.title,
            value: FORMIO_URL + form.path
        })) : [];

    const formIOChangeHandler = (value) => {
        const selectedForm = formIOData.filter(form => form.path === value.split('/').pop());
        if (selectedForm && selectedForm[0] && selectedForm[0].components) {
            const flattened = utils.flattenComponents(selectedForm[0].components, true);
            const flattenedArray = Object.entries(flattened);
            const formFields = flattenedArray.map(([key, value]) => ({
                label: value.label ? value.label : value.title,
                value: key.split('.').at(-1) // special handling for datagrid as flattenArray will concat the section key to the field key
            }));
            const translationValuesArray = flattenedArray.map(([key, value]) => ({
                TranslationValue: value.label ? value.label : value.title,
                TranslationKey: key.split('.').at(-1),
                LanguageKey: i18n.language
            }));
            values.ApplicationData.FormIOTranslationKey = translationValuesArray;
            setFormIOFormFieldList(formFields);
        }
    }

    // Retrieve list of CRM fields based on selected module
    const crmModuleChangeHandler = async (value) => {
        const selectedCRMField = CRMFieldListExample.filter(form => form.module === value).map(form => ({
            label: form.field,
            value: form.field
        }));
        setCRMFieldList(selectedCRMField);
        // const response = await fetchRequest(GET_SUITECRM_FIELDS + '/'+ value, getParams());
        // if (response.success === true) {
        //     const fieldsList = [];
        //     response.body.forEach(element => {
        //         fieldsList.push({ label: element.DisplayName, value: element.Value });
        //     });
        //     setCRMFieldList(fieldsList)
        // }
    }


    //Set relevant data from props
    useEffect(() => {
        setDeclarationReadyClick(values.ApplicationData.LicenceTypeId > 0);
    }, [
        values.ApplicationData.AdditionalApplicationFieldsData,
        values.ApplicationData.LicenceTypeId
    ]);

    const fetchWorkflowResponse = async (response) => {
        const { Data, IsSuccess } = response.body;
        if (response.success && IsSuccess) {
            const workflowSchemeOptions = [];
            Data.forEach(element => {
                workflowSchemeOptions.push({ label: element.WorkflowScheme, value: element.WorkflowScheme });
            });
            setInternalWorkflowSchemeList(workflowSchemeOptions);
            setPublicWorkflowSchemeList(workflowSchemeOptions);
        }
    };

    //Fetch select list options
    useEffect(() => {
        if (workflowResponse) {
            fetchWorkflowResponse(workflowResponse);
        }
        if (applicationTypeResponse) {
            const fetchApplicationTypeListResponse = async response => {
                const { Data, IsSuccess } = response.body;
                if (response.success && IsSuccess) {
                    const applicationTypeList = [];
                    Data.forEach(element => {
                        applicationTypeList.push({ label: t(getLangKey(LANGUAGE_KEYS.BLS_CODE_LICENCEAPPLICATIONTYPE_TITLE_KEY, element.CodeName)), value: element.ID });
                    });
                    setApplicationTypeList(applicationTypeList);
                }
            };
            fetchApplicationTypeListResponse(applicationTypeResponse);
        }
        if (retrieveLicenceNameResponse) {
            const fetchLicenceNameResponse = async (response) => {
                const { Data, IsSuccess } = response.body;
                if (response.success && IsSuccess) {
                    const categoryOptions = [];
                    Data.forEach(e => {
                        const licenceTypeNameTranslationKey = getLicenceTypeNameTranslationKey(e.LicenceName);
                        const licenceOptionName = t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY, licenceTypeNameTranslationKey, e.LicenceTypeId));
                        categoryOptions.push({ label: licenceOptionName, value: e.LicenceTypeId, licenceName: e.LicenceName });
                    });
                    setCategoryOptions(categoryOptions);
                }
            };
            fetchLicenceNameResponse(retrieveLicenceNameResponse);
        }
        // if (crmModuleResponse) {
        //     const fetchCrmModuleResponse = async response => {
        //         if (response.success) {
        //             const moduleList = [];
        //             response.body.forEach(element => {
        //                 moduleList.push({ label: element, value: element });
        //             });
        //             setCRMModuleNameList(moduleList);
        //         }
        //     };
        //     fetchCrmModuleResponse(crmModuleResponse);
        // }
    }, [workflowResponse, applicationTypeResponse, retrieveLicenceNameResponse, t, licenceName, crmModuleResponse]);

    const fetchDeclarationClickFn = () => {
        setDeclarationReadyClick(true);
    };
    const setLicenceTypeName = (value) => {
        const id = categoryOptions.findIndex(o => o.value === value);
        const originalLicenceName = categoryOptions[id] ? categoryOptions[id].licenceName : null;
        setLicenceName(originalLicenceName);
    }
    const removeChosenDeclarations = () => {
        onChangeField('ApplicationData.DeclarationData', []);
    };
    const previewForm = async values => {

        if ((values.ApplicationData.FormEngineExcel !== undefined && values.ApplicationData.FormEngineExcel !== '') ||
            (values.ApplicationData.IsUsingFormIO === 'true' && values.ApplicationData.FormIOName !== undefined)) {
            const data = convertToFormData(values);
            const response = await fetchRequest(FORM_ENGINE_EXCEL_URL, postParams(data), false);

            if (response.success) {
                const { IsSuccess, Data } = response.body;
                if (IsSuccess) {
                    window.open(Data, '_blank');
                } else {
                    toastError(t(ERROR.FILE_PROCESS_FAIL));
                }
            } else {
                toastError(t(ERROR.SERVER_UNREACHABLE));
            }
        }
    };

    return (
        <React.Fragment>
            <Container className="full-width">
                <AccordionContainer
                    title={SECTION_NAMES.APPLICATION_DETAILS.title}
                    active={SECTION_NAMES.APPLICATION_DETAILS.status}
                    onClick={toggleSection}
                    isIndividual={true}
                    sections={SECTION_NAMES}
                >
                    <SelectList
                        name="ApplicationData.LicenceTypeId"
                        value={values.ApplicationData.LicenceTypeId}
                        onChangeField={(name, value) => {
                            onChangeField(name, value);
                            setLicenceTypeName(value);
                            // get declarations
                            if (values.ApplicationData.LicenceTypeId !== value) {
                                fetchDeclarationClickFn();
                                removeChosenDeclarations();
                            }
                        }}
                        options={categoryOptions}
                        inputSize={FIELD_SIZE.MEDIUM}
                        maxMenuHeight={140}
                        label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_SELECTSERVICE)}
                        placeholder={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_PLACEHOLDER_SELECTSERVICE)}
                        isMulti={false}
                        error={errors.ApplicationData && errors.ApplicationData.LicenceTypeId}
                        isDisabled={isLicenceNameReadOnly}
                        required
                    />
                    <SelectList
                        name="ApplicationData.ApplicationTypeId"
                        value={values.ApplicationData.ApplicationTypeId}
                        placeholder={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_PLACEHOLDER_APPLICATIONTYPE)}
                        minLength={0}
                        maxLength={140}
                        inputSize={FIELD_SIZE.MEDIUM}
                        label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_APPLICATIONTYPE)}
                        required
                        options={applicationTypeList}
                        onChangeField={onChangeField}
                        isMulti={false}
                        isDisabled={isApplicationTypeReadOnly}
                        error={errors.ApplicationData && errors.ApplicationData.ApplicationTypeId}
                    />
                    <SelectList
                        name="ApplicationData.InternalWorkflowScheme"
                        value={values.ApplicationData.InternalWorkflowScheme}
                        placeholder={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_PLACEHOLDER_INTERNALWORKFLOWSCHEMENAME)}
                        minLength={0}
                        maxLength={140}
                        inputSize={FIELD_SIZE.MEDIUM}
                        label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_INTERNALWORKFLOWSCHEMENAME)}
                        required
                        isClearable={true}
                        options={internalWorkflowSchemeList.filter(w => w.label !== values.ApplicationData.PublicWorkflowScheme)}
                        onChangeField={onChangeField}
                        isMulti={false}
                        error={errors.ApplicationData && errors.ApplicationData.InternalWorkflowScheme}
                    />
                    <Radio
                        name="ApplicationData.IsFormDataNeededByInternalWorkflowScheme"
                        value={values.ApplicationData.IsFormDataNeededByInternalWorkflowScheme}
                        inputSize={FIELD_SIZE.NONE}
                        label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_FORMDATANEEDEDBYINTERNALWORKFLOWSCHEME)}
                        options={[
                            { label: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_YES), value: 'true' },
                            { label: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_NO), value: 'false' }]}
                        onChange={e => {
                            onChange(e);
                        }}
                    //error={errors.ApplicationData && errors.ApplicationData.IsDeclarationRequired}
                    />
                    <SelectList
                        name="ApplicationData.PublicWorkflowScheme"
                        value={values.ApplicationData.PublicWorkflowScheme}
                        placeholder={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_PLACEHOLDER_PUBLICWORKFLOWSCHEMENAME)}
                        minLength={0}
                        maxLength={140}
                        inputSize={FIELD_SIZE.MEDIUM}
                        label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_PUBLICWORKFLOWSCHEMENAME)}
                        required
                        isClearable={true}
                        options={publicWorkflowSchemeList.filter(w => w.label !== values.ApplicationData.InternalWorkflowScheme)}
                        onChangeField={onChangeField}
                        isMulti={false}
                        error={errors.ApplicationData && errors.ApplicationData.PublicWorkflowScheme}
                    />
                    <Radio
                        name="ApplicationData.IsFormDataNeededByPublicWorkflowScheme"
                        value={values.ApplicationData.IsFormDataNeededByPublicWorkflowScheme}
                        inputSize={FIELD_SIZE.NONE}
                        label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_FORMDATANEEDEDBYPUBLICWORKFLOWSCHEME)}
                        options={[
                            { label: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_YES), value: 'true' },
                            { label: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_NO), value: 'false' }]}
                        onChange={e => {
                            onChange(e);
                        }}
                    //error={errors.ApplicationData && errors.ApplicationData.IsDeclarationRequired}
                    />
                    <Radio
                        name="ApplicationData.IsDeclarationRequired"
                        value={values.ApplicationData.IsDeclarationRequired}
                        options={[
                            { label: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_YES), value: 'true' },
                            { label: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_NO), value: 'false' }]}
                        onChange={e => {
                            onChange(e);
                        }}
                        inputSize={FIELD_SIZE.NONE}
                        label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_DECLARATIONREQUIRED)}
                        error={errors.ApplicationData && errors.ApplicationData.IsDeclarationRequired}
                        required
                    />

                </AccordionContainer>
                {values.ApplicationData.LicenceTypeId && values.ApplicationData.IsDeclarationRequired === 'true' &&

                    <AccordionContainer
                        title={SECTION_NAMES.DECLARATION.title}
                        active={SECTION_NAMES.DECLARATION.status}
                        onClick={toggleSection}
                        isIndividual={true}
                        sections={SECTION_NAMES}
                    >
                        <LACDeclaration values={values}
                            errors={errors}
                            isDeclarationReadyClick={isDeclarationReadyClick}
                            licenceName={licenceName}
                            resetDeclarationReadyFunc={resetDeclarationReadyFunc} />
                    </AccordionContainer>
                }
                <AccordionContainer
                    title={SECTION_NAMES.SUPPORTING_DOCUMENT.title}
                    active={SECTION_NAMES.SUPPORTING_DOCUMENT.status}
                    onClick={toggleSection}
                    isIndividual={true}
                    sections={SECTION_NAMES}
                >
                    <LACSupportingDocument values={values} />
                </AccordionContainer>

                <AccordionContainer
                    title={SECTION_NAMES.APPLICATION_FIELDS.title}
                    active={SECTION_NAMES.APPLICATION_FIELDS.status}
                    onClick={toggleSection}
                    isIndividual={true}
                    sections={SECTION_NAMES}
                >
                    <Radio
                        name="ApplicationData.IsUsingFormIO"
                        value={values.ApplicationData.IsUsingFormIO}
                        options={[
                            { label: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_YES), value: 'true' },
                            { label: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_NO), value: 'false' }]}
                        onChange={e => {
                            onChange(e);
                        }}
                        inputSize={FIELD_SIZE.NONE}
                        label="Use Form.IO"
                        error={errors.ApplicationData && errors.ApplicationData.IsUsingFormIO}
                        required
                    />
                    {values.ApplicationData.IsUsingFormIO === 'false' &&
                        <Row>
                            <AuthConsumer>
                                {({ isAuthenticated, profile }) => ((profile && isAuthenticated) && (
                                    <>
                                        <p className="app-fields-upload">{t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_MESSAGE_PLACEHOLDER_CLICK)}
                                            <span className='cursor-pointer' onClick={() => getFileTemplate(profile)}><b>&nbsp;{t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_LINK_HERE)}&nbsp;</b></span>
                                            {t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_MESSAGE_PLACEHOLDER_DOWNLOADEXCELMESSAGE)}
                                        </p>
                                    </>
                                ))}</AuthConsumer>
                        </Row>
                    }
                    {values.ApplicationData.IsUsingFormIO === 'false' &&
                        <FileUpload
                            name="ApplicationData.FormEngineExcel"
                            fileList={values.ApplicationData.FileUploadFiles}
                            value={values.ApplicationData.FormEngineExcel}
                            placeholder={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_PLACEHOLDER_UPLOADFILE)}
                            accept=".xls,.xlsm"
                            onChangeField={onChangeField}
                            inputSize={FIELD_SIZE.LARGE}
                            label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_UPLOADFILE)}
                            error={errors.ApplicationData && errors.ApplicationData.FormEngineExcel}
                            getFileURL={FORM_ENGINE_DOWNLOAD_EXCEL_FILE_URL}
                            required
                        />
                    }

                    {values.ApplicationData.IsUsingFormIO === 'true' &&
                        <Row>
                            <p className="app-fields-upload">{t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_MESSAGE_PLACEHOLDER_CLICK)}
                                <span className='cursor-pointer' onClick={() => { navigateTo(PATH.CONFIG.FORM_GENERATOR) }}><b>&nbsp;{t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_LINK_HERE)}&nbsp;</b></span>
                                {t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_MESSAGE_PLACEHOLDER_ACCESSFORMGENERATOR)}
                            </p>
                        </Row>
                    }

                    {values.ApplicationData.IsUsingFormIO === 'true' &&
                        <SelectList
                            name="ApplicationData.FormIOName"
                            value={values.ApplicationData.FormIOName}
                            placeholder="Select Form"
                            minLength={0}
                            maxLength={140}
                            inputSize={FIELD_SIZE.MEDIUM}
                            label="Select Form"
                            required
                            isClearable={true}
                            options={formIOFormOptions}
                            onChangeField={(name, value) => {
                                onChangeField(name, value);
                                // get all fields in such form
                                formIOChangeHandler(value);
                            }}
                            isMulti={false}
                            error={errors.ApplicationData && errors.ApplicationData.FormIOName}
                        />
                    }

                    {values.ApplicationData.IsUsingFormIO === 'true' &&
                        <Radio
                            name="ApplicationData.IsMappingToCRM"
                            value={values.ApplicationData.IsMappingToCRM}
                            options={[
                                { label: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_YES), value: 'true' },
                                { label: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_NO), value: 'false' }]}
                            onChange={e => {
                                onChange(e);
                            }}
                            inputSize={FIELD_SIZE.NONE}
                            label="Map to CRM Module?"
                            error={errors.ApplicationData && errors.ApplicationData.IsMappingToCRM}
                            required
                        />
                    }

                    {values.ApplicationData.IsUsingFormIO === 'true' && values.ApplicationData.IsMappingToCRM === 'true' &&
                        <SelectList
                            name="ApplicationData.CRMModuleName"
                            value={values.ApplicationData.CRMModuleName}
                            placeholder="Select CRM Module"
                            minLength={0}
                            maxLength={140}
                            inputSize={FIELD_SIZE.MEDIUM}
                            label="Select CRM Module"
                            required
                            isClearable={true}
                            options={CRMModuleNameList}
                            onChangeField={(name, value) => {
                                onChangeField(name, value);
                                // get all fields in such form
                                crmModuleChangeHandler(value);
                            }}
                            isMulti={false}
                            error={errors.ApplicationData && errors.ApplicationData.CRMModuleName}
                        />
                    }
                    <Row>
                        <Col className="button-group-center">
                            <Button color="neutral" size="sm" type="button" onClick={() => previewForm(values)}>{t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_PREVIEW)}</Button>
                        </Col>
                    </Row>
                </AccordionContainer>

                {values.ApplicationData.IsMappingToCRM === 'true' &&
                    <AccordionContainer
                        title={SECTION_NAMES.CRMM_MAPPING.title}
                        active={SECTION_NAMES.CRMM_MAPPING.status}
                        onClick={toggleSection}
                        isIndividual={true}
                        sections={SECTION_NAMES}
                    >
                        <CRMMapping formIOFormFieldOptions={formIOFormFieldList} crmFieldOptions={CRMFieldList} values={values}></CRMMapping>
                    </AccordionContainer>
                }
            </Container>
        </React.Fragment>
    );
}
