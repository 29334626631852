import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import * as Yup from 'yup';
import { ADD_CATEGORY_URL, CATEGORY_MANAGEMENT_URL, LAYOUT_TYPE } from '../../App/AppSettings';
import Layout from '../../Layout';
import { ERROR, SUCCESS } from '../../Localization/index';
import { navigateTo } from '../../Shared/Actions';
import { FIELD_SIZE } from '../../Shared/Constants';
import { withSMARTWrapper, SMARTForm, Text } from '../../Shared/Forms';
import { toastError, toastSuccess } from '../../Shared/Forms/Toaster.js';
import { DEFAULT_TEXT, DEFAULT_NAMESPACE } from '../../Shared/Constants/LanguageKeys.js';
import PageFooter from '../../Shared/PageFooter';
import './LanguageList.css';

const FORM_CONTEXT = "AddCategory";
const FORM_INITIAL_VALUES = {
    Name: '',
    Description: ''
}

class AddCategory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            values: {}
        }
    }

    // Fn: Get Yup form validations
    getFormValidations = () => {
        return Yup.object().shape({
            Name: Yup.string()
                .required(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_REQUIRED, ERROR.REQUIRED))
                .max(255, this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_MAXLENGTH, ERROR.MAX_LENGTH_LIMIT)),
            Description: Yup.string()
                .min(0, this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_MINLENGTH, ERROR.MIN_LENGTH_LIMIT))
                .max(2000, this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_MAXLENGTH, ERROR.MAX_LENGTH_LIMIT))
        });
    }

    submitCallback = ({ response }) => {
        if (response.success) {
            const { IsSuccess, RedirectURL, Message, MessageLanguageKey } = response.body;
            if (IsSuccess) {
                if (RedirectURL) {
                    navigateTo(RedirectURL, "");
                } else {
                    navigateTo(CATEGORY_MANAGEMENT_URL, "");
                }
                toastSuccess(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_SUCCESS_TITLE_CREATECATEGORY, SUCCESS.CREATE_SUCCESS), DEFAULT_TEXT.CATEGORY);
            }
            else {
                toastError(this.props.getMessage(MessageLanguageKey, Message));
            }
        } else {
            toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
        }
    }

    render() {
        return (
            <>
                <Layout type={LAYOUT_TYPE.FORM} title=
                    {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_CONTENT_TITLE_ADDCATEGORY, DEFAULT_TEXT.ADD_CATEGORY)}>
                    <SMARTForm
                        formContext={FORM_CONTEXT}
                        formValues={FORM_INITIAL_VALUES}
                        validationSchema={this.getFormValidations()}
                        validateOnSubmit={true}
                        serverURL={ADD_CATEGORY_URL}
                        nextUrl={CATEGORY_MANAGEMENT_URL}
                        submitCallback={this.submitCallback}
                    >
                        {({ values, errors, onChange, submitPlainForm }) => (
                            <Row className="body-content">
                                <Col>
                                    <Col>
                                        <Text
                                            name="Name"
                                            value={values.Name}
                                            placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_FORM_PLACEHOLDER_NAME, DEFAULT_TEXT.NAME)}
                                            onChange={onChange}
                                            maxLength={255}
                                            inputSize={FIELD_SIZE.LARGE}
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_FORM_TITLE_NAME, DEFAULT_TEXT.NAME)}
                                            error={errors.Name}
                                            helpLabel={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_FORM_HELPTEXT_CATEGORY, DEFAULT_TEXT.HELPTEXT_CATEGORY_NAME)}
                                            required
                                        />
                                        <Text
                                            name="Description"
                                            value={values.Description}
                                            placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_FORM_PLACEHOLDER_DESCRIPTION, DEFAULT_TEXT.DESCRIPTION)}
                                            onChange={onChange}
                                            maxLength={2000}
                                            inputSize={FIELD_SIZE.LARGE}
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_FORM_TITLE_DESCRIPTION, DEFAULT_TEXT.DESCRIPTION)}
                                            error={errors.Description}
                                        />
                                    </Col>
                                    <PageFooter type={LAYOUT_TYPE.FORM}>
                                        <Button color="backward" size="sm" type="button" onClick={() => navigateTo(CATEGORY_MANAGEMENT_URL)}>
                                            {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_NAVIGATION_BUTTON_BACK, DEFAULT_TEXT.BACK)}
                                        </Button>
                                        <Button color="forward" size="sm" type="button" onClick={submitPlainForm}>
                                            {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_NAVIGATION_BUTTON_CREATE, DEFAULT_TEXT.CREATE)}
                                        </Button>
                                    </PageFooter>
                                </Col>
                            </Row>
                        )}
                    </SMARTForm>
                </Layout>
            </>
        );
    }
}

export default withSMARTWrapper(AddCategory);
