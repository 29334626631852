import React from 'react';
import { Button, Badge } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faCaretLeft, faLock } from '@fortawesome/free-solid-svg-icons';
import { ColumnDefinition, utils, selectors } from 'griddle-react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import mapProps from 'recompose/mapProps';
import getContext from 'recompose/getContext';
import { STATUS_BADGE_COLOR } from '../../App/AppSettings.js';

//#region LAYOUTS_OVERWRITTEN

// Component: Override Griddle RowContainer Component
const RowContainer = OriginalComponent => compose(
	getContext({
		components: PropTypes.object,
	}),
	utils.connect((state, props) => ({
		columnTitles: selectors.columnTitlesSelector(state),
		columnIds: selectors.columnIdsSelector(state),
		rowProperties: selectors.rowPropertiesSelector(state),
		rowData: selectors.rowDataSelector(state, props),
		className: selectors.classNamesForComponentSelector(state, 'Row'),
		style: selectors.stylesForComponentSelector(state, 'Row'),
	})),
	mapProps(props => {
		const { components, rowProperties, className, ...otherProps } = props;
		return {
			Cell: components.Cell,
			className: props.className,
			...otherProps,
		};
	}),
)(props => (<OriginalComponent {...props} />));


// Component: Override Griddle Row Component
const Row = ({ Cell, griddleKey, columnIds, columnTitles, onClick, onMouseEnter, onMouseLeave, style, className }) => (
	<tr
		key={griddleKey}
		onClick={onClick}
		onMouseEnter={onMouseEnter}
		onMouseLeave={onMouseLeave}
		style={style}
		className={className}
	>
		{columnIds && columnIds.map((c, i) => (
			<Cell
				key={`${c}-${griddleKey}`}
				griddleKey={griddleKey}
				columnId={c}
				style={style}
				className={className}
				dataTitle={columnTitles[i]}
			/>
		))}
	</tr>
);


// Component: Override Griddle Cell Component
const Cell = ({ value, onClick, onMouseEnter, onMouseLeave, style, className, dataTitle }) => (
	<td
		onClick={onClick}
		onMouseEnter={onMouseEnter}
		onMouseLeave={onMouseLeave}
		style={style}
		className={className}
		data-title={dataTitle}
	>
		{value}
	</td>
);


// Component: Override Griddle NoResultsContainer Component
const NoResultsContainer = OriginalComponent => compose(
	getContext({
		components: PropTypes.object,
	}),
	utils.connect(
		state => ({
			columnIds: selectors.columnIdsSelector(state),
			className: selectors.classNamesForComponentSelector(state, 'NoResults'),
			style: selectors.stylesForComponentSelector(state, 'NoResults'),
			noResultsMessage: selectors.textSelector(state, { key: 'noResultsMessage' }),
		})
	),
	mapProps((props) => {
		const { components, ...otherProps } = props;
		return {
			NoResults: components.NoResults,
			...otherProps
		};
	})
)((props) => <OriginalComponent {...props} />);


// Component: Override Griddle NoResults Component
const NoResults = ({ columnIds, className, style, noResultsMessage }) => (
	<tbody>
		<tr style={style} className={className}>
			<td colSpan={columnIds.length}>{noResultsMessage ? noResultsMessage : 'No results available.'}</td>
		</tr>
	</tbody>
);


// Component: Override Griddle Table Component
const Table = ({ TableHeading, TableBody, Loading, NoResults, style, className, dataLoading, visibleRows, noResultMessage }) =>
	dataLoading ? (Loading && <Loading />) :
		(
			<table style={style} className={className}>
				<TableHeading />
				{visibleRows ? (TableBody && <TableBody />) : (NoResults && <NoResults noResultMessage={noResultMessage} />)}
			</table>
		)

/* ********************************* Inbox Pagination *********************************** */
// const InboxPagination = ({ Next, Previous, style, className }) => (
// 	<div style={style} className={className}>
// 		{/* {First && <First />} */}
// 		{Previous && <Previous />}
// 		{/* {PageDropdown && <PageDropdown />} */}
// 		1 - 5 of 7
// 		{Next && <Next />}
// 		{/* {Last && <Last />} */}
// 	</div>
// );

// const InboxTable = ({ TableBody, Loading, NoResults, style, className, dataLoading, visibleRows, noResultMessage }) =>
// 	dataLoading ? (Loading && <Loading />) :
// 		(
// 			<table style={style} className={className}>
// 				{visibleRows ? (TableBody && <TableBody />) : (NoResults && <NoResults noResultMessage={noResultMessage} />)}
// 			</table>
// 		)



// const Filter = () => {
// 	return (
// 		<div className="filter-container">
// 			<Input
// 				type="text"
// 				name="search"
// 				placeholder="Search for email"
// 			// onChange={this.props.setFilter(value)}
// 			/>
// 		</div>
// 	);
// };

// const InboxLayout = ({ Buttons, InboxTable, Table, Pagination, Filter, SettingsWrapper }) => (
// 	<React.Fragment>
// 		<ReactRow>
// 			<Col md="6"><Filter /></Col>
// 			<Col md="6"><Pagination /></Col>
// 		</ReactRow>

// 		<ReactRow>
// 			<Col md="1">
// 				{/* <div className="checkall"> */}
// 				{/* <Checkbox
// 					id="CheckAll"
// 					name="CheckAll"
// 					value="Checked"
// 					options={[{ label: "", value: "CheckAll" }]}
// 				// onChange
// 				// checked
// 				/> */}

// 				<Input type="checkbox" className="divstyle"/>
// 			</Col>
// 			<Col md="3">
// 				<div className="buttongroup">
// 					<Button className="move-btn">Move</Button>{' '}
// 					<Button className="moveto-btn">Move to</Button>{' '}
// 				</div>
// 			</Col>
// 			{/* </div> */}
// 		</ReactRow>
// 	<Table />
// 	</React.Fragment >
// 	// <div>
// 	// 	<Filter />
// 	// 	{/* <InboxTable /> */}
// 	// 	<Pagination />
// 	// 	<Table />
// 	// </div>
// );

// Component: Override Griddle Layout Component
const Layout = ({ Buttons, InboxTable, Table, Pagination, Filter, SettingsWrapper }) => (
	<div>
		<Filter />
		<Table />
		<Pagination />
	</div>
);


// Component: Override Griddle PaginationContainer Component
const PaginationContainer = OriginalComponent => compose(
	getContext({
		components: PropTypes.object,
	}),
	utils.connect(
		(state, props) => ({
			className: selectors.classNamesForComponentSelector(state, 'Pagination'),
			style: selectors.stylesForComponentSelector(state, 'Pagination'),
		})
	),
	mapProps((props) => {
		const { components, ...otherProps } = props;
		return {
			First: components.FirstButton,
			Last: components.LastButton,
			Next: components.NextButton,
			Previous: components.PreviousButton,
			PageDropdown: components.PageDropdown,
			...otherProps
		};
	})
)((props) => <OriginalComponent {...props} />);


// Component: Override Griddle Pagination Component
const Pagination = ({ First, Last, Next, Previous, PageDropdown, style, className }) => (
	<div style={style} className={className}>
		{First && <First />}
		{Previous && <Previous />}
		{PageDropdown && <PageDropdown />}
		{Next && <Next />}
		{Last && <Last />}
	</div>
);


// Component: Override Griddle PreviousButton Component
const PreviousButton = ({ hasPrevious, onClick }) => (
	(hasPrevious) &&
	(<Button size="sm" onClick={onClick} color="neutral"><FontAwesomeIcon icon={faCaretLeft} /></Button>)
);


// Component: Override Griddle NextButton Component
const NextButton = ({ hasNext, onClick }) => (
	(hasNext) &&
	(<Button size="sm" onClick={onClick} color="neutral"><FontAwesomeIcon icon={faCaretRight} /></Button>)
);

//#endregion LAYOUTS_OVERWRITTEN


//#region LAYOUTS_ADDITIONAL

// Component: Add Custom FirstButtonEnhancer Component
// const FirstButtonEnhancer = OriginalComponent => compose(
// 	getContext({
// 		events: PropTypes.object
// 	}),
// 	mapProps(({ events: { onFirst }, ...props }) => ({
// 		...props,
// 		onClick: utils.compositionUtils.combineHandlers([onFirst, props.onClick]),
// 	}))
// )((props) => <OriginalComponent {...props} />);


// Component: Add Custom LastButtonEnhancer Component
// const LastButtonEnhancer = OriginalComponent => compose(
// 	getContext({
// 		events: PropTypes.object
// 	}),
// 	mapProps(({ events: { onLast }, ...props }) => ({
// 		...props,
// 		onClick: utils.compositionUtils.combineHandlers([onLast, props.onClick]),
// 	}))
// )((props) => <OriginalComponent {...props} />);


// Component: Add Custom FirstButton Component
// const FirstButton = ({ onClick }) => (
// 	<Button onClick={onClick} size="sm" color="neutral">First</Button>
// );


// Component: Add Custom LastButton Component
// const LastButton = ({ onClick }) => (
// 	<Button onClick={onClick} size="sm" color="neutral">Last</Button>
// );


//#endregion LAYOUTS_ADDITIONAL


//#region HELPER METHODS

// Fn: Wrapper that connects to Griddle & returns rowData & pageProperties props to CustomComponent
const getGridProperties = utils.connect((state, props) => {
	let rowData = state.get('data').find(rowMap => rowMap.get('griddleKey') === props.griddleKey).toJSON();
	let pageProperties = state.get('pageProperties').toJSON();

	return {
		rowData: rowData,
		pageProperties: pageProperties
	};
});

export const getColDefinitionCustom = (props) => {
	return (
		<ColumnDefinition
			id="#"
			key={"#"}
			title={this.props.title}
			sortable={false}
			width={this.props.width}
			customComponent={getGridProperties(RowNumberComponent)}
		/>
	);
};


// Fn: Map & return all the <ColumnDefinition /> used in <RowDefinition> container
export const getColDefinition = (props) => {
	let cols = [];
	Object.entries(props.columns).map(([col, def]) => {
		switch (col) {
			// For ID Column only
			case 'RunningNumber':
				cols.push(
					<ColumnDefinition
						id="#"
						key={"#"}
						title={def.title}
						sortable={false}
						width={def.width}
						customComponent={getGridProperties(RowNumberComponent)}
					/>)
				break;

			// For Action Column only
			case 'Actions':
				cols.push(
					<ColumnDefinition
						id="Actions"
						key={"Actions"}
						headerCssClassName={"griddle-actions"}
						title={def.title}
						sortable={false}
						width={def.width}
						customComponent={getGridProperties(ActionButtonGroupComponent)}
						extraData={props}
					/>)
				break;

			case 'Actions2':
				cols.push(
					<ColumnDefinition
						id="Actions2"
						key={"Actions2"}
						title={def.title}
						sortable={false}
						width={def.width}
						customComponent={getGridProperties(ActionButtonGroup2Component)}
						extraData={props}
					/>)
				break;

			case 'StatusBadge':
				cols.push(
					<ColumnDefinition
						id={def.DBkey}
						key={def.DBkey}
						title={def.title}
						sortable={def.sortable === undefined ? true : def.sortable}
						width={def.width}
						customComponent={getGridProperties(StatusComponent)}
						extraData={props}
					/>)
				break;

			case 'StatusBooleanBadge':
				cols.push(
					<ColumnDefinition
						id={def.DBkey}
						key={def.DBkey}
						title={def.title}
						sortable={def.sortable === undefined ? true : def.sortable}
						width={def.width}
						customComponent={getGridProperties(StatusBooleanComponent)}
						extraData={props}
					/>)
				break;
			case 'Enum':
				cols.push(
					<ColumnDefinition
						id={def.DBkey}
						key={def.DBkey}
						title={def.title}
						sortable={def.sortable === undefined ? true : def.sortable}
						width={def.width}
						customComponent={getGridProperties(EnumComponent)}
						extraData={props}
					/>)
				break;
			case 'Custom':
				cols.push(
					<ColumnDefinition
						id={def.DBkey}
						key={def.DBkey}
						title={def.title}
						visible={def.visible}
						sortable={def.sortable === undefined ? true : def.sortable}
						width={def.width}
						customComponent={getGridProperties(CustomComponent)}
						extraData={props}
					/>)
				break;
			case 'SLADate':
				cols.push(
					<ColumnDefinition
						id={def.DBkey}
						key={def.DBkey}
						title={def.title}
						visible={def.visible}
						sortable={def.sortable === undefined ? true : def.sortable}
						width={def.width}
						customComponent={getGridProperties(StatusDependantComponent)}
						extraData={props}
					/>)
				break;
			case 'IsCheckedOut':
				cols.push(
					<ColumnDefinition
						id={def.DBkey}
						key={def.DBkey}
						title={def.title}
						headerCssClassName={"griddle-actions"}
						visible={def.visible}
						sortable={def.sortable === undefined ? true : def.sortable}
						width={def.width}
						customComponent={getGridProperties(LockComponent)}
						extraData={props}
					/>)
				break;
			// For dynamically generating columns
			case 'CustomExcel':
				props.columns.CustomExcel.map((excelDef) =>
					cols.push(
						<ColumnDefinition
							id={excelDef.DBkey}
							key={excelDef.DBkey}
							title={excelDef.title}
							visible={excelDef.visible}
							sortable={excelDef.sortable === undefined ? true : excelDef.sortable}
							width={excelDef.width}
							customComponent={getGridProperties((RowData) => CustomExcelComponent(RowData, excelDef.DBkey))}
							extraData={props}
						/>)
				);
				break;

			// For all other Columns
			default:
				cols.push(
					<ColumnDefinition
						id={def.DBkey}
						title={def.title}
						key={def.DBkey}
						visible={def.visible}
						sortable={def.sortable}
						customComponent={def.setContent ? getGridProperties(CustomComponent) : undefined}
						width={def.width}
						extraData={props}
					/>
				)
				break;
		}

		return '';
	});
	return cols;
}

// Fn: Return overridden & custom components
export const getCustomComponents = () => {
	return {
		Layout,
		NoResultsContainer,
		NoResults,
		Table,
		RowContainer,
		Row,
		Cell,
		Pagination,
		PaginationContainer,
		NextButton,
		PreviousButton,
		//FirstButtonEnhancer,
		//FirstButton,
		//LastButtonEnhancer,
		//LastButton
	};
};

export const getCustomInboxComponents = () => {
	return {
		Layout,
		NoResultsContainer,
		NoResults,
		Table,
		RowContainer,
		Row,
		Cell,
		Pagination,
		PaginationContainer,
		NextButton,
		PreviousButton,
		// FirstButtonEnhancer,
		// FirstButton,
		// LastButtonEnhancer,
		// LastButton
	};
};

//#endregion HELPER METHODS

//#region COMPONENTS 

// Component: Custom component to get Row Number
const GetRowNumber = (griddleKey, pageProperties) => {
	return (
		(griddleKey + 1) + ((pageProperties.currentPage - 1) * pageProperties.pageSize)
	)
}

// Component: Custom component to display Row ID
const RowNumberComponent = ({ griddleKey, pageProperties }) => {
	return (
		<div>{GetRowNumber(griddleKey, pageProperties)}</div>
	)
}

// Component Custom component to render Action buttons
const ActionButtonGroupComponent = (props) => {
	//props has access to rowData
	return (
		props.renderActions !== undefined &&
		<div className="action-button-group">
			{props.renderActions({
				RowData: props.rowData,
				ID: props.rowIdentifier === undefined ? props.rowData.ID : props.rowData[props.rowIdentifier],
				RowNumber: GetRowNumber(props.griddleKey, props.pageProperties),
				IsDraft: props.rowData.IsDraft,
				FormType: props.rowData.FormType,
				Version: props.rowData.Version,
				FormName: props.rowData.FormName,
				ReferenceNo: props.rowData.ReferenceNo,
				Status: props.rowData.Status,
				PublicDescription: props.rowData.PublicDescription,
				InternalDescription: props.rowData.InternalDescription
			})}
		</div>
	);
};

const ActionButtonGroup2Component = (props) => {
	//props has access to rowData
	return (
		props.renderActions2 !== undefined &&
		<div className="action-button-group">
			{props.renderActions2({
				ID: props.rowIdentifier === undefined ? props.rowData.ID : props.rowData[props.rowIdentifier],
				IsDraft: props.rowData.IsDraft
			})}
		</div>
	);
};

const StatusComponent = (props) => {
	if ((STATUS_BADGE_COLOR.success).indexOf(props.rowData[props.columns.StatusBadge.DBkey]) > -1) {
		return (
			<div className="status-cell">
				<Badge color="success">{props.rowData[props.columns.StatusBadge.DBkey]}</Badge>
			</div>
		);
	} else if ((STATUS_BADGE_COLOR.warning).indexOf(props.rowData[props.columns.StatusBadge.DBkey]) > -1) {
		return (
			<div className="status-cell">
				<Badge color="warning">{props.rowData[props.columns.StatusBadge.DBkey]}</Badge>
			</div>
		);
	} else if ((STATUS_BADGE_COLOR.danger).indexOf(props.rowData[props.columns.StatusBadge.DBkey]) > -1) {
		return (
			<div className="status-cell">
				<Badge color="danger">{props.rowData[props.columns.StatusBadge.DBkey]}</Badge>
			</div>
		);
	} else {
		return (
			<div className="status-cell">
				<Badge color="normal">{props.rowData[props.columns.StatusBadge.DBkey]}</Badge>
			</div>
		);
	}
}

const StatusDependantComponent = (props) => {
	if ((STATUS_BADGE_COLOR.success).indexOf(props.rowData[props.columns.SLAStatus.DBkey]) > -1) {
		return (
			<div className="status-cell">
				<Badge color="success" className="badge-normal-size">{props.rowData[props.columns.SLADate.DBkey]}</Badge>
			</div>
		);
	} else if ((STATUS_BADGE_COLOR.warning).indexOf(props.rowData[props.columns.SLAStatus.DBkey]) > -1) {
		return (
			<div className="status-cell">
				<Badge color="warning" className="badge-normal-size">{props.rowData[props.columns.SLADate.DBkey]}</Badge>
			</div>
		);
	} else if ((STATUS_BADGE_COLOR.danger).indexOf(props.rowData[props.columns.SLAStatus.DBkey]) > -1) {
		return (
			<div className="status-cell">
				<Badge color="danger" className="badge-normal-size">{props.rowData[props.columns.SLADate.DBkey]}</Badge>
			</div>
		);
	} else {
		return (
			<div className="status-cell">
				<Badge color="none" className="badge-normal-size badge-no-color">{props.rowData[props.columns.SLADate.DBkey]}</Badge>
			</div>
		);
	}
}

const StatusBooleanComponent = (props) => {
	if (props.rowData[props.columns.StatusBooleanBadge.DBkey] === true) {
		return (
			<div className="status-cell">
				<Badge color="success">{props.columns.StatusBooleanBadge.trueText}</Badge>
			</div>
		);
	} else {
		return (
			<div className="status-cell">
				<Badge color="normal">{props.columns.StatusBooleanBadge.falseText}</Badge>
			</div>
		);
	}
}

const LockComponent = (props) => {
	if (props.rowData[props.columns.IsCheckedOut.DBkey] === true) {
		return (
			<div className="action-button-group">
				<FontAwesomeIcon icon={faLock} />
			</div>
		);
	} else {
		return (
			''
		);
	}
}

const EnumComponent = (props) => {
	if (props.rowData[props.columns.Enum.DBkey] !== undefined) {
		return (
			<div className="status-cell">
				<div>{props.columns.Enum.enumArray[(props.rowData[props.columns.Enum.DBkey])]}</div>
			</div>
		);
	}
}

const CustomComponent = (props) => {
	const { setContent } =  props.columns[props.columnId];

	if (setContent !== undefined) {
		return setContent(props.rowData);
	}

	return null;
}

const CustomExcelComponent = (props, dbKey) => {
	if (props.rowData[dbKey] !== undefined) {
		let highlightedClassName =
			((props.rowData[dbKey].HasChanged) ? "highlight-background" : "");
		return (<span className={highlightedClassName}>{props.rowData[dbKey].Value}</span>);
	}
}

//#endregion COMPONENTS