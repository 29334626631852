import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Scroll from 'react-scroll';
import { withBaseForm } from './BaseForm';
import { SMARTFormContext } from './SMARTContext';


class SMARTSubForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = { controlList: {} };
    }

    // Lifecycle Fn: Called when component is updated
    componentDidUpdate(prevProps) {

        // If isSubmitting prop in formik changes
        if (prevProps.baseForm.formik.isSubmitting !== this.props.baseForm.formik.isSubmitting) {

            const { errors } = this.props.baseForm.formik;

            // Get first error in dotted reference format
            const firstError = this.getFirstError(errors);
            
            // Toggle & scroll to 1st error
            this.toggleToError(firstError);
            this.scrollToError(firstError);
        }
    }

    // Fn: Output firstError into a dotted reference format given an errorItem object
    getFirstError = (errorItem) => {

        // If errorItem exists
        if (!_.isEmpty(errorItem)) {

            // Get key of errorItem
            const errorKey = Object.keys(errorItem)[0];

            // If VALUE of errorItem is not an object, end the recursion
            if (errorKey && typeof (errorItem[errorKey]) !== 'object') {
                return errorKey;
            }

            // Otherwise, append <ERRORITEM_KEY><.><recursive_fn>
            return errorKey + "." + this.getFirstError(errorItem[errorKey]);
        }
    };


    // Fn: Scroll to error
    scrollToError = (firstError) => {
        // If there is firstError
        if (firstError) {
            // Scroll to BaseControl with same key as firstError
            Scroll.scroller.scrollTo(firstError, {
                duration: 300,
                smooth: true,
                offset: -300
            });
        }

    };

    // Fn: Toggle to error section
    toggleToError = (firstError) => {

        // Retrieve section name based on the given Key
        const sectionName = this.state.controlList[firstError];

        // If section exists, toggle to it (isCollapsible do not matter)
        if (sectionName) {
            const { toggleSection } = this.props.baseForm;
            toggleSection(false, false, sectionName, null);
        }
    };

    // Fn: Callback to inform SMARTForm which SMARTControls are inside SMARTForm
    updateControlList = (controlName, sectionName) => {

        // Create a control list item
        const controlListItem = { [controlName]: sectionName };

        // Add item to controlList without mutating it
        this.setState((state) => ({
            controlList: {
                ...state.controlList,
                ...controlListItem
            }
        }));
    };

    // Fn: Do not display errors if user wants validation to be seen after submitForm() is called
    displayErrors = (validateOnSubmit, submitCount, errors) => {
        return (validateOnSubmit && submitCount < 1) ? '' : errors;
    };

    render() {
        const { baseForm, validateOnSubmit } = this.props;
        const { sectionState, toggleSection, submitPlainForm, submitForm, submitDraft, navigateForm, currentStep, prevStep, nextStep } = baseForm;
        const { values, errors, handleChange, setFieldValue, submitCount } = baseForm.formik;

        return (
                <SMARTFormContext.Provider value={this.updateControlList}>
                    {this.props.children({
                        sectionState,
                        toggleSection,
                        values,
                        errors: this.displayErrors(validateOnSubmit, submitCount, errors),
                        onChange: handleChange,
                        onChangeField: setFieldValue,
                        submitPlainForm,
                        submitForm,
                        submitDraft,
                        navigateForm,
                        currentStep,
                        prevStep,
                        nextStep
                    })}
                </SMARTFormContext.Provider>
        );
    }
}

// PropTypes: For prop validation
SMARTSubForm.propTypes = {                         /* Additional Props: refer to BaseForm.js */
    validateOnSubmit: PropTypes.bool            // Note: Display errors AFTER Submission button is pressed
};

// PropTypes: Defaulting value for optional props
SMARTSubForm.defaultProps = {
    validateOnSubmit: false
};


export default withBaseForm(SMARTSubForm);
