import { default as React } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import {
  GET_PAYMENT_REQUEST_URL,
  IS_DEMO,
  LAYOUT_TYPE,
} from '../../App/AppSettings';
import Layout from '../../Layout';
import {
  fetchRequest,
  getParams,
  METHOD_TYPE,
  navigateTo,
  postParams
} from '../../Shared/Actions';
import { ERROR, LANGUAGE_KEYS } from '../../Shared/Constants/LanguageKeys';
import { withSMARTWrapper } from '../../Shared/Forms';
import history from '../../Shared/History';

const INITIAL_VALUES = 0;

const GET_PAYMENT_REQUEST = GET_PAYMENT_REQUEST_URL;
const PAYMENT_SUCCESS_URL = '/paymentSuccess';

//make payment redirect page to cph
class MakePayment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          PaymentUrl: '',
          SessionToken: '',
          ApplicationCode: '',
          ApplicationRequestID: '',
          ApplicationPaymentReferenceNo: '',
          ApplicationMerchantCode: '',
          PaymentDescription: '',
          Currency: '',
          TotalAmount: '',
          ReturnUrl: '',
          NotifyUrl: '',
          CancelUrl: '',
          OnlineOfflineFlag: '',
          VersionNo: '',
          CustomerId: '',
          IsCashier: '',
          Custom: '',
          ReturnTimer: '',
          OriginalAmount: '',
          Tax: '',
        };
      }
  componentDidMount() {
    document.getElementById("payment-session-form").style.display="none";
    this.getPaymentRequest();
  }

  getPaymentRequest = async () => {
    // Start loading
    const { loader, t } = this.props;
    loader.start();

    let paramSubmissionNumber = INITIAL_VALUES;
    let paramReferenceNumber = INITIAL_VALUES;

    //get the params
    if (history.location.state !== undefined) {
      paramSubmissionNumber = history.location.state.Data.SubmissionNumber;
      paramReferenceNumber = history.location.state.Data.ReferenceNumber;
    } else {
      //show error page
      loader.error(t(ERROR.PREPARE_PAYMENT_FAIL));
      return;
    }

    let queryString = {
      submissionNumber: paramSubmissionNumber,
      referenceNumber: paramReferenceNumber,
    };
    let response = await fetchRequest(
      GET_PAYMENT_REQUEST,
      getParams(queryString),
      IS_DEMO
    );

    const { Data, IsSuccess } = response.body;

    if (IsSuccess) {
      if (Data.TotalAmount !== 0) {
        this.setState({
            PaymentUrl: Data.PaymentUrl,
            SessionToken: Data.PaymentRequestApiModel.SessionToken,
            ApplicationCode: Data.PaymentRequestApiModel.ApplicationCode,
            ApplicationRequestID:
              Data.PaymentRequestApiModel.ApplicationRequestID,
            ApplicationPaymentReferenceNo:
              Data.PaymentRequestApiModel.ApplicationPaymentReferenceNo,
            ApplicationMerchantCode:
              Data.PaymentRequestApiModel.ApplicationMerchantCode,
            PaymentDescription: Data.PaymentRequestApiModel.PaymentDescription,
            Currency: Data.PaymentRequestApiModel.Currency,
            TotalAmount: Data.PaymentRequestApiModel.TotalAmount,
            ReturnUrl: Data.PaymentRequestApiModel.ReturnUrl,
            NotifyUrl: Data.PaymentRequestApiModel.NotifyUrl,
            CancelUrl: Data.PaymentRequestApiModel.CancelUrl,
            OnlineOfflineFlag: Data.PaymentRequestApiModel.OnlineOfflineFlag,
            VersionNo: Data.PaymentRequestApiModel.VersionNo,
            CustomerId: Data.PaymentRequestApiModel.CustomerId,
            IsCashier: Data.PaymentRequestApiModel.IsCashier,
            Custom: Data.PaymentRequestApiModel.Custom,
            ReturnTimer: Data.PaymentRequestApiModel.ReturnTimer,
            OriginalAmount: Data.PaymentRequestApiModel.OriginalAmount,
            Tax: Data.PaymentRequestApiModel.Tax,
          });
          loader.done();
          document.getElementById('payment-session-form').submit();
        // const paramData = new URLSearchParams();
        // for (var key in Data.PaymentRequestApiModel) {
        //   if (Data.PaymentRequestApiModel[key] !== null) {
        //     paramData.append(key, Data.PaymentRequestApiModel[key]);
        //   }
        // }

        // const header = new Headers();
        // header.append('Content-Type', 'application/x-www-form-urlencoded');

        // fetch(Data.PaymentUrl, {
        //   method: METHOD_TYPE.POST,
        //   headers: header,
        //   body: paramData,
        // }).then((response) => {
        //   if (response.redirected) {
        //     window.location.href = response.url;
        //   }
        // });
      } else {
        //no payment needed, trigger update to paid
        const header = new Headers();
        header.append('Content-Type', 'application/json');
        fetch(Data.NotifyNoPaymentUrl, {
          method: 'POST',
          headers: header,
          body: Data.PaymentRequestApiModel.Custom,
        }).then((response) => {
          response.json().then(function (data) {
            if (data.IsSuccess) {
              navigateTo(PAYMENT_SUCCESS_URL);
            } else {
              //fail
              alert(t(ERROR.PAYMENT_FAIL));
            }
          });
        });
      }
    } else {
      //IsSuccess = false, show error page
      loader.error(t(ERROR.PROCESS_PAYMENT_FAIL));
    }
  };

  onSubmit = (e) => {
    e.preventDefault();
  };

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <Layout type={LAYOUT_TYPE.FORM} footer={true}>
          <div className='main-content'>
            <p className='paymentRedirect'>
              {t(
                LANGUAGE_KEYS.BLS_INTERNALPAYMENTINBOX_MESSAGE_PLACEHOLDER_REDIRECTPAYMENT
              )}
            </p>
            <div className='container'>
            <form
                id='payment-session-form'
                action={this.state.PaymentUrl}
                method={METHOD_TYPE.POST}
                onSubmit={this.onSubmit}
              >
                <h2>Payment Session</h2>
                <label>
                  <span className='text'>Session Token:</span>
                  <input
                    type='text'
                    name='sessionToken'
                    value={this.state.SessionToken}
                  />
                  <br />
                </label>
                <br />
                <label>
                  <span className='text'>Application Code:</span>
                  <input
                    type='text'
                    name='applicationCode'
                    value={this.state.ApplicationCode}
                  />
                  <br />
                </label>
                <br />
                <label>
                  <span className='text'>ApplicationRequestID:</span>
                  <input
                    type='text'
                    name='applicationRequestID'
                    value={this.state.ApplicationRequestID}
                  />
                  <br />
                </label>
                <br />
                <label>
                  <span className='text'>ApplicationPaymentReferenceNo:</span>
                  <input
                    type='text'
                    name='applicationPaymentReferenceNo'
                    value={this.state.ApplicationPaymentReferenceNo}
                  />
                  <br />
                </label>
                <br />
                <label>
                  <span className='text'>ApplicationMerchantCode:</span>
                  <input
                    type='text'
                    name='applicationMerchantCode'
                    value={this.state.ApplicationMerchantCode}
                  />
                  <br />
                </label>
                <br />
                <label>
                  <span className='text'>PaymentDescription:</span>
                  <input
                    type='text'
                    name='paymentDescription'
                    value={this.state.PaymentDescription}
                  />
                  <br />
                </label>
                <br />
                <label>
                  <span className='text'>Currency:</span>
                  <input
                    type='text'
                    name='currency'
                    value={this.state.Currency}
                  />
                  <br />
                </label>
                <br />
                <label>
                  <span className='text'>Total Amount:</span>
                  <input
                    type='text'
                    name='totalAmount'
                    value={this.state.TotalAmount}
                  />
                  <br />
                </label>
                <br />
                <label>
                  <span className='text'>ReturnUrl:</span>
                  <input
                    type='text'
                    name='returnUrl'
                    value={this.state.ReturnUrl}
                  />
                  <br />
                </label>
                <br />
                <label>
                  <span className='text'>NotifyUrl:</span>
                  <input
                    type='text'
                    name='notifyUrl'
                    value={this.state.NotifyUrl}
                  />
                  <br />
                </label>
                <br />
                <label>
                  <span className='text'>CancelUrl:</span>
                  <input
                    type='text'
                    name='cancelUrl'
                    value={this.state.CancelUrl}
                  />
                  <br />
                </label>
                <br />
                <label>
                  <span className='text'>OnlineOfflineFlag:</span>
                  <input
                    type='text'
                    name='onlineOfflineFlag'
                    value={this.state.OnlineOfflineFlag}
                  />
                  <br />
                </label>
                <br />
                <label>
                  <span className='text'>VersionNo:</span>
                  <input
                    type='text'
                    name='versionNo'
                    value={this.state.VersionNo}
                  />
                  <br />
                </label>
                <br />
                <label>
                  <span className='text'>CustomerId:</span>
                  <input
                    type='text'
                    name='customerId'
                    value={this.state.CustomerId}
                  />
                  <br />
                </label>
                <br />
                <label>
                  <span className='text'>IsCashier:</span>
                  <input
                    type='text'
                    name='isCashier'
                    value={this.state.IsCashier}
                  />
                  <br />
                </label>
                <br />
                <label>
                  <span className='text'>Custom:</span>
                  <input type='text' name='Custom' value={this.state.Custom} />
                  <br />
                </label>
                <br />
                <label>
                  <span className='text'>ReturnTimer:</span>
                  <input
                    type='text'
                    name='returnTimer'
                    value={this.state.ReturnTimer}
                  />
                  <br />
                </label>
                <br />
                <label>
                  <span className='text'>OriginalAmount:</span>
                  <input
                    type='text'
                    name='originalAmount'
                    value={this.state.OriginalAmount}
                  />
                  <br />
                </label>
                <br />
                <label>
                  <span className='text'>Tax:</span>
                  <input type='text' name='tax' value={this.state.Tax} />
                  <br />
                </label>
                <br />
                <div className='align-right'>
                  <button>Submit</button>
                </div>
              </form>
            </div>
          </div>
        </Layout>
      </React.Fragment>
    );
  }
}
export default withTranslation()(withRouter(withSMARTWrapper(MakePayment)));
