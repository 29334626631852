import _ from 'lodash';
import { faEdit, faEye, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { default as React } from 'react';
import { Button, Col, Row } from 'reactstrap';
import Select from "react-select";
import { ERROR, SUCCESS } from '../../Localization/index';
import { fetchRequest, getParams, navigateTo, postParams } from '../../Shared/Actions';
import { ActionButton, DataTable, MobileCardActionButton } from '../../Shared/DataTable';
import { getCustomComponents } from '../../Shared/DataTable/BaseTable.js';
import { withSMARTWrapper } from '../../Shared/Forms';
import { ModalConfirm } from '../../Shared/Modal';
import { LIST_APPCONFIG_URL, APPCONFIG_UPDATE, APPCONFIG_CREATE, APPCONFIG_VIEW, DELETE_APPCONFIG_URL } from '../../App/AppSettings';
import { toastSuccess, toastError } from '../../Shared/Forms/Toaster';
import { FILTER, SELECT_FILTERS } from '../../Shared/Constants';
import { DEFAULT_TEXT, DEFAULT_NAMESPACE } from '../../Shared/Constants/LanguageKeys.js';
import Title from '../../Title';

const RETRIEVE_URL = LIST_APPCONFIG_URL;

const DELETE_MODAL = {
    hasModal: true,
    name: "Delete"
};

class AppConfigMgmtList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            count: 0,
            searchFilter: "",
            categoryFilter: "",
            modalState: undefined,
            AppConfigCategory: ""
        };
    }

    componentDidMount = async () => {
        this.loadGrid();
    };

    onFilterChange = (filterName, value, setFilter) => {
        this.setState({
            [filterName]: value
        }, () => {
            this.filter(setFilter);
        });
    }

    filter = (setFilter) => {
        const filterFields = {
            [FILTER]: this.state.searchFilter,
            [SELECT_FILTERS]: {
                'AppConfigCategory': this.state.categoryFilter
            }
        };
        setFilter(filterFields);
    }

    CustomGridFilter = (props) => {
        let categoryOptions = this.state.categoryOptions;
        let categoryValue = this.state.categoryFilter;
        categoryValue = categoryValue === "" ? "" :
            _.find(categoryOptions, { 'value': categoryValue });

        return (
            <React.Fragment>
                <Select
                    defaultValue={categoryValue}
                    placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_CONTENT_DROPDOWN_ALLCATEGORIES, DEFAULT_TEXT.ALL_CATEGORIES)}
                    onChange={(selectedOption) => this.onFilterChange("categoryFilter", selectedOption.value, props.setFilter)}
                    options={categoryOptions}
                    maxMenuHeight={150}
                    menuPlacement="auto"
                    styles={{
                        menuPortal: styles => ({ ...styles, zIndex: 100 }) //  >= dialog's z-index
                    }}
                    className="select-filter select-list" //  class for css
                />
                <input
                    type="text"
                    name="filter"
                    defaultValue={this.state.searchFilter}
                    placeholder={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_TABLE_PLACEHOLDER_FILTER, DEFAULT_TEXT.FILTER)}
                    className="custom-griddle-filter griddle-filter"
                    onChange={e => this.onFilterChange("searchFilter", e.target.value, props.setFilter)}
                />
            </React.Fragment>
        );
    };

    loadGrid = async () => {
        this.props.loader.start();
        const response = await fetchRequest(RETRIEVE_URL, getParams(), false);

        const { Data, IsSuccess } = response.body;
        if (response.success && IsSuccess) {
            this.setState({
                success: response.success,
                data: Data,
                categoryOptions: this.getCategoryOptions(Data),
                count: ((Data !== undefined) ? Data.length : 0)
            });
        }
        this.props.loader.done();
    }

    getCategoryOptions = (Data) => {
        let allCategoriesOption = [{ label: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_CONTENT_DROPDOWN_ALLCATEGORIES, DEFAULT_TEXT.ALL_CONFIGURATIONS), value: "" }];

        // Existing Category Options
        let categoryOptions = [];
        _.forEach(Data, function (appConfig) {
            let val = appConfig.AppConfigCategory;
            categoryOptions.push({ 'label': val, 'value': val });
        });

        // Sort Unique Category Options
        let otherCategoryOptions = _.sortBy(_.uniqBy(categoryOptions, 'label'), ['label']);
        categoryOptions = _.concat(allCategoriesOption, otherCategoryOptions);

        return categoryOptions;
    }

    // Fn: Toggle modal
    toggleModal = (modalName) => {
        this.setState({
            modalState: (this.state.modalState !== modalName) ? modalName : undefined,
        })
    };

    // Fn: Set state for ApplicationCategory and ApplicationName 
    setConfigData = (data) => {
        this.setState({
            AppConfigCategory: data.AppConfigCategory,
            AppConfigName: data.AppConfigName
        })
    }

    // Fn: Confirm Modal submit callback
    confirmModalCallBack = () => {
        this.toggleModal(DELETE_MODAL.name);
        this.DeleteApplicationConfig();
    }


    // Fn: Delete ApplicationConfig 
    DeleteApplicationConfig = async () => {
        const { AppConfigCategory, AppConfigName } = this.state

        const params = {
            AppConfigCategory: AppConfigCategory,
            AppConfigName: AppConfigName
        }

        const response = await fetchRequest(DELETE_APPCONFIG_URL, postParams(DELETE_APPCONFIG_URL, params));

        const { IsSuccess, Messages } = response.body;

        if (response.success) {
            if (IsSuccess) {
                // Reload Grid Data after deleting
                await this.loadGrid();

                toastSuccess(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_SUCCESS_TITLE_DELETE, SUCCESS.DELETE_SUCCESS), DEFAULT_TEXT.APPLICATION_CONFIGURATION);
            }
            else {
                // Error message to be loaded here
                toastError(Messages);
            }
        }
        else {
            toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
        }
    }


    renderMobileCardActions = (RowData) => {
        return (
            <React.Fragment>
                <MobileCardActionButton
                    onClick={() => navigateTo(APPCONFIG_UPDATE, { ID: RowData.Id })}
                    icon={faEdit}
                    label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_TABLE_TOOLTIP_UPDATECONFIG, DEFAULT_TEXT.UPDATE_CONFIGURATION)}
                />
            </React.Fragment>
        )
    }

    getMobileCardSelectFilterValues = () => {
        let categoryOptions = this.state.categoryOptions;
        let selectFilterValues = [
            {
                name: "AppConfigCategory",
                placeholder: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_CONTENT_DROPDOWN_ALLCATEGORIES, DEFAULT_TEXT.ALL_CONFIGURATIONS),
                options: categoryOptions
            }
        ]
        return selectFilterValues;
    }

    getCustomComponents = () => {
        let customComponents = getCustomComponents();
        customComponents.Filter = this.CustomGridFilter;

        return customComponents;
    }

    setFilterText = (filterText) => {
        this.setState({
            categoryFilter: filterText[SELECT_FILTERS]['AppConfigCategory'],
            searchFilter: filterText[FILTER]
        })
    }

    renderLayout = () => {
        if (this.state.success) {
            return (
                <React.Fragment>
                    <Col className='text-right remove-padding'>
                        <Button color="neutral" onClick={() => navigateTo(APPCONFIG_CREATE)}>
                            {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_NAVIGATION_BUTTON_NEWCONFIGURATION, DEFAULT_TEXT.NEW_CONFIGURATION)}
                        </Button>
                    </Col>
                    <div className="smart-list">
                        <DataTable
                            pageSize="5"
                            isCustomFilter={true}
                            setFilterText={this.setFilterText}
                            minFilterChars='2'
                            data={this.state.data}
                            noResultsMessage={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_TABLE_TEXT_NORECORD, DEFAULT_TEXT.APPCONFIG_NO_RECORD)}
                            components={this.getCustomComponents()}
                            columns={
                                {
                                    RunningNumber: { title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_CONTENT_TABLE_NO, DEFAULT_TEXT.NUM), width: '5%' },
                                    Actions: { title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_CONTENT_TABLE_ACTIONS, DEFAULT_TEXT.ACTIONS), width: '10%', DBkey: 'Action' },
                                    Category: { title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_CONTENT_TABLE_CATEGORY, DEFAULT_TEXT.CATEGORY), width: '8%', DBkey: 'AppConfigCategory' },
                                    Name: { title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_CONTENT_TABLE_NAME, DEFAULT_TEXT.NAME), width: '20%', DBkey: 'AppConfigName' },
                                    ValueType: { title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_CONTENT_TABLE_VALUETYPE, DEFAULT_TEXT.VALUE_TYPE), width: '8%', DBkey: 'AppConfigValueType' },
                                    Value: { title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_CONTENT_TABLE_VALUE, DEFAULT_TEXT.VALUE), width: '8%', DBkey: 'AppConfigValue' },
                                    EffectiveStartDate: { title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_CONTENT_TABLE_EFFECTIVESTARTDATE, DEFAULT_TEXT.EFFECTIVE_START_DATE), width: '10%', DBkey: 'AppConfigEffectiveStartDate' },
                                    EffectiveEndDate: { title: this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_CONTENT_TABLE_EFFECTIVEENDDATE, DEFAULT_TEXT.EFFECTIVE_END_DATE), width: '10%', DBkey: 'AppConfigEffectiveEndDate' }
                                }
                            }
                            renderActions={({ RowData }) => (
                                <React.Fragment>
                                    <ActionButton
                                        tooltip={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_TABLE_TOOLTIP_UPDATECONFIG, DEFAULT_TEXT.UPDATE_CONFIGURATION)}
                                        color="forward"
                                        onClick={() => navigateTo(APPCONFIG_UPDATE, { Category: RowData.AppConfigCategory, Name: RowData.AppConfigName })}>
                                        <FontAwesomeIcon icon={faEdit} />
                                    </ActionButton>

                                    <ActionButton
                                        tooltip={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_TABLE_TOOLTIP_VIEWCONFIG, DEFAULT_TEXT.VIEW_CONFIGURATION)}
                                        color="neutral"
                                        onClick={() => navigateTo(APPCONFIG_VIEW, { Category: RowData.AppConfigCategory, Name: RowData.AppConfigName })}>
                                        <FontAwesomeIcon icon={faEye} />
                                    </ActionButton>

                                    <ActionButton
                                        tooltip={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_TABLE_TOOLTIP_DELETECONFIG, DEFAULT_TEXT.DELETE_CONFIGURATION)}
                                        color="danger"
                                        onClick={() => { this.setConfigData(RowData); this.toggleModal(DELETE_MODAL.name) }}>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </ActionButton>
                                </React.Fragment>
                            )}
                            getMobileCardSelectFilterValues={this.getMobileCardSelectFilterValues}
                            renderMobileCardActions={this.renderMobileCardActions}
                        />
                    </div>
                </React.Fragment>
            );
        } else {
            return null;
        }
    }

    render() {
        return (
            <React.Fragment>
                <Title className="first body-title body-title-text" titleValue=
                    {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_CONTENT_TITLE_APPCONFIGMANAGEMENT, DEFAULT_TEXT.APPCONFIG_MANAGEMENT)} />
                <div className="grid-container">
                    <Row>
                        <Col>
                            {this.renderLayout()}
                        </Col>
                    </Row>
                    <Row>
                        <ModalConfirm
                            isOpen={(this.state.modalState === DELETE_MODAL.name)}
                            contentHeader={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_MODAL_TITLE_DELETEAPPCONFIG, DEFAULT_TEXT.DELETE_APPCONFIG_MODAL_TITLE)}
                            contentBody={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_MODAL_TEXT_DELETEAPPCONFIG, DEFAULT_TEXT.DELETE_APPCONFIG_MODAL_TEXT, true)}
                            confirmText={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_NAVIGATION_BUTTON_CONFIRM, DEFAULT_TEXT.CONFIRM)}
                            confirmCallback={() => { this.confirmModalCallBack(); }}
                            cancelText={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_APPCONFIGSTATIC_NAVIGATION_BUTTON_CANCEL, DEFAULT_TEXT.CANCEL)}
                            cancelCallback={() => this.toggleModal(DELETE_MODAL.name)}
                        />
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}

export default withSMARTWrapper(AppConfigMgmtList);

