import { } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row } from 'reactstrap';
import * as Yup from 'yup';
import i18n from '../../../i18n';
import { LAYOUT_TYPE, LICENCE_APP_CONFIG_CREATE, ADDITIONAL_APPLICATION_WORKFLOW_SCHEME_NAME_LIST } from '../../App/AppSettings';
import Layout from '../../Layout';
import Section from '../../Section';
import SectionWrapper from '../../SectionWrapper';
import { isValidForm, navigateTo, getParams, fetchRequest } from '../../Shared/Actions';
import { SECTION_WRAPPER_TYPES } from '../../Shared/Constants';
import { ERROR, LANGUAGE_KEYS } from '../../Shared/Constants/LanguageKeys';
import { SMARTForm } from '../../Shared/Forms';
import { toastError } from '../../Shared/Forms/Toaster.js';
import history from '../../Shared/History';
import PageFooter from '../../Shared/PageFooter';
import { getLangKey } from '../DisplayComponents/DisplayUtils';
import LicenceAppConfigAdditionalInformation from './FormComponents/AdditionalInformation';
import LicenceAppConfigApplication from './FormComponents/Application';
import LicenceAppConfigDocument from './FormComponents/Document';
import LicenceAppConfigPayment from './FormComponents/Payment';
// import Publish from './FormComponents/Publish';

const FORM_CONTEXT = 'LicenceAppConfigCreate';
const SERVER_URL = LICENCE_APP_CONFIG_CREATE;

const DIRECTION = {
    PREVIOUS: 'Previous',
    NEXT: 'Next'
};

const VALUES = {
    ApplicationData: {
        LicenceTypeId: '',
        ApplicationTypeId: '',
        InternalWorkflowScheme: '',
        PublicWorkflowScheme: '',
        IsFormDataNeededByInternalWorkflowScheme: 'false',
        PublicWorkflowScheme: '',
        IsFormDataNeededByPublicWorkflowScheme: 'false',
        IsMappingToCRM: 'false',
        CRMModuleName: '',
        DeclarationData: [],
        SupportingDocumentData: [],
        IsUsingFormIO: 'true',
        FormEngineExcel: '',
        FormIOName: '',
        CRMMappingData: [],
        FormIOTranslationKey: []
    },
    ApplicationOfficerData: {
        AdditionalApplicationFieldsData: []
    },
    PaymentData: [],
    FileUploadSectionFiles: [],
    // PublishData: {
    //     EffectiveFromDate: '',
    //     EffectiveToDate: ''
    // },
    LicenceDocumentData: []
};

// const INVALID_DATE_ERROR = 'Effective To Date should be later than effective from date';



export default function LicenceConfigMgmtCreate() {
    const { t } = useTranslation();

    const VALIDATION_SCHEMA = {
        APPLICATION_DETAILS: Yup.object().shape({
            ApplicationData: Yup.object().shape({
                LicenceTypeId: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED)),
                ApplicationTypeId: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED)),
                InternalWorkflowScheme: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED)),
                PublicWorkflowScheme: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED)),
                IsDeclarationRequired: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED)),
                DeclarationData: Yup.array().when('IsDeclarationRequired', {
                    is: 'true',
                    then: Yup.array().required(t(ERROR.SELECT_DECLARATION))
                }),
                IsMappingToCRM: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED)),
                CRMModuleName: Yup.string().when('IsMappingToCRM', {
                    is: 'true',
                    then: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED))
                }),
                IsUsingFormIO: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED)),
                FormEngineExcel: Yup.string().when('IsUsingFormIO', {
                    is: 'false',
                    then: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED))
                }),
                FormIOName: Yup.string().when('IsUsingFormIO', {
                    is: 'true',
                    then: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED))
                }),
                CRMMappingData: Yup.array().when('IsMappingToCRM', {
                    is: 'true',
                    then: Yup.array().required('Select CRM Mapping.')
                })
            })
        }),
        APPLICATION_DETAILS_OFFICER: Yup.object().shape({
            ApplicationOfficerData: Yup.object().shape({
                AdditionalApplicationFieldsData: Yup.array()
            })
        }),
        PAYMENT_INFORMATION: Yup.object().shape({
            PaymentData: Yup.array().min(1,t(ERROR.NO_PAYMENT_ADDED))
        }),
        LICENCE_DOCUMENT: Yup.object().shape({
            LicenceDocumentData: Yup.array()
        }),
        // PUBLISH: Yup.object().shape({
        //     PublishData: Yup.object().shape({
        //         EffectiveFromDate: Yup.date().required((ERROR.LABEL) + t(ERROR.REQUIRED)),
        //         EffectiveToDate: Yup.date()
        //             .test('EffectiveToDate', INVALID_DATE_ERROR,
        //                 function () {
        //                     const effectiveFromDate = this.resolve(Yup.ref('EffectiveFromDate'));
        //                     const effectiveToDate = this.resolve(Yup.ref('EffectiveToDate'));
        //                     return (effectiveFromDate && effectiveToDate) ? effectiveToDate > effectiveFromDate : true;
        //                 }
        //             )
        //     })
        // })
    };

    const [validationSchema, setValidationSchema] = useState(VALIDATION_SCHEMA.APPLICATION_DETAILS);
    const [values, setValues] = useState(VALUES);
    // const minEffectiveFromDate = new Date();
    const previousPage = history.location.state.from;
    const { LicenceAppConfig } = history.location.state;
    let LicenceTypeId, ApplicationTypeId, SFormTypeId;
    if (LicenceAppConfig) {
        LicenceTypeId = LicenceAppConfig.LicenceTypeId;
        ApplicationTypeId = LicenceAppConfig.ApplicationTypeId;
        SFormTypeId = LicenceAppConfig.SFormTypeId;
    }

    const WIZARD_TAB_NAMES = {
        APPLICATION_DETAILS: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_MENU_APPLICATIONDETAILS),
        APPLICATION_DETAILS_OFFICER: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_MENU_APPLICATIONDETAILSOFFICER),
        LICENCE_DOCUMENT: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_MENU_SERVICEDOCUMENT),
        PAYMENT_INFORMATION: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_MENU_PAYMENTINFORMATION),
        //PUBLISH: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_MENU_PUBLISH)
    };

    useEffect(() => {
        VALUES.ApplicationData.DeclarationData = [];
        VALUES.ApplicationData.SupportingDocumentData = [];
        if (LicenceTypeId && ApplicationTypeId) {
            setValues(prevValues => ({
                ...prevValues,
                SFormTypeId,
                ApplicationData: {
                    ...prevValues.ApplicationData,
                    LicenceTypeId,
                    ApplicationTypeId
                }
            }));
        }
        VALUES.ApplicationOfficerData.AdditionalApplicationFieldsData = [];
    }, [LicenceTypeId, ApplicationTypeId, SFormTypeId]);

    const prepareValidation = (navigationValues, direction) => {
        const { currentStep, nextStep, prevStep, values, submitForm } = navigationValues;
        let navigatedStep, sectionName;
        switch (direction) {
            case DIRECTION.PREVIOUS:
                navigatedStep = currentStep - 1;
                sectionName = Object.keys(WIZARD_TAB_NAMES)[navigatedStep];
                setValidationSchema(VALIDATION_SCHEMA[sectionName]);
                prevStep();
                break;
            case DIRECTION.NEXT:
            default:
                navigatedStep = currentStep + 1;
                sectionName = Object.keys(WIZARD_TAB_NAMES)[navigatedStep];
                if (navigatedStep === 3 && values.LicenceDocumentData
                    && values.LicenceDocumentData.length > 0 && values.ApplicationData.InternalWorkflowScheme) {
                    // Retrieve Workflow State Id and Name by using Scheme Code
                    const fetchDocumentWorkflowResponse = async schemeCode => {
                        const response = await fetchRequest(`${ADDITIONAL_APPLICATION_WORKFLOW_SCHEME_NAME_LIST}schemeCode=${schemeCode}`, getParams(), false);
                        const { Data, IsSuccess } = response.body;
                        if (response.success && IsSuccess) {
                            const invalidPairs = [];
                            values.LicenceDocumentData.forEach(document => {
                                if (Data.some(data => data.WorkflowStateId === document.WorkflowStateId) === false) {
                                    if(invalidPairs.some(pair => 
                                        pair.label === document.WorkflowStateName 
                                        && pair.value === document.RequiredActionName) === false
                                    )
                                    invalidPairs.push({ label: document.WorkflowStateName, value: document.RequiredActionName });
                                }
                            });

                            const actionPairs = [];
                            invalidPairs.forEach(pair => {
                                let position = actionPairs.findIndex(actionPair => actionPair.label === pair.label);
                                if(position === -1){
                                    actionPairs.push({ label: pair.label, value: pair.value });
                                } else {
                                    actionPairs[position].value += ', ' + pair.value;
                                }
                            });

                            if (actionPairs.length > 0) {
                                let errorMsg = 'The following workflow state and action pairs are no longer supported by the currently selected internal workflow,';
                                actionPairs.forEach(pair => errorMsg = errorMsg + "\n" + pair.label + ' - ' + pair.value);
                                toastError(errorMsg);
                            } else if (isValidForm(validationSchema, values)) {
                                setValidationSchema(VALIDATION_SCHEMA[sectionName]);
                                nextStep();
                            }
                        }
                    };

                    // React-Hook that is use to call the above 2 methods
                    fetchDocumentWorkflowResponse(values.ApplicationData.InternalWorkflowScheme);
                }
                else {
                    if (isValidForm(validationSchema, values)) {
                        setValidationSchema(VALIDATION_SCHEMA[sectionName]);
                        nextStep();
                    } else {
                        submitForm();
                    }
                }
                break;
        }
    };

    const submitCallback = ({ response }) => {
        if (response.success) {
            const { IsSuccess, Messages } = response.body;
            if (IsSuccess) {
                navigateTo(previousPage, { LicenceAppConfig });
                i18n.reloadResources();
            }
            else {
                toastError(t(getLangKey(ERROR.BACKEND_ERROR_MESSAGE, Messages)), Messages);
            }
        } else {
            toastError(t(ERROR.SERVER_UNREACHABLE));
        }
    };

    return (
        <React.Fragment>
            <Layout type={LAYOUT_TYPE.FORM} title={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_TITLE_NEWSERVICEAPPLICATION)}>
                <SMARTForm
                    className="container"
                    formContext={FORM_CONTEXT}
                    sectionNames={WIZARD_TAB_NAMES}
                    defaultSection={WIZARD_TAB_NAMES.APPLICATION_DETAILS}
                    formValues={values}
                    serverURL={SERVER_URL}
                    validationSchema={validationSchema}
                    submitCallback={submitCallback}
                    validateOnSubmit={true}
                >
                    {({ toggleSection, toggleAllSections, sectionState, onChange, onChangeField, values, submitForm, submitDraft, errors, currentStep, prevStep, nextStep }) => {
                        const smartFormValues = {
                            toggleSection: toggleSection,
                            toggleAllSections: toggleAllSections,
                            values: values,
                            onChange: onChange,
                            onChangeField: onChangeField,
                            errors: errors,
                            submitForm: submitForm
                        };

                        const navigationValues = {
                            nextStep: nextStep,
                            prevStep: prevStep,
                            currentStep: currentStep,
                            values: values,
                            submitForm: submitForm
                        };

                        return (
                            <React.Fragment>
                                <Row className="body-content">
                                    <Col>
                                        <SectionWrapper
                                            type={SECTION_WRAPPER_TYPES.WIZARD}
                                            tabs={WIZARD_TAB_NAMES}
                                            sectionState={sectionState}
                                            toggleSection={toggleSection}>

                                            <Section
                                                type={SECTION_WRAPPER_TYPES.WIZARD}
                                                sectionState={sectionState}
                                                toggleSection={toggleSection}
                                                sectionName={WIZARD_TAB_NAMES.APPLICATION_DETAILS}
                                                key={WIZARD_TAB_NAMES.APPLICATION_DETAILS}
                                                container={false}>
                                                <LicenceAppConfigApplication
                                                    smartFormValues={smartFormValues}
                                                    isLicenceNameReadOnly={LicenceTypeId && LicenceTypeId !== ''}
                                                    isApplicationTypeReadOnly={ApplicationTypeId && ApplicationTypeId !== ''}
                                                />
                                            </Section>
                                            <Section
                                                type={SECTION_WRAPPER_TYPES.WIZARD}
                                                sectionState={sectionState}
                                                toggleSection={toggleSection}
                                                sectionName={WIZARD_TAB_NAMES.APPLICATION_DETAILS_OFFICER}
                                                key={WIZARD_TAB_NAMES.APPLICATION_DETAILS_OFFICER}
                                                container={false}>
                                                <LicenceAppConfigAdditionalInformation
                                                    smartFormValues={smartFormValues}
                                                />
                                            </Section>
                                            <Section
                                                type={SECTION_WRAPPER_TYPES.WIZARD}
                                                sectionState={sectionState}
                                                toggleSection={toggleSection}
                                                sectionName={WIZARD_TAB_NAMES.LICENCE_DOCUMENT}
                                                key={WIZARD_TAB_NAMES.LICENCE_DOCUMENT}
                                                container={false}>

                                                <LicenceAppConfigDocument
                                                    smartFormValues={smartFormValues}
                                                />
                                            </Section>
                                            <Section
                                                type={SECTION_WRAPPER_TYPES.WIZARD}
                                                sectionState={sectionState}
                                                toggleSection={toggleSection}
                                                sectionName={WIZARD_TAB_NAMES.PAYMENT_INFORMATION}
                                                key={WIZARD_TAB_NAMES.PAYMENT_INFORMATION}
                                                container={false}>
                                                <LicenceAppConfigPayment
                                                    smartFormValues={smartFormValues}
                                                />
                                            </Section>
                                            {/*<Section
                                                type={SECTION_WRAPPER_TYPES.WIZARD}
                                                sectionState={sectionState}
                                                toggleSection={toggleSection}
                                                sectionName={WIZARD_TAB_NAMES.PUBLISH}
                                                key={WIZARD_TAB_NAMES.PUBLISH}
                                                container={false}>

                                                 <Publish
                                                    smartFormValues={smartFormValues}
                                                    minEffectiveFromDate={minEffectiveFromDate}
                                                /> 
                                            </Section>*/}
                                        </SectionWrapper>
                                    </Col>
                                </Row>
                                <PageFooter type={LAYOUT_TYPE.FORM}>
                                    <div>
                                        {/* Wizard Previous Button */}
                                        {currentStep !== 0 &&
                                            <Button color="backward" size="sm" type="button" onClick={() => prepareValidation(navigationValues, DIRECTION.PREVIOUS)}>
                                                {t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_NAVIGATION_BUTTON_PREVIOUS)}
                                            </Button>}

                                        {/* Cancel Button */}
                                        {currentStep === 0 &&
                                            <Button color="backward" size="sm" type="button" onClick={() => navigateTo(previousPage, { LicenceAppConfig })}>
                                                {t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_CANCEL)}
                                            </Button>}

                                        {/* Submit Button */}
                                        {currentStep === Object.keys(WIZARD_TAB_NAMES).length - 1 &&
                                            <Button color="neutral" size="sm" type="button" onClick={submitForm}>
                                                {t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_SUBMIT)}
                                            </Button>}

                                        {/* Wizard Next Button */}
                                        {currentStep !== Object.keys(WIZARD_TAB_NAMES).length - 1 &&
                                            <Button color="neutral" size="sm" type="button" onClick={() => prepareValidation(navigationValues, DIRECTION.NEXT)}>
                                                {t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_NAVIGATION_BUTTON_NEXT)}
                                            </Button>}
                                    </div>
                                </PageFooter>
                            </React.Fragment>
                        );
                    }
                    }
                </SMARTForm>
            </Layout>
        </React.Fragment>
    );
}
