import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import { Divider } from 'antd';
import { EDIT_FAQ_SETTINGS_URL, LAYOUT_TYPE, FAQ_MANAGEMENT_URL, GET_FAQ_URL, SUPPORTED_LANGUAGE_LIST_URL } from '../../App/AppSettings';
import Layout from '../../Layout';
import { ERROR, SUCCESS } from '../../Localization/index';
import { navigateTo, fetchRequest, getParams } from '../../Shared/Actions';
import { FIELD_SIZE } from '../../Shared/Constants';
import { withSMARTWrapper, SMARTForm, Plaintext, ToggleSwitch, Checkbox } from '../../Shared/Forms';
import { toastError, toastSuccess } from '../../Shared/Forms/Toaster.js';
import { DEFAULT_TEXT, DEFAULT_NAMESPACE } from '../../Shared/Constants/LanguageKeys.js';
import { withLoader } from '../../Shared/Loading';
import history from '../../Shared/History';
import PageFooter from '../../Shared/PageFooter';

const FORM_CONTEXT = "AddFaq";
const dropDownArraySupportedLanguage = 'dropDownArraySupportedLanguage';
const FORM_INITIAL_VALUES = {
    Name: '',
    Description: '',
    Application: '',
    IsLocaleEnabled: "No",
    IsQnAMakerSyncEnabled: "No"
}
const LABEL_SIZE = 3;
const INPUT_SIZE = 9;

class AddFaq extends React.Component {
    constructor(props) {
        super(props);
        if (history.location.state) {

            this.state = {
                values: FORM_INITIAL_VALUES,
                Id: history.location.state.Id,
                isLocaleEnabled: history.location.state.IsLocaleEnabled,
                [dropDownArraySupportedLanguage]: [],
                language: history.location.state.LanguageCode,
                applicationName: history.location.state.ApplicationName
            }
        }
    }

    componentDidMount = async () => {
        this.props.loader.start();

        // Get Faq
        await this.getFaq();

        this.props.loader.done();
    }

    // Fn: Get Faq data
    getFaq = async () => {
        const params = {
            Id: this.state.Id,
            Language: this.state.language,
            ApplicationName: this.state.applicationName
        };

        const response = await fetchRequest(GET_FAQ_URL, getParams(params));
        const { Data, IsSuccess, Message } = response.body;

        if (response.success) {
            if (IsSuccess) {

                this.setState({
                    values: Data
                })

                if (Data.IsLocaleEnabled) {
                    await this.getSupportedLanguageDropdownList(SUPPORTED_LANGUAGE_LIST_URL, this.state.dropDownArraySupportedLanguage, this.state.applicationName);
                }

                this.setState({
                    values: Data
                });

            } else {
                toastError(Message);
            }
        } else {
            toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
        }
    }

    // Fn: Get language list from code table
    getSupportedLanguageDropdownList = async (url, dropDownName, value) => {
        this.props.loader.start();
        const response = await fetchRequest(url, getParams({ applicationName: value }));
        const { Data, IsSuccess, Message } = response.body;
        let dropDownArray = [];

        this.props.loader.done();
        if (response.success) {
            if (IsSuccess) {

                // Add data to array
                Data.forEach(name => {
                    dropDownArray.push(name.LanguageCodeTitle);
                });

                // Set array to dropDownArraySupportedLanguage
                this.setState({
                    dropDownArraySupportedLanguage: dropDownArray
                });

            } else {
                toastError(Message);
            }
        } else {
            toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
        }

        return dropDownArray;
    }

    // Fn: Create array of language object for checkbox options
    createLanguageCheckboxOptions = () => {
        const languageObjArray = [];

        // Add Language object to array
        this.state.dropDownArraySupportedLanguage.map((lang) =>
            languageObjArray.push({
                label: lang,
                value: lang
            })
        );

        return languageObjArray;
    }

    // Fn: Map values name to pass to backend
    massageValue = (values, submitPlainForm) => {
        values.Application = values.ApplicationName;
        values.FaqId = this.state.Id;

        submitPlainForm();
    }

    // Fn: Action after submitting form
    submitCallback = ({ response }) => {
        if (response.success) {
            const { IsSuccess, RedirectURL, Message } = response.body;
            if (IsSuccess) {
                if (RedirectURL) {
                    navigateTo(RedirectURL, "");
                } else {
                    navigateTo(FAQ_MANAGEMENT_URL, "");
                }
                toastSuccess(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_SUCCESS_TITLE_UPDATEFAQ, SUCCESS.UPDATE_SUCCESS), DEFAULT_TEXT.FAQ);
            }
            else {
                toastError(Message);
            }
        } else {
            toastError(this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE, ERROR.SERVER_UNREACHABLE));
        }
    }

    render() {
        return (
            <>
                <Layout type={LAYOUT_TYPE.FORM} title=
                    {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_CONTENT_TITLE_SETTINGS, DEFAULT_TEXT.FAQ_SETTINGS)}>
                    <SMARTForm
                        formContext={FORM_CONTEXT}
                        formValues={this.state.values}
                        validateOnSubmit={true}
                        serverURL={EDIT_FAQ_SETTINGS_URL}
                        nextUrl={FAQ_MANAGEMENT_URL}
                        submitCallback={this.submitCallback}
                    >
                        {({ values, errors, onChange, onChangeField, submitPlainForm }) => (
                            <Row className="body-content">
                                <Col>
                                    <Col>
                                        <Plaintext
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_APPLICATION, DEFAULT_TEXT.APPLICATION)}
                                            name="ApplicationName"
                                            value={values.ApplicationName}
                                            labelSize={LABEL_SIZE}
                                            inputSize={INPUT_SIZE}
                                        />
                                        <Plaintext
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_NAME, DEFAULT_TEXT.NAME)}
                                            name="Name"
                                            value={values.Name}
                                            labelSize={LABEL_SIZE}
                                            inputSize={INPUT_SIZE}
                                        />
                                        <Plaintext
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_DESCRIPTION, DEFAULT_TEXT.DESCRIPTION)}
                                            name="Description"
                                            value={values.Description}
                                            labelSize={LABEL_SIZE}
                                            inputSize={INPUT_SIZE}
                                        />

                                        {/*US#275350 to hide the chatbot settings*/
                                        /* <Col lg="12" className="label-default">
                                            <Divider className="db-divider" orientation="left">
                                                {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_DIVIDER_AICHATBOT, DEFAULT_TEXT.AI_CHATBOT)}
                                            </Divider>
                                        </Col>
                                        <ToggleSwitch
                                            name="IsQnAMakerSyncEnabledToString"
                                            value={values.IsQnAMakerSyncEnabledToString}
                                            onChangeField={onChangeField}
                                            defaultOn={false}
                                            onText={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_YES, DEFAULT_TEXT.YES)}
                                            offText={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_NO, DEFAULT_TEXT.NO)}
                                            labelSize={FIELD_SIZE.SMALL}
                                            inputSize={FIELD_SIZE.MEDIUM}
                                            label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_ENABLEAI, DEFAULT_TEXT.ENABLE_AI)}
                                            required
                                            error={errors.Language}
                                        /> */}

                                        <Col lg="12" className="label-default">
                                            <Divider className="db-divider" orientation="left">
                                                {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_DIVIDER_MULTILANG, DEFAULT_TEXT.MULTI_LANGUAGE)}
                                            </Divider>
                                        </Col>

                                        {this.state.values.IsLocaleEnabled ?
                                            <Checkbox
                                                label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_LANGUAGE, DEFAULT_TEXT.LANGUAGE)}
                                                name="Language"
                                                value={this.state.dropDownArraySupportedLanguage}
                                                options={this.createLanguageCheckboxOptions()}
                                                onChangeField={onChangeField}
                                                labelSize={LABEL_SIZE}
                                                inputSize={INPUT_SIZE}
                                                checked
                                                required
                                            />
                                            :
                                            <Plaintext
                                                label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_FORM_TITLE_LANGUAGE, DEFAULT_TEXT.LANGUAGE)}
                                                name="Language"
                                                value={values.Language}
                                                labelSize={LABEL_SIZE}
                                                inputSize={INPUT_SIZE}
                                            />
                                        }
                                    </Col>
                                    <PageFooter type={LAYOUT_TYPE.FORM}>
                                        <Button color="backward" size="sm" type="button" onClick={() => navigateTo(FAQ_MANAGEMENT_URL)}>
                                            {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_BACK, DEFAULT_TEXT.BACK)}
                                        </Button>
                                        <Button color="forward" size="sm" type="button" onClick={() => this.massageValue(values, submitPlainForm)}>
                                            {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_SAVE, DEFAULT_TEXT.SAVE)}
                                        </Button>
                                    </PageFooter>
                                </Col>
                            </Row>
                        )}
                    </SMARTForm>
                </Layout>
            </>
        );
    }
}

export default withSMARTWrapper(withLoader(AddFaq));
