import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Container, Row } from 'reactstrap';
import { FORM_ENGINE_EXCEL_URL, GET_FILE_BY_NAME_URL } from '../../../App/AppSettings';
import { fetchRequest, getFileRequest, getParams, postParams } from '../../../Shared/Actions';
import { ERROR, getLangKey, LANGUAGE_KEYS } from '../../../Shared/Constants/LanguageKeys';
import { ActionButton, DataTable } from '../../../Shared/DataTable';
import { getCustomComponents } from '../../../Shared/DataTable/BaseTable.js';
import { AccordionContainer, Plaintext } from '../../../Shared/Forms';
import { convertToFormData } from '../../../Shared/Forms/FormUtils';
import { toastError } from '../../../Shared/Forms/Toaster.js';
import { ModalConfirm } from '../../../Shared/Modal';
import { getLicenceTypeNameTranslationKey } from '../../DisplayComponents/DisplayUtils';

const GET_FILE_URL = GET_FILE_BY_NAME_URL;

export default function LicenceAppConfigApplication(props) {
    const { t } = useTranslation();
    const { values, toggleSection } = props.smartFormValues;
    const [selectedDocValues, setSelectedDocValues] = useState('');
    const [selectedDeclarationValues, setSelectedDeclarationValues] = useState('');
    const [modalState, setModalState] = useState(null);
    const previewButtonDisabled = (values.ApplicationData.ApplicationFieldData.length <= 0) ? true : false;
    const licenceTypeNameTranslationKey = getLicenceTypeNameTranslationKey(values.LicenceTypeName);

    const SECTION_NAMES = {
        APPLICATION_DETAILS: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_SECTION_APPLICATIONDETAILS), status: true },
        DECLARATION: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_SECTION_DECLARATION), status: true },
        SUPPORTING_DOCUMENT: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_SECTION_SUPPORTINGDOCUMENT), status: true },
        APPLICATION_FIELDS: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_SECTION_APPLICATIONFIELDS), status: true }
    };

    const VIEW_DECLARATION_MODAL = {
        hasModal: true,
        name: 'ViewDeclaration',
        modalHeader: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_MODAL_VIEWDECLARATION)
    };

    const VIEW_DOCUMENT_MODAL = {
        hasModal: true,
        name: 'ViewDocument',
        modalHeader: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_MODAL_VIEWDOCUMENT)
    };

    const toggleModal = modalName => {
        setModalState((modalState !== modalName) ? modalName : null);
    };

    const viewDeclaration = data => {
        setSelectedDeclarationValues(data);
        toggleModal(VIEW_DECLARATION_MODAL.name);
    };

    const viewDocument = data => {
        setSelectedDocValues(data);
        toggleModal(VIEW_DOCUMENT_MODAL.name);
    };

    const setMandatorySuppDoc = rowData => (
        rowData.IsMandatorySupportingDocument === 'true' ? t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_YES) : t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_NO)
    );

    const getMandatorySuppDocTranslatedString = (rowData) => {
        return rowData.IsMandatorySupportingDocument === 'true' ? t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_YES) :
            t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_NO);
    }

    const viewExcelFile = async fileName =>
        await getFileRequest(`${GET_FILE_URL}?fileName=${fileName}`, false, fileName, getParams());

    const previewForm = async (values) => {
        if ((values.ApplicationData.FormEngineExcel !== undefined && values.ApplicationData.FormEngineExcel !== '') ||
        (values.ApplicationData.IsUsingFormIO === 'true' && values.ApplicationData.FormIOName !== undefined)) {
            const data = convertToFormData(values);
            const response = await fetchRequest(FORM_ENGINE_EXCEL_URL, postParams(data), false);

            if (response.success) {
                const { IsSuccess, Data } = response.body;
                if (IsSuccess) {
                    window.open(Data, '_blank');
                } else {
                    toastError(t(ERROR.FILE_PROCESS_FAIL));
                }
            } else {
                toastError(t(ERROR.SERVER_UNREACHABLE));
            }
        }
    };

    const setSuppDocNameTranslatedString = (rowData) => {
        return <>{t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_GLOBALSUPPORTINGDOC_DOCNAME_KEY, rowData.GlobalDocumentGuid))}</>
    }

    const getSuppDocNameTranslatedString = (rowData) => {
        const translatedString = t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_GLOBALSUPPORTINGDOC_DOCNAME_KEY, rowData.GlobalDocumentGuid));
        return rowData.GlobalDocumentGuid ? translatedString : rowData.SupportingDocumentName;
    }

    const setSuppDocDescTranslatedString = (rowData) => {
        return <>{t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_GLOBALSUPPORTINGDOC_DOCDESC_KEY, rowData.GlobalDocumentGuid))}</>
    }

    const getSuppDocDescTranslatedString = (rowData) => {
        const translatedString = t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_GLOBALSUPPORTINGDOC_DOCDESC_KEY, rowData.GlobalDocumentGuid));
        return rowData.GlobalDocumentGuid ? translatedString : rowData.SupportingDocumentDescription;
    }

    const setDeclarationTranslatedString = (rowData) => {
        const translatedString = t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPEDECLARATION_DECLARATIONDESC_KEY, licenceTypeNameTranslationKey, rowData.Id));
        return <>{rowData.Id ? translatedString : rowData.DeclarationDescription}</>
    }

    const getDeclarationTranslatedString = (rowData) => {
        const translatedString = t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPEDECLARATION_DECLARATIONDESC_KEY, licenceTypeNameTranslationKey, rowData.Id));
        return rowData.Id ? translatedString : rowData.DeclarationDescription;
    }

    const renderSupportingDocumentGrid = values => {
        return (
            <React.Fragment>
                <div className='smart-list'>
                    <DataTable
                        pageSize='5'
                        minFilterChars='2'
                        data={values.ApplicationData.SupportingDocumentData}
                        noResultsMessage={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_MESSAGE_PLACEHOLDER_NOSUPPORTINGDOC)}
                        components={getCustomComponents()}
                        columns={
                            {
                                RunningNumber: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_NUMBER), width: '5%' },
                                SupportingDocumentName: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_SUPPORTINGDOCUMENT), width: '30%', DBkey: 'SupportingDocumentName', setContent: setSuppDocNameTranslatedString, getTranslatedString: getSuppDocNameTranslatedString },
                                SupportingDocumentDescription: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_DESCRIPTION), width: '25%', DBkey: 'SupportingDocumentDescription', setContent: setSuppDocDescTranslatedString, getTranslatedString: getSuppDocDescTranslatedString },
                                IsMandatorySupportingDocument: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_MANDATORY), width: '10%', DBkey: 'IsMandatorySupportingDocument', setContent: setMandatorySuppDoc, getTranslatedString: getMandatorySuppDocTranslatedString  },
                                Actions: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_ACTION), width: '10%' }
                            }
                        }
                        renderActions={({ RowData }) => (
                            <React.Fragment>
                                <ActionButton tooltip={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_VIEW)} color='forward' onClick={() => viewDocument(RowData)}><FontAwesomeIcon icon={faEye} /></ActionButton>
                            </React.Fragment>
                        )}
                    />
                </div>

                <ModalConfirm
                    isOpen={(modalState === VIEW_DOCUMENT_MODAL.name)}
                    contentHeader={VIEW_DOCUMENT_MODAL.modalHeader}
                    cancelText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_CANCEL)}
                    cancelCallback={() => toggleModal(VIEW_DOCUMENT_MODAL.name)}
                    contentBody={
                        <div>
                            <Plaintext
                                name='SupportingDocumentName'
                                value={selectedDocValues.GlobalDocumentGuid === undefined ? "" : t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_GLOBALSUPPORTINGDOC_DOCNAME_KEY, selectedDocValues.GlobalDocumentGuid))}
                                label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_DOCUMENTNAME)}
                            />
                            <Plaintext
                                name='SupportingDocumentDescription'
                                value={selectedDocValues.GlobalDocumentGuid === undefined ? "" : t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_GLOBALSUPPORTINGDOC_DOCDESC_KEY, selectedDocValues.GlobalDocumentGuid))}
                                label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_DESCRIPTION)}
                            />
                            <Plaintext
                                name='IsMandatorySupportingDocument'
                                value={selectedDocValues.IsMandatorySupportingDocument ? t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_YES) : t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_NO)}
                                label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_ISMANDATORY)}
                            />
                        </div>
                    }
                />
            </React.Fragment>
        );
    };

    const renderDeclarationGrid = values => {
        return (
            <React.Fragment>
                <div className='smart-list'>
                    <DataTable
                        pageSize='3'
                        minFilterChars='2'
                        data={values.ApplicationData.DeclarationData}
                        noResultsMessage={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_MESSAGE_PLACEHOLDER_NODECLARATION)}
                        components={getCustomComponents()}
                        columns={
                            {
                                RunningNumber: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_NUMBER), width: '5%' },
                                DeclarationDescription: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_DECLARATION), width: '85%', DBkey: 'DeclarationDescription', setContent: setDeclarationTranslatedString, getTranslatedString: getDeclarationTranslatedString },
                                Actions: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_ACTION), width: '10%' }
                            }
                        }
                        renderActions={({ RowData }) => (
                            <React.Fragment>
                                <ActionButton tooltip={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_VIEW)} color='forward' onClick={() => viewDeclaration(RowData)}><FontAwesomeIcon icon={faEye} /></ActionButton>
                            </React.Fragment>
                        )}
                    />
                </div>
                <ModalConfirm
                    isOpen={(modalState === VIEW_DECLARATION_MODAL.name)}
                    contentHeader={VIEW_DECLARATION_MODAL.modalHeader}
                    cancelText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_CANCEL)}
                    cancelCallback={() => toggleModal(VIEW_DECLARATION_MODAL.name)}
                    contentBody={
                        <div>
                            <Plaintext
                                name='Declaration'
                                value={selectedDeclarationValues.DeclarationDescription}
                                label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_DECLARATION)}
                            />

                        </div>
                    }
                />
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            <Container className='full-width'>
                <AccordionContainer
                    title={SECTION_NAMES.APPLICATION_DETAILS.title}
                    active={SECTION_NAMES.APPLICATION_DETAILS.status}
                    onClick={toggleSection}
                    isIndividual={true}
                    sections={SECTION_NAMES
                    }
                >
                    <Plaintext
                        label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_SERVICENAME)}
                        name='LicenceTypeName'
                        value={t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY, licenceTypeNameTranslationKey, values.LicenceTypeId))}
                    />
                    <Plaintext
                        label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_APPLICATIONTYPE)}
                        name='ApplicationData.ApplicationType'
                        value={t(getLangKey(LANGUAGE_KEYS.BLS_CODE_LICENCEAPPLICATIONTYPE_TITLE_KEY, values.ApplicationData.ApplicationTypeCodeName))}
                    />
                    <Plaintext
                        label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_INTERNALWORKFLOWSCHEMENAME)}
                        name='InternalWorkflowScheme'
                        value={values.ApplicationData.InternalWorkflowScheme}
                    />
                    <Plaintext
                        label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_FORMDATANEEDEDBYINTERNALWORKFLOWSCHEME)}
                        name='ApplicationData.IsFormDataNeededByInternalWorkflowScheme'
                        value={values.ApplicationData.IsFormDataNeededByInternalWorkflowScheme === 'true' ? t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_YES) : t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_NO)}
                    />
                    <Plaintext
                        label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_PUBLICWORKFLOWSCHEMENAME)}
                        name='PublicWorkflowScheme'
                        value={values.ApplicationData.PublicWorkflowScheme}
                    />
                    <Plaintext
                        label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_FORMDATANEEDEDBYPUBLICWORKFLOWSCHEME)}
                        name='ApplicationData.IsFormDataNeededByPublicWorkflowScheme'
                        value={values.ApplicationData.IsFormDataNeededByPublicWorkflowScheme === 'true' ? t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_YES) : t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_NO)}
                    />
                    <Plaintext
                        name='ApplicationData.IsDeclarationRequired'
                        value={values.ApplicationData.IsDeclarationRequired === 'true' ? t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_YES) : t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_NO)}
                        label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_DECLARATIONREQUIRED)}
                    />
                </AccordionContainer>
                {values.ApplicationData.IsDeclarationRequired === 'true' &&
                    <AccordionContainer
                        title={SECTION_NAMES.DECLARATION.title}
                        active={SECTION_NAMES.DECLARATION.status}
                        onClick={toggleSection}
                        isIndividual={true}
                        sections={SECTION_NAMES}
                    >
                        {renderDeclarationGrid(values)}
                    </AccordionContainer>
                }
                <AccordionContainer
                    title={SECTION_NAMES.SUPPORTING_DOCUMENT.title}
                    active={SECTION_NAMES.SUPPORTING_DOCUMENT.status}
                    onClick={toggleSection}
                    isIndividual={true}
                    sections={SECTION_NAMES}
                >
                    {renderSupportingDocumentGrid(values)}
                </AccordionContainer>
                <AccordionContainer
                    title={SECTION_NAMES.APPLICATION_FIELDS.title}
                    active={SECTION_NAMES.APPLICATION_FIELDS.status}
                    onClick={toggleSection}
                    isIndividual={true}
                    sections={SECTION_NAMES}
                >
                    {values.ApplicationData.IsUsingFormIO === 'true' ? 
                    <div>
                        <Plaintext
                            label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_FORMIOURL)}
                            name='LicenceTypeName'
                            value={values.ApplicationData.FormIOUrl}
                        />
                        <Row>
                            <Col className="button-group-center">
                                <Button color="neutral" size="sm" type="button" onClick={() => previewForm(values)}>{t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_PREVIEW)}</Button>
                            </Col>
                        </Row>
                    </div>
                    :
                    <div>
                        <DataTable
                            pageSize='5'
                            minFilterChars='2'
                            data={values.ApplicationData.ApplicationFieldData}
                            noResultsMessage={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_MESSAGE_PLACEHOLDER_NOFILEFOUND)}
                            components={getCustomComponents()}
                            columns={
                                {
                                    RunningNumber: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_NUMBER), width: '5%' },
                                    FileName: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_FILENAME), width: '85%', DBkey: 'FileName' },
                                    Actions: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_ACTION), width: '10%' }
                                }
                            }
                            renderActions={({ RowData }) => (
                                <React.Fragment>
                                    <ActionButton tooltip={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_VIEW)} color='forward' onClick={() => viewExcelFile(RowData.FileName)}><FontAwesomeIcon icon={faEye} /></ActionButton>
                                </React.Fragment>
                            )}
                        />
                        <Row>
                            <Col className="button-group-center">
                                <Button color="neutral" size="sm" type="button" disabled={previewButtonDisabled} onClick={() => previewForm(values)}>{t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_PREVIEW)}</Button>
                            </Col>
                        </Row>
                    </div>}
                </AccordionContainer>
            </Container>
        </React.Fragment>
    );
}
