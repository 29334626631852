import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import { GET_FILE_BY_NAME_URL } from '../../../App/AppSettings';
import { getFileRequest } from '../../../Shared/Actions';
import { FIELD_SIZE } from '../../../Shared/Constants';
import { ActionButton, DataTable } from '../../../Shared/DataTable';
import { getCustomComponents } from '../../../Shared/DataTable/BaseTable.js';
import { AccordionContainer, Plaintext, FileUpload } from '../../../Shared/Forms';
import { ModalConfirm } from '../../../Shared/Modal';
import { useTranslation } from 'react-i18next';
import { LANGUAGE_KEYS } from '../../../Shared/Constants/LanguageKeys';

const LICENCE_DOC_VALUES = {
    WorkflowStateId: 0,
    WorkflowStateName: '',
    DocumentFile: '',
    DocumentName: '',
    RequiredActionId: '',
    RequiredActionName: '',
    FileName: '',
    FileKey: ''
}

export default function LicenceAppConfigDocument(props) {
    const { t } = useTranslation();
    const { values, toggleSection } = props.smartFormValues;
    const [selectedDocValues, setSelectedDocValues] = useState(LICENCE_DOC_VALUES);
    const [isOpenViewDocument, setIsOpenViewDocument] = useState(false);

    const SECTION_NAMES = {
        LICENCE_DOCUMENT: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_SECTION_SERVICEDOCUMENT), status: true }
    };

    const viewFile = async (fileName) =>
        await getFileRequest(GET_FILE_BY_NAME_URL + '?fileName=' + fileName, false, fileName);

    const viewDocument = (data) => {
        const index = values.LicenceDocumentData.findIndex(val =>
            val.RowId === data.RowId
        );
        let fileIndex = values.FileUploadSectionFiles.findIndex(val => val.key === data.FileKey);
        if (fileIndex < 0) {
            fileIndex = values.FileUploadSectionFiles.findIndex(val => val.Filename === data.FileKey);
        }
        data.DocumentFile = values.FileUploadSectionFiles[fileIndex];
        setSelectedDocValues(data);
        setIsOpenViewDocument(true);
    }

    const renderBody = () => {
        
        return (
            <>
                <Plaintext
                    name='WorkflowStateName'
                    value={selectedDocValues.WorkflowStateName}
                    label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_ASSOCIATEDWORKFLOWSTATE)}
                />
                <Plaintext
                    name='DocumentName'
                    value={selectedDocValues.DocumentName}
                    label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_DOCUMENTNAME)}
                />
                <Plaintext
                    name='RequiredActionName'
                    value={selectedDocValues.RequiredActionName}
                    label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_PLACEHOLDER_REQUIREDACTIONS)}
                />
                <FileUpload
                    fileList={values.FileUploadSectionFiles}
                    labelSize={FIELD_SIZE.SMALL}
                    inputSize={FIELD_SIZE.LARGE}
                    name="DocumentFile"
                    label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_DOCUMENTFILE)}
                    accept=".doc,.docx"
                    onChangeField={(name, value) => {}}
                    value={selectedDocValues.DocumentFile}
                    documentFileName={selectedDocValues.DocumentFileName}
                    multipleFilesWithSameName={true}
                    error=''
                    multiple={true}
                    getFileURL={GET_FILE_BY_NAME_URL}
                    readonly={true}
                    isRequireAuthorize={true}
                />
                <Plaintext
                    name='DocumentDescription'
                    value={selectedDocValues.DocumentDescription}
                    label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_PLACEHOLDER_DOCUMENTDESCRIPTION)}
                />
            </>
        );
    };

    const renderDocumentGrid = values => {
        return (
            <React.Fragment>
                <div className='smart-list'>
                    <DataTable
                        pageSize='5'
                        minFilterChars='2'
                        data={values.LicenceDocumentData}
                        noResultsMessage={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_MESSAGE_PLACEHOLDER_NOSERVICESELECTED)}
                        components={getCustomComponents()}
                        columns={
                            {
                                RunningNumber: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_NUMBER), width: '5%' },
                                WorkflowState: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_WORKFLOWSTATE), width: '20%', DBkey: 'WorkflowStateName' },
                                DocumentName: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_DOCUMENTNAME), width: '20%', DBkey: 'DocumentName' },
                                RequiredAction: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_REQUIREDACTIONS), width: '20%', DBkey: 'RequiredActionName' },
                                DocumentFile: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_UPLOADEDDOCUMENT), DBkey: 'DocumentFileName' },
                                Actions: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_ACTION), width: '10%' }
                            }
                        }
                        renderActions={({ RowData }) => (
                            <React.Fragment>
                                <ActionButton tooltip={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_VIEW)} color='forward' onClick={() => viewDocument(RowData)}>
                                    <FontAwesomeIcon icon={faEye} />
                                </ActionButton>
                            </React.Fragment>
                        )}
                    />
                    <ModalConfirm
                        isOpen={isOpenViewDocument}
                        cancelText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_CANCEL)}
                        contentHeader={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_MODAL_VIEWSERVICEDOCUMENT)}
                        cancelCallback={() => setIsOpenViewDocument(false)}
                        contentBody={renderBody()}
                    />
                </div>
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            <Container className='full-width'>
                <AccordionContainer
                    title={SECTION_NAMES.LICENCE_DOCUMENT.title}
                    active={SECTION_NAMES.LICENCE_DOCUMENT.status}
                    onClick={toggleSection}
                    isIndividual={true}
                    sections={SECTION_NAMES
                    }
                >
                    {renderDocumentGrid(values)}
                </AccordionContainer>
            </Container>

        </React.Fragment>
    );
}


