import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import { LAYOUT_TYPE, SCOPE_MANAGEMENT_URL } from '../../App/AppSettings';
import Layout from '../../Layout';
import { navigateTo } from '../../Shared/Actions';
import { withSMARTWrapper, Plaintext, SMARTForm } from '../../Shared/Forms';
import history from '../../Shared/History';
import { DEFAULT_TEXT, DEFAULT_NAMESPACE } from '../../Shared/Constants/LanguageKeys.js';
import PageFooter from '../../Shared/PageFooter';
import './LanguageList.css';

const FORM_CONTEXT = "ViewScope";
const LABEL_SIZE = 3;
const INPUT_SIZE = 9;

class ViewScope extends React.Component {
    constructor(props) {
        super(props);
        if (history.location.state) {
            this.state = {
                data: history.location.state.data.RowData
            }
        }
    }

    render() {
        const { Name, Description, FormattedCreatedDate, CreatedBy, LastUpdatedBy, FormattedLastUpdatedDate } = this.state.data
        return (
            <>
                <Layout type={LAYOUT_TYPE.FORM} title=
                    {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_CONTENT_TITLE_VIEWSCOPE, DEFAULT_TEXT.VIEW_SCOPE)}>
                    <SMARTForm
                        formContext={FORM_CONTEXT}
                    >
                        {() => (
                            <Row className="body-content">
                                <Col>
                                    <Plaintext
                                        label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_FORM_TITLE_NAME, DEFAULT_TEXT.NAME)}
                                        name="Name"
                                        value={Name}
                                        labelSize={LABEL_SIZE}
                                        inputSize={INPUT_SIZE}
                                    />

                                    <Plaintext
                                        label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_FORM_TITLE_DESCRIPTION, DEFAULT_TEXT.DESCRIPTION)}
                                        name="Scope Description"
                                        value={Description}
                                        labelSize={LABEL_SIZE}
                                        inputSize={INPUT_SIZE}
                                    />
                                    <Plaintext
                                        label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_FORM_TITLE_CREATEDBY, DEFAULT_TEXT.CREATED_BY)}
                                        name="Created By"
                                        value={CreatedBy}
                                        labelSize={LABEL_SIZE}
                                        inputSize={INPUT_SIZE}
                                    />
                                    <Plaintext
                                        label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_FORM_TITLE_CREATEDDATE, DEFAULT_TEXT.CREATED_DATE)}
                                        name="Created Date"
                                        value={FormattedCreatedDate}
                                        labelSize={LABEL_SIZE}
                                        inputSize={INPUT_SIZE}
                                    />
                                    <Plaintext
                                        label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_FORM_TITLE_LASTUPDATEDBY, DEFAULT_TEXT.LAST_UPDATED_BY)}
                                        name="Last Updated By"
                                        value={LastUpdatedBy}
                                        labelSize={LABEL_SIZE}
                                        inputSize={INPUT_SIZE}
                                    />
                                    <Plaintext
                                        label={this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_FORM_TITLE_LASTUPDATEDDATE, DEFAULT_TEXT.LAST_UPDATED_DATE)}
                                        name="Last Updated Date"
                                        value={FormattedLastUpdatedDate}
                                        labelSize={LABEL_SIZE}
                                        inputSize={INPUT_SIZE}
                                    />
                                    <PageFooter type={LAYOUT_TYPE.FORM}>
                                        <Button color="backward" size="sm" type="button" onClick={() => navigateTo(SCOPE_MANAGEMENT_URL)}>
                                            {this.props.getStaticText(DEFAULT_NAMESPACE.GLOBAL_LOCALESTATIC_NAVIGATION_BUTTON_BACK, DEFAULT_TEXT.BACK)}
                                        </Button>
                                    </PageFooter>
                                </Col>
                            </Row>
                        )}
                    </SMARTForm>
                </Layout>
            </>
        );
    }
}

export default withSMARTWrapper(ViewScope);
