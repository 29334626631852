import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { withTranslation } from "react-i18next";
import {
	GET_FILE_URL,
	LAYOUT_TYPE,
	TABLE_LAYOUT,
	GET_CODE_BY_CODE_CATEGORY,
	GET_APPLICATION_CONFIGURATION_BY_CATEGORY,
} from "../../../../App/AppSettings";
import Section from "../../../../Section";
import SubSection from "../../../../Section/SubSection";
import { ActionButton, DataTable } from "../../../../Shared/DataTable";
import { getCustomComponents } from "../../../../Shared/DataTable/BaseTable.js";
import { AccordionContainer, Plaintext } from "../../../../Shared/Forms";
import { ModalConfirm } from "../../../../Shared/Modal";
import FileUploadSection from "../../../FormComponents/FileUploadSection";
import {
	additionalFormRegex,
	getTableListValues,
} from "../../../FormComponents/FormUtils";
import RenderFieldComponent from "../../../FormComponents/RenderFieldComponent";
import {
	BigPreviewTable,
	BigTable,
	SmallPreviewTable,
	SmallTable,
} from "../../../FormComponents/TableComponent";
import {
	LANGUAGE_KEYS,
	getLangKey,
} from "../../../../Shared/Constants/LanguageKeys";
import { Form, Formio } from "@formio/react";
import "bootstrap/dist/css/bootstrap.css";
import "formiojs/dist/formio.full.css";
import "../../../../Css/formio.custom.css";
import utils from "formiojs/utils";
import { fetchRequest, getParams } from "../../../../Shared/Actions/index.js";

const MODAL_NAMES = { AdditionalFormViewHistory: "AdditionalFormViewHistory" };
const ADDITIONAL_SUPPORTING_DOCUMENT_SECTION_NAMES = {
	MANDATORY_DOCUMENTS: { title: "Mandatory Documents", status: true },
	OPTIONAL_DOCUMENTS: { title: "Optional Documents", status: true },
};
const IS_UPPER = true;
const FORMIO_SELECT_INPUT_TYPE = "select";
const FORMIO_PANEL_INPUT_TYPE = "panel";
const FORMIO_SELECT_DATA_SOURCE_CUSTOM = "custom";
const FORMIO_DSP = "DSP";
const FORMIO_DSP_CODE = "Code";
const FORMIO_DSP_APPLICATION_CONFIGURATION = "ApplicationConfiguration";

class AdditionalInformation extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			//Check whether webpage is loaded
			isLoaded: false,
			licence: {},
			// form.io data to be sent to public API
			formIODataArray: [],
			// final form.io data converted
			formIODataArrayConverted: "",
			// array to hold all form.io forms
			formIOFormsList: [],
			formIOFormInstances: [],
		};
	}

	// Get Form.io form and translation key
	async componentDidMount() {
		const { t, additionalFormResponseData } = this.props;

		if (additionalFormResponseData.FormDefinition.FormIOUrl) {
			const formList = this.formIO_SetFormIOStateValues(
				additionalFormResponseData.FormDefinition,
				null,
				null
			);
			for (var currentForm of formList) {
				const response = await fetchRequest(
					currentForm.url,
					getParams(),
					false
				);
				const apiFetchResultArray = [];
				if (response.success && response.body !== "") {
					var json = response.body;
					// replace each field's title/label with the translation key
					if (json.components) {
						utils.eachComponent(
							json.components,
							(component) => {
								const translated = t(
									getLangKey(
										LANGUAGE_KEYS.BLS_FORMIO_FIELD,
										currentForm.translationKey,
										component.key
									)
								);
								if (utils.isLayoutComponent(component)) {
									component.title = translated;
								}
								if (utils.isInputComponent(component)) {
									component.label = translated;
								}
							},
							true
						);
					}

					// fetch Code/ApplicationConfiguration from DB
					const selectComponents = utils.searchComponents(
						json.components,
						{
							type: FORMIO_SELECT_INPUT_TYPE,
							dataSrc: FORMIO_SELECT_DATA_SOURCE_CUSTOM,
						}
					);

					// need to fetch values from Code/ApplicationConfiguration separately since form.io eachComponent function does not support async
					for (const selectComponent of selectComponents) {
						const customDataSource =
							selectComponent.data.custom.split(".");
						if (customDataSource[0] === FORMIO_DSP) {
							switch (customDataSource[1]) {
								case FORMIO_DSP_CODE:
									const queryString = Object.assign(
										{},
										{ "?categories": customDataSource[2] }
									);
									const codeResponse = await fetchRequest(
										GET_CODE_BY_CODE_CATEGORY,
										getParams(queryString),
										false
									);
									if (codeResponse.success) {
										apiFetchResultArray.push({
											key: selectComponent.key,
											value: codeResponse.body.Data,
										});
									}
									break;
								case FORMIO_DSP_APPLICATION_CONFIGURATION:
									const queryString_two = Object.assign(
										{},
										{ "?category": customDataSource[2] }
									);
									const applicationConfigurationResponse =
										await fetchRequest(
											GET_APPLICATION_CONFIGURATION_BY_CATEGORY,
											getParams(queryString_two),
											false
										);
									if (
										applicationConfigurationResponse.success
									) {
										apiFetchResultArray.push({
											key: selectComponent.key,
											value: applicationConfigurationResponse
												.body.Data,
										});
									}
									break;
								default:
									break;
							}
						}
					}

					utils.eachComponent(
						json.components,
						(component) => {
							if (
								component.type === FORMIO_SELECT_INPUT_TYPE &&
								component.dataSrc ===
									FORMIO_SELECT_DATA_SOURCE_CUSTOM
							) {
								const customDataSource =
									component.data.custom.split(".");
								if (customDataSource[0] === FORMIO_DSP) {
									component.dataSrc = "values";
									component.data.custom = "";
									const resultArray =
										apiFetchResultArray.find(
											(item) => item.key === component.key
										);
									switch (customDataSource[1]) {
										case FORMIO_DSP_CODE:
											component.data.values =
												resultArray.value.map(
													(item) => ({
														value: item.CodeValue,
														label: item.CodeTitle,
													})
												);
											break;
										case FORMIO_DSP_APPLICATION_CONFIGURATION:
											component.data.values =
												resultArray.value.map(
													(item) => ({
														value: item.ConfigurationValue,
														label: item.ConfigurationName,
													})
												);
											break;
										default:
											break;
									}
								}
							}
						},
						true
					);
					currentForm.json = json;
				}
			}

			this.setState({
				formIOFormsList: formList,
			});
		}
	}
	// Open View Modal
	toggleModal = (modalName) => {
		this.setState({
			modalState:
				this.state.modalState !== modalName ? modalName : undefined,
		});
	};
	viewAdditionalForm = (data) => {
		this.toggleModal(MODAL_NAMES.AdditionalFormViewHistory);
		this.setState({
			modalId: data.griddleKey,
		});
	};

	formIO_ChangeHandler(formData) {
		var currentForm = this.state.formIOFormsList[0];
		if (currentForm && formData) {
			currentForm.isValid = formData.isValid;
			currentForm.data = formData.data;

			const formIOName = currentForm.name;
			if (
				this.state.formIODataArray !== undefined &&
				this.state.formIODataArray.length > 0
			) {
				const currentFormIOData = this.state.formIODataArray.find(
					(form) => form.name === formIOName
				);
				if (currentFormIOData) {
					const newFormIODataArray = this.state.formIODataArray.map(
						(form) => {
							if (form.name === formIOName)
								return {
									name: formIOName,
									data: formData.data,
									isValid: formData.isValid,
								};
							else return form;
						}
					);
					this.setState({ formIODataArray: newFormIODataArray });
				} else {
					this.setState({
						formIODataArray: [
							...this.state.formIODataArray,
							{
								name: formIOName,
								data: formData.data,
								isValid: formData.isValid,
							},
						],
					});
				}
			} else {
				this.setState({
					formIODataArray: [
						...this.state.formIODataArray,
						{
							name: formIOName,
							data: formData.data,
							isValid: formData.isValid,
						},
					],
				});
			}
			this.formIO_ConvertDataArrayToJson();

			// assign new value of formIOFormsList
			const newFormList = [currentForm];
			this.setState({ formIOFormsList: newFormList });
		}
	}

	formIO_ConvertDataArrayToJson = () => {
		let resultJson = [];
		for (const formData of this.state.formIODataArray) {
			const formName = formData.name;
			const currentFormData = {
				[formName]: formData.data,
			};
			resultJson = [...resultJson, currentFormData];
		}
		this.setState({
			formIODataArrayConverted: Object.assign({}, ...resultJson),
		});
	};

	formIO_SetFormIOStateValues = (formDefinition, dataList, formType) => {
		let dataGridDataArray = [];
		let nonDataGridArray = [];
		for (const individualFieldKey in dataList) {
			const fieldKeyArray = individualFieldKey.split(".");
			if (fieldKeyArray.length < 2) {
				nonDataGridArray = [...nonDataGridArray, individualFieldKey];
				continue;
			}

			const datagridName = fieldKeyArray[0];
			const newFieldKey = fieldKeyArray[fieldKeyArray.length - 1];

			let existingDataGridElement = dataGridDataArray.find(
				(element) => element[0] === datagridName
			);
			if (existingDataGridElement) {
				let existingDataGridValue =
					existingDataGridElement[1][fieldKeyArray[1]];
				if (existingDataGridValue) {
					existingDataGridValue[newFieldKey] =
						dataList[individualFieldKey];
				} else {
					const newDataGridValue = {
						[newFieldKey]: dataList[individualFieldKey],
					};
					existingDataGridElement[1] = [
						...existingDataGridElement[1],
						newDataGridValue,
					];
				}
			} else {
				const newDataGridElement = [
					datagridName,
					[
						{
							[newFieldKey]: dataList[individualFieldKey],
						},
					],
				];
				dataGridDataArray = [...dataGridDataArray, newDataGridElement];
			}
		}

		let formData;
		for (const individualGrid of dataGridDataArray) {
			formData = {
				...formData,
				[individualGrid[0]]: individualGrid[1],
			};
		}

		for (const individualField of nonDataGridArray) {
			formData = {
				...formData,
				[individualField]: dataList[individualField],
			};
		}

		if (
			this.state.formIOFormsList.filter(
				(form) => form.name === formDefinition.FormName
			).length === 0
		) {
			return [
				...this.state.formIOFormsList,
				{
					url: formDefinition.FormIOUrl,
					name: formDefinition.FormName,
					json: {},
					translationKey: formDefinition.FormName.replaceAll(
						" ",
						"_"
					),
					isValid: true,
					formInstance: null,
					data: formData,
				},
			];
		} else {
			if (formType) {
				let currentForm = this.state.formIOFormsList.find((form) =>
					formType.includes(form.name)
				);
				currentForm.data = formData;

				const newFormList = this.state.formIOFormsList.map((form) => {
					if (form.name === currentForm.name) return currentForm;
					else return form;
				});
				return newFormList;
			}
		}
	};

	render() {
		const {
			t,
			additionalFieldsError,
			additionalFormResponseData,
			optionDataList,
			sectionState,
			sectionName,
			additionalFormDefinition,
		} = this.props;
		const { values, onChange, onChangeField, errors, toggleSection } =
			this.props.smartFormValues;
		const { modalState } = this.state;
		let requiredDocs = [];
		let optionDocs = [];
		if (
			additionalFormResponseData.FilesDefinition &&
			additionalFormResponseData.FilesDefinition.Fields.length > 0
		) {
			requiredDocs =
				additionalFormResponseData.FilesDefinition.Fields.filter(
					(field) =>
						field.Validation && field.Validation.Required === true
				);
			optionDocs =
				additionalFormResponseData.FilesDefinition.Fields.filter(
					(field) =>
						field.Validation && field.Validation.Required === false
				);
		}

		//const cloneValues = JSON.parse(JSON.stringify(values));
		//this is to copy Application details to InternalForms
		//const internalFormValues = cloneValues[additionalFormResponseData.FormDefinition.FormType];
		values[additionalFormResponseData.FormDefinition.FormType] =
			this.state.formIODataArrayConverted;
		const internalFormValues =
			values[additionalFormResponseData.FormDefinition.FormType];
		if (additionalFormResponseData.FormDefinition.Sections) {
			for (var key of Object.keys(internalFormValues)) {
				const intrFormValue = internalFormValues[key];
				const type = typeof intrFormValue;
				if (type === "string") {
					if (intrFormValue === "" || undefined) {
						let applicationValue = values[key];
						if (applicationValue === undefined)
							applicationValue = intrFormValue;
						internalFormValues[key] = applicationValue;
					}
				} else {
					let isEmpty = true;
					const tableRows = getTableListValues(
						Object.keys(values),
						key
					);
					const formSection =
						additionalFormResponseData.FormDefinition.Sections.find(
							(d) => d.Name === key
						);
					const isSmallTable =
						formSection.Table.Fields.length <= TABLE_LAYOUT.COLUMNS;
					//check it is an array and contains table
					const result = Array.isArray(intrFormValue);
					if (result && formSection.Table) {
						if (isSmallTable) {
							// check length is one (smalltable component default empty row is coming)
							if (intrFormValue.length === 1) {
								intrFormValue.forEach(function (arrayItem) {
									for (var arrayKey of Object.keys(
										arrayItem
									)) {
										if (
											arrayItem[arrayKey] !== "" &&
											isEmpty
										)
											isEmpty = false;
									}
								});
							} else isEmpty = false;
						} else {
							// check length is zero (largetable component doesn't have empty row)
							if (intrFormValue.length === 0) {
								isEmpty = true;
							} else isEmpty = false;
						}

						//create table data holder
						const tableData = [];
						if (isEmpty) {
							//loop for number rows in Application submission
							tableRows[key].forEach(function (row) {
								const data = {};
								//loop for the table columns
								formSection.Table.Fields.forEach((field) => {
									data[field.Name] =
										values[`${row}.${field.Name}`];
								});
								tableData.push(data);
							});
							internalFormValues[key] = tableData;
						}
					}
				}
			}
		}

		return (
			<Section
				type={LAYOUT_TYPE.FORM_CONTENT}
				sectionState={sectionState}
				sectionName={sectionName}
				toggleSection={toggleSection}
			>
				{additionalFormResponseData.FormDefinition.Sections &&
					additionalFormResponseData.FormDefinition.Sections.map(
						(section, secindex) => {
							return (
								<SubSection
									type={LAYOUT_TYPE.FORM_CONTENT}
									title={t(
										getLangKey(
											LANGUAGE_KEYS.BLS_COMMONINFO_FORM_SECTION_KEY,
											section.Name
										)
									)}
									orientation="left"
									key={secindex}
								>
									{section.Fields &&
										section.Fields.map((field, index) => {
											return (
												<RenderFieldComponent
													key={index}
													formType={
														additionalFormResponseData
															.FormDefinition
															.FormType
													}
													field={field}
													values={values}
													onChange={onChange}
													onChangeField={
														onChangeField
													}
													errors={errors}
													additionalFieldsError={
														additionalFieldsError
													}
													optionDataList={
														optionDataList
													}
												/>
											);
										})}
									{section.Table &&
										section.Table.Fields.length <=
											TABLE_LAYOUT.COLUMNS && (
											<SmallTable
												smartFormValues={
													this.props.smartFormValues
												}
												formType={
													additionalFormResponseData
														.FormDefinition.FormType
												}
												formSection={section}
												additionalFieldsError={
													additionalFieldsError
												}
												optionDataList={optionDataList}
											/>
										)}
									{section.Table &&
										section.Table.Fields.length >
											TABLE_LAYOUT.COLUMNS && (
											<BigTable
												smartFormValues={
													this.props.smartFormValues
												}
												formType={
													additionalFormResponseData
														.FormDefinition.FormType
												}
												form={
													additionalFormResponseData.FormDefinition
												}
												formSection={section}
												additionalFieldsError={
													additionalFieldsError
												}
												optionDataList={optionDataList}
											/>
										)}
								</SubSection>
							);
						}
					)}
				{additionalFormResponseData.FormDefinition.FormIOUrl && (
					<Form
						src={
							additionalFormResponseData.FormDefinition.FormIOUrl
						}
						onChange={(formData) =>
							this.formIO_ChangeHandler(formData)
						}
					></Form>
				)}
				{additionalFormResponseData.FilesDefinition &&
					additionalFormResponseData.FilesDefinition.Fields.length >
						0 && (
						<SubSection
							orientation="left"
							title={t(
								LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_SECTION_SUPPORTINGDOCUMENT
							)}
						>
							{requiredDocs.length > 0 && (
								<AccordionContainer
									className="accordion"
									title={
										ADDITIONAL_SUPPORTING_DOCUMENT_SECTION_NAMES
											.MANDATORY_DOCUMENTS.title
									}
									active={
										ADDITIONAL_SUPPORTING_DOCUMENT_SECTION_NAMES
											.MANDATORY_DOCUMENTS.status
									}
									onClick={toggleSection}
									isIndividual={true}
									sections={
										ADDITIONAL_SUPPORTING_DOCUMENT_SECTION_NAMES
									}
								>
									<div className="card-body">
										{requiredDocs.map((field) => (
											<RenderFieldComponent
												key={field.Name}
												formType={"SupportingDocument"}
												field={field}
												values={values}
												onChange={onChange}
												onChangeField={onChangeField}
												errors={errors}
												additionalFieldsError={
													additionalFieldsError
												}
											/>
										))}
									</div>
								</AccordionContainer>
							)}

							{optionDocs.length > 0 && (
								<AccordionContainer
									className="accordion"
									title={
										ADDITIONAL_SUPPORTING_DOCUMENT_SECTION_NAMES
											.OPTIONAL_DOCUMENTS.title
									}
									active={
										ADDITIONAL_SUPPORTING_DOCUMENT_SECTION_NAMES
											.OPTIONAL_DOCUMENTS.status
									}
									onClick={toggleSection}
									isIndividual={true}
									sections={
										ADDITIONAL_SUPPORTING_DOCUMENT_SECTION_NAMES
									}
								>
									<div className="card-body">
										{optionDocs.map((field) => (
											<RenderFieldComponent
												key={field.Name}
												formType={"SupportingDocument"}
												field={field}
												values={values}
												onChange={onChange}
												onChangeField={onChangeField}
												errors={errors}
											/>
										))}
									</div>
								</AccordionContainer>
							)}
						</SubSection>
					)}
				{additionalFormDefinition && (
					<SubSection
						orientation="left"
						title={t(
							LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_CONTENT_PAGINATION_HISTORY
						)}
					>
						<DataTable
							pageSize="5"
							minFilterChars="2"
							data={additionalFormDefinition}
							noResultsMessage={t(
								LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_MESSAGE_PLACEHOLDER_NODECLARATION
							)}
							components={getCustomComponents()}
							columns={{
								RunningNumber: {
									title: t(
										LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_TABLE_TITLE_NUMBER
									),
									width: "5%",
								},
								ActionDate: {
									title: t(
										LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_TABLE_INFORMATION_ACTIONDATE
									),
									width: "40%",
									DBkey: "CreatedDate",
									isDate: true,
								},
								ProcessedBy: {
									title: t(
										LANGUAGE_KEYS.BLS_INTERNALLICENCEDETAILS_TABLE_TITLE_PROCESSEDBY
									),
									width: "35%",
									DBkey: "ProcessedBy",
								},
								Actions: {
									title: t(
										LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_TABLE_TITLE_ACTION
									),
									width: "25%",
								},
							}}
							renderActions={({ RowData }) => (
								<React.Fragment>
									<ActionButton
										tooltip={t(
											LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_VIEW
										)}
										color="forward"
										onClick={() =>
											this.viewAdditionalForm(
												RowData,
												additionalFormResponseData.Title
											)
										}
									>
										<FontAwesomeIcon icon={faEye} />
									</ActionButton>
								</React.Fragment>
							)}
						/>
						<ModalConfirm
							isOpen={
								modalState ===
								MODAL_NAMES.AdditionalFormViewHistory
							}
							contentHeader={
								additionalFormDefinition[this.state.modalId] &&
								t(
									getLangKey(
										LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_CONTENT_TITLE_KEY,
										additionalFormRegex(
											additionalFormDefinition[
												this.state.modalId
											]["AdditionalFormDefinition"]
												.FormType
										)
									)
								)
							}
							cancelText={t(
								LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_CANCEL
							)}
							cancelCallback={() =>
								this.toggleModal(
									MODAL_NAMES.AdditionalFormViewHistory
								)
							}
							contentBody={
								<div>
									{this.state.modalId !== undefined &&
										additionalFormDefinition[
											this.state.modalId
										]["AdditionalFormDefinition"].Sections
											.length !== 0 &&
										additionalFormDefinition[
											this.state.modalId
										][
											"AdditionalFormDefinition"
										].Sections.map((section, index) => {
											return (
												<SubSection
													key={index}
													orientation="left"
													title={t(
														getLangKey(
															LANGUAGE_KEYS.BLS_COMMONINFO_FORM_SECTION_KEY,
															section.Name
														)
													)}
												>
													{section.Fields &&
														section.Fields.map(
															(field, findex) => {
																return (
																	<div
																		key={
																			findex
																		}
																	>
																		{
																			<Plaintext
																				key={
																					findex
																				}
																				label={t(
																					getLangKey(
																						LANGUAGE_KEYS.BLS_COMMONINFO_FORM_FIELD_KEY,
																						field.Name
																					)
																				)}
																				name={
																					field.Name
																				}
																				value={
																					additionalFormDefinition[
																						this
																							.state
																							.modalId
																					][
																						"Values"
																					][
																						field
																							.Name
																					]
																				}
																				isUpper={
																					IS_UPPER
																				}
																			/>
																		}
																	</div>
																);
															}
														)}
													{section.Table &&
														section.Table.Fields
															.length <=
															TABLE_LAYOUT.COLUMNS && (
															<SmallPreviewTable
																section={
																	section
																}
																values={
																	additionalFormDefinition[
																		this
																			.state
																			.modalId
																	]["Values"]
																}
															/>
														)}
													{section.Table &&
														section.Table.Fields
															.length >
															TABLE_LAYOUT.COLUMNS && (
															<BigPreviewTable
																section={
																	section
																}
																values={
																	additionalFormDefinition[
																		this
																			.state
																			.modalId
																	]["Values"]
																}
															/>
														)}
												</SubSection>
											);
										})}
									{additionalFormDefinition[
										this.state.modalId
									] &&
										additionalFormDefinition[
											this.state.modalId
										]["SupportingDocument"] &&
										additionalFormDefinition[
											this.state.modalId
										]["SupportingDocument"].length > 0 && (
											<FileUploadSection
												name="SupportingDocuments"
												fileList={
													additionalFormDefinition[
														this.state.modalId
													]["SupportingDocument"]
												}
												getFileURL={GET_FILE_URL}
												isUpper={IS_UPPER}
												readonly
											/>
										)}
								</div>
							}
						/>
					</SubSection>
				)}
			</Section>
		);
	}
}

export default withTranslation()(AdditionalInformation);
