// Const: Error Messages
export const ERROR = {
    REQUIRED: '<%= label %> is required.',
    TICK_REQUIRED: 'Please tick the box to confirm that you have read the above.',
    MIN_VALUE: 'Minimum value is <%= minValue %>.',
    MAX_VALUE: 'Maximum value is <%= maxValue %>.',
    FILE_LIMIT_REACHED: 'Maximum number of file(s) uploaded is <%= limit %>.',
    FILE_ITEM_REQUIRED: 'File is required.',
    FILE_DESC_REQUIRED: 'File description is required.',
    FORM_VALIDATION_FAIL: 'Kindly fill in the required fields appropriately. Please try again.',
    FORM_DEFAULT_FAIL: 'Application has not been processed successfully.',
    PASSWORD_REGEX_UNMATCH: 'The password should contain at least 8 characters, with 1 uppercase, 1 lowercase and 1 number.',
    EMAIL_INVALID: 'Email is invalid.',
    MIN_LENGTH_LIMIT: 'Minimum length is <%= minLength %>.',
    MAX_LENGTH_LIMIT: 'Maximum length is <%= maxLength %>.',
    TELEPHONE_INVALID: 'Telephone number provided is invalid.',
    NUMBER_INVALID: 'Number provided is invalid.',
    APPLICATION_DELETE_FAIL: 'Application has not been deleted. Please try again.',
    LIST_RETRIEVAL_FAIL: 'Unable to retrieve list. Please try again.',
    DRAFT_SAVED_FAIL: 'Draft has not been saved. Please try again.',
    DRAFT_EMPTY_FIELD: 'Please fill in at least one field.',
    DATE_INVALID: 'Date is invalid.',
    //Work Hour Error
    TIME_INVALID_WE: 'Work End should be after Work Start',
    TIME_INVALID_BS: 'Break Start should be after Work Start',
    TIME_INVALID_BSWE: 'Break Start should be before Work End',
    TIME_INVALID_BE: 'Break End should be after Break Start',
    SERVER_UNREACHABLE: 'Server could not be reached, please try again later.',
    SEQUENCE_NO: 'Sequence must be a number.'
};



// Const: Success Messages
export const SUCCESS = {
    FORM_DEFAULT_SUCCESS: 'Application has been processed successfully.',
    APPLICATION_DELETE_SUCCESS: 'Application has been deleted successfully.',
    CREATE_SUCCESS: '<%= component %> has been added successfully.',
    UPDATE_SUCCESS: '<%= component %> has been updated successfully.',
    DELETE_SUCCESS: '<%= component %> has been deleted successfully.',
    PUBLISH_SUCCESS: '<%= component %> has been published successfully.',
    REVERT_SUCCESS: '<%= component %> has been reverted successfully.',
    CREATE_DRAFT_SUCCCESS: '<%= component %> draft has been created successfully.'
};



//Const: Button Labels
export const BUTTON = {
    CREATE: 'Create',
    UPDATE: 'Save Changes',
    BACK: 'Back',
    SUBMIT: 'Submit',
    SAVE: 'Save',
    PUBLISH: 'Publish',
    CREATE_DRAFT: 'Create Draft',
    DELETE_DRAFT: 'Delete Draft',
    PREVIEW: 'Preview',
    NEW: 'New <%= component %>'
};
